import { postPromise } from "@/apis/base";
import config from "@/apis/config";
import { ROUTE_IDS, composeFormData } from "../routeId";

export const checkEmailUnique = async (params) => {
    return new Promise((resolve, reject) => {
        postPromise(config.API_BASE_URL, composeFormData(params, ROUTE_IDS.CHECK_EMAIL)).then(response => {
            if (response?.status === 0) resolve(true) 
            if (response?.status === 1) reject(false)
        });
    })
  }