import React from 'react';
import { BasicTable } from '../BasicTable';
import { EligibleOrgsColumns } from './EligibleOrgsColumns';


export const EligibleOrgsTable = ({data, disabled, ...props}) => {
    const safeData = data?.length > 0 ? data : []
    return (
        <BasicTable 
            hasPinnedRows
            className='table-xs xl:table-sm '
            rowClassName='text-base'
            tableProps={{
                enablePinning: true,
                enableRowPinning: true,
                keepPinnedRows: true
            }} 
            disabled={disabled}
            meta={{
                disabled,
            }}
            oneRowControls 
            data={safeData} columnHelper={EligibleOrgsColumns} {...props}/>
    );
};

