
import React from 'react';
import { useCookies, withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import { UserDropDown } from './HeaderDropdowns';
import {
  DonationIcon,
  LocationIcon,
  NewsIcon,
  WishlistIcon
} from './HeaderIcons';

import { UsersIcon } from '@/components/Icons';
import { getCookie } from '@/utils/index';
import { useQueryClient } from '@tanstack/react-query';

const HeaderMenu = (props) => {
  const { history, isAdmin, availability, context } = props;
  // const { scpStatus, setScpStatus } = useAppContext();
  const queryClient = useQueryClient();
  const [cookies, setCookie, removeCookie] = useCookies([]);

  // const [showDropDowns, setShowDropDowns] = useState({
  //   user: null,
  //   auth: null,
  // });

  const handleHomeClicked = () => {
    if (getCookie('orgIdContext') !== '') {
      setCookie('orgIdContext', '', { path: '/' });
      queryClient.invalidateQueries({ queryKey: ['context', 'org'] });
    }
  }

  return (
    <>
      <div className="flex items-center flex-row gap-3">
        <div className="sm:flex hidden gap-1">
            {availability['news'] && (
              <Link to="/news">
                <div className='btn  btn-little btn-circle btn-ghost '>
                  <NewsIcon className='stroke-1 fill-gray-dark w-9 h-9'/>
                </div>
              </Link>
            )}
            {availability['members'] && (
              <Link to="/members">
                <div className='btn   btn-little btn-circle btn-ghost'>
                  <UsersIcon className='w-6 h-6 stroke-gray-dark' strokeWidth={2.45}/>
                </div>
              </Link>
            )}
            {availability['local-communities'] && (
              <Link to="/local-communities">
                <div className='btn btn-sm  btn-little btn-circle btn-ghost'>
                  <LocationIcon className='w-6 h-6 stroke-gray-dark'/>
                </div>
              </Link>
            )}
            {availability['donation'] && (
              <Link to="/donation">
                <div className='btn btn-little btn-circle btn-ghost'>
                  <DonationIcon  className='stroke-gray-dark w-9 h-9' strokeWidth={0}/>
                </div>
              </Link>
            )} 
            {availability['wishlist'] && (
              <Link to="/wishlist">
                <div className='btn  btn-little btn-circle btn-ghost'>
                  <WishlistIcon className='w-7 h-7 stroke-gray-dark'/>
                </div>
              </Link>
            )}
            {/* {availability['fakeheaderitems'] && (
               <BasicGhostButton circle className={''}>
                <div className='indicator'>
                  <div className='indicator-item badge badge-info badge-xs'/>
                    <BellIcon className='w-6 h-6'/> 
                </div>
               </BasicGhostButton> 
            )} */}
        </div>
        <UserDropDown
          {...{ history, isAdmin, availability, context }}
          // onClose={() => handleCloseDropDown('user')}
        />
      </div>
    </>
  ) 
};

export default withCookies(HeaderMenu);
