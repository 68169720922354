import { BasicOrgTypeDropdown, FormBasicOrgTypeDropdown } from '@/components/Input/BasicOrgTypeDropdown';
import useOrgTypes from '@/hooks/state/enum/useOrgTypes';
import { useTranslations } from '@/hooks/translations';
import { useYupValidationResolver } from '@/hooks/utils/useYupValidationResolver';
import { checkFormForAutofill } from '@/utils/misc';
import { ContinueButton } from '@/views/pages/Auth/Signup/components/ContinueButton';
import { AuthTextInput } from '@/views/pages/Auth/components/BasicInputs';
import { SuccessPopup } from '@/wrappers/swal/SuccessPopup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';


const validationSchema = Yup.object({
    firstname: Yup.string().required('First Name is required').default('').default(''),
    lastname: Yup.string().required('Last Name is required').default(''),
    email: Yup.string().email('Email is not valid').required('Email is required').default(''),
    communityname: Yup.string().required('Community Name is required').default(''),
    communityType: Yup.string().required('Community Type is required').default(''),
    communitySize: Yup.number().required('Community Size is required').default(''),
    communityWebsite: Yup.string().notRequired()
})



export const CommunityRequest = ({history, ...props}) => {
    const text = useTranslations('signup')
    const orgTypesQuery = useOrgTypes()
    const resolver = useYupValidationResolver(validationSchema);
    const {register, handleSubmit, formState, setValue, reset, control}
        = useForm({resolver, defaultValues: validationSchema.default(), mode: 'onBlur'});

    function onSubmit(data,e) {
        // TODO make community request
        setTimeout(() => {
            onSubmissionSuccess()
        }, 1000);

        reset()
    }

    function onSubmissionSuccess() {
        SuccessPopup.fire({
            title: 'Community request submitted successfully',
            text: 'You will receive an email as soon as your request is processed',
            confirmButtonText: 'Return to home',
            showConfirmButton: true,
            position: 'center',
            timer: false
        }).then(() => {
            history.push('/')
        })
    }
    return (
        <div className="form-control items-start authContainer max-h-screen">
            <p className="text-3xl font-semibold">New Community Request</p>
            <form onSubmit={(e) => {checkFormForAutofill(formState,handleSubmit(onSubmit),e)}} className='flex flex-nowrap flex-col gap-3 lg:mt-4 lg:mb-20 w-full mt-2 '>
                <AuthTextInput
                    label='First Name'
                    error={formState.errors.firstname}
                    {...register('firstname')}/>           
                <AuthTextInput
                    label='Last Name'
                    error={formState.errors.lastname}
                    {...register('lastname')}/>
                <AuthTextInput
                    type='email'
                    label='Email'
                    error={formState.errors.email}
                    {...register('email')}/>
                <AuthTextInput  
                    label='Community name'
                    {...register('communityname')}/>
                <FormBasicOrgTypeDropdown
                    control={control}
                    orgTypes={orgTypesQuery.data?.hierarchialTypes} 
                    placeHolder='Community Type'
                    {...register('communityType')}
                />    
                <AuthTextInput
                    label='Community size'
                    type='number'
                    error={formState.errors.communitySize}
                    {...register('communitySize')}/>
                <AuthTextInput  
                    label='Community website'
                    error={formState.errors.communityWebsite}
                    {...register('communityWebsite')}/>
            <ContinueButton
                variant="contained"
                size="large"
                className="w-full"
                type='submit'
                text={text}
              >
                Continue
              </ContinueButton>
            </form>

        </div>
    );
};
