import { DIRECTION } from '@/constants';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SquareDirectionalButton } from '../Buttons/SquareDirectionalButton';
import { TrackedImage } from '../Image/TrackedImage';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const CAROUSEL_BUTTON_TYPE = {
    ecommerceButtons: 'ecommerceButtons',
    shadowButtons: 'shadowButtons',
}

/**
 * @param {keyof CAROUSEL_BUTTON_TYPE} buttonType
 * @returns 
 */
export const CarouselBase = ({
    modifiedTimestamp='',
    images, 
    loadingType='eager',
    firstLoadingType=loadingType,
    buttonType=CAROUSEL_BUTTON_TYPE.SHADOW,
    disableTracking=false, 
    numImages: initialNumImages, 
    aspectRatioClassName='',
    imageClassName='',
    className='',
    ...props }) => {
    const carouselRef = useRef()
    const [scrollPosition, setScrollPosition] = useState(0)
    const [numImages, setNumImages] = useState(images ? images?.length : 1)    
    useEffect(() => {
        // console.log('changing num images', initialNumImages);
        setNumImages(
            images 
                ? images?.length
                : initialNumImages
        )
    },[initialNumImages])

    useEffect(() => {
        setScrollPosition(0)
    },[images])

    useLayoutEffect(() => {
        const clientWidth = carouselRef.current?.clientWidth
        carouselRef.current?.scrollTo({left: clientWidth * scrollPosition, behavior: 'smooth'})
    },[scrollPosition])

    function scrollLeft(e) {
        e.stopPropagation()
        let newPosition = canLeft()
            ? scrollPosition - 1
            : numImages - 1
            setScrollPosition(newPosition)
    }
    function scrollRight(e) {
        e.stopPropagation()
        let newPosition = canRight()
            ? scrollPosition + 1
            : 0
        setScrollPosition(newPosition)
    }
    function canRight() {
        return scrollPosition < numImages - 1
    }
    function canLeft() {
        return scrollPosition > 0
    }

    const hideImage = useCallback((e) => {
        setNumImages(prev => prev - 1)
        e.currentTarget.parentElement.classList.add('hidden')
    },[])
    const showImage = useCallback((e) => {
        if (e.currentTarget.parentElement.classList.contains('hidden')) {
            setNumImages(prev => prev + 1)
            e.currentTarget.parentElement.classList.remove('hidden')
        }
    },[])

    return (
        <div className={`relative group w-full h-full ${className} ${aspectRatioClassName}`}>
            <div ref={carouselRef} className="carousel w-full h-full">
                { images?.map?.((img, idx) => (
                    <div key={`${img}-${idx}`} className="w-full  carousel-item">
                        <img
                            key={`${img}?.${modifiedTimestamp}`}
                            loading={idx === 0 ? firstLoadingType : loadingType}
                            src={img || '/assets/images/placeholder.png'}
                            className={`w-full h-full object-cover ${aspectRatioClassName} ${imageClassName}`}
                            onLoad={showImage}
                            onError={hideImage}

                        />
                    </div>
                ))}
            { numImages > 1 && (
                {
                    ecommerceButtons: (
                        <div className="absolute pointer-events-none transition-opacity duration-200 group-hover:opacity-100 flex w-full h-full justify-between flex-nowrap pb-[0.4rem] items-center opacity-0">
                            <SquareDirectionalButton onClick={scrollLeft} direction={DIRECTION.LEFT} className='ml-2 relative max-w-[10%]'/>
                            <SquareDirectionalButton onClick={scrollRight} direction={DIRECTION.RIGHT} className='mr-2 relative max-w-[10%]'/>
                    </div>
                    ),
                    shadowButtons: (
                        <div className="absolute flex w-full h-full justify-between flex-nowrap">
                            <div tabIndex={0} role='button' className="w-1/3 items-center h-full opacity-0 hover:cursor-pointer hover:opacity-100 shadow-mask-left transition-[opacity, background-opacity] duration-300 bg-opacity-0  hover:bg-opacity-80 bg-black flex" onClick={scrollLeft}>
                                <ChevronLeft className='ml-[15%] text-white w-10 h-10 '/>
                            </div>
                            <div tabIndex={0} role='button' className="w-1/3 items-center h-full opacity-0 hover:cursor-pointer hover:opacity-100 shadow-mask-right transition-[opacity, background-opacity] duration-300 bg-opacity-0  hover:bg-opacity-80 bg-black flex justify-end" onClick={scrollRight}>
                                <ChevronRight className='mr-[15%] text-white w-10 h-10 '/>
                            </div>
                        </div>
                    )
                }[buttonType]
                )}
            </div>
        </div>
    );
};

