import { modBDShipment } from '@/apis/base/donation/shipment/modBDShipment';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';


export default function useModBDShipment(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_BD_SHIPMENT],
        mutationFn: modBDShipment,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['bd', 'event', req.eventid]})
            onSuccess && onSuccess(data)
        },
        ...otherProps
    });
    return mutation;
}