import { DeleteButton } from '@/components/Buttons';
import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';
import { BasicGrayButton } from '@/components/Buttons/BasicGrayButton';
import { EditIcon, PlusIcon, XIcon } from '@/components/Icons';
import { BasicInput } from '@/components/Input/BasicInput';
import useAddSecondaryEmail from '@/hooks/state/user/useAddSecondaryEmail';
import useModMyUserData from '@/hooks/state/user/useModMyUserData';
import useRemoveSecondaryEmail from '@/hooks/state/user/useRemoveSecondaryEmail';
import { useRHF } from '@/hooks/utils/useRHF';
import { useYupValidationResolver } from '@/hooks/utils/useYupValidationResolver';
import { checkFormForAutofill } from '@/utils/misc';
import { GrayButton } from '@/views/pages/Auth/components/Buttons';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import Yup from '@/wrappers/Yup/Yup';
import React from 'react';

const VIEW = {
    DEFAULT: 'default',
    EDIT_PRIMARY: 'edit_primary',
    EDIT_SECONDARY: 'edit_secondary',
}

const validationSchema = Yup.object({
    email: Yup.string().email('Must be a valid email').required('Please enter a valid email')
})

export const EmailManagement = ({myUserQuery, secondaryEmailQuery, handleBack, ...props}) => {
    const addEmailMutation = useAddSecondaryEmail({
        myUserID: myUserQuery.data?.id,
        ...queryResultFns(
            'Secondary Email Added',
            'Failed to add secondary email',
            () => handleBack()
        )
    })
    const deleteEmailMutation = useRemoveSecondaryEmail({
        myUserID: myUserQuery.data?.id, 
        ...queryResultFns(
            'Secondary email removed',
            'Failed to remove secondary email'
        )
    })
    const modPrimaryEmailMutation = useModMyUserData(
        queryResultFns(
            'Primary email changed',
            'Failed to change primary email',
            // (data,req) => deleteEmail(req) //deletes secondary email
        )
    )
    const [view, setView] = React.useState(VIEW.DEFAULT)

    const resolver = useYupValidationResolver(validationSchema);
    const {register, handleSubmit, formState, setValue, reset, control}
        = useRHF({resolver, defaultValues: validationSchema.default(), mode: 'onBlur'});

    function submitForm() {document.forms.secondaryEmailForm?.requestSubmit?.()}
    function handleFormSubmit(event) {
        checkFormForAutofill(formState, handleSubmit(addEmail),event)
    }
    function deleteEmail(email) {
        deleteEmailMutation.mutate({emailid: email.id})
    }
    function modPrimaryEmail(email) {
        const currentPrimaryEmail = myUserQuery.data?.email
        const secondaryListContainsPrimary = 
            secondaryEmailQuery.data?.map?.(e => e?.name)?.some(e => e === currentPrimaryEmail)
        modPrimaryEmailMutation.mutate({email: email?.name}, {
            onSuccess: () => {
                if (!secondaryListContainsPrimary)
                    addEmailMutation.mutate({email: currentPrimaryEmail})
            }
        })
    }
    function addEmail(fields) {
        addEmailMutation.mutate(fields)
    }

    return (
        <div className=''>
            <div className="xs:flex items-center my-4">
                <div className="flex-2">
                    <p className="font-light">{'Primary email'}:</p>
                </div>
                <div className="flex-5">
                    
                    <p className="font-medium">
                        {myUserQuery.data?.email}
                    </p>
                </div>
            </div>
            <div className="xs:flex items-start my-4">
                <div className="flex-2">
                    <p className="font-light">{'Secondary emails'}:</p>
                </div>
                <div className="flex-5 grid grid-cols-[10px_auto_auto_1fr_1fr] gap-3 w-screen items-center">
                        {secondaryEmailQuery.data?.map?.((e, idx) => (
                            <SecondaryEmail 
                                key={idx} 
                                email={e} 
                                idx={idx+1} 
                                handleDelete={deleteEmail}
                                handleMakePrimary={modPrimaryEmail}
                            />
                        ))}
                        {   view === VIEW.EDIT_SECONDARY && (
                            <>
                                <span className='font-semibold mr-2'>
                                    {(secondaryEmailQuery.data?.length || 0) + 1}. 
                                </span>
                                <form id='secondaryEmailForm' onSubmit={handleFormSubmit}>
                                    <BasicInput 
                                        className='min-w-[16rem]'
                                        {...register('email')}
                                    />
                                </form>
                                <div className='w-1'/>
                                <div className="w-1" />
                                <div className="w-1" />
                            </>
                        )}
                </div>
            </div>
            <div className="flex gap-2 -mb-5">
                <BasicGrayButton className='' onClick={handleBack}>
                    Back
                </BasicGrayButton>
                {
                    view === VIEW.DEFAULT ? (
                        <>
                            <BasicBlueRectButton customSize onClick={() => setView(VIEW.EDIT_SECONDARY)}>
                                Add secondary email
                                <PlusIcon />
                            </BasicBlueRectButton>
                        </>
                    ) : (
                        <>
                            <BasicBlueRectButton customSize onClick={submitForm}>
                                Submit 
                            </BasicBlueRectButton>
                            <BasicGrayButton onClick={() => setView(VIEW.DEFAULT)}>
                                Cancel
                            </BasicGrayButton>
                        </>
                    )
                }
            </div>
        </div>
    );
};



const SecondaryEmail = ({email, idx, handleDelete, handleMakePrimary}) => {
    function deletePressed() {handleDelete(email)}
    function makePrimaryPressed() {handleMakePrimary(email)}

    return (
        <>
            <span className='font-semibold mr-2'>{idx}. </span>
            <span>{email.name}</span>
            <div className={`badge badge-xs ${email?.emlverified === '1' ? 'badge-success' : 'badge-warning'}`}>
                {email?.emlverified === '1' ? 'Verified' : 'unverified'}
            </div>
            <div className={'btn btn-secondary btn-sm w-fit !text-2xs lg:!text-xs'} onClick={makePrimaryPressed}>
                Make Primary 
            </div>
            <div className="btn btn-sm w-fit" onClick={deletePressed}>
                <XIcon 
                    className='font-black text-xl '
                />
            </div>
        </>
)}