import { PageTitle } from "@/components/Label/PageTitle";
import { UserManagementPage } from "@/components/pages/UserManagementPage";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";

const BrandUser = () => {
  const orgContextQuery = useCurrentOrgContext();

  return (
    <div className="flex flex-col gap-10">
      <PageTitle>
          Members
      </PageTitle>
      <UserManagementPage standardCommunity={false} orgid={orgContextQuery.data?.orgid} />
    </div>
  
  );
}

export default BrandUser;