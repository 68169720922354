import { CardActions, CardContent, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import useAllBrands from '@/hooks/state/brands/useAllBrands';
import useSetMyBrands from '@/hooks/state/brands/useSetMyBrands';
import useMyUserData from '@/hooks/state/useMyUserData';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { WhiteCard } from '../../../../../../components/Cards';
import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';

function BrandPreference({ text }) {
  const [brands, setBrands] = useState([]);
  const [brandPreferences, setBrandPreferences] = useState([]);
  // const [otherBrandPreferences, setOtherBrandPreferences] = useState('');
  const brandsQuery = useAllBrands();
  const userQuery = useMyUserData();
  const brandsMutation = useSetMyBrands({
    onSuccess: () => 
      SuccessToast.fire({ text: text.succeedToUpdateBrandPreference}),
    onError: () => FailPopup.fire({text: text.failedToUpdateBrandPreference})
  });

  useEffect(() => {
    if (brandsQuery.isSuccess)
      setBrands(brandsQuery?.data);
  }, [brandsQuery?.data]);

  useEffect(() => {
    if (userQuery.isSuccess ) 
      setBrandPreferences(userQuery?.data?.mybrands);
  }, [userQuery?.data]);

  const onUpdateBrandPreference = () => {
    console.log(brandPreferences);
    const params =  brandPreferences;
    console.log(params);
    brandsMutation.mutate(params);
  }

  const onPreferenceChanged = (brand_id, checked) => {
    checked ? brandPreferences.push(brand_id) : brandPreferences.splice(brandPreferences.indexOf(brand_id), 1)
    setBrandPreferences(brandPreferences);
  }

  return (
    <div className="lg:flex mb-12">
      <div className="flex-4">
        <div className="mt-0.5">
          <WhiteCard>
            <CardContent>
              <p className="mb-4 text-2xl font-semibold">{text.brandPreferenceTitle}</p>
              <Divider />
              <p className="mt-7.5 leading-1.75 text-gray-subText">{text.brandPreferenceDescription}</p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-12.5">
                { brandsQuery.isSuccess && userQuery.isSuccess && brands.sort((l, r) => l.name.toUpperCase() < r.name.toUpperCase() ? -1 : 1).map(brand => (
                  <div key={brand.id}>
                    <input type="checkbox" defaultChecked={brandPreferences.includes(brand.id)}  className="checkbox" onChange={(e) => onPreferenceChanged(brand.id, e.target.checked)}/>
                    <span className={"ml-2"}>{brand.name}</span>
                  </div>
                ))}
            </div>
            </CardContent>
            <CardActions>
              <div className="flex-7 flex justify-end">
                <BasicBlueRectButton onClick={() => onUpdateBrandPreference()}>{text.update}</BasicBlueRectButton>
              </div>
            </CardActions>
          </WhiteCard>
        </div>
      </div>
      <div className="flex-1 lg:block hidden mt-7 ml-12">
      </div>
    </div>
  );
}

export default BrandPreference;
