import React, { useState, useEffect, useCallback } from 'react';
import { NewsCard } from '../../../../components/Cards';

import { useTranslations } from '../../../../hooks/translations';
import { useAppContext } from '../../../../context/AppContext';
import { CuratorWidget } from '../Home/components/CuratorWidget';
import { PageTitle } from '@/components/Label/PageTitle';
import { DefaultCuratorFeed } from '@/views/pages/Main/Home/components/Curator/DefaultCuratorFeed';

function News() {
  const text = useTranslations('news');

  return (
    <div className="w-full min-h-content bg-base-200 flex justify-center py-12">
      <div className="container">
        <div className="w-full h-full px-4 sm:px-6 xl:px-8">
          <PageTitle>{text.title}</PageTitle>
            <DefaultCuratorFeed initialRows={4} showLoadMore={true}/>
            {/* <CuratorWidget feedId="2da72240-96c1-4050-b897-dfe742d1c9bf" /> */}
        </div>
      </div>
    </div>
  );
}

export default News;
