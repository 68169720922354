import { BrandPrefTurnoutChart } from '@/components/Charts/PieCharts/BrandPrefTurnoutChart';
import { BasicSearchBar } from '@/components/Input/BasicSearchBar';
import { BasicStartEndDate } from '@/components/Input/BasicStartEndDate';
import { TableSkeleton } from '@/components/Skeletons/TableSkeleton';
import { BrandPrefReportTable } from '@/components/Tables/BrandPrefReportTable/BrandPrefReportTable';
import useAllBrands from '@/hooks/state/brands/useAllBrands';
import useAllOrgs from '@/hooks/state/organization/useAllOrgs';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import { arrayToObject, between, roundTo } from '@/utils';
import { useEffect, useMemo, useState } from 'react';
import { TopPlacements } from './components/TopPlacements';

export const OrgBrandPrefReport = ({ps, ...props}) => {
    const [timeRange, setTimeRange] = useState({start: '', end: ''})
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [voteData, setVoteData] = useState({
        possibleVotes: 0,
        timelyPrefs: 0,
        outdatedPrefs: 0,
        unsubmittedPrefs: 0,
        topFive: [],
        timelyTopFive:[],
        prefs: {},
    })
    const orgQuery = useOrgWithId({org_id: selectedOrg?.id, showMembers: true, recursive: 1})
    const brandPrefs = useAllBrands()
    const orgsQuery = useAllOrgs({flat: true})
    const useTimespan = !!timeRange.start && !!timeRange.end
    const searchOptions = useMemo(() => {
        if (!orgsQuery.data) return []
        return orgsQuery.data.map(org => ({label: org.name, value: org}))
    },[orgsQuery.data])

    useEffect(() => {
        calculateBrandPrefs()
    },[orgQuery.data, brandPrefs.data, timeRange])


    function calculateBrandPrefs() {
        if (!selectedOrg || !orgQuery.data || !brandPrefs.data) return
        for (const brand of brandPrefs.data) {
            brand.votes = 0
            brand.votesTimespan = 0
        }
        const brandPrefObj = arrayToObject(brandPrefs.data, 'id')
        const members = orgQuery.data?.[0]?.members || []
        let timelyPrefs = 0
        let unsubmittedPrefs = 0
        let outdatedPrefs = 0
        let top5 = []
        let timelyTop5 = []
        for (const member of members) {
            if (member.brandsmodified == null) console.log(member)
            if (member.brands?.length === 0) {
                unsubmittedPrefs++   
                continue
            }
            if (useTimespan) {
                if (between(Number(member?.brandsmodified), timeRange.start, timeRange.end)) {
                    timelyPrefs++
                    member.brands.forEach((b) => {
                        if (!brandPrefObj[b]?.votes) brandPrefObj[b].votes = 0
                        brandPrefObj[b].votesTimespan++
                    })
                } else {
                    outdatedPrefs++
                }
            } 
            member.brands.forEach((b) => {
                if (!brandPrefObj[b]?.votes) brandPrefObj[b].votes = 0
                brandPrefObj[b].votes++
            })
            
        }
        const sorted = 
            Object.values(brandPrefObj)
                .sort(comparePrefs)
        top5 = sorted.slice(0,5)
        sorted
            .forEach((pref, idx) => {
                pref.percentTotal = roundTo(pref.votes / (members.length - unsubmittedPrefs))
                pref.rank = idx + 1 
        })
        if (useTimespan) {
            const timelySorted =  
                Object.values(brandPrefObj)
                    .sort(compareTimespanPrefs)
            timelyTop5 = timelySorted.slice(0,5)
            timelySorted.forEach((pref, idx) => {
                const percentTimespan = 
                    timelyPrefs > 0 
                        ? pref.votesTimespan / timelyPrefs
                        : timelyPrefs
                pref.percentTimespan = 
                roundTo(percentTimespan)
                    pref.timespanRank = idx + 1
                })
        }

        setVoteData({
            possibleVotes: members?.length,
            timelyPrefs,
            outdatedPrefs,
            unsubmittedPrefs,
            topFive: top5,
            timelyTopFive: timelyTop5,
            prefs: brandPrefObj
        })
    }

    function onSearch(e) {
        setSelectedOrg(e)
    }

    function onSetTimeRange(val) {
        if (!val) return
        const start = new Date(val.start)?.getTime() / 1000
        const end = new Date(val.end)?.getTime() / 1000
        setTimeRange({start, end})
    }
    
    function comparePrefs(a, b) {
        if (a.votes < b.votes) return 1
        if (a.votes > b.votes) return -1
        return 0
    }

    function compareTimespanPrefs(a, b) {
        if (a.votesTimespan < b.votesTimespan) return 1
        if (a.votesTimespan > b.votesTimespan) return -1
        return 0
    }

    return (
        <div className='form-control'>
            <div className="flex gap-10 h-50">
                <div className="form-control flex-1 justify-evenly bg-base-100 p-4 rounded-xl gap-4">
                    <div className="form-control h-[45px] -mt-6">
                        <p className="ml-3 text-sm text-gray-subText">Selected Community</p>
                        <div className='indicator w-full'>
                            <BasicSearchBar
                                liveChanges={false}
                                className={'w-full bg-base-150 h-[45px]'}
                                placeholder='Search communities'
                                optionsLabel='possible results'
                                options={searchOptions}
                                onChange={onSearch}/>
                        </div>
                    </div>
                        <BasicStartEndDate
                            className={'h-[45px] w-full'}
                            onChange={onSetTimeRange} 
                            inputClassName={'w-full text-sm h-[45px] bg-base-150'}
                        />
                </div>
                <div className=" flex-2 bg-base-100 p-4 rounded-xl h-full flex justify-between gap-20">
                    <div className='min-w-fit w-72 h-full'>
                        {  selectedOrg 
                            ?   <BrandPrefTurnoutChart
                                    showTimely={useTimespan}
                                    timely={voteData.timelyPrefs}
                                    untimely={voteData.outdatedPrefs}
                                    total={voteData.possibleVotes}
                                    unsubmitted={voteData.unsubmittedPrefs}
                                />
                            :   <ChartSkeleton/>
                        }
                    </div>
                    {( selectedOrg ? (
                        <div className="group mr-auto">
                            <div className={`swap hover:cursor-default ${useTimespan ? ' group-hover:swap-active' : ''}`}>
                                    <TopPlacements className='swap-off' title={'Top Voted Brands'} data={voteData.topFive}/>
                                    <TopPlacements timely className='swap-on' title={'Top Voted Brands (in time range)'} data={voteData.timelyTopFive}/>
                            </div>
                        </div>
                    ) : (
                        <PlacementSkeleton/>
                    )

                    )}
                </div>
            </div>


            <div className="form-control mt-10">
                {   !selectedOrg && 
                    <TableSkeleton hideIcons/>
                }
                {   selectedOrg &&      
                    <BrandPrefReportTable
                        numBrands={brandPrefs.data?.length}
                        data={Object.values(voteData.prefs)}
                        useTimespan={useTimespan}
                    />
                }
            </div>
        </div>
    );
};


const PlacementSkeleton = () => (
    <div className='form-control gap-2 h-full mr-auto'>
        <div className="skeleton flex-1 w-72 h-5"></div>
        <div className="skeleton flex-1 w-72 h-5"></div>
        <div className="skeleton flex-1 w-72 h-5"></div>
        <div className="skeleton flex-1 w-72 h-5"></div>
        <div className="skeleton flex-1 w-72 h-5"></div>
    </div>
)

const ChartSkeleton = () => (
    <div className="skeleton rounded-full aspect-square h-full">
    </div>
)