//./components/UserColumnDefs.tsx
import { RemoveUserButton } from "@/components/Buttons/RemoveUserButton";
import { RemoveUserIcon } from "@/components/Icons";
import { PERMISSION } from "@/constants";
import { getDateString } from "@/utils";
import { userCanKickUser } from "@/utils/userUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { UserCell } from "../Cells/UserCell";
import { UserStatusCell } from "../Cells/UserStatusCell";
import { oneOrMoreFilterFn } from "../filterFunctions/oneOrMoreFilterFn";
import { OrgCell } from "@/components/Tables/Cells/OrgCell";
import { IndentedCell } from "@/components/Tables/Cells/IndentedCell";
import { BasicToggle } from "@/components/Input/BasicToggle";

// createColumnHelper helps us create columns with maximum type safety.
// we assign the type person so that it knows the structure for our data
const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
export const nonBusinessUserColumnHelper = [
  columnHelper.accessor((row) => row?.id, {
    id: "ID",
    size: 10,
    meta: { hiddenCol: true },
    filterFn: stringFilter,
    cell: ({ getValue }) => getValue(),
    header: (info) => <span>ID</span>,
  }),
  columnHelper.accessor((row) => row?.name || '', {
    id: "Member",
    size: 10,
    filterFn: stringFilter,
    cell: ({ row, table }) => (
      <IndentedCell indentLevel={row.depth}>
        <UserCell
          row={row.original}
          onClick={() => table.options.meta?.handleProfileClicked(row.original)}
        />
      </IndentedCell>
    ),
    header: ({ row, getValue }) => <span>MEMBER</span>,
  }),
  columnHelper.accessor((row) => row?.['phone number'] || '', {
    id: "Phone",
    size: 10,
    filterFn: stringFilter,
    cell: ({ row, getValue }) => getValue(),
    header: ({ row, getValue }) => <span>PHONE</span>,
  }),
  columnHelper.accessor((row) => row?.type || '', {
    id: "Type",
    size: 60,
    filterFn: stringFilter,
    cell: ({ row, getValue }) => <span>{getValue()}</span>,
    header: () => <span>TYPE</span>,
  }),
  columnHelper.accessor((row) => getRoles(row), {
    id: "Roles",
    size: 60,
    filterFn: oneOrMoreFilterFn('roles'),
    cell: ({ row, getValue }) => <Roles roles={getValue()} />,
    header: () => <span>ROLES</span>,
  }),
  columnHelper.accessor((row) => strToStatus(row.status), {
    id: "Status",
    size: 120,
    filterFn: stringFilter,
    cell: ({ row, getValue }) => <UserStatusCell status={getValue()} />,
    header: () => <span>STATUS</span>,
  }),
  columnHelper.accessor((row) => row?.timestamp || '', {
    id: "Member Since",
    size: 60,
    enableColumnFilter: false,
    cell: ({ row, getValue }) => (
      <span className="whitespace-nowrap">{getDateString(getValue())}</span>
    ),
    header: () => <span>MEMBER SINCE</span>,
  }),
  columnHelper.display({
    id: "Admin",
    size: 60,
    enableColumnFilter: false,
    cell: ({ table, row }) => (
      <AdminToggle
        row={row}
        meta={table.options.meta}
      />
    ),
    header: () => <span>ADMIN</span>,
  }),
  // columnHelper.display({
  //   id: "Action",
  //   size: 60,
  //   enableColumnFilter: false,
  //   cell: ({ table, row }) => (
  //     <Actions
  //       row={row}
  //       meta={table.options.meta}
  //     />
  //   ),
  //   header: () => <span>ACTION</span>,
  // }),
];

const getRoles = (row) => {
  const roles = [];
  if (row?.FIN === 'Approved') roles.push('FIN');
  if (row?.Ambassador === 'Approved') roles.push('Ambassador');
  if (row?.['Social Media Influencer'] === 'Approved') roles.push('Social Media Influencer');
  if (row?.Guardian === 'Approved') roles.push('Guardian');
  row.roles = roles;
  return roles;
};

const AdminToggle = ({ row, meta }) => {
  const {
    handleAdminToggle,
    permission } = meta
  const canModifyUserAdmin =  
    parseInt(permission) >= parseInt(PERMISSION.MASTER_ADMIN) 
    && row.original.type !== "Master Admin"
  return canModifyUserAdmin && (
    <div className="form-control">
      <p className="font-medium">Admin</p>
      <BasicToggle
        type="checkbox" className="toggle-little -mt-2" defaultChecked={row.original.admin} readOnly onChange={() => handleAdminToggle(row.original)} />
    </div>
  )
}

const Actions = ({ row, meta }) => {
  const {deleteUser,permission} = meta;
  return (
    <div className="flex justify-end items-center gap-2">
      <RemoveUserButton
        onClick={() => deleteUser(row.original.orgid, row.original.id)}
        disabled={!userCanKickUser(row.original, permission)}
      >
        <RemoveUserIcon />
      </RemoveUserButton>

    </div>
  );
};

const Roles = ({ roles }) => roles?.map?.((role) => (
  <p key={role}>{role}</p>
));

const strToStatus = (str) => str === '1' ? 'Active' : 'Inactive';
