import { postPromise } from "@/apis/base";
import { addImage } from "@/apis/base/image/addImage";
import { default as config } from "@/apis/config";
import { UPLOAD_TYPE } from "@/constants";
import { convertObjFieldsToString, getAvatarUrl, getBannerUrl, getEventInventoryUrl, getEventUrl, getLogoUrl, getProductUrl, getSplashUrl, rangeArray } from "@/utils";
import axios from "axios";

const usePresignedUrl = (api, uploads, type, url) => {
    console.log('using url');
    const file = uploads.find(u => u.type === type).file
    const options = { headers: { 'Content-Type': file.type}}
    return api.put(url,file,options)
}

const usePresignedUrlMultiupload = (putFn, uploads, type, url) => {
    const promises = []
    console.log(uploads);
    uploads.filter(u => u.type === type)
        .map((u, i) => 
            promises.push(putFn(url[i], u.file))
        )
    return promises || []
}

export const uploadImages = async ({
    uploads, 
    userID=0, 
    orgID=0, 
    productID=0, 
    eventID=0, 
    inventoryID=0 }) => {

    const uploadFiles = uploads.filter(u => u.file != null)
    const uploadRequests = uploads.map(u => ({type: u.type, suffix: u.suffix}))

    const api = axios.create();
    function putPromise(url, file, options) {
        return new Promise((resolve, reject) => {
            api.put(url, file, options).then(res => {
                resolve(url)
            })
        })
    }
    const params = convertObjFieldsToString({
        uploads: uploadRequests, userID ,orgID, productID ,eventID ,inventoryID
    })

    return new Promise((resolve, reject) => {
        postPromise(config.API_GATEWAY_URL + '/upload-image',
           params
           ).then(res => {
            //    console.log("presigned urls response ",res);
               const body = JSON.parse(res.body).urls
            //    console.log("uploads",uploads);
               const promises = []
                if (body.bannerUrl?.length > 1 ) {
                    // console.log(body.bannerUrl);
                    // console.log('len',body.bannerUrl?.length);
                    promises.push(usePresignedUrl(api, uploads, 'banner', body.bannerUrl))

                }
                if (body.logoUrl?.length > 1 )                       
                    promises.push(usePresignedUrl(api, uploads, 'logo', body.logoUrl))
                if (body.splashUrl?.length > 1)
                       promises.push(usePresignedUrl(api, uploads, 'splash', body.splashUrl))
                if (body.avatarUrl?.length > 1 )
                       promises.push(usePresignedUrl(api, uploads, 'avatar', body.avatarUrl))
                if (body.productUrls?.length > 0)
                    promises.push(...(usePresignedUrlMultiupload(putPromise, uploads, 'product', body.productUrls ?? [])))  
                if (body.eventUrls?.length > 0)
                    promises.push(...(usePresignedUrlMultiupload(putPromise, uploads, 'event', body.eventUrls ?? [])))
                if (body.eventInventoryUrls?.length > 0)
                    promises.push(...(usePresignedUrlMultiupload(putPromise, uploads, 'eventInventory', body.eventInventoryUrls ?? [])))
               resolve(Promise.all(promises))
       })
    })
};

export const uploadAvatar = async (client, file, userID) => {
    const suffix = '.' + file.name.split('.')[1]
    return new Promise((resolve, reject) => {
        uploadImages( { userID, uploads: [{type: UPLOAD_TYPE.AVATAR, suffix, file: file}] })
        .then(res => {
            setTimeout(() => {
                client.invalidateQueries({queryKey:['mock',getAvatarUrl(userID)]})
            },0)
            console.log('upload result', res);
            resolve(res)
        })
    })
}

export const uploadOrgFlair = async ({client, bannerFile=null, splashFile=null, logoFile=null, orgID, userID}) => {
    const invalidations = []
    if (!orgID) throw new Error('No orgID provided');
    if (!bannerFile && !splashFile && !logoFile) throw new Error('No files provided');
    const uploads = []
    if (bannerFile) {
        uploads.push({type: UPLOAD_TYPE.ORG_BANNER, suffix: fSuf(bannerFile), file: bannerFile})
        invalidations.push(getBannerUrl(orgID))
    }
    if (splashFile) {
        uploads.push({type: UPLOAD_TYPE.ORG_SPLASH, suffix: fSuf(splashFile), file: splashFile})
        invalidations.push(getSplashUrl(orgID))
    }
    if (logoFile) {
        uploads.push({type: UPLOAD_TYPE.ORG_LOGO, suffix: fSuf(logoFile), file: logoFile})
        invalidations.push(getLogoUrl(orgID))
    }
    return new Promise((resolve, reject) => {
        uploadImages({ orgID, uploads, userID })
        .then(res => {
            invalidations.forEach(i => client.invalidateQueries({queryKey:['mock',i]}))
            console.log('upload result', res);
            resolve(res)
        })

    })
}

export const uploadProductImages = async ({client, productID, userID, uploads}) => {
    if (!productID) throw new Error('No productID provided');
    if (!uploads || uploads?.length < 1) throw new Error('No files provided');
    return new Promise((resolve, reject) => {
        uploadImages(
            { 
                productID, 
                uploads: uploads.map(u => ({
                    type: UPLOAD_TYPE.PRODUCT, 
                    suffix: fSuf(u), 
                    file: u,
                })), userID 
            }
        ).then(res => {
            rangeArray(0, uploads.length - 1).forEach( i => 
                client.invalidateQueries({queryKey:['mock',getProductUrl(productID,i)]})
            )
            const successfulUrls = res?.map(r => r?.split('?')[0]?.split('amazonaws.com/')[1])
            for (const u of successfulUrls) {
                addImage({objname: 'wishlist', nodeid:productID, imagename:u})
                client.invalidateQueries({queryKey:['wishlist']})
            }
            resolve(successfulUrls)
        
        })
    })
}

export const uploadEventImages = async ({client, orgID, eventID, userID, uploads}) => {
    if (!eventID) throw new Error('No eventID provided');
    if (!uploads || uploads?.length < 1) throw new Error('No files provided');
    return new Promise ((resolve, reject) => {
        uploadImages(
            { 
                eventID, 
                uploads: uploads.map(u => ({type: UPLOAD_TYPE.EVENT, suffix: fSuf(u), file: u})), 
                userID,
                orgID 
            }
        ).then(res => {
            rangeArray(0, uploads.length - 1).forEach( i => 
                client.invalidateQueries({queryKey:['mock', getEventUrl(eventID,i)]})
            )
            resolve(res)
        })
    })
}

export const uploadEventInventoryImages = async ({client, orgID, eventID, inventoryID, userID, uploads}) => {
    if (!orgID) throw new Error('No orgID provided');
    if (!eventID) throw new Error('No eventID provided');
    if (!uploads) throw new Error('No files provided');
    return new Promise ((resolve, reject) => {
        uploadImages(
            { 
                eventID, 
                inventoryID,
                uploads: uploads.map(u => ({type: UPLOAD_TYPE.EVENT_INVENTORY, suffix: fSuf(u), file: u})), 
                userID,
                orgID 
            }
        ).then(res => {
            rangeArray(0, uploads.length - 1).forEach( i => 
                client.invalidateQueries({queryKey:['mock',getEventInventoryUrl(eventID, inventoryID,i)]})
            )
            resolve(res)
        })
    })
}


const fileToFormData = (file) => {
    const formData = new FormData();
    formData.append('src', file);
    return formData;
}

const fSuf = (file) => {
    console.log('file to split is', file);
    return '.' + file.name.split('.')[1] }