
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getMyBaseRoleData } from '@/apis/base/applications/getMyBaseRoleData';
import useRoleEnum from '../enum/useRoleEnum';


export default function useGetMyBaseRoleData() {
    const roleQuery = useRoleEnum()
    const query = useQuery({
        queryKey: ['context','role',ROUTE_IDS.GET_MY_BASE_ROLE_DATA],
        queryFn: () => getMyBaseRoleData({'roleids[]': roleQuery.data?.allRoles}),
        staleTime: MILLISEC.MIN_15,
    });
    return query;
}