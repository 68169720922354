import Swal from "sweetalert2"

export const InfoPopup = Swal.mixin({
    icon: 'info',
        // showClass: {
        //     popup: 'animate-in-popup',
        // },
        customClass: {
            actions: 'w-full flex flex-nowrap',
            confirmButton: 'btn btn-secondary whitespace-nowrap',
            cancelButton:  'btn btn-neutral ml-2 whitespace-nowrap',    
            // container: 'h-[40rem]',
            icon: 'mt-[1rem]'
        },
        backdrop:'true',
        showConfirmButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        position: 'center',
    })
