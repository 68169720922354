import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getPopup } from './getPopup';


export default function usePopup({eventid, popupid}) {
    const query = useQuery({
        queryKey: ['popup', popupid, ROUTE_IDS.GET_POPUP],
        queryFn: (params) => getPopup({eventid, popupid}),
        enabled: !!eventid && !!popupid,
        staleTime: MILLISEC.MIN_15,
        select: (data) => {
            
            return data
        },
    });
    return query;
}