
import React, { useRef, useState } from "react";

import { BasicBackButton } from "@/components/Buttons/BasicBackButton";
import { EditShipmentDialog } from "@/components/Dialog/EditShipmentDialog";
import { EditIcon } from "@/components/Icons";
import { PageTitle } from "@/components/Label/PageTitle";
import { OrgTreeBDShipmentReport } from "@/components/Tables/OrgTreeBDShipmentReport/OrgTreeBDShipmentReport";
import { ShipmentsTable } from "@/components/Tables/ShipmentsTable/ShipmentsTable";
import { useReqShipPairs } from "@/hooks/state/donation/aggregateHelpers/useReqShipPairs";
import useBDEventByID from "@/hooks/state/donation/useBDEventByID";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import useOrgTree from "@/hooks/state/organization/useOrgTree";
import { useTranslations } from "@/hooks/translations";
import { openDialogWithRef, resetFormWithId } from "@/utils";

const VIEWS = {
  DEFAULT: 0,
  TREE: 1,
}

const DonationShipments = (props) => {
  const [view, setView] = useState(VIEWS.DEFAULT)
  const dialogRef = useRef(null)
  const { history, match } = props;
  const text = useTranslations("donationSummary");
  const {eventid, orgid} = match.params || {};

  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [inlineEditing, setInlineEditing] = useState(false)

  const ctx = useCurrentOrgContext().data
  const orgTreeQuery = useOrgTree({orgid: selectedRow.orgid})
  const eventQuery = useBDEventByID({eventid, orgid})
  const event = eventQuery?.data

  const {pairs, userNames} = useReqShipPairs({eventid, brandid: orgid})
  const canEditRequest = ctx?.isUICContext || ctx?.standardCommunity
  const canEditShipment = (ctx?.isUICContext || ctx?.brand) || 
                          (ctx?.processor && event.processor === ctx.orgid)

  const handleBack = () => {
    if (view === VIEWS.TREE) return setView(VIEWS.DEFAULT)
    history.goBack(1)
  }
  
  const onTreeClicked = (row) => {
    setSelectedRow(row)
    setView(VIEWS.TREE)
  }

  const onEditClicked = (row) => {
    setSelectedRequest({...row, name: row?.orgname})
    openDialogWithRef(dialogRef)
    resetFormWithId('shipmentForm')
  }
  function toggleInlineEditing() {
      setInlineEditing(prev => !prev)
  }
  return (
      <div>
        <PageTitle>
          Shipments
          { view === VIEWS.TREE}
          <button className="btn btn-primary btn-little" onClick={toggleInlineEditing}>
            Toggle Inline Editing
            <EditIcon/>
          </button>
        </PageTitle>
        <div className="flex justify-between items-center py-3">
        <BasicBackButton onClick={handleBack} className={'absolute'} label={view === VIEWS.DEFAULT ? 'Back to events list' : 'Back to Shipments'}/>
          {/* <ActionButton onClick={() => handleBack()}>
            <ArrowBack />
          </ActionButton> */}
        </div>
        { view === VIEWS.DEFAULT ? (
          <ShipmentsTable 
              {...{
                userNames,
                event, 
                pairs, 
                canEditRequest,
                canEditShipment,
                inlineEditing,
                onTreeClicked, 
                onEditClicked,
                className:'table-xs'
              }}
            />
        ) : (
          <OrgTreeBDShipmentReport
            inlineEditing={inlineEditing}
            eventid={event.id}
            brandorgid={event.orgid}
            orgTree={orgTreeQuery.data}  
          />
        )}
          <EditShipmentDialog  
          {...{
            event,
            dialogRef,
            canEditRequest,
            canEditShipment,
            row: selectedRequest,
          }}
          />
      </div>
  );
};

export default DonationShipments;
