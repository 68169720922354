import React from 'react';


export const BasicRedRectButton = ({className, customSize, disabled, ...props}) => {
    return (
        <button {...props} className={`btn bg-red-button disabled:opacity-30 text-lg  text-white
        hover:cursor-pointer rounded-md
        ${disabled ? 'opacity-40 pointer-events-none select-none' : ''}
         ${customSize ? '' : 'px-5 py-3 lg:min-w-[10rem]'}
        ${className}
        `}>
            {props.children}
        </button>
    );
};

