import { addCommas } from '@/utils';
import React, { Fragment } from 'react';


export const TopPlacements = ({title, data, timely, className}) => (
    <div className={`${className}`}>
        <p className='font-bold'>{title}</p>  
        <div className={` grid grid-cols-[40px,auto,auto]`}>
            { data.map((b, idx) => (
                <Fragment key={b.id}>
                    <p className='font-bold text-xl'>{idx + 1}. </p>
                    <p className='ml-2 '>{b.name}</p>
                    <p className='ml-5  font-bold text-lg  text-green-verdant'>
                        {addCommas(timely ? b.votesTimespan : b.votes)} <span className='font-medium text-sm'>votes</span>
                    </p>
                </Fragment>
            ))}
        </div>  
    </div>
)

