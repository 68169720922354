
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getBDEventByID } from '@/apis/base/donation/getBDEventByID';
import { prefetchQueries } from '@/utils/queryUtils';
import { get } from 'react-hook-form';


export default function useBDEventByID({eventid, orgid,...props}) {
    const query = useQuery(getBDEventByIDConfig({eventid, orgid, ...props}));
    return query;
}

export const getBDEventByIDConfig = ({eventid, orgid, ...props}) => queryOptions({
    queryKey: ['bd','event', eventid, orgid, ROUTE_IDS.GET_BD_EVENT_BY_ID],
    queryFn: () => getBDEventByID({eventid, orgid}),
    staleTime: MILLISEC.MIN_15,
    ...props
})

export const prefetchBDEventByID = (client, eventid, orgid) => {
    return prefetchQueries(
        client,
        [getBDEventByIDConfig({eventid, orgid})] )
}
