import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { getOrgWishlistReport } from '@/apis/base/wishlist/getOrgWishlistReport';
import { arrayToObject } from '@/utils';


export default function useOrgWishlistReport({orgid, enabled=true}) {
    const query = useQuery(orgWishlistReportConfig({orgid, enabled}));
    return query;
}

export const orgWishlistReportConfig = ({orgid, enabled=true}) => queryOptions({
    queryKey: ['wishlist', 'org', orgid, ROUTE_IDS.GET_ORG_WISHLIST_REPORT],
    queryFn: (params) => getOrgWishlistReport({orgid}),
    staleTime: MILLISEC.MIN_15,
    enabled: enabled && !!orgid,
    select
})

function select(data) {
    const ret = {
        ...data,
        wishlist: arrayToObject(data?.wishlist, 'productid')
    }

    return ret
}