import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { DeleteCell } from "../Cells/DeleteCell";


const columnHelper = createColumnHelper();


export const BrandPrefCols = [
columnHelper.accessor((row, idx) => idx, {
    id: "No",
    size: 100,
    cell: (info) => <span className="pl-2">{info.getValue()}</span>,
    header: (info) => <span className="pl-2">NO</span>,
  }),
columnHelper.accessor((row) => row.name, {
    id: "Brand",
    size: 1000,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>BRAND</span>,
  }),
columnHelper.display( {
    id: "Action",
    size: 10,
    cell: ({row, table}) => <DeleteCell 
    onDelete={() => 
        table.options.meta.deleteBrand(row.original.id)
    } />
    ,
    header: (info) => <span>ACTION</span>,
  }),
]