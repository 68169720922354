import { MILLISEC } from '@/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useCookies } from 'react-cookie';
import { login } from '@/apis/base/auth/login';
import { sendOrgInvite } from '@/apis/base/invites/sendOrgInvite';

export default function useSendOrgInvite( {...props }) {
  const client = useQueryClient();
  const loginData = useMutation({
    mutationKey: [ROUTE_IDS.SEND_ORG_INVITE],
    mutationFn: sendOrgInvite,
    retry: false,
    staleTime: MILLISEC.MIN_15,
    ...props,
  });
  return loginData;
}
