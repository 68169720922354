import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getInviteData } from '@/apis/base/invites/getInviteData';

export default function useGetInviteData({ code, props }) {
  const inviteData = useQuery({
    queryKey: [ ROUTE_IDS.GET_INVITE_DATA, code],
    queryFn: () => getInviteData({ code: code }),
    staleTime: MILLISEC.MIN_15,
    notifyOnChangeProps: ['data'],
    retry: false,
    enabled: code != null && code != "",
    ...props
  });
  return inviteData;
}
