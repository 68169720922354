import { removeOrgAdmin } from '@/apis/base/admin/removeOrgAdmin';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useRemoveOrgAdmin(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.REMOVE_ORG_ADMIN],
        mutationFn: removeOrgAdmin,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data,req) => {
            if (data?.status === 'organization admin not removed.') 
                throw new Error(data.status)
            // client.invalidateQueries({queryKey: ['context']})
            client.invalidateQueries({queryKey: ['org', req.orgid]})
            client.invalidateQueries({queryKey: ['org',[req.orgid]]})
            onSuccess && onSuccess(data,req)
        },
        ...otherProps
    });
    return mutation;
}