import { Link } from 'react-router-dom';

import uicLogo from '/assets/images/u11square.png';
import footerLogo from '/assets/images/logo-united-hearts-for-canada-2.png';

import { useTranslations } from '../hooks/translations';

const Footer = () => {
  const text = useTranslations('footer');

  return (
    <footer className="flex flex-col  items-center justify-center bg-black h-59.5 text-gray-400 ">
      <div className="flex items-baseline">
        <span className="text-sm font-semibold">{text.reservedMark}</span>
        {/* <img
          className="h-4 ml-1 mr-1"
          src={uicLogo}
          alt="uic"
        /> */}
        <span className="text-sm font-semibold">&nbsp;{text.reserved}</span>
      </div>
      {/* <p className="text-xs font-medium mt-5">
        <Link to="/terms" className="hover:underline">
          {text.terms}
        </Link>{' '}
        |{' '}
        <Link to="/privacy" className="hover:underline">
          {text.privacy}
        </Link>
      </p> */}
      
    </footer>
  );
};

export default Footer;
