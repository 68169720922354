import React, { useMemo } from 'react';


export const BasicTooltip = ({className, tooltipHex, tooltipClassName='', label="", ...props}) => {
    const classes = useMemo(() => {
        const splitClasses = tooltipClassName?.split(' ')
        return [...splitClasses?.map?.(cls => 'before:' + cls)]?.join?.(' ')
    }
        , [tooltipClassName]
    )

    return (
        <div 
            className={`
                    [--tooltip-color:${tooltipHex}]
                    [--tooltip-tail:10px]
                    relative
                    before:shadow-card
                    tooltip
                    ${className}
                    ${classes}
                `} 
            data-tip={label}
        >
            <div className="text-left">
                {props.children}
            </div>
        </div>
    );
};

