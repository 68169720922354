import { useEffect, useRef } from "react"

export const BasicCheckbox = ({ 
    onUnmount = () => {}, label, 
    containerProps={},
    indeterminate=false, 
    name, 
    className, 
    ...rest }) => {
    const ref = useRef(null)
    useEffect(() => {
        if(ref.current) 
            ref.current.indeterminate = indeterminate
    },[indeterminate])
    return (
        <div className={`flex flex-row justify-between ${containerProps.className}`} {...containerProps}>
            { label && 
            <p  className="w-1/2">{label}</p>
            }
            <div className="form-control w-3/4">
                <label className="label p-0 cursor-pointer w-fit">
                    <input {...rest} name={name} type="checkbox" 
                        ref={ref}
                        className={`
                            -ml-1
                            checkbox 
                            ${className}
                        `}
                    />
                
                </label>
            </div>
    </div>
)}