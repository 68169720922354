import { getAvatarUrl } from '@/utils';
import React from 'react';
import { TrackedImage } from '../Image/TrackedImage';


export const UserAvatar = ({userID, className='w-10 h-10'}) => {
    return (
        <TrackedImage
            className={`rounded-half object-cover ${className}`}
            src={getAvatarUrl(userID)}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/assets/images/profile.png";
            }}
            alt="avatar"
        />
    );
};

