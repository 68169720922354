import { CollapseOrg } from '@/components/Dialog/SlideModal/BrowseOrgSlideModal/CollapseOrg';
import useJoinOrg from '@/hooks/state/organization/useJoinOrg';
import useMyOrgs from '@/hooks/state/organization/useMyOrgs';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import React from 'react';

export const OrgAccordion = ({orgsOptions, ...props}) => {
    const joinOrgMutation = useJoinOrg(queryResultFns('Organization joined successfully', 'Failed to join organization'))
    const myOrgsQuery = useMyOrgs()
    function joinOrg(orgid) {
        joinOrgMutation.mutate({orgid})
    }
    return (
        <div className='w-full form-control gap-2 '>
            { orgsOptions.map((org) => (
                <CollapseOrg
                    isJoined={myOrgsQuery.data?.includes?.(org?.id)}
                    joinOrg={joinOrg} key={org.id} org={org} />
            ))}
        </div>
    );
};

