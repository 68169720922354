import { getOrgsById } from '@/apis/base/organizations/getOrgsById';
import { MILLISEC, UIC_ORG } from '@/constants';
import { ensureToken, prettyPrint } from '@/utils';
import { queryOptions, useQueries } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { orgRoleAppQueryConfig } from '../application/useGetRoleApps';
import { appStatusEnumQueryConfig } from '../enum/useAppStatusEnum';
import useRoleEnum from '../enum/useRoleEnum';
import useMyUserData, { myUserDataQueryConfig } from '../useMyUserData';

export default function useCurrentOrgContext() {
  const [cookies, setCookie, deleteCookie] = useCookies(['orgIdContext']);
  const orgIdContext = cookies.orgIdContext
  const userInfoQuery = useMyUserData();
  const roleEnumQuery = useRoleEnum()
  const conf = useMemo(() => {
    // const start = performance.now()

    const ret = contextQueryConfig(roleEnumQuery.data, orgIdContext,)
    // console.log('time to get configs', performance.now() - start);
    return ret
  }, [cookies.orgIdContext, userInfoQuery.data, roleEnumQuery.data])
  // contextQueryConfig(roleEnumQuery.data, orgIdContext, userInfoQuery)
  
  const orgContext = useQueries(conf);
  
  return orgContext;
}

export const orgInfoQueryConfig = (orgid) => queryOptions({
  queryKey: ['context','org', orgid, ],
  queryFn: () => getOrgsById({ 'orgids[]': [orgid], recursive: 0, showmembers: false }, true),
  retry: 1,
  // notifyOnChangeProps: ['data'],
  gcTime: MILLISEC.SEC * 5,
  refetchOnMount: false,
  enabled:  ensureToken() &&
            orgid != null &&
            orgid !== "" ,
  })

export const contextQueryComponentConfigs = (roleEnumData, orgid) => [
  myUserDataQueryConfig(),
  orgRoleAppQueryConfig({roleids: roleEnumData?.allRoles, orgid}),
  orgInfoQueryConfig(orgid),
  appStatusEnumQueryConfig()
]

export function constructContextData(uData, rData, oData, aData) {
    const isUICAdmin = ['10','13'].includes(uData?.['admin level'])
    const deps = rData?.fin?.FIN?.findata?.subnodes || []
    const uniqueNames = [...new Set(deps.map(dep => dep.name))];
    const uniqueDeps = uniqueNames?.map?.(name => {
      const dep = deps.filter(dep => dep.name === name);
      const depWithLargestTimestamp = dep.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        },[]);
        return depWithLargestTimestamp;
    });

    const isUICContext = isUICAdmin && oData?.id === UIC_ORG
    const brand = oData?.brand === 'true'
    const processor = oData?.processor === 'true'
    const charity = oData?.charity === 'true'
    const standardCommunity = !brand && !processor && !isUICContext
    const extraInfo = {
      isAdmin: String(uData?.userid) === String(oData?.owner) || oData?.admins?.map(a => String(a.id))?.includes(String(uData?.userid)),
      isOwner: String(oData?.owner) === String(uData?.userid),
      finStatus: aData?.find(s => s.id === rData?.['fin']?.['FIN']?.status,)?.name,
      ambStatus: aData?.find(s => s.id === rData?.['ambassador']?.['Ambassador']?.status,)?.name,
      dependents: uniqueDeps,
      guardStatus: aData?.find(s => s.id === rData?.['guardian']?.['Guardian']?.status,)?.name,
      smiStatus: aData?.find(s => s.id === rData?.['smi']?.['Social Media Influencer']?.status,)?.name,
      ecommerceEnabled: oData?.ecommerceEnabled === '1',
      username: `${uData?.['first name']} ${uData?.['last name']}`,
      isUICAdmin,
      isUICContext,
      orgName: oData?.name,
      orgid: oData?.id,
      brand,
      processor,
      charity,
      standardCommunity,
    };      
    return {...oData,...uData,...extraInfo}
}

const contextQueryConfig = (roleEnumQueryData, orgid) => ({
  queries: contextQueryComponentConfigs(roleEnumQueryData, orgid),
  combine
})

function combine(data) {
  const uData = data[0]?.data
  const rData = data[1]?.data
  const oData = data[2]?.data
  const sData = data[3]?.data
  // console.log('in ctx combine');
  
  // Object.assign(data, extraInfo, userDataClone);
  // console.log('in context');
  // prettyPrint(data)
  return ({
    isSuccess: data.every(result => result.isSuccess),
    isError: data.some(result => result.isError),
    isLoading: data.some(result => result.isLoading),
    isPending: data.some(result => result.isPending),
    dataUpdatedAt: data.reduce((acc, result) => Math.max(acc, result.dataUpdatedAt), 0),
    data: constructContextData(uData, rData, oData, sData),
    componentQueries: data,
  });
}