
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { modApplicationRequests } from '@/apis/base/admin/modRequests';


export default function useModAppRequests({onSuccess, onError}) {
    const client = useQueryClient()
    const query = useMutation({
        mutationKey: ['admin',ROUTE_IDS.MOD_APPLICATION_STATUS],
        mutationFn: (params) => modApplicationRequests(params),
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey:['role']});
            client.invalidateQueries({queryKey:['org', req?.orgid]});
            // client.invalidateQueries({queryKey:['context','role']});
            onSuccess(data);
        },
        onError,

    });
    return query;
}