import { BasicTabs } from "@/components/Tabs/BasicTabs";
import useOrgSponsorships from "@/hooks/state/organization/sponsorships/useOrgSponsorships";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { useEffect, useState } from "react";
import { OrgLogo } from "./Image/OrgLogo";

const CommunitySponsorCard = ({orgid, orgname, ...props}) => {
    return (
        <div className="p-2 form-control items-center max-w-[8rem]">
            <div className="max-h-[4rem]">
                <OrgLogo 
                    className={'rounded-lg object-cover max-w-[5rem] h-auto max-h-full'}
                    customSize
                    id={orgid}
                    {...props}
                />
            </div>
            <p className="max-w-full pt-1 font-medium text-sm text-gray-subText overflow-hidden overflow-ellipsis whitespace-nowrap">{orgname}</p>
        </div>
    )
}

export const CommunitySponsorsSection = () => {
    const contextQuery = useCurrentOrgContext();
    const sponsorsQuery = useOrgSponsorships({orgid: contextQuery.data?.orgid})
    const [numLogos, setNumLogos] = useState(0)

    useEffect(() => {
        if(sponsorsQuery.isSuccess)
            setNumLogos(sponsorsQuery.data?.length || 0)
    }, [sponsorsQuery.isSuccess])   

    function handleLogoLoadError() {
        setNumLogos(prev => prev - 1)
    }

    return numLogos > 0 && sponsorsQuery.isSuccess && (
        <div className="py-2">
            <BasicTabs autonomous fake tabNames={['Community Sponsors']}>
                <div className="flex flex-nowrap gap-3 -mt-6">
                {/* {console.log(sponsorsQuery.data)} */}
                {   sponsorsQuery.data?.filter(s => !isNaN(Number(s.orgid)))?.map?.((sponsor, idx) => (
                    <CommunitySponsorCard
                        key={sponsor?.orgid || idx}
                        orgid={sponsor?.orgid}
                        orgname={sponsor?.name}
                        onError={handleLogoLoadError}
                    />
                    ))
                }
                </div>
            </BasicTabs>
        </div>
    )
};

