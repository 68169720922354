import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS,composeFormData } from "@/apis/base/routeId"


export const leaveOrg = (params) => {
    return new Promise((resolve, reject) => {
        postPromise(
            config.API_BASE_URL,
            composeFormData(params, ROUTE_IDS.LEAVE_ORG)
        )
            .then((response) => {
                if (response?.status === "")
                    reject("Failed to Leave Org")
                else
                    resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
};
