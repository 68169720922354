import { DonationManagementPage } from '@/components/pages/DonationMangementPage';
import { BD_ROLES } from '@/constants';
import useAllBDEvents from '@/hooks/state/admin/uic/useAllBDEvents';
import React from 'react';


export const AdminDonation = (props) => {
    const donationQuery = useAllBDEvents({})
    const donations = donationQuery.data;
    return (
        <DonationManagementPage {...{role: BD_ROLES.UIC,donations}}/>
    );
};

