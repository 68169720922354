
import { forwardRef } from "react"
import PropTypes from 'prop-types';
import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { closeDialogWithRef, resetFormWithId } from "@/utils";

export const BasicDialog = forwardRef(function BasicDialogFunc({ 
	handleSubmit: onSubmit= () => {}, 
	formID, 
	title, 
	children, 
	className, 
	containerClassName, 
	confirmText='Submit',
	cancelText='Cancel',
	onReset=()=>{},
	native=false,
	confirmDisabled=false,
	confirmLoading=false,
	hideActions=false,
	onClose = ()=>{} }, 
	ref)  {

		function handleCancel() {
			resetFormWithId(formID)
			closeDialogWithRef(ref)
		}

		function handleSubmit(e) {
			e.preventDefault();
			if (native) return onSubmit(e)
			const formData = new FormData(e.target)
			onSubmit(formData,e)
		}

    return (
        <dialog ref={ref}  className="modal overflow-scroll" onClose={onClose}>
			<div className={`modal-box max-w-screen-sm md:max-w-none max-h-[95vh] px-10 py-3 pb-5 w-[40rem]  ${className}`}>
				<form method="dialog">
				{/* if there is a button in form, it will close the modal */}
				<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
				</form>
				<div className="flex flex-col mb- px-6 ">
					<div className="modal-action flex flex-col ">
                        { title && <h3 className="font-bold text-xl pb-5">{title}</h3>}
                        <form  
							onReset={onReset}
							id={formID}
							onSubmit={handleSubmit} className={`w-full max-h-none ${containerClassName}`} >	
                            {children}
							{ !hideActions && (
								<div className=' w-full justify-end flex flex-row  gap-5 pt-2.5'>
									<BasicRectButton 
										type='submit'
										className={'btn-secondary'}
										disabled={confirmDisabled}
										formMethod="dialog"
									>
										{ confirmLoading 
											? <div className="loading loading-spinner loading-sm "></div>
											: confirmText	
										}
									</BasicRectButton>
									<BasicRectButton 
										type='button'
										className={'btn-neutral'}
										onClick={handleCancel}
									>
										{cancelText}
									</BasicRectButton>
								</div>
							)}
						</form>
					</div>
                </div>
            </div>
			<form method="dialog" className="modal-backdrop ">
				<button>close</button>
			</form>
        </dialog> 	
    )
}) 

BasicDialog.propTypes = {
    handleSubmit: PropTypes.func,
    formID: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    onClose: PropTypes.func,
}
