import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS } from "@/apis/base/routeId"
import { composeFormData } from "../routeId"
import useRoleEnum from "@/hooks/state/enum/useRoleEnum"


export const getMyBaseRoleData = (params) => {
    return postPromise(config.API_BASE_URL, composeFormData(params, ROUTE_IDS.GET_MY_BASE_ROLE_DATA))
}

