import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getAllUsers } from '@/apis/base/admin/getAllUsers';


export default function useAllUsers(params) {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_ALL_USER_DATA],
        queryFn: (params) => getAllUsers(params),
        staleTime: MILLISEC.MIN_15,
    });
    return query;
}