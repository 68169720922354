import { BasicDetailRow } from '@/components/Label/BasicDetailRow';
import { getDateString, getDateTimeString } from '@/utils';
import React, { useMemo } from 'react';

export const SMIDetail = ({app}) => {
    const hasExistingSM = app.networkdescription?.length > 0
    return (
        <div className="flex flex-col">
                <BasicDetailRow label="Student" value={app.affiliation === 'Student' ? 'Yes' : 'No'}  />
                <BasicDetailRow label="Over 18 Years Old" value={app?.over18 === '1' ? 'Yes' : 'No'}/>
                <BasicDetailRow label='Comment' value={app.description}/>
                <BasicDetailRow label="Has Existing Social Media Presence" value={hasExistingSM ? 'Yes' : 'No'}/>   
                {   hasExistingSM &&
                    <BasicDetailRow label="Existing Social Media Presence Description" value={app.networkdescription}/>    
                } 

        </div>
    );
}
