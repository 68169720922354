import { XButton } from "@/components/Buttons"
import Swal from "sweetalert2"

export const FailPopup = Swal.mixin({
    showClass: {
        popup: 'animate-in-popup'
    },
    customClass: {
        container:'z-[10000]',
        confirmButton: 'btn btn-secondary btn-md focus:shadow-none',
        cancelButton:  'btn btn-neutral btn-md focus:shadow-none',
        icon: 'mt-[1rem]'
    },
    backdrop:true,
    buttonsStyling: false,
    icon: 'error',
    title: 'Error',
    position: 'center',
    showConfirmButton: true,
})