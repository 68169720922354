import { getCookie, setCookie } from "@/utils"
import { observeResize } from "@/utils/misc"
import { useEffect, useRef, useState } from "react"



export const BasicTabs = ({autonomous=false, tabNames, activeTab, onTabClick, children, fake=false, className}) => {
    
    const numTabs = tabNames?.length
    const containerRef = useRef(null)
    const [tabWidths, setTabWidths] = useState(0)
    const [tabOffsets, setTabOffsets] = useState(0)
    const [mounted, setMounted] = useState(false)
    const [tab, setTab] = useState(Number(getCookie(tabNames.join(''))) ?? 0 )
    // console.log(tabWidths, tabOffsets);

    useEffect(observeContainerResize,[containerRef.current?.children?.length])

    useEffect(() => {setMounted(true)}, [])

    useEffect(observeContainerResize,[containerRef.current?.children?.length])

    useEffect(() => {setMounted(true)}, [])

    function observeContainerResize(e) {
        observeResize(containerRef.current, () =>{
            const d = Array.from(containerRef.current?.children || [])
                ?.reduce?.((acc, curr, idx) => {
                        const currWidth = curr.offsetWidth 
                        acc[0].push?.(currWidth)
                        acc[1].push?.((acc[0][idx - 1] ?? 0) + (acc[1][idx - 1] ?? 0))
                        return acc
                    }
                , [[],[]])
                setTabWidths(d[0])
                setTabOffsets(d[1])
        })
    }

    const handleAutonomousTabChange = (tab) => {
        setTab(tab)
        setCookie(`${tabNames.join('')}`, tab)
    }
    const handleClick = (e,idx) => {   
        autonomous ? 
            handleAutonomousTabChange(idx)
            : onTabClick?.(idx)
    }
    const realTab = autonomous ? tab : activeTab
    
    return (
        <div className="relative form-control justify-start w-full">
          <div 
            ref={containerRef} 
            className={` max-w-full relative flex flex-row whitespace-nowrap  w-fit-content  text-sm md:text-base xl:text-lg   mb-6 ${className}` }>
            {tabNames?.map?.((tabName, idx) => (
                <Tab
                    key={tabName || idx}
                    {...{idx, handleClick, tabName, fake}} activeTab={realTab}    
                />
            ))}
            </div>
            <div 
                className={ `h-[2.5px] will-change-transform top-6 md:top-9 absolute bg-black z-[2] 
                    ${mounted ? 'duration-300 transform transition-translate ease-in-out' : ''} `}    
                style={{width: tabWidths[realTab], translate: tabOffsets[realTab]  }}/>
            <div className="absolute w-full h-[1.4px] top-6 md:top-9 bg-gray-300"/>
            <div className="mt-8 md:mt-10">
            { Array.isArray(children) ? 
                children?.filter(Boolean)?.[realTab]
                : children
            }
        </div>
    </div>        
    )
}

const Tab = ({idx, activeTab, handleClick, tabName, fake }) => (
    <a 
        className={`w-full inline-block  place-content-center px-1 md:px-2 xl:px-5 
            ${activeTab === idx ? 'text-primary ' : 'text-gray-300 font-[450px]'} block 
            ${fake ? '' : 'hover:text-gray-450 hover:cursor-pointer'}`} 
        onClick={(e) => handleClick(e,idx)}>
        <p className=" font-medium text-center">{tabName}</p>
    </a>     
)