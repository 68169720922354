import { addCommas, ordinalIndicator } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";

const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const strictStringFilter = filterFns.includesString.autoRemove


export const orgBrandPrefReportCols = [
  columnHelper.accessor((row) => row.name, {
    id: "Brand",
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <span className="text-xl font-medium">{info.getValue()}</span>,
    header: (info) => <span>BRAND</span>,
  }),
  columnHelper.display({
    id: 'nonTimespan',
    size: 500,
    cell: ({row, table}) => <NonTimespanMetrics row={row.original} numBrands={table.options.meta.numBrands}/>,
    header: (info) => <span>DEFAULT METRICS</span>, 
  }),
  columnHelper.display({
    id: 'Timespan',
    size: 600,
    cell: ({row, table}) => <TimespanMetrics row={row.original} numBrands={table.options.meta.numBrands}/>,
    header: (info) => <span>TIMESPAN METRICS</span>, 
  }),
  columnHelper.accessor((row) => row.votes, {
    id: "Total Votes",
    meta: {
      hiddenCol: true,
      showSortControls: true,
    },
    size: 100,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>TOTAL VOTES</span>,
  }),
  columnHelper.accessor((row) => row.votesTimespan, {
    id: "Total Votes In Timespan",
    meta: {
      hiddenCol: true,
      showSortControls: true,
    },
    size: 100,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>VOTES IN TIMESPAN</span>,
  }),
  columnHelper.accessor(row => row.percentTotal, {
    id: '% Votes',
    meta: {
      hiddenCol: true,
      showSortControls: true,
    },
    size: 100,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>% VOTES</span>,
  }),
  columnHelper.accessor(row => row.percentTimespan, {
    id: '% Votes in Timespan',
    enableColumnFilter: false,
    meta: {
      hiddenCol: true,
      showSortControls: true,
    },
    size: 100,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>% VOTES IN TIMESPAN</span>,
  }),
]

const PercentVotes = ({percent}) => (
  <div
    className="radial-progress"
    style={{ "--value": `${percent}`, "--size": "5rem", "--thickness": "0.5rem" }} 
    role="progressbar">
    {percent}%
  </div>
)

const PercentVotesLinear = ({percent}) => (
  <div className="place-items-center text-center">
    <span >{percent}%</span>
    <progress className="progress w-56" value={percent} max='100'></progress>
  </div>
)



const NonTimespanMetrics = ({row, numBrands}) => (
  <div className="form-control gap-2 max-w-[15rem]">
    <div className="flex justify-between items-center">
      <p className="text-xl">
        <span className="font-bold">{row.rank} <span className="align-super -ml-[0.2rem] text-xs">{ordinalIndicator(row.rank)}</span></span>
        <sub>/ {numBrands}</sub>
      </p>
      <PercentVotes percent={row.percentTotal}/>
    </div>

    <div className="stats place-items-center bg-inherit">
      <div className="stat-value">{addCommas(row.votes)}</div>
      <div className="stat-desc">Votes</div>
    </div>
  </div>
)

const TimespanMetrics = ({row, numBrands}) => (
  <div className="flex  items-center gap-16">
    <div className="flex justify-center items-center gap-16">
      <p className="text-xl flex-1">
        <span className="font-bold">{row.rank} <span className="align-super -ml-[0.2rem] text-xs">{ordinalIndicator(row.rank)}</span></span>
        <sub>/ {numBrands}</sub>
      </p>
      <div className="flex-1">
        <PercentVotesLinear percent={row.percentTimespan}/>
      </div>
    </div>

    <div className="stats place-items-center bg-inherit w-fit px-2">
      <div className="stat-value">{addCommas(row.votesTimespan)}</div>
      <div className="stat-desc">Votes</div>
    </div>
  </div>

)
