import { MILLISEC } from '@/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { UICOCreateOrg } from '@/apis/base/organizations/UICOCreateOrg';

export default function useUICOCreateOrg({ onError, onSuccess, ...props }) {
  const client = useQueryClient();
  const createOrgQuery = useMutation({
    mutationKey: ['org',ROUTE_IDS.UIC_OVERRIDE_CREATE_ORG],
    mutationFn: UICOCreateOrg,
    retry: false,
    staleTime: MILLISEC.MIN_15,
    onSuccess: (data, req) => {
      client.invalidateQueries({queryKey:['org']});
      client.invalidateQueries({queryKey:['context']});
      console.log('done invalidations');
      onSuccess?.(data, req)
    },
    onError: onError,
    ...props,
  });
  return createOrgQuery;
}
