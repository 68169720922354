

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getSponsorshipEnum } from '@/apis/base/organizations/sponsorships/getSponsorshipEnum';

export default function useSponsorshipEnum(params) {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_SPONSORSHIP_ENUM],
        queryFn: (params) => getSponsorshipEnum(params),
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        staleTime: MILLISEC.MIN_15,
        select
    });
    return query;
}
function select(data) {
    const enumObj = {}
    data?.forEach?.(item => {
        enumObj[item.name] = item
    })
    return enumObj
}