import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';
import { BasicRadioButton } from '@/components/Input/BasicRadioButton';
import { BasicRadioGroup } from '@/components/Input/BasicRadioGroup';
import { MultiTagSelect } from '@/components/Input/MultiTagSelect';
import { BasicSubsectionTitle } from '@/components/Label/BasicSubsectionTitle';
import { RemovableTag } from '@/components/Misc/RemovableTag';
import { EligibleOrgsTable } from '@/components/Tables/EligibleOrganizationsTable/EligibleOrgsTable';
import useBDEventByID from '@/hooks/state/donation/useBDEventByID';
import useDonationLocations from '@/hooks/state/donation/useDonationLocations';
import useModBDEvent from '@/hooks/state/donation/useModBDEvent';
import useOrgRoles from '@/hooks/state/enum/useOrgRoles';
import useAllOrgs from '@/hooks/state/organization/useAllOrgs';
import useGetOrgTypes from '@/hooks/state/organization/useGetOrgTypes';
import useMinimalOrgs from '@/hooks/state/organization/useMinimalOrgs';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import { useIntervalFunction } from '@/hooks/utils/useIntervalFunction';
import { capitalizeFirst } from '@/utils';
import { deselectRow, unpinRow } from '@/utils/tableUtils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { Label } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const ELIGIBLE = {
    ALL: 1,
    BY_TYPE: 2,
    WHITELIST: 3
}

const allEligibleData = [
    {
        label: 'All Organizations Eligible',
        value: ELIGIBLE.ALL,
    },
    {
        label: 'Organizations Eligible by Type',
        value: ELIGIBLE.BY_TYPE
    },
    {
        label: 'Only Specific Organizations Eligible',
        value: ELIGIBLE.WHITELIST
    }
]

export const BrandDonationEligibleOrganizations = ({canModify}) => {
    const tableRef = React.useRef();
    const {eventid, orgid} = useParams();
    const [selectedOrgs, setSelectedOrgs] = useState([])
    const [selectedOrgTypes, setSelectedOrgTypes] = useState([])
    const [selectedOrgRoles, setSelectedOrgRoles] = useState([])
    const [checkedIndex, setCheckedIndex] = useState(0)
    
    const donationQuery = useBDEventByID({eventid,orgid})
    const [eType, setEType] = useState(inferEventEligibility(donationQuery.data));
    
    const orgNamesQuery = useMinimalOrgs({})
    const orgsQuery = useOrgWithId({
        org_id: orgNamesQuery.data?.map?.(org => org.id),
    })
    
    const orgTypesQuery = useGetOrgTypes(true)
    const orgRolesQuery = useOrgRoles()
    const eventMutation = useModBDEvent({
        orgid,
        onSuccess: (data) => {SuccessToast.fire({text: 'Event Availability Updated'})},
        onError: (error) => {FailPopup.fire({text: 'Failed to update event availability'})}
    });

    const roleAndTypeOptions = useMemo(() => {
        const filteredTypes = orgTypesQuery.data?.reduce?.(
            (acc, curr) => {
                if (['Business','Corporation']?.includes(curr.name)) return acc
                acc.push({label: curr.name, value: curr.id})
                return acc            
            }
            ,[]) || []
        const filteredRoles = Object.values(orgRolesQuery.data || {})
                                .filter?.(role => !['brand', 'processor', 'corporation']
                                .includes(role.name))
                                .map?.(role => ({label: capitalizeFirst(role.name), value: role.id})) || []
        return [...filteredTypes, ...filteredRoles]
    },[orgTypesQuery.dataUpdatedAt])

    const orgsWithTypes = useMemo(() => {
        let flatOrgs = orgsQuery.data
        if (flatOrgs?.length > 0)  {
            flatOrgs = flatOrgs.reduce((acc, curr) => {
                if ('Business' === curr?.['org type name'] || curr.brand === '1' || curr.corporation === '1') return acc
                curr.type = 
                    roleAndTypeOptions.find(t => t.value === curr?.['org type id'])
                    ?.label
                acc.push(curr)
                return acc
            },[])
            return flatOrgs
        } 
        return []
    },[orgsQuery.dataUpdatedAt])

    useEffect(() => {
        if (!donationQuery.isSuccess) return
        const data = donationQuery.data
        setEType(inferEventEligibility(data))
        setSelectedOrgs(orgsWithTypes ? orgsWithTypes.filter(o => data.orgs.includes(o.id)) : [] )
        setSelectedOrgTypes(data?.orgtypes || [])
        setSelectedOrgRoles(data?.orgroles || [])
    },[donationQuery.dataUpdatedAt])

    function handleDeselect(rowID) {
        if (tableRef?.current) {
            deselectRow(tableRef.current, rowID)
            unpinRow(tableRef.current, rowID)
        }
    }

    function handleOrgTypeClicked(type, checked) {
        if (checked) setSelectedOrgTypes([...selectedOrgTypes, type])
        else setSelectedOrgTypes(selectedOrgTypes.filter(t => t !== type))
    }

    function handleOrgRoleClicked(role, checked) {
        if (checked) setSelectedOrgRoles([...selectedOrgRoles, role])
        else setSelectedOrgRoles(selectedOrgRoles.filter(r => r !== role))
    }

    const handleSubmit = () => {
        let orgtypes = []
        let orgs = []
        let orgroles = []
        switch (eType) {
            case ELIGIBLE.ALL:
                break;
            case ELIGIBLE.BY_TYPE:
                orgtypes = selectedOrgTypes
                orgroles = selectedOrgRoles
                break;
            case ELIGIBLE.WHITELIST:
                orgs = selectedOrgs.map(org => org.id)
                break;
            default:
                break;
        } 
        const params = {
            orgid,
            eventid,
            orgtypes,
            orgs
        }
        eventMutation.mutate(params)
    }

    return (
        <div className='flex flex-col'>
            <div className='flex w-full  justify-end'>
                <BasicBlueRectButton disabled={!canModify} onClick={handleSubmit} className=''>
                    Update
                </BasicBlueRectButton>

            </div>
            <div className="flex flex-col lg:flex-row w-full gap-20 ">
                <div className="flex gap-6 h-fit ">
                    <div className='divider divider-horizontal py-6  mx-0 w-1 px-3'/>
                    <div className="flex flex-col gap-4  items-start">
                    <span className='text-gray-subText text-[16px]'>Pick One </span>
                        <BasicRadioGroup 
                            disabled={!canModify}
                            name='org eligibility' 
                            value={eType}
                            data={allEligibleData} 
                            onChange={setEType} 
                            containerClassName={'gap-3 max-w-[35rem] '} 
                            className={'h-20 flex-row items-center text-lg font-medium'}/>
                    </div>
                </div>
                <div className='w-full form-control max-w-4xl'>
                    <>
                    { eType !== ELIGIBLE.ALL && (
                        <div className='flex'>

                            <div className='float-left divider divider-horizontal py-6 mx-0 w-1 px-3 h-full'/>
                                <div className="w-full ml-7 gap-4 form-control">
                                    <span className='text-gray-subText text-[16px]'>
                                        Pick One Or More
                                    </span>
                                    {   eType === ELIGIBLE.BY_TYPE && (
                                        <MultiTagSelect 
                                            className={'items-end justify-end flex-row-reverse max-w-[30rem] mr-[14.2rem]'}
                                            disabled={!canModify || eType !== ELIGIBLE.BY_TYPE} 
                                            onChange={handleOrgTypeClicked}
                                            checkedValues={selectedOrgTypes}
                                            data={roleAndTypeOptions}     
                                        />
                                    )}

                                    { eType === ELIGIBLE.WHITELIST && (
                                        <EligibleOrgsTable  
                                            tableRef={tableRef}
                                            disabled={!canModify || eType !== ELIGIBLE.WHITELIST}
                                            data={orgsWithTypes}
                                            handleSelectionChange={setSelectedOrgs}
                                            initialSelectionDataIDs={donationQuery.data?.orgs}
                                            extraRows={ eType === ELIGIBLE.WHITELIST && selectedOrgs?.length > 0 ? [
                                                <tr 
                                                    key={'extrarow'}
                                                    className={`p-2  ${selectedOrgs?.length > 0 ? '' : 'invisible'}`}>
                                                    <td colSpan={4} className=''>
                                                        <div className="flex flex-row place-items-center">
                                                            <div className='text-gray-subText'>Selected</div>
                                                            <div className="divider divider-horizontal py-2"/>
                                                            <div className='flex flex-row text-sm flex-wrap gap-3 gap-y-1 p-2 min-h-[3.7rem]'>
                                                                { selectedOrgs?.map?.(org => (
                                                                    
                                                                <RemovableTag 
                                                                    key={org.name}
                                                                    disabled={!canModify} 
                                                                    label={org.name} 
                                                                    onDelete={() => handleDeselect(org.rowID)}/>
                                                                    
                                                                ))

                                                                }

                                                        </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                            ] : []}
                                            >
                                        </EligibleOrgsTable>
                                    )}
                                </div>
                        </div>
                        )}
                    </>
                </div>
            </div>
        </div>
            
    );
};


const inferEventEligibility = (event) => {
    if (event?.orgtypes?.length > 0) return ELIGIBLE.BY_TYPE;
    if (event?.orgs?.length > 0) return ELIGIBLE.WHITELIST;
    return ELIGIBLE.ALL;
}