import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { GrayButton } from './components/Buttons';

import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';
import useRecoverPassword from '@/hooks/state/auth/useRecoverPassword';
import { AuthTextInput } from '@/views/pages/Auth/components/BasicInputs';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useAppContext } from '../../../context/AppContext';
import { useTranslations } from '../../../hooks/translations';
import { validateEmail } from '../../../utils';

function Forgot(props) {
  const text = useTranslations('forgot');
  const { setLoading, setMessage } = useAppContext();
  const [values, setValues] = useState({
    email: ''
  });

  const [validations, setValidations] = useState({
    email: '',
  });

  const [requestSent, setRequestSent] = useState(false);
  const [requestResent, setRequestResent] = useState(false);
  const recoverQuery = useRecoverPassword({
    onSuccess: (data) => {
      setRequestSent(true);
      SuccessToast.fire({text: text.linkSent})
    },
  onError: (err) => {
    FailPopup.fire({text: text.failedSendResetLink})  
    },
    onSettled: () => {
      setLoading(false);
    }
  })

  const checkvalidations = () => {
    if (values.email === '') {
      setValidations({ email: 'has-empty' });
      return false;
    } else if (!validateEmail(values.email)) {
      setValidations({ email: 'has-danger' });
      return false;
    }

    return true;
  };

  const handleChange = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSend = () => {
    console.log(values);
    if(!checkvalidations()) return;
    setLoading(true);
    recoverQuery.mutate(values);
  };

  function handleSubmit(e) {
    e.preventDefault()
  }

  return ( !requestSent ?
    <>
      <form className="lg:my-25 sm:w-3/5 w-4/5" onSubmit={handleSubmit}>
        <div className="w-full">
          <h1 className="text-3xl font-bold text-gray-800">{text.forgotPassword}</h1>
        </div>
        <div className="mt-4 w-full">
          <label className="text-gray-800">{text.enterEmail}</label>
        </div>
        <div className="mt-11 w-full">
          <AuthTextInput
            label={text.email}
            value={values.email}
            onChange={e => handleChange('email', e.target.value)}
            error={validations.email !== ''}
            helperText={validations.email === 'has-empty' ? text.fieldRequired : validations.email === 'has-danger' ? text.emailInvalid : ''}
          />
        </div>
        <div className="mt-9 w-full">
          <BasicBlackButton variant="contained" size="large" className="w-full" onClick={() => handleSend()}>{text.continue}</BasicBlackButton>
        </div>
        <div className="mt-4 w-full flex justify-center">
          <span className="text-gray-400">{text.rememberPassword} <Link className="underline" to="/auth/login">{text.signin}</Link></span>
        </div>
      </form>
    </> :
    <>
      <div className="lg:my-25 sm:w-3/5 w-4/5">
        <div className="w-full">
          <p className="text-3xl font-semibold">{text.thankyou}</p>
        </div>
        <div className="mt-4 w-full">
          <p className="text-gray-400">{requestResent ? text.linkResent : text.linkSent}</p>
        </div>
        <div className="mt-4 w-full">
          <p className="text-gray-400">{text.checkInbox}</p>
        </div>
         <div className="mt-14 w-full">
          <GrayButton variant="contained" size="large" className="w-full" onClick={() => handleSend()}>{text.resendLink}</GrayButton>
        </div>
      </div>
    </>
  );
}

export default Forgot;
