import React from 'react';


export const BasicGrayButton = ({className, circle=false, square=false, ...props}) => {
    const shapeClass = circle ? 'btn-circle' : square ? 'btn-square' : ''
    return (
        <button {...props} 
        className={`btn btn-neutral no-animation
            ${shapeClass}
            ${className}
        `}>
            {props.children}
        </button>
    );
};

