
import { getUsersDependents } from '@/apis/base/admin/getUsersDependents';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';

export default function useUsersDependents({userids}) {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_USERS_DEPENDENTS],
        queryFn: (params) => getUsersDependents({'userids[]': userids}),
        staleTime: MILLISEC.MIN_15,
        enabled: Array.isArray(userids) && userids?.length > 0,
        select: (data) => {
            data.forEach((user) => {
                const uniqueNames = [...new Set(user.dependents.map(dep => dep.name))];
                const uniqueDeps = uniqueNames.map(name => {
                    const dep = user.dependents.filter(dep => dep.name === name);
                    const depWithLargestTimestamp = dep.reduce((prev, current) => {
                        return prev.timestamp > current.timestamp ? prev : current;
                    },[]);
                    return depWithLargestTimestamp;
                });
                user.uniqueDependents = uniqueDeps;
            })
            return data
        },
    });
    return query;
}