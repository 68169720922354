import useBDStatusEnum from '@/hooks/state/enum/useBDStatusEnum';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import React, { useMemo } from 'react';
import { BasicTable } from '../BasicTable';
import { shipmentsColumnHelper } from './shipmentsColumnHelper';
import useModBDRequest from '@/hooks/state/donation/request/useModBDRequest';
import { clamp, closeDialogWithRef } from '@/utils';
import useModBDShipment from '@/hooks/state/donation/shipment/useModBDShipment';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import useConfirmBDShipment from '@/hooks/state/donation/shipment/useConfirmBDShipment';

export const ShipmentsTable = ({
    pairs, 
    event, 
    canEditRequest,
    canEditShipment,
    inlineEditing,
    userNames, 
    onTreeClicked,
    onEditClicked,
    ...props}) => {
    const ctx = useCurrentOrgContext().data
    const eventStatusQuery = useBDStatusEnum()
    const requestMutation = useModBDRequest({
        ...queryResultFns(
            'Request updated',
            'Failed to update request'
        ),
    })
    const shipmentMutation = useModBDShipment(queryResultFns(
        'Shipment updated',
        'Failed to update shipment'
    ))
    const confirmReceivedMutation = useConfirmBDShipment({
        ...queryResultFns(
            'Shipment confirmed',
            'Failed to confirm shipment'
        ),
        brandorgid: event?.orgid
    })
    console.log("event", event)
    const safePairs = pairs ? Object.values(pairs) : []
    const colHelper = useMemo(() => 
        shipmentsColumnHelper(event?.donations || []),[event?.donations]) 
    const canModify = useMemo(() => {
        if (!ctx || !event) return false
        return (
            (event.status === eventStatusQuery.data?.live) && 
            ((!ctx?.processor) || (ctx.processor && ctx.orgid === event.processor))
        )
    },[event, ctx])
    function handleInlineBlur(e, field, item) {
        console.log('input blurred', e.target.value, field, item);
        let newValue = 0
        console.log('newvalue',newValue);
        if (newValue === parseInt(item?.[field])) return
        switch (field) {
            case 'requestquantity':
                newValue = (clamp(Number(e.target.value), 0, item.quantity))
                console.log('request q changed', newValue, item?.[field]);
                submitRequest(item, newValue)
                break
            case 'shipmentquantity':
                newValue = (clamp(Number(e.target.value), 0, Number(item.requestquantity)))
                console.log('shipment q changed', newValue, item?.[field]);
                submitShipment(item, newValue)
                break
            case 'confirmedquantity':
                newValue = (clamp(Number(e.target.value), 0, Number(item.shipmentquantity)))
                console.log('confirmed q changed', newValue, item?.[field]);
                confirmShipment(item, newValue)
                break
            default:
                break
        }
    }
    function submitRequest(row, requestedquantity) {
        const params = {
            orgid: row.orgid,
            eventid: event.id,
            lineitems: [{
                inventoryid: row.id,
                quantity: Number(requestedquantity)
            }]
        }
        requestMutation.mutate(params)
    }
    function submitShipment(row, shipppedquantity) {
        const params = {
            recipientorgid: row.orgid,
            eventid: event.id,
            lineitems: [{
                inventoryid: row.id,
                quantity: Number(shipppedquantity)
            }]
        }
        shipmentMutation.mutate(params)
    }
    function confirmShipment(row, qtyconfirmed) {
        const params = {
            recipientorgid: row.orgid,
            eventid: event.id,
            lineitems: [{
                inventoryid: row.id,
                qtyconfirmed: Number(qtyconfirmed)
            }]
        }
        confirmReceivedMutation.mutate(params)
    }
    return (
        <BasicTable 
            maxHeight='12rem'
            data={safePairs} 
            columnHelper={colHelper} 
            scrollShadows
            // state={{columnVisibility: {Actions: canModify}}}
            meta={{
                onTreeClicked,
                onEditClicked,
                handleInlineBlur,
                canEditRequest,
                canEditShipment,
                inlineEditing,
                userNames,
                canModify
            }}
            initialState={{
                columnPinning: {right: ['Actions'], left:['Organization']}
            }}
            {...props}/>
    );
};

