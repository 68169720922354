import { useRegionCitySelect } from '@/components/CountryRegionSelect';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TextInput } from '../../components/Inputs';
import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';
import { AuthTextInput } from '@/views/pages/Auth/components/BasicInputs';


export const OrgRegister = ({text, onSubmit, ...props}) => {
    const [values, setValues] = useState({
        orgname: '',
        orgaddress: '',
        orgcityid: '',
        orgregionid: '',
        orgpostalcode: '',
        orgcountryid: '27003',
        countryid: '27003',
        website: '',
      });
    const [validations, setValidations] = useState({
        orgname: '',
        orgaddress: '',
        orgcityid: '',
        orgregionid: '',
        orgpostalcode: '',
        orgcountryid: '',
    });
    const [canada, setCanada] = useState(null);
    const [regionSelection, setRegionSelection] = useState(null);
    const [citySelection, setCitySelection] = useState(null);

    const { CitySelect, RegionSelect } = useRegionCitySelect({
        onRegionChange: (val) => {
            setValues({ ...values, orgregionid: val });
            setValidations({ ...validations, orgregionid: '' });
        },
        onCityChange: (val) => {
            setValues({ ...values, orgcityid: val });
            setValidations({ ...validations, orgcityid: '' });
        },
        regionError: validations.orgregionid != '',
        cityError: validations.orgcityid != '',
    });

    const handleContinue = () => {
        if (values.orgname === '')
          setValidations((prevState) => ({
            ...prevState,
            orgname: 'has-empty',
          }));
        else if (values.address === '')
          setValidations((prevState) => ({
            ...prevState,
            orgaddress: 'has-empty',
          }));
        else if (values.orgcityid === '')
          setValidations((prevState) => ({ ...prevState, orgcityid: 'has-empty' }));
        else if (values.orgregionid === '')
          setValidations((prevState) => ({ ...prevState, state: 'has-empty' }));
        else if (values.orgpostalcode === '')
          setValidations((prevState) => ({
            ...prevState,
            orgpostalcode: 'has-empty',
          }));
        else if (values.orgcountryid === '')
          setValidations((prevState) => ({
            ...prevState,
            orgcountryid: 'has-empty',
          }));
        else {
          onSubmit(values);
        }
    }
    
    const handleChange = (prop, value) => {
        if (prop === 'region') {
          setCitySelection(canada.regions.get(value)?.cities);
          setValues((prevState) => ({ ...prevState, [prop]: value }));
          setValidations((prevState) => ({ ...prevState, [prop]: '' }));
          return;
        }
        setValidations((prevState) => ({ ...prevState, [prop]: '' }));
        setValues((prevState) => ({ ...prevState, [prop]: value }));
    };

    return (
        <>
            <div className="w-full h-fit-content overflow-hidden">
              <p className="text-3xl font-semibold">{text.orgSetup}</p>
            </div>

            <div className="mt-6 w-full">
              <AuthTextInput
                label={text.orgName}
                value={values.orgname}
                onChange={(e) => handleChange('orgname', e.target.value)}
                error={validations.orgname !== ''}
                helperText={
                  validations.orgname === 'has-empty'
                    ? text.fieldRequired
                    : ''
                }
              />

            <div className="mt-6 w-full">
              <AuthTextInput
                label={text.orgWebsite}
                value={values.website}
                onChange={(e) => handleChange('website', e.target.value)}
                error={validations.orgname !== ''}
                helperText={
                  validations.orgname === 'has-empty'
                    ? text.fieldRequired
                    : ''
                }
              />
              </div>

            <div className="mt-6 w-full">{RegionSelect}</div>

            <div className="mt-6 w-full">{CitySelect}</div>

            </div>

            
            <div className="mt-6 w-full">
              <AuthTextInput
                label={text.organizationAddress}
                value={values.orgaddress}
                onChange={(e) => handleChange('orgaddress', e.target.value)}
                error={validations.orgaddress !== ''}
                helperText={
                  validations.orgaddress === 'has-empty'
                    ? text.fieldRequired
                    : ''
                }
              />
            </div>

            <div className="mt-6 w-full">
              <AuthTextInput
                label={text.postal}
                value={values.postalcode}
                onChange={(e) => handleChange('orgpostalcode', e.target.value)}
                error={validations.orgpostalcode !== ''}
                helperText={
                  validations.orgpostalcode === 'has-empty'
                    ? text.fieldRequired
                    : ''
                }
              />
            </div>

            <div className="mt-6 w-full">
              <span className="text-gray-400">
                {text.agreeTo}{' '}
                <Link className="underline" to="/terms">
                  {text.terms}
                </Link>{' '}
                {text.and}{' '}
                <Link className="underline" to="/privacy">
                  {text.privacy}
                </Link>
                .
              </span>
            </div>

            <div className="mt-9 w-full">
              <BasicBlackButton
                variant="contained"
                size="large"
                className="w-full"
                onClick={() => handleContinue()}
              >
                {text.continue}
              </BasicBlackButton>
            </div>
          </>
    );
};

