import { forwardRef } from 'react';
import { InputBase } from './InputBase';
import { numberWithCommas } from '@/utils';

export const BasicInput = forwardRef(function({
    label=null,
    labelView=false,
    name,
    className, 
    error: controlledError,
    isError: isControlledError=false,
    errorText: controlledErrorText,
    required=false,
    onChange=()=>{},
    containerClassName, 
    type='text',
    formState=null,
    defaultValue,
    disabled=false,
    placeholder,
    customSize=false,
    compact=false,
    children,
    ...props},ref)  {
    const error = controlledError || formState?.errors[name];
    const isError = isControlledError || error != null;
    const errorText = controlledErrorText || error?.message;
    return (
        <InputBase 
            {...{
                label: children || label,
                isError,
                errorText,
                required,
                containerClassName,
                disabled
            }}
        >
            <input 
                ref={ref}
                step={ type === 'number' ? "any" : null}
                onChange={onChange}
                className={`
                    input focus:outline-none  focus:border-primary
                    ${customSize ? '' : 'input-little'}
                    ${isError ? 'input-error' : 'input-bordered'}
                    ${disabled ? 'input-disabled' : ''}
                    ${labelView ? 'placeholder:text-current' : ''}
                    ${className}
                `} 
                {...{
                    placeholder: labelView ? label : placeholder,
                    name, 
                    type,
                    disabled, 
                }}
                {...props}
            />
        </InputBase>

    );
});

