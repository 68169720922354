import { postPromise } from '@/apis/base';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import config from '@/apis/config';

const UICOCreateOrg = (params) => {
  return postPromise(
    config.API_BASE_URL,
    composeFormData(params, ROUTE_IDS.UIC_OVERRIDE_CREATE_ORG)
  );
};

export { UICOCreateOrg };
