import { refreshToken } from '@/apis/base/auth/refreshToken';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { setCookie } from '@/utils';
import { useMutation } from '@tanstack/react-query';


export default function useRefreshToken(props) {
    const {onSuccess, ...otherProps } = props || {}
    const query = useMutation({
        mutationKey: [ROUTE_IDS.REFRESH],
        mutationFn: refreshToken,
        refetchIntervalInBackground: true,
        gcTime:0,
        onSuccess: (data, req) => {
            const {token} = data || {}
            if (token)
                setCookie('token', token, '/')    
            if (onSuccess)
                onSuccess(data, req)
        },
        ...otherProps
    });
    return query;
}