
import { DashboardCard } from '@/components/Cards';
import Dropdown from '@/components/Dropdown';
import { TrackedImage } from '@/components/Image/TrackedImage';
import { GenericReport } from '@/components/SEE/GenericReport';
import { useAppContext } from '@/context/AppContext';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { useTranslations } from '@/hooks/translations';
import { getBannerUrl } from '@/utils';
import { DefaultCuratorFeed } from '@/views/pages/Main/Home/components/Curator/DefaultCuratorFeed';
import { ArcElement, CategoryScale, Chart, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import React, { useState } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
Chart.register(ArcElement,Tooltip,LineElement, LinearScale,CategoryScale,PointElement,Title)

const TIME = {
  ALL_TIME: 1,
  THIS_YEAR: 0,
}

const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: true,
  // aspectRatio: 1,
  cutout: '60%',
  borderWidth: 0,
  resizeDelay:75,
  spacing: 0,
  offset: 5,
  borderRadius: 2,
  backgroundColor: '#374151',
  plugins: {
    legend: {
      display: true,
      fullSize:false,
      position: "right",
      align: "center",
      labels: {
          font: {
              size: 20
          },
          boxWidth: 8,
          boxHeight:8,
          // borderRadius:100,
          // useBorderRadius: true,
          usePointStyle: true,
          pointStyle: 'circle',
      }
  }
  }
}

const lineOptions = {
  responsive: true,
  maintainAspectRatio:false,
  scales: {
    x: {
      display: false,
      title: {
        display: false,
        type: 'month'
      }
    },
    y: {
      display: false,
      title: {
        display: false,
        type: 'value'
      },
    }
  },
  plugins: {
    tooltip: {
      enabled: true,
      cornerRadius: 5,
      intersect:false,
      backgroundColor: '#374151',
      position: 'nearest',
      titleFont: {
        size: 22
      },
      bodyFont: {
        size: 18
      },
      displayColors: false
      // external: externalTooltipHandler
    },
    title: {
      fullSize: false,
      display: true,
      text: 'Revenue for 2023 ($)',
      font: {
        size: 22,
        weight: 'bold',
      },
      color:'white'
    },
  }
};

const BrandDashboard = () => {
  const text = useTranslations('brandDashboard');
  const { setLoading, setMessage } = useAppContext();
  const [data, setData] = useState([]);
  const [dataAllTime, setDataAllTime] = useState({key: TIME.ALL_TIME, label: 'All Time'});
  const [pieData, setPieData] = useState({
    labels: ['Schools', 'Charities', 'First Nations', 'Other'],
    datasets: [
      {
        data: [12,34,18,23],
        backgroundColor: ['#A2d8ea', '#A2eac3', '#Eaa2e6', '#Ff8789'],
        borderColor: ['#A2d8ea', '#A2eac3', '#Eaa2e6', '#Ff8789'],
        
      },
    ],
  });
  const [revenueData, setRevenueData] = useState({
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        data: [1529, 1234, 2345, 3456, 4567, 5678, 6789, 7890, 8901, 9012, 10123, 11234],
        backgroundColor: '#19D6BF',
        borderColor: '#19D6BF',
        borderWidth: 2,
        pointRadius: 10,
        pointHoverRadius: 15,
        cubicInterpolationMode: 'monotone',
        tension:0.4,
      }
    ],
  })
  const ctx = useCurrentOrgContext().data
  return (
    <div className='overflow-scroll form-control gap-5'>
      <TrackedImage
        className='w-screen relative left-[calc(-50vw + 50%)] max-h-100 object-cover'
        onError={e => {
        e.currentTarget.style.display = 'none'
        }} src={getBannerUrl(ctx?.orgid)} alt={'banner'}/>

      {/* <div className='w-40 place-self-end '>
        <Dropdown 
          labelFn={(option) => option.label}
          keyFn={(option) => option.key}
          value={dataAllTime}
          onChange={setDataAllTime} placeHolder={'All Time'} 
          options={[{key: 1, label: 'All Time'}, {key:0, label: 'This Year'}]} />
      </div> */}
      <div >
          {/* {data.map((item, index) => {
            return (
              <DashboardCard
                key={item.title}
                title={item.title}
                data={item.content}
                st_color={item.fromColor}
                end_color={item.toColor}
              />
            );
          })} */}
          <DefaultCuratorFeed initialRows={1}/>
          <div className='md:mt-1 lg: mt-2 xl:mt-3'>
            <GenericReport />
          </div>
        </div>
      </div>
    
  );
};


export default BrandDashboard;
