
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getSecondaryEmails } from '@/apis/base/user/getSecondaryEmails';
import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';

export default function useMySecondaryEmails(params) {
    const query = useQuery({
        queryKey: ['user','me',ROUTE_IDS.GET_SECONDARY_EMAILS],
        queryFn: (params) => getSecondaryEmails(params),
        staleTime: MILLISEC.MIN_15, 
        placeholderData: []
    });
    return query;
}