import React, { useState } from "react";

import { useTranslations } from "@/hooks/translations";

import { PageTitle } from "@/components/Label/PageTitle";
import { BasicTabs } from "@/components/Tabs/BasicTabs";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import CurrentUsers from "./tabs/CurrentUsers";
import Requests from "./tabs/Requests";

const User = (props) => {
  const text = useTranslations("userManagement");
  const contextQuery = useCurrentOrgContext({})
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <PageTitle>{text.title}</PageTitle>
      <BasicTabs activeTab={activeTab} onTabClick={setActiveTab} tabNames={[text.current, text.requests]}>
        <CurrentUsers context={contextQuery.data}/>
        <Requests context={contextQuery.data} />
      </BasicTabs>
    </div>
  );
};

export default User;
