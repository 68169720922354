import { postPromise } from '@/apis/base';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import config from '@/apis/config';

/**
 *
 * @param {*} params
 * @param {[string]} params.orgids
 * @returns
 */
export const getProductCategories = (params) => {
  return postPromise(
    config.API_BASE_URL,
    composeFormData(params, ROUTE_IDS.GET_PRODUCT_CATEGORIES)
  );
};
