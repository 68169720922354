import React from 'react';
import { ChevronDown } from '../Icons';


export const BasicDropdownButton = ({label, className, customContent=false, ...props}) => {
    return (
        <div className="dropdown dropdown-hover">

            <button tabIndex={0} 
                className={`btn flex flex-nowrap items-center px-5 py-2 btn-sm border border-gray-border ${className} text-md`}>
                    <div className='justify-self-start  mr-2'>
                        {label}
                    </div>
                <ChevronDown className='w-5 h-5 justify-self-end '/>
            </button>

            { customContent ? (
                props.children
            ) : ( 
                <ul 
                    tabIndex={0} className="dropdown-content z-[14] menu p-2 shadow bg-base-100 rounded-box w-52">
                    {props.children}
                </ul>
            )}
        </div>
    );
};

