import { ImageList, ImageListItem, styled } from '@mui/material';

const StyledListItem = styled(ImageListItem)`
  & {
    height: '50%';
  }
  & .MuiImageListItem-img {
    height: 35vh;
  }
`;

const GalleryOverlay = ({ imageLinks }) => {
  const cssDimensions =
    imageLinks?.length > 3
      ? { listHeight: '70vh', listBottomMargin: '0' }
      : { listHeight: '35vh', listBottomMargin: '35vh' };
  return (
    <ShadedContainer>
      <ImageList
        cols={3}
        sx={{
          height: cssDimensions.listHeight,
          width: '105vh',
          marginBottom: cssDimensions.listBottomMargin,
          placeSelf: 'center',
          marginLeft: '10vw',
        }}
        className=" scrollbar-hide"
      >
        {imageLinks.map((link) => (
          <StyledListItem
            key={link}
            sx={{ height: '35vh', width: 'auto', flexGrow: 0 }}
          >
            <img
              className=" object-cover"
              src={`${link}`}
              srcSet={`${link}`}
              alt="Gallery item"
              loading="lazy"
            />
          </StyledListItem>
        ))}
      </ImageList>
    </ShadedContainer>
  );
};

const ShadedContainer = ({ children }) => {
  return (
    <div className="pointer-events-none fixed w-screen h-screen top-0 left-0 bg-[rgb(0,0,0,0.5)] z-30 flex justify-center align-middle">
      {children}
    </div>
  );
};

export default GalleryOverlay;
