import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { DashboardCard } from "@/components/Cards/DashboardCard";
import DropdownTwo from "@/components/DropDownTwo";
import { ExcelIcon } from "@/components/Icons";
import { BasicBoxTabs } from "@/components/Input/BasicBoxTabs";
import MultiSelectDropdown from "@/components/Input/MultiSelectDropdown";
import { OrgWishlistTable } from "@/components/Tables/OrgWishlistTable/OrgWishlistTable";
import { WISHLIST_VIEW_TYPE } from "@/constants";
import { useOrgsWishlistReportAndDemand } from "@/hooks/state/wishlist/helpers/useOrgsWishlistReportAndDemand";
import { useWishlistFilters } from "@/hooks/state/wishlist/helpers/useWishlistFilters";
import { useWishlistObject } from "@/hooks/state/wishlist/helpers/useWishlistObject";
import { arrayToObject, flattenOrgs, getDashedDateString, getDateString, getNiceDateString } from "@/utils";
import { downloadExcel } from "@/utils/fileUtils/downloadExcel";
import { compareWishlistProducts, getFlatWishlistProductsWithSuperCategory } from "@/utils/misc";
import { WishlistGrid } from "@/views/pages/Admin/ProductManagement/components/WishlistGrid";
import React, { useMemo } from "react";

const tabNames = ["Grid", "Table"];
const TABS = {
    GRID: 0,
    TABLE: 1,
};
export const WishlistCommunityReport = ({
    orgsQuery,
    wishlistTreeQuery,
    geoQuery,
    categoryQuery,
    productsQuery,
    demographicsQuery,
    ...props
}) => {
    const [activeTab, setActiveTab] = React.useState(null);
    const [selectedGeo, setSelectedGeo] = React.useState(null);
    const [selectedOrg, setSelectedOrg] = React.useState(null);
    const {
        demoFilter,
        catFilter,
        handleCatFilterChange,
        handleDemoFilterChange,
    } = useWishlistFilters(demographicsQuery.data, categoryQuery.data?.flat);
    const { demandQueries, reportQueries } = useOrgsWishlistReportAndDemand({
        orgsQuery,
    });

    const flatOrgs = useMemo(() => {
        if (!orgsQuery.data) return [];
        const clone = structuredClone(orgsQuery.data);
        return flattenOrgs(clone).filter(
            (org) => !(org.brand == "true") && !(org.processor == "true")
        );
    }, [orgsQuery.data]);
    const wishlistObj = useWishlistObject({
        view: WISHLIST_VIEW_TYPE.ORG_ADMIN,
        communityWishlistObj: demandQueries.data[selectedOrg?.id],
        orgWishlistReportObj: reportQueries.data[selectedOrg?.id],
        wishProductsData: productsQuery.data,
    });

    function exportWishlist(allCommunities) {
        const today = new Date();
        const todayInSecs = today.getTime() / 1000;
        const filename = allCommunities
            ? "all_community_wishlists_" + getDashedDateString(todayInSecs)
            : `${selectedOrg.name}_community_wishlist_` +
            getDashedDateString(todayInSecs);
        // Combine demand and report data
        const demandAndReportObj = {};
        const orgsToExport = allCommunities
            ? flatOrgs.filter(
                  (org) => !(org.brand == "true") && !(org.processor == "true")
              )
            : [selectedOrg];
        for (const org of orgsToExport) {
            demandAndReportObj[org.id] = {};
            for (const [pid, reportQty] of Object.entries(
                reportQueries.data?.[org.id]?.wishlist || {}
            )) {
                if (!demandAndReportObj[org.id][pid]) {
                    demandAndReportObj[org.id][pid] = {};
                }
                demandAndReportObj[org.id][pid].report = reportQty.quantity;
            }
            for (const [pid, demandQty] of Object.entries(
                demandQueries.data[org.id] || {}
            )) {
                if (!demandAndReportObj[org.id][pid]) {
                    demandAndReportObj[org.id][pid] = {};
                }
                demandAndReportObj[org.id][pid].demand = demandQty;
            }
        }
        const flatProductsWithSupercategory = getFlatWishlistProductsWithSuperCategory(wishlistTreeQuery.data)
        const flatProductsWithSupercategoryObj = arrayToObject(
            flatProductsWithSupercategory,
            "id"
        );
        // Get data into name / rows format for excel
        const sheets = Object.entries(demandAndReportObj).map(
            ([orgid, products]) => ({
                name: flatOrgs.find((org) => org.id === orgid).name,
                rows: Object.entries(products).map(
                    ([pid, { demand, report }]) => {
                        let targetProduct =
                            flatProductsWithSupercategoryObj[pid];
                        if (!targetProduct) return null
                        return {
                            Category: targetProduct.superCategory,
                            Subcategory: targetProduct.subCategory,
                            "Product Name": targetProduct.name,
                            "Demographic Name": targetProduct.targetname,
                            Description: targetProduct.description,
                            "Requested Amount": demand || "0",
                            "Community Demand": report || "0",
                        };
                    }
                ).filter(Boolean).sort(compareWishlistProducts),
            })
        );
        const columnWidths = [13,26,28,18,25,17,18] // By trial and error
        console.log(filename);
        downloadExcel(sheets, filename, columnWidths);
    }

    const exportNotReady = !wishlistTreeQuery.isSuccess  || !productsQuery.isSuccess || demandQueries.isPending || reportQueries.isPending;

    return (
        <div>
            <DashboardCard className={"place-self-start max-w-[23rem] mb-4"}>
                <DropdownTwo
                    labelClassName={
                        !selectedOrg
                            ? "border border-2 !border-blue-cornflower"
                            : ""
                    }
                    showClearButton
                    isSearchable={true}
                    options={flatOrgs}
                    value={selectedOrg}
                    onChange={setSelectedOrg}
                    placeHolder={
                        <p className="text-blue-cornflower">
                            Select an organization to get started
                        </p>
                    }
                    valueFn={(val) => val}
                    renderFn={(val) => val?.name}
                    displayFn={(val) => (
                        <p>
                            {" "}
                            Showing results for <strong>{val?.name}</strong>
                        </p>
                    )}
                />
            </DashboardCard>
            <div className="place-self-start flex gap-4 items-end">
                <DashboardCard className={"max-w-[20rem]"}>
                    <BasicBoxTabs
                        className="tabs-lg w-full"
                        tabNames={tabNames}
                        activeTab={activeTab}
                        onChange={setActiveTab}
                    />
                    <div className="flex flex-col md:flex-row w-fit gap-5">
                        <MultiSelectDropdown
                            placeHolder="Filter categories"
                            options={categoryQuery.data?.tree}
                            renderFn={(val) => val?.name}
                            onToggle={handleCatFilterChange}
                            selectedIDs={catFilter}
                            className="w-[50rem]"
                        />
                        <MultiSelectDropdown
                            placeHolder="Filter demographics"
                            options={demographicsQuery.data}
                            renderFn={(val) => val?.name}
                            onToggle={handleDemoFilterChange}
                            selectedIDs={demoFilter}
                            className="w-72"
                        />
                    </div>
                </DashboardCard>
                <div className="flex flex-col gap-4">
                    <BasicRectButton
                        disabled={exportNotReady}
                        customSize
                        className={
                            "btn-neutral w-[20rem] justify-between  btn-little "
                        }
                        onClick={() => exportWishlist(true)}
                    >
                        {exportNotReady ? (
                            <div className="loading"></div>
                        ) : (
                            <>Export all community wishlists</>
                        )}
                        <ExcelIcon className="fill-green-600 w-6 h-6" />
                    </BasicRectButton>
                    <BasicRectButton
                        disabled={exportNotReady || selectedOrg == null}
                        customSize
                        className={
                            "btn-neutral w-[20rem] justify-between  btn-little "
                        }
                        onClick={() => exportWishlist(false)}
                    >
                        {exportNotReady ? (
                            <div className="loading"></div>
                        ) : (
                            <>
                            Export current community wishlist
                            </>
                        )}
                        <ExcelIcon className="fill-green-600 w-6 h-6" />
                    </BasicRectButton>
                </div>
            </div>
            {selectedOrg && activeTab === TABS.GRID && (
                <div className="mt-5">
                    <WishlistGrid
                        {...{
                            view: WISHLIST_VIEW_TYPE.REPORT_AND_DEMAND,
                            catFilter,
                            demoFilter,
                            wishlistObj,
                            reportProps: {
                                geonodeFocus: selectedGeo,
                            },
                        }}
                    />
                </div>
            )}
            {selectedOrg && activeTab === TABS.TABLE && (
                <OrgWishlistTable
                    data={wishlistTreeQuery.data}
                    catFilter={catFilter}
                    demoFilter={demoFilter}
                    wishlistObj={wishlistObj}
                />
            )}
        </div>
    );
};
