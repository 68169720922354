import config from "@/apis/config";
import { getCookie } from "@/utils";
import axios from "axios";
import { ROUTE_IDS, composeFormData } from "../routeId";

export const refreshToken =  () => {
    const refreshPayload = new FormData();
      refreshPayload.append('type', ROUTE_IDS.REFRESH);
      let refreshToken = getCookie('refreshToken');
      if (refreshToken != null) {
          return axios.post(
            config.API_BASE_URL,
            composeFormData({}, ROUTE_IDS.REFRESH),
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
                Accept: '*/*',
              },
            }
          );
      } else {
        throw new Error('No refresh token found');
      }
}