

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation,useQuery,useQueryClient } from '@tanstack/react-query';
import { getOrgPopups } from '@/apis/base/donation/popupStore/getOrgPopups';

export default function useOrgPopups({orgid}) {
    const query = useQuery({
        queryKey: ['popup', 'org', orgid, ROUTE_IDS.GET_ORG_POPUPS],
        queryFn: (params) => getOrgPopups({orgid}),
        emabled: !!orgid,
        staleTime: MILLISEC.MIN_15,
    });
    return query;
}