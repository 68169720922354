
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getOrgWishlist } from '@/apis/base/wishlist/getOrgWishlist';
import { arrayToObject } from '@/utils';


export default function useOrgWishlist({orgID, enabled=true}) {
    const query = useQuery(orgWishlistConfig({orgID, enabled}));
    return query;
}

export const orgWishlistConfig = ({orgID, enabled=true}) => ({
    queryKey: ['org', orgID , 'wishlist', ROUTE_IDS.GET_ORG_WISHLIST],
    queryFn: () => getOrgWishlist({orgid: orgID}),
    staleTime: MILLISEC.MIN_15,
    enabled: enabled && !!orgID,
})