
import useAppStatusEnum from '@/hooks/state/enum/useAppStatusEnum';
import React from 'react';


export const RolesList = ({user, roles,  onRoleClicked, appData, text}) => {
    const appStatusQuery = useAppStatusEnum()
    console.log(roles[0].statusKey, appData?.[roles[0].statusKey], appData);
    return (
        <>
            {   roles.map((role, idx) => (
                <Role
                    key={idx}
                    label={role.label}
                    type={role.type}
                    status={appData?.[role.statusKey]}
                    {...{ onRoleClicked, text}}/>
            ))
            }
            { user?.guest && (
            <div className="xs:flex flex-7">
                    <div className="flex-2">
                    <span
                        className={`text-gray-500 `}>
                        Guardian
                    </span>
                    </div>
                    <div className="flex-5">
                    {user?.guardianStatus === appStatusQuery.data?.approved ? (
                        <span
                        className="w-fit-content bg-green-pale-lime text-green-dark-lime rounded-6 py-px px-2.5 mb-1 text-xs">
                            {text.active}
                        </span>
                    ) : (
                        <span
                        className="w-fit-content bg-gray-200 text-gray-500 rounded-6 py-px px-2.5 mb-1 text-xs">
                            {text.none}
                        </span>
                    )}
                    </div>
                </div>
            )}
        </>
    )}

const Role = ({label, status, type ,onRoleClicked, text}) => (
    <div>
        <div className="xs:flex flex-7" >
            <div className="flex-2" >
                <span
                className={`text-gray-500 ${status === 'Approved' ? "cursor-pointer underline" : ""}`}
                onClick={status === 'Approved' ? 
                    () => onRoleClicked(type) 
                    : undefined}>
                {label}
                </span>
            </div>
            <div className="flex-5">
                {status === 'Approved' ? (
                <span
                    className="w-fit-content bg-green-pale-lime text-green-dark-lime rounded-6 py-px px-2.5 mb-1 text-xs">
                    {text.active}
                    </span>
                ) : (
                <span
                    className="w-fit-content bg-gray-200 text-gray-500 rounded-6 py-px px-2.5 mb-1 text-xs">
                    {text.none}
                    </span>
                )}
            </div>
        </div>

    </div>
)


