import { EditShipmentTable } from '@/components/Tables/EditShipmentTable/EditShipmentTable';
import useCreateBDRequest from '@/hooks/state/donation/request/useCreateBDRequest';
import useModBDRequest from '@/hooks/state/donation/request/useModBDRequest';
import useModBDShipment from '@/hooks/state/donation/shipment/useModBDShipment';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import { closeDialogWithRef, resetFormWithId } from '@/utils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import React, { useEffect, useMemo } from 'react';
import { SendIcon } from '../Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons';
import { RequestIcon } from '../Icons';
import { OrgLogo } from '../Image/OrgLogo';
import { TableSkeleton } from '../Skeletons/TableSkeleton';
import { BasicDialog } from './BasicDialog';


export const EditShipmentDialog = ({dialogRef, canEditRequest, canEditShipment, row, event, ...props}) => {
    const [values, setValues] = React.useState({})
    const recipientQuery = useOrgWithId({org_id: [row?.orgid]})
    const {data: ctx} = useCurrentOrgContext() || {}
    const shipmentMutation = useModBDShipment({
        onSuccess: (data) => {SuccessToast.fire({text: "Shipment Updated"})},
        onError: (error) => {FailPopup.fire({text: "Failed to update shipment"})},
        onSettled: handleRequestSettled
    })
    const requestMutation = useModBDRequest({
        onSuccess: (data) => {SuccessToast.fire({text: "Request Updated"})},
        onError: (error) => {FailPopup.fire({text: "Failed to update request"})},
        onSettled: handleRequestSettled
    })
    const title = useMemo(() => {
        let titleStart = ''
        if (canEditRequest && canEditShipment) titleStart = 'Edit Request and Shipment'
        else if (canEditRequest) titleStart = 'Edit Request'
        else if (canEditShipment) titleStart = 'Edit Shipment'
        return (
            <p>
                {titleStart}  for  <span className='underline'>{row?.name || row?.orgname}</span>
            </p>
        )
    },[canEditRequest, canEditShipment, row])

    function handleRequestSettled() {
        closeDialogWithRef(dialogRef)
        resetFormWithId('shipmentForm')
    }

    const submitRequest = () => {
        const params = {
            orgid: row.orgid,
            eventid: event.id,
            lineitems: Object.values(values).map(inv => (
                {
                    inventoryid: inv.inventoryid,
                    quantity: inv.requestquantity
                }
            ))
        }
        requestMutation.mutate(params)
    }

    const submitShipment = () => {  
        const params = {
            recipientorgid: row.orgid,
            eventid: event.id,
            lineitems: Object.values(values).map(inv => (
                {
                    inventoryid: inv.inventoryid,
                    quantity: inv.shipmentquantity
                }
            ))
        }
        shipmentMutation.mutate(params,{
            onSuccess: (data) => {
                if (!ctx?.isUICContext) 
                    closeDialogWithRef(dialogRef)
            }
        })
    }

    useEffect(() => {
        if (!event?.donations?.length) return
        const initVals = {}
        event?.donations?.forEach(d => {
            initVals[d.id] = {
                productname: d.productname,
                inventoryid: d.id,
                requestquantity: row?.lineitems?.[d.id]?.requestquantity || 0,
                shipmentquantity: row?.lineitems?.[d.id]?.shipmentquantity || 0, 
            }
        })
        setValues(initVals)
    },[row, event])

    const handleChange = (changedVal) => {
        setValues({
            ...values, 
            [changedVal.inventoryid] : changedVal})
    }
    return  (
        <BasicDialog 
            ref={dialogRef}
            id='shipmentForm'
            title={title}
            className='w-[50rem] '
            hideActions
            {...props}
        >   
        {values && event && !recipientQuery.isLoading ? (
            <>
            <OrgLogo id={row?.orgid} customSize className={' z-[5]'}/>
            { values &&
                <form
                    id='shipmentForm'
                    onSubmit={(e) => e.preventDefault()}
                >
                    <EditShipmentTable
                        canEditRequest={canEditRequest}
                        canEditShipment={canEditShipment}
                        eventid={event?.id}
                        onInlineChange={handleChange}
                        items={values}
                    />
                </form>
            }
                <div className="my-4 flex w-full justify-end">
                    { canEditRequest &&
                        <button className="btn btn-secondary " onClick={submitRequest}>
                            Update Request
                            <RequestIcon className='w-5 h-5'/>
                        </button>
                    }
                    
                    { canEditShipment &&
                        <button 
                            className="btn btn-secondary ml-2 " 
                            onClick={submitShipment}
                        >
                            Update Shipment
                            <SendIcon className='w-5 h-5'/>
                        </button>
                    }

                    <button 
                        className="btn btn-neutral ml-2" 
                        onClick={() => closeDialogWithRef(dialogRef)}
                    >
                        Cancel
                    </button>
                </div>
            </>
        ) : (
            <div className='form-control items-center w-full gap-14'>
                <div className="skeleton rounded-xl w-1/3 aspect-video"/>
                <TableSkeleton numRows={3} rowHeightClass='h-18'/>
            </div>
        )}
        </BasicDialog>
    ) 
};



