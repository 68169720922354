

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie, requestStatusToLabel } from '@/utils';
import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserRoleRequests } from '@/apis/base/admin/getUserRoleRequests';
import useRoleEnum from '../enum/useRoleEnum';
import useAppStatusEnum from '../enum/useAppStatusEnum';


export default function useUserRoleRequests({orgid, userid, ...props}) {
    const roleEnumQuery = useRoleEnum()
    const appStatusQuery = useAppStatusEnum()
    const client = useQueryClient();
    const query = useQuery(userRoleRequestQueryConfig({orgid, userid, roleids: roleEnumQuery.data?.allRoles, appStatuses: appStatusQuery.data, ...props}));
    return query;
}

export function userRoleRequestQueryConfig({orgid, userid, roleids, appStatuses,  ...props}) {
    return queryOptions({
        queryKey: ['role', orgid, userid, ROUTE_IDS.GET_USER_ROLE_APPLICATIONS],
        queryFn: () => getUserRoleRequests({orgid, userid,'roleids[]': roleids, recursive: 0}),
        staleTime: 0,
        enabled: !!orgid && !!userid && !!roleids,
        notifyOnChangeProps: ['data'],
        select: (data) => {
            
            const rd = data.roledata;
            data.finStatus = requestStatusToLabel(rd.fin?.status, appStatuses);
            data.ambStatus = requestStatusToLabel(rd.ambassador?.status, appStatuses);
            data.smiStatus = requestStatusToLabel(rd.smi?.status, appStatuses);

            data.finapp = rd.fin;
            data.ambapp = rd.ambassador;
            data.smiapp = rd.smi;
            return data
        },
        ...props
    })
}