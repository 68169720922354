import useCityContext from "@/hooks/state/misc/useCityContext";
import useOrgsByCity from "@/hooks/state/organization/useOrgByCity";
import { getCookie } from "@/utils";
import { LocalCommunitiesTable } from "./LocalCommunitiesTable";

export const LocalCommunities = () => {
    const city = getCookie('cityContextId')
    const orgsQuery = useOrgsByCity({city})
    const cityContextQuery = null //useCityContext()
    const {data: cityContext} = cityContextQuery
    const communitiesQuery = useOrgsByCity({city: cityContext?.id, recursive: 1})
    console.log(communitiesQuery)
    

    return (
        <div className="w-full min-h-screen bg-base-200 flex flex-col py-12 px-28">
            <div className="flex flex-row w-full justify-between">
                <p className="place-self-start text-4xl font-semibold text-black">
                    {`${cityContext?.name || ''} Communities`}
                </p>
                <p className="btn btn-ghost font-light text-lg hover:bg-transparent ">+ Add Community</p>

            </div>
            <div className="pt-5 w-full bg-base-100 mt-4 rounded-2xl shadow-md">
                {  communitiesQuery.data?.length > 0 &&
                    <LocalCommunitiesTable data={communitiesQuery.data} />
                }
            </div>
        </div>
    )
}