import { BasicUserDropdown } from '@/components/Input/BasicUserDropdown';
import useTransferOrgOwnership from '@/hooks/state/admin/useTransferOrgOwnership';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import React, { useMemo } from 'react';


const CONFIRMATION_LEVEL = {
    FIRST: 1,
    SECOND: 2,
}
export const TransferOwnership = ({admins, exit, context, org, ...props}) => {
    const [newOwner, setNewOwner] = React.useState('')
    const [confirmed, setConfirmed] = React.useState(CONFIRMATION_LEVEL.FIRST) 
    const transferOwnershipMutation = useTransferOrgOwnership({
        contextOrgID: context?.orgid,
        ...queryResultFns(
            'Successfully transferred master admin role',
            'Failed to transfer master admin role',
            () => exit()
        )

    })
    const nonMasterAdmins = useMemo(() => 
        org?.admins?.filter?.(admin =>
             String(admin.id) !== String(org?.owner)
        ),[context])
    function handleConfirmPressed() {
        if (confirmed === CONFIRMATION_LEVEL.FIRST)  {
            return setConfirmed(CONFIRMATION_LEVEL.SECOND)
        }
        transferOwnership()
    }
    function transferOwnership() {
        transferOwnershipMutation.mutate({orgid: org?.orgid, newowner: newOwner.id})
    }

    return (
        <div>
            <BasicUserDropdown 
                className='max-w-[22rem]'
                users={nonMasterAdmins}
                placeHolder={
                    <span className='text-sm tracking-tighter'>
                        Choose new master admin from list of admins
                    </span>}
                value = {newOwner}
                onChange={setNewOwner}
            />
            <div 
                onClick={handleConfirmPressed}
                className={`btn 
                    max-w-[22rem] w-full
                    ${confirmed === CONFIRMATION_LEVEL.FIRST ? 'btn-secondary' : 'btn-error'}
                `}>
                {confirmed === CONFIRMATION_LEVEL.FIRST 
                    ? 'Confirm new master admin' 
                    : 'Are you Sure? (click again)'}
            </div>
        </div>
    );
};
