
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation,useQueryClient } from '@tanstack/react-query';
import { modPopup } from '@/apis/base/donation/popupStore/modPopup';


export default function useModPopup(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_POPUP],
        mutationFn: modPopup,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['popup', req.popupid]})
            client.invalidateQueries({queryKey: ['popup', 'org', req.orgid]})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}