import React, { useEffect, useRef } from 'react';

export const HamburgerButtonDropdown = ({...props}) => {
    const detailsRef = useRef(null)
    function handleBlur(e) {
        if (e.currentTarget.contains(e.relatedTarget)) return;
        detailsRef?.current?.removeAttribute?.('open')
    }
    return (
        <>
            <details ref={detailsRef} className="dropdown dropdown-open dropdown-left  dropdown-bottom" onBlur={handleBlur}>
                    <summary className="swap swap-rotate">
                        <svg className="swap-off fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"/></svg>
                        
                        <svg className="swap-on fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"/></svg>
                    </summary>

                    <ul  tabIndex={0} className="dropdown-content bg-white  z-[1] menu p-2 rounded-box w-52 shadow-card">
                        {props.children}
                    </ul>
            </details>
        </>
    );
};

