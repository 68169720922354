import { BasicTable } from '@/components/Tables/BasicTable';
import { wishlistReportColumnHelper } from '@/components/Tables/WishlistReportTable/wishlistReportColumnHelper';
import { recursiveSubnodeHelper } from '@/utils';
import React, { useMemo } from 'react';

export const WishlistReportTable = ({
    data,
    catFilter, 
    demoFilter,
    wishlistObj,  ...props}) => {
        const tableData = useMemo(() => {
            if (!wishlistObj || !data) return []
            const clone = structuredClone(data)
            recursiveSubnodeHelper({subnodes: clone}, (node) => {
                node.isProduct = false
                node.categoryid = node.id
                node?.products?.forEach((p) => {
                    p.categoryid = node.id
                    p.isProduct = true
                    const wishlistItem = wishlistObj[p.id]
                    if (wishlistItem) p.quantities = wishlistItem
                    else p.isProduct = false
                })
            },false, false)
            return clone
        },[data, wishlistObj])
    
    return (
        <BasicTable
            data={tableData}
            columnHelper={wishlistReportColumnHelper}
            showLoading={wishlistObj == null || tableData == null}
            subrowFn={(row) => row.subnodes || row.products || []}
            tableProps={{filterFromLeafRows: true}}
            canExpand
            initialState={{
                columnVisibility: {
                }
            }}
            extraFilters={[
                {id: 'Category ID', value: catFilter},
                {id: 'Demographic ID', value: demoFilter}
            ]}
            meta={{
                wishlistObj,
            }}
        />

        
    );
};
