import useGeoData from "@/hooks/state/misc/useGeoData"
import { arrayToObject, recursiveSubnodeHelper } from "@/utils"

export const useGeoIDs = () => {
    const geoQuery = useGeoData({
        select: (data) => {
            const regions = arrayToObject(data[0]?.subnodes,'id')
            const cs = []
            for (const r of data[0]?.subnodes) {
                for (const c of r.subnodes) {
                    cs.push(c)
                }
            }
            const cities = arrayToObject(cs, 'id')

            return {regions, cities}
        }
    })
    return geoQuery
}