import { makeStyles } from '@material-ui/core/styles';
import { PersonAdd } from '@material-ui/icons';
import React, { useState } from 'react';

import { PageTitle } from '@/components/Label/PageTitle';
import { UICAdminTable } from '@/components/Tables/UICAdminTable.jsx/UICAdminTable';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import { invite } from '../../../../apis/base/auth';
import { UIC_ORG, userTypes } from '../../../../constants';
import { useAppContext } from '../../../../context/AppContext';
import { useTranslations } from '../../../../hooks/translations';
import { validateEmail } from '../../../../utils';
import { DevOnly } from '@/components/Misc/DevOnly';
import { Link } from 'react-router-dom';
import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';

function UIC() {
  const text = useTranslations('uicManagement');
  const uicQuery = useOrgWithId({showMembers: true, recursive: false, org_id: UIC_ORG });
  const members = uicQuery.data?.[0]?.members;
  
  return (
    <div>
      <PageTitle>
        {text.title}
        <div className='flex gap-2 items-center'>
          <DevOnly>
            <Link to={{pathname: 'debug', }}>
              <button className='btn btn-sm btn-primary btn-little'>
                Dev
              </button>
            </Link>
          </DevOnly>
          <BasicBlackButton customSize className={'btn-md  btn-square rounded-xl !p-3 grid'} // onClick={() => onInvite()}
          >
            <PersonAdd />
          </BasicBlackButton>
        </div>
      </PageTitle>
      <UICAdminTable data={members}/>
    </div>
  );
}

export default UIC;
