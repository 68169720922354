import { OrgCell } from '@/components/Tables/Cells/OrgCell';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import React from 'react';

export const ExpandedUserRow = ({row, ...props}) => {
    const orgsQuery = useOrgWithId({org_id: row.original.orgs, recursive:false})
    return (
        <div className='form-control gap-2 py-10`'>
            { orgsQuery.data?.map?.((org) => (
                <div className="flex gap-2" key={org.id}>
                    <OrgCell
                        org={org} 
                    />
                </div>
            ))}
        </div>
    );
};
