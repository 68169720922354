import { BasicBackButton } from '@/components/Buttons/BasicBackButton';
import { PageTitle } from '@/components/Label/PageTitle';
import React from 'react';

export const BrandDonationSummary = (props) => {
    const {history, match} = props || {}
    const donationID = match?.params?.id;
	const handleBack = () => {
		history.goBack()
	};

    return (
        <div className="flex flex-col gap-5">
            <div className="w-full h-full flex flex-col gap-5">

                <div className="flex w-1/2 justify-between">
                    <span className="text-4xl font-semibold text-black">
                            <BasicBackButton onClick={handleBack} className={'absolute'} label={'Back to Donations' }/>
                    </span>
                    <div className="justify-self-end align-bottom"></div>
                </div>

                <span className="flex justify-start">
                    <PageTitle className={'w-fit'}>
                        <span>Donation Summary – 
                            <span className="font-medium"></span>
                        </span>
                    </PageTitle>
                </span>
            </div>
        </div>
    );
};