import { getAllUserWishlists } from '@/apis/base/admin/uic/getAllUserWishlists';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';


export default function useAllUserWishlists() {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_ALL_USER_WISHLISTS],
        queryFn: (params) => getAllUserWishlists(params),
        staleTime: MILLISEC.MIN_15,
    });
    return query;
}
