import React, { useEffect } from "react";
import useBDRequest from "../request/useBDRequest";
import useBDShipment from "../shipment/useBDShipments";
import { arrayToObject } from "@/utils";
import useBDEventByID from "../useBDEventByID";
import useLiveBDEvents from "../useLiveBDEvent";

export function useCommunityEventState({orgid, brandorgid, eventid}) {
    const [eventState, setEventState] = React.useState({orgid, brandorgid, eventid, lineItems: []})
    const requestQuery = useBDRequest({orgid, eventid})
    const shipmentQuery = useBDShipment({orgid, eventid})
    const eventsQuery = useLiveBDEvents()
    // console.log('data', event);
    useEffect(() => {
        // console.log('running');
        const event = eventsQuery.data?.find((event) => event.id === eventid)
        if (!event) return
        const lineItems = structuredClone(event?.donations)
        const reqLineItems = arrayToObject(requestQuery.data?.lineitems || [], 'inventoryid')
        const shipLineItems = arrayToObject(shipmentQuery.data?.lineitems || [], 'inventoryid')
        const initState = {...eventState ,hasRequest: false, hasShipment: false, hasConfirmed: false, hasAllConfirmed: true}
        // console.log(reqLineItems);
        const finalLineItems = lineItems
            ?.filter(item => item.active === '1')
            ?.map?.((item) => {
                const fItem =  {
                productname: item.productname,
                description: item.description,
                inventoryid: item.id,
                inventoryQuantity: Number(item.quantity),
                requestQuantity: Number(reqLineItems[item.id]?.request?.quantity) || 0,
                requestModifier: reqLineItems[item.id]?.['modified by'],
                requestModified: reqLineItems[item.id]?.modified,
                shipmentQuantity: Number(shipLineItems[item.id]?.quantity) || 0,
                confirmedQuantity: Number(shipLineItems[item.id]?.qtyconfirmed) || 0 ,
                confirmer: shipLineItems[item.id]?.['confirmed by'],
            }
            // console.log(fItem);
            if (fItem.requestQuantity > 0) initState.hasRequest = true
            if (fItem.shipmentQuantity > 0) initState.hasShipment = true
            if (fItem.confirmedQuantity > 0) initState.hasConfirmed = true
            if (fItem.confirmedQuantity > 0) {
                if (fItem.confirmedQuantity < fItem.shipmentQuantity) initState.hasAllConfirmed = false
            }
            return fItem
        })
    if (!initState.hasShipment) initState.hasAllConfirmed = false
    setEventState({ ...eventState, ...initState, lineItems:  finalLineItems})
    },[eventsQuery.data, requestQuery.data, shipmentQuery.data])
    // console.log('nums', brandorgid, eventid, orgid);
    if (requestQuery.isSuccess)
        return eventState
}


