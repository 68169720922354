import { postPromise } from '@/apis/base';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import config from '@/apis/config';

// export const setMyBrands = (params) => {
//   return postPromise(
//     config.API_BASE_URL,
//     composeFormData(params, ROUTE_IDS.SET_MY_BRANDS)
//   );
// };
export const setMyBrands = (brandIds) => {
    const formData = new FormData()
    console.log("ids",brandIds)
    for (let i = 0; i < brandIds.length; i++) {
        formData.append('brand_ids[]', brandIds[i])
    }
    formData.append('type',ROUTE_IDS.SET_MY_BRANDS)

  return postPromise(
    config.API_BASE_URL,
    formData
  );
};


