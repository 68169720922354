
import React, { useEffect } from 'react';


import { useAppContext } from '@/context/AppContext';

import { BasicTabs } from '@/components/Tabs/BasicTabs';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import ActiveDonations from './ActiveDonations';
import ClosedDonations from './ClosedDonations';
import PickupEvent from './PickupEvent';

const adminTabNames = ["active", "pickup", "closed"];
const memberTabNames = ["pickup", "closed"];

const SubPages = ({ text, history }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  // const { setDonations, setPickupDonations, setClosedDonations } = useDonationContext();
  const params = new URLSearchParams(history.location.search);
  const tab = params.get('tab');
  const { setMessage, setLoading } = useAppContext();
  const ctx = useCurrentOrgContext().data;
  useEffect(() => {
    if(tab) {
      setActiveTab(adminTabNames.indexOf(tab));
    }
  },[])
  const tabNames = ctx?.isAdmin ? adminTabNames : memberTabNames;

  return (
    <BasicTabs {...{tabNames}} autonomous activeTab={activeTab} onTabClick={setActiveTab}>
      { ctx?.isAdmin && <ActiveDonations text={text} history={history} /> }
      <PickupEvent text={text} history={history} />
      <ClosedDonations text={text} history={history}/>
    </BasicTabs>
  );
}

export default SubPages;
