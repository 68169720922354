import { withCookies } from 'react-cookie';
import { Redirect, Route, Switch } from 'react-router-dom';

import Footer from '../../../components/Footer';
import BrandSidebar from './components/BrandSidebar';

import { ChevronRight } from '@material-ui/icons';
import routes from '../../../routes';

function Brand(props) {
  const { history } = props;

  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === '/brand') {
        if (prop.collapse) {
          return prop.children.map((child, key) => (
            <Route
              path={prop.layout + prop.path + child.path}
              component={child.component}
              key={key}
            />
          ))
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };

  return (
    <div className="overscroll-none bg-base-200">
        <label htmlFor='my-drawer' className="btn hover:cursor-pointer btn-neutral  drawer-button absolute top-5 -left-[1px] rounded-l-none rounded-r-9">
          <ChevronRight />
        </label>
      <div className="drawer xl:drawer-open min-h-screen">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <BrandSidebar history={history} />
        <main className="drawer-content overscroll-none min-h-content mb-5">
          <div className='px-9 pb-9'>
            <Switch>
              {getRoutes()}
              <Redirect from="/brand" to="/brand/dashboard"/>
            </Switch>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default withCookies(Brand);
