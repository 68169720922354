
import { PopupPickupOrdersTable } from "@/components/Tables/PopupPickupOrdersTable/PopupPickupOrdersTable";
import React from "react";

const PickupRequestView = ({lineItems, popupName, text, popupActive,  eventid, pickupState, requests, history, popupid, loading, reloadDataFn}) => {
  return (
    <div className="mb-12">
      <PopupPickupOrdersTable
        {...{
          popupName,
          popupActive,
          requests,
          pickupState,
          eventid,
          popupid,
          loading,
          reloadDataFn
        }}>
          
        </PopupPickupOrdersTable>
    </div>
  )
};

export default PickupRequestView;
