import React from 'react';
import { BasicTable } from '../BasicTable';
import { EligibleLocationsColumnHelper } from './EligibleLocationsColumnHelper';


export const EligibleLocationsTable = ({handleEdit, data, canModify}) => {
    const safeData = data?.flatRegions?.length > 0 ? data?.flatRegions : [];
    // <></>
    return  (
        <BasicTable 
            data={safeData}
            initialPageSize={25}
            state={{
                columnVisibility: {
                    'Edit': canModify
                }
            }}
            columnHelper={EligibleLocationsColumnHelper}
            meta={{handleEdit,allCities:data?.cities}}/>

    );
};

