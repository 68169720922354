import React, { forwardRef } from 'react';

export const BasicToggle = forwardRef(({
    label = null,
    name,
    className,
    isError = false,
    errorText,
    required = false,
    vertical = false,
    containerClassName,
    onChange = () => {},
    ...props
}, ref) => {
    return (
        <div 
            className={`flex input-base gap-1
                ${vertical ? 'flex-col' : 'flex-row'}
                ${containerClassName}
            `}
        >
            <div className="flex text-xs font-[500] text-gray-subText">
                {label}
            </div>
            <input
                ref={ref}
                name={name}
                onChange={onChange}
                type='checkbox'
                className={`
                toggle
                toggle-little 
                focus:outline-none
                focus:border-primary
                border-gray-unselected
                [--tglbg:#DADADA]
                checked:[--tglbg:#D7FED1]
                checked:bg-green-text
                ${className}`}
                {...props}
            />
        </div>
    );
});
                

