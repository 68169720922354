import { BasicTable } from '@/components/Tables/BasicTable';
import { orgWishlistColumnHelper } from '@/components/Tables/OrgWishlistTable/orgWishlistColumnHelper';
import { recursiveSubnodeHelper, tailRecursiveSubnodeHelper } from '@/utils';
import React, { useMemo } from 'react';

export const OrgWishlistTable = ({
    data,
    catFilter, 
    demoFilter,
    wishlistObj, 
    maxQuantity,
    ...props}) => {
        const tableData = useMemo(() => {
            if (!wishlistObj || !data) return []
            const clone = structuredClone(data)
            tailRecursiveSubnodeHelper({subnodes: clone}, (node) => {
                node.isProduct = false
                node.categoryid = node.id
                node.quantities = {reportQty: 0, communityQty: 0}
                if (node.products?.length > 0)
                    node?.products?.forEach((p) => {
                        p.categoryid = node.id
                        p.isProduct = true
                        const wishlistItem = wishlistObj[p.id]
                        if (wishlistItem) {
                            p.quantities = wishlistItem
                            node.quantities.reportQty += Number(p.quantities.reportQty)
                            node.quantities.communityQty += Number(p.quantities.communityQty)
                        }
                        else p.isProduct = false
                    })
                else if (node.subnodes) {
                    for (const subnode of node.subnodes) {
                        node.quantities.reportQty += Number(subnode?.quantities?.reportQty) || 0
                        node.quantities.communityQty += Number(subnode?.quantities?.communityQty) || 0
                    }
                }
            },false, false)
            return clone
        },[data, wishlistObj])
    return (
        <BasicTable
            className='table-xs md:table-sm'
            showLoading={wishlistObj == null}
            subrowFn={(row) => row.subnodes || row.products || []}
            tableProps={{filterFromLeafRows: true}}
            canExpand
            data={tableData}
            columnHelper={orgWishlistColumnHelper}
            extraFilters={[
                {id: 'Category ID', value: catFilter || []},
                {id: 'Demographic ID', value: demoFilter || []}
            ]}
            meta={{
                wishlistObj,
            }}
            {...props}
        />
    );
};
