

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {modBDEvent } from '@/apis/base/donation/modBDEvent';

export default function useModBDEvent({...props}) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: ['bd', 'event', ROUTE_IDS.MOD_BD_EVENT],
        mutationFn: modBDEvent,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            // client.invalidateQueries({queryKey: ['bd', 'event']})
            // if (data?.status?.length > 0) throw new Error(data.status)
            client.invalidateQueries({queryKey: ['bd']})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}