import React from 'react'
import PropTypes from 'prop-types'

const FullStar = ({onClick}) => (
  <svg aria-hidden="true" className="w-7 h-7 text-blue-cornflower " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
  </svg>
)

const EmptyStar = ({onClick}) => (
  <svg aria-hidden="true" className="w-7 h-7 text-gray-300 dark:text-gray-500" fill="currentColor"
       viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path
      d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
  </svg>
)

const RatingBar = ({ value, onChange }) => {

  const [data, setData] = React.useState(value || 0)
  const full = Math.min(5, Math.floor(data))
  const empty = 5 - full;

  React.useEffect(() => {
    setData(value)
  }, [value])
  const handleChange = (idx) => {
    onChange && onChange(idx)
  }

  return (
    <div className="flex justify-center">
      {
        new Array(full).fill("").map((_, idx) => (
          <FullStar key={idx} onClick={() => handleChange(idx + 1)} />
        ))
      }
      {
        new Array(empty).fill("").map((_, idx) => (
          <EmptyStar key={idx} onClick={() => handleChange(full + idx + 1) }/>
        ))
      }
    </div>
  )
}

RatingBar.propTypes = {
  onChange: PropTypes.func,
  rate: PropTypes.number
}

RatingBar.defaultProps = {
  rate: 0
}

export default RatingBar;