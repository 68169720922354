import React from 'react';
import { InfoIcon } from '../Icons';


export const BasicDropdownTooltip = ({tip, title, text, titleClass, bgClass='bg-base-100', textClass, className, ...props}) => (
    <div className={`dropdown dropdown-top dropdown-hover  ${className}`}>
        {props.children}
        <div tabIndex={0} className={`card-compact shadow-all-light -translate-x-[50%] dropdown-content z-[1] rounded-box w-fit max-w-[30rem] min-w-[20rem] mb-4 ${bgClass}`}>

            <div tabIndex={0} className="card-body px-3 pb-5 pt-0">
                {tip}
                <p className="card-title w-full ">{title}</p> 
                <p className='w-full whitespace-pre-wrap'>{text}</p>
            </div>
        </div>
    </div>
);

