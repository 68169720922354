
import React from "react";

import useOrgPopups from "@/hooks/state/donation/popupStore/useOrgPopups";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { BasicBDPopupCard } from "../BasicBDPopupCard";

const ClosedDonations = (props) => {
  const { history, text } = props;
  const contextQuery = useCurrentOrgContext()
  const { data: ctx } = contextQuery || {}

  const popupsQuery = useOrgPopups({orgid: ctx?.orgid})
  

  const onDonationClicked = (popup) => {
    if (ctx.isAdmin || ctx.ambStatus === 'Approved') history.push(`/donation/pickup/${popup.eventid}/${popup.id}/admin`)
    else history.push(`/donation/pickup/${popup.eventid}/${popup.id}`)
  }

  return (
    <div className="mb-12">
      <div className="flex flex-col items-center">
        <div className="w-full">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8">
            { popupsQuery.data && 
              popupsQuery.data
                ?.filter?.(p => p.active === '0')
                ?.map?.((item, index) => (
                  <div key={`donation-pickup-${index}`}>
                    <BasicBDPopupCard
                      popup={item}
                      onClick={() => {onDonationClicked(item)}}
                    />
                  </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
};

export default ClosedDonations;
