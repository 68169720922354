import * as Yup from 'yup'
const yupImpl = Yup

function NaNCheck(schema) {
    // console.log('schema',schema');
    return this.transform(v => isNaN(v) ? null : v) 
}

function requiredNonNull(msg) {
    return this.required(msg).nonNullable(msg).typeError(msg)
}

function defaultRequiredEmptyMin(name, min=1,setToMin=false) {
    const requiredStr = `${name} is required`
    return this
        .min(min,`${name} must be greater than ${min}`)
        // .typeError(requiredStr)
        .nonNullable(requiredStr)
        // .required(requiredStr)
        .default('')
}

function emptyNum() {
    return this.default('').transform(v => v === '' ? null : v)
}

function priceStrToNum() {
    return this.transform((v,orig) => {
        return Number((orig)?.replace(/,/g, '')?.replace(/[^0-9.]|\.(?=.*\.)/g, ""))
    })
}


yupImpl.addMethod(Yup.number, 'NaNCheck', NaNCheck)
yupImpl.addMethod(Yup.number, 'requiredNonNull', requiredNonNull)
yupImpl.addMethod(Yup.number, 'defaultRequiredEmptyMin', defaultRequiredEmptyMin)
yupImpl.addMethod(Yup.number, 'priceStrToNum', priceStrToNum)

export default Yup