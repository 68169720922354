import useCityGeoData from '@/hooks/state/misc/useCityGeoData';
import useGeoData from '@/hooks/state/misc/useGeoData';
import useGeoNode from '@/hooks/state/misc/useGeoNode';
import { useTranslations } from '@/hooks/translations';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import Dropdown from './Dropdown';

const useRegionCitySelect = ({
  initialRegionID = null,
  initialRegionLabel = null,
  initialCityID=null,
  initialCityLabel=null,
  cityClassName='',
  regionClassName='',
  regionValue,
  cityValue,
  controlled=false,
  onRegionChange,
  onCityChange,
  disabled,
  regionError,
  cityError,
  regionErrorMessage,
  cityErrorMessage,
  regionPosition,
  cityPosition,
  fixed=false
}) => {
  const geoDataQuery = useGeoData();
  const selectedCountry = '27003'; // Canada
  const [selectedCity, setSelectedCity] = useState();
  const [cityNeedsReset,setCityNeedsReset] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState();
  const initialDataQuery = useGeoNode({
    nodeIds: 
    [...(initialRegionID ? [initialRegionID] : []),
    ...(initialCityID ? [initialCityID] : []) ]
  });
  const text = useTranslations('geography');
  const selectableRegions = useMemo(() => {
    return geoDataQuery.isSuccess
      ? geoDataQuery?.data?.[0]?.subnodes
      : [];
  }, [selectedCountry, geoDataQuery?.data]);

  let selectableCities = useMemo(() => {
    const r = controlled ? regionValue : selectedRegion
    return r
      ? geoDataQuery?.data?.[0]?.subnodes
          ?.filter((region) => region?.id === r.id)
            ?.pop()?.subnodes
      : [];
  },[selectedRegion, regionValue]);
useEffect(() => {
    if (!initialDataQuery.data) return
    setSelectedRegion(initialDataQuery.data.find(r=>r.id === initialRegionID))
    setSelectedCity(initialDataQuery.data.find(r=>r.id === initialCityID))
  } ,[initialDataQuery.data])

  return (
    { CountrySelect: <Dropdown
        fixed={fixed}
        enabled={false}
        options={[{key: '10003', label: 'Canada'}]}
        placeHolder={'Canada'}
        isSearchable={true}
        isError={regionError}
        errorText={regionErrorMessage}
        onChange={(key,label) => {}}
    />,
      RegionSelect: <Dropdown
        labelFn={r=>r.name}
        keyFn={r=>r.id}
        fixed={fixed}
        value={controlled ? regionValue : selectedRegion}
        position={regionPosition}
        className={regionClassName}
        placeHolder={initialRegionLabel || text.regionPlaceholder}
        placeHolderDark={initialRegionLabel != null}
        options={selectableRegions}
        isError={regionError}
        errorText={regionErrorMessage}
        isSearchable={true}
        onChange={(val) => {
          if (controlled) return onRegionChange(val)
          if (val !== selectedRegion)
            onRegionChange(val.id); 
            setSelectedRegion(val)
            setSelectedCity('')
            setCityNeedsReset(true)
          }}
      />,
      CitySelect: <Dropdown
        labelFn={c=>c.name}
        keyFn={r=>r.id}
        needsReset={cityNeedsReset}
        setNeedsReset={setCityNeedsReset}
        fixed={fixed}
        value={controlled ? cityValue : selectedCity}
        position={cityPosition}
        className={cityClassName}
        placeHolder={initialCityLabel ||text.cityPlaceholder}
        placeHolderDark={initialCityLabel != null}
        options={selectableCities}
        isError={cityError}
        isSearchable={true}
        errorText={cityErrorMessage}
        enabled={controlled ? !!regionValue?.id : !!selectedRegion?.id}
        onChange={(val) =>  {
          if (controlled) return onCityChange(val)
          onCityChange(val.id)
          setSelectedCity(val)
        }}
      />
    }
  );
};

const AllCitySelect = ({ position,initialLabel,error,errorMessage,enabled, onChange }) => {
  const [cookies, setCookie, deleteCookie] = useCookies(['cityContextId']); 

  const text = useTranslations('geography');
  const citiesQuery = useCityGeoData()
  return citiesQuery.isSuccess && (
    <Dropdown
        className="w-50 rounded-l-none rounded-r-lg h-full border-l-0 "
        position={position}
        placeHolder={initialLabel ||text.cityPlaceholder}
        placeHolderDark={initialLabel != null}
        options={citiesQuery.data.map(city => ({key: city.id, label: city.name}))}
        isError={error}
        isSearchable={true}
        errorText={errorMessage}
        enabled={enabled}
        onChange={({key, label}) =>  {onChange(key);console.log(key)}}
      />
  );
};

export { AllCitySelect, useRegionCitySelect };
