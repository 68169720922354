import { getNiceDateString } from '@/utils';
import { PlatformIcon } from '@/views/pages/Main/Home/components/Curator/PlatformIcon';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TikTokIcon, TwitterXIcon } from '@/views/pages/Main/Home/components/Curator/PlatformIcons';
import React from 'react';

const CommentIcon = () => (
    <svg width="22" height="22" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="32" d="M408 64H104a56.16 56.16 0 0 0-56 56v192a56.16 56.16 0 0 0 56 56h40v80l93.72-78.14a8 8 0 0 1 5.13-1.86H408a56.16 56.16 0 0 0 56-56V120a56.16 56.16 0 0 0-56-56Z"/></svg>
)


export const CuratorCard = ({post, selectPost, ...props}) => {    // console.log(post);
    const postedDate = new Date(post.source_created_at).getTime() / 1000
    return (
        <div className='' onClick={() => selectPost(post)}>
            <div className="w-full aspect-product overflow-hidden swap !place-content-start hover:swap-active">
                <div className="swap-off ">
                    <figure>
                        <img
                            className='object-cover w-full h-auto aspect-product'
                            src={post?.image}
                            alt={'post_image'}
                        />
                    </figure>
                    <PlatformIcon className={'absolute p-1 right-0 bottom-0 w-5'} platform={post?.network_name}/>
                </div>
                <div className="swap-on bg-white text-center  !place-content-start overflow-hidden">
                    <div className='absolute bg-gradient-to-b from-transparent from-70% to-90% to-white w-full h-full left-0 top-0'/>
                    <h2 className='font-medium pb-2 px-2 relative pt-6'>{post.user_screen_name}</h2>
                    <p className='text-sm px-2 text-clip whitespace-break-spaces break-words'>{post.text}</p>
                    <div className="border-t-2 mt-auto absolute bottom-0 left-0 flex justify-between h-10 py-2 px-2 items-end bg-white w-full">
                        <figure className='h-full w-auto'>
                            <img className='h-full aspect-square' src={post.user_image} alt="user_image" />
                        </figure>
                        <span className='text-sm font-light opacity-60'>
                            {getNiceDateString(postedDate, true)}
                        </span>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10 9V5l-7 7l7 7v-4.1c5 0 8.5 1.6 11 5.1c-1-5-4-10-11-11"/></svg>
                    </div>
                </div>
            </div>
        </div>
    );
};


// https://www.facebook.com/share_channel/?link=https%3A%2F%2Fwww.instagram.com%2Fp%2FC2aRuRLL5io%2F&app_id=966242223397117&source_surface=external_reshare&display&hashtag