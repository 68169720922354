import config from '@/apis/config';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import api from '@/apis/api';

const sendOrgInvite = (params) => {
  return new Promise((resolve, reject) => {
    const formData = composeFormData(params, ROUTE_IDS.SEND_ORG_INVITE);
    api
      .post(config.API_BASE_URL, formData)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error );
        reject(error);
      });
  });
};

export { sendOrgInvite };
