import config from '@/apis/config';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import api from '@/apis/api';
import { jsonToFormData } from '@/utils';

/**
 * 
 * @param {Object} param0
 * @param {Object} param0.params
 * @param {string} param0.params.email
 * @param {string} param0.params.firstname
 * @param {string} param0.params.lastname
 * @param {string} param0.params.occupation
 * @param {string} param0.params.password
 * @param {string} param0.params.phone_number
 * @param {string} param0.params.org_id
 * @param {string} param0.params.invitecode

 * @returns {Promise<any>}
 */
const verifyEmail = (params) => {
  return new Promise((resolve, reject) => {
    let formData = composeFormData(params, ROUTE_IDS.VERIFY_EMAIL);
    api
      .post(config.API_BASE_URL, formData)
      .then((response) => {
        console.log('response in verifyEmail', response);
        if (response.status === 200 && response?.data?.status !== "not verified") {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
};

export { verifyEmail};
