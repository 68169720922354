
import React, { useEffect, useState } from 'react';


import { UIC_ORG } from '@/constants';
import useSignupMember from '@/hooks/state/auth/useSignupMember';
import useMinimalOrgs from '@/hooks/state/organization/useMinimalOrgs';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useAppContext } from '../../../../../context/AppContext';
import { ContactRegister } from './ContactRegister';
import { JoinOrgPicker } from './JoinOrgPicker';
import RegisterContainer from './RegisterContainer';
import { StudentRegisterStepper } from './Steppers';
import { UserRegister } from './UserRegister';
import { Stepper } from '@/views/pages/Auth/Signup/components/Stepper';
import { OrgSearchBar } from '@/components/Input/OrgSearchBar';
import { PoweredBy } from '@/views/pages/Auth/components/PoweredBy';


function StudentRegister({
  text,
  STEP,
  step,
  setStep,
  progress,
  setProgress,
  linkOrg,
  code='',
  history,
}) {
  const [emailDomainIncorrectMessage, setEmailDomainIncorrectMessage] =
    useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [values, setValues] = useState({});

  // Get all organizations
  const orgsQuery = useMinimalOrgs({})
  // Query mutation for signing up user
  const signupMutation = useSignupMember({
    onSuccess: (result) => {
      SuccessToast.fire({
        text: text.registrationSuccess,
      });
      // if (code) 
      //   history.push('/auth/login');
      // else 
      setStep(step + 1)
    },
    onError: (e) => FailPopup.fire({text: text.registrationFail}),
  });
  useEffect(() => {
    if (orgsQuery.isSuccess && linkOrg) {
      const inviteLinkOrg = orgsQuery.data?.find?.(org=>org.id === linkOrg)
      if (inviteLinkOrg == null) return
      setSelectedOrg(inviteLinkOrg)
      setStep(STEP.TWO)
      setProgress(progress + 1)
    }
  },[linkOrg, orgsQuery.data])


  function handleUserInfo(info) {
    const {confirmPassword, ...filtered} = info
    setValues(filtered)
    setStep(STEP.THREE)
    setProgress(progress + 1)
  }

  const handleSignUp = (contactInfo) => {
    const params = {
      orgid: selectedOrg?.id,
      invitecode: code || '',
      ...contactInfo,
      ...values
    }; 
    if (!code) 
      params.orgid = selectedOrg?.id
    signupMutation.mutate(params);
  };
  return (
    <RegisterContainer>
        {step === STEP.ONE && (
            <JoinOrgPicker 
              initialOrgID={linkOrg}
              onChange={setSelectedOrg}
              onSubmit={() => {
                setStep(STEP.TWO) 
                setProgress(progress + 1)}}
              orgs={orgsQuery.data} 
                value={selectedOrg}
                text={text}
            />
        )}
          {/* <>
          </> */}
        {step === STEP.TWO && (
          <UserRegister 
            {...{
              onSubmit: handleUserInfo,
              code,
              history,
              linkOrg,
              suffix : selectedOrg?.email_suffix,
              text}}
          />
        )}
        {step === STEP.THREE && (
          <ContactRegister
              suffix={selectedOrg?.email_suffix}
              text={text}
              onSubmit={handleSignUp}
          />
        )}
        {step === STEP.FOUR && (
          <div className={'flex flex-col h-full mt-10'}>
            <div className="w-full">
              <p className="text-3xl font-semibold">{text.thankyou}</p>
            </div>
            <div className="mt-4 w-full">
              <p className="text-gray-400">{text.linkSent}</p>
            </div>
            <div className="mt-4 w-full h-full">
              <p className="text-gray-400">{text.checkEmail}</p>
            </div>
            {/* <div className="mt-14 w-full">
              <GrayButton variant="contained" size="large" className="w-full" onClick={() => handleContinue()}>{text.resendLink}</GrayButton>
            </div> */}
          </div>
        )}
        <div className='mt-auto'>
          <div className="mt-8 w-full flex justify-center">
            {!linkOrg && <Stepper numSteps={4} activeStep={step} onStepClicked={setStep} />}
          </div>
          <PoweredBy />
        </div>
    </RegisterContainer>

  );
}

export default StudentRegister;
