

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getAllOrgsRoleData } from '@/apis/base/applications/getAllOrgsRoleData';
import { requestStatusToLabel } from '@/utils';
import useAppStatusEnum from '../enum/useAppStatusEnum';
import useRoleEnum from '../enum/useRoleEnum';

export default function useAllOrgsRoleData() {
    const roleEnumQuery = useRoleEnum()
    const statusEnumQuery = useAppStatusEnum()

    const query = useQuery({
        queryKey: ['role','admin', ROUTE_IDS.GET_ALL_ORGS_ROLE_DATA],
        queryFn: (params) => getAllOrgsRoleData(params),
        staleTime: MILLISEC.MIN_15,
        enabled: !!roleEnumQuery.data && !!statusEnumQuery.data,
        select: (data) => {
            const dataClone = structuredClone(data)
            recursiveRowDataPopulator(dataClone, roleEnumQuery.data, statusEnumQuery.data)
            console.log('dataafter', dataClone);
            return dataClone.filter(d => d.requests?.length > 0)
        },
    });
    return query;
}

function recursiveRowDataPopulator(data, roleEnum, statusEnum) {
    if (!data?.length) return
    data.forEach((d, i) => {
        // console.log('d',d);
        if (d.subnodes) recursiveRowDataPopulator(d.subnodes, roleEnum, statusEnum)
        if (!d.subnodes) d.subnodes = []
        d.subnodes = d.subnodes.filter(s => s.requests?.length > 0)
        if (!d.roledata) d.roledata = {}
        const flatRoles = []
        if (d.roledata?.FIN?.length > 0) {
            d.roledata.FIN?.forEach((item) => {
                item.roleid = roleEnum.fin
                item.roleLabel = 'Family In Need'
                item.statusLabel = requestStatusToLabel(item.status, statusEnum)
                flatRoles.push(item)
            });
        }
        if (d.roledata?.Ambassador?.length > 0) {
            d.roledata.Ambassador?.forEach((item) => {
                item.roleid = roleEnum.ambassador
                item.roleLabel = 'Ambassador'
                flatRoles.push(item)
            });
        }
        if (d.roledata?.['Social Media Influencer']?.length > 0) {
            d.roledata['Social Media Influencer']?.forEach((item) => {
                item.roleid = roleEnum.smi
                item.roleLabel = 'Social Media Influencer'
                flatRoles.push(item)
            });
        }
        d.requests = flatRoles
    })
}