import { DashboardCard } from '@/components/Cards/DashboardCard';
import DropdownTwo from '@/components/DropDownTwo';
import { BasicBoxTabs } from '@/components/Input/BasicBoxTabs';
import MultiSelectDropdown from '@/components/Input/MultiSelectDropdown';
import { AllOrgsWishlistTable } from '@/components/Tables/AllOrgsWishlistTable/AllOrgsWishlistTable';
import useGeoData from '@/hooks/state/misc/useGeoData';
import useAllOrgs from '@/hooks/state/organization/useAllOrgs';
import { useWishlistFilters } from '@/hooks/state/wishlist/helpers/useWishlistFilters';
import { orgWishlistReportConfig } from '@/hooks/state/wishlist/useOrgWishlistReport';
import useWishProductCategories from '@/hooks/state/wishlist/useWishProductCats';
import useWishTargetCust from '@/hooks/state/wishlist/useWishTargetCust';
import { useQueries } from '@tanstack/react-query';
import React, { useMemo } from 'react';

export const WishlistCommunityEngagement = ({orgsQuery, ...props}) => {
    const categoryQuery = useWishProductCategories({})
    const demographicsQuery = useWishTargetCust({})
    const geoQuery = useGeoData({})

    const [selectedGeo, setSelectedGeo] = React.useState(null)

    const {
        demoFilter, 
        catFilter,
        handleCatFilterChange,
        handleDemoFilterChange
    } = useWishlistFilters(demographicsQuery.data, categoryQuery.data?.flat)


    return (
        <div>
            <DashboardCard className={'place-self-start max-w-[20rem]'}>
                <DropdownTwo 
                    showClearButton
                    hasSubOptions
                    allowSuperOptionSelect
                    options={geoQuery.data?.[0]?.subnodes}
                    value={selectedGeo}
                    onChange={setSelectedGeo}
                    placeHolder='No geographic filter set'
                    valueFn={(val) => val}
                    renderFn={(val) => val?.name}
                    subOptFn={(val) => val?.subnodes}
                    displayFn={(val) => <p> Showing results in <strong>{val?.name}</strong></p>}
                />
                <div className="flex flex-col md:flex-row w-fit gap-5">
                    <MultiSelectDropdown 
                        placeHolder='Filter categories'
                        options={categoryQuery.data?.tree}
                        renderFn={(val) => val?.name}
                        onToggle={handleCatFilterChange} 
                        selectedIDs={catFilter}
                        className='w-[50rem]'
                    />
                    <MultiSelectDropdown
                        placeHolder='Filter demographics'
                        options={demographicsQuery.data}
                        renderFn={(val) => val?.name}
                        onToggle={handleDemoFilterChange}
                        selectedIDs={demoFilter}
                        className='w-72'
                    />
                </div>
            </DashboardCard>
            <AllOrgsWishlistTable
                orgsData={orgsQuery.data}
                geonode={selectedGeo}
            />
        </div>
    );
};
