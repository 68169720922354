import React from 'react';


export const WhiteCard = ({className, ...props}) => {
    return (
        <div className='bg-white rounded-lg shadow-md form-control p-10'>
            {props.children}
        </div>
    );
};

