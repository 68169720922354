import { getEventInventoryUrl } from "@/utils"
import { TrackedImage } from "../Image/TrackedImage"
import { ProductCarousel } from "../Carousel/ProductCarousel"

export const BasicPickupCard = ({eventid, item, showRemaining=false })  => {
    const { productname, productsize, description, inventoryid, confirmed, remaining, qtyconfirmed} =  item || {}
    return (
    <div className="rounded-none card card-compact w-full" >
        <ProductCarousel 
          eventid={eventid}
          inventoryid={inventoryid}
          numImages={4}
        />
      <div className="card-body p-0 pt-2" >
        <h2 className="card-title font-semibold text-xl" >
          {productname}
        </h2>
        <p className="-mb-2 font-[0.9rem] text-base">Sizes: {productsize}</p>
      <div className="divider  m-0 w-full" ></div>
        <div className="card-actions justify-between item
        s-center" >
          <div className="form-control gap-4">
            <div className="flex flex-row gap-2 w-full justify-end items-center h-5" > 
                <span>{remaining}</span> / <span>{qtyconfirmed}</span> Remaining
          </div>
  
        </div>
      </div>
      </div>
  </div>
    )
  }