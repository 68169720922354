import React from 'react';
export const ErrorLabel = ({className, ...props}) => {
    return (
        <span 
            className={`text-sm  text-error-content ${className}`} 
                {...props}>{props.children}
            </span>
    );
};

