import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { Redirect, Route, Switch } from 'react-router-dom';

import Footer from '../../../components/Footer';

import { useDonationContext } from '@/context/DonationContext';
import { ChevronRight } from '@material-ui/icons';
import { useUserContext } from '../../../context/UserContext';
import routes from '../../../routes';
import ProcessorSideBar from './components/ProcessorSideBar';

function Processor(props) {
    const { cookies, history } = props;
    const storedUserInfo = cookies.get('userInfo');
    const { setUserInfo } = useUserContext();
    const { setBrandDonationSearchKey } = useDonationContext();

    useEffect(() => {
        if (cookies.get('userInfo')) {
            setUserInfo(cookies.get('userInfo'));
        } else {
            setUserInfo(false);
        }
    }, [cookies, setUserInfo]);

    useEffect(() => {
        setBrandDonationSearchKey('');
    }, [history.location.pathname])

    const getRoutes = () => {
        return routes.map((prop, key) => {
        if (prop.layout === '/processor') {
            if (prop.collapse) {
            return prop.children.map((child, key) => (
                <Route
                    path={prop.layout + prop.path + child.path}
                    component={child.component}
                    key={key}
                />
            ))
            } else {
            return (
                <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
                />
            );
            }
        } else {
            return null;
        }
        });
    };

    return (
        <div className="min-h-screen overscroll-none bg-base-200">
        <label htmlFor='my-drawer' className="btn hover:cursor-pointer btn-neutral  drawer-button absolute top-5 -left-[1px] rounded-l-none rounded-r-9">
            <ChevronRight/>
        </label>
        <div className="drawer xl:drawer-open">
          <input id="my-drawer" type="checkbox" className="drawer-toggle" />
          <ProcessorSideBar history={history} />
          <main className="drawer-content overscroll-none min-h-screen">
            <div className='px-9 pb-9'>
              <Switch>
                {getRoutes()}
                <Redirect from="/processor" to="/processor/dashboard" />
              </Switch>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    );
}

export default withCookies(Processor);
