import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getAllOrgs } from '@/apis/base/organization';
import { getProductCategories } from '@/apis/base/wishlist/getProductCategories';
import { flattenSubnodes, recursiveFlattenArraySubnodes, recursiveFlattenSubnodes } from '@/utils';

export default function useWishProductCategories(props) {
  const catQuery = useQuery({
    queryKey: [ROUTE_IDS.GET_PRODUCT_CATEGORIES],
    queryFn: getProductCategories,
    retry: 2,
    // notifyOnChangeProps: 'data',
    staleTime: MILLISEC.MIN_15,
    select: (data) => {
      const flatCats = recursiveFlattenArraySubnodes({topArr: data})
      return {
        flat: flatCats,
        tree: data,
      }
    },
    ...props
  });
  return catQuery;
}
