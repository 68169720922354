import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getOrgsByRoles } from '@/apis/base/organizations/getOrgsByRoles';
import useOrgWithId from './useOrg';


export default function useOrgsByRole({role}) {

    const query = useQuery({
        queryKey: ['org', 'role', role],
        queryFn: () => getOrgsByRoles({role}),
        staleTime: MILLISEC.MIN_15,
    });
    const orgQuery = useOrgWithId({org_id: query.data })
    return orgQuery;
}