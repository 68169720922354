    import { GEO_NODE_TYPE, WISHLIST_VIEW_TYPE } from "@/constants";
import { arrayToObject, prettyPrint } from "@/utils";
import { useEffect, useMemo, useState } from "react";


export function useWishlistObject({
    view=null,
    memberWishlistObj=null,
    communityWishlistObj=null,
    orgWishlistReportObj=null,
    orgWishlistReportAndDemandObj=null,
    sitewideWishlists=null,
    wishProductsData=null,
}) {
    if (view == null) throw new Error('wishlist hook view type is null')
    const [wishlistObj, setWishlistObj] = useState(null)

    // useEffect(() => updateObj(true),[])

    const sitewideWishlistObj = useMemo(() => 
        calculateSitewideDemand(sitewideWishlists),
        [sitewideWishlists])

    useEffect(updateObj,[
        view,
        memberWishlistObj,
        communityWishlistObj,
        orgWishlistReportObj,
        sitewideWishlists,
        wishProductsData,
        orgWishlistReportAndDemandObj,
    ])
    function updateObj() {
        if (!wishProductsData) return
        const productsWithQuantities = wishProductsData?.map?.((product) => {
            const id = product.id
            let newData = {}
            switch (view) {
                case WISHLIST_VIEW_TYPE.MEMBER:
                    newData = {myQty : memberWishlistObj?.[id]?.quantity || 0}
                    break;
                case WISHLIST_VIEW_TYPE.ORG_ADMIN:
                    newData = {
                        communityQty : communityWishlistObj?.[id] || 0,
                        reportQty : orgWishlistReportObj?.wishlist?.[id]?.quantity || 0
                    }
                    console.log('reportQty', orgWishlistReportObj?.wishlist?.[id], id);
                    break;
                case WISHLIST_VIEW_TYPE.UIC:
                    // product.totalQuantity = memberWishlistObj?.[id]?.quantity || 0
                    break;
                case WISHLIST_VIEW_TYPE.REPORT:
                    newData = sitewideWishlistObj?.[id] || {}
                    // if (product.totalQty) console.log('new product', product);
                    break;
                    default:
                        console.log('default');
                    break;
            }
            return {...newData, ...product}
        })
        setWishlistObj(arrayToObject(productsWithQuantities, 'id'))
    }
    return wishlistObj
}

function calculateOrgsReportAndDemand(orgsWishlistReportAndDemandObj) {
    
}

function calculateSitewideDemand(sitewideWishlists) { 
    if (!sitewideWishlists) return {}
        const object = {}
        for (const [name,user] of Object.entries(structuredClone(sitewideWishlists))) {
            const regionid = user['region id']
            const cityid = user['city id']
            for (const product of user.wishlist) {
                const pid = product.productid
                const quantity = Number(product.quantity)
                if (object[pid] == null)    {
                    object[pid] = {
                        ...product, 
                        totalQty: 0,
                        regions: {},
                        cities: {}
                        }}
                object[pid].totalQty += quantity
                if (regionid) { 
                    if (object[pid].regions?.[regionid]?.totalQty == null) 
                        object[pid].regions[regionid] = {totalQty: 0}
                    object[pid].regions[regionid].totalQty += quantity
                }
                if (cityid) {
                    if (object[pid].cities?.[cityid]?.totalQty == null) 
                        object[pid].cities[cityid] = {totalQty: 0}
                    object[pid].cities[cityid].totalQty += quantity
                }
            }
        }
        return object
    }



const defaultProduct = {
    totalQty: 0,
    regions: {},
    cities: {}
}