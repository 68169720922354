import { ConfirmPopupPickupDialog } from '@/components/Dialog/ConfirmPopupPickupDialog';
import UserProfile from '@/components/User/UserProfile';
import { userRoleRequestQueryConfig } from '@/hooks/state/admin/useUserRoleRequests';
import useRoleEnum from '@/hooks/state/enum/useRoleEnum';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useUserData, { userDataQueryConfig } from '@/hooks/state/user/useUserData';
import { openDialogWithRef, rangeArray } from '@/utils';
import { prefetchQueries } from '@/utils/queryUtils';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { BasicTable } from '../BasicTable';
import { PopupPickupOrdersCols } from './PopupPickupOrdersCols';
import { BasicRefreshButton } from '@/components/Buttons/BasicRefreshButton';
import useUsersDependents from '@/hooks/state/admin/useUsersDependents';
import { downloadExcel } from '@/utils/fileUtils/downloadExcel';
import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { ExcelIcon } from '@/components/Icons';

const VIEW_MODE = {
    TABLE: 0,
    USER: 1
}
export const PopupPickupOrdersTable = ({requests, popupName, popupActive, pickupState, popupid, eventid, reloadDataFn, loading, ...props}) => {
    const dialogRef = React.useRef()
    const client  = useQueryClient()
    const {data: ctx} = useCurrentOrgContext()
    const {data: roleEnum} = useRoleEnum()
    const [viewMode, setViewMode] = React.useState(VIEW_MODE.TABLE)
    const [selectedUserID, setSelectedUserID] = React.useState(null)
    const [selectedRequest, setSelectedRequest] = React.useState(null)
    const userDataQuery = useUserData({userids: [selectedUserID]})
    const dependentsQuery = useUsersDependents({userids: Object.keys(requests || {})})
    const dependentsObject = useMemo(() => {
        if (!dependentsQuery.data) return {}
        return Object.fromEntries(dependentsQuery.data.map(d => [d.id, d]))
    },[dependentsQuery.data])

    const safeRequests = useMemo(() => Object.values(requests || {}) || [],[requests])

    function handleUserClick(user) {
        setSelectedUserID(user.userid)
        prefetchQueries(client, [
            userDataQueryConfig({userids: [user.userid]}),
            userRoleRequestQueryConfig({userid: user.userid, orgid:ctx?.orgid, roleEnum: roleEnum})
        ])
        setViewMode(VIEW_MODE.USER)
    }

    function pickupConfirmedClicked(row) {
        setSelectedRequest(row)
        openDialogWithRef(dialogRef)
    }

    function exitUserView() {
        setViewMode(VIEW_MODE.TABLE)
    }
    function exportPickups() {
        const filename = `Pickup Orders - ${popupName}}`
        const uniqueItemNames = new Map()
        for (const request of safeRequests) {
            request.items.forEach((item) => {
                uniqueItemNames.set(item.inventoryid, item.productname)
            })
        }
        const rows = safeRequests.map((request) => {
            const row =  {
                "Name": request.username,
                "Email": request.email, 
            }
            for (const [id, name] of uniqueItemNames) {
                const requestItem = Object.values(request.items)?.find(item => item.inventoryid === id)
                row[`${name} Requested`] = requestItem?.quantity || "0"
                row[`${name} Picked up`] = requestItem?.confirmedqty || "0"
            }
            return row
        })
        const sheet = {
            name: 'Pickup Orders',
            rows
        }
        const columnWidths = rangeArray(1,2 + uniqueItemNames.size * 2).map(() => 20) // +2 for name and email
        columnWidths[1] = 35 // emails are long
        downloadExcel([sheet], filename, columnWidths)
    }
    return (
        <>
            { viewMode === VIEW_MODE.TABLE ?  (
                <BasicTable 
                    horizontalScrolling={false}
                    data={safeRequests}  
                    columnHelper={PopupPickupOrdersCols}
                    meta={{
                        handleUserClick,
                        pickupConfirmedClicked,
                        popupActive,
                        dependentsObject
                    }} {...props} >
                    <BasicRectButton customSize className={'btn-neutral !h-table-element  btn-little '} onClick={exportPickups}>
                        Export to excel
                        <ExcelIcon className='fill-green-600 w-6 h-6'/>
                    </BasicRectButton>
                    <BasicRefreshButton loading={loading} onClick={reloadDataFn} />
                </BasicTable>
                
            ) : userDataQuery.data && ( 
                <UserProfile backLabel={'Back to Popup Store'} showRoles={false} user={userDataQuery.data?.[0]} exitDetailView={exitUserView}/>
            )}
            <ConfirmPopupPickupDialog 
                dialogRef={dialogRef}
                popupState={pickupState}
                popupid={popupid}
                request={selectedRequest}
                eventid={eventid}/>
        </>
    )
};

