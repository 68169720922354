const config = {
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  SCP_API_BASE_URL: import.meta.env.VITE_SCP_API_BASE_URL,
  APP_URL: import.meta.env.VITE_APP_URL,
  IMAGES_URL: import.meta.env.VITE_APP_IMAGES_URL,
  WISHLIST_URL: import.meta.env.VITE_WISHLIST_URL,
  API_GATEWAY_URL: import.meta.env.VITE_API_GATEWAY_URL,
  DEFAULT_CURATOR: import.meta.env.VITE_DEFAULT_CURATOR,
  SITE_URL: import.meta.env.VITE_SITE_URL,
};
export default config;