
import { login } from '@/apis/base/auth/login';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useLogin({ onSuccess, ...props }) {
  const client = useQueryClient();
  const loginData = useMutation({
    mutationKey: ['user', ROUTE_IDS.LOGIN],
    mutationFn: login,
    retry: false,
    staleTime: MILLISEC.MIN_15,
    gcTime:0,
    onSuccess: (data, req) => {
      client.invalidateQueries( { queryKey: ['org']});
      client.invalidateQueries( { queryKey: ['context']});
      client.invalidateQueries( { queryKey: ['user']});
      onSuccess(data, req); 
    },
    ...props,
  });
  return loginData;
}
