export const BasicPageCard = ({ title, children, titleRowComponents }) => (
    <div className="w-full min-h-content flex justify-center ">
        <div className="w-full h-full">
            <div className="flex md:flex-row xs:flex-col items-center justify-between mb-5">
                <span className="text-4xl  font-semibold text-black mr-3">
                    {title}
                </span>
                {titleRowComponents}
            </div>
            <div className=" w-full bg-base-100 mt-10 rounded-2xl shadow-md py-5">
                {children}
            </div>
        </div>
    </div>
)