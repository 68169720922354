import React, { useEffect, useMemo } from 'react';
import { BasicDialog } from './BasicDialog';
import GalleryOverlay from '../GalleryOverlay';
import { arrayToObject, closeDialogWithRef, collapseObjIntoArray, getDashedDateString, pick, subObject } from '@/utils';
import { OrgLogo } from '../Image/OrgLogo';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import { TableSkeleton } from '../Skeletons/TableSkeleton';
import { SendIcon } from '../Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons';
import { ConfirmDeliveryIcon, RequestIcon } from '../Icons';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useModBDRequest from '@/hooks/state/donation/request/useModBDRequest';
import useModBDShipment from '@/hooks/state/donation/shipment/useModBDShipment';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { func } from 'prop-types';
import { ConfirmShipmentTable } from '../Tables/SimpleTable/ConfirmShipmentTable';
import { BasicInput } from '../Input/BasicInput';
import { BasicDateInput } from '../Input/BasicDateInput';
import { BasicTextArea } from '../Input/BasicTextArea';
import useCreatePopup from '@/hooks/state/donation/popupStore/useCreatePopup';
import { Popup } from '@/wrappers/swal/Popup';
import useModPopup from '@/hooks/state/donation/popupStore/useModPopup';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';


export const ManagePopupDialog = ({dialogRef, eventid, orgid, initialValues, ...props}) => {
    const client = useQueryClient()
    const {data: ctx} = useCurrentOrgContext() || {}
    const history = useHistory()
    
    const [values, setValues] = React.useState({
        name: '',
        location: '',
        starttime: Date.now() / 1000,
        endtime: Date.now() / 1000,
        description: '',
    })
    const valuesArray = useMemo(() => collapseObjIntoArray(values), [values])
    const modPopupMutation = useModPopup({
		onSuccess: (data, req) => {
            closeDialogWithRef(dialogRef)
            client.invalidateQueries({queryKey: ['popup', req.popupid]})
            SuccessToast.fire({text: 'Popup store modified'})
        },
		onError: (error) => {FailPopup.fire({text: 'Failt to modify popoup store'})}
	})
    const popupMutation = useCreatePopup({
        onSuccess: (data) => {
            closeDialogWithRef(dialogRef)
            if (initialValues) SuccessToast.fire({text: 'Popup Store Updated!'})
            else Popup.fire({
                    title: 'Success', 
                    text: 'Popup Store Created! Would you like to go to your popup stores?',
                    icon: 'success',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Yes, go to Popup Stores',
                }).then((result) => {
                    if (result.isConfirmed) history.push(`/donation?tab=pickup`)
                })
        },
        onError: (error) => {FailPopup.fire({text: 'Failed to Create Popup Store'})}
    })
    
    useEffect(() => {
        if (!initialValues) return
        setValues({
            ...values, 
            ...(initialValues && subObject(initialValues, ['name','location','starttime','endtime','description', 'popupid']))})
    },[initialValues])
        
    function onChange(field, value) {
        setValues({
            ...values,
            [field]: value
        })
    }

    function onSubmit() {
        const params = {...{eventid, orgid, ...values}}
        if (initialValues) modPopupMutation.mutate({...params, popupid: initialValues?.id})
        else popupMutation.mutate(params)
    }

    return  (
        <BasicDialog 
            ref={dialogRef}
            id='shipmentForm'
            title={initialValues ? 'Modify Popup Store' : 'Create Popup Store'}
            className='w-[45rem] min-h-fit overflow-hidden pb-20'
            handleSubmit={onSubmit}
            hideActions
            {...props}
        >   
        {values ? (
            <>
                <BasicInput
                    required 
                    label={'Popup Store Name'} 
                    value={values.name} 
                    onChange={(e) => onChange('name', e.target.value)} />
                <BasicTextArea
                    required 
                    className='min-h-24'
                    label={'Description'} 
                    value={values.description} 
                    onChange={(e) => onChange('description', e.target.value)} />
                <BasicInput
                    required 
                    label={'Location'} 
                    value={values.location} 
                    onChange={(e) => onChange('location', e.target.value)} />
                <div className="w-full flex gap-5">
                    <BasicDateInput
                        required
                        label={'Start Time'}
                        value={getDashedDateString(values.starttime)}
                        onChange={(e) => {
                            onChange('starttime', e.target.valueAsNumber / 1000 )}
                        } 
                    />
                    <BasicDateInput
                        required
                        label={'End Time'}
                        value={getDashedDateString(values.endtime)}
                        onChange={(e) => onChange('endtime', e.target.valueAsNumber / 1000)}
                    />
                </div>
                <div className="my-4 flex w-full justify-end">        
                    <button className="btn btn-secondary " type='submit' formMethod='dialog' >
                        {initialValues ? 'Modify Popup Store' : 'Create Popup Store'}
                        <ConfirmDeliveryIcon className='w-5 h-5'/>
                    </button>
                
                    <button 
                        className="btn btn-neutral ml-2" type='button' 
                        onClick={() => closeDialogWithRef(dialogRef)}
                    >
                        Cancel
                    </button>
                </div>
            </>
        ) : (
            <div className='form-control items-center w-full gap-14'>
                <div className="skeleton rounded-xl w-1/3 aspect-video"/>
                <TableSkeleton numRows={3} rowHeightClass='h-18'/>
            </div>
        )}
        </BasicDialog>
    ) 
};



