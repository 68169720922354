import { forwardRef } from "react";
import PropTypes from 'prop-types';
import { fileToDataUri } from "@/utils";
import { InputContainer } from "./InputContainer";
import { InputLabel } from "../Label/InputLabel";

export const BasicFileInput = forwardRef(function({
    label=null,
    name='file',
    className, 
    isError=false,
    errorText,
    required=false,
    onChange=()=>{},
    containerClassName, 
    disabled=false,
    ...props},ref) { 
    
    return (
        <div className={`input-base ${label? 'form-control' : ''} ${containerClassName}`}>
            <InputLabel {...{label, required}}/>
            <InputContainer {...{ isError, errorText}}>
                <input 
                    tabIndex={0}
                    required={required} 
                    name={name}
                    type="file" 
                    ref={ref}
                    multiple
                    disabled={disabled}
                    onChange={onChange}
                    className={`file-input file-input-bordered focus:outline-none focus:border-primary  ${disabled ? 'input-disabled' : ''} ${className} `} 
                    {...props}/>
            </InputContainer>
        </div>            
    )
})

BasicFileInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.bool
}
