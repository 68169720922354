import useRefreshToken from '@/hooks/state/auth/useRefreshToken';
import { deleteAllCookies, getCookie, setCookie, setRedirectPathCookie } from '@/utils';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route } from 'react-router-dom';
import AuthenticatedHeader from '../Headers/AuthenticatedHeader/AuthenticatedHeader';


export const ProtectedRoute = ({children,...props}) => {
    const [cookies, _, removeCookie] = useCookies(["token","refreshToken"]);
    const [authenticated,setAuthenticated] = useState(true);
    const refreshMutation = useRefreshToken(queryResultFns(
        'Successfully refreshed token',
        'Failed to refresh token'
    ));
    useEffect(() => {
        if (cookies.token == null)
            if (cookies.refreshToken == null)  {
                setAuthenticated(false)
                const redirectPath = getCookie('redirect')
                deleteAllCookies()
                setCookie('redirect', redirectPath)
            }
            else 
                refreshMutation.mutate()
    },[cookies.token, cookies.refreshToken])

    return authenticated ? (
        <div className='scroll-auto'>
            <AuthenticatedHeader />
            <div className='grid'>
                <div 
                    className='sticky flex col-span-1 row-span-1 right-0 top-0 h-0  z-[10000]' 
                    id={'popuptarget'}
                />

                <div className='col-1 row-span-1'>
                    <Route  {...props}/>
                </div>
            </div>

        </div>
            
    ) : (
        <>
            <Redirect to={'/welcome'} exact />
        </>
    )
};

