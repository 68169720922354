import { BasicCheckbox } from "@/components/Input/BasicCheckbox";
import { getDateString } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { ApproveDeclineCell } from "../Cells/ApproveDeclineCell";
import { RequestStatusCell } from "../Cells/RequestStatusCell";
import { UserCell } from "../Cells/UserCell";
import { EyeIcon } from "@/components/Icons";
import { BasicGhostButton } from "@/components/Buttons/BasicGhostButton";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove

export const UICRequestManagementCols = [
columnHelper.display( {
    id: "Selected",
    size: 10,
    cell: ({row}) => 
        <BasicCheckbox 
            containerProps={{style:{paddingLeft: `${row.depth * 3}rem`}}}
            className={'w-6'}
            {...{checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler()}}
        />,
    header: ({table}) => 
        <BasicCheckbox 
            className={'w-6 items-bottom'}
            indeterminate={table.getIsSomeRowsSelected()}
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />,
  }),
columnHelper.accessor((row) => row.orgname || row.name, {
    id: "Organization",
    size: 300,
    filterFn: stringFilter,
    cell: ({row, getValue}) => 
        (   row.original.roledata &&
            <span style={{paddingLeft: `${row.depth * 2}rem`}}>
                {getValue()}
            </span> 
        ),
    header: (info) => <span>Organization</span>,
}),
columnHelper.accessor((row) => row.roledata ? '' : row.name, {
    id: "Member",
    size: 100,
    filterFn: stringFilter,
    cell: ({row, table}) => ( !row.original.roledata &&  (
        <UserCell onClick={() => table.options.meta.viewUserDetails(row.original)} row={row.original}/> )
    ),
    header: (info) => <span>Member</span>,
  }),
columnHelper.accessor((row) => row.roleLabel, {
    id: "Type",
    size: 300,
    filterFn: stringFilter,
    cell: ({row, getValue}) =>  (!row.original.roledata && <span className="text-sm">Role: {row.original.roledata} {getValue()}</span>),
    header: (info) => <span>Type</span>,
  }),
columnHelper.accessor((row) => row.statusLabel, {
    id: "Status",
    size: 50,
    filterFn: stringFilter,
    cell: ({row}) =>  (!row.original.roledata && <RequestStatusCell status={row.original.status}/>),
    header: (info) => <span>Status</span>,
  }),
columnHelper.accessor((row) => row.occupation, {
    id: "Occupation",
    size: 50,
    filterFn: stringFilter,
    enableColumnFilter: false,
    cell: (info) => <span className="text-sm">{info.getValue()}</span>,
    header: (info) => <span>Occupation</span>,
}),
columnHelper.accessor((row) => row.timestamp, {
    id: "Member Since",
    size: 150,
    filterFn: stringFilter,
    enableColumnFilter: false,
    cell: ({row, getValue}) => ( !row.original.roledata && <span className="whitespace-nowrap text-sm">{getDateString(getValue())}</span>),
    header: (info) => <span>Member Since</span>,
  }),
columnHelper.display({
    id: "Actions",
    size: 30,
    filterFn: stringFilter,
    cell: ({row,table}) =>  (!row.original.roledata && 
        <div className="flex">
        <BasicGhostButton little circle onClick={() => table.options.meta.viewRequestDetails(row.original)}>
          <EyeIcon className={'w-7 h-7 text-gray-unselected'}/>
        </BasicGhostButton>
        <ApproveDeclineCell 
              onApprove={() => table.options.meta.onSingleModify(true, row.original)}
              onDecline={() => table.options.meta.onSingleModify(false, row.original)}
        />
      </div> ),
    header: (info) => <div className="text-center w-full ">Actions</div>,
  }),
]

const getRoleText = (roleEnum, type) => {
    let roleText = 'Role: '
    switch (type) {
        case roleEnum.fin:
            roleText += 'Family In Need'
            break;
        case roleEnum.ambassador:
            roleText += 'Ambassador'
            break;
        case roleEnum.smi:
            roleText += 'Social Media Influencer'
            break;
        default:
            break;
    }
    return roleText       
}

