
import { IMAGE_TYPE, MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie, getWishlistUrls } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { delImage } from '@/apis/base/image/delImage';

export default function useDeleteImage(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.DELETE_IMAGE],
        mutationFn: delImage,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            if (!req.queryKeyToInvalidate) throw new Error('Must provide image url to mutation to aid in cache invalidation')
            client.invalidateQueries({queryKey: [req.queryKeyToInvalidate]})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}