
import { getDonationStatusEnum } from '@/apis/base/enum/getDonationStatusEnum';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';


export default function useBDStatusEnum() {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_DONATION_STATUS_ENUM],
        queryFn: getDonationStatusEnum,
        staleTime: MILLISEC.HOUR,
        gcTime: MILLISEC.HOUR,
        select
    });
    return query;
}

function select(data) {
    data.brandReview = data.find(s => s?.name === 'brand_review')?.id
    data.brandDeclined = data.find(s => s?.name === 'brand_declined')?.id
    data.unity11Review = data.find(s => s?.name === 'u11_review')?.id
    data.unity11Declined = data.find(s => s?.name === 'u11_declined')?.id
    data.processorReview = data.find(s => s?.name === 'processor_review')?.id
    data.processorDeclined = data.find(s => s?.name === 'processor_declined')?.id
    data.live = data.find(s => s?.name === 'live')?.id
    data.closed = data.find(s => s?.name === 'closed')?.id

    data.allStatuses = [data.brandReview, data.brandDeclined, data.unity11Review, data.unity11Declined, data.processorReview, data.processorDeclined, data.live, data.closed]
    
    data.brandAuditStatuses = [data.brandReview, data.brandDeclined, data.processorDeclined, data.unity11Declined]

    data.pipelineStatuses = [data.brandReview, data.brandDeclined, data.unity11Declined,  data.processorDeclined]

    data.inProcessStatuses = [data.unity11Review, data.processorReview]

    data.liveStatuses = [data.live]
    
    data.historicalStatuses = [data.closed]

    data.preLiveStatuses = [data.brandReview, data.brandDeclined, data.unity11Review, data.unity11Declined, data.processorReview, data.processorDeclined]
    return data
}