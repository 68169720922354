import { CardEventCarousel } from '@/components/Carousel/CardEventCarousel';
import { TrackedImage } from '@/components/Image/TrackedImage';
import { getDateString, getEventUrl } from '@/utils';
import React from 'react';


export const BasicBDEventCard = ({event, fake, imgSrc, ...props}) => {
    const  {name, orgid, id, starttime, endtime, description} = event || {};
    const {children, ...otherProps} = props;
    return (
        <div className={`rounded-2xl form-control pb-4 xl:pb-6 overflow-clip transition-shadow duration-500 hover:cursor-pointer ${fake ? 'shadow-lg' : 'hover:shadow-lg'}`} {...otherProps}>
            <CardEventCarousel 
                eventid={id}
                numImages={4}
                images={fake ? [imgSrc] : []}

            />
            <div className='form-control gap-2 px-3 xl:px-6 mt-5'>
                <EventTitle>{name}</EventTitle>
                <EventDate start={Number(starttime)} end={Number(endtime)}/>
                <EventDescription>{description}</EventDescription>
            </div>
        </div>
    );
};

const EventTitle = (props) => (
    <div className='text-1.5xl font-semibold leading-1.33 whitespace-nowrap overflow-ellipsis overflow-hidden'>
        {props.children}
    </div>
)


const EventDate = ({start,end}) => (
    <div className='text-sm font-light leading-1.75 whitespace-nowrap'>
        {getDateString(start)} - {getDateString(end)}
    </div>
)

const EventDescription = (props) => (
    <div className='text-sm font-light leading-1.75 overflow-ellipsis  line-clamp-3'>
        {props.children}
    </div>
)
