//./components/UserColumnDefs.tsx
import { addCommas, getDateString } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { ApproveButton, EditButton, InventoryButton, LocationButton, ShippingButton, XButton } from "../Buttons";
import { HoverHint } from "../Misc/HoverHint";
import { BadgeFromEventStatus } from "../Util/BadgeFromEventStatus";
import { SummaryIcon } from "@/components/Icons";

// createColumnHelper helps us create columns with maximum type safety.
// we assign the type person so that it knows the structure for our data
const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const rangeFilter = (row, id, value) => {
  const rowVal = row.getValue(id)
  if (!value) return true
  return rowVal >= value.min && rowVal <= value.max
}

export const donationColumnHelper = [
  columnHelper.accessor((row) => row.id, {
    id: "ID",
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: ({row}) => <span>ID</span>,
  }),
  columnHelper.accessor((row) => row?.orgname, {
    id: "Organization",
    size: 70,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>ORGANIZATION</span>,
  }),
  columnHelper.accessor((row) => row?.name, {
    id: "Event",
    size: 140,
    filterFn: stringFilter,
    cell: (info) => <span className="font-bold line-clamp-3">{info.getValue()}</span>,
    header: () => <span>EVENT</span>,
  }),
  columnHelper.accessor((row) => row?.status, {
    id: "Status",
    size: 80,
    filterFn: multiSelectFilter,
    meta: {
      hiddenFilter: true,
      multiSelect: true
    },
    cell: ({row, table, getValue}) => <BadgeFromEventStatus
      forceClosed={table.options.meta.showingHistorical}
      statusEnum={table.options.meta.eventStatuses}
      className='text-[13px]'
      status={getValue()}
    />  ,
    header: () => <span>STATUS</span>,
  }),
  columnHelper.accessor((row) => parseInt(row?.modified || 0), {
    id: "Modified",
    size: 100,
    meta: {
      hiddenFilter: true,
      hiddenCol: true
    },
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor((row) => row?.starttime, {
    id: "Start",
    size: 100,
    enableColumnFilter: false,
    filterFn: numRangeFilter,
    cell: (info) => <span className="whitespace-nowrap">{getDateString(info.getValue())}</span>,
    header: () => <span>START</span>,
  }),
  columnHelper.accessor((row) => Number(row?.endtime), {
    id: "End",
    size: 100,
    enableColumnFilter: true,
    meta: {
      hiddenFilter: true
    },
    filterFn: rangeFilter,
    cell: (info) => <span className="whitespace-nowrap">{getDateString(info.getValue())}</span>,
    header: () => <span>END</span>,
  }),
  columnHelper.accessor((row) => row?.price, {
    id: "Price",
    size: 60,
    enableColumnFilter: false,
    // filterFn: stringFilter,
    cell: ({row, getValue}) => <span className="whitespace-nowrap">${addCommas(getValue())}</span>,
    header: () => <span>$ VALUE</span>,
  }),
  columnHelper.accessor((row) => row?.creatorname, {
    id: "Owner",
    size: 130,
    filterFn: stringFilter,
    cell: (info) => <EventOwner row={info.row.original}/>,
    header: () => <span>OWNER</span>,
  }),
  columnHelper.display({
    id: "Management",
    size: 80,
    cell: ({row, table}) => <EventManagement meta={table.options.meta} row={row.original}/>,
    header: () => <span >MANAGEMENT</span>,
  }),
  columnHelper.display({
    id: "Actions",
    size: 60,    
    cell: ({row, table}) => <EventActions 
      row={row.original} table={table}/>,
    header: () => <span >ACTIONS</span>,
  }),
];

const EventOwner = ({row}) => (
  <div className="flex flex-col whitespace-nowrap">
    <span >{row?.creatorname}</span>
    <span className="text-[12px] text-gray-400">{row?.creatoremail}</span>
  </div>
)

const EventManagement = ({ row, meta }) => {
  // const {editEnabled, inventoryEnabled, eligibilityEnabled, auditEnabled} = meta || {}
  return (
    <div className="flex flex-row -mx-4">
      <HoverHint>
        <EditButton buttonProps={{onClick: () => meta.handleEdit(row), className:'focus:outline-none '}} />
      </HoverHint>
    {/* { auditEnabled &&
    } */}
        <HoverHint>
          <Link 
            to={{ 
            pathname: `donation/inventory/${row.orgid}/${row.id}`,
            state: { orgid: row?.orgid }
            }} 
            >
            <InventoryButton buttonProps={{
                onMouseEnter: () => meta.handleInventoryPrefetch(row.id, row.orgid)
                }}/>
          </Link> 
        </HoverHint>
        <HoverHint>
          <Link 
            to={{
              pathname: `donation/locations/${row.orgid}/${row.id}`,
              state: { orgid: row?.orgid, eventName: row?.name }
            }}>
            <LocationButton  buttonProps={{
                onMouseEnter: () => meta.handleLocationPrefetch(row.id, row.orgid)
                }}/>
          </Link>
        </HoverHint>
        <HoverHint>
            <Link to={{
              pathname: `donation/shipments/${row.orgid}/${row.id}`,
              state: { orgid: row?.orgid, eventName: row?.name }
              }}>
              <ShippingButton  />
            </Link>
        </HoverHint>
        <HoverHint>
            <Link to={{
              pathname: `donation/summary/${row.orgid}/${row.id}`,
              state: { orgid: row?.orgid, eventName: row?.name }
              }}>
              <button className="btn btn-ghost btn-circle btn-little">
                  <SummaryIcon />
              </button>
            </Link>
        </HoverHint>
    </div>
  )
}

const EventActions = ({row, table}) => {
  // TODO  delete event
  // TODO  approve event
  const {internalProcessing, handleDecline, handleApprove} = table?.options?.meta || {}
  
    return  row.canAudit && (
        <div className="flex w-20 justify-between group-hover:opacity-100 opacity-30 h-full align-middle -ml-3 mb-1 transition-opacity duration-100">
            <XButton   
              buttonProps={{className:'w-6 h-6', 
                onClick:() => handleDecline(row.id)
                }}/>
              <div className="w-1"></div>
            <ApproveButton 
             buttonProps={{
              className:'w-6 h-6',
              onClick:() => handleApprove(row.id)
              }}/>  
        </div>
    )
}