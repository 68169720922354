import { flattenOrgs } from "@/utils";
import { useEffect, useState } from "react";
import Dropdown from "../Dropdown";


export const OrgDropdown = ({ orgs, extraValues, initialValue, isFlat=false, onChange, className='', placeHolder='Choose Organization', value, controlled=false, ...otherProps }) => {
    const flatOrgs = isFlat ? orgs : flattenOrgs(orgs)

    const [selectedOrg, setSelectedOrg] = useState()

    function handleChange(val) {
        setSelectedOrg(val)
        onChange(val)
    }
    
    useEffect(() => {
        setSelectedOrg(initialValue)
    },[initialValue])

    const options = flatOrgs?.length > 0 ? flatOrgs : []
    
    extraValues && extraValues.concat(extraValues)

    return (
        <Dropdown
            nullChoice={placeHolder}
            className={className}
            value={controlled ? value : selectedOrg}
            isSearchable
            labelFn={(org) => org?.name}
            keyFn={(org) => org?.id}
            placeHolder={placeHolder}
            options={options}
            onChange={handleChange}
            {...otherProps}
        />
     )
}
