import React, { useEffect } from 'react';

import SubPages from './components/pages';

import { useTranslations } from '../../../../hooks/translations';
import { useUserContext } from '../../../../context/UserContext';
import { useCookies } from 'react-cookie';
import { deleteCookie, getCookie } from '@/utils';
import { PageTitle } from '@/components/Label/PageTitle';
import { MainContainer } from '@/components/Containers/MainContainer';

function Account(props) {
  const text = useTranslations('account');
  const [cookies, setCookie, removeCookie] = useCookies(['orgIdContext']);
  const { history } = props;

  return (
    <MainContainer>
      <PageTitle>{text.title}</PageTitle>
        <div className="mt-4">
          <SubPages text={text} history={history} />
      </div>
    </MainContainer>  
  );
}

export default Account;
