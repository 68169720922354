import { useCallback } from "react";

export const useYupValidationResolver = (validationSchema, context={}, config={}) => useCallback(
    async (data) => {
      console.log('data',data);
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
          context,
          ...config,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {  
        return {
          values: {},
          errors: errors?.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema, config]
  )