import React from 'react';
import { BasicTable } from '../BasicTable';
import { requestManagementColmnHelper } from './requestManagementColumns';


export const RequestManagementTable = ({data,...props}) => {
    const safeData = data || []
    return (
        <BasicTable 
            resetFiltersOnFiltersHide={false}
            tableProps={{
                filterFromLeafRows:true,
            }}
            initialExpand
            data={safeData} 
            initialState={{columnFilters: [{id: 'Status', value: 'Pending'}]}}
            subrowFn={o => [...(o?.subnodes ? o.subnodes : []), ...(o?.requests ? o.requests : [])]} 
            columnHelper={requestManagementColmnHelper} {...props}/>
    );
};

