
import { withCookies } from 'react-cookie';
// import Client from 'shopify-buy';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import OrgHome from './OrgHome';

function Home(props) {
  const ctx = useCurrentOrgContext()

  // const shopifyClient = Client.buildClient({
  //   domain: 'unitedinchange.myshopify.com',
  //   storefrontAccessToken: '5f3b1ba5a63cd8e631f4cb62bf7f0ce2',
  // });

  // const collectionId = 'gid://shopify/Collection/294886899903';

  // useEffect(() => {
  //   shopifyClient.collection
  //     .fetchWithProducts(collectionId, { productsFirst: 10 })
  //     .then((collection) => {
  //       setShopifyProducts(collection.products);
  //     })
  //     .catch(() => {
  //       setShopifyProducts([]);
  //     });
  // }, []);
  
  // return !userQuery.data ? (
  //   <Switzerland  {...props}/>
  // ) : (
  //  <OrgHome {...props}/> 
  // );
  return ctx  && (
    <OrgHome {...props}/>
  )
}

export default withCookies(Home);
