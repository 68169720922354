import React from 'react';
import { TrackedImage } from '../Image/TrackedImage';
import { getDateString } from '@/utils';
import { CardEventCarousel } from '../Carousel/CardEventCarousel';


export const EventCardMockup =  ({values, images, ...props}) => {
    const  {name, orgid, id, starttime, endtime, description} = values || {};
    const {children, ...otherProps} = props;
    return (
        <div className={`rounded-2xl form-control pb-4 xl:pb-6 overflow-clip transition-shadow duration-500 shadow-all-light`} {...otherProps}>
            <CardEventCarousel 
                images={images || ['/assets/images/placeholder.png']}
                numImages={images?.length || 1}
            />
            <div className='form-control gap-2 px-3 xl:px-6 mt-5'>
                <EventTitle>{name === '' ? 'Event Name' : name}</EventTitle>
                <EventDate start={Number(starttime)} end={Number(endtime)}/>
                <EventDescription>{
                        description === '' ? 
                            'Event description goes here' 
                            : description}</EventDescription>
            </div>
        </div>
    );
};

const EventTitle = (props) => (
    <div className='text-1.5xl font-semibold leading-1.33 whitespace-nowrap overflow-ellipsis overflow-hidden'>
        {props.children}
    </div>
)


const EventDate = ({start,end}) => (
    <div className='text-sm font-light leading-1.75 whitespace-nowrap'>
        {getDateString(start)} - {getDateString(end)}
    </div>
)

const EventDescription = (props) => (
    <div className='text-sm font-light leading-1.75 overflow-ellipsis  line-clamp-3'>
        {props.children}
    </div>
)
