import { WishlistProductCarousel } from "@/components/Carousel/WishlistProductCarousel"
import { TwoColumnGrid } from "@/components/Containers/Grid/TwoColumnGrid"
import { TwoColumnRow } from "@/components/Containers/Grid/TwoColumnRow"
import { DeleteIcon, EditIcon, MinusIcon, PlusIcon } from "@/components/Icons"
import { GEO_NODE_TYPE, WISHLIST_VIEW_TYPE } from "@/constants"
import { trimZerosAndDec } from "@/utils"
import { forwardRef, useRef } from "react"

const v = WISHLIST_VIEW_TYPE
export const WishlistProductCard = ({
    view,
    reportQty, 
    myQty, 
    totalQty=0,
    geonodeFocus,
    communityQty,
    regions, 
    cities,
    handleMod,
    handleDelete,
    product,
    maxQuantity,
    subcategory="subcategory",
    className='w-full', ...props}) => {
    const ref = useRef(null)
    const { id, name, description } = product || {}
    let inputValue = -1
    if (view === v.ORG_ADMIN || view === v.REPORT_AND_DEMAND) inputValue = Number(reportQty)
    else if (view === v.REPORT) inputValue = totalQty
    else if (view === v.MEMBER) inputValue = myQty

    let inputLabel = 'Quantity Request'
    if (view === v.ORG_ADMIN || view === v.REPORT_AND_DEMAND) inputLabel = 'Final Request'
    else if (view === v.REPORT) inputLabel = 'Total Demand'
    else if (view === v.MEMBER) inputLabel = 'Quantity Request'
    
    let geoAmount = null
    if (geonodeFocus != null)  {
      geoAmount = geonodeFocus?.geotype === GEO_NODE_TYPE.REGION 
        ? regions?.[geonodeFocus.id]?.totalQty ?? 0
        : cities?.[geonodeFocus.id]?.totalQty ?? 0
    }
    if (Number(reportQty) > 0) console.log('reportqty', reportQty);
  return (
  <div className={`@container rounded-none ${className}`} >
    <figure className="w-full relative group aspect-product" 
    >
      {/* <div className="carousel group w-full aspect-product object-cover border border-gray-border" >
        <div className="carousel-item w-full">
          <img className="w-full aspect-product object-cover border border-gray-border"
            src={getProductUrl(product?.id,0)}
            alt="uic"
            onError={(e) => {
              e.target.onerror = (e) => {
                e.target.onerror = null
                e.target.src = "/assets/images/placeholder.png"
              };
                e.target.src = `${config.WISHLIST_URL}/${product?.images?.[0]?.name}`
            }}
          />
        </div>
      </div> */}
      <WishlistProductCarousel 
        key={product?.modified}
        modifiedTimestamp={product?.modified}
        images={product?.images}
        loadingType='lazy'
        firstLoadingType='eager'
      />
      { view === v.UIC && (
        <div className="invisible group-hover:visible">
          <div className="inline-flex absolute btn btn-secondary bottom-5 right-5" onClick={() => handleMod(product)}>
            <EditIcon />
          </div>
          <div className="inline-flex absolute btn btn-error bottom-5 left-5" onClick={() => handleDelete(product)}>
            <DeleteIcon />
          </div>
        </div>
      )}
    </figure> 
    <div className="form-control p-2 pt-2 " >
      <p className="text-[1.0625rem] font-semibold">
        {name}
      </p>
      <p className="-mb-2 font-[0.9rem] text-[0.9rem] lg:text-[1rem] ">{description || 'Plain'}</p>
      <div className="divider  m-0 w-full" ></div>
      <TwoColumnGrid className="items-center gap-y-2 gap-x-4">
        { view !== v.UIC && (
        <TwoColumnRow>
            <p className={'text-sm md:text-base leading-tight 2md:leading-normal whitespace-pre-line md:whitespace-nowrap'}>
              {inputLabel}
            </p>
            { [v.ORG_ADMIN, v.MEMBER].includes(view) ? (
            <RequestAmountControls 
                ref={ref}
                {...{initialValue: inputValue, maxQuantity, communityQty, productID: product.id}}
            />
            ) : (
              <p className="text-blue-cornflower text-lg  ">
                {inputValue}
              </p>
            )}
        </TwoColumnRow>
        )}
        { view === v.REPORT && geonodeFocus != null && (
          <TwoColumnRow>
              <span className={'text-sm md:text-base leading-tight 2md:leading-normal whitespace-pre-line md:whitespace-nowrap'}>
                <strong>{geonodeFocus.name}</strong> Demand
              </span>
              <p className="text-blue-cornflower text-lg">
                {geoAmount}  
              </p>
              
          </TwoColumnRow>
        )}
        { view === v.ORG_ADMIN || view === v.REPORT_AND_DEMAND && (
          <TwoColumnRow>
              <p className={'text-sm md:text-base leading-tight 2md:leading-normal whitespace-pre-line md:whitespace-nowrap'}>
                {'Community \n Request'}
              </p>
              <p className="text-blue-cornflower text-sm 2md:text-base h-5 flex-1 text-center col-span-1 place-self-center">
                {Number(communityQty)}  
              </p>

          </TwoColumnRow>
        )}
      </TwoColumnGrid>
    </div>
</div>
  )
}




function RequestAmountControlsFn({
    initialValue, maxQuantity, communityQty, productID
  }, ref)  { 
    function setToCommunityRequest() {ref.current.value = communityQty}
    function stepUp() {ref.current.stepUp()}
    function stepDown() {ref.current.stepDown()}
    function handleBlur(e) {
      ref.current.value = trimZerosAndDec(ref.current.value)
    }
    return (
      <div className="flex justify-end items-center gap-1 ">
          <div 
            role="button" 
            // type="button"
            className="animate-none w-full min-w-fit justify-end flex"
            onClick={stepDown}>
            <MinusIcon className='z-[1] w-5  '/>
          </div>
            <div className="dropdown dropdown-hover dropdown-top  ">
              <input
                onBlur={handleBlur}
                ref={ref}
                name={productID}
                className="input w-12 2md:w-16 input-xs xl:input-sm text-base 2md:text-base  min-w-[3rem]  focus:border-black text-center border-black border-solid border-[0.75px] rounded-6" 
                defaultValue={Number(initialValue)}
                type="number"
                min={0}
                max={maxQuantity}
                onKeyDown={(e) => e.key === '.' && e.preventDefault()}
              />
              <div className="dropdown-content w-fit left-1/2 right-0 mx-auto z-[10]">
                { communityQty > 0 && (
                  <div className="btn relative bg-white rounded-md p-1 shadow-all-dark -left-1/2 btn-little btn-ghost hover:opacity-70 text-secondary w-fit whitespace-nowrap  font-normal text-lg" 
                  onClick={setToCommunityRequest}>
                    Set To Community Request ({communityQty})
                  </div>
                )}
              </div>
            </div>  
        <div role="button" className="btn-[black] animate-none w-full min-w-fit justify-start flex" 
          key={`${productID}plusbutton`} 
          type="button"
          onClick={stepUp}>
            <PlusIcon className="w-5 "/>
        </div>

        </div>
  )
}

export const RequestAmountControls = forwardRef(RequestAmountControlsFn)