import { orgWishlistConfig } from '@/hooks/state/wishlist/useOrgWishlist';
import { orgWishlistReportConfig } from '@/hooks/state/wishlist/useOrgWishlistReport';
import { arrayToObject, flattenOrgs, recursiveSubnodeHelper } from '@/utils';
import { curry } from '@/utils/advanced';
import { useQueries } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';



export const useOrgsWishlistReportAndDemand = ({orgsQuery, ...props}) => {
    const flatOrgs = useMemo(() => {
        if (!orgsQuery.data) return []
        const clone = structuredClone(orgsQuery.data)
        return flattenOrgs(clone).filter(org => !(org.brand == "true") && !(org.processor == "true"))
    }, [orgsQuery.data])

    const reportQueries = useQueries({
        queries: flatOrgs?.map(org => orgWishlistReportConfig({orgid: org.id})) || [],
        combine: useCallback((queries) => {
            const ret = {
                data: {},
                isPending: queries.map(q => q.isLoading).some(Boolean), 
            }
            if (ret.isPending) return ret
            flatOrgs.map((org, i) => {
                ret.data[org.id] = queries[i].data
            })
            return ret
        },[flatOrgs])
    })
    const demandQueries = useQueries({
        queries: flatOrgs?.map(org => orgWishlistConfig({orgID: org.id})),
        combine: useCallback((queries) => {
            const ret = {
                data: {},
                isPending: queries.map(q => q.isPending).some(Boolean), 
            }
            if (ret.isPending) return ret
            flatOrgs.forEach((org, i) => {
                ret.data[org.id] = queries[i].data
            })
            return ret
        },[flatOrgs])
    })

    // const combinedReportAndDemand = useMemo(() => {
    //     if (orgsQuery.isPending || reportQueries.isPending || demandQueries.isPending ) return null
    //     const flatWishlistItemObject = {} 
    //     const ret = {}
        
    //     for (const org of flatOrgs) {
    //         ret[org.id] = {
    //             ...org,
    //             wishlist: {test: 'hi'}
    //         }
    //         for (const [pid, reportQty] of Object.entries(reportQueries.data[org.id] || {})) {
    //             if (!ret[org.id].wishlist[pid]) {
    //                 ret[org.id].wishlist[pid] = {
    //                     demand: demandQueries.data[org.id]?.[pid],
    //                     report: reportQty
    //                 }
    //             } else {
    //                 ret[org.id].wishlist[pid].report = reportQty
    //             }
    //         }
    //         for (const [pid, demandQty] of Object.entries(demandQueries.data[org.id] || {})) {
    //             if (!ret[org.id].wishlist[pid]) {
    //                 ret[org.id].wishlist[pid] = {
    //                     demand: demandQty,
    //                     report: reportQueries.data[org.id]?.[pid]
    //                 }
    //             } else {
    //                 ret[pid].demand = demandQty
    //             }
    //         }
    //         ret[org.id] = {...org}
    //         console.log('here',ret[org.id]);
    //     }
    //     console.log("ret", ret)
    //     return ret
    // },[flatOrgs, reportQueries.data, demandQueries.data])

    // console.log("wishlistTreeQuery", wishlistTreeQuery.data)

    // console.log("reportQueries", reportQueries.data)
    // console.log("demandQueries", demandQueries.data)
    // console.log('ret', combinedReportAndDemand);
    return {
        demandQueries,
        reportQueries,
    }
};