import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS } from "@/apis/base/routeId"
import { convertObjFieldsToString } from "@/utils"
import { useMutation, useQueryClient } from '@tanstack/react-query';


export const modBDRequest = (params) => {
    params.type = ROUTE_IDS.MOD_BD_REQUEST
    return postPromise(config.API_BASE_URL, convertObjFieldsToString(params), {json: true})
}
