import { SlidersIcon } from '@/components/Icons';
import { BasicToggle } from '@/components/Input/BasicToggle';
import React from 'react';


export const OrgTogglesDropdownCell = ({
    row,
    onEcommerceToggle,
    onCharityToggle,
    onProcessorToggle,
    onBrandToggle,
    onCorporationToggle,
     ...props
    }) => {

        function handleToggle(toggleFunction) {
            return (e) => {
                console.log(toggleFunction);
                toggleFunction?.(row, e.target.checked);
            };
        }

        function handlePointerLeave() {
            document.activeElement.blur()
        }

        return (
            <div className='dropdown static' onPointerLeave={handlePointerLeave}>
                <div
                    role='button'
                    tabIndex={0}
                    className="btn btn-ghost btn-little btn-circle   focus:bg-blue-200"
                >
                    <SlidersIcon />
                </div>
                <div 
                    tabIndex={0}
                    className="dropdown-content font-semibold text-[16spx] p-4 bg-base-100 shadow-all-light z-[12] form-control "
                >
                    <div className="flex items-center gap-5 justify-end">
                        <span>Ecommerce</span>
                        <BasicToggle 
                            onChange={handleToggle(onEcommerceToggle)}
                            defaultChecked={row.ecommerce === '1'}
                            />
                    </div>
                    <div className="divider place-self-center w-32 py-0 -my-1.5"></div>
                    <div className="flex items-center gap-5 justify-end">
                        <span>Charity</span>
                        <BasicToggle 
                            onChange={handleToggle(onCharityToggle)}
                            defaultChecked={row.charity === 'true'}
                            />
                    </div>
                    <div className="divider place-self-center w-32 py-0 -my-1.5"></div>
                    <div className="flex items-center gap-5 justify-end">
                        <span>Processor</span>
                        <BasicToggle 
                            onChange={handleToggle(onProcessorToggle)}
                            defaultChecked={row.processor === 'true'}
                            />
                    </div>
                    <div className="divider place-self-center w-32 py-0 -my-1.5"></div>
                    <div className="flex items-center gap-5 justify-end">
                        <span>Brand</span>
                        <BasicToggle 
                            onChange={handleToggle(onBrandToggle)}
                            defaultChecked={row.brand === 'true'}
                            />
                    </div>
                    {/* <div className="flex font-semibold items-center gap-5 justify-end">
                        <span>Corporation</span>
                        <BasicToggle 
                            onChange={handleToggle(onCorporationToggle)}
                            defaultChecked={row.corporation === '1'}
                            />
                    </div> */}
                </div>
            </div>
        );
    };

