import { Delimit } from '@/components/Misc/Delimit';
import { addCommas } from '@/utils';
import { List } from '@material-ui/core';
import React from 'react';

export const TopDonorsListCard = ({donors, ...props}) => {
    const safeDonors = donors || []
    return (
        <div className='form-control justify-around h-full bg-gray-see text-xs md:text-sm xl:text-md text-white  px-6 pt-7 rounded-2xl min-h-full gap-2'>
            <div className="flex justify-between pb-3 ">
                <p className='text-[16px] text-left'>National Brands</p>
                <p className='text-xs xl:text-sm font-extralight text-right block'>Donation - 3 years</p>
            </div>
            <Delimit
                delimiter={<div className="w-full bg-white h-[1px]"></div>}
            >
                { safeDonors.map((donor, idx) => (
                    <ListRow key={idx} idx={idx} donor={donor?.orgname} amount={donor?.contribution} />
                ))}
            </Delimit>
            <p className='py-7 md:py-10 ml-auto text-xs xl:text-sm font-extralight'>Powered by Unity11</p>
        </div>
    );
};


const ListRow = ({idx, donor, amount}) => (
    <div className='flex w-full justify-between'>
        <span className='text-xs xl:text-sm font-medium'>{`${donor}`}</span>
        <span className='text-xs xl:text-sm font-normal'>{`$${addCommas(amount,'')}`}</span>
    </div>
)