import { TrackedImage } from '@/components/Image/TrackedImage';
import { getLogoUrl } from '@/utils';
import React from 'react';


export const OrgProfileLogo = ({className='w-10', orgid, ...props}) => {
    return (
        <div key={orgid + 'ring'} id={`${orgid}ring`} className="avatar w-fit block ring-accent ring-offset-base-100 ring-[1px]  bg-white  rounded-full " >
            <div className={className}>
                <TrackedImage
                    loading='lazy'
                    className="rounded-full"
                    src={getLogoUrl(orgid)}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="/assets/images/u11square.png";
                        currentTarget.hidden = true
                    }}
                    alt='logo'
                />
            </div>

        </div>
    );
};

