import React from 'react';
import { XIcon } from '../Icons';
import { BasicImageDropdown } from '../dropdowns/BasicImageDropdown';


export const ImageUploadAuditor = ({
    images, 
    onDelete,
    className = 'flex items-end gap-2', 
    ...props}) => {
        return (
            <div className={className}>
                { images.map((img, idx) => (
                    <div
                        key={idx}
                         className="indicator">
                        <div 
                            className="indicator-item indicator-end " 
                            onClick={() => {onDelete(idx)}}
                        >
                            <div className="btn btn-circle btn-xs btn-primary ">
                                <XIcon />
                            </div>
                        </div>
                        <BasicImageDropdown 
                            disableTracking
                            src={img} 
                            imageClassName='w-auto h-fit object-cover aspect max-h-28'
                            className={`
                                w-auto dropdown-top
                                ${idx < 3 ? ` dropdown-start ` : ` dropdown-end `}
                                `}
                            dropdownClassName={`
                                w-72  
                                `}
                        />
                        {/* <img src={img} alt={`event ${idx}`} /> */}
                    </div>
                ))

                }
            </div>
        );
};

