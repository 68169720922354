
import React from "react";


import { PageTitle } from "@/components/Label/PageTitle";
import { useTranslations } from "../../../../hooks/translations";
import Organization from "../../Main/Account/components/pages/Organization";

const ProcessorOrganization = () => {
  const text = useTranslations('account');
  return (
    <div className="container">
      <div className="w-full min-h-screen form-control ">
            <PageTitle>{'Organizations'}</PageTitle>
            <div className='-mt-1.5'>
              <Organization text={text}/>
            </div>
    </div>
  </div>
  );
}

export default ProcessorOrganization;
