import React from "react"
import { cn } from "../../../../utils/misc"
import { ClosedEyeIcon, EyeIcon } from "../../../../components/Icons"


export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    errorMessage?: string | undefined
  }

export const AuthTextInput = React.forwardRef<HTMLInputElement, InputProps>(function ({label='label', errorMessage, ...props},ref) {
    return (
        <label htmlFor={props?.name} className={cn(
            "relative border-2  rounded-md w-full hover:cursor-text group form-control flex-col-reverse h-14 focus-within:border-[#4588ff] transition-auth-input autofill:bg-red-400",
            errorMessage && "border-[#ff4d4d] focus-within:border-[#ff4d4d]",
            )} >
            <input 
              className="font-form  bg-transparent focus:outline-none pl-2.5 w-full transition peer h-full pt-4" {...props} 
              ref={ref} placeholder=" " id={props?.name} />
            <span 
              className="font-form absolute text-base  text-[#bbbbbb] top-4 left-3 group-focus-within:left-2 group-focus-within:top-[0.3rem] group-focus-within:text-[#3588ff] group-focus-within:text-xs peer-[&:not(:placeholder-shown)]:left-2 peer-[&:not(:placeholder-shown)]:text-xs peer-[&:not(:placeholder-shown)]:top-[0.3rem] :text-[#4588ff]  transition-all duration-[250ms] ease-in-out peer-autofill:left-2 peer-autofill:top-[0.3rem] select-none">
                {label}
            </span>
        </label>
    )   
})


export const AuthPasswordInput = React.forwardRef<HTMLInputElement, InputProps>(function ({label='label', errorMessage, ...props},ref) {
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const currentRef = ref ?? inputRef 
  const [showPassword, setShowPassword] = React.useState(false)
  function toggleShow() {
    if (currentRef.current !== null) {
      const isPassword = currentRef.current.type === 'password'
      currentRef.current.type = 
        isPassword
          ? 'text' 
          : 'password'
      setShowPassword(isPassword)
    }
  }
  return (
      <div className="flex flex-col">
        <span className="text-sm mt-1 text-error">{errorMessage}</span>
        <div className="flex relative items-center">
          <label htmlFor={props?.name} className={cn(
              "relative border-2 rounded-md w-full hover:cursor-text group form-control flex-col-reverse h-14 focus-within:border-[#4588ff] transition-auth-input autofill:bg-red-400",
              errorMessage !== '' && "border-[#ff4d4d] focus-within:border-[#ff4d4d]",
              )}>
              <div className="flex h-full items-center peer relative">
                <input type={'password'} ref={currentRef} placeholder=" " id={props?.name} className="font-form bg-transparent focus:outline-none pl-2.5 w-full transition h-full pt-4" {...props} />
              </div>
              <span className="font-form
                absolute text-base text-[#bbbbbb] top-4 left-3
                group-focus-within:left-2
                group-focus-within:top-[0.3rem] auth-input-label
                group-focus-within:text-[#3588ff]
                group-focus-within:text-xs
                peer-has-[input:not(:placeholder-shown)]:left-2
                peer-has-[:autofill]:top-[0.3rem]
                peer-has-[input:not(:placeholder-shown)]:top-[0.3rem]
                peer-has-[input:not(:placeholder-shown)]:text-xs
                transition-all duration-[250ms] ease-in-out
                select-none">
                  {label}
              </span>
          </label>
          <div onClick={toggleShow} tabIndex={-1} className={cn("absolute select-none right-1 swap btn btn-ghost btn-square text-[#bbbbbb] group", showPassword && 'swap-active')}>
            <EyeIcon className="swap-on w-9 h-9 group-hover:text-black"/>
            <ClosedEyeIcon className="swap-off fill-[#bbbbbb] group-hover:fill-black w-9 h-9"/>
          </div>
        </div>
      </div>
  )   
})
// peer-[&:has(:not(:placeholder-shown))]:text-[#4588ff]  