import { getLogoUrl } from '@/utils';
import { TrackedImage } from './TrackedImage';


export const OrgLogo = ({id, customSize=false, className, ...props}) => (
    <TrackedImage 
        src={getLogoUrl(id)} 
        alt="org logo" 
        className={customSize ? className : "max-w-[5rem] min-w-fit object-contain"}
        style={{height: '100px'}}
        onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.classList.add('invisible')
        }}
        {...props}
    />
)

