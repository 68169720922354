import useModAppRequests from "@/hooks/state/admin/useModAppRequests";
import useRoleRequests from "@/hooks/state/admin/useRoleRequests";
import useUserRoleRequests from "@/hooks/state/admin/useUserRoleRequests";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import useUserData from "@/hooks/state/user/useUserData";
import { ConfirmationPopup } from "@/wrappers/swal/ConfirmationPopup";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { userTypes } from "../../constants";
import { useAppContext } from "../../context/AppContext";
import { useTranslations } from "../../hooks/translations";
import { getDateTimeString } from "../../utils";
import { BasicBlueRectButton } from "../Buttons/BasicBlueRectButton";
import { BasicGhostButton } from "../Buttons/BasicGhostButton";
import { BasicRedRectButton } from "../Buttons/BasicRedRectButton";
import { BasicPageCard } from "../Cards/BasicPageCard";
import { ConfirmDialog } from "../Dialogs";
import { BackArrow } from "../Icons";
import { AvatarDropdown } from "../dropdowns/AvatarDropdown";
import { EditUserInfoDetail } from "./UserInfoDetail/EditUserInfoDetail";
import { RolesList } from "./UserInfoDetail/RolesList";
import { UserInfoDetail } from "./UserInfoDetail/UserInfoDetail";
import { AmbassadorDetail } from "./UserRoleDetail/AmbassadorDetail";
import { FINDetail } from "./UserRoleDetail/FINDetail";
import { SMIDetail } from "./UserRoleDetail/SMIDetail";
import { BasicBackButton } from "@/components/Buttons/BasicBackButton";
import { GrayButton } from "@/views/pages/Auth/components/Buttons";
import { BasicGrayButton } from "@/components/Buttons/BasicGrayButton";

const UserProfile = ({user, exitDetailView, backLabel='Back', showRoles=true, showUserType=true, userOrgsData, showUserOrgs}) => {
  const text = useTranslations('userProfile');
  const client = useQueryClient()
  const contextQuery = useCurrentOrgContext()
  const ctx = contextQuery.data
  const roleEnumQuery = useRoleEnum()
  const roleEnum = roleEnumQuery.data
  const appStatusQuery = useAppStatusEnum()
  const appStatuses = appStatusQuery.data
  const userRoleQuery = useUserRoleRequests({userid: user?.id, orgid: ctx?.orgid});
  const userDataQuery = useUserData({userids: [user?.id]})
  const tempRoleRequestQuery = useRoleRequests({orgid: ctx.orgid, roleids: roleEnum.allRoles,flat:false})

  // Use an updatable user query once it's available 
  const safeUser = userDataQuery.data?.[0]

  const roleMutation = useModAppRequests({
    onSuccess: (data) => {
      client.invalidateQueries(['user', [user?.id]])
      SuccessToast.fire({text: 'Request Updated'})
    },
    onError: (error) => {FailPopup.fire({text: 'Failed to update request'})}
  })

  const PERSONAL_CONTENT = {
    OVERVIEW: "overview",
    UPDATE_INFO: "update_info",
    AUDIT_VIEW: "audit_view"
  };

  const ROLE_CONTENT = {
    OVERVIEW: "overview",
    FIN_VIEW: "fin_view",
    AMB_VIEW: "amb_view",
    SMI_VIEW: "smi_view",
    GUARD_VIEW: "guard_view"
  };

  const [personalContent, setPersonalContent] = useState(PERSONAL_CONTENT.OVERVIEW);
  const [userInfoChangeLogs, setUserInfoChangeLogs] = useState([]);

  const [openConfirmUpdatePersonalInfoDlg, setOpenConfirmUpdatePersonalInfoDlg] = useState(false);
  const [openConfirmDeactivateRoleDlg, setOpenConfirmDeactivateRoleDlg] = useState(false);

  const [roleContent, setRoleContent] = useState(ROLE_CONTENT.OVERVIEW);
  const [dependents, setDependents] = useState([]);

  // TODO: Change this once we have a proper userroleapplications endpoint
  const tempRoleRequests = useMemo(() => {
    if (tempRoleRequestQuery.isSuccess) {
      const requests = {}
      requests.fin = tempRoleRequestQuery.data.FIN.find(u => u?.['user id'] === user?.id)
      requests.ambassador = tempRoleRequestQuery.data.Ambassador.find(u => u?.['user id'] === user?.id)
      requests.smi = tempRoleRequestQuery.data['Social Media Influencer'].find(u => u?.['user id'] === user?.id)
      return requests
    }
    return []
  },[tempRoleRequestQuery.data])

  const roles = [
    {label: 'Family In Need', name: 'fin', type: roleEnum.fin, statusKey: 'finStatus', fieldName: 'FIN', },
    {label: 'Ambassador', name: 'ambassador', type: roleEnum.ambassador, statusKey: 'ambStatus', fieldName: 'Ambassador', },
    {label: 'Social Media Influencer', name: 'smi', type: roleEnum.smi, statusKey: 'smiStatus', fieldName: 'Social Media Influencer'},
  ]
  const userRoleData = userRoleQuery.data

  const onUpdatePersonalInfoBtnClicked = () => {
    // setUpdatedUserInfo(userInfo);
    setPersonalContent(PERSONAL_CONTENT.UPDATE_INFO);
  };

  const onCancelUpdatePersonalInfoBtnClicked = () => {
    setPersonalContent(PERSONAL_CONTENT.OVERVIEW);
  };

  const onSavePersonalInfoBtnClicked = () => {
    // setPersonalContent(PERSONAL_CONTENT.OVERVIEW);
    setOpenConfirmUpdatePersonalInfoDlg(true);
  };

  const onPersonalInfoChanged = (key, value) => {
    // setUpdatedUserInfo({...updatedUserInfo, [key]: value});
  };


  const onRoleClicked = (role_type) => {
    switch (role_type) {
      case roleEnum.fin:
        setRoleContent(ROLE_CONTENT.FIN_VIEW);
        break;
      case roleEnum.ambassador:
        setRoleContent(ROLE_CONTENT.AMB_VIEW);
        break;
      case roleEnum.smi:
        setRoleContent(ROLE_CONTENT.SMI_VIEW);
        break;
      case roleEnum.guardian:
        setRoleContent(ROLE_CONTENT.GUARD_VIEW);
        break;
    }
  };

  const onDeactivateButtonClicked = () => {
    ConfirmationPopup.fire({
      title: 'Deactivate Role',
      text: 'Are you sure you want to deactivate the role of this user?',
    }).then((res) => {
      res.isConfirmed && onConfirmRoleDeactivate()
    })
  }

  const onConfirmRoleDeactivate = () => {
    setOpenConfirmDeactivateRoleDlg(false);
    let finids = [], ambassadorids = [], smiids = [], guardianids = [];
      switch (roleContent) {
        case ROLE_CONTENT.FIN_VIEW:
          finids = [tempRoleRequests.fin.id]
          break;
        case ROLE_CONTENT.AMB_VIEW:
          ambassadorids = [tempRoleRequests.ambassador.id]
          break;
        case ROLE_CONTENT.SMI_VIEW:
          smiids = [tempRoleRequests.smi.id]
          break;
        case ROLE_CONTENT.GUARD_VIEW:
          break;
      }
    roleMutation.mutate({orgid: ctx.orgid, statusid: appStatuses.declined, 'finids[]': finids, 'ambassadorids[]': ambassadorids, 'smiids[]': smiids, 'guardianids[]': guardianids})
  };

  return safeUser && (
    <BasicPageCard>
      <div className="flex flex-col px-4 mx-4">
        <div className="flex flex-col">
          <BasicBackButton onClick={exitDetailView} className={'w-fit mb-4 -ml-5'} label={backLabel}/>
          <div className="flex justify-between mb-2">
            <div className="flex align-middle">
                <AvatarDropdown numImages={1} userid={safeUser.id}

                />
              <span className="text-2xl align-middle ml-2">
                {personalContent === PERSONAL_CONTENT.OVERVIEW ? text.personalInfo :
                  personalContent === PERSONAL_CONTENT.UPDATE_INFO ? text.updatePersonalInfo : text.auditLog}
              </span>
            </div>
            {user.type !== userTypes.member && (
              <>
                {personalContent === PERSONAL_CONTENT.OVERVIEW && (
                  <BasicGrayButton small={true} onClick={() => setPersonalContent(PERSONAL_CONTENT.AUDIT_VIEW)}>
                    <span className="sm:block hidden">{text.viewHistory}</span>
                    <span className="sm:hidden block">History</span>
                  </BasicGrayButton>
                )}
                {personalContent === PERSONAL_CONTENT.AUDIT_VIEW && (
                  <GrayButton small={true} onClick={() => setPersonalContent(PERSONAL_CONTENT.OVERVIEW)}>
                    <span className="sm:block hidden">{text.viewProfile}</span>
                    <span className="sm:hidden block">Profile</span>
                  </GrayButton>
                )}
              </>
            )}
          </div>
          <div className="divider my-0"/>
          <div className="flex flex-col mt-2 mb-4">
            {user &&  (
              <>
                { personalContent === PERSONAL_CONTENT.OVERVIEW && (
                  <>
                    <UserInfoDetail showUserType={showUserType} user={safeUser}/>
                    <div className="ml-[33%]">
                      <BasicBlueRectButton className={'btn-sm text-sm'} customSize onClick={onUpdatePersonalInfoBtnClicked}>
                        {text.update}
                      </BasicBlueRectButton>

                    </div>
                  </>

                )}
                { personalContent === PERSONAL_CONTENT.UPDATE_INFO && (
                  <>
                    <EditUserInfoDetail  user={safeUser} onCancel={onCancelUpdatePersonalInfoBtnClicked}/>
                  </>
                )}
              </>
            )}
            {personalContent === PERSONAL_CONTENT.AUDIT_VIEW && (
              <>
                {userInfoChangeLogs.length === 0 && (
                  <div className="flex flex-col mb-4">
                    <span className="text-gray-500">{text.noupdateloghistory}</span>
                  </div>
                )}
                {userInfoChangeLogs.map((userInfoChangeLog, idx) => (
                  <div className="flex flex-col mb-4" key={idx}>
                    <div>
                      <span className="text-gray-400 text-sm mr-1">{text.updatedBy}</span>
                      <span className="mr-1">{userInfoChangeLog.updated_by}</span>
                      <span className="text-gray-500 text-sm mr-1">{text.updatedAt}</span>
                      <span>{getDateTimeString(userInfoChangeLog.timestamp)}</span>
                    </div>
                    {Object.keys(userInfoChangeLog.details).map((obj_key, idx) => (
                      <div className="flex flex-7" key={idx}>
                        <div className="flex-2">
                          <span>{}</span>
                        </div>
                        {obj_key === 'is_active' ? (
                          <div className="flex-5">
                            <span
                              className="w-fit-content bg-gray-200 text-gray-500 line-through rounded-6 py-px px-2.5 mb-1 text-xs mr-2">
                              {userInfoChangeLog.details[obj_key]['prev_value'] ? text.active : text.inActive}
                            </span>
                            <span
                              className={`w-fit-content ${userInfoChangeLog.details[obj_key]['curr_value'] ? "bg-green-pale-lime text-green-dark-lime" : "bg-red-misty-rose text-red-strong"} rounded-6 py-px px-2.5 mb-1 text-xs`}>
                              {userInfoChangeLog.details[obj_key]['curr_value'] ? text.active : text.inActive}
                            </span>
                          </div>
                        ) : (
                          <div className="flex-5">
                            <span
                              className="text-gray-400 text-sm line-through">{userInfoChangeLog.details[obj_key]['prev_value']}</span>
                            <span className="ml-2">{userInfoChangeLog.details[obj_key]['curr_value']}</span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>

        { showRoles &&
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <span className="text-2xl">
                {roleContent === ROLE_CONTENT.OVERVIEW ? text.roles :
                  roleContent === ROLE_CONTENT.FIN_VIEW ? text.finApplication :
                    roleContent === ROLE_CONTENT.AMB_VIEW ? text.ambApplication :
                      roleContent === ROLE_CONTENT.SMI_VIEW ? text.smiApplication :
                        roleContent === ROLE_CONTENT.GUARD_VIEW ? text.guardApplication : ""
                }
              </span>
              {roleContent !== ROLE_CONTENT.OVERVIEW && (
                <GrayButton small={true} onClick={() => setRoleContent(ROLE_CONTENT.OVERVIEW)}>{text.goBack}</GrayButton>
              )}
            </div>
            <div className="divider my-0"/>
            <div className="mb-2"/>
            {(roleContent === ROLE_CONTENT.OVERVIEW && user) && (
              <RolesList {...{onRoleClicked, roles, text, user: safeUser, appData: userRoleData}}/>
            )}
            {roleContent === ROLE_CONTENT.FIN_VIEW  && 
              <>
                <FINDetail app={tempRoleRequests?.fin?.findata}/>
              </>
            }
            
            {roleContent === ROLE_CONTENT.AMB_VIEW  &&
              <AmbassadorDetail app={tempRoleRequests?.ambassador.ambassadordata} />
              }
            {roleContent === ROLE_CONTENT.SMI_VIEW  &&
              <SMIDetail app={tempRoleRequests?.smi?.smidata} />
            }
            {roleContent !== ROLE_CONTENT.OVERVIEW && (
              <div className="flex flex-7">
                <div className="flex-2"/>
                <div className="flex-4 mt-4">
                  <BasicRedRectButton customSize className='btn-sm'
                            onClick={onDeactivateButtonClicked}>
                    {text.deactivate}
                  </BasicRedRectButton>
                </div>
              </div>
            )}
        </div>
        }

        <ConfirmDialog open={openConfirmUpdatePersonalInfoDlg}
                      title={text.updatePersonalInfoDlgTitle}
                      description={text.updatePersonalInfoDlgDescription}
                      //  onConfirm={onConfirmUpdatePersonalInfoBtnClicked}
                      onCancel={() => setOpenConfirmUpdatePersonalInfoDlg(false)}
        />
      </div>
    </BasicPageCard>
  )
};

export default UserProfile;
