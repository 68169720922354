import { WhiteCard } from '@/components/Cards';
import { WishlistIcon } from '@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons';
import { CheckBoxIcon, LinkIcon } from '@/components/Icons';
import React from 'react';


export const WishlistReportCard = ({history, ...props}) => {
    function navToWishlistReport() {
        history.push('reporting/wishlist')
    }
    return (
        <div className="card card-compact bg-white shadow-sm hover:shadow-all-light transition-shadow will-change-[shadow] duration-700 hover:cursor-pointer  w-full" onClick={navToWishlistReport}>
            <div className="card-body">
                <div className="flex w-full justify-between">
                    <WishlistIcon className='w-7 h-7 opacity-50 mt-3' />
                    <LinkIcon className='w-4 h-4'/>
                </div>
                <h2 className="card-title">
                    Wishlist
                </h2>
                <p className='text-gray-subText'>   
                    <span className='font-semibold text-green-text'>{11}</span> Reports submitted
                </p> 
                <p className='text-gray-subText'>   
                    <span className='font-semibold  text-red-text'>{5}</span>  Reports pending
                </p> 
            </div>
        </div>
    );
};

