import { SendIcon } from "@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons";
import { AvatarDropdown } from "@/components/dropdowns/AvatarDropdown";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { UserCell } from "../Cells/UserCell";


const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const PopupPickupOrdersCols = [
columnHelper.accessor((row, idx) => idx, {
    id: "No",
    enableColumnFilter: false,
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>ID</span>,
  }),
columnHelper.accessor((row) => row.userid, {
    id: "Pickup ID",
    enableColumnFilter: false,
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>PICKUP ID</span>,
  }),
columnHelper.accessor((row) => row.username, {
    id: "Name",
    size: 100,
    filterFn: stringFilter,
    cell: ({row, table}) => <UserCell row={{...row.original, name: row.original.username}} 
        onClick={() => table.options.meta.handleUserClick(row.original)}/>,
    header: (info) => <span>NAME</span>,
  }),
columnHelper.display({
    id: "Pickup Limit",
    size: 100,
    filterFn: stringFilter,
    cell: ({row, table}) => <PickupLimit {...{row, table}}/>,
    header: (info) => <span>PICKUP LIMIT</span>,
  }),
columnHelper.display({
    id: "Product Name",
    size: 30,
    filterFn: stringFilter,
    cell: (info) => <ProductNames lineItems={info.row.original.items}/>,
    header: (info) => <span>PRODUCT NAME</span>,
  }),
columnHelper.display({
    id: "Requested Quantity",
    enableColumnFilter: false,
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <RequestedQuantities lineItems={info.row.original.items}/>,
    header: (info) => <span>REQUESTED QUANTITY</span>,
  }),
columnHelper.display({
    id: "Received Quantity",
    enableColumnFilter: false,
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <PickedUpQuantities lineItems={info.row.original.items}/>,
    header: (info) => <span>PICKED UP QUANTITY</span>,
  }),
columnHelper.accessor((row) => row.picked, {
    id: "Status",
    enableColumnFilter: false,
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <StatusBadge picked={info.getValue()}/>,
    header: (info) => <span>STATUS</span>,
  }),
columnHelper.display({
    id: "Action",
    size: 10,
    cell: ({row,table}) => 
        ( table.options.meta?.popupActive && !row.original.picked && (
            <Action  {...{row: row.original,...table.options.meta}}/>

        )),
    header: (info) => <span>ACTION</span>,
  }),
]

const StatusBadge = ({picked}) => (
    <div className={`badge ${picked ? 'bg-green-back text-green-text' : 'bg-red-back text-red-text'}`}>
        {picked ? 'Picked' : 'Unpicked'}
    </div>
)

const getRowStatusLabel = (status) => status === '0' ? 'Unpicked' : 'Picked'

const Action = ({pickupConfirmedClicked, row}) => (
    <div className="">
        <button className="btn btn-ghost btn-circle btn-little " onClick={() => pickupConfirmedClicked(row)}>
            <SendIcon className="w-5 h-5"/>
        </button>
    </div>
)

const ProductNames = ({lineItems}) => (
    <div className="form-control gap-2">
        {Object.values(lineItems).map((item, index) => (
            <div key={item.shipmentid} className="flex items-center">
                <p className="text-sm">{item.productname}</p>
            </div>
        ))}
    </div>
)

const RequestedQuantities = ({lineItems}) => (
    <div className="form-control gap-2">
        {Object.values(lineItems).map((item, index) => (
            <div key={item.shipmentid} className="flex items-center">
                <p className="text-sm">{item.quantity}</p>
            </div>
        ))}
    </div>
)

const PickedUpQuantities = ({lineItems}) => (
    <div className="form-control gap-2">
        {Object.values(lineItems).map((item, index) => (
            <div key={item.shipmentid} className="flex items-center">
                <p className="text-sm">{item.qtyconfirmed}</p>
            </div>
        ))}
    </div>
)

const ProductRequests = ({lineItems}) => (
    <div className="form-control gap-2">
        {lineItems.map((item, index) => (
            <div key={item.shipmentid} className="grid grid-flow-col columns-2 ">
                <p>{item.productname}</p>
                <p className="text-sm">{item.quantity}</p>
            </div>
        ))}
    </div>

)

const PickupLimit = ({table, row}) => {
    const dependentsObject = table.options.meta.dependentsObject
    if (!dependentsObject) return <div className="loading loading-xs"></div>
    const pickupLimit = 
        (dependentsObject[row.original.userid]?.uniqueDependents?.length || 0) + 1
    return <span>{pickupLimit}</span>
}