
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cancelRoleApp } from '@/apis/base/applications/cancelRoleApp';


export default function useCancelRoleApp({onSuccess, onError}) {
    const client = useQueryClient()
    const query = useMutation({
        mutationKey: ['context', 'role',ROUTE_IDS.CANCEL_ROLE_APPLICATION],
        mutationFn: cancelRoleApp,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey:['context']});
            onSuccess(data)
        },
        onError
    });
    return query;
}