import { Route, useLocation } from 'react-router-dom/cjs/react-router-dom';
import UnauthenticatedHeader from '../Headers/UnauthenticatedHeader/UnauthenticatedHeader';


export const UnprotectedRoute = ({children,...props}) => {
    const {pathname} = useLocation()
    return (
        <div className='scroll-auto'>
            { !pathname.includes('auth') &&
                <UnauthenticatedHeader />
            }
            <div className='grid'>
                    <div className='sticky flex col-span-1 row-span-1 right-0 top-0 h-0  z-[10000]' id={'popuptarget'}/>
                <div className='col-1 row-span-1'>
                    <Route  {...props}/>
                </div>
            </div>

        </div>
    )
};

