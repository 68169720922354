import { useGeoIDs } from '@/hooks/state/misc/useGeoIDs';
import useModOrgData from '@/hooks/state/organization/useModOrgData';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import React from 'react';
import { BasicTable } from '../BasicTable';
import { orgTableColumnHelper } from './orgTableColumnHelper';


export const OrganizationManagementTable = ({orgs,canChangeOrgRoles=false, copyInviteLink, ...props}) => {
    const safeOrgs = orgs?.length > 0 ? orgs : []

  const modOrgMutation = 
    useModOrgData( 
        queryResultFns(
          "Successfully modified organization",
          "Failed to modify organization")
    ); 

    const ecommercePermissionUpdated = (org, newVal) => 
        modOrgMutation.mutate({orgid:org.id, ecommerce: newVal});
    const handleProcessorToggle = (org,newValue) => 
        modOrgMutation.mutate({orgid:org.id, processor: newValue});
    const handleBrandToggle = (org,newValue) => 
        modOrgMutation.mutate({orgid:org.id, brand: newValue});
    const handleCharityToggle = (org,newValue) => 
        modOrgMutation.mutate({orgid:org.id, charity: newValue});

    return (
        <BasicTable
            {...props}
            meta={{
                onEcommerceToggle: ecommercePermissionUpdated,
                onProcessorToggle: handleProcessorToggle,
                onBrandToggle: handleBrandToggle,
                onCharityToggle: handleCharityToggle,
                copyInviteLink
            }}
            state={{
                columnVisibility: {'Permissions & Roles': canChangeOrgRoles}
            }}
            initialExpand
            canExpand
            columnHelper={orgTableColumnHelper}
            data={safeOrgs}
        >
            {props.children}
        </BasicTable>
    );
};

