import React from "react";

import { PageTitle } from "@/components/Label/PageTitle";
import { DonationManagementPage } from "@/components/pages/DonationMangementPage";
import useProcessorDonations from "@/hooks/state/donation/useProcessorDonations";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { useTranslations } from "@/hooks/translations";
import { BD_ROLES } from "@/constants";

const ProcessorDonation = ({history}) => {
    const text = useTranslations("processorDonation");
    const contextQuery = useCurrentOrgContext()
    const donationsQuery = useProcessorDonations({processorid: contextQuery?.data?.orgid})
    const donations = donationsQuery.data;
    return (
        <div className="w-full min-h-content flex justify-center">
          <div className="w-full h-full">
              <PageTitle>{text.title}</PageTitle>
              <DonationManagementPage {...{role: BD_ROLES.PROCESSOR, donations}}/>
          </div>
        </div>
    );
}

export default ProcessorDonation;
