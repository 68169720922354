import { MILLISEC } from '@/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getGeoData, honestGeoData } from '@/apis/base/misc';
import { setMyBrands } from '@/apis/base/brands/setMyBrands';

export default function useSetMyBrands({ onError, onSuccess, props }) {
    const client = useQueryClient() 
    const brandsQuery = useMutation({
        mutationKey: ['user',ROUTE_IDS.SET_BRANDS],
        mutationFn: setMyBrands,
        staleTime: MILLISEC.MIN_15,
        retry: false,
        onError: onError,
        onSuccess: () => {
            onSuccess();
            client.invalidateQueries({queryKey: ['user']})
        },
        ...props
    });
    return brandsQuery;
}
