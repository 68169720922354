import DropdownTwo from "@/components/DropDownTwo";
import { InputBase } from "@/components/Input/InputBase";
import { useMemo } from "react";
import { forwardRef } from "react";
import { useController } from "react-hook-form";

export const BasicDropdownInput = forwardRef(function({
    options: controlledOptions=null,
    data=null,
    initialValue = {},
    hasSubOptions=false,
    label=null,
    name,
    renderFn=({option}) => option?.label,
    className, 
    required=false,
    onChange=()=>{},
    containerClassName, 
    control,
    disabled: controlledDisabled=false,
    ...props}, r)  {
        const {field, fieldState: {error}, formState: { disabled }} = 
            useController({name, control, defaultValue: initialValue})
        const options = useMemo(()=> {
            if (!data && !controlledOptions) return []
            return (
                controlledOptions || 
                data.map((option) => {
                    const suboptions = option.subnodes?.length > 0 
                        ?   option?.subnodes?.map(
                                (sub) => ({
                                    label: sub.name,
                                    value: sub?.id,
                                    ...sub
                                })
                            )
                        :   []
                    return {
                        label: option.name,
                        value: option?.id,
                        suboptions,
                        ...option,
                    }
                })
            )
        },[data])
        const {ref,...otherfield} = field || {}

        const isDisabled = controlledDisabled || disabled
        return (
            <InputBase
                {...{
                    label,
                    isError: error!=null,
                    errorText: error?.message,
                    required,
                    containerClassName,
                    disabled: isDisabled
                }}
            >
                <DropdownTwo
                    controlled
                    renderFn={renderFn}
                    options={options}
                    value={field.value}
                    disabled={isDisabled}
                    valueFn={option => {console.log(option);option?.id}}
                    hasSubOptions={hasSubOptions}
                    initialValue={initialValue}
                    className={`
                        focus:border-primary
                        disabled:opacity-30
                        ${isDisabled ? 'input-disabled bg-gray-100 border-none' : ''}
                        ${className}
                    `}
                    {...props}
                    {...otherfield}
                />
            </InputBase>
        )
});