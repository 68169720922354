import { UserCell } from "@/components/Tables/Cells/UserCell";
import { createColumnHelper, filterFns } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove

export const VIPMemberCols = [
    columnHelper.accessor((row) => row.id, {
        id: "Member",
        size: 100,
        filterFn: stringFilter,
        cell: ({row}) => <UserCell row={row.original}/>,
        header: (info) => <span>Members</span>,
    }),
    columnHelper.accessor((row) => row.id, {
        id: "Authority",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>Authority</span>,
    }),
    columnHelper.accessor((row) => row.id, {
        id: "Roles",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <UserCell row={info.getValue()}/>,
        header: (info) => <span>Members</span>,
    }),
]


function getUserAuthority(user) {
    if (user?.owner) return "Master Admin"
    if (user?.admin) return "Admin"
    return ""
}

function getRolesArray(user) {
    const roles = []
    if (user?.amb === 'true') roles.push("Brand Ambassador")
    if (user?.smi === 'true') roles.push('Social Media Influencer')
}