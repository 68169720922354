import React, { useMemo } from "react";

import { BasicDialog } from "@/components/Dialog/BasicDialog";
import { AmbassadorDetail } from "@/components/User/UserRoleDetail/AmbassadorDetail";
import { FINDetail } from "@/components/User/UserRoleDetail/FINDetail";
import { SMIDetail } from "@/components/User/UserRoleDetail/SMIDetail";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import useOrgTypes from "@/hooks/state/enum/useOrgTypes";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";

const VIEWS = {
  NONE: -1,
  FIN: 0,
  AMB: 1,
  SMI: 2,
}

const RequestDetailsDialog = ({request, dialogRef}) => {
  const rolesQuery = useRoleEnum()
  const appStatusQuery = useAppStatusEnum()
  const orgTypesQuery = useOrgTypes();
  const types = orgTypesQuery?.data;
  const roleEnum = rolesQuery?.data
  const appStatuses = appStatusQuery?.data
  const view = useMemo(() => {
    if (!request || !roleEnum || !appStatuses) return VIEWS.NONE
    switch (request.roleid){
      case roleEnum.fin:
        return VIEWS.FIN
      case roleEnum.ambassador:
        return VIEWS.AMB
      case roleEnum.smi:
        return VIEWS.SMI
      default:
        return VIEWS.NONE
    }
  } ,[request, roleEnum, appStatuses])
  return (
    <BasicDialog  
      title={dialogTitle(request)}
      className='min-w-fit'
      hideActions
      ref={dialogRef}
    >
      { view === VIEWS.FIN && (
        <FINDetail app={request.findata}/>
      )}
      { view === VIEWS.AMB && (
        <AmbassadorDetail app={request.ambassadordata}/>
      )}
      { view === VIEWS.SMI && (
        <SMIDetail app={request.smidata}/>
      )}
    </BasicDialog>
  );
};

function dialogTitle(request){
  return request && (
    <p className="font-normal whitespace-nowrap">
      <span className="">{request.name}</span>&apos;s <span className="underline font-semibold">{request.roleLabel}</span> request at <span className="font-semibold">{request.orgname}</span>

    </p>
  )
}

export default RequestDetailsDialog;

