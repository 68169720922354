import React from 'react';
import {SelectInput, TextInput} from "../../../../../../components/Inputs";
import {Select} from "@material-ui/core";
import {DeleteSweep} from "@material-ui/icons";

function AddDependent({text, dependents, onChange, onRemove}) {
  return (
    <>
      {
        dependents.map((dependent, idx) => (
          <div className="lg:flex flex-col mt-5" key={`dependent-${idx}`}>
            <div className="flex justify-between">
              <span>{idx + 1})</span>
              <DeleteSweep onClick={() => onRemove(idx)}/>
            </div>
            <div className="flex">
              <TextInput className={"mt-1 flex-1"} value={dependent.firstname} placeholder={text.firstname}
                         onChange={e => onChange(idx, 'firstname', e.target.value)}/>
              <span className="text-red-strong ml-2">*</span>
            </div>
            <div className="flex">
              <TextInput className={"mt-1 flex-1"} value={dependent.lastname} placeholder={text.lastname}
                         onChange={e => onChange(idx, 'lastname', e.target.value)}/>
              <span className="text-red-strong ml-2">*</span>
            </div>
            <div className="flex">
              <TextInput className={"mt-1 flex-1"} value={dependent.email} placeholder={text.email}
                         onChange={e => onChange(idx, 'email', e.target.value)}/>
              <span className="text-red-strong ml-2">*</span>
            </div>
            <div className="flex">
              <TextInput className={"mt-1 flex-1"} value={dependent.age} placeholder={text.age}
                         onChange={e => onChange(idx, 'age', e.target.value)}/>
              <span className="text-red-strong ml-2">*</span>
            </div>
            <div className="flex">
              <Select className="mt-1 flex-1"
                      native
                      value={dependent.gender}
                      onChange={e => onChange(idx, 'gender', e.target.value)}
                      input={<SelectInput/>}
              >
                <option value={text.male}>{text.male}</option>
                <option value={text.female}>{text.female}</option>
                <option value={text.notDisclosed}>{text.notDisclosed}</option>
              </Select>
              <span className="text-red-strong ml-2">*</span>
            </div>
            <div className="flex">
              <Select
                className={"mt-1 flex-1"}
                native
                defaultValue={dependent.relationship}
                input={<SelectInput/>}
                onChange={e => onChange(idx, 'relationship', e.target.value === 'I am a parent' ? 'Parent' : 'Guardian')}
              >
                <option value='' disabled>{text.relationship}</option>
                <option value={text.relationshipFamily}>{text.relationshipFamily}</option>
                <option value={text.relationshipGuardian}>{text.relationshipGuardian}</option>
              </Select>
              <span className="text-red-strong ml-2">*</span>
            </div>
          </div>
        ))
      }
    </>
  );
}

export default AddDependent;
