import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery } from '@tanstack/react-query';
import { inviteMember } from '@/apis/base/invites/inviteMember';

export default function useInviteMember(props) {
    const query = useMutation({
        mutationKey: [ROUTE_IDS.INVITE_MEMBER],
        mutationFn: inviteMember,
        staleTime: MILLISEC.MIN_15,
        ...props
    });
    return query;
}