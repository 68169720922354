import { BasicBackButton } from '@/components/Buttons/BasicBackButton';
import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { EventCarousel } from '@/components/Carousel/EventCarousel';
import { ProductCarousel } from '@/components/Carousel/ProductCarousel';
import { MainContainer } from '@/components/Containers/MainContainer';
import { ConfirmShipmentDialog } from '@/components/Dialog/ConfirmShipmentDialog';
import { ManagePopupDialog } from '@/components/Dialog/ManagePopupDialog';
import { ConfirmDeliveryIcon, EditIcon, ExcelIcon, MinusIcon, PlusIcon, RequestIcon, StoreIcon, TreeIcon } from '@/components/Icons';
import { BasicPulseLoader } from '@/components/Loaders/BasicPulseLoader';
import { OrgTreeBDShipmentReport } from '@/components/Tables/OrgTreeBDShipmentReport/OrgTreeBDShipmentReport';
import { BasicTooltip } from '@/components/Tooltips/BasicTooltip';
import { useCommunityEventState } from '@/hooks/state/donation/aggregateHelpers/useCommunityEventView';
import useOrgPopups from '@/hooks/state/donation/popupStore/useOrgPopups';
import useModBDRequest from '@/hooks/state/donation/request/useModBDRequest';
import useLiveBDEvents from '@/hooks/state/donation/useLiveBDEvent';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useOrgTree from '@/hooks/state/organization/useOrgTree';
import { getNiceDateString, openDialogWithRef, recursiveSubnodeHelper } from '@/utils';
import { downloadCSV } from '@/utils/fileUtils/downloadCSV';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const STEPS = {
    AWAITING_REQUEST: 0.5,
    REQUESTED : 1,
    AWAITING_SHIPMENT: 1.5,
    SHIPPED: 2,
    AWAITING_CONFIRMATION: 2.5,
    RECEIVED: 3,
    AWAITING_STORE: 3.5,
    DISTRIBUTED: 4,
}

const ERRORS = {
    PARTIALLY_RECEIVED: 3,
}

const VIEWS = {
    DEFAULT: 0,
    TREE: 1
}

export const EventRequest = ({...props}) => {
    const {history, match} = props
    const {brandorgid, eventid, orgid } = match.params || {};

    const shipmentDialogRef = useRef()
    const popupDialogRef = useRef()
    const [values, setValues] = useState({})
    const [step, setStep] = useState(STEPS.AWAITING_REQUEST)
    const [errors, setErrors] = useState([])
    const [inlineEditing, setInlineEditing] = React.useState(false)
    const [view, setView] = useState(VIEWS.DEFAULT)
    const orgTreeQuery = useOrgTree({orgid})
    const ctx = useCurrentOrgContext().data
    const communityEventState = useCommunityEventState({brandorgid, eventid, orgid})
    const popupsQuery = useOrgPopups({orgid})
    const currPopup = useMemo(() => popupsQuery.data?.find(p => p.eventid === eventid),[popupsQuery])
    const requestMutation = useModBDRequest(queryResultFns(
        'Request Updated', 
        'Failed to Update Request'))
    const eventsQuery = useLiveBDEvents()
    useEffect(() => {
        if (!communityEventState?.lineItems) return
        const initValues = values
        communityEventState.lineItems.forEach(({inventoryid, requestQuantity, confirmedQuantity}) => {
            initValues[inventoryid] = {
                inventoryid,    
                requested: requestQuantity,
                confirmed: confirmedQuantity,
            }
        })
        const progress = inferEventProgress(communityEventState, currPopup)
        const errors = inferEventErrors(communityEventState)
        setValues({...values, ...initValues})
        setErrors(errors)
        setStep(progress)
    } ,[communityEventState, currPopup])
    const event = useMemo(() => {
        return eventsQuery.data?.find?.(e => e.id === eventid)
    },[eventsQuery.data])

    function handleChange(inventoryid, field, value) {
        let newValue = value
        if (newValue < 0) newValue = 0
        setValues({...values, [inventoryid]: {...values[inventoryid], [field]: value}})
    }

    function updateRequest() {
        const lineitems = Object.values(values)?.map(({inventoryid, requested}) => ({inventoryid, quantity: requested}))
        const params = {orgid, eventid, lineitems}
        requestMutation.mutate(params)
    }
        // console.log(orgTreeQuery.data?.[0]);
    function handleDataExport() {
        if (view === VIEWS.DEFAULT) return downloadSpreadsheet({...communityEventState, name:ctx?.orgName})
        recursiveSubnodeHelper(
            orgTreeQuery.data?.[0],
            (org) => downloadSpreadsheet(addLineItemData(org)))
    }
    // console.log('event',event, orgTreeQuery.data?.[0]);
    function addLineItemData(org) {
        const lineItems = event.donations?.map((item) => ({
            ...item,
            requestQuantity: org.lineitems?.[item.id]?.requestquantity || 0,
            shipmentQuantity: org.lineitems?.[item.id]?.confirmedquantity || 0,
            confirmedQuantity: org.lineitems?.[item.id]?.shipmentquantity || 0,
        }))
        // console.log("lineItems", lineItems)
        return {...org, lineItems}
    }

    function downloadSpreadsheet(org) {
        // console.log('org',org);
        // console.log('li',org);
        // const {lineItems, name} = org
        if (!org.lineItems?.some((item) => item?.requestQuantity > 0)) return
        const fileTitle = event?.name + ' Donation Event Summary - ' + org.name
        const headers = ['Product Name', 'Requested Amount', 'Shipped Amount', 'Received Amount']
        const rows = org.lineItems?.map(
            ({productname, requestQuantity, confirmedQuantity, shipmentQuantity}) => 
            [productname,requestQuantity,shipmentQuantity,confirmedQuantity,]
        )

        downloadCSV(fileTitle, headers, rows)
    }
    // console.log(orgTreeQuery.data, communityEventState, );
    function viewNetworkReport() {
        setView(VIEWS.TREE)
    }
    function closeNetworkReport() {
        setView(VIEWS.DEFAULT)
    }
    function openConfirmArrivalDialog() {
        openDialogWithRef(shipmentDialogRef)
    }
    function openPopupDialog() {
        openDialogWithRef(popupDialogRef)
    }
    function toggleInlineEditing() {
        setInlineEditing(prev => !prev)
    }
    const handleBack = () => history.goBack(1)
    return (
        <MainContainer>
            <BasicBackButton onClick={handleBack} className={'absolute'} label={'Back To Events'}/>
            {event && communityEventState ? (
                <div className='w-full form-control items-center'>
                    <div className="w-full flex lg:flex-row flex-col-reverse mt-10 lg:mt-0 h-fit items-stretch ">

                        <div className="pt-10 mr-10 flex-5  form-control self-stretch justify-between">
                            <div>
                                <div className="w-fit text-3xl font-semibold">
                                    {event.name}
                                </div>
                                <EventDates className={'-mt-1'} event={event}/>
                                <EventDescription description={event.description}/>
                            </div>
                            <Buttons {...{updateRequest, state: communityEventState,  confirmArrival: openConfirmArrivalDialog, toggleInlineEditing, closeNetworkReport, view, handleDataExport, viewNetworkReport, openPopupDialog, step}}/>
                            
                            {/* <div className='flex lg:mt-24 mt-10 items-end h-12 gap-5'>
                                Made possible by
                                <OrgLogo id={event.orgid} customSize className='h-full w-auto'/>
                            </div> */}
                        </div>
                        <EventCarousel eventid={event.id} numImages={event.images}/>
                    </div>

                    <div className="bg-base-200 form-control items-center border-gray-border rounded-box p-4 mt-10 w-full">
                        { view === VIEWS.DEFAULT && (
                            <>
                                <p className='text-lg font-semibold'>
                                    <span className='font-normal'>Request Goods for </span>{ctx?.orgName}
                                </p>
                                <RequestStepper {...{step, errors}}/>

                                <div className="w-full grid mt-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8">
                                    {communityEventState.lineItems?.map?.((lineItem) => (
                                        <ProductCard key={lineItem.inventoryid} {...{lineItem, brandorgid: event.orgid, eventid, step, handleChange, values: values[lineItem.inventoryid]  }}/>
                                    ))}
                                </div>
                            </>
                        )}
                        { view === VIEWS.TREE && (
                            <div className="p-4 w-full">
                                <OrgTreeBDShipmentReport 
                                    {...{brandorgid, step, eventid, inlineEditing, orgTree: orgTreeQuery.data}}     
                                />
                            </div>
                        )}

                    </div>
                    <ConfirmShipmentDialog brandorgid={brandorgid} dialogRef={shipmentDialogRef} communityState={communityEventState} />
                    <ManagePopupDialog dialogRef={popupDialogRef} {...{orgid, eventid}}/>  
                </div>

            ) : (
                <BasicPulseLoader/>
            )}
        </MainContainer>
    );
};


const EventDates = ({className, event}) => (
    <div className={`flex gap-4 text-xs text-gray-darkSubText flex-nowrap w-fit my-2 ${className}`}>
        <p>{getNiceDateString(event.starttime)}</p>
        <p>–</p>
        <p>{getNiceDateString(event.endtime)}</p>
    </div>
)

const EventDescription = ({description}) => (
    <div className='flex relative mt-5'>
        <div className="absolute h-full divider divider-horizontal"></div>
        <p className='text-sm ml-10 text-gray-darkSubText font-medium w-full'>{description}</p>
    </div>
)

const RequestStepper = ({step, errors}) => (
    <div className="steps step  w-full max-w-[40rem] [&]">
        <RequestStep currStep={step} errors={errors} step={STEPS.REQUESTED}>Requested</RequestStep>
        <RequestStep currStep={step} errors={errors} step={STEPS.AWAITING_CONFIRMATION}>Shipped</RequestStep>
        <RequestStep currStep={step} errors={errors} step={STEPS.RECEIVED}>Received</RequestStep>
        <RequestStep currStep={step} errors={errors} step={STEPS.DISTRIBUTED}>Popup Store</RequestStep>
    </div>
)

function getStepColor(curr, step) {
    return curr >= step ? 'step-success' : curr === step - 0.5 ? 'step-info' : ''
}

function inferEventProgress(eventState, popup) {
    if (!!popup) return STEPS.DISTRIBUTED
    if (eventState.hasConfirmed) return STEPS.AWAITING_STORE
    if (eventState.hasShipment) return STEPS.AWAITING_CONFIRMATION
    if (eventState.hasRequest) return STEPS.AWAITING_SHIPMENT
    return STEPS.AWAITING_REQUEST
}

function inferEventErrors(eventState) {
    if (eventState.hasConfirmed && !eventState.hasAllConfirmed) return [ERRORS.PARTIALLY_RECEIVED]
    return []
}

const RequestStep = ({currStep, step, errors=[], children}) => {
    const hasError = errors.includes(step)

    let className = 'step data-[content]:after:text-white text-sm lg:text-base '
    if (hasError) {className += 'step-warning step-info data-[content]:after:content-["!"] data-[content]:after:text-xl '}
    else {
        if (currStep >= step) className += 'step-info step-info data-[content]:after:content-["✓"] data-[content]:after:font-sans '
        else if (currStep === step - 0.5) className += 'step-info data-[content]:after:content-["●"] data-[content]:after:font-mono '
    }    
    return <div data-content={''} className={className}>{children}</div>
}
const Buttons = ({step, state, view, updateRequest, toggleInlineEditing, confirmArrival, handleDataExport, viewNetworkReport, closeNetworkReport, openPopupDialog}) => (
    <div className="flex flex-wrap gap-2 w-full sm:items-end items-start justify-end  sm:flex-row flex-col pt-5">
        { view === VIEWS.DEFAULT && (
            <button className="btn btn-primary btn-little" onClick={viewNetworkReport}>
                Aggregate View
                <TreeIcon />
            </button>
        )}

        {   view === VIEWS.TREE && (
            <>
                <button className="btn btn-primary btn-little" onClick={closeNetworkReport}>
                    View Community Request 
                    <RequestIcon/>
                </button>
                <button className="btn btn-primary btn-little" onClick={toggleInlineEditing}>
                    Toggle Inline Editing
                    <EditIcon/>
                </button>
            </>
        )}


        {   step >= STEPS.REQUESTED && (
            <BasicRectButton customSize className={'btn-neutral  btn-little '} onClick={handleDataExport}
                disabled={step < STEPS.REQUESTED}>
                Export to Excel
                <ExcelIcon className='fill-green-600 w-6 h-6'/>
            </BasicRectButton>

        )}


        { step <= STEPS.SHIPPED && (
            <BasicRectButton customSize className={'bg-blue-cornflower text-white btn-little '} onClick={updateRequest}
            >
                Update Request
                <RequestIcon />
            </BasicRectButton>
        )}

        { view === VIEWS.DEFAULT && (
            <>
                { step >= STEPS.AWAITING_CONFIRMATION && step < STEPS.DISTRIBUTED && (
                    <BasicRectButton customSize className={'bg-green-600 text-white btn-little '} onClick={confirmArrival}
                        disabled={step <= STEPS.SHIPPED}>
                        {state?.hasConfirmed ? 'Re-confirm Shipment' : 'Confirm Shipment'}
                        <ConfirmDeliveryIcon className='w-5 h-5'/>
                    </BasicRectButton>
                )}
                { step === STEPS.AWAITING_STORE && (
                    <BasicRectButton customSize className={'bg-green-600 text-white btn-little '} onClick={openPopupDialog}
                        disabled={step !== STEPS.AWAITING_STORE}>
                        Create Popup Store
                        <StoreIcon className='stroke-white w-5 h-5'/>
                    </BasicRectButton>
                )}

            </>

        )}

    </div>
)

const ProductCard = ({brandorgid, eventid, lineItem, values, handleChange, step })  => {
    const { productname, description, inventoryid, shipmentQuantity, inventoryQuantity, images} = lineItem || {}
    const {requested=0, confirmed=0} = values || {}
    function onChange(e){
        let newVal = Number(e)
        if (newVal > inventoryQuantity) newVal = inventoryQuantity
        if (newVal < 0) newVal = 0
        handleChange(inventoryid, 'requested', newVal) 
    }

    return (
    <div className="rounded-none card card-compact w-full" >
        <ProductCarousel 
            eventid={eventid}
            inventoryid={inventoryid}
            numImages={4}
        />
      <div className="card-body p-0 pt-2" >
        <h2 className="card-title font-semibold text-xl" >
          {productname}
        </h2>
        <BasicTooltip tooltipHex={'#FFFFFF'} tooltipClassName='tooltip-primary' text={'hi'}  label={description} >
            <div className="h-9 form-control justify-end -mb-2 -mt-1">
                <span className=" h-fit-content w-fit font-[0.9rem] line-clamp-2  overflow-ellipsis ">{description || 'Plain'}</span>
            </div>
        </BasicTooltip>
      <div className="divider  m-0 w-full" ></div>
        <div className="card-actions justify-between item flex-nowrap
        s-center" >
          <div className="form-control gap-4">
            <p className="text-[0.75] h-5">Inventory</p>
            <p className="text-[0.75] h-5">Requested</p>
            { shipmentQuantity ? (
                <div className="flex items-center gap-2">
                    <p className={`text-[0.75] h-5 text-blue-cornflower ${confirmed === shipmentQuantity ? 'Received' : 'Partially Received'}`}>Shipped</p>
                    { confirmed > 0 && (
                        <div className={`indicator-item indicator-end  indicator-middle badge whitespace-nowrap text-xs badge-sm -top-1  ${confirmed === shipmentQuantity ? 'badge-success' : 'badge-warning -right-10 '}`}>
                            {confirmed === shipmentQuantity ? 'Received' : 'Partially Received'}
                        </div>
                    )}
                </div>
            ):<></>}
          </div>
  
          <div className="form-control gap-4 items-center">
            <p className="text-lg h-5">{inventoryQuantity}</p>
            <div className="flex flex-row gap-2  place-items-end items-center h-5" > 
            {   step < STEPS.SHIPPED ? (
                <>
                    <button 
                        className=" animate-none " 
                        onClick={() => onChange(requested - 1)}>
                        <MinusIcon/>
                    </button>
                    <input 
                        className="h-8 text-base w-20  focus:border-black text-center border-black border-solid border-[0.75px] rounded-6"
                        value={requested}
                        type="tel"  onChange={(e) => onChange(e.target.value)}       
                    />
                    <button className=" align-middle btn-[black] animate-none " 
                        onClick={() => onChange(requested + 1)}>
                        <PlusIcon />
                    </button>
                </>
                ) : (
                    <p className="text-lg h-5">{requested}</p>
                )}
            </div>
               { 
                    <p className="text-blue-cornflower text-lg h-5">{shipmentQuantity > 0 ? shipmentQuantity : ' '}</p>
               } 
          </div>
  
          {/* <div className="badge badge-outline">Fashion</div> 
          <div className="badge badge-outline bage-[#eee]">Products</div> */}
        </div>
      </div>
  </div>
    )
  }