
import { createBrandPref } from '@/apis/base/admin/uic/createBrandPref';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';


export default function useCreateBrandPref(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.CREATE_BRAND_PREF],
        mutationFn: createBrandPref,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data) => {
            client.invalidateQueries({queryKey: [ROUTE_IDS.GET_BRANDS]})
            client.invalidateQueries({queryKey: [ROUTE_IDS.GET_BRAND_PREF_REGIONAL_REPORT]})
            onSuccess && onSuccess(data)
        },
        ...otherProps
    });
    return mutation;
}