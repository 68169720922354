import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { SearchIcon, XIcon } from "../Icons";
import { useDebounce } from "@/hooks/utils/useDebounce";
import { blurActive } from "@/utils/misc";
import { checkForgotPassword } from "@/apis/base/auth";

  function defaultSearchFilter(option,searchValue,labelFn) {
        return (
            (labelFn(option)?.length > 0) && 
            (labelFn(option).toLowerCase().indexOf(searchValue?.toLowerCase()) >= 0)
        )
    }

export const BasicSearchBar = ({ 
    value: initialValue, 
    labelFn = (op) => op?.label,
    displayFn=labelFn,
    idFn=(op) => op?.value,
    initialResults=false,
    disabled=false, 
    onChange = ()=>{}, 
    placeholder='Search', 
    className, 
    dropdownClassName,
    id='searchBar',
    inputClassName, 
    iconClassName, 
    liveChanges=true,
    onFilter: emitSearch=()=>{},
    searchFilterFn=defaultSearchFilter,
    debounce = 500, 
    options,
    optionsLabel=''
}) => {
    const inputRef = useRef()
    const [value, setValue] = useState(initialValue || '')
    const [searchPressed, setSearchPressed] = useState(false)
    const [searchValue, setSearchValue] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState(options); 
    const hasClicked = useRef(false);
    const dropdownRef = useRef(false);

    useEffect(() => {
        setValue(initialValue || "")
    },[initialValue])
    
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (hasClicked.current === true) {
    //             onChange?.(value)
    //             hasClicked.current = false
    //             return
    //         }
    //         onChange?.(value)
    //     }
    //     , debounce)
    //     return () => clearTimeout(timeout)
    // }, [value])

    // useDebounce(
    //     () => {
    //         if (hasClicked.current === true) {
    //             onChange?.(value)
    //             hasClicked.current = false
    //             return
    //         }
    //     },
    //     [value, searchValue],debounce)

    useLayoutEffect(() => {},[])
    useDebounce(() => {
        if (liveChanges && !!searchValue) console.log('actually emitting search')
        liveChanges && !!searchValue && emitSearch?.(filteredOptions)
    }, [filteredOptions], debounce)

    useDebounce(() => setOptions(), [searchValue], 200)

    useEffect(() => {
        if (!initialResults || options?.length === 0) return
        setOptions()
    },[initialResults, options]) 

    useLayoutEffect(() => {
        if (!options) return
        setOptions()
        setFilteredOptions(options)
    },[options])

    const setOptions = () => {
        if (!searchValue) return
        const newFilteredOptions = getOptions()
        setFilteredOptions(newFilteredOptions)
    };

    const getOptions = () => {
        if (!options) return []
        if (searchValue === '' || searchValue == null) return options
        return  options.filter(  
          (option) => searchValue === '' || searchValue == null || searchFilterFn(option, searchValue, labelFn)
        );
    }

    function onOptionClick(e,option) {
        if (disabled) return
        if (!liveChanges) onChange(option) 
        !liveChanges && emitSearch?.([option])
        setSearchPressed(true)
        setSearchValue(labelFn(option))
        hasClicked.current = true
        blurActive()
    }

    function onClearClick() {
        console.log('here');
        setSearchValue('')
        setFilteredOptions(options)
        emitSearch(options)
        onChange?.('')
    }

    function onTextInput(e) {
        setSearchValue(e.target.value)
        if (liveChanges) onChange?.(e.target.value)
        
    }

    function handleDropdownLeave(e) {
        // console.log('leaving');
        blurActive()
    }
    // console.log(filteredOptions?.length);
    // console.log(filteredOptions);
    return (
    <div className={`indicator @container ${className} shadow-none`} onMouseLeave={handleDropdownLeave}>
        <div  className="dropdown w-full focus-within:focus  max-w-full focus-within:z-[12] hover:cursor-text h-full"
            ref={dropdownRef}
        >
            <label  
                tabIndex={0}
                className={`text-sm px-2 py-1 @[14rem]:px-3 @[14rem]:py-1.5 @[18rem]:px-4 @[18rem]:py-2 focus-within:border-primary rounded-2xl  whitespace-nowrap  border border-gray-border flex justify-end items-center relative bg-base-100 z-[14] h-full w-full
                ${disabled ? 'opacity-40' : ''}
                `}
                >
                <input
                    ref={inputRef}
                    autoComplete="off"
                    className={`hover:first:placeholder:font-medium h-fit pl-1 placeholder-gray-300 placeholder:select-none text-xs left-5 focus:outline-none w-full bg-transparent after:invisible placeholder:max-w-11 @[12rem]:placeholder:max-w-none
                    ${inputClassName}`}
                    onKeyDown={(e) => {
                        if (e.key === 'Escape') return blurActive()
                        if (e.key === 'Enter') {
                            blurActive()
                            if (liveChanges) return
                            emitSearch(filteredOptions)
                        }
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                    type="text"
                    inputMode="search"
                    id={id}
                    value={searchValue || ''}
                    onChange={onTextInput}
                />
                <SearchIcon {...{className: `@[] mr-1 ${iconClassName}`}}/>
            </label>
            { options?.length > 0 && (
                <div 
                    className={`dropdown-content left-1/2 -translate-x-1/2   bg-white w-full min-w-fit -mt-3 rounded-2xl z-[13] shadow-md ${dropdownClassName}`}
                    >
                    <div className="pb-2 pt-4 px-2 w-full" >
                        <p className="text-gray-subText text-xs">{optionsLabel}</p>
                        <ul className="menu form-control flex-nowrap w-full overflow-y-auto max-h-64" >
                            {filteredOptions?.map?.((option, idx) => (
                                    <li 
                                        onKeyDown={(e) => e.key === 'Enter' && onOptionClick(e,option)}
                                        key={`${idFn(option)}${value}`}  className="w-full" >
                                        <div 
                                            role='button' type="button" 
                                            className="flex !px-2 py-1 mb-1 hover:bg-[#e3e3e370] focus:bg-[#e3e3e370] w-full"
                                            onClick={(e) => {onOptionClick(e,option)}}
                                        >
                                            {displayFn(option)}
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            )}
        </div>
        { (searchValue != '' && searchValue != null) &&
            <div tabIndex={0} onClick={onClearClick} className="indicator-item z-[14]">
                <XIcon className='btn btn-ghost btn-circle btn-xs text-white bg-gray-darkSubText'/>
            </div>
        }
    </div>
)}