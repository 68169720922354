import { CardContent, Divider } from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';

import { BasicDialog } from '@/components/Dialog/BasicDialog';
import { BasicInput } from '@/components/Input/BasicInput';
import { FormBasicOrgDropdown } from '@/components/Input/BasicOrgDropdown';
import { BasicTextArea } from '@/components/Input/BasicTextArea';
import useSubmitTicket from '@/hooks/state/misc/useSubmitTicket';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useMyOrgsData from '@/hooks/state/organization/useMyOrgsData';
import useMyUserData from '@/hooks/state/useMyUserData';
import { useYupValidationResolver } from '@/hooks/utils/useYupValidationResolver';
import { closeDialogWithRef, openDialogWithRef } from '@/utils';
import { checkFormForAutofill } from '@/utils/misc';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { WhiteCard } from '../../../../../../components/Cards';
import { BlueOutlineButton } from '@/components/Buttons/BlueOutlineButton';

const validationSchema = Yup.object({
  orgid: Yup.string().transform(o => o.id ?? '').required('Please select an organization').default({}),
  subject: Yup.string().required('Please input the subject').default(''),
  content: Yup.string().required('Please input the content').default('')
})

function TicketDialog({adminOrgs, dialogRef,  text, onSubmit, onChange }) {
  const ctx = useCurrentOrgContext().data
  const [orgid, setOrgid] = useState(ctx?.orgid)
  const resolver = useYupValidationResolver(validationSchema);
  const {register, handleSubmit, formState, setValue, reset, control}
    = useForm({resolver, defaultValues: validationSchema.default(), mode: 'onBlur'});

  function onClose() {
    reset()
  }
  return adminOrgs?.length > 0 && (
    <BasicDialog 
      ref={dialogRef} 
      formID='ticketForm'
      confirmText='Send'
      handleSubmit={(e) => checkFormForAutofill(formState,handleSubmit(onSubmit),e)}
      onClose={onClose}
      title={'Create Ticket'} 
      className='h-fit'
    >
          <FormBasicOrgDropdown 
            control={control} 
            orgs={adminOrgs} 
            label='Ask on Behalf of which Organization?'
              {...register('orgid')}
            />
          {/* <OrgDropdown onChange={({key}) => onChange('orgidtest',key)} orgs={adminOrgs}/> */}
          <BasicInput 
            placeholder='Enter email subject' 
            label='Subject'
            error={formState.errors.subject}
            {...register('subject')}
            />
          <BasicTextArea 
            customSize 
            className='min-h-fit'
            style={{height:'56px'}} 
            error={formState.errors.content}
            placeholder='Enter email content' 
            label={text.content} 
            {...register('content')}
          />

    </BasicDialog>
  )
}

function Support({ text, myOrgsQuery, myUserDataQuery }) {
  const dialogRef = useRef();
  const [supportInfo, setSupportInfo] = useState({
    firstname: 'Lloyd',
    lastname: ' von Wersebe',
    email: 'lloyd@unitedinchange.com',
    phone_number: '+1 (416) 648-4081'
  });

  const myOrgs = myOrgsQuery?.data
  const {data: userData} = useMyUserData() || {}
  const ticketMutation = useSubmitTicket({
    onSuccess: (data) => {SuccessToast.fire({title: 'Ticket submitted successfully'})},
    onError: (error) => {FailPopup.fire({title: 'Ticket submission failed'})}
  })

const adminOrgs = useMemo(() => {
    return myOrgs?.filter?.(org => 
      org?.admins?.map(a => 
        Number(a.id))
      ?.includes?.(Number(userData?.id)))
}, [myOrgs,userData])

  const openTicketDialog = () => openDialogWithRef(dialogRef)

  const onSubmit = (params) => {
    ticketMutation.mutate(params)
    closeDialogWithRef(dialogRef)
  }

  return (
    <div className="lg:flex mb-12">
      <div className="flex-4">
        <div className="mt-0.5">
          <WhiteCard>
            <CardContent>
              <p className="mb-4 text-2xl font-semibold">{text.customerSupport}</p>
              <Divider />
              <p className="mt-7.5 leading-1.75">{text.customerSupportDescription}</p>
              <a href='https://unitedinchange.gitbook.io/uic-end-user-guide' target="_blank" rel="noreferrer">
                <span className="text-blue-cornflower">End user guide</span>
              </a>
              
              { adminOrgs?.length > 0  ? (
                <>
                <div className="mt-3">
                  <Divider/>
                </div>
                  <>
                    <p className="mt-2 leading-1.75">{text.findContactDescription}</p>
                    <div className="xl:flex mt-2">
                      <div className="flex-7">
                        <div className="xs:flex items-center my-4">
                          <div className="flex-2">
                            <p className="font-light">{text.name}</p>
                          </div>
                          <div className="flex-5">
                            <p className="font-medium">{supportInfo.firstname} {supportInfo.lastname}</p>
                          </div>
                        </div>
                        <div className="xs:flex items-center my-4">
                          <div className="flex-2">
                            <p className="font-light">{text.email}</p>
                          </div>
                          <div className="flex-5">
                            <p className="font-medium">{supportInfo.email}</p>
                          </div>
                        </div>
                        <div className="xs:flex items-center my-4">
                          <div className="flex-2">
                            <p className="font-light">{text.phone}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex-3" />
                    </div>
                  </>
                </>
              ) : (
                <img src="/assets/images/support.png" alt="empty" className="ml-0 object-contain rounded-md shadow-card w-1/2 mx-auto mt-5" />
              )}
              <div className="mt-5">
                <Divider/>
              </div>
              { adminOrgs?.length > 0 && (
                <>
                  <p className="my-2 leading-1.75">{text.logTicketDescription}</p>
                  <BlueOutlineButton onClick={openTicketDialog}>{text.logTicket}</BlueOutlineButton>
                </>
              )}
            </CardContent>
          </WhiteCard>
        </div>
      </div>
      <div className="flex-1 lg:block hidden mt-7 ml-12">
      </div>
      <TicketDialog 
        dialogRef={dialogRef}
        {...{text, dialogRef, adminOrgs, onSubmit}}
      />
    </div>
  );
}

export default Support;
