import { BasicGhostButton } from '@/components/Buttons/BasicGhostButton';
import { ApproveIcon, RejectIcon } from '@/components/Icons';
import React from 'react';


export const ApproveDeclineCell = ({onApprove, onDecline, ...props}) => {
    return (
        <div className='flex flex-row justify-center w-full '>
            <BasicGhostButton little circle onClick={onApprove}>
            <ApproveIcon/>
            </BasicGhostButton>
            <BasicGhostButton little circle onClick={onDecline}
            >
            <RejectIcon/>
            </BasicGhostButton>
        </div>
    );
};

