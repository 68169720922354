import { OrgTypeDropDown } from '@/components/Organization/OrgTypeDropDown';
import React from 'react';
import { BlackButton } from '../../components/Buttons';


export const OrgTypePicker = ({text, onSubmit, ...props}) => {
    const [value, setValue] = React.useState(null);
    return (
        <div className='overflow-auto relative h-[40rem]'>
            <div className="w-full">
              <p className="text-3xl font-semibold">{text.selectOrgType}</p>
            </div>
            <div className="mt-4 w-full">
              <p className="text-gray-400">{text.whichOrgType}</p>
            </div>
            <OrgTypeDropDown onChange={e => setValue(e.value)}/>
            <div className="mt-9 w-full">
              <BlackButton
                variant="contained"
                size="large"
                className="w-full"
                disabled={!value}
                onClick={() => onSubmit(value)}
              >
                {text.continue}
              </BlackButton>
            </div>
        </div>
    );
};

