import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addOrgSponsorship } from '@/apis/base/organizations/sponsorships/addOrgSponsorship';

export default function useAddOrgSponsorship(params) {
    const client = useQueryClient()
    const {onSuccess, ...otherparams} = params | {}
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.ADD_ORG_SPONSORSHIP],
        mutationFn: (params) => addOrgSponsorship(params),
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['sponsorship','recipient',req?.orgid]})
            client.invalidateQueries({queryKey: ['sponsorship','donor',req?.sponsororg]})
        }
    });
    return mutation;
}