import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createBDEvent } from '@/apis/base/donation/createBDEvent';


export default function useCreateBDEvent(props) {
    const {onSuccess, ...otherProps} = props
    const client = useQueryClient();
    const query = useMutation({
        mutationKey: [ROUTE_IDS.CREATE_BRAND_DONATION_EVENT],
        mutationFn: createBDEvent,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['bd']})
            onSuccess?.(data, req)
        },
        ...otherProps
    });
    return query;
}