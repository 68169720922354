import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQueries, useQuery } from '@tanstack/react-query';
import { getGeoDataConfig } from '../misc/useGeoData';
import { getBDEventByIDConfig } from './useBDEventByID';
import { recursiveFlattenSubnodes, recursiveNodeToArray } from '@/utils';
import { prefetchQueries } from '@/utils/queryUtils';


export default function useDonationLocations({eventid, orgid}) {
    const query = useQueries(donationLocationsComponentConfigs({eventid, orgid}));
    return query;
}

const donationLocationsComponentConfigs = ({eventid, orgid}) => ({ 
    queries: [
        getGeoDataConfig(),
        getBDEventByIDConfig({eventid, orgid})
    ], 
   combine
})

function combine(data) {
    const gData = data[0].data;
    const eData = data[1].data;

    let cities = []
    gData?.[0].subnodes.forEach(r => cities.push(...r.subnodes))
    let flatRegions = structuredClone(gData?.[0]?.subnodes ?? [])

    if (data.every(result => result.isSuccess)) {

        flatRegions.forEach(r => {
            const entry = eData?.locales?.find?.(l => l.regionid === r.id) || null
            r.cities = entry ? 
                r.subnodes.filter(c => entry?.cities?.includes?.(c.id)) 
                : []
        })
        flatRegions?.sort?.(regionCompare)

    }

    return ({
        isSuccess: data.every(result => result.isSuccess),
        isError: data.every(result => result.isError),
        dataUpdatedAt: data.reduce((acc, result) => Math.max(acc, result.dataUpdatedAt), 0),
        data: {flatRegions,cities}
    })
}   

export const prefetchDonationLocations = (client, eventid, orgid) => {
    prefetchQueries(client, [getGeoDataConfig(), getBDEventByIDConfig({eventid, orgid})],
    getBDEventByIDConfig({eventid, orgid}))
}

function regionCompare(a,b) {
    if (a.name < b.name)
        return -1
    if (a.name > b.name)
        return 1
    return 0
}