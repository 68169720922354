import { CrownIcon, ShieldIcon } from '@/components/Icons';
import { Delimit } from '@/components/Misc/Delimit';
import { VIPMemberTable } from '@/components/Tables/VIPMemberTable/VIPMemberTable';
import useOrgVIPs from '@/hooks/state/organization/useOrgVIPs';
import { isTrue } from '@/utils/misc';
import { VIPCard } from '@/views/pages/Main/Members/components/Cards/VIPCard';
import React, { useMemo } from 'react';

export const VIPMembers = ({ctx, ...props}) => {
    const VIPQuery = useOrgVIPs({orgid: ctx?.orgid})
    const [admins, champions] =  useMemo(() => {
        if (! VIPQuery.data || VIPQuery.data?.vips?.length < 1) return [[],[]]
        const a = []
        const c = []
        for (const vip of VIPQuery?.data?.vips || []) {
            if (isTrue(vip.owner) || isTrue(vip.admin)) a.push(vip)
            else c.push(vip)
        }
        return [a,c]
    },[VIPQuery.data])
    return (
        <div 
            className='flex flex-col md:flex-row gap-4'
        >
            <div className="flex-1 gap-4 rounded-xl bg-white p-6">
                <div className="flex items-center">
                    <span className="text-md md:text-lg mr-2 font-medium">Community Admins</span>
                    <CrownIcon className='w-6 h-6 text-yellow-400'/>
                </div>
                <div className="form-control gap-4 mt-4">
                    <Delimit
                        delimiter={<div className='w-full h-[1px] bg-slate-400 bg-opacity-20'></div>}
                    >
                        {admins?.map((vip) => (
                            <VIPCard key={vip?.id} vip={vip}/>
                        ))}
                    </Delimit>
                </div>
            </div>
            <div className="flex-1 rounded-xl bg-white p-6">
                <div className="flex items-center">
                    <span className="text-sm sm:text-md md:text-lg mr-2 font-medium">
                        Community Champions
                    </span>
                    <ShieldIcon className='w-6 h-6 text-green-500'/>
                </div>
                <div className="form-control gap-4 mt-4">
                    <Delimit
                        delimiter={
                            <div className='w-full h-[1px] bg-slate-400 bg-opacity-20'></div>
                        }
                    >
                        {champions?.map((vip) => (
                            <VIPCard key={vip?.id} vip={vip}/>
                        ))}
                    </Delimit>

                </div>
            </div>

        </div>
        // <VIPMemberTable
        //     VIPs={VIPQuery.data?.vips}
        // >
        // </VIPMemberTable>
    );
};