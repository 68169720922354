import { WISHLIST_VIEW_TYPE } from '@/constants';
import useWishlistTree from '@/hooks/state/wishlist/useWishlistTree';
import { WishlistProductCard } from '@/views/pages/Main/Wishlist/components/WishlistProductCard';
import React, { Fragment, useEffect } from 'react';
const MAX_QUANTITY = 999999

export const WishlistGrid = ({
    view=WISHLIST_VIEW_TYPE.MEMBER,
    reportProps,
    catFilter,
    demoFilter,
    wishlistObj,
    handleRemoveProduct, 
    handleEditProduct, 
    handleEditFinish,
    handleSubmitWishlist,
    maxQuantity=0,
    ...props}) => {
    const {geonodeFocus} = reportProps || {}
    const wishlistTree = useWishlistTree({})
    return wishlistObj && catFilter && demoFilter && wishlistTree.data && (
        catFilter?.sort?.().map((catid) => {
            const targetCat = wishlistTree.data.find(cat => cat.id === catid)
            const hasDemoProducts = targetCat?.products?.some?.(p => demoFilter.includes(p.targetid)) 
            const hasSubCatProducts = targetCat?.subnodes
                ?.some?.(subcat => subcat.products
                ?.some?.(p => demoFilter.includes(p.targetid))) || false
            if (!hasDemoProducts && !hasSubCatProducts) return <div key={catid}></div>
            return (
                <div  key={catid} className="pb-16">
                    <p className="text-1.5xl font-medium mb-2 pb-2" >{targetCat.name}</p>
                    <div className="mt- flex flex-col gap-5">
                    { targetCat.products?.length > 0 ? (
                        <div 
                        className="mt-5 justify-center grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 justify-items-center
                        3xl:grid-cols-5  gap-7">
                        { 
                            targetCat.products.filter(p => demoFilter.includes(p.targetid))
                                .map((product, index) => {
                                    return (
                                        <WishlistProductCard 
                                            key={product.id} 
                                            view={view}
                                            geonodeFocus={geonodeFocus}
                                            showControls={view !== WISHLIST_VIEW_TYPE.UIC}
                                            showDelete={view === WISHLIST_VIEW_TYPE.UIC}
                                            showMod={view === WISHLIST_VIEW_TYPE.UIC}
                                            isAdmin={view === WISHLIST_VIEW_TYPE.ORG_ADMIN}
                                            handleMod={handleEditProduct}
                                            handleDelete={handleRemoveProduct}
                                            maxQuantity={maxQuantity} 
                                            product={product}
                                            {...wishlistObj?.[product.id]}
                                            />
                                    )
                                })
                        }
                        </div>
                    ) : targetCat.subnodes && targetCat.subnodes.length > 0 && targetCat.subnodes.map((subcat) => {
                        const productsForDemo = subcat.products 
                            ? subcat.products.filter(
                                    p => demoFilter.includes(p.targetid) &&
                                    catFilter.includes(p.categoryid)) 
                            : []
                        return productsForDemo.length > 0  &&  
                            <Fragment key={subcat.id}>
                                <p className="text-1.5xl font-light text-slate-600 pl-10">
                                    {subcat.name}
                                </p>
                                <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7 pb-5">
                                    { productsForDemo.map((product, index) => 
                                        <WishlistProductCard
                                            key={product.id} 
                                            view={view}
                                            geonodeFocus={geonodeFocus}
                                            showMod={view === WISHLIST_VIEW_TYPE.UIC}
                                            showDelete={view === WISHLIST_VIEW_TYPE.UIC}
                                            showControls={view !== WISHLIST_VIEW_TYPE.UIC}
                                            handleMod={handleEditProduct}
                                            handleDelete={handleRemoveProduct}
                                            maxQuantity={maxQuantity}
                                            product={product}
                                            {...wishlistObj?.[product.id]}

                                        />
                                    )}
                                </div>
                            </Fragment>
                    })
                    }   
            </div>
            </div>
        )
    }));
}