import { forwardRef, useEffect, useRef, useState } from 'react';

import { uploadEventInventoryImages } from '@/apis/base/lambda/uploadImages';
import { BasicDialog } from '@/components/Dialog/BasicDialog';
import { BasicDropdownInput } from '@/components/Input/BasicDropdownInput';
import { BasicFileInput } from '@/components/Input/BasicFileInput';
import { BasicInput } from '@/components/Input/BasicInput';
import { FormBasicNumberInput } from '@/components/Input/BasicNumberInput';
import { BasicTextArea } from '@/components/Input/BasicTextArea';
import { BasicToggle } from '@/components/Input/BasicToggle';
import { ImageUploadAuditor } from '@/components/Misc/ImageUploadAuditor';
import useModBDInventory from '@/hooks/state/donation/useModBDInventory';
import useSubmitBDInventory from '@/hooks/state/donation/useSubmitBDInventory';
import { useRHF } from '@/hooks/utils/useRHF';
import { useYupValidationResolver } from '@/hooks/utils/useYupValidationResolver';
import { closeDialogWithRef, filesToDataUri } from '@/utils';
import { checkFormForAutofill } from '@/utils/misc';
import Yup from '@/wrappers/Yup/Yup';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useQueryClient } from '@tanstack/react-query';

const validationSchema = Yup.object({
    name: Yup.string().required('Brand is required'),
    productname: Yup.string().required('Product name is required'),
    description: Yup.string().required('Description is required'),
    categoryid: Yup.string().transform((cat) => cat.id).required('Please select the category').default(''),
    targetid: Yup.string().transform((target) => target.id).required('Please select the demographic').default(''),
    size: Yup.string().required('Size is required'),
    quantity: Yup.number().defaultRequiredEmptyMin('Quantity').NaNCheck().required('Quantity is required'),
    price: Yup.number().default(0)
        .when('$perItemReport', ([perItemReport],schema) => 
            perItemReport 
                ? schema.required('Price is required').priceStrToNum() 
                : schema),
    weight: Yup.number().default(0)
        .when('$perItemReport', ([perItemReport],schema) => 
            perItemReport 
                ? schema.required('Weight is required').priceStrToNum() 
                : schema),
    active: Yup.boolean().required('Active is required').default(false),
})

const InventoryDialog = forwardRef(function InventoryDialog({ 
    demoData,
    categoryData,
    onClose, 
    hostOrgData, 
    isEdit, 
    inventory, 
    eventid, 
    perItemReport }, 
    ref)  {
    const imageRef = useRef();
    const [loading,setLoading] = useState(false)
    const client = useQueryClient()
    const [images, setImages] = useState([]);
    const submitMutation = useSubmitBDInventory({
        onSuccess: (data) => {
            SuccessToast.fire({text: 'Inventory has been successfully added'})
            uploadEventInventoryImages({client, orgID: hostOrgData?.id, eventID: eventid, inventoryID: data?.inventoryid, uploads: Array.from(imageRef.current.files)})
        },
        onError: (error) => {FailPopup.fire({text: 'Failed to add inventory'})},
        onSettled: onMutationSettled
    });
    const modMutation = useModBDInventory({
        eventid: eventid,
        onSuccess: (data, req) => {
            SuccessToast.fire({text: 'Inventory modified successfully'})
            if (imageRef.current.files.length > 0) {
                uploadEventInventoryImages({client, orgID: hostOrgData?.id, eventID: eventid, inventoryID: req.inventoryid, uploads: Array.from(imageRef.current.files)})
            }
        },
        onError: (error) => {FailPopup.fire({text: 'Failed to modify inventory'})},
        onSettled: onMutationSettled
    });
    const resolver = useYupValidationResolver(validationSchema,{isEdit,perItemReport});
    const {register, handleSubmit, formState, setValue, reset, control} = 
        useRHF({resolver, defaultValues: validationSchema.default(),})
    
    useEffect(() => {
        if (!inventory || !isEdit || !demoData || !categoryData) return
        loadExistingProduct()
    },[inventory, isEdit, demoData, categoryData])

    useEffect(() => {
        setValue('name', hostOrgData?.name, {shouldTouch: true})
    },[hostOrgData])

    function onMutationSettled() {
        onBeforeDialogClose()
        closeDialogWithRef(ref)
    }
    const onFormValidated = (baseParams) => {
        const numImg = imageRef.current.files.length
        const optionalParams =  isEdit ? 
                {
                    inventoryid: inventory?.id,
                    images: numImg > 0 ? numImg : inventory?.images
                } : {
                    eventid: eventid,
                    images: numImg
                }
        if (isEdit) 
            modMutation.mutate({
                ...baseParams,
                ...optionalParams,
            })
        else
            submitMutation.mutate({
                ...baseParams,
                ...optionalParams,
            })
        setLoading(true)
    }
    function onBeforeDialogClose() {
        imageRef.current.value = ''
        setImages([])
        reset(validationSchema.default())
        setLoading(false)
        onClose()
        setValue('categoryid','')
    }
    function loadExistingProduct() {

        reset({
            ...inventory,
            active: inventory.active === '1',
            categoryid: categoryData.flat.find(c => c.id === inventory.categoryid),
            targetid: demoData.find(d => d.id === inventory.targetid),
            }, {keepDirtyValues: true})
        setImages([])
    }

	const handleFileUpload = (e) => {
		let files = Array.from(imageRef?.current?.files);
        if (files.length > 6)  {
			files = files.slice(0,6);
		}	
		filesToDataUri(files).then( data =>
			setImages(data)
		)
	}

    const handleRemoveAttach = (id) => {
		const dt = new DataTransfer()
		const refFiles = imageRef.current.files
		// Update file URI's
		let list = images;
		list.splice(id, 1);
		if (imageRef?.current?.value && !list.length) {
			imageRef.current.value = null;
		}
		// Update input ref
		for (let i = 0; i < refFiles?.length; i++) {
			if (id != i)
				dt.items.add(refFiles[i])
		}
		let updatedImages = [...list];
		setImages(updatedImages)
		imageRef.current.files = dt.files;
		// updatedImages.splice(id, 1);
	};
    return (
        <BasicDialog 
            confirmText={isEdit ? 'Update' : 'Add'}
            onClose={onBeforeDialogClose}
            confirmLoading={loading}
            ref={ref}
            formID='inventoryform'
            handleSubmit={(e) => checkFormForAutofill(formState, handleSubmit(onFormValidated),e)}
            title={isEdit ? 'Edit Donation Inventory' : 'Add Donation Inventory'}
        >
            <BasicInput label='Brand' {...register('name')} />
            <BasicInput label='Product Name' {...register('productname')} required />
            <BasicTextArea label='Description' 
                {...register('description')} className='h-20' />
            <BasicDropdownInput
                hasSubOptions
                control={control}
                name='categoryid'
                required
                isSearchable
                renderFn={(option) => option?.name}
                label='Product Category'
                placeHolder={"Pick a Product Category"}
                data={categoryData?.tree}
            />
            <BasicDropdownInput
                control={control}   
                required
                isSearchable
                label='Demographic Target'
                renderFn={(option) => option?.name}
                placeHolder={"Pick a Demographic Target"}
                data={demoData}
                name='targetid'
            />
            <BasicInput label='Size Range' {...register('size')} required />
            <BasicInput type={'number'} label='Quantity' {...register('quantity')} required />
            <FormBasicNumberInput required={perItemReport} name='price' control={control} label='Price (CAD)' disabled={!perItemReport}/>
            <FormBasicNumberInput required={perItemReport} name='weight' control={control} label='Weight (lbs)' disabled={!perItemReport}/>
            <div className="ml-1.5">
                <BasicToggle  defaultChecked={inventory?.active === '1'} label='Active' {...register('active')} />
            </div>
            <ImageUploadAuditor
                className='flex gap-2 mt-4'
                images={images}
                onDelete={handleRemoveAttach}
            />
            <BasicFileInput
                className='file-input-secondary'
                ref={imageRef}
                onChange={handleFileUpload}
                label='Images'
                required={!isEdit}
            />
        </BasicDialog>
    );
});

export default InventoryDialog;
