import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS,composeFormData } from "@/apis/base/routeId"

export const getOrgPopups = (params) => 
    new Promise((resolve,reject) => {
        postPromise(config.API_BASE_URL,composeFormData(params, ROUTE_IDS.GET_ORG_POPUPS)).then((res) => {
            if (!Array.isArray(res)) reject(res)
            else resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
