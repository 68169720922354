import React from 'react';
import { BackArrow } from '../Icons';


export const BasicBackButton = ({className, label, ...props}) => {
    return (
        <button type='button' className={` text-gray-subText hover:text-black transition-colors btn btn-ghost bg-transparent btn-xs ${className} [--btn-focus-scale:0.98]`} {...props}>
            <BackArrow className='w-4 h-4' />
            <p className='text-xs'>{label}</p>
            {props.children}
        </button>
    );
};

