import { withStyles, makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";
import {
  ErrorOutline,
  LocalOfferOutlined,
  Close,
  FileCopy,
} from "@material-ui/icons";

const TableIcon = withStyles({
  root: {
    width: "20px",
    height: "20px",
  },
})(SvgIcon);

const RemoveAttachIcon = withStyles({
  root: {
    color: "black",
    width: "15px",
    height: "15px",
  },
})(Close);

const FileCopyIcon = withStyles({
  root: {
    color: "black",
    width: "15px",
    height: "15px",
  },
})(FileCopy);

const sortIconStyles = makeStyles(() => ({
  root: {
    height: "15px",
    width: "15px",
  },
}));
const SortIcon = (props) => {
  const classes = sortIconStyles();
  const { active, ascending } = props;
  return (
    <SvgIcon viewBox="0 0 512 512" className={classes.root}>
      <rect width="512" height="42" fill={active ? "#3B82F6" : "gray"} />
      <rect y="80" width="416" height="42" fill={active ? "#3B82F6" : "gray"} />
      <rect
        y="160"
        width="320"
        height="42"
        fill={active ? "#3B82F6" : "gray"}
      />
      <rect
        y="240"
        width="224"
        height="42"
        fill={active ? "#3B82F6" : "gray"}
      />
      <rect
        y="320"
        width="160"
        height="42"
        fill={active ? "#3B82F6" : "gray"}
      />
      <rect
        y="400"
        width="128"
        height="42"
        fill={active ? "#3B82F6" : "gray"}
      />
      <rect y="480" width="64" height="42" fill={active ? "#3B82F6" : "gray"} />
      {ascending ? (
        <path
          d="M427.312,164.688c-6.256-6.256-16.368-6.256-22.624,0l-48,48l22.624,22.624L400,214.624V464h32V214.624l20.688,20.688
			l22.624-22.624L427.312,164.688z"
          fill={active ? "#3B82F6" : "gray"}
        />
      ) : (
        <path
          d="M452.688,388.688L432,409.376V160h-32v249.04l-20.048-20.288l-22.768,22.496l47.44,48c3.008,3.04,6.592,4.96,11.376,4.752
			c4.24,0,8.32-1.68,11.312-4.688l48-48L452.688,388.688z"
          fill={active ? "#3B82F6" : "gray"}
        />
      )}
    </SvgIcon>
  );
};

const copyIconStyles = makeStyles(() => ({
  root: {
    height: "20px",
    width: "20px",
  },
}));
const CopyIcon = (props) => {
  const classes = copyIconStyles();
  return (
    <SvgIcon className={classes.root}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-copy"
      >
        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
      </svg>
    </SvgIcon>
  );
};

const ApproveIcon = () => (
  <TableIcon viewBox="0 0 21 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.91582 4.80188C7.21783 2.10852 11.0172 1.25011 14.2536 2.69214C14.758 2.91692 15.3492 2.69019 15.574 2.18571C15.7988 1.68124 15.572 1.09006 15.0676 0.865285C11.0221 -0.937245 6.27297 0.135768 3.39547 3.50244C0.517976 6.86911 0.197619 11.7274 2.60813 15.4428C5.01864 19.1581 9.5858 20.8455 13.8329 19.5898C18.0799 18.334 20.9952 14.4344 20.9977 10.0056V10.005V9.17696C20.9977 8.62468 20.55 8.17696 19.9977 8.17696C19.4454 8.17696 18.9977 8.62468 18.9977 9.17696V10.0044C18.9957 13.5475 16.6635 16.6672 13.2658 17.6718C9.86812 18.6764 6.21436 17.3265 4.28594 14.3542C2.35752 11.3819 2.6138 7.49523 4.91582 4.80188ZM20.7035 3.51064C21.0939 3.11992 21.0936 2.48676 20.7028 2.09643C20.3121 1.7061 19.679 1.70642 19.2886 2.09714L10.9954 10.3986L9.0028 8.406C8.61228 8.01547 7.97911 8.01547 7.58859 8.406C7.19807 8.79652 7.19807 9.42969 7.58859 9.82021L10.2887 12.5203C10.4763 12.7079 10.7307 12.8133 10.996 12.8132C11.2613 12.8131 11.5157 12.7076 11.7032 12.5199L20.7035 3.51064Z"
      fill="#1D1D1B"
      fillOpacity="0.3"
    />
  </TableIcon>
);

const WaitIcon = () => (
  <TableIcon viewBox="0 0 23 23" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09729 11.0959C2.09729 6.12489 6.1268 2.09521 11.0973 2.09521C16.0678 2.09521 20.0973 6.12489 20.0973 11.0959C20.0973 16.0669 16.0678 20.0965 11.0973 20.0965C6.1268 20.0965 2.09729 16.0669 2.09729 11.0959ZM11.0973 0.0952148C5.02211 0.0952148 0.09729 5.02044 0.09729 11.0959C0.09729 17.1713 5.02211 22.0965 11.0973 22.0965C17.1725 22.0965 22.0973 17.1713 22.0973 11.0959C22.0973 5.02044 17.1725 0.0952148 11.0973 0.0952148ZM12.0973 5.09546C12.0973 4.54317 11.6496 4.09546 11.0973 4.09546C10.545 4.09546 10.0973 4.54317 10.0973 5.09546V11.0959C10.0973 11.4746 10.3113 11.8209 10.6501 11.9903L14.6501 13.9904C15.144 14.2374 15.7447 14.0372 15.9917 13.5432C16.2387 13.0493 16.0385 12.4486 15.5445 12.2016L12.0973 10.4778V5.09546Z"
      fill="#1D1D1B"
      fillOpacity="0.3"
    />
  </TableIcon>
);

const RejectIcon = () => (
  <TableIcon viewBox="0 0 23 23" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.098 2.09326C6.12746 2.09326 2.09802 6.1227 2.09802 11.0933C2.09802 16.0638 6.12746 20.0933 11.098 20.0933C16.0686 20.0933 20.098 16.0638 20.098 11.0933C20.098 6.1227 16.0686 2.09326 11.098 2.09326ZM0.0980225 11.0933C0.0980225 5.01813 5.02289 0.0932617 11.098 0.0932617C17.1732 0.0932617 22.098 5.01813 22.098 11.0933C22.098 17.1684 17.1732 22.0933 11.098 22.0933C5.02289 22.0933 0.0980225 17.1684 0.0980225 11.0933ZM14.8051 7.38616C15.1957 7.77668 15.1957 8.40984 14.8051 8.80037L12.5122 11.0933L14.8051 13.3862C15.1957 13.7767 15.1957 14.4098 14.8051 14.8004C14.4146 15.1909 13.7814 15.1909 13.3909 14.8004L11.098 12.5075L8.80513 14.8004C8.41461 15.1909 7.78144 15.1909 7.39092 14.8004C7.00039 14.4098 7.00039 13.7767 7.39092 13.3862L9.68381 11.0933L7.39092 8.80037C7.00039 8.40984 7.00039 7.77668 7.39092 7.38616C7.78144 6.99563 8.41461 6.99563 8.80513 7.38616L11.098 9.67905L13.3909 7.38616C13.7814 6.99563 14.4146 6.99563 14.8051 7.38616Z"
      fill="#1D1D1B"
      fillOpacity="0.3"
    />
  </TableIcon>
);

const MoreIcon = () => (
  <TableIcon viewBox="0 0 6 21" fill="none">
    <path
      d="M3.0911 8.40205C4.28158 8.40205 5.23396 9.37766 5.23396 10.5972C5.23396 11.8167 4.28158 12.7923 3.0911 12.7923C1.90062 12.7923 0.948242 11.8167 0.948242 10.5972C0.948242 9.37766 1.90062 8.40205 3.0911 8.40205ZM0.948242 2.79229C0.948242 4.0118 1.90062 4.98741 3.0911 4.98741C4.28158 4.98741 5.23396 4.0118 5.23396 2.79229C5.23396 1.57278 4.28158 0.597168 3.0911 0.597168C1.90062 0.597168 0.948242 1.57278 0.948242 2.79229ZM0.948242 18.402C0.948242 19.6216 1.90062 20.5972 3.0911 20.5972C4.28158 20.5972 5.23396 19.6216 5.23396 18.402C5.23396 17.1825 4.28158 16.2069 3.0911 16.2069C1.90062 16.2069 0.948242 17.1825 0.948242 18.402Z"
      fill="#1D1D1B"
      fillOpacity="0.3"
    />
  </TableIcon>
);

const CheckRoundedIcon = () => {
  const StyledIcon = withStyles({
    root: {
      width: "15px",
      height: "16px",
    },
  })(SvgIcon);

  return (
    <StyledIcon viewBox="0 0 15 16" fill="none">
      <path
        d="M6.97895 10.3526C7.24737 10.6211 7.73684 10.6211 8.00526 10.3526L10.8474 7.51053C11.1316 7.22632 11.1316 6.76842 10.8474 6.48421C10.5632 6.2 10.1053 6.2 9.82105 6.48421L7.5 8.80526L5.17895 6.48421C5.03684 6.34211 4.84737 6.26316 4.65789 6.26316C4.46842 6.26316 4.27895 6.34211 4.13684 6.48421C3.85263 6.76842 3.85263 7.22632 4.13684 7.51053L6.97895 10.3526Z"
        fill="#1D1D1B"
        fillOpacity="0.3"
      />
      <path
        d="M7.5 15.5C11.6368 15.5 15 12.1368 15 8C15 3.86316 11.6368 0.5 7.5 0.5C3.36316 0.5 0 3.86316 0 8C0 12.1368 3.36316 15.5 7.5 15.5ZM7.5 1.95263C10.8316 1.95263 13.5474 4.66842 13.5474 8C13.5474 11.3316 10.8316 14.0474 7.5 14.0474C4.16842 14.0474 1.45263 11.3316 1.45263 8C1.45263 4.66842 4.16842 1.95263 7.5 1.95263Z"
        fill="#1D1D1B"
        fillOpacity="0.3"
      />
    </StyledIcon>
  );
};

const TruckIcon = () => {
  const StyledIcon = withStyles({
    root: {
      width: "16px",
      height: "15px",
    },
  })(SvgIcon);

  return (
    <StyledIcon viewBox="0 0 16 15" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58344 1.5C1.16922 1.5 0.833435 1.83579 0.833435 2.25V9.83333C0.833435 10.2475 1.16922 10.5833 1.58344 10.5833H2.11605C2.0408 10.8057 2 11.0439 2 11.2917C2 12.5113 2.98871 13.5 4.20834 13.5C5.42797 13.5 6.41667 12.5113 6.41667 11.2917C6.41667 11.0439 6.37587 10.8057 6.30063 10.5833H9.69948C9.62424 10.8057 9.58344 11.0439 9.58344 11.2917C9.58344 12.5113 10.5721 13.5 11.7918 13.5C13.0114 13.5 14.0001 12.5113 14.0001 11.2917C14.0001 11.0439 13.9593 10.8057 13.8841 10.5833H14.4168C14.831 10.5833 15.1668 10.2476 15.1668 9.83335V6.91668C15.1668 6.71777 15.0878 6.527 14.9471 6.38635L13.1971 4.63635C13.0564 4.4957 12.8657 4.41668 12.6668 4.41668H11.0834V2.25C11.0834 1.83579 10.7476 1.5 10.3334 1.5H1.58344ZM11.7858 10.5833H11.7978C12.1862 10.5866 12.5001 10.9025 12.5001 11.2917C12.5001 11.6829 12.183 12 11.7918 12C11.4006 12 11.0834 11.6829 11.0834 11.2917C11.0834 10.9025 11.3973 10.5866 11.7858 10.5833ZM11.8023 9.08335H13.6668V7.22734L12.3561 5.91668H11.0834V9.08335H11.7812C11.7847 9.08333 11.7882 9.08332 11.7918 9.08332C11.7953 9.08332 11.7988 9.08333 11.8023 9.08335ZM4.20834 9.08332L4.21582 9.08333H9.58344L9.58344 5.16668L9.58344 3H2.33344V9.08333H4.20086L4.20834 9.08332ZM3.5 11.2917C3.5 10.9019 3.81485 10.5856 4.2041 10.5833H4.21257C4.60183 10.5856 4.91667 10.9019 4.91667 11.2917C4.91667 11.6829 4.59954 12 4.20834 12C3.81714 12 3.5 11.6829 3.5 11.2917Z"
        fill="#965E00"
      />
    </StyledIcon>
  );
};

const TimeScheduleIcon = () => {
  const StyledIcon = withStyles({
    root: {
      width: "14px",
      height: "15px",
    },
  })(SvgIcon);

  return (
    <StyledIcon viewBox="0 0 14 15" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 7.5C1.5 4.59729 3.85311 2.24419 6.75582 2.24419C9.65853 2.24419 12.0116 4.59729 12.0116 7.5C12.0116 10.4027 9.65853 12.7558 6.75582 12.7558C3.85311 12.7558 1.5 10.4027 1.5 7.5ZM6.75582 0.744186C3.02468 0.744186 0 3.76887 0 7.5C0 11.2311 3.02468 14.2558 6.75582 14.2558C10.487 14.2558 13.5116 11.2311 13.5116 7.5C13.5116 3.76887 10.487 0.744186 6.75582 0.744186ZM7.50586 3.89713C7.50586 3.48291 7.17007 3.14713 6.75586 3.14713C6.34165 3.14713 6.00586 3.48291 6.00586 3.89713V7.50061C6.00586 7.78469 6.16636 8.04439 6.42045 8.17143L8.82278 9.3726C9.19326 9.55784 9.64377 9.40767 9.82901 9.03719C10.0142 8.6667 9.86408 8.2162 9.4936 8.03096L7.50586 7.03709V3.89713Z"
        fill="#1D1D1B"
        fillOpacity="0.5"
      />
    </StyledIcon>
  );
};

// const SearchIcon = () => {
//   const StyledIcon = withStyles({
//     root: {
//       width: "18px",
//       height: "19px",
//     },
//   })(SvgIcon);

//   return (
//     <StyledIcon viewBox="0 0 18 19" fill="none">
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M1.8 8.61121C1.8 5.18091 4.58081 2.4001 8.01111 2.4001C11.4414 2.4001 14.2222 5.18091 14.2222 8.61121C14.2222 10.2835 13.5613 11.8014 12.4865 12.918C12.4552 12.942 12.4251 12.9683 12.3964 12.997C12.3678 13.0256 12.3415 13.0557 12.3175 13.087C11.2009 14.1616 9.68317 14.8223 8.01111 14.8223C4.58081 14.8223 1.8 12.0415 1.8 8.61121ZM13.0035 14.8769C11.6343 15.9693 9.89894 16.6223 8.01111 16.6223C3.5867 16.6223 0 13.0356 0 8.61121C0 4.18679 3.5867 0.600098 8.01111 0.600098C12.4355 0.600098 16.0222 4.18679 16.0222 8.61121C16.0222 10.4993 15.3691 12.2348 14.2764 13.6041L17.5359 16.8637C17.8873 17.2151 17.8873 17.785 17.5359 18.1365C17.1844 18.4879 16.6146 18.4879 16.2631 18.1365L13.0035 14.8769Z"
//         fill="#1D1D1B"
//         fillOpacity="0.3"
//       />
//     </StyledIcon>
//   );
// };

const MemberAddIcon = () => {
  const StyledIcon = withStyles({
    root: {
      width: "22px",
      height: "19px",
    },
  })(SvgIcon);

  return (
    <StyledIcon viewBox="0 0 22 19" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08166 5.05565C5.08166 3.60799 6.2876 2.4001 7.81803 2.4001C9.34845 2.4001 10.5544 3.60799 10.5544 5.05565C10.5544 6.50332 9.34845 7.71121 7.81803 7.71121C6.2876 7.71121 5.08166 6.50332 5.08166 5.05565ZM7.81803 0.600098C5.33184 0.600098 3.28166 2.57596 3.28166 5.05565C3.28166 7.53535 5.33184 9.51121 7.81803 9.51121C10.3042 9.51121 12.3544 7.53535 12.3544 5.05565C12.3544 2.57596 10.3042 0.600098 7.81803 0.600098ZM4.63638 11.2667C2.15019 11.2667 0.100021 13.2426 0.100021 15.7223V17.5001C0.100021 17.9971 0.502965 18.4001 1.00002 18.4001C1.49708 18.4001 1.90002 17.9971 1.90002 17.5001V15.7223C1.90002 14.2746 3.10596 13.0667 4.63638 13.0667H11C12.5304 13.0667 13.7364 14.2746 13.7364 15.7223V17.5001C13.7364 17.9971 14.1393 18.4001 14.6364 18.4001C15.1334 18.4001 15.5364 17.9971 15.5364 17.5001V15.7223C15.5364 13.2426 13.4862 11.2667 11 11.2667H4.63638ZM18.2729 5.04456C18.77 5.04456 19.1729 5.4475 19.1729 5.94456V7.71117H21C21.497 7.71117 21.9 8.11411 21.9 8.61117C21.9 9.10822 21.497 9.51117 21 9.51117H19.1729V11.2779C19.1729 11.7749 18.77 12.1779 18.2729 12.1779C17.7759 12.1779 17.3729 11.7749 17.3729 11.2779V9.51117H15.5454C15.0484 9.51117 14.6454 9.10822 14.6454 8.61117C14.6454 8.11411 15.0484 7.71117 15.5454 7.71117H17.3729V5.94456C17.3729 5.4475 17.7759 5.04456 18.2729 5.04456Z"
        fill="white"
      />
    </StyledIcon>
  );
};

const FilterIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
)

const ChevronDown = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" ><polyline points="6 9 12 15 18 9"></polyline></svg>
)

const SearchIcon= (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2 " strokeLinecap="round" strokeLinejoin="round" ><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
)

const CheckIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polyline points="20 6 9 17 4 12"></polyline></svg>
)

const HamburgerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
)

const DeleteIcon = (props) =>(
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
)

const RemoveUserIcon = ({className = "",pathProps, ...props}) => (
  <svg 
    className={className?.length > 0 ? className : "w-6 h-6"}
     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
    <path  stroke="currentColor" {...pathProps} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 8h6m-9-3.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM5 11h3a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"/>
  </svg>
)

const PlusIcon = ({className = "",lineProps, ...props}) => (
  <svg {...props} className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" >
    <line {...lineProps} x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
)

const XIcon = ({...props}) => (
  <span {...props}>✕</span>
)

const MinusIcon = ({lineProps, ...props}) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line {...lineProps} x1="5" y1="12" x2="19" y2="12"></line></svg>
)

const BackArrow = ({...props}) => (
  <svg {...props} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M13 5H1m0 0 4 4M1 5l4-4"/>
  </svg>
)

const BackArrowShort = ({...props}) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
)

const ForwardArrowShort = ({...props}) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
)

const EllipsesIcon = (props) => {
  <svg {...props}  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
    <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
  </svg>
}

const BellIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
)

const FilterIconOutline = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M32 144h448M112 256h288M208 368h96"/></svg>
)

const UsersIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round" ><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
)

const ChevronRight = (props) => (
  <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...props}><polyline points="9 18 15 12 9 6"></polyline></svg>
)

const ChevronLeft = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
)

const TreeIcon = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ><path  fill="currentColor" d="M10 2a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8v2h5V9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-1H8v6h5v-1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-1H7a1 1 0 0 1-1-1V8H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm9 16h-4v2h4zm0-8h-4v2h4zM9 4H5v2h4z"/></svg>
)

const RequestIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
)

// <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
const ExcelIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
  <path opacity="1" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z"/></svg>
)

const ConfirmDeliveryIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 264l-89.6 112-38.4-44.88"/><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 00-16-16zM160 176v-32a96 96 0 0196-96h0a96 96 0 0196 96v32"/></svg>
)

const StoreIcon = (props) => (
<svg {...props} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M3.308 9a2.257 2.257 0 0 0 2.25-2.264 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 1 0 4.5 0C19.058 5.471 16.956 1 16.956 1H3.045S1.058 5.654 1.058 6.736A2.373 2.373 0 0 0 3.308 9Zm0 0a2.243 2.243 0 0 0 1.866-1h.767a2.242 2.242 0 0 0 3.733 0h.767a2.242 2.242 0 0 0 3.733 0h.767a2.247 2.247 0 0 0 1.867 1A2.22 2.22 0 0 0 18 8.649V19H9v-7H5v7H2V8.524c.37.301.83.469 1.308.476ZM12 12h3v3h-3v-3Z"/>
  </svg>
)

const InfoIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" ><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
)

const ErrorIcon = withStyles({
  root: {
    width: "16px",
    height: "16px",
  },
})(ErrorOutline);

const LocalOfferOutlinedIcon = withStyles({
  root: {
    width: "16px",
    height: "16px",
  },
})(LocalOfferOutlined);

const ChartIcon = (props) => (
  <svg {...props} className='w-5 h-5' aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M1 1v14h16m0-9-3-2-3 5-3-2-3 4"/>
  </svg>
)

const CheckBoxIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
)

const LinkIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
)

const AscendingIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
)

const DescendingIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <line x1="18" y1="20" x2="18" y2="16"></line>
  <line x1="6" y1="20" x2="6" y2="4"></line>
  <line x1="12" y1="20" x2="12" y2="10"></line>
  </svg>
)

const EyeIcon = ({className, ...props}) => (
  <svg {...props} className={className} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path stroke="currentColor" strokeWidth="2" d="M21 12c0 1.2-4 6-9 6s-9-4.8-9-6c0-1.2 4-6 9-6s9 4.8 9 6Z"/>
    <path stroke="currentColor" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
  </svg>
)

const ClosedEyeIcon = ({...props}) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M432 448a15.92 15.92 0 01-11.31-4.69l-352-352a16 16 0 0122.62-22.62l352 352A16 16 0 01432 448zM255.66 384c-41.49 0-81.5-12.28-118.92-36.5-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 00.14-2.94L93.5 161.38a2 2 0 00-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0075.8-12.58 2 2 0 00.77-3.31l-21.58-21.58a4 4 0 00-3.83-1 204.8 204.8 0 01-51.16 6.47zM490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 00-74.89 12.83 2 2 0 00-.75 3.31l21.55 21.55a4 4 0 003.88 1 192.82 192.82 0 0150.21-6.69c40.69 0 80.58 12.43 118.55 37 34.71 22.4 65.74 53.88 89.76 91a.13.13 0 010 .16 310.72 310.72 0 01-64.12 72.73 2 2 0 00-.15 2.95l19.9 19.89a2 2 0 002.7.13 343.49 343.49 0 0068.64-78.48 32.2 32.2 0 00-.1-34.78z"/><path d="M256 160a95.88 95.88 0 00-21.37 2.4 2 2 0 00-1 3.38l112.59 112.56a2 2 0 003.38-1A96 96 0 00256 160zM165.78 233.66a2 2 0 00-3.38 1 96 96 0 00115 115 2 2 0 001-3.38z"/></svg>
)

const RefreshIcon = (props) => (
  <svg  {...props} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.7 7.7A7.1 7.1 0 0 0 5 10.8M18 4v4h-4m-7.7 8.3A7.1 7.1 0 0 0 19 13.2M6 20v-4h4"/>
  </svg>
)

// const SortIcon = (props) => (
//   <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
// )

const SlidersIcon = (props) => (
  <svg 
    {...props}
    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line>
  </svg>
)

const ProductsIcon = ({
    classname ='-ml-0.5 w-7 h-7', 
    ...props}) => (
  <svg className={classname} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 341.37V170.61A32 32 0 00432.11 143l-152-88.46a47.94 47.94 0 00-48.24 0L79.89 143A32 32 0 0064 170.61v170.76A32 32 0 0079.89 369l152 88.46a48 48 0 0048.24 0l152-88.46A32 32 0 00448 341.37z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M69 153.99l187 110 187-110M256 463.99v-200"/></svg>
)

const EditIcon = (props) => (
  <svg 
    {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
  </svg>
)

const SummaryIcon = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10 19q-.425 0-.712-.288T9 18t.288-.712T10 17h10q.425 0 .713.288T21 18t-.288.713T20 19zm0-6q-.425 0-.712-.288T9 12t.288-.712T10 11h10q.425 0 .713.288T21 12t-.288.713T20 13zm0-6q-.425 0-.712-.288T9 6t.288-.712T10 5h10q.425 0 .713.288T21 6t-.288.713T20 7zM5 20q-.825 0-1.412-.587T3 18t.588-1.412T5 16t1.413.588T7 18t-.587 1.413T5 20m0-6q-.825 0-1.412-.587T3 12t.588-1.412T5 10t1.413.588T7 12t-.587 1.413T5 14m0-6q-.825 0-1.412-.587T3 6t.588-1.412T5 4t1.413.588T7 6t-.587 1.413T5 8"/></svg>
)

const CrownIcon = (props) => (
  <svg {...props} width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" fillRule="evenodd" d="M7.41 1.713a.5.5 0 0 0-.82 0L3.432 6.225L.854 3.646A.5.5 0 0 0 0 4v6.5a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4a.5.5 0 0 0-.854-.354l-2.578 2.579z" clipRule="evenodd"/></svg>
)

const ShieldIcon = (props) => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M19 11a7.5 7.5 0 0 1-3.5 5.94L10 20l-5.5-3.06A7.5 7.5 0 0 1 1 11V3c3.38 0 6.5-1.12 9-3c2.5 1.89 5.62 3 9 3zm-9 1.08l2.92 2.04l-1.03-3.41l2.84-2.15l-3.56-.08L10 5.12L8.83 8.48l-3.56.08L8.1 10.7l-1.03 3.4L10 12.09z"/></svg>
)

export {
  ApproveIcon,
  WaitIcon,
  RejectIcon,
  MoreIcon,
  CheckRoundedIcon,
  TruckIcon,
  TimeScheduleIcon,
  SearchIcon,
  MemberAddIcon,
  ErrorIcon,
  SortIcon,
  CopyIcon,
  LocalOfferOutlinedIcon,
  RemoveAttachIcon,
  FileCopyIcon,
  FilterIcon,
  ChevronDown,
  CheckIcon,
  DeleteIcon,
  RemoveUserIcon,
  PlusIcon,
  MinusIcon,
  XIcon,
  BackArrow,
  EllipsesIcon,
  BellIcon,
  BackArrowShort,
  ForwardArrowShort,
  FilterIconOutline,
  UsersIcon,
  ChevronRight,
  TreeIcon,
  RequestIcon,
  ExcelIcon,
  ConfirmDeliveryIcon,
  StoreIcon,
  InfoIcon,
  ChartIcon,
  CheckBoxIcon,
  LinkIcon,
  AscendingIcon,
  DescendingIcon,
  EyeIcon,
  ChevronLeft,
  RefreshIcon,
  SlidersIcon,
  ClosedEyeIcon,
  ProductsIcon,
  EditIcon,
  SummaryIcon,
  CrownIcon,
  ShieldIcon
};