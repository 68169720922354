import { BasicImageDropdown } from '@/components/dropdowns/BasicImageDropdown';
import { getLogoUrl } from '@/utils';
import React from 'react';


export const OrgCell = ({org, className, ...props}) => {
    return org ? (
        <div 
            className={`flex flex-col md:flex-row items-start md:items-center gap-2 w-fit ${className} flex-shrink-0 min-w-[auto] min-h-fit my-1 `} 
            {...props}
        >
            <BasicImageDropdown 
                src={getLogoUrl(org.id)}
                className='static'
                imageClassName="max-w-[5rem] object-cover  rounded-md flex-shrink-0 min-w-[auto]"
                imageProps={{
                    alt:"org logo",
                    style:{height: '50px'},
                    onError:({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.classList.add('invisible')
                    }
                }}
            />
            <span className='font-semibold text-sm lg:text-lg  mt-2  w-fit'>{org.name}</span>
        </div>
    ) : (
        <>
            <div className="skeleton w-20 h-14"/>
            <div className="mt-2 skeleton w-20 h-4"/>
        </>
    )
};

