import React, { forwardRef } from 'react';
import { InputContainer } from './InputContainer';
import { InputBase } from './InputBase';
import { MILLISEC } from '@/constants';
import { getDashedDateString } from '@/utils';


export const BasicDateInput = forwardRef(function({
    label=null,
    name='file',
    className, 
    isError=false,
    errorText,
    required=false,
    onChange=()=>{},
    containerClassName, 
    min,
    max,
    disabled=false,
    ...props},ref) { 
    return (
<div className={`my-2 w-full gap-1 ${label? 'form-control' : ''}`}>
    <InputBase 
        {...{
            label,
            isError,
            errorText,
            required,
            containerClassName,
        }}
        >
        <input 
            onChange={onChange}
            required={required} 
            name={name}
            type="date" 
            ref={ref}
            disabled={disabled}
            className={`input input-little input-bordered  py-[2px] focus:outline-none focus:border-primary focus-within:outline-none focus-within:border-primary w-full 
                    ${disabled ? 'input-disabled' : ''} ${className}`} 
            {...props}/>
    </InputBase>
</div>
    );
});

