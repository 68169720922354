
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getMyWishlist } from '@/apis/base/wishlist/getMyWishlist';
import { arrayToObject } from '@/utils';


export default function useMyWishlist({enabled=true, ...props}) {
    const query = useQuery({
        queryKey: ['user', 'wishlist', ROUTE_IDS.GET_MY_WISHLIST],
        queryFn: getMyWishlist,
        staleTime: MILLISEC.MIN_15,
        enabled: enabled,
        select,
        ...props
    });
    return query;
}
function select(data) {
    return arrayToObject(data, 'productid');
}