import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';
import { BasicBlackOutlineButton } from '@/components/Buttons/BasicBlackOutlineButton';
import { CheckIcon, ChevronDown } from '@/components/Icons';
import React from 'react';


export const PageSizeDropdown = ({table}) => {
    const {pageIndex, pageSize } = table?.getState?.()?.pagination || {}
    return (
        <div className='dropdown dropdown-top ml-6 flex gap-1 text-sm items-center'>
            Show 
            <div tabIndex={0} role="button" className="btn btn-ghost px-2 btn-sm m-1">
                {pageSize}
                <ChevronDown className='w-5 h-5 -mr-1'/>
            </div>
            <ul tabIndex={0} className="dropdown-content z-[999] menu p-2 shadow bg-base-100 rounded-box ">
                <li onClick={() => table?.setPageSize(10)}><a className='flex'>
                10
                {pageSize === 10 && <CheckIcon className='w-4 h-4'/>}
                </a></li>
                <li onClick={() => table?.setPageSize(25)}><a className='flex'>
                25
                {pageSize === 25 && <CheckIcon className='w-4 h-4'/>}
                </a></li>
                <li onClick={() => table?.setPageSize(50)}><a className='flex'>
                50
                {pageSize === 50 && <CheckIcon className='w-4 h-4'/>}
                </a></li>
            </ul>
            Rows
        </div>
    );
};

