
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeSecondaryEmail } from '@/apis/base/user/removeSecondaryEmail';

export default function useRemoveSecondaryEmail({myUserID, ...props}) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.REMOVE_SECONDARY_EMAIL],
        mutationFn: removeSecondaryEmail,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            if (data?.status === "Secondary email not removed.") throw new Error(data)
            client.invalidateQueries({queryKey: ['user', myUserID]})
            client.invalidateQueries({queryKey: ['user', 'me']})
            client.invalidateQueries({queryKey: ['context']})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}