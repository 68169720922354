import { getOrgsById } from '@/apis/base/organizations/getOrgsById';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { queryOptions, useQuery } from '@tanstack/react-query';

export default function useOrgWithId({org_id,showMembers = false, recursive = 0}) {
  const safeOrgId = Array.isArray(org_id) ? org_id : [org_id];
  const myOrgs = useQuery(orgWithIdConfig({org_id: safeOrgId, showMembers, recursive}));
  return myOrgs;
}

export const orgWithIdConfig = ({org_id, showMembers=false, recursive=0}) => queryOptions({
  queryKey: ['org', org_id, showMembers, recursive, ROUTE_IDS.GET_ORGS_BY_ID],
  queryFn:  () => getOrgsById({"orgids[]": org_id, recursive, showmembers: showMembers}, false),
  staleTime: MILLISEC.MIN_15,
  enabled: org_id != null && org_id.length > 0 && org_id?.[0] != null,
})
