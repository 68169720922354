import { Divider, styled } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import Carousel from 'react-material-ui-carousel';

import { CommunitySponsorsSection } from '@/components/CommunitySponsorsSection';
import { TrackedImage } from '@/components/Image/TrackedImage';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { getSplashUrl, setCookie } from '@/utils';
// import Client from 'shopify-buy';
import { GenericReport } from '@/components/SEE/GenericReport';
import { BasicTabs } from '@/components/Tabs/BasicTabs';
import { DefaultCuratorFeed } from '@/views/pages/Main/Home/components/Curator/DefaultCuratorFeed';
import { InfoPopup } from '@/wrappers/swal/InfoPopup';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslations } from '../../../../hooks/translations';
import { GiftIcon, HeartIcon } from './components/Icons';

const backgroundItems = [
  {
    url: '/assets/images/welcome.jpg',
    text1: 'Welcome to a new',
    text2: 'sustainable marketplace.',
  },
  {
    url: '/assets/images/connecting-brands.jpg',
    text1: 'Connecting brands',
    text2: 'with communities.',
  },
  {
    url: '/assets/images/every-transaction.jpg',
    text1: 'Every transaction',
    text2: 'gives back.',
  },
];


const logos = [
  { src: '/assets/images/levi.png', alt: "Levi's" },
  { src: '/assets/images/ikea.png', alt: 'IKEA' },
  { src: '/assets/images/p&g.png', alt: 'P&G' },
  { src: '/assets/images/aldo.png', alt: 'ALDO' },
  { src: '/assets/images/h&m.png', alt: 'H&M' },
  { src: '/assets/images/gap.png', alt: 'GAP' },
  { src: '/assets/images/roots.png', alt: 'Roots' },
  { src: '/assets/images/zara.png', alt: 'ZARA' },
];

function OrgHome(props) {
  const { cookies, history } = props;
  const text = useTranslations('home');
  const client = useQueryClient()
  const contextQuery = useCurrentOrgContext();
  const context = contextQuery.data;
  const contextSuccess = contextQuery.isSuccess;
  const wishlistAccessible = context?.finStatus === 'Approved'

  const [splashImageError, setSplashImageError] = useState(false)
  
  useEffect(() => {
    if (!contextQuery.data) return
    ensureBrandPreferences()
    checkCachedSplashImage()
  },[contextQuery.data])
  
  function ensureBrandPreferences() {
    if (contextQuery.isSuccess  && context?.standardCommunity && !context?.isAdmin ) {
      if (context?.mybrands?.length > 0) return
      if (!!cookies.get('askedForBrandPreferences')) return
      setCookie('askedForBrandPreferences', true)
      InfoPopup.fire({
        title: 'Welcome to ' + context?.orgName,
        text: 'To help personalize your experience and source the products you love, please select the brands you like',
        confirmButtonText: 'Select Brands',
        cancelButtonText: 'Maybe later'
      }).then((res)=> {
        res.isConfirmed && history.push('/account?active=2')
      })
    }
  }
  function checkCachedSplashImage() {
    // const data = client.getQueryData(['mock',getSplashUrl(context?.orgid)])
    // if (data == null) {
    //   setSplashImageError(true)
    // } else {
    //   setSplashImageError(false)
    // }
  }

  return (
    <div className="w-full min-h-content bg-primary flex justify-center">
      <div className="container">
        <div className="w-full h-full px-4 sm:px-6 xl:px-8 my-4">
          {contextSuccess && (
            <>
              <p className="text-3xl font-semibold opacity-75">{`${context?.orgName}`}</p>
              <p className="text-xl font-semibold text-black">{`${text.title}${
                contextSuccess ? ', ' + context?.firstname : ''
              }`}</p>
            </>
          )}
          <div className="my-6 w-full xl:flex">
            <div className="xl:w-3/5">
              <div
                className="w-full shadow-card rounded-9 relative select-none z-[1]"
                style={{ paddingTop: '65%' }}
              >
                {!!context?.title || !!context?.welcome && (
                  <>
                    <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 z-[2]">
                      <div className="flex justify-center items-center text-center">
                        <div className="flex flex-col justify-center items-center">
                          <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                            {context?.title
                              ? context.title
                              : ''}
                          </p>
                          <p className="2xl:text-3xl sm:text-2xl xs:text-xl text-lg xs:mt-4 mt-2 font-medium text-w
                          te">
                            {context?.welcome
                              ? context?.welcome
                              : ''}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-full bg-dark absolute top-0 left-0 opacity-60" />
                  </>
                )}

                { 
                  <>
                    {!splashImageError && context ? (
                      <>
                        <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 z-[2]">
                          <div className="flex justify-center items-center text-center">
                            <div className="flex flex-col justify-center items-center">
                              <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                                {context?.title
                                  ? context?.title
                                  : ''}
                              </p>
                              <p className="2xl:text-3xl sm:text-2xl xs:text-xl text-lg xs:mt-4 mt-2 font-medium text-white">
                                {context?.welcome
                                  ? context.welcome
                                  : ''}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-full h-full bg-dark absolute top-0 left-0  opacity-60 z-[1]" />
                        <TrackedImage
                          // disableTracking
                          className="w-full h-full absolute top-0 left-0 object-cover object-top"
                          src={getSplashUrl(context?.orgid)}
                          onError={({ currentTarget }) => {
                            setSplashImageError(true);
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="/assets/images/defaultsplash.png";
                          }}
                          alt="Home logo"
                        />
                      </>
                    ) : (
                      <div className="w-full h-full absolute top-0 left-0">
                        <StyledCarousel
                          className='z-0'
                          indicatorContainerProps={{style: {zIndex:1}}}
                          indicatorIconButtonProps={{
                            style: { color: 'white', zIndex: 1, opacity: 0.5, padding: 3 },
                          }}
                          activeIndicatorIconButtonProps={{
                            style: { zIndex: 1, opacity: 1 },
                          }}
                        >
                          {backgroundItems.map((item, i) => (
                            <div key={item.url} className="w-full h-full relative">
                              <img
                                className="w-full h-full object-cover object-bottom"
                                src={item.url}
                                alt={item.text1}
                              />
                              <div className="w-full h-full bg-dark absolute top-0 left-0  opacity-60" />
                              <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 ">
                                <div className="flex justify-center items-center text-center">
                                  <div className="flex flex-col justify-center items-center">
                                    <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                                      {item.text1}
                                    </p>
                                    <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                                      {item.text2}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </StyledCarousel>
                      </div>
                    )}
                  </>
                }
              </div>
            </div>
            <div className="xl:w-2/5 xl:pl-5 xl:pt-0 pt-6 flex xl:flex-col sm:flex-row flex-col gap-4 h-ull">
              {/* TODO Figure out what this is about */}
              {!context ? (
                <>
                  <div className="flex-1 flex flex-col justify-center items-center text-center rounded-9 bg-gray-200 p-4 overflow-hidden">
                    <HeartIcon />
                    <p className="2xl:text-3xl sm:text-2xl text-xl mt-4 font-semibold tracking-0.28">
                      {text.annualDonated}
                    </p>
                    <p className="2xl:mt-5 mt-3 sm:text-lg text-base font-light">
                      {text.annualDonation}
                    </p>
                  </div>
                  <div className="2xl:mt-5 xl:mt-3 sm:mt-0 mt-3 xl:ml-0 sm:ml-3 ml-0 flex-0 flex flex-col justify-center items-center text-center rounded-9 bg-gray-200 p-4 overflow-hidden">
                    <GiftIcon />
                    <p className="2xl:text-3xl sm:text-2xl text-xl mt-4 font-semibold tracking-0.28">
                      {text.communityPartner}
                    </p>
                    <div className="2xl:mt-5 mt-3 sm:text-lg text-base font-light flex justify-space items-center w-full ml-0">
                      <div className="flex-1">
                        <p>{text.communitySchools}</p>
                        <p>{text.schoolText}</p>
                        <p>{text.CommunityText}</p>
                      </div>
                      <p className="mx-4 sm:text-3xl text-2xl opacity-50">
                        {' > '}
                      </p>
                      <div className="flex-1">
                        <p>{text.communityCharities}</p>
                        <p>{text.charities}</p>
                      </div>
                      <p className="mx-4 sm:text-3xl text-2xl opacity-50">
                        {' > '}
                      </p>
                      <div className="flex-1">
                        <p>{text.communityBrands}</p>
                        <p>{text.brands}</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex-1 flex flex-col justify-between rounded-9 bg-gray-200 p-4 overflow-hidden ">
                    <p className="2xl:text-3xl xl:text-2xl sm:text-2xl text-xl font-semibold tracking-0.28 text-blue-cornflower">
                      {text.gettingStarted}
                    </p>
                    <div className="flex flex-col ml-2">
                      <span
                        className="xl:text-xl sm:text-lg text-lg font-semibold underline cursor-pointer"
                        onClick={() =>
                          history.push(
                            // TODO : figure out what this means
                            `/organization-preferences?active=${
                              context?.isAdmin
                                ? 2
                                : 0
                            }#request-products`
                          )
                        }
                      >
                        {text.ambTitle}
                      </span>
                      <span className="2xl:text-lg xl:text-md sm:text-sm text-md text-gray-500">
                        {text.ambDescription}
                      </span>
                    </div>
                    <div className="flex flex-col ml-2">
                      <span
                        className="xl:text-xl sm:text-lg text-lg font-semibold underline cursor-pointer"
                        onClick={() =>
                          history.push(
                            `/organization-preferences?active=${
                              context?.isAdmin
                                ? 2
                                : 0
                            }#become-ambassador`
                          )
                        }
                      >
                        {text.finTitle}
                      </span>
                      <span className="2xl:text-lg xl:text-md sm:text-sm text-md text-gray-500">
                        {text.finDescription}
                      </span>
                    </div>
                    <div className="flex flex-col ml-2">
                      <span
                        className="xl:text-xl sm:text-lg text-lg font-semibold underline cursor-pointer"
                        onClick={() =>
                          history.push(
                            `/organization-preferences?active=${
                              context?.isAdmin
                                ? 2
                                : 0
                            }#become-social-media-influencer`
                          )
                        }
                      >
                        {text.smiTitle}
                      </span>
                      <span className="2xl:text-lg xl:text-md sm:text-sm text-md text-gray-500">
                        {text.smiDescription}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col rounded-9 bg-gray-200 p-4 overflow-hidden justify-stretch ">
                    <div>
                      <span className="inline-block align-baseline 2xl:text-3xl sm:text-2xl text-xl font-semibold tracking-0.28 text-blue-cornflower">
                        {text.ourCommunityStore}
                      </span>
                      <div className="mb-3">
                        <Divider />
                        {context.ecommerceEnabled ? (
                          <span className="2xl:text-lg xl:text-md sm:text-sm text-md text-gray-500">
                            {text.transactionSupport}
                          </span>
                        ) : wishlistAccessible && (
                            <span className="2xl:text-lg xl:text-md sm:text-sm text-md text-gray-500">
                              {text.updateWishlish}
                              <a onClick={() => {
                                if (wishlistAccessible)
                                  history.push('/wishlist')
                                }} className={'underline'}>
                                wishlist
                              </a>
                            </span>
                        )}
                      </div>
                    </div>
                    <div className='h-full'>
                      {context.ecommerceEnabled ? (
                        <a
                          href={'https://unitedinchange-app.myshopify.com/'}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div 
                            className="
                            relative grid grid-cols-4 xl:gap-5 md:gap-4 sm:gap-3 gap-2 justify-items-stretch h-full">
                            {/*{shopifyProducts.map(product => (
                                <a className="mr-3" href={product.onlineStoreUrl}>
                                  <img className="h-24" src={product.images[0].src} alt={"product name"}/>
                                </a>
                            ))}*/}

                            {logos.map((logo, i) => <BrandLogo key={logo.alt} {...logo}/>)}
                          </div>
                        </a>
                      ) : (
                        <a href={'/wishlist'} className='hover:cursor-pointer'>
                          <div className="
                             relative grid grid-cols-4 xl:gap-5 md:gap-4 gap-1 auto-rows-fr h-full">
                              {logos.map((logo, i) => <BrandLogo key={logo.alt} {...logo}/>)}
                          </div>
                        </a>
                      )}
                    </div>
                  </div>
                </>
              )}

            </div>
          </div>
          
          <div className="mt-4 flex flex-col gap-3">

          { context && (
            <CommunitySponsorsSection />
          )}

            <BasicTabs tabNames={['News']} activeTab={0} fake>
              <div className='-mt-6'>
                <DefaultCuratorFeed/>
                {/* <CuratorWidget feedId="2da72240-96c1-4050-b897-dfe742d1c9bf" /> */}
              </div>
            </BasicTabs>
            <GenericReport />
          </div>
        </div>
      </div>
      {/* <FeedBackDialog
        open={take_survey === 1 && pickup_id && openFeedBackGlg}
        data={feedBackDlgData}
        onChange={onFeedBackDlgDataChanged}
        onConfirm={onConfirmFeedBackBtnClicked}
      /> */}
    </div>
  );
}

const StyledCarousel = styled(Carousel)({
  height: '100%',
  '& .CarouselItem': {
    height: '100%',
  },
  '& .CarouselItem div': {
    height: '100%',
  },
  '& > div:last-child': {
    position: 'absolute',
    bottom: 20,
    zIndex: 40,
  },
  '& button svg': {
    fontSize: '17px !important',
  },
});

const BrandLogo = ({src, alt}) => (
  <img className='place-self-center h-12 md:h-16 ' src={src} alt={alt} />

)

export default withCookies(OrgHome);

