import { BasicTable } from "../BasicTable"
import { businessUserColumnHelper } from "./businessUserColumnHelper"
import { nonBusinessUserColumnHelper } from "./nonBusinessUserColumnHelper"

export const UserTable = ({
    standardCommunity,
    permission,
    users,
    deleteUser,
    handleAdminToggle,
    handleBackClicked,
    handleProfileClicked, 
    ...props }) => {
    let columnHelper = null
    if (standardCommunity) 
        columnHelper = nonBusinessUserColumnHelper
    else columnHelper = businessUserColumnHelper
    return users?.length > 0 && (
        <BasicTable 
            horizontalScrolling={false}
            canExpand
            meta={{ 
                permission,
                deleteUser,
                handleAdminToggle,
                handleBackClicked,
                handleProfileClicked,
            }}
            columnHelper={columnHelper}
            data={users}
            {...props}
        />
    )
}