import { PERMISSION } from "@/constants";

export const ctxToPermission = (ctx) => {
    if (!ctx)
        return PERMISSION.USER;
    if (ctx?.['admin level'] === PERMISSION.UIC_ADMIN) 
        return PERMISSION.UIC_ADMIN;
    if (ctx?.isOwner) 
        return PERMISSION.MASTER_ADMIN;
    if (ctx?.isAdmin) 
        return PERMISSION.ADMIN;
    return PERMISSION.USER;
}

export function userCanKickUser(row, permission) {
    const kickerPermission = parseInt(permission);
    if (kickerPermission < parseInt(PERMISSION.ADMIN)) return false;
    const userType = row.type;
    if (userType === 'Master Admin') return false;
    if (userType === 'Admin') return kickerPermission >= parseInt(PERMISSION.MASTER_ADMIN);
    return true;
}