import { useEffect, useState } from 'react';

export function useWishlistFilters(demoData, catData,  defaultToWomens=false) {
    const [demoFilter, setDemoFilter] = useState(null)
    const [catFilter, setCatFilter] = useState(null)
    useEffect(() => {
        if (demoFilter == null && demoData)
            setDemoFilter([demoData?.find?.(d => d.name === 'Women')?.id] || [])
        if (catFilter == null && catData)
            setCatFilter(catData?.map?.(c => c.id))
    },[catData, demoData])

    function handleCatFilterChange(targetCat) {
        if (catFilter.includes(targetCat.id)) 
            setCatFilter((prev) => [...prev.filter(cat => cat !== targetCat.id)])
        else 
            setCatFilter((prev) => [...prev, targetCat.id])
    }
    function handleDemoFilterChange(targetDemo) {
        if (demoFilter.includes(targetDemo.id)) 
            setDemoFilter((prev) => [...prev.filter(demo => demo !== targetDemo.id)])
        else 
            setDemoFilter((prev) => [...prev, targetDemo.id])
    }
    return {
        demoFilter,
        catFilter,
        handleCatFilterChange,
        handleDemoFilterChange
    }
}