import React, { useEffect, useRef, useState } from "react";


import { BasicBlueRectButton } from "@/components/Buttons/BasicBlueRectButton";
import { BasicRedRectButton } from "@/components/Buttons/BasicRedRectButton";
import { RequestManagementTable } from "@/components/Tables/RequestManagementTable/RequestManagementTable";
import { UICRequestManagementCols } from "@/components/Tables/RequestManagementTable/UICRequestManagementCols";
import ApplicationManagementDialog from "@/components/User/ApplicationManagementDialog";
import UserProfile from "@/components/User/UserProfile";
import useModAppRequests from "@/hooks/state/admin/useModAppRequests";
import useAllOrgsRoleData from "@/hooks/state/application/useAllOrgsRoleData";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { useTranslations } from "@/hooks/translations";
import { openDialogWithRef } from "@/utils";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { Popup } from "@/wrappers/swal/Popup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import RequestDetailsDialog from "../components/RequestDetailsDialog";

const VIEWS = {
  REQUESTS: 0,
  USER: 1 
}

function Requests() {
  const text = useTranslations("members");
  const tableRef = useRef();
  const dialogRef = useRef()
  const [view, setView] = useState(VIEWS.REQUESTS);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hasSelection, setHasSelection] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [detailedRequest, setDetailedRequest] = useState(null);
 
  const [openApplicationMgmtDlg, setOpenApplicationMgmtDlg] = useState(false);
  const [approve, setApprove] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [comment, setComment] = useState('');
  const contextQuery = useCurrentOrgContext()

  const roleEnumQuery = useRoleEnum()
  const roleEnum = roleEnumQuery?.data

  const appStatusQuery = useAppStatusEnum()
  const appStatuses = appStatusQuery?.data

  const requestsQuery = useAllOrgsRoleData()
  const requestMutation = useModAppRequests({
    onSuccess: (data) => {
      SuccessToast.fire({
        text: `Request Successfully ${approve ? 'Approved' : 'Declined'}`,
      })
    },
    onError: (data) => {
      Popup.fire({
        title: "Error",
        text: `Failed ${approve ? 'approve' : 'decline'} to request`,
        icon: "error",
        showConfirmButton: true,
      })
    } 
  })

  useEffect(() => {
  }, [requestsQuery.data])

  // NOTE Because both a request and its parent org may be selected, there may be duplicate ids
  function recursiveRequestParser(arr, fs, as, is) {
    if (arr?.length > 0) {
      for (const item of arr) {
        if (!item?.requests) {
          addReqData(item, fs, as, is)
          continue
        }
        for (const req of item?.requests) addReqData(req, fs, as, is)
        
      recursiveRequestParser(item.subnodes, fs, as, is)
      }
    }
  }

  function addReqData(req, fs, as, is) {
    if (req?.findata) fs.push(req.id)
      if (req?.ambassadordata) as.push(req.id)
      if (req?.smidata) is.push(req.id)
  }

  const handleMutate = (isApprove, targetRequests) => {
    const safeTargetRequests = targetRequests || selectedItems
    const items = safeTargetRequests?.length > 0 ?
      safeTargetRequests
      : tableRef?.current?.getSelectedRowModel?.()
        ?.flatRows.map(r => r.original)
    if (tableRef?.current != null && items?.length > 0) {
      const finIDs = []
      const ambIDs = []
      const smiIDs = []
      recursiveRequestParser(items, finIDs, ambIDs, smiIDs)
      const params = {
        orgid: contextQuery.data?.orgid,
        statusid : isApprove ? appStatuses.approved : appStatuses.declined,
        "finids[]": finIDs,
        "ambassadorids[]": ambIDs,
        "smiids[]": smiIDs,
          comment: comment 
      }
      requestMutation.mutate(params)
      setSelectedItems([]);
    } else {
      FailPopup.fire({text: `Failed to ${isApprove ? 'approve' : 'reject'} request`})
    }
    // setOpenApplicationMgmtDlg(false); 
}


  const viewRequestDetails = (item) => {
      setDetailedRequest(item);
      openDialogWithRef(dialogRef)
  };


  const onSingleModify = (isApprove,request) => {
    setSelectedItems([{requests: [request]}]);
    setApprove(isApprove);
    // setOpenApplicationMgmtDlg(true);
    if (isApprove) {
      handleMutate(true, [request])
    } else {
      handleMutate(false, [request])
    }
  }

  const handleMultiModify = (isApprove) => {
    setApprove(isApprove);
    // setOpenApplicationMgmtDlg(true);
    if (isApprove) {
      handleMutate(true)
    } else {
      handleMutate(false)
    }
  }

  const handleSelectionChange = (e) => { 
      setHasSelection(Object.keys(e).length > 0);
  }

  function viewUserDetails(user) {
    setSelectedUser({...user, id: user['user id']});
    setView(VIEWS.USER);
  }
  return (
    <div className="mb-12">
      {!requestsQuery.data  ? (
          <div className="flex flex-col justify-center  gap-4">
            <div className="skeleton h-100 bg-accent-content w-full flex flex-col justify-end px-5 py-5  gap-1">
            </div>
          </div>
       ) : 
      <>
        { view === VIEWS.USER ? (
          <UserProfile
            backLabel='Back to Requests table'
            user={selectedUser}
            exitDetailView={() => setView(VIEWS.REQUESTS)}
            showRoles={false}
          />
        ) : (
          <RequestManagementTable 
            tableRef={tableRef}
            columnHelper={UICRequestManagementCols}
            className='table-lg'
            data={requestsQuery.data}
            handleSelectionChange={handleSelectionChange}
            canExpand
            meta={{
              onSingleModify,
              viewRequestDetails,
              viewUserDetails
            }}>
              <BasicBlueRectButton disabled={!hasSelection} onClick={() => handleMultiModify(true)}>
                Approve
              </BasicBlueRectButton>
              <BasicRedRectButton disabled={!hasSelection} onClick={() => handleMultiModify(false)}>
                Decline
              </BasicRedRectButton>      
          </RequestManagementTable>

        )}
      </>

      }
      <RequestDetailsDialog
        dialogRef={dialogRef}
        request={detailedRequest}
      />
      {/* <ApplicationManagementDialog
        text={text}
        open={openApplicationMgmtDlg}
        approve={approve}
        comment={comment}
        onClosedBtnClicked={() => setOpenApplicationMgmtDlg(false)}
        onConfirmBtnClicked={onConfirmBtnClicked}
        onCommentChanged={(comment) => setComment(comment)}
      /> */}
    </div>
  );
}

export default Requests;
