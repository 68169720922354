import React from "react"
import { ChevronDown, FilterIcon } from "../Icons"

export const Filters = ({ disabled, headers, table }) => {
    const filteredHeaders = headers.filter(h => 
      h.column.getCanFilter() &&
      h.column.getIsVisible()
    )
  
      return (
          <div className={`flex gap-2 items-start 
          ${disabled ? 'opacity-40' : '' }`}
          >
          {
            filteredHeaders
                .map((header, idx) => (
                    <div 
                      className="relative join items-center flex rounded-2xl bg-base-100 border border-gray-border" key={header.id + 'container'}>
                        <Filter disabled={disabled} key={header.id + 'filter'} column={header.column} table={table} />    
                    </div>
            ))
          }
          </div>
      )
}

const Filter = ({table, column, ...props}) => {
    const firstValue = table
    .getPreFilteredRowModel()
    .flatRows
    ?.find(r => r.getValue(column.id) != null)
    ?.getValue(column.id)
  const columnFilterValue = column.getFilterValue()
  const sortedUniqueValues = React.useMemo(
      () => { 
        switch (typeof firstValue) {
          case 'number':  return []
          case 'object': 
            // This dedupes column values that are arrays
            return Array.isArray(firstValue) 
              ? [...(new Set( 
                  [].concat.apply([], Array.from(column.getFacetedUniqueValues().keys()))
                ))] 
              : []
          default: return Array.from(column.getFacetedUniqueValues().keys())?.filter(k => k!=null).sort()
        }
      },
      [column.getFacetedUniqueValues()]
    )   
    if (column.columnDef?.meta?.hiddenFilter != null) return <></>
  return typeof firstValue === 'number' ? (
    <>
        <div className="indicator join-item w-full">
          <div className="indicator-item  border text-xs top-1 border-gray-border rounded-lg px-4  bg-base-100 shadow-sm">
            {column.id}
          </div>
          {console.log(column.getFacetedMinMaxValues())}
          <FilterMinMaxInput
            {...props}
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[0] ?? ''}
            onChange={value =>
              column.setFilterValue((old) => [value, old?.[1]])
            }
            placeholder={`Min ${
              column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ''
            }`}
            className='w-24 pl-5'
          />
        </div>
        <div className="divider px-0 mx-0 divider-horizontal after:w-[1.5px] before:w-[1.5px]"></div>  
        <div className="join-item w-full">
          <FilterMinMaxInput
            {...props}
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[1] ?? ''}
            onChange={value =>
              column.setFilterValue((old) => [old?.[0], value])
            }
            placeholder={`Max ${
              column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ''
            }`}
            className='w-24 pl-2'
          />
        </div>
    </>
  ) : (
    <div>
      <datalist 
        key={column.id + 'list'} 
        id={column.id + 'list'} 
        style={{
          maxHeight:'15rem'
        }} 
        >
          {sortedUniqueValues.slice(0, 5000).map((value) => value != null && (
              <option value={value} key={value} />
          ))}
      </datalist>
      <FilterTextInput
        {...props}
        type="text"
        value={(columnFilterValue ?? '')}
        onChange={value => column.setFilterValue(value)}
        // placeholder={`﹙${column.getFacetedUniqueValues().size}﹚${column.id}`}
        placeholder={`${column.id}`}
        className="w-36 nospin"
        list={column.id + 'list'}
      />
    </div>
  )
}

const FilterMinMaxInput = ({
  value: initialValue,
  onChange,
  className,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue)
      
        React.useEffect(() => {
          setValue(initialValue)
        }, [initialValue])
      
        React.useEffect(() => {
          const timeout = setTimeout(() => {
            onChange(value)
          }, debounce)
      
          return () => clearTimeout(timeout)
        }, [value])

    return (
      <input 
        value={value} 
        onChange={(e) => setValue(e.target.value)} 
        type="search" 
        className={`
          nospin
          bg-transparent placeholder:select-none  text-[13px] h-12    focus:outline-none  [&::-webkit-search-cancel-button]:hidden placeholder:hover:font-medium bg-base-100
          ${className} `}
        {...props}     
      />
    )
}

const FilterTextInput = ({
    value: initialValue,
    onChange,
    className,
    debounce = 500,
    ...props}) => {
        const [value, setValue] = React.useState(initialValue)
      
        React.useEffect(() => {
          setValue(initialValue)
        }, [initialValue])
      
        React.useEffect(() => {
          const timeout = setTimeout(() => {
            onChange(value)
          }, debounce)
      
          return () => clearTimeout(timeout)
        }, [value])
      
        return (
        <div className="flex flex-row justify-between h-full align-middle">
            <input 
              {...props} 
                value={value} 
                  onChange={(e) => setValue(e.target.value)} 
                  type="search" 
                  className={`
                    bg-transparent placeholder:select-none px-5 text-[13px] h-12  w-full  focus:outline-none [&::-webkit-calendar-picker-indicator]:hidden [&::-webkit-search-cancel-button]:hidden placeholder:hover:font-medium bg-base-100
                    ${className} `}/>
            <div className="w-4"/>
            <div className="relative h-full place-self-center  ">
            {
                value.length === 0 ? (
                <ChevronDown className=" pointer-events-none absolute right-4 w-[1.2rem] h-[1.2rem]  bottom-0 -top-2  stroke-accent" />
                ) : (
                    <div className="absolute text-[12px] right-3 w-[1.2rem] h-[1.2rem]  bottom-0 -top-2  text-accent hover:cursor-pointer" onClick={() => setValue('')} >✕</div>
                )
            }
            </div>
        </div>
        )
    //   }
    // return (
    // )
}