import { InventoryDropdown } from "@/components/dropdowns/InventoryDropdown";
import { createColumnHelper, filterFns } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove

export const EditShipmentCols = [
    columnHelper.accessor((row,idx) => idx, {
        id: "No",
        size: 100,
        meta: {
            hiddenFilter:true
        },
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>NO</span>,
    }),
    columnHelper.display({
        id: 'Product Image',
        cell: ({row, table}) => 
            <InventoryDropdown eventid={table.options.meta.eventid} inventoryid={row.original.inventoryid} />
    }),
    columnHelper.accessor((row) => row.productname, {
        id: "Product Name",
        size: 100,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>PRODUCT</span>,
    }),
    columnHelper.accessor((row) => row.requestquantity, {
        id: "Requested Quantity",
        size: 100,
        cell: ({table, getValue, row}) => <>
            { table.options.meta.canEditRequest ? 
                (
                    <input
                        type="number"
                        className='input input-xs border border-gray-border text-lg max-w-[5rem] focus:outline-none'
                        defaultValue={getValue()} 
                        name='req' 
                        onBlur={(e) => 
                            table.options.meta?.onInlineChange?.(
                                {
                                    ...row.original,
                                    requestquantity:e.target.value
                                }
                                )
                        }
                                
                    />
                ) : (
                    <span>{getValue()}</span>
                )
            }
        </>,
        header: (info) => <span>REQUESTED QUANTITY</span>,
    }),
    columnHelper.accessor((row) => row.shipmentquantity, {
        id: "Shipped Quantity",
        size: 100,
        cell: ({table, getValue, row}) => <>
            { table.options.meta.canEditShipment ? 
                (
                    <input
                        type="number"
                        className='input input-xs border border-gray-border text-lg max-w-[5rem] focus:outline-none'
                        defaultValue={getValue()} 
                        name='ship' 
                        onBlur={(e) => 
                            table.options.meta?.onInlineChange?.(
                                {
                                    ...row.original,
                                    shipmentquantity:e.target.value}
                            )
                        }/>
                ) : (
                    <span>{getValue()}</span>
                )
            }
        </>,
        header: (info) => <span>SHIPPED QUANTITY</span>,
    }),
]

