import { getEventInventoryUrl, rangeArray } from '@/utils';
import { useMemo } from 'react';
import { CarouselBase } from './CarouselBase';

export const ProductCarousel = ({
    eventid, 
    inventoryid,
    images, 
    numImages, 
    className = 'max-h-[20rem]  lg:max-h-[30rem] lg:max-w-[50%] w-fit h-full', 
    ...props }) => {
    const src = useMemo(() => {
        return (
            eventid
                ? rangeArray(0, numImages - 1)
                    .map(idx => getEventInventoryUrl( eventid, inventoryid, idx))
                : images || ['/assets/images/placeholder.png']
        )
    },[eventid, images, numImages])
    
    return (eventid || images) && (
      <CarouselBase
        className='w-full h-full object-cover border border-gray-border' 
        buttonType='ecommerceButtons'
        aspectRatioClassName='aspect-product'
        disableTracking={images != null}
        images={src}
        numImages={numImages}
      />
    );
};
