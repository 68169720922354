import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';

import AppContextProvider from './context/AppContext';
import UserContextProvider from './context/UserContext';
import Views from './views';
// import 'tailwindcss/tailwind.css';
import DonationContextProvider from '@/context/DonationContext';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import './i18n';
// const {ReactQueryDevtools} = React.lazy(() => import('react-query-devtools'));
import { MILLISEC } from '@/constants';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import config from '@/apis/config';

const queryCache = new QueryCache({
  onError: (error) => {
    // console.log('error from cache:', error);
  },
  onSuccess: (data) => {
    // console.log('data from cache:', data);
  },
  onSettled: (data, error) => {
    // if (error) console.log('error from cache settled:', error);
  },
});
const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: 'always',
      refetchOnReconnect: 'always',
      refetchInterval: MILLISEC.MIN_14,
      retryDelay: 60,
      retry: 1,
      staleTime: 0,
      gcTime: MILLISEC.SEC * 10,
    },
  },
});

const persistor = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
console.log(`env: ${import.meta.env.MODE}`);
console.log(`url: ${config.API_BASE_URL}`);
console.log("config", config)
console.log("import.meta.env.",import.meta.env)
console.log('----')

  return (
    <CookiesProvider>
      {/* <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister: persistor,  maxAge: MILLISEC.MIN_15}}
        onSuccess={() => {
          console.log('successfully created persist client');
        }}
      > */}
      <QueryClientProvider
        client={queryClient}
      >

        { <ReactQueryDevtools />}
        <AppContextProvider>
          <UserContextProvider>
            <DonationContextProvider>
              <Suspense fallback={null}>
                <Views />
              </Suspense>
            </DonationContextProvider>
          </UserContextProvider>
        </AppContextProvider>
      </QueryClientProvider>
      {/* </PersistQueryClientProvider> */}
    </CookiesProvider>
  );
}

export default App;
