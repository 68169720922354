import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { BasicDialog } from '@/components/Dialog/BasicDialog';
import { BasicInput } from '@/components/Input/BasicInput';
import useCreateBrandPref from '@/hooks/state/admin/uic/useCreateBrandPref';
import useDeleteBrand from '@/hooks/state/admin/uic/useDeleteBrandPref';
import { closeDialogWithRef, objectFromForm, openDialogWithRef } from '@/utils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import { AddCircle } from '@material-ui/icons';
import React from 'react';
import { BasicTable } from '../BasicTable';
import { BrandPrefCols } from './BrandPrefCols';


export const BrandPrefTable = ({prefData, ...props}) => {
    const dialogRef = React.useRef();
    const safeData = prefData || [];
    const deleteBrandMutation = useDeleteBrand({
        onSuccess: (data) => {
            if (data?.status === 'Brand not removed') throw new Error('Brand not removed')
            SuccessToast.fire({text: 'Brand Deleted'})
        },
        onError: (error) => {FailPopup.fire({text: 'Failed to delete brand'})}
    })
    const createBrandMutation = useCreateBrandPref(queryResultFns('Brand Created', 'Failed to create brand'))
    const deleteBrand = (brandid) => {
        console.log('delete brand', brandid);
        deleteBrandMutation.mutate({brandid})
    }

    const createBrand = (form) => {
        const {brandname} = objectFromForm(form.target) || {}
        createBrandMutation.mutate({brandname})
    }

    const openDialog = () => {
        openDialogWithRef(dialogRef)
    }

    return ( 
        <>
            <BasicTable className='table-xs' data={safeData} columnHelper={BrandPrefCols} meta={{deleteBrand}} {...props} formID='brandPrefForm' initialPageSize={50} >
                <div className="mb-6 flex justify-end items-center">
                    <BasicRectButton className={'btn-secondary'} onClick={openDialog}>
                    <AddCircle />
                    &nbsp;&nbsp;{'Add'}
                    </BasicRectButton>
                </div>
            </BasicTable>
            <BasicDialog ref={dialogRef}  handleSubmit={createBrand} title={'Create Brand'} className='w-[20rem]'>
                <BasicInput name='brandname' label="Brand" placeholder='Enter a name for the new brand' />
                <div className='flex gap-2 justify-end'>
                    <BasicRectButton className={'btn-neutral'} onClick={() => closeDialogWithRef(dialogRef)}>
                        Cancel
                    </BasicRectButton>
                    <BasicRectButton className={'btn-secondary'} formMethod='dialog' type='submit'>
                        Create
                    </BasicRectButton>

                </div>
            </BasicDialog>

        </>
    );
};

