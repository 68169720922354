import { useTranslations } from '@/hooks/translations';
import { Link, useHistory } from 'react-router-dom';

import { DropdownBlueLink } from '@/components/Label/DropdownBlueLink';
import { HamburgerButtonDropdown } from '@/components/dropdowns/HamburgerButtonDropdown';
import { withCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';


const UnauthenticatedHeader = ({ isAdmin }) => {
  const text = useTranslations('header');
  const history = useHistory()
  const location = useLocation()


  return (
    <header 
    className={`flex place-content-center w-screen justify-items-end items-center  h-header z-20 relative`}>
      <div className='flex flex-row justify-between place-items-end container pl-6 pr-5 xs:pr-0 py-3'>
            <div className='h-16'>
                    <img src={'/assets/images/u11square.png'} className=" max-h-16 object-contain rounded-md" alt='open sidebar'
                    ></img>

            </div>
          <div className="flex items-end ">
              <div className='flex flex-row justify-end w-full place-items-center '>
                <div className="mr-10 sm:block  hidden align-middle ">
                  <Link to="/auth/signup">
                    <BasicBlueRectButton className={'!text-base !px-10'}>{text.signup}</BasicBlueRectButton>
                  </Link>
                </div>
                <div className="mr-10 sm:block hidden align-middle">
                  <Link to="/auth/login">
                    <span className="text-blue-cornflower font-semibold cursor-pointer hover:opacity-80 whitespace-nowrap sm:block hidden place-items-center h-8 mt-2">
                      {text.login}
                    </span>
                  </Link>
                </div>
                <div className="sm:hidden block">
                  <HamburgerButtonDropdown>
                    <li>
                      <DropdownBlueLink to='/auth/login' >
                        Log in
                      </DropdownBlueLink>
                    </li>
                    <li>
                      <DropdownBlueLink to='/auth/signup'>
                        Sign up
                      </DropdownBlueLink>
                    </li>
                  </HamburgerButtonDropdown>
                </div>

                
              </div>
            </div>
        </div>
    </header>
  );
};

export default withCookies( UnauthenticatedHeader);
