//./components/UserColumnDefs.tsx
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { getDateString, strToStatus } from "@/utils";
import { Link } from "react-router-dom";
import { UserStatusCell } from "../Cells/UserStatusCell";
import { UserCell } from "../Cells/UserCell";
import { DeleteButton } from "@/components/Buttons";
import { PERMISSION } from "@/constants";
import { userCanKickUser } from "@/utils/userUtils";
import { RemoveUserButton } from "@/components/Buttons/RemoveUserButton";
import { RemoveUserIcon } from "@/components/Icons";
import { OrgCell } from "@/components/Tables/Cells/OrgCell";
import { BasicToggle } from "@/components/Input/BasicToggle";

// createColumnHelper helps us create columns with maximum type safety.
// we assign the type person so that it knows the structure for our data
const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;

export const businessUserColumnHelper  = [
  columnHelper.accessor((row) =>  row?.id, {
    id: "ID",
    size: 10,
    meta: {hiddenCol: true},
    filterFn: stringFilter,
    cell: ({getValue}) => getValue(),
    header: (info) => <span>ID</span>,
  }),
  // columnHelper.accessor((row) => row.name, {
  //   id: "Organization",
  //   size: 60,
  //   filterFn: stringFilter,
  //   cell: ({row, table, getValue}) => row.original?.shouldRendercells && 
  //     <OrgCell org={row.original}/>,
  //   header: () => <span>ORGANIZATION</span>,
  // }),
  columnHelper.accessor((row) =>  row?.name, {
    id: "Member",
    size: 100,
    filterFn: stringFilter,
    cell: ({row, table, getValue}) =>   
      <UserCell
        row={row.original}
        onClick={() => table.options.meta?.handleProfileClicked(row.original)}
      />,
    header: ({row, table, getValue}) => <span>MEMBER</span>,
  }),
  columnHelper.accessor((row) =>  row?.['phone number'], {
    id: "Phone",
    size: 10,
    filterFn: stringFilter,
    cell: ({row, table, getValue}) =>   getValue(),
    header: ({row, table, getValue}) => <span className="whitespace-nowrap">PHONE</span>,
  }),
  columnHelper.accessor((row) =>  row?.type, {
    id: "Type",
    size: 60,
    filterFn: stringFilter,
    cell: ({row, table, getValue}) =>  
      <span>{getValue()}</span>,
    header: () => <span>TYPE</span>,
  }),
  columnHelper.accessor((row) =>  strToStatus(row.status), {
    id: "Status",
    size: 120,
    filterFn: stringFilter,
    cell: ({row, table, getValue}) =>   
      <UserStatusCell status={getValue()} />  ,
    header: () => <span>STATUS</span>,
  }),
  columnHelper.accessor((row) =>   row?.timestamp, {
    id: "membersince",
    size: 60,
    enableColumnFilter: false,
    cell: ({row, table, getValue}) => 
      <span className="whitespace-nowrap">{getDateString(getValue())}</span>,
    header: () => <span >MEMBER SINCE</span>,
  }),
  columnHelper.display({
    id: "Admin",
    size: 60,
    enableColumnFilter: false,
    cell: ({ table, row }) => (
      <AdminToggle
        row={row}
        meta={table.options.meta}
      />
    ),
    header: () => <span>ADMIN</span>,
  }),
  // columnHelper.display({
  //   id: "Action",
  //   size: 60,
  //   enableColumnFilter: false,
  //   cell: ({table, row}) => 
  //     <Actions 
  //       row={row}
  //       meta={table.options.meta}
  //     />
  //   ,
  //   header: () => <span >ACTION</span>,
  // }),
];

const AdminToggle = ({ row, meta }) => {
  const {
    handleAdminToggle,
    permission } = meta
  const canModifyUserAdmin =  
    parseInt(permission) >= parseInt(PERMISSION.MASTER_ADMIN) 
    && row.original.type !== "Master Admin"
  return canModifyUserAdmin && (
    <div className="form-control">
      <p className="font-medium">Admin</p>
      <BasicToggle
        type="checkbox" className="-mt-2" defaultChecked={row.original.admin} readOnly onChange={() => handleAdminToggle(row.original)} />
    </div>
  )
}

const Actions = ({row, meta}) => {
  const {
    deleteUser,
    handleAdminToggle,
    permission
    } = meta
  return (
    <div className="flex justify-end items-center gap-2">
    { parseInt(permission) >= parseInt(PERMISSION.MASTER_ADMIN) && row.original.type != "Master Admin" && (
      <div className="form-control">
        <p className="font-medium">Admin</p>
        <input 
          type="checkbox" className="toggle " defaultChecked={row.original.type === 'Admin'} readOnly onChange={() => handleAdminToggle(row.original)}/>

      </div>

    )}
      <RemoveUserButton 
        onClick={() => deleteUser(row.original.orgid, row.original.id)}
        disabled={!userCanKickUser(row.original, permission)}
      >
        <RemoveUserIcon />
      </RemoveUserButton>
        
    </div>
  )
}


const UserStatus = ({status}) => (
    <div className="flex items-center">
        <div className="w-3 h-3 rounded-full mr-2">
            { status === '1' ? (
                    <div className="badge bg-green-back text-green-text">Active</div>
                ): (
                    <div className="badge bg-red-back text-red-text">Inactive</div>
                )

            }
        </div>
    </div>
)
