import React, { useEffect, useState } from 'react';
import { BasicRectButton } from './BasicRectButton';
import { RefreshIcon } from '../Icons';
import { useDebounce } from '@/hooks/utils/useDebounce';
import { useMinTimeTrue } from '@/hooks/utils/useMinTimeTrue';


export const BasicRefreshButton = ({onClick, loading=false, ...props}) => {
    const [delayedIsLoading, setDelayedIsLoading] = useState(false)
    useMinTimeTrue(setDelayedIsLoading, loading)

    return (
        <BasicRectButton customSize className='text-sm  btn-square whitespace-nowrap   btn-primary' onClick={onClick}>
            <div className={`swap w-full h-full place-items-center ${delayedIsLoading ? 'swap-active' : ''}`}>
                <span className="loading loading-spinner swap-on"></span>
                <RefreshIcon 
                    className='w-8 h-8 swap-off'
                />  
            </div>
    </BasicRectButton>
    );
};

