import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { signupMember } from '@/apis/base/auth/signupMember';
import { verifyEmail } from '@/apis/base/auth/verifyEmail';

/**
 *
 * @param {Object} param0
 * @param {Object} param0.params
 * @param {String} param0.params.joinerEmail
 * @param {String} param0.params.joinerFirstName
 * @param {String} param0.params.joinerLastName
 *
 * @returns
 */
export default function useVerifyEmail({ onError, onSuccess, props }) {
  const client = useQueryClient();
  const verifyEmailData = useMutation({
    mutationKey: [ROUTE_IDS.VERIFY_EMAIL],
    mutationFn: verifyEmail,
    staleTime: MILLISEC.MIN_15,
    retry: false,
    retryDelay: MILLISEC.SEC,
    onError: onError,
    onSuccess: (data) => {
      client.invalidateQueries({queryKey: ['org']})
      client.invalidateQueries({queryKey: ['user']})
      onSuccess(data)
    },
    ...props,
  });
  return verifyEmailData;
}
