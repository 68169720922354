import React from 'react';

export const BasicRectButton = ({onClick, className, customSize, disabled, type, ...props}) => {
    
    return ['submit','reset'].includes(type) ? (
            <button type={type} className={`
                btn ${disabled ? 'opacity-30 pointer-events-none' : ''}
                focus:outline-none
                focus:border-primary
                ${customSize ? '' : 'text-md px-5 lg:px-10 py-3 '}
                ${className}` } 
                {...{onClick,...props}}
            />
        ) : (
            <div role='button' type='submit' tabIndex={0} className={`
                btn ${disabled ? 'opacity-30 pointer-events-none' : ''}
                focus:outline-none
                focus:border-primary
                ${customSize ? '' : 'text-md px-5 lg:px-10 py-3 '}
                ${className}` } 
                {...{onClick,...props}}>    
            </div>
    );
};

