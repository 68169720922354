import React, { useEffect, useRef, useState } from 'react';
import { XButton } from '../Buttons';
import { XIcon } from '../Icons';
import { dateIsAfter, dashedDateIsValid, getDashedDateString } from '@/utils';
import { MAX_DATE, MIN_DATE } from '@/constants';
import { ErrorLabel } from '../Label/ErrorLabel';


export const BasicStartEndDate = ({
    minLimit=MIN_DATE, 
    maxLimit=MAX_DATE, 
    startName='start',
    endName='end',
    labelClassName= "ml-3 text-sm text-gray-subText",
    label="Time Range",
    onChange, 
    className, 
    minPlaceHolder = 'Start date', 
    maxPlaceholder = 'End date', 
    inputClassName, 
    ...props}) => {
    const [labels, setLabels] =  useState({[startName]: '', [endName]: ''})
    const [error, setError] = useState('')

    useEffect(() => {
        validateRange()
    },[labels])

    function handleChange(e) {
        const {value, name} = e.target || {}
        console.log("e", e)
        setError('')
        setLabels({...labels, [name]: value})
    }

    function validateRange(e) {
        if ((!labels?.[startName] || !labels?.[endName])) return 
            
        if (!dashedDateIsValid((labels[startName]))) {
            setError('Invalid start date')
            return
        }
        if (!dashedDateIsValid((labels[endName]))) {
            setError('Invalid end date')
            return
        }
        if (!dashedDateIsValid((labels[endName]))) 
        if (labels.start === '' || labels[endName] === '') return
        if (dateIsAfter(labels[startName], labels[endName])) {
            setError('Start date is greater than end date')
            return
        }
        setError('')
        onChange(labels)
    }
    function onClearClick() {
        onChange({[startName]: '' , [endName]: ''})
        setLabels({[startName]: '' , [endName]: ''})
    }
    function handlePointerLeave(e) {
        console.log('el', e.target);
        if (e.target.contains(document.activeElement)) return
        console.log('doesnt contain');
        e.target.type = 'text'
    }   
    console.log('start',labels?.[startName] , 'end',labels?.[endName], labels?.[startName]?.length);
    return (
        <div className={`form-control ${className} min-w-full`}>
            <div className="label pb-0 pt-0 ">
                <span 
                    className={`whitespace-nowrap label-text ${labelClassName}`}>
                        {label}
                </span>
                <ErrorLabel className='label-text-alt'>{error}</ErrorLabel>
            </div>
            <div className="indicator w-full rounded-xl join bg-red-400">
                <input
                    onPointerEnter={(e) => e.target.type = 'date'}
                    onPointerLeave={handlePointerLeave}
                    onFocus={(e) => e.target.type = 'date'}
                    onBlur={(e) => e.target.type = 'text'}
                    required
                    placeholder={minPlaceHolder}
                    type="text"
                    name={startName}
                    min={minLimit}
                    max={maxLimit}
                    onChange={handleChange}
                    value={labels?.[startName]}
                    className={`join-item  focus-within:outline-none focus-within:border-primary input input-bordered focus:outline-none
                    ${inputClassName} 
                    ${labels?.[startName]
                        ? 'text-primary focus-within:data-[placeholder]:text-primary' 
                        : 'focus-within:data-[placeholder]:text-base-content data-[placeholder]:text-gray-400' 
                    }
                    `}/>
                { (labels?.[startName] || labels?.[endName]) &&
                    <div tabIndex={0} onClick={onClearClick} className="indicator-item">
                        <XIcon className='btn btn-ghost btn-circle btn-xs text-white bg-gray-darkSubText'/>
                    </div>
                }
                <input
                    required
                    type="text"
                    onPointerEnter={(e) => e.target.type = 'date'}
                    onPointerLeave={handlePointerLeave}
                    onFocus={(e) => e.target.type = 'date'}
                    onBlur={(e) => e.target.type = 'text'}
                    name={endName}
                    min={minLimit}
                    max={maxLimit}
                    placeholder={maxPlaceholder}
                    onChange={handleChange}
                    value={labels?.[endName]}
                    className={`join-item focus-within:outline-none focus-within:border-primary input input-bordered focus:outline-none focus:border-primary 
                    ${inputClassName} 
                    ${!!labels?.[endName] 
                        ? 'text-primary' 
                        : 'focus-within:data-[placeholder]:text-base-content data-[placeholder]:text-gray-400' }
                    `}/>
            </div>
        </div>
    );
};

