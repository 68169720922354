import config from '@/apis/config';
import { ROUTE_IDS, addRouteIDToFormData } from '../routeId';
import api from '@/apis/api';
import { convertObjFieldsToString, jsonToFormData } from '@/utils';
import { postPromise } from "@/apis/base"

/**
 * 
 * @param {Object} params
 * @param {string} params.email
 * @param {string} params.firstname
 * @param {string} params.lastname
 * @param {string} params.occupation
 * @param {string} params.password
 * @param {string} params.phone_number
 * @param {string} params.org_id
 * @param {string} params.invitecode

 * @returns {Promise<any>}
 */

export const signupMember = (params) => 
  {
    params.type = ROUTE_IDS.SIGN_UP_MEMBER;
    postPromise(config.API_BASE_URL,convertObjFieldsToString(params), {json: true});
  }
