
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import StudentRegister from './components/StudentRegister';

import useGetInviteData from '@/hooks/state/invite/useGetCodeData';
import { useCookies, withCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import { userTypes } from '../../../../constants';
import { useTranslations } from '../../../../hooks/translations';

const STEP = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
};
const PROGRESS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

function Signup(props) {
  const { history, location } = props;
  const [cookies, setCookie] = useCookies(['authBGIndex'])
  const params = new URLSearchParams(location.search);
  const text = useTranslations('signup');
  const code = params.get('code');
  const linkOrg = params.get('organization') || params.get('orgid');
  const [invalidInvite, setInvalidInvite] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(PROGRESS.ONE)
  const inviteData = useGetInviteData({code: code, enabled: !invalidInvite})
  useLayoutEffect(() => {
    let bgIndex = cookies.authBGIndex
    if (step === STEP.ONE) {
      bgIndex = 0
    }
    if (step === STEP.TWO || step === STEP.THREE) {
      bgIndex = 1
    }
    if (step === STEP.FOUR) {
      bgIndex = 2
    }
    if (bgIndex !== cookies.authBGIndex) {
      setCookie('authBGIndex', bgIndex, { path: '/' })
    }
    
  },[step])
  useEffect(() => {
    if (code != null) {
      if ((inviteData.isError)) {
        setInvalidInvite(true);
        Swal.fire({
          title: text.error,
          text: text.invitationIncorrect,
          timer: 1500,
          position: 'top-end',
          backdropf: false,
          showConfirmButton: false,
        })
      } else {
          console.log(inviteData.data);
          console.log("step 2");
          setStep(2)
          setProgress(2)
      }
    }
  },[code]) 

  return (  
    <div className='flex flex-nowrap flex-col gap-3 lg:mt-4 lg:mb-20 sm:w-[75%] w-4/5 mt-2 '>
    { code && inviteData.isSuccess && step < STEP.FOUR && (
      <div role="alert" className="alert gap-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <span className='text-sm leading-tight'>Join This org With An Existing Account?</span>
        <div>
          <button className="btn btn-xs btn-primary" onClick={() => history.push(`/auth/login?code=${code}`)}>Accept</button>
        </div>
  </div>

    )}
    <form className="">
      {isLoaded ? (
        step !== null ? (
          <StudentRegister
            userType={userTypes.STUDENT}
            {...{
              code,
              text, 
              STEP, 
              step, 
              setStep, 
              progress, 
              setProgress, 
              linkOrg, 
              history}}
          />
        ) : (
          <>
            <div className="w-full">
              <span className="text-gray-400">{text.invitationIncorrect}</span>
            </div>
            <div className="mt-8 w-full flex justify-center">
              <span className="text-gray-400">
                {text.haveAccount}{' '}
                <Link className="underline" to="/auth/login">
                  {text.signin}
                </Link>
              </span>
            </div>
          </>
        )
      ) : (
        <div className="w-full flex justify-start">
          <span className="text-gray-400">{text.waiting}</span>
        </div>
      )}
    </form>
    </div>
  );
}

export default withCookies(Signup);
