

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { joinOrg } from '@/apis/base/organizations/joinOrg';


export default function useJoinOrg(props) {
    const {onSuccess, ...otherProps} = props;
    const client = useQueryClient();
    const query = useMutation({
        mutationKey: [ROUTE_IDS.JOIN_ORG],
        mutationFn: joinOrg,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({ queryKey: ['context', ROUTE_IDS.GET_MY_ORGS]});
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return query;
}