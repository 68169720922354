import config from "@/apis/config"
import { getPromise, postPromise } from "@/apis/base"
import { ROUTE_IDS,composeFormData } from "@/apis/base/routeId"
import axios from "axios";

export const getDefaultCuratorFeed = (params) => 
    getPromise(config.API_GATEWAY_URL + '/curator/', {},{
        params: {
            url: config.DEFAULT_CURATOR
        }
    }, false);
