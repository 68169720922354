import React from 'react';


export const MainContainer = ({...props}) => {
    return (
        <div className="w-full min-h-screen bg-primary flex justify-center ">
            <div className="container">
                <div className="w-full h-full px-4 sm:px-6 xl:px-8 mb-5">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

