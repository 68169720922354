import { postPromise } from '../base';
import config from '../config';
import { ROUTE_IDS, addRouteIDToFormData } from './routeId';

const getMyOrgs = () => {
  return postPromise(
    config.API_BASE_URL,
    addRouteIDToFormData(new FormData(), ROUTE_IDS.GET_MY_ORGS)
  );
};

const getAllOrgs = () => {
  return postPromise(
    config.API_BASE_URL,
    addRouteIDToFormData(new FormData(), ROUTE_IDS.GET_ORGANIZATIONS)
  );
};
export {
  getAllOrgs, getMyOrgs
};

