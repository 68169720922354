import React from 'react';
import { MinusIcon } from '../Icons';


export const BasicMinusButton = ({className,iconClassName, disabled=false, ...props}) => {
    return (
        <button {...props} className={`
            btn btn-sm btn-outline btn-circle 
            ${disabled ? 'btn-disabled' : ''}
            ${className}
        `}>
            <MinusIcon  className={`${iconClassName}`}/>
            {props.children}
        </button>
    );
};

