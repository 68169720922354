import React from 'react';
import { Link } from 'react-router-dom';
import { BlackButton } from '../../components/Buttons';


export const ContinueButton = ({handleContinue, text, ...props}) => {
    return (
        <>
            <div className="mt-9 w-full">
                <BlackButton
                variant="contained"
                size="large"
                className="w-full"
                onClick={handleContinue}
                >
                    Continue
                {/* {text.continue} */}
                </BlackButton>
            </div>
            <div className="mt-4 w-full flex justify-center">
                <span className="text-gray-400">
                {text.haveAccount}
                <Link className="underline" to="/auth/login">
                    {text.signin}
                </Link>
                </span>
            </div>
        </>
    );
};

