
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getPopupRequests } from './getPopupRequests';


export default function usePopupRequests({popupid, ...props}) {
    const query = useQuery({
        queryKey: ['popup', popupid, ROUTE_IDS.GET_POPUP_REQUESTS],
        queryFn: (params) => getPopupRequests({popupid}),
        enabled: !!popupid, 
        staleTime: MILLISEC.MIN_15,
        ...props
    });
    return query;
}