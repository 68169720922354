import React, { useRef } from 'react';
import { TrackedImage } from '../Image/TrackedImage';


export const BasicImageDropdown = ({
    src, 
    disableTracking=false, 
    className, 
    imageProps,
    DropdownProps,
    imageClassName ,
    dropdownClassName='w-50' , 
    ...props}) => {
    const buttonRef = useRef()
    function handlePointerEnter(e) {
        buttonRef.current?.focus?.()
    }
    function handlePointerLeave(e) {
        buttonRef.current?.blur?.()
    }
    return (
        <div 
            className={`dropdown dropdown-hover w-fit static ${className}`}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
        >
            <div
                ref={buttonRef}
                role='button'
                tabIndex={0}
                className="focus:bg-blue-200"
            />
            <TrackedImage
                disableTracking={disableTracking}
                src={src}
                className={`w-16 rounded-md ${imageClassName}`}
                {...imageProps}
            />
            <div 
                className={`dropdown-content pointer-events-none mb-1 mx-auto z-[99] shadow-all-light ${dropdownClassName}`}
            > 
                <TrackedImage 
                    disableTracking={disableTracking}
                    src={src} 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.classList.add('hidden')
                    }}
                    className={`w-full bg-white rounded-md`} 
                    {...DropdownProps}
                />
            </div>
        </div>
    );
};

