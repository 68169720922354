import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useCookies, withCookies } from 'react-cookie';
import {
  Redirect,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';


import Admin from '../views/layouts/Admin';
import Auth from '../views/layouts/Auth';
import Brand from '../views/layouts/Brand';
import Main from '../views/layouts/Main';
import Processor from './layouts/Processor';

import { ProtectedRoute } from '@/components/Util/ProtectedRoute';
import { UnprotectedRoute } from '@/components/Util/UnprotectedRoute';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useMyUserData from '@/hooks/state/useMyUserData';
import { getCookie, getPageAvailability } from '@/utils';
import { getRedirectPath } from '@/utils/routerUtils';
import { RedirectHelper } from '@/views/RedirectHelper';
import { Welcome } from './pages/Main/Welcome/Welcome';

function Views(props) {
  const userDataQuery = useMyUserData();
  const orgContextQuery = useCurrentOrgContext();
  // const { data: userInfo } = userDataQuery || {};
  const context = orgContextQuery?.data;
  const [cookies, setCookie, removeCookie] = useCookies(['token','refreshToken','orgIdContext']);
  const [availability, setAvailability] = useState(null)
  
  useEffect(() => {
    document.addEventListener("visibilitychange", checkForExternalCookieChange, {passive:true});
    return () => document.removeEventListener("visibilitychange", checkForExternalCookieChange, {passive:true});
  },[])

  useLayoutEffect(() => {
    if (orgContextQuery.isPending) return
    setAvailability(getPageAvailability(context))
  },[userDataQuery.data, orgContextQuery.data])

  useEffect(() => {
    if (userDataQuery.isSuccess && cookies.orgIdContext == null) 
      setCookie('orgIdContext',userDataQuery.data?.defaultorg,{path:'/'})
  },[userDataQuery.data?.defaultorg, context]) 

  function checkForExternalCookieChange() {
    const trackedCookieNames = ['orgIdContext','token','refreshToken']
    for (const cookiename of trackedCookieNames) {
      const actualCookie = getCookie(cookiename);
      if (actualCookie != null && String(actualCookie) !== String(cookies?.[cookiename])) {
        // console.log('cookie is different', cookiename);
        // console.log(`cookies for ${cookiename} are different`, actualCookie, cookies?.[cookiename]);
        // console.log(getStringDiff(actualCookie,cookies?.[cookiename]));
        setCookie(cookiename, actualCookie,{path:'/'})
      }
    }
  }


  return !orgContextQuery?.isLoading && (
    <>

      <Router>
        <RedirectHelper contextQuery={orgContextQuery}/>
        <Switch>
          <UnprotectedRoute
            path="/welcome"
            render={(props) =>
              context && cookies.token ? (
                <Redirect to='/' />
              ) : (
                <>
                  <Welcome {...props} />
                </>
              )
            }
          />
          <UnprotectedRoute path="/auth" render={(props) => <Auth {...props} />} />
            <ProtectedRoute
              path="/admin"
              render={(props) =>
                context && availability && !availability?.['admin'] ? (
                    <Redirect to='/' />
                ) : (
                    <Admin {...props} />
                )
              }
            />
            <ProtectedRoute
              path="/brand"
              render={(props) =>
                context && availability && !availability?.['brand'] ? (
                  <Redirect to='/' />
                ) : (
                  <Brand {...props} />
                )
              }
            />
            <ProtectedRoute
              path="/processor"
              render={(props) =>
                context && availability && !availability?.['processor'] ? (
                  <Redirect to='/' />
                ) : (
                  <Processor {...props} />
                )
              }
            />
            <ProtectedRoute
              path="/"
              render={(props) =>
                context && !(context?.standardCommunity || ['/account','/organization-preferences'].includes(props.location.pathname))  ? (
                  <>
                    <Redirect
                      to={getRedirectPath(context)}
                      exact
                    />
                  </>
                ) : (
                  <>
                    <Main {...props} />
                    {/* {console.log('redirecting to',getRedirectPath(context),' from ' , pathname)} */}
                  </>
                )
              }
            />
        </Switch>
      </Router>
    </>
  );
}

export default withCookies(Views);
