

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { submitMyWishlist } from '@/apis/base/wishlist/submitMyWishlist';


export default function useSubmitMyWishlist({onSuccess, onError}) {
    const client = useQueryClient();
    const query = useMutation({
        mutationKey: ['user','wishlist', ROUTE_IDS.SUBMIT_MY_WISHLIST],
        mutationFn: (params) => submitMyWishlist(params),
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data,req) => {
            client.invalidateQueries({queryKey: ['user','wishlist']});
            onSuccess?.(data,req)
        },
        onError: onError
    });
    return query;
}