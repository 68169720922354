import Swal from 'sweetalert2'

export const InfoToast = Swal.mixin({
	toast: true,
	position: 'top-end',
	icon: 'info',
	target: '#popuptarget',
	customClass: {
		container: 'absolute top-0 right-0 h-fit ',
        popup: "bg-blue-50",
        timerProgressBar: 'bg-blue-400'
	},
	showConfirmButton: false,
	timerProgressBar: true,
	timer: 2500,
	showClass: {
		popup: 'animate-in-toast'
	},
})

