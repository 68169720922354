
import { getRoleEnum } from '@/apis/base/enum/getRoleEnum';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { queryOptions, useQuery } from '@tanstack/react-query';


export default function useRoleEnum() {
    const query = useQuery(roleEnumQueryConfig());
    return query;
}

export const roleEnumQueryConfig = () => queryOptions({
        queryKey: [ROUTE_IDS.GET_ROLE_ENUM],
        queryFn: getRoleEnum,
        retry: 4,
        refetchInterval: (d) => d.null ? 500 : false,
        staleTime: MILLISEC.HOUR,
        gcTime: MILLISEC.HOUR,
        select
})

function select(data) {
    // console.log('in role enum');
    const newData = {}
    newData.fin = data?.find?.(s => s?.name === 'FIN')?.id
    newData.ambassador = data?.find?.(s => s?.name === 'Ambassador')?.id
    newData.smi = data?.find?.(s => s?.name === 'Social Media Influencer')?.id
    newData.guardian = data?.find?.(s => s?.name === 'Guardian')?.id

    newData.allRoles = [newData.fin, newData.ambassador, newData.smi, newData.guardian]
    return newData
}