import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { closeDialogWithRef, resetFormWithId } from "@/utils";
import { forwardRef } from "react";

export const BasicSubDialog = ({	
    handleSubmit: onSubmit= () => {}, 
    formID, 
    title, 
    children, 
    showCloseButton = true,
    showActions = true,
    className, 
    confirmText='Submit',
	cancelText='Cancel',
	native=false,
	confirmDisabled=false,
	confirmLoading=false,
    onClose = ()=>{},
    customSize = false,
    containerClassName,}) => {

		function handleCancel() {
			resetFormWithId(formID)
			onClose()
            // closeDialogWithRef(ref)
		}

		function handleSubmit(e) {
			e.preventDefault();
			if (native) return onSubmit(e)
			const formData = new FormData(e.target)
			onSubmit(formData,e)
		}


    return (
        <div 
            className={`modal-box overflow-auto max-w-screen-sm md:max-w-none 
                max-h-[95vh]
                ${customSize ? '' : 'px-10 py-3 pb-5'}  ${className}`}>
            <form method="dialog" className="w-full flex justify-end">
                { showCloseButton &&(
                    <button id="nones" className="btn btn-sm sticky btn-circle btn-ghost -mt-2 -mr-20 -right-8 -top-4">✕</button>
                )}
            </form>
            <div className={`flex flex-col  ${customSize ? '' : 'px-6'}`} >
                <div className="flex flex-col ">
                    { title && <h3 className={`font-bold text-xl pb-5`}>{title}</h3>}
                    <form  
                        id={formID}
                        onSubmit={handleSubmit} 
                        className={`w-full max-h-none ${containerClassName}`} 
                    >	
                        {children}
                        { showActions &&
                            <div className=' w-full justify-end flex flex-row  gap-5 pt-2.5'>
                                <BasicRectButton 
                                    type='submit'
                                    className={'btn-secondary'}
                                    disabled={confirmDisabled}
                                    formMethod="dialog"
                                >
                                    { confirmLoading 
                                        ? <div className="loading loading-spinner loading-sm "></div>
                                        : confirmText	
                                    }
                                </BasicRectButton>
                                <BasicRectButton 
                                    type='reset'
                                    className={'btn-neutral'}
                                    onClick={handleCancel}
                                >
                                    {cancelText}
                                </BasicRectButton>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}