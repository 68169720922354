import { postPromise } from '@/apis/base';
import { addRouteIDToFormData, ROUTE_IDS } from '@/apis/base/routeId';
import config from '@/apis/config';
import { jsonToFormData } from '@/utils';
import { composeFormData } from '@/apis/base/routeId';
import { getCookie, setCookie } from '@/utils';

function getMyUserData() {
  return new Promise((resolve, reject) => {
    postPromise(
      config.API_BASE_URL,
      composeFormData({}, ROUTE_IDS.GET_MY_USER_DATA)
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

/**
 *
 * @param {object} formJson
 * @param {object} formJson.user_id
 *
 * @returns
 */
function getUserData(formJson) {
  return postPromise(
    config.API_BASE_URL,
    composeFormData(formJson, ROUTE_IDS.GET_USER_DATA)
  );
}

export { getMyUserData, getUserData };
