import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getOrgSponsorships } from '@/apis/base/organizations/sponsorships/getOrgSponsorships';
import { useCookies } from 'react-cookie';


export default function useOrgSponsorships({orgid}) {
    const [cookies, setCookie, deleteCookie] = useCookies(['orgIdContext']);
    const query = useQuery({
        queryKey: ['sponsorships', 'recipient', orgid ,ROUTE_IDS.GET_ORG_SPONSORSHIPS],
        queryFn: (params) => getOrgSponsorships({orgid}),
        enabled: orgid != null,
        staleTime: MILLISEC.MIN_15,
    });
    return query;
}