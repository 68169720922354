import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getGeoData, honestGeoData } from '@/apis/base/misc';
import { getAllBrands } from '@/apis/base/organizations/brands/getAllBrands';

export default function useAllBrands(props) {
  const geoQuery = useQuery({
    queryKey: [ROUTE_IDS.GET_BRANDS],
    queryFn: getAllBrands,
    staleTime: MILLISEC.MIN_15,
    ...props
  });
  return geoQuery;
}
