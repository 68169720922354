
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';


import {
    sendVerifyEmail
} from '../../../../apis/base/auth';
import { useAppContext } from '../../../../context/AppContext';
import { useTranslations } from '../../../../hooks/translations';
import RegisterContainer from './components/RegisterContainer';
// import { CitySelect, CountrySelect, RegionSelect } from "@/components/CountryRegionSelect";
import useSignUpOrg from '@/hooks/state/organization/useSignUpOrg';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { ContactRegister } from './components/ContactRegister';
import { OrgRegister } from './components/OrgRegister';
import { OrgTypePicker } from './components/OrgTypePicker';
import { MasterAdminRegisterStepper } from './components/Steppers';
import { UserRegister } from './components/UserRegister';
import { Stepper } from '@/views/pages/Auth/Signup/components/Stepper';
import { useCookies } from 'react-cookie';

const STEP = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};
const PROGRESS = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

function BrandSignup(props) {
  const { history, location } = props;
  const [cookies, setCookie] = useCookies(['authBGIndex'])
  const params = new URLSearchParams(location.search);
  // const join_org = params.get("join");
  const text = useTranslations('signup');
  const code = params.get('code');
  const orgId = params.get('orgid');
  const { setLoading, setMessage } = useAppContext();
  const [isLoaded, setIsLoaded] = useState(true);
  const [step, setStep] = useState(STEP.ONE);
  const [progress, setProgress] = useState(PROGRESS.ONE);
  const [error, setError] = useState(null);

  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    orgtype: '',
    phonenumber: '',
    countryid: '27003',
    cityid: '',
    regionid: '',
    orgcountryid: '27003',
    orgregionid: '',
    orgcityid: '',
    orgname: '',
    orgpostalcode: '',
    password: '',
    website: '',
  });
  const [validations, setValidations] = useState({
    code: '',
  });

  const signupMutation = useSignUpOrg({
    onSuccess: (res) => {
      if (res?.error === 'Email already exists!!!') throw new Error('Email Already Exists')
      if (res?.status === 'Member has not signed up.') throw new Error('Org Creation Failure')
      SuccessToast.fire({text: text.createOrgSuccess,});
      setStep(step + 1);
      setProgress(progress + 1);
    },
    onError: (err) => {
      FailPopup.fire({
        title: 'Oops...',
        text: text.failedCreateOrganization,
      });
      setError(err);
    },
  });

  useLayoutEffect(() => {
    let bgIndex = cookies.authBGIndex
    if (step === STEP.ONE) {
      bgIndex = 0
    }
    if (step === STEP.TWO || step === STEP.THREE || step  === STEP.FOUR) {
      bgIndex = 1
    }
    if (step === STEP.FIVE) {
      bgIndex = 2
    }
    if (bgIndex !== cookies.authBGIndex) {
      setCookie('authBGIndex', bgIndex, { path: '/' })
    }
    
  },[step])  

  const checkProgress = useCallback((progress) => {
    switch (progress) {
      case 0:
        setStep(1);
        break;
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        setProgress(2);
        break;
      case 3:
        setStep(4);
        setProgress(3);
        break;
      case 4:
        setStep(6);
        setProgress(4);
        break;
      case 5:
        setStep(7);
        setProgress(5);
        break;
      default:
        break;
    }
  }, []);

  // Fetch initial values

  const handleChange = (prop, value) => {
    setValidations((prevState) => ({ ...prevState, [prop]: '' }));
    setValues((prevState) => ({ ...prevState, [prop]: value }));
  };

  const handleUserFormSubmit = (values) => {
    setValues((prev) => ({...prev, ...values}));
    setStep(step + 1);
    setProgress(progress + 1);
  }

  const  handleOrgFormSubmit = (values) => {
    setValues((prev) => ({...prev, ...values}));
    setStep(step + 1);
    setProgress(progress + 1);  
  }

  const handlePhoneSubmit = (phone) => {
    setValues((prev) => ({...prev, ...phone}));
    setStep(step + 1);
    setProgress(progress + 1); 
  }

  const handleOrgTypeSubmit = (type) => {
    setValues((prev) => ({...prev, orgtype: type}));
    handleCreateOrganization({
      ...values,
      orgtype: type,
      invitecode: code,
    })
  }


  const handleCreateOrganization = (params) => {
    signupMutation.mutate(params)
  };

  const handleResendLink = () => {
    sendVerifyEmail(code)
      .then((res) => {
      })
      .catch((err) => {
        setMessage({
          open: true,
          title: text.error,
          description: text.failedSendEmailLink,
        });
      });
  };
  const handleContinue = () => {
    switch (step) {
      case STEP.TWO:
        if (values.orgname === '')
          setValidations((prevState) => ({
            ...prevState,
            orgname: 'has-empty',
          }));
        else if (values.address === '')
          setValidations((prevState) => ({
            ...prevState,
            orgaddress: 'has-empty',
          }));
        else if (values.orgcityid === '')
          setValidations((prevState) => ({ ...prevState, orgcityid: 'has-empty' }));
        else if (values.orgregionid === '')
          setValidations((prevState) => ({ ...prevState, state: 'has-empty' }));
        else if (values.orgpostalcode === '')
          setValidations((prevState) => ({
            ...prevState,
            orgpostalcode: 'has-empty',
          }));
        else if (values.orgcountryid === '')
          setValidations((prevState) => ({
            ...prevState,
            orgcountryid: 'has-empty',
          }));
        else {
          setProgress(progress + 1);
          setStep(step + 1);
        }

        break;

      case STEP.THREE:
        if (values.phonenumber === '')
          setValidations((prevState) => ({
            ...prevState,
            phonenumber: 'has-empty',
          }));
        else if (values.phonenumber.length < 10)
          setValidations((prevState) => ({
            ...prevState,
            phonenumber: 'has-error',
          }));
        else {
          // TODO phone numbe verification
          // handleCreateOrganization();
          // handleSendSMS();
          setStep(step + 1);
          setProgress(progress + 1);
        } 
        break;
      case STEP.FOUR:
        if (values.code.length < 6)
          setValidations((prevState) => ({ ...prevState, code: 'has-error' }));
        else {
          // TODO verify code
        }
        break;
      case STEP.FIVE:
        history.push('/auth/login');
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* <OrgTreeView /> */}
      <form className="lg:mt-12 lg:mb-10 sm:w-3/5 w-4/5 ">
        {isLoaded ? (
          step !== null ? (
            <div className='h-fit'>
              <RegisterContainer>
                {step === STEP.ONE && (
                  
                  <UserRegister 
                    {...{
                      onSubmit: handleUserFormSubmit,
                      code,
                      includePhone: false,
                      history,
                      text}}
                  />
                )}
                {step === STEP.TWO && (
                  <OrgRegister
                    onSubmit={handleOrgFormSubmit}
                    text={text}
                   />
                )}
                {step === STEP.THREE && (
                  <ContactRegister
                    onSubmit={handlePhoneSubmit}
                    text={text}
                  />
                )}
                {
                  step === STEP.FOUR && (
                    <div className='h-96 overflow-y-visible '>
                      <OrgTypePicker text={text} onSubmit={handleOrgTypeSubmit}/>
                    </div>
                  )
                }
                {/* TODO submit  */}
                {/* {step === STEP.FOUR && (
                  <>
                    <div className="w-full">
                      <p className="text-3xl font-semibold">{text.enterCode}</p>
                    </div>
                    <div className="mt-9 w-full">
                      <CodeInput
                        error={validations.code === 'has-error'}
                        autoFocus
                        onChange={(val) => handleChange('code', val)}
                      />
                      {validations.code === 'has-error' && (
                        <span className="ml-2 text-xs text-red-bright">
                          {text.codeIncorrect}
                        </span>
                      )}
                    </div>
                    <div className="mt-9 w-full">
                      <BlackButton
                        variant="contained"
                        size="large"
                        className="w-full"
                        onClick={() => handleContinue()}
                      >
                        {text.continue}
                      </BlackButton>
                    </div>
                  </>
                )} */}
                { step === STEP.FIVE && (
                  <>
                    <div className={'flex flex-col h-full'}>
                      <div className="w-full">
                        <p className="text-3xl font-semibold">{text.thankyou}</p>
                      </div>
                      <div className="mt-4 w-full">
                        <p className="text-gray-400">{text.linkSent}</p>
                      </div>
                      <div className="mt-4 w-full h-full">
                        <p className="text-gray-400">{text.checkEmail}</p>
                      </div>
                      {/* <div className="mt-14 w-full">
                        <GrayButton variant="contained" size="large" className="w-full" onClick={() => handleContinue()}>{text.resendLink}</GrayButton>
                      </div> */}
                    </div>
                    {/* <div className="w-full">
                      <p className="text-3xl font-semibold">
                        {text.registrationSuccess}
                      </p>
                    </div>
                    <div className="mt-4 w-full">
                      <p className="text-gray-400">{text.registeredSuccess}</p>
                    </div>
                    <div className="mt-14 w-full">
                      <BlackButton
                        variant="contained"
                        size="large"
                        className="w-full"
                        onClick={() => handleContinue()}
                      >
                        {text.login}
                      </BlackButton>
                    </div> */}
                  </>
                )}
                <div className='mt-auto mb-20'>
                  <div className="mt-8 w-full flex justify-center">
                    {<Stepper numSteps={5} activeStep={step} onStepClicked={setStep} />}
                  </div>
                  <div className='flex gap-2 items-end text-sm w-full justify-center mt-5'>
                    Powered by <img className='mb-1' width={80}  src="/assets/images/logo-unity-11.png" alt="unity11 logo" />
                  </div>
                </div>
              </RegisterContainer>
            </div>
          ) : (
            <>
              <div className="w-full">
                <span className="text-gray-400">
                  {text.invitationIncorrect}
                </span>
              </div>
              <div className="mt-8 w-full flex justify-center">
                <span className="text-gray-400">
                  {text.haveAccount}{' '}
                  <Link className="underline" to="/auth/login">
                    {text.signin}
                  </Link>
                </span>
              </div>
            </>
          )
        ) : (
          <div className="w-full flex justify-start">
            <span className="text-gray-400">{text.waiting}</span>
          </div>
        )}
      </form>
    </>
  );
}

export default BrandSignup;
