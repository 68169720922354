
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { leaveOrg } from '@/apis/base/organizations/leaveOrg';
import { getCookie, setCookie } from '@/utils';


export default function useLeaveOrg(props) {
    const {onSuccess, ...otherProps} = props || {};
    const client = useQueryClient();
    const query = useMutation({
        mutationKey: [ROUTE_IDS.LEAVE_ORG],
        mutationFn: (params) => leaveOrg(params),
        onSuccess: (data, req) => {
            client.invalidateQueries({ queryKey : ['context', 'org', req?.orgid]})
            client.invalidateQueries({ queryKey : ['org', req?.orgid]})
            client.invalidateQueries({ queryKey : ['context', ROUTE_IDS.GET_MY_ORGS], exact:false})
            client.invalidateQueries({ queryKey : ['context', ROUTE_IDS.GET_MY_ORGS, 'data'], exact:false})
            // client.setQueryData(
            //     ['context', ROUTE_IDS.GET_MY_ORGS, 'data'],
            //     (old) => {
            //         const targetIndex = old.findIndex((org) => org.orgid === req?.orgid)
            //         if (targetIndex > -1) {
            //             return old.splice(targetIndex, 1)
            //         }
            //     }
            // )
            // client.setQueryData(
            //     ['context', ROUTE_IDS.GET_MY_ORGS],
            //     (old) => {
            //         const targetIndex = old.findIndex((orgID) => orgID === req?.orgid)
            //         if (targetIndex > -1) 
            //             return old.splice(targetIndex, 1)
            //     }
            // )
            onSuccess?.(data, req);
        },
        staleTime: MILLISEC.MIN_15,
        ...otherProps
    });
    
    return query;
}