import { BasicDetailRow } from '@/components/Label/BasicDetailRow';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { getUserLabelInOrg } from '@/utils';
import React from 'react';
import { BadgeCell } from '../../Tables/Cells/BadgeCell';


export const UserInfoDetail = ({user, showUserType=true}) => {
    const ctxQuery = useCurrentOrgContext();
    return (
        <div className="flex flex-col">
            <BasicDetailRow label="First Name" value={user?.['first name']}/>
            <BasicDetailRow label="Last Name" value={user?.['last name']}/>
            <BasicDetailRow label='Occupation' value={user?.occupation}/>
            <BasicDetailRow label="Email" value={user?.email}/>
            <BasicDetailRow 
                label='Active' 
                value={
                    <BadgeCell isSuccess label={user.status === '1' ? 'Active' : 'Inactive'} />
                }/>
            <BasicDetailRow label="Phone" value={user?.['phone number']}/>
            { showUserType &&
                <BasicDetailRow label="Type" value={getUserLabelInOrg(user, ctxQuery.data)}/>
            }
        </div>
    );
};


