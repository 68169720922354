
import { CardActions, CardContent, Divider, Select } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { WhiteCard } from "../../../../../../components/Cards";
import CustomCheckbox from "../../../../../../components/CustomCheckbox";
import { SelectInput, TextInput } from "../../../../../../components/Inputs";
import { useAppContext } from "../../../../../../context/AppContext";
import { getDateString, getMonthDayString } from "../../../../../../utils";
import AddDependent from "./AddDependent";

import useSubmitBaseRole from "@/hooks/state/application/useSubmitBaseRole";
import useSubmitRoleApp from "@/hooks/state/application/useSubmitRoleApp";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { Popup } from "@/wrappers/swal/Popup";

import useCancelRoleApp from "@/hooks/state/application/useCancelRoleApp";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { queryResultFns } from "@/wrappers/swal/queryResultFns";
import { BasicGrayButton } from "@/components/Buttons/BasicGrayButton";
import { BlueOutlineButton } from "@/components/Buttons/BlueOutlineButton";
import { GrayOutlineButton } from "@/components/Buttons/GrayOutlineButton";
import { BasicBlueRectButton } from "@/components/Buttons/BasicBlueRectButton";
import { FailPopup } from "@/wrappers/swal/FailPopup";

function FamilyInNeedApplication({baseFin, prevFinApp, text, org_type, expiry_date, isGuardian}) {
  const { setLoading, setMessage } = useAppContext();
  const [finForm, setFinForm] = useState(null);
  const [editing,setEditing] = useState(false)
  const [hasAssistance, setHasAssistance] = useState(false);
  const [dependents, setDependents] = useState([]);
  const [agreed, setAgreed] = useState(false)
  const contextQuery = useCurrentOrgContext({})
  const roleEnumQuery = useRoleEnum()
  const appStatusQuery = useAppStatusEnum()
  const baseFinMutation = useSubmitBaseRole({
    onSuccess: () => finAppMutation.mutate({roleid: roleEnum.fin, orgid: context.orgid}),
    onError: () => {
      FailPopup.fire({text: 'Failed to submit FIN application'})
      setEditing(false)
    }
  })
  const cancelAppMutation = useCancelRoleApp(
      queryResultFns(text.succeedToCancelFin, text.failedToCancelFin))
  const context = contextQuery.data
  const finAppMutation = useSubmitRoleApp({
      ...queryResultFns(text.succeedToApplyFIN, 'Failed to submit FIN application'),
      onSettled: () => setEditing(false)
  })
  
  const appStatuses = appStatusQuery.data
  const appDeclined = prevFinApp?.status === appStatuses?.declined
  const roleEnum = roleEnumQuery.data
  const appsOpen = context?.finactive === "1"

  const onAddDependent = () => {
    setDependents([...dependents, {
      firstname: '',
      lastname: '',
      email: '',
      gender: 'Male',
      age: '',
      relationship: ''
    }]);
  }

  const onRemoveDependent = (idx) => {
    setDependents([
      ...dependents.slice(0, idx),
      ...dependents.slice(idx + 1)
    ]);
  }

  const onChangeDependent = (idx, key, value) => {
    setDependents([...dependents.slice(0, idx),
      {...dependents[idx], [key]: value},
      ...dependents.slice(idx + 1)]);
  }

  const validateDependent = () => {
    for (let dependent of dependents) {
      if (dependent.firstname === '' || dependent.lastname === '' || dependent.age === '' || dependent.relationship === '' || dependent.gender === '') {
        return false;
      }
    }
    return true;
  }


  const cancelApplication = () => { 
    if (prevFinApp?.status === appStatuses.pending) 
      cancelAppMutation.mutate({roleid: roleEnum.fin, orgid: context.orgid})
  }

  const onSubmit = () => {
    console.log('date',finForm.dob);
    const requestdetails = {
      ...finForm,
      dependents: dependents,
      dob: finForm.dob
    }
    const baseParams = {
        roleid: roleEnum.fin,
        ...requestdetails
    }
    baseFinMutation.mutate(baseParams)
  }

  const handleChange = (name, value) => {
    if (name === 'dob') console.log('dob',value);
    switch (name)  {
      default:
        setFinForm({...finForm, [name]: value});  
    }
  }

  console.log(agreed, hasAssistance, finForm, dependents);

  useEffect(() => {
      // Toast.fire({icon: 'success', title: text.succeedToApplyFIN})
          setFinForm({
          provider: "",
          studentid: "",
          gender: 'Male',
          dob: "",
          dependents: {}
        })}
    ,[])
    return (
      <>
        { finForm  && appStatuses && (
            <div id="request-products" className="mt-0.5">
              {prevFinApp?.status === appStatuses.pending ? (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.requestProducts}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.pendingRequestProducts}</p>
                          <div className="xs:flex items-center mt-12.5 my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.status}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="w-fit-content bg-yellow-cornsilk text-yellow-broom rounded-6 text-center py-px px-2.5">{text.pending}</p>
                            </div>
                          </div>
                          <div className="xs:flex items-center my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.submitted}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="font-medium">{getDateString(prevFinApp?.timestamp)}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                      <BasicGrayButton onClick={() => cancelApplication()}>{text.cancel}</BasicGrayButton>
                    </CardActions>
                  </WhiteCard>
              ) : prevFinApp?.status === appStatuses.approved ? (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.requestProducts}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.successedRequestProducts}</p>
                          <div className="xs:flex items-center mt-12.5 my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.status}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="w-fit-content bg-green-honeydew text-green-lime rounded-6 text-center py-px px-2.5">{text.approved}</p>
                            </div>
                          </div>
                          <div className="xs:flex items-center my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.expiryDate}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="font-medium">{getMonthDayString(context?.roleexpiry)}</p>
                            </div>
                          </div>
                          {/*<div className="xs:flex items-center my-4">*/}
                          {/*  <div className="flex-2">*/}
                          {/*    <p className="font-light">{text.expires}:</p>*/}
                          {/*  </div>*/}
                          {/*  <div className="flex-5">*/}
                          {/*    <p className="font-medium">March 26, 2022</p>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          {/*<div className="flex-3" />*/}
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                      <BasicGrayButton  disabled onClick={() => cancelApplication()}>{text.cancel}</BasicGrayButton>
                    </CardActions>
                  </WhiteCard>
              ) : prevFinApp?.status === appStatuses.declined ? (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.requestProducts}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.declinedRequestProducts}</p>
                          <div className="xs:flex items-center mt-12.5 my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.status}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="w-fit-content bg-red-misty-rose text-red-strong rounded-6 text-center py-px px-2.5">{text.declined}</p>
                            </div>
                          </div>
                          <div className="xs:flex items-center my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.declined}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="font-medium">{prevFinApp.modified}</p>
                              {/* TODO: add fin reject date */}
                            </div>
                          </div>
                          <div className="flex-3" />
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                      <div className="indicator">
                        { !appsOpen || appDeclined && 
                        <div 
                          className={`mb-2 ml-2 indicator-item badge  ${appDeclined ? 'text-red-700 bg-red-200' : 'badge-warning'}`}
                        >
                          { appDeclined
                            ? 'Application Declined'
                            : 'Applications Closed'
                          }
                        </div>}
                      <BasicGrayButton 
                        disabled={!appsOpen || appDeclined} onClick={() => setEditing(true)}>{text.openApplication}</BasicGrayButton>
                      </div>
                    </CardActions>
                  </WhiteCard>
              ) : (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.requestProducts}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.noRequestProducts}</p>
                          {Boolean(editing) && (
                              <>
                                <div className="mt-12.5 my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.indicateSocialAssistance}</p>
                                    </div>
                                    <div className="flex-4">
                                      {hasAssistance ? (
                                          <div className="flex">
                                            <div>
                                              <BlueOutlineButton className={'!btn-sm'} onClick={() => setHasAssistance(true)}>{text.yes}</BlueOutlineButton>
                                            </div>
                                            <div className="ml-2">
                                              <GrayOutlineButton className={'!btn-sm'} onClick={() => setHasAssistance(false)}>{text.no}</GrayOutlineButton>
                                            </div>
                                            <span className="text-red-strong ml-2">*</span>
                                          </div>
                                      ) : (
                                          <div className="flex">
                                            <div>
                                              <GrayOutlineButton className={'!btn-sm'} onClick={()  => setHasAssistance(true)}>{text.yes}</GrayOutlineButton>
                                            </div>
                                            <div className="ml-2">
                                              <BlueOutlineButton className={'!btn-sm'} onClick={() => setHasAssistance(false)}>{text.no}</BlueOutlineButton>
                                            </div>
                                            <span className="text-red-strong ml-2">*</span>
                                          </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {hasAssistance && (
                                    <div className="my-4">
                                      <div className="xs:flex items-end">
                                        <div className="flex-3">
                                          <p className="font-light">{text.assistOrganization}:</p>
                                        </div>
                                        <div className="flex-4 flex">
                                          <TextInput className="flex-1" value={finForm.provider} onChange={e => handleChange('provider', e.target.value)} />
                                          <span className="text-red-strong ml-2">*</span>
                                        </div>
                                      </div>
                                      <div className="xs:flex items-center">
                                        <div className="flex-3" />
                                        <div className="flex-4">
                                          <p className="pt-1 text-xs text-gray-normal leading-loose">{text.assistOrganizationDescription}</p>
                                        </div>
                                      </div>
                                    </div>
                                )}
                                <div className="my-4">
                                      <div className="xs:flex items-center">
                                        <div className="flex-3">
                                          <p className="font-light">{text.studentDescription}</p>
                                        </div>
                                        <div className="flex-4 flex">
                                          <TextInput className="flex-1" value={finForm.studentid} onChange={e => handleChange('studentid', e.target.value)} />
                                          <span className="text-white ml-2">*</span>
                                        </div>
                                      </div>
                                    </div>
                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.dob}:</p>
                                    </div>
                                    <div className="flex-4 flex">
                                      <TextInput className="flex-1" type="date" value={finForm.dob} onChange={e => handleChange('dob', e.target.valueAsNumber)} />
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                  </div>
                                  <div className="xs:flex items-center">
                                    <div className="flex-3" />
                                    <div className="flex-4">
                                      <p className="pt-1 text-sm text-gray-normal leading-loose">{text.dobDescription}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.gender}:</p>
                                    </div>
                                    <div className="flex-4 flex">
                                      <Select className="flex-1"
                                          native
                                          value={finForm.gender}
                                          onChange={e => handleChange('gender', e.target.value)}
                                          input={<SelectInput />}
                                      >
                                        <option value={text.male}>{text.male}</option>
                                        <option value={text.female}>{text.female}</option>
                                        <option value={text.notDisclosed}>{text.notDisclosed}</option>
                                      </Select>
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                  </div>
                                  <div className="xs:flex items-center">
                                    <div className="flex-3" />
                                    <div className="flex-4">
                                      <p className="pt-1 text-sm text-gray-normal leading-loose">{text.genderDescription}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.dependents}:</p>
                                    </div>
                                    <div className="flex-4">
                                      <AddCircle onClick={onAddDependent}/>
                                    </div>
                                  </div>
                                  <div className="xs:flex items-center mt-3">
                                    <div className="flex-3"/>
                                    <div className="flex-4">
                                      <AddDependent text={text} dependents={dependents} onChange={onChangeDependent} onRemove={onRemoveDependent}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="xs:flex items-center my-4">
                                  <div className="flex-7">
                                    <CustomCheckbox
                                        checked={Boolean(agreed)}
                                        onChange={(v) => {setAgreed(v)}}
                                    />
                                    <span className="ml-2 text-sm">{text.finAgreeDescription}</span>
                                  </div>
                                </div>
                              </>
                          )}
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                      {editing ? (
                          <div className="w-full xl:flex">
                            <div className="flex-7 flex justify-between">
                              <BasicGrayButton onClick={() => setEditing(false)}>{text.cancel}</BasicGrayButton>
                              <BasicBlueRectButton disabled={!agreed || (hasAssistance && !finForm?.provider?.length) || !finForm?.gender?.length || !String(finForm?.dob?.length) > 0 || !validateDependent()} onClick={() => onSubmit('fin')}>
                                {text.submit}
                              </BasicBlueRectButton>
                            </div>
                            <div className="flex-3" />
                          </div>
                      ) : (
                        <div className="indicator">
                          { !appsOpen && <div className="mb-2 ml-2 indicator-item badge badge-warning">Applications Closed</div>}
                          <BasicGrayButton disabled={!appsOpen} onClick={() => setEditing(true)}>{text.openApplication}</BasicGrayButton>
                        </div>

                      )}
                    </CardActions>
                  </WhiteCard>
              )}
            </div>
        )}
      </>
  )
}

export default FamilyInNeedApplication;
