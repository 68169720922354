import { BasicImageDropdown } from '@/components/dropdowns/BasicImageDropdown';
import { WishlistDropdown } from '@/components/dropdowns/WishlistDropdown';
import React from 'react';

// TODO support multiple images
export const WishlistProductCell = ({name, imgID, images, className, ...props}) => {
    console.log('images',images);
    return imgID ? (
        <div 
            className={`flex flex-col md:flex-row items-start md:items-center gap-2 w-fit ${className} flex-shrink-0 min-w-[auto] min-h-fit my-1 `} 
            {...props}
        >
            <WishlistDropdown 
                images={images}
                numImages={1}
            />
            <span className='font-semibold text-sm lg:text-lg  mt-2  w-fit'>{name}</span>
        </div>
    ) : (
        <>
            <div className="skeleton w-20 h-14"/>
            <div className="mt-2 skeleton w-20 h-4"/>
        </>
    )
};
