import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { CheckIcon } from '@/components/Icons';
import { BasicSearchBar } from '@/components/Input/BasicSearchBar';
import { Add } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';


export const LocalMultiSelect = ({region, handleSubmit, close}) => {
    const [selectedCities, setSelectedCities] = useState(region?.cities ?? []);
    const [unselectedCities, setUnselectedCities] = useState(
        region.subnodes?.length > 0 ?
        region?.subnodes.filter(c => 
            !selectedCities?.includes?.(c?.id)
        ) : []
    );
    const [searchValue, setSearchValue] = useState('')

    const filteredSelectedCities = useMemo(() => {
        return searchValue?.length > 0 ?
            selectedCities.filter(c => c?.name.toLowerCase()?.includes?.(searchValue.toLowerCase()))
                .sort((c1,c2) => c1.name[0] > c2.name)
                : selectedCities?.length > 0 ?
                    selectedCities
                    .sort((c1,c2) => c1.name[0] > c2.name)
                    : []

            },[selectedCities, searchValue])
            
    const filteredUnselectedCities = useMemo(() => {
        return searchValue?.length > 0 ?
            unselectedCities.filter(c => c?.name.toLowerCase()?.includes?.(searchValue.toLowerCase()))
                .sort((c1,c2) => c1.name[0] > c2.name)
                : unselectedCities?.length > 0 ?
                    unselectedCities
                        .sort((c1,c2) => c1.name[0] > c2.name)
                    : []
    }
    ,[unselectedCities, searchValue])


    return (
        <div className='flex flex-col h-full justify-stretch px-5 pb-10'>

            <div className="flex flex-row justify-between items-center">
                <span className='text-2xl font-bold'>
                    {`${region?.name} Availability`}
                </span>
                <div className="flex flex-row justify-center gap-2 mt-7">
                    <BasicRectButton customSize className='btn-secondary pl-6 text-lg whitespace-nowrap flex flex-row  justify-between' onClick={() => 
                        handleSubmit({regionid: region.id, cities: selectedCities
                        .map(c=>c.id)})
                    }>
                        Confirm 
                        <CheckIcon className='ml-5'/>
                    </BasicRectButton>
                    <BasicRectButton className='btn-neutral ' onClick={() => close()}>
                        Cancel
                    </BasicRectButton>

                </div>
            </div>

            <div className='flex flex-row w-full mt-4 gap-10 justify-between h-full'>
                <div className='w-2/5 flex relative h-full overflow-visible '>

                    <div className='absolute w-full  top-0 text-lg tracking-wide'> 
                        <BasicSearchBar 
                            inputClassName={'w-100'}
                            className={'w-full'}
                            value={searchValue}
                            placeholder={`Search cities in  ${region?.name}`}
                            iconClassName='text-blue-cornflower' 
                            onChange={setSearchValue}
                    />
                    </div>
                    <div className='flex flex-col gap-3   w-full mt-20 overflow-auto h-[60vh] '>
                            {   filteredUnselectedCities?.length > 0  &&
                                filteredUnselectedCities.map((city, index) => (
                                <UnselectedCity key={index} city={city} onClick={() => {
                                    setSelectedCities([...(selectedCities || []), city]);
                                    setUnselectedCities(unselectedCities.filter(c => c.id !== city.id));
                                }}/>
                            ))}
                    </div>
                </div>

                
                <div className='w-2/5 flex relative overflow-visible h-[60vh]'>
                    <div className='absolute w-full  top-10 text-lg tracking-wide'> 
                        Eligible
                        <div className='w-full h-[1.31px] bg-gray-400'/>
                    </div>
                    
                    <div className='flex flex-col gap-3   w-full mt-[5.2rem] overflow-auto '>
                        {   selectedCities?.length > 0  &&
                            selectedCities.map((city, index) => (
                            <SelectedCity key={index} city={city} onClick={() => {
                                setSelectedCities(selectedCities.filter(c => c.id !== city.id));
                                setUnselectedCities([...(unselectedCities ?? []), city]);
                            }}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const UnselectedCity = ({city, onClick}) => (
    <div className='flex justify-between items-center  border border-solid border-gray-300 rounded-xl py-2 px-4 hover:cursor-pointer' onClick={onClick}>
        <span className='text-lg'>{city?.name}</span>
    <div className='h-5'></div>
        <Add className='w-5 h-5 text-blue-cornflower' >Add</Add>
    </div>
)

const SelectedCity = ({city, onClick}) => (
    <div className='flex h-12 justify-between items-center  border border-solid border-blue-cornflower rounded-xl py-2 px-4 hover:cursor-pointer' onClick={onClick}>
        <span>{city?.name}</span>
        <div className="relative h-full place-self-center  align-middle">
            <div className="absolute text-[18px] -right-1 w-[1.2rem] h-[1.2rem]   -top-[1.5px]  text-blue-cornflower hover:cursor-pointer"  >✕</div>
        </div>
    </div>
)