
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getUserData } from '@/apis/user/userData';

export default function useUserData({userids}) {
    const query = useQuery(userDataQueryConfig({userids}));
    return query;
}

export function userDataQueryConfig({userids}) {
    return {
        queryKey: ['user', userids, ROUTE_IDS.GET_USER_DATA],
        queryFn: () => getUserData({'userids[]': userids}),
        staleTime: MILLISEC.MIN_15,
        enabled: !!userids,
    }
}