

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation,useQueryClient } from '@tanstack/react-query';
import { modPopupRequest } from '@/apis/base/donation/popupStore/modPopupRequest';

export default function useModPopupRequest(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_POPUP_REQUEST],
        mutationFn: modPopupRequest,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['popup', req.popupid]})
            onSuccess && onSuccess(data)
        },
        ...otherProps
    });
    return mutation;
}