import { BasicTable } from '@/components/Tables/BasicTable';
import { EditShipmentCols } from '@/components/Tables/EditShipmentTable/EditShipmentCols';
import React, { useMemo } from 'react';

export const EditShipmentTable = ({
    canEditRequest, 
    canEditShipment, 
    eventid, 
    onInlineChange,
    items,
    ...props}) => {
        const tableData = useMemo(() => Object.values(items),[items])
    return (
        <BasicTable
            data={Object.values(items)}
            columnHelper={EditShipmentCols}
            meta={{canEditRequest, canEditShipment, eventid, onInlineChange}}
        >
        </BasicTable>
    );
};
