import { PageTitle } from '@/components/Label/PageTitle';
import { useTranslations } from '@/hooks/translations';
import Brand from '@/views/pages/Admin/Brand/components/pages/Brand';
import React from 'react';

function BrandManagement() {
  const text = useTranslations('brandManagement');

  return (
    <div className="w-full">
        <PageTitle>{text.title}</PageTitle>
        <Brand text={text}/>
    </div>
  );
}

export default BrandManagement;
