import config from '@/apis/config';
import axios from 'axios';
import { ROUTE_IDS, composeFormData } from '../routeId';

const login = (params) => {
  return new Promise((resolve, reject) => {
    const tempApi = axios.create();
    const formData = composeFormData(params, ROUTE_IDS.LOGIN);
    tempApi
      .post(config.API_BASE_URL, formData, {})
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { login };
