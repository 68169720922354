	import { uploadEventImages } from '@/apis/base/lambda/uploadImages';
import { NumberInput } from '@/components/Input/NumberInput';
import useCreateBDEvent from '@/hooks/state/donation/useCreateBDEvent';
import useModBDEvent from '@/hooks/state/donation/useModBDEvent';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import useOrgsByRole from '@/hooks/state/organization/useOrgsByRole';
import { addCommas, closeDialogWithRef, filesToDataUri, getDashedDateString, getEventUrl, numberWithCommas, objectFromForm, rangeArray, subObject } from '@/utils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslations } from '../../hooks/translations';
import { BasicDateInput } from '../Input/BasicDateInput';
import { BasicFileInput } from '../Input/BasicFileInput';
import { BasicInput } from '../Input/BasicInput';
import { BasicOrgDropdown } from '../Input/BasicOrgDropdown';
import { BasicTextArea } from '../Input/BasicTextArea';
import { BasicToggle } from '../Input/BasicToggle';
import { ImageUploadAuditor } from '../Misc/ImageUploadAuditor';
import { EventCardMockup } from '../Mockups/EventCardMockup';
import { BasicMultiDialog } from './MultiDialog/BasicMultiDialog';
import { BasicSubDialog } from './MultiDialog/BasicSubDialog';
import { BasicNumberInput } from '@/components/Input/BasicNumberInput';
import { MILLISEC } from '@/constants';

const today = new Date();

const CreateDonationEventDialog = ({
	canEdit,
	isExisting,
	data,
	open,
	onFinish,
	partialEdit,
	dialogRef
}) => {
	const imageRef = useRef();

	const [resetDropdowns, setResetDropdowns] = useState(false);

	const contextQuery = useCurrentOrgContext()
	const ctx = contextQuery.data
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState({
		orgid: data.orgid || ctx?.orgid || '',
		processor: data?.processor || ctx?.orgid || '',
		charity: data?.charity || '',
		name: '',
		description: '',
		peritemreport: false,
		price: 0,
		weight: 0,
		starttime: '',
		endtime: '',
		images: [],
	})
	const text = useTranslations('brandDonation');
	const client = useQueryClient()
	const hostQuery = useOrgWithId({org_id: values.orgid, recursive: 0})
	const processorQuery = useOrgsByRole({role: 'processor'})
	const charityQuery = useOrgsByRole({role: 'charity'})
	const brandOrgsQuery = useOrgWithId({org_id: contextQuery.data?.orgid, recursive: 1});
	const UICBrandOrgsQuery = useOrgsByRole({role: 'brand'})
	const donationMutation = useCreateBDEvent({
		onSuccess: async (res, req) => {
			if (res?.status === "donation event not created")
				throw new Error("Failed to create event")
			if ((imageRef?.current?.files?.length > 0))
				await uploadEventImages({
					client,
					orgID: req.orgid, 
					eventID: res.eventid, 
					userID: contextQuery.data.userid, 
					uploads: Array.from(imageRef?.current?.files)})
			SuccessToast.fire({text: "Donation event created"})
			handleFormFinished()
		},
		onError: () => {
			closeDialogWithRef(dialogRef)
			FailPopup.fire({text: "Donation event creation failed!"})},
	})
	const editMutation = useModBDEvent({
		onSuccess: async (data, req) => {
			if (imageRef.current?.files?.length > 0)
				await uploadEventImages({
					client,
					orgID: values.orgid, 
					eventID: req.eventid, 
					userID: contextQuery.data.userid, 
					uploads: Array.from(imageRef?.current?.files)
				})
			SuccessToast.fire({text: 'Updated event'})
			handleFormFinished()
		},
		onError: (error) => {
			closeDialogWithRef(dialogRef)
			FailPopup.fire({text: 'Failed to update event'})
		}
	})
	const initialHostOrg = useMemo(() => {
		return isExisting && data 
		? {id: data.orgid, name: data?.orgname} 
		: {id: contextQuery.data, name: contextQuery.data?.orgName}
	},[brandOrgsQuery.data, data])

	useEffect(() => {
		if (!data) return
		const {peritemreport, images, ...otherData} = data
		setValues(prev => ({...prev,...otherData, images:[], peritemreport: peritemreport === '1'}))
	},[data])

	const initialProcessor = useMemo(() => 
		isExisting 
			? processorQuery.data?.find?.(o => o?.id === data?.processor) 
			: {id: contextQuery.data?.orgid, name: 'Internal Processing'}
	,[processorQuery.data, data.processor, contextQuery.data?.orgid])
	
	const initialCharity = useMemo(() =>
		isExisting
			? charityQuery.data?.find?.(o => o?.id === data?.charity)
			: null 
	,[charityQuery.data, data.charity])
	console.log('p',values.processor);
	const handleSubmit = (f,e) => {
		if (loading) return
		setLoading(true);
		const baseParams = subObject(values, ['orgid', 'processor', 'charity', 'description','name','peritemreport'])

		const {  name, description, price, weight, starttime, endtime} = objectFromForm(e.target);
		const params = {
			...baseParams,
			orgs: data?.orgs,
			peritemreport: values.peritemreport,
			orgtypes: data?.orgtypes,
			locales: data?.locales,
			status: data?.status,
			images: null,
			name,
			description,
			price: price.replace(/[, ]/g, ''),
			weight: weight.replace(/[, ]/g, ''),
			starttime: Math.round(values.starttime),
			endtime: Math.round(values.endtime),
			numimages: isExisting ? data.images : values.images?.length,
			
		}
		if (isExisting) editMutation.mutate({...params, eventid: data.id})
		else donationMutation.mutate(params)
	}

	function handleClose() {
		setLoading(false);
		closeDialogWithRef(dialogRef)
		setValues({
			orgid: ctx?.orgid || '',
			processor:  ctx?.orgid,
			charity: '',
			name: '',
			description: '',
			peritemreport: false,
			price: '',
			weight: '',
			starttime: '',
			endtime: '',
			images: [],
		})
	}

	const handleImageUpload = (e) => {
		let files  = Array.from(imageRef?.current?.files);
		if (!Array.isArray(files) || files?.length < 1)  return
		if (files.length > 6)  {
			files = files.slice(0,6);
		}	
		filesToDataUri(files).then(
			data => onChange('images', data)
		)
	}
	
	function onChange(field, value) {
		let newVal = value;
		switch (field) {
			case 'price':
			case 'weight':
				newVal = numberWithCommas(newVal?.replace(/[, ^a-zA-Z -]/g, ''));
			default:
				break;
		}
		if (['starttime', 'endtime'].includes(field)) {
			newVal = value + MILLISEC.DAY / 1000
		}
		setValues({...values, [field]: newVal})
	}

	const handleFormFinished = () => {
		closeDialogWithRef(dialogRef)
		setLoading(false);
		setResetDropdowns(true);
		onFinish()
	}
	const handleRemoveAttach = (id) => {
		const dt = new DataTransfer()
		const refFiles = imageRef.current.files
		// Update file URI's
		let list = values.images;
		list.splice(id, 1);
		if (imageRef?.current?.value && !list.length) {
			imageRef.current.value = null;
		}
		// Update input ref
		for (let i = 0; i < refFiles?.length; i++) {
			if (id != i)
				dt.items.add(refFiles[i])
		}
		let updatedImages = [...list];
		onChange('images', updatedImages);
		imageRef.current.files = dt.files;
		// updatedImages.splice(id, 1);
	};
	function zeroField(field) { if(!values[field]) onChange(field, '0') }
	const safeBrandList = contextQuery.data?.isUICContext ? 
		UICBrandOrgsQuery.data 
		: brandOrgsQuery.data
		return (
			<>
			<BasicMultiDialog 
				onClose={handleClose}
				ref={dialogRef} 
				className='w-full min-w-[50rem] flex justify-center items-start mt-5 px-5 gap-5 h-full'
			>
				<BasicSubDialog
					formID='eventform' 
					handleSubmit={handleSubmit}  
					onClose={handleClose}
					title={isExisting ? 'Edit Donation' : 'Add Donation'}
					confirmDisabled={!canEdit || partialEdit}
					confirmLoading={loading}
					containerClassName='form-control'
					className='w-fit lg:w-[40rem] overflow-x-hidden pt-4 '
				>
						<BasicOrgDropdown 
							containerClassName='compact text-sm'
							className='h-12 compact'
							label='Host Organization'
							name='orgid'
							required
							value={values.org}
							disabled={!canEdit || partialEdit}
							initialValue={initialHostOrg}
							needsReset={resetDropdowns} 
							setNeedsReset={setResetDropdowns} 
							onChange={(org) =>
								onChange('orgid', org.id)
							}

							orgs={[...(safeBrandList ?? [])]}
						/>
						<BasicOrgDropdown
							className='h-12 compact'
							label='Processor'
							name='processor'
							disabled={!canEdit}
							initialValue={initialProcessor}
							placeHolder={'Internal Processing'}
							containerClassName='compact  text-sm whitespace-nowrap'
							needsReset={resetDropdowns} 
							setNeedsReset={setResetDropdowns} 
							onChange={(org) => {
								onChange('processor', org.id)}}
							orgs={[
								...(processorQuery.data || []),
								{
									id: values.orgid,	
									name: 'Internal Processing'
								}
							]}
						/>

						<BasicOrgDropdown
							className='h-12 compact'
							containerClassName='compact text-sm '
							label='Benefiting Charity'
							name='charity'
							disabled={!canEdit || partialEdit}
							initialValue={isExisting ? initialCharity : null}
							needsReset={resetDropdowns}
							setNeedsReset={setResetDropdowns}
							placeHolder={'Select a Charity'}
							onChange={(org) => onChange('charity', org.id)}
							orgs={[...(charityQuery.data || []),{id:'',name:'No Charity'}]}
						/>

						<BasicInput 
							containerClassName='compact'
							onChange={(e) => {onChange('name',e.target.value)}}
							value={values.name}
							disabled={!canEdit || partialEdit}
							label='Event Name'
							name='name'
							required
						/>
						<BasicTextArea
							containerClassName='compact'
							customSize
							style={{height:'20px'}}
							onChange={(e) => onChange('description',e.target.value)}
							value={values.description}
							disabled={!canEdit || partialEdit}
							label='Description'
							name='description'
							required
						/>

					<BasicToggle 
						defaultChecked={values.peritemreport}
						vertical
						containerClassName='compact justify-start w-full'
						label='Input weight & cost at item level?'
						disabled={!canEdit || partialEdit}
						name='peritemreport'
						className='no-animation'
						onClick={(e) => onChange('peritemreport', e.target.checked)}
					/>


					<BasicNumberInput
						value={values.price}
						containerClassName='compact'
						label='Price (CAD$)'
						name='price'
						controlled
						onBlur={() => zeroField('price')}
						required
						disabled={values.peritemreport === true || !canEdit || partialEdit}
						onChange={(v) => onChange('price', v)}
					/>
					<BasicNumberInput
						containerClassName='compact'
						value={values.weight}
						controlled
						label='Weight (LBS)'
						onBlur={() => zeroField('weight')}
						name='weight'
						required
						disabled={values.peritemreport === true || !canEdit || partialEdit}
						onChange={(v) => {onChange('weight',v); }}
					/>	
						<div className='grid columns-2 grid-flow-col gap-10  '>
							<BasicDateInput 
								containerClassName='compact'
								value={getDashedDateString(values.starttime)}
								disabled={!canEdit}
								required
								label='Start Date'
								name='starttime' 
								min={today}
								onChange={(e) => onChange('starttime',e.target.valueAsNumber / 1000)}
							/>
							<BasicDateInput
								containerClassName='compact'
								value={getDashedDateString(values.endtime)}
								disabled={!canEdit}
								min={data.endtime}
								required
								label='End Date'
								name='endtime'
								onChange={(e) => onChange('endtime',e.target.valueAsNumber / 1000)}
							/>
						</div>
					<ImageUploadAuditor 
						className='flex gap-2 mt-2 justify-stretch align-bottom '
						images={values.images}
						onDelete={handleRemoveAttach}
					/>
					<BasicFileInput
						containerClassName='compact'
						ref={imageRef}
						onChange={handleImageUpload}
						disabled={!canEdit || partialEdit}
						accept="image/*"
						label='Images'
						className="text-center text-gray-450 text-sm file-input-secondary"
						required={!isExisting}
						id="contained-button-file"	
					/>

				</BasicSubDialog>
				
				<BasicSubDialog 
					showCloseButton={false}
					showActions={false}
					customSize
					className={'h-fit w-fit p-0  pb-5 py-3'} 
					containerClassName={'w-fit max-w-[25rem] h-fit px-6 '}
				>
					<p className='text-lg font-semibold text-gray-subText text-center mt-0'>
						Card Preview
					</p>
					<EventCardMockup 
						values={values}  
						images={
							values.images?.length > 0 
								? values.images 
								: null || isExisting 
									? rangeArray(0, Number(data?.images) || 4)
										.map(i => getEventUrl(data.id, i)) 
									: null
						} 
					/>
				</BasicSubDialog>
			</BasicMultiDialog>
		</>
	);
};

export default CreateDonationEventDialog;
