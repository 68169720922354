import React, { forwardRef } from 'react';
import { OrgDropdown } from '../dropdowns/OrgDropdown';
import { InputBase } from './InputBase';
import { OrgTypeDropDown } from '../Organization/OrgTypeDropDown';
import { useController } from 'react-hook-form';

export const BasicOrgTypeDropdown = forwardRef(function({
    orgTypes = [],
    label=null,
    name,
    className, 
    isError=false,
    errorText,
    required=false,
    onChange=()=>{},
    containerClassName, 
    disabled=false,
    ...props},ref)  {
        return (
            <InputBase 
                {...{
                    label,
                    isError,
                    errorText,
                    required,
                    containerClassName,
                }}
            >
                <OrgTypeDropDown
                    isError={isError}
                    onChange={onChange}
                    orgTypes={orgTypes}
                    className={`
                        ${disabled ? 'input-disabled' : ''}
                        ${className}
                    `}
                    {...props}
                />
            </InputBase>
        )
});

export const FormBasicOrgTypeDropdown = forwardRef(function({
    orgTypes = [],
    label=null,
    initialValue={},
    name,
    className, 
    isError=false,
    errorText,
    required=false,
    onChange=()=>{},
    containerClassName, 
    control,
    disabled: controlledDisabled=false,
    ...props},ref)  {
        const {field, fieldState: {error}, formState: { disabled }} = 
            useController({name, control, defaultValue: initialValue})
        const isDisabled = controlledDisabled || disabled
    
        return (
            <InputBase 
                {...{
                    label,
                    isError: error!=null,
                    errorText: error?.message,
                    required,
                    containerClassName,
                    disabled: isDisabled
                }}
            >
                <OrgTypeDropDown
                    onChange={field.onChange}
                    orgTypes={orgTypes}
                    className={`
                        ${isDisabled ? 'input-disabled' : ''}
                        ${className}
                    `}
                    {...props}
                />
            </InputBase>
        )
});

