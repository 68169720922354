import React from 'react';


export const BasicRadioGroup = ({data, value, name, onChange, disabled=false, containerClassName,  className}) => {
    const [checkedIndex, setCheckedIndex] = React.useState(0)
    const handleChecked = (e,idx) => {
        if (disabled) return
        setCheckedIndex(idx)
        onChange(data[idx]?.value)
    }
    return (
        <div className={`flex flex-col gap-1 ${containerClassName}`}>
            {data?.map?.((item, idx) => (
                <label 
                key={`${name}${idx}label`} htmlFor={idx}
                className={
                    `bg-primary flex flex-row gap-3  box-border border-[2px]  group   rounded-xl py-3 px-5 
                    ${checkedIndex === idx ? 'border-blue-cornflower ' :'border-transparent'}
                    ${disabled ? 'opacity-60' : 'hover:cursor-pointer'}
                    ${className}`}
                    >
                    <input 
                        disabled={disabled}
                        checked={value === item.value}
                        type='radio' 
                        className={`radio opacity-100 checked:bg-blue-cornflower  
                        `}
                        key={`${name}${idx}input`} id={idx} name={name} value={item.value} onChange={(e) => {handleChecked(e,idx)}} />
                    <span>{item.label}</span>
                </label>
            ))}
        </div>
    );
};

