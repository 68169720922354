
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteBrand } from '@/apis/base/admin/uic/deleteBrandPref';


export default function useDeleteBrand(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.DELETE_BRAND],
        mutationFn: deleteBrand,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data) => {
            client.invalidateQueries({queryKey: [ROUTE_IDS.GET_BRANDS]})
            client.invalidateQueries({queryKey: [ROUTE_IDS.GET_BRAND_PREF_REGIONAL_REPORT]})
            onSuccess && onSuccess(data)
        },
        ...otherProps
    });
    return mutation;
}