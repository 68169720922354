import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { verifyEmail } from '../../../apis/base/auth';
import { userTypes } from '../../../constants';
import { useTranslations } from '../../../hooks/translations';
import { useAppContext } from '../../../context/AppContext';
import useVerifyEmail from '@/hooks/state/auth/useVerifyEmail';
import Swal from 'sweetalert2';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import useSendVerificationEmail from '@/hooks/state/auth/useSendVerificationEmail';
import { InfoToast } from '@/wrappers/swal/InfoToast';
import { BasicInput } from '@/components/Input/BasicInput';
import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';

function VerifyEmail(props) {
  const { history } = props;
  const text = useTranslations('verify');
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const email = query.get('email');
  const orgId = query.get('orgid');
  const { setLoading } = useAppContext();
  const [invalid, setInvalid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailToVerify, setEmailToVerify] = useState(email);
  const verificationEmailMutation = useSendVerificationEmail({
    onSuccess: (data) => {InfoToast.fire({text:'Verification email sent'})},
    onError: (err) => {FailPopup.fire({text: 'Failed to send verification email'})}
  })
  const verifyEmail = useVerifyEmail({
    onSuccess: (data) => {
      setLoading(false);
      if (data?.status === 'email not found') {
        setSuccess(false);
        setInvalid(true);
        Swal.fire({
          title: 'Verification Failed!',
          text: 'Please check the verification link',
          icon: 'error',
          timer: 2000,
          position: 'center',
          showConfirmButton: false,
        })
        return;
      }
      setSuccess(true);
      history.push('/auth/login');
      Swal.fire({
        title: 'Verification Success!',
        text: 'Please login to continue',
        icon: 'success',
        timer: 1000,
        position: 'center',
        showConfirmButton: false,
      }).then(() => {
        history.push('/auth/login')
      });
    },
    onError: (err) => {
      setInvalid(true);
      FailPopup.fire({text: 'Failed to verify email'})
    }
  });

  useEffect(() => {
    const params = {
      code: code,
      email: emailToVerify,
    };
    verifyEmail.mutate(params);
  }, []);

  function resendEmail() {verificationEmailMutation.mutate({email: emailToVerify})}

  function preventDefault(e) {
    resendEmail()
    e.preventDefault()
  }
  return (
    <form className="lg:my-25 sm:w-3/5 w-4/5 text-center" onSubmit={preventDefault}>
      {invalid ? (
        <div>
          <label className="font-semibold text-lg tracking-0.28 text-gray-600">
            {text.failedVerify}
          </label>
          <BasicInput 
            containerClassName='mt-5'
            label={'Email to verify'} 
            placeholder={'Enter email to send verification to'}
            value={emailToVerify}
            onChange={(e) => setEmailToVerify(e.target.value)}
          />
          <div className="mt-9 w-full">
            <BasicBlackButton
              type="button"
              onClick={resendEmail}
              variant="contained"
              size="large"
              className="w-full"
            >
              Resend
            </BasicBlackButton>
          </div>
        </div>
      ) : success ? (
        <div className={'flex flex-col'}>
          <div className={'flex mb-2 justify-center'}>
            <img
              className={'h-16 grayscale'}
              src={'/assets/images/check.png'}
              alt={'success'}
            />
          </div>
          <div className={'flex justify-center'}>
            <label className="font-semibold text-3xl text-black mb-5">
              {'Success'}
            </label>
          </div>
          <label className="font-semibold text-lg text-gray-700">
            {text.verifiedSuccess}
          </label>
          <label className="font-semibold text-lg text-gray-700">
            {text.verifiedSuccessClick}{' '}
            <Link
              to="/auth/login"
              className="text-blue-cornflower font-semibold cursor-pointer hover:opacity-80"
            >
              {text.here}
            </Link>{' '}
            {text.toLogin}
          </label>
        </div>
      ) : (
        <label className="font-semibold text-lg tracking-0.28 text-gray-600">
          {text.verifying}
        </label>
      )}
    </form>
  );
}

export default VerifyEmail;
