import React from 'react';

export const InputContainer = ({isError, errorText, label, required, disabled, children}) => {
    return (
        <>
            <div className="label py-0">
                { label && (
                    <div className={`label-text flex text-xs font-[500]  ${disabled ? 'opacity-40' : 'text-gray-subText' }`}>
                        {label}
                        {required  && (
                            <>
                                {/* <span className='ml-2'>&#65113;required&#65114;</span> */}
                                <span className="text-red-strong ml-2">*</span>
                            </>
                            )}
                    </div>
                )}
                { isError && 
                    <span className='text-error label-text-alt'>
                        {errorText}
                    </span>
                }
                    {/* <label className=''>
                    </label> */}

            </div>
            {children}
        </>
    );
};

