import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getInviteData } from '@/apis/base/invites/getInviteData';
import { modUserData } from '@/apis/user/modUserData';
import useMyUserData from '../useMyUserData';

export default function useModUserData( props ) {
  const client = useQueryClient();
  const myUserDataQuery = useMyUserData()
  const {onSuccess, ...otherProps } = props || {}
  const inviteData = useMutation({
    mutationKey: ['user', ROUTE_IDS.CHANGE_USER_DATA],
    mutationFn: modUserData,
    staleTime: MILLISEC.MIN_15,
    retry: false,
  onSuccess: (data, req) => {
    client.invalidateQueries({ queryKey: ['user', [req?.userid]]})
    if (req.userid === myUserDataQuery.data?.id) 
      client.invalidateQueries({ queryKey: ['myUserData']})
    if (!!onSuccess) props.onSuccess(data);
  },
    ...otherProps
  });
  return inviteData;
}