import { jsonToFormData } from '@/utils';

export const ROUTE_IDS = {
  LOGIN: 'login',
  CHECK_EMAIL: 'verifyemailuniqueness',
  VERIFY_EMAIL: 'verifyemailcode',
  SEND_EMAIL_VERIFICATION: 'sendverificationemail',
  RECOVER_PASSWORD: 'passwordrecovery',
  CHECK_RESET_PASSWORD_CODE: 'verifypwdrecoverycode',
  CHANGE_PASSWORD: "changepassword",
  GET_INVITE_DATA: 'getinvitedata',
  SEND_ORG_INVITE: 'createorginvite',
  SIGN_UP_MEMBER: 'signupmember',
  SIGN_UP_BRAND: 'signuporg',
  REFRESH: 'refreshtoken',

  INVITE_MEMBER: 'creatememberinvite',


  // SEE
  GET_GENERIC_SEE_DATA: 'getmonthlyseereport',

  // Admin
  REMOVE_MEMBER: 'removemember',
  REMOVE_ORG_ADMIN: 'remorgadmin',
  ADD_ORG_ADMIN: 'addorgadmin',
  TRANSFER_ORG_OWNERSHIP: 'transferorgownership',

  // UIC Admins
  GET_ALL_USER_DATA: 'getalluserdata',
  GET_UIC_ADMINS: 'getuicadmins',
  DELETE_BRAND: 'delbrand',
  CREATE_BRAND_PREF: 'addbrand',
  GET_BRAND_PREF_REGIONAL_REPORT: 'getbrandprefsbyregion',
  GET_ALL_USER_WISHLISTS: 'getalluserwishlists',

  // Brand Portal
  GET_ALL_BD_EVENTS: 'getalldonationevents',
  CREATE_BRAND_DONATION_EVENT: 'createdonationevent',
  GET_BD_EVENTS: 'getorgdonationevents',
  MOD_BD_EVENT: 'moddonationevent',
  SUBMIT_BD_INVENTORY: 'adddonationinventory',
  MOD_BD_INVENTORY: 'moddonationinventory',
  GET_BD_EVENT_BY_ID: 'getdonationeventbyid',
  GET_PROCESSOR_DONATIONS: 'getdonationeventsbyprocessor',
  GET_LIVE_BD_EVENTS: 'getlivedonationevents',
  GET_ELIGIBLE_BD_EVENTS: 'getorgeligibleevents',

  // Request
  CREATE_BD_REQUEST: 'adddonationrequest',    
  MOD_BD_REQUEST: 'moddonationrequest',         
  GET_ORG_BD_REQUESTS: 'getorgrequestsbyevent',

  // Shipment
  CREATE_BD_SHIPMENT: 'adddonationshipment',
  MOD_BD_SHIPMENT: 'moddonationshipment',
  GET_BD_SHIPMENTS: 'getorgshipmentsbyevent',
  GET_ALL_BD_SHIPMENTS: 'getalleventshipments',
  CONFIRM_SHIPMENT_ARRIVAL: 'qtyconfirmation',
  
  // Popup
  CREATE_POPUP: 'createpopupstore',
  MOD_POPUP: 'modpopupstore',
  GET_ORG_POPUPS: 'getorgpopupstores',
  GET_POPUP: 'getpopupbyid',
  GET_MY_POPUP_REQUESTS: 'getmypopuprequests',
  CREATE_POPUP_REQUEST: 'addpopuprequest',
  MOD_POPUP_REQUEST: 'modpopuprequest',
  CONFIRM_POPUP_PICKUP: 'popuppickupconfirmation',
  GET_POPUP_REQUESTS: 'getpopuprequests',

  // Organizations
  GET_ORGS_BY_ROLES: 'getorgidsbyrole',
  MOD_ORG_DATA: 'modorgdata',
  GET_ORGANIZATIONS: 'getorganizations',
  GET_ORGS_BY_ID: 'getorgsbyid',
  GET_MY_ORGS: 'getmyorgs',
  GET_ORG_TYPES: 'getorgtypes',
  CREATE_ORG_INVITE: 'createorginvite',
  SIGN_UP_ORG: 'signuporg',
  GET_MY_MEMBERSHIP_DATA: 'getmemberdata',
  LEAVE_ORG: 'leaveorg',
  GET_ORGS_BY_CITY: 'getorgsbycity',
  JOIN_ORG: 'joinorg',
  GET_MINIMAL_ORGS: 'getorgnames',
  GET_ORG_TAGS: 'getorgtags',
  UNTAG_ORG: 'remorgtag',
  TAG_ORG: 'addorgtag',
  GET_ORG_WISHLIST: 'getorgwishlist',
  GET_ORG_WISHLIST_REPORT: 'getorgwishlistreport',
  MOD_ORG_WISHLIST_REPORT: 'updateorgwishlistreport',
  GET_ORG_TREE: 'getorgtree',

  // Members
  GET_ORG_VIPS: 'getorgvips',

  GET_ALL_ORGS_ROLE_DATA: 'getallorgsroledata',
  
  GET_BRANDS: 'getbrands',
  SET_BRANDS: 'setmybrands',

  // Products
  ADD_WISHLIST_PRODUCT: 'addwishlistproduct',
  MOD_WISHLIST_PRODUCT: 'modwishlistproduct',
  REMOVE_WISHLIST_PRODUCT: 'delwishlistproduct',

  // Sponsorships
  GET_ORG_SPONSORSHIPS: 'getorgsponsors',
  ADD_ORG_SPONSORSHIP: 'addorgsponsorship',
  
  // User
  MOD_MY_USER_DATA: 'modmyuserdata',
  GET_MY_USER_DATA: 'getmyuserdata',
  GET_USER_DATA: 'getuserdata',
  CHANGE_USER_DATA: 'moduserdata',
  GET_USER: 'getuserdata',
  GET_SECONDARY_EMAILS: 'getsecondaryemails',
  ADD_SECONDARY_EMAIL: 'addemailaddress',
  REMOVE_SECONDARY_EMAIL: 'remsecondaryemail',
  
  SUBMIT_BASE_ROLE: 'submitbaserole',
  SUBMIT_ROLE_APPLICATION: 'submitroleapplication',
  GET_MY_BASE_ROLE_DATA: 'getmybaseroledata',
  CANCEL_ROLE_APPLICATION: 'cancelroleapplication',
  GET_USER_ROLE_APPLICATIONS: 'getuserroleapplications',
  
  GET_MEMBER_ROLES: 'getmemberorgroles',
  GET_ROLE_REQUESTS: 'getallroleapplications',
  MOD_APPLICATION_STATUS: 'modroleapplicationstatus',
  GET_ROLE_APPLICATIONS: 'getroleapplications',
  GET_USERS_DEPENDENTS: 'getfindependents',

  GET_GEO_DATA: 'getgeodata',
  GET_GEO_NODE: 'getgeonodedata',
  SUBMIT_TICKET: 'sendsupportemail',

  // Wishlist
  GET_PRODUCT_CATEGORIES: 'getproductcategories',
  SET_MY_BRANDS: 'setmybrands',
  GET_TARGET_CUSTOMERS: 'gettargetcustomers',
  GET_WISHLIST_PRODUCTS: 'getwishlistproducts',
  GET_WISHLIST_PRODUCTS_BY_CAT: 'getwishlistproductsbycat',
  GET_MY_WISHLIST: 'getmywishlist',
  SUBMIT_MY_WISHLIST: 'submitmywishlist',
  GET_WISHLIST_TREE: 'getwishlisttree2',

  // UIC Admins
  UIC_OVERRIDE_CREATE_ORG: 'createorganization',

  // IMAGES
  GET_IMAGES: 'getimages',  
  MOD_IMAGES: 'modimage',
  ADD_IMAGES: 'addimage',
  DELETE_IMAGE: 'delimage',

  // ENUM
  GET_APP_STATUS_ENUM: 'getapplicationstatuses',
  GET_DONATION_STATUS_ENUM: 'getdonationsenum',
  GET_ROLE_ENUM: 'getmemberroles',
  GET_ORG_ROLES: 'getorgroles',
  GET_SPONSORSHIP_ENUM: 'getsponsorshiptypes',
  GET_OBJECT_ENUM:'getobjs',
};

export function addRouteIDToFormData(formData, routeID) {
  let dataWithId = formData;
  dataWithId?.append('type', routeID);
  return dataWithId;
}

export function composeFormData(json, routeID) {
  return addRouteIDToFormData(jsonToFormData(json), routeID);
}
