
import React from 'react';



import { PageTitle } from '@/components/Label/PageTitle';
import { useTranslations } from '@/hooks/translations';
import Organization from '../../Main/Account/components/pages/Organization';
import { BasicTabs } from '@/components/Tabs/BasicTabs';


const BrandOrganization = () => {
  const text = useTranslations('account');
  return (
    <div className="container">
      <div className="w-full min-h-screen form-control ">
            <PageTitle>{'Organizations'}</PageTitle>
            <BasicTabs tabNames={['General']} fake activeTab={0}></BasicTabs>
              <Organization text={text}/>
    </div>
  </div>
  );
}

export default BrandOrganization;
