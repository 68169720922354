import { BasicCheckbox } from "@/components/Input/BasicCheckbox";
import { getDateString } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { ApproveDeclineCell } from "../Cells/ApproveDeclineCell";
import { RequestStatusCell } from "../Cells/RequestStatusCell";
import { UserCell } from "../Cells/UserCell";
import { BasicTableButton } from "@/components/Buttons/BasicTableButton";
import { EyeIcon } from "@/components/Icons";
import { BasicGhostButton } from "@/components/Buttons/BasicGhostButton";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const requestManagementColmnHelper = [
columnHelper.display( {
    id: "Selected",
    size: 20,
    cell: ({row}) => 
        <BasicCheckbox 
            className={'-ml-3'}
            {...{checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler()}}
        />,
    header: ({table}) => 
    <BasicCheckbox 
        className={'-ml-4'}
        indeterminate={table.getIsSomeRowsSelected()}
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        />,
  }),
columnHelper.accessor((row, i) => i + 1, {
    id: "No",
    size: 10,
    enableColumnFilter: false,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>No</span>,
  }),
columnHelper.accessor((row) => row.name, {
    id: "Member",
    size: 30,
    filterFn: stringFilter,
    cell: ({row}) => <UserCell hasUserDetail={false} row={row.original}/>,
    header: (info) => <span>Member</span>,
  }),
columnHelper.accessor((row) => row.roleLabel, {
    id: "Type",
    size: 300,
    filterFn: stringFilter,
    cell: (info) => <span>Role: {info.getValue()}</span>,
    header: (info) => <span>Type</span>,
  }),
columnHelper.accessor((row) => row.statusLabel, {
    id: "Status",
    size: 100,
    filterFn: stringFilter,
    cell: ({row}) => <RequestStatusCell status={row.original.status}/>,
    header: (info) => <span>Status</span>,
  }),
columnHelper.accessor((row) => row.occupation, {
    id: "Occupation",
    size: 200,
    filterFn: stringFilter,
    enableColumnFilter: false,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>Occupation</span>,
}),
columnHelper.accessor((row) => row.orgname, {
    id: "Organization",
    size: 300,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>Organization</span>,
  }),
columnHelper.accessor((row) => row.timestamp, {
    id: "Member Since",
    size: 150,
    filterFn: stringFilter,
    enableColumnFilter: false,
    cell: (info) => <span>{getDateString(info.getValue())}</span>,
    header: (info) => <span>Member Since</span>,
  }),
columnHelper.display({
    id: "Actions",
    size: 30,
    filterFn: stringFilter,
    cell: ({row,table}) => 
      <div className="flex">
        <BasicGhostButton little circle onClick={() => table.options.meta.viewDetails(row.original)}>
          <EyeIcon className={'w-7 h-7 text-gray-unselected'}/>
        </BasicGhostButton>
        <ApproveDeclineCell 
              onApprove={() => table.options.meta.onSingleModify(true, row.original)}
              onDecline={() => table.options.meta.onSingleModify(false, row.original)}
        />
      </div>,
    header: (info) => <div className="text-center w-full ">Actions</div>,
  }),
]

const getRoleText = (roleEnum, type) => {
    let roleText = 'Role: '
    switch (type) {
        case roleEnum.fin:
            roleText += 'Family In Need'
            break;
        case roleEnum.ambassador:
            roleText += 'Ambassador'
            break;
        case roleEnum.smi:
            roleText += 'Social Media Influencer'
            break;
        default:
            break;
    }
    return roleText       
}

