import React from 'react';


export const PageTitle  = ({className, compact=false, ...props}) => {
    return (
        <div className={`
            flex w-full mb-6 text-2xl lg:text-3xl font-bold
            ${compact ? 'items-end' : 'justify-between items-center'}
            ${className}`}
        >
        {props.children}
        </div>
    );
};

