import { BDRequestQueryConfig } from '@/hooks/state/donation/request/useBDRequest';
import { BDShipmentsQueryConfig } from '@/hooks/state/donation/shipment/useBDShipments';
import useBDEventByID from '@/hooks/state/donation/useBDEventByID';
import { arrayToObject, between, clamp, closeDialogWithRef, openDialogWithRef, recursiveSubnodeHelper, resetFormWithId } from '@/utils';
import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';
import { BasicTable } from '../BasicTable';
import { OrgTreeBDShipmentReportCols } from './OrgTreeBDShipmentReportCols';
import useUserData from '@/hooks/state/user/useUserData';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { EditShipmentDialog } from '@/components/Dialog/EditShipmentDialog';
import useLiveBDEvents from '@/hooks/state/donation/useLiveBDEvent';
import { curry } from '@/utils/advanced';
import useModBDRequest from '@/hooks/state/donation/request/useModBDRequest';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import useModBDShipment from '@/hooks/state/donation/shipment/useModBDShipment';
import useConfirmBDShipment from '@/hooks/state/donation/shipment/useConfirmBDShipment';


export const OrgTreeBDShipmentReport = ({orgTree, eventid, brandorgid, inlineEditing, ...props}) => {
    const dialogRef = React.useRef()
    const [tableData, setTableData] = React.useState([])
    const [columnHelper, setColumnHelper] = React.useState([])
    const [queryConfigs, setQueryConfigs] = React.useState([])
    const [selectedRow, setSelectedRow] = React.useState()
    const [userIDs, setUserIDs] = React.useState([])
    const {data: ctx} = useCurrentOrgContext()
    const userDataQueries = useUserData({userids: userIDs})
    const eventsQuery = useLiveBDEvents()
    const requestMutation = useModBDRequest({
        ...queryResultFns(
            'Request updated',
            'Failed to update request'
        ),
        onSettled: (data) => {closeDialogWithRef(dialogRef)}
    })
    const shipmentMutation = useModBDShipment(queryResultFns(
        'Shipment updated',
        'Failed to update shipment'
    ))
    const confirmReceivedMutation = useConfirmBDShipment({
        ...queryResultFns(
            'Shipment confirmed',
            'Failed to confirm shipment'),
        brandorgid
    })
    
    const event = useMemo(() => {
        if (!eventsQuery.data) return
        return eventsQuery.data?.find?.((event) => event.id === eventid)
    },[eventsQuery.data])
    const pairsQuery = useQueries({
        queries: queryConfigs,
        combine: (data) => {
            const retData = {}
            const isSuccess = data.every((item) => item.isSuccess)
            for (let i = 0; i < data?.length; i+=2) {
                // console.log(i);
                const currOrg = data[i]?.data?.orgid
                if (!currOrg) continue
                retData[currOrg] = [data[i],data[i+1]]
            }
            return {data: retData, isSuccess} 
        }
    })
    const notExpired = true //between(Date.now() / 1000, event?.starttime, event?.endtime)
    const canEditRequest = notExpired && (ctx.isUICContext || ctx?.standardCommunity)
    const canEditShipment = notExpired && 
                            ((ctx?.isUICContext || ctx?.brand) || 
                            (ctx?.processor && event.processor === ctx.orgid))
    useEffect(() => {
        if (!event) return
        setColumnHelper(OrgTreeBDShipmentReportCols(event.donations))
    },[eventsQuery.data])

    useEffect(() => {
        if (!orgTree || !eventsQuery.data || !pairsQuery.isSuccess) return
        const uniqueUserIDs = new Set()
        recursiveSubnodeHelper(orgTree?.[0], curry(addLineItemsToOrg)({pairs: pairsQuery.data, userIDSet: uniqueUserIDs}))
        setUserIDs(Array.from(uniqueUserIDs))
        setTableData([orgTree[0]])
        // console.log('here');
    },[eventsQuery.data, pairsQuery.data, orgTree])

    useEffect(() => {
        if (!orgTree?.[0]) return
        const uniqueIDs = new Set()
        recursiveSubnodeHelper(orgTree[0],curry(addUniqueIDs)(uniqueIDs))
        const configs = []
        for (const id of uniqueIDs) {
            configs.push(BDRequestQueryConfig({orgid: id, eventid}))
            configs.push(BDShipmentsQueryConfig({orgid: id, eventid}))
        }
        setQueryConfigs(configs)
    },[orgTree])

    function addUniqueIDs(IDSet, org) {
        if (!org) return
        if (IDSet.has(org.id)) return
        IDSet.add(org.id)
    }

    function addLineItemsToOrg({pairs, userIDSet}, org) {
        if (!pairs[org.id]) return
        const r = pairs[org.id][0].data
        const s = pairs[org.id][1].data
        const firstReq= r?.lineitems?.[0]?.request
        const firstShip = s?.lineitems?.[0]
        if (firstReq) {
            org.requestid = firstReq.requestid
            org.requestmodifier = firstReq['modified by'] || firstReq['created by']
            org.requestmodified = firstReq.modified
        }
        if (firstShip) {
            org.shipmentmodifier = firstShip['modified by'] || firstShip['created by']
            org.shipmentmodified = firstShip.qtymodifiedtime || firstShip.modified
            org.confirmedby = firstShip['confirmed by'] 
            org.qtyconfirmedtime = firstShip.qtyconfirmedtime
            org.shipmentid = firstShip.shipmentid
        }
        org.lineitems = {}
        for (const req of r.lineitems) {
            if (!org.lineitems[req.inventoryid]) org.lineitems[req.inventoryid] = {}
            org.lineitems[req.inventoryid].requestquantity = req.request.quantity
        }
        for (const ship of s.lineitems) {
            org.lineitems[ship.inventoryid].shipmentquantity = ship.quantity
            org.lineitems[ship.inventoryid].confirmedquantity = ship.qtyconfirmed
        }            
        if (!isNaN(org.confirmedby)) userIDSet.add(org.confirmedby)
        if (!isNaN(org.requestmodifier)) userIDSet.add(org.requestmodifier)
        if (!isNaN(org.shipmentmodifier)) userIDSet.add(org.shipmentmodifier)
        if (!isNaN(org.confirmedby)) userIDSet.add(org.confirmedby)
    }
    function handleInlineBlur(e, field, item) {
        let newValue = 0
        if (newValue === parseInt(item?.[field])) return
        switch (field) {
            case 'requestquantity':
                newValue = (clamp(Number(e.target.value), 0, item.quantity))
                submitRequest(item, newValue)
                break
            case 'shipmentquantity':
                newValue = (clamp(Number(e.target.value), 0, Number(item.requestquantity)))
                submitShipment(item, newValue)
                break
            case 'confirmedquantity':
                newValue = (clamp(Number(e.target.value), 0, Number(item.shipmentquantity)))
                confirmShipment(item, newValue)
                break
            default:
                break
        }
    }
    function submitRequest(row, requestedquantity) {
        const params = {
            orgid: row.orgid,
            eventid: event.id,
            lineitems: [{
                inventoryid: row.id,
                quantity: Number(requestedquantity)
            }]
        }
        requestMutation.mutate(params)
    }
    function submitShipment(row, shipppedquantity) {
        const params = {
            recipientorgid: row.orgid,
            eventid: event.id,
            lineitems: [{
                inventoryid: row.id,
                quantity: Number(shipppedquantity)
            }]
        }
        shipmentMutation.mutate(params)
    }
    function confirmShipment(row, qtyconfirmed) {
        const params = {
            recipientorgid: row.orgid,
            eventid: event.id,
            lineitems: [{
                inventoryid: row.id,
                qtyconfirmed: Number(qtyconfirmed)
            }]
        }
        confirmReceivedMutation.mutate(params)
    }
    function handleEdit(row) {
        setSelectedRow({...row, orgid: row.id})
        openDialogWithRef(dialogRef)
        resetFormWithId('shipmentForm')
    }

    return (
        <>
            <BasicTable
                initialExpand
                state={{
                    columnVisibility: {
                        Actions: true
                    }
                }}
                canExpand
                data={tableData}
                columnHelper={columnHelper}
                meta={{
                    inlineEditing,
                    handleInlineBlur,
                    userNames: arrayToObject(userDataQueries.data,'id'),
                    canEditRequest,
                    canEditShipment,
                    handleEdit,
                }}
            />
            <EditShipmentDialog 
                dialogRef={dialogRef}
                canEditRequest={canEditRequest}
                canEditShipment={canEditShipment}
                event={event}
                row={selectedRow}
            />
        </>
    );
};



