import { Delimit } from '@/components/Misc/Delimit';
import { UserAvatar } from '@/components/User/UserAvatar';
import { getTimeSince } from '@/utils';
import { isTrue } from '@/utils/misc';
import React, { useMemo } from 'react';

export const VIPCard = ({vip, }) => {
    const roles = useMemo(() => {
        const roles = []
        if (isTrue(vip.owner)) {
            roles.push('Master Admin')
            return roles
        }
        else if (isTrue(vip.admin))  {
            roles.push('Admin')
            return roles
        }
        if (isTrue(vip.amb)) roles.push('Brand Ambassador')
        if (isTrue(vip.smi)) roles.push('Influencer')
        return roles
    } ,[vip])
    return (
        <div
            className='@container rounded-xl flex items-center'
        >
            <UserAvatar userID={vip.id} className={'w-8 h-8 @[340px]:w-12 @[340px]:h-12'}/>
            <div className="form-control ml-4">
                <span className='text-xs sm:text-sm'>{`${vip.firstname} ${vip.lastname}`}</span>
                <div className="flex gap-1 text-2xs sm:text-xs text-gray-subText">
                    <Delimit
                        delimiter={<span className='text-gray-300'>|</span>}
                    >
                        {roles?.map((r, idx) => 
                            <span key={idx} className='whitespace-nowrap'>{r}</span>)
                        }
                    </Delimit>
                </div>
            </div>
            {/* <span className="ml-auto text-2xs sm:text-xs">
            Joined {getTimeSince(vip.joinedtimestamp || 1712905155)} ago
            </span> */}
            
            
        </div>
    );
};
