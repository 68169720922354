import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const CustomCheckbox = (props) => {
  const { checked, onChange } = props;
  const StyledCheckBox = withStyles({
    root: {
      width: '16px',
      height: '16px',
      borderRadius: 0,
      padding: 0,
      '&$checked': {
        color: grey[500],
      },
    },
    checked: {},
  })(Checkbox);

  const handleChange = (event) => {
    onChange(event.target.checked);
  }

  return (
    <StyledCheckBox color="default" defaultChecked={checked} onChange={handleChange} />
  )
}

CustomCheckbox.propTypes = {
	checked: PropTypes.bool,
  onChange: PropTypes.func
};
CustomCheckbox.defaultProps = {
  checked: false,
  onChange: () => true
};

export default CustomCheckbox;