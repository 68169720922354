import Swal from "sweetalert2"

export const Popup = Swal.mixin({
        icon: 'info',
        showClass: {
            popup: 'animate-in-popup',
        },
        customClass: {
            confirmButton: 'btn btn-secondary whitespace-nowrap',
            cancelButton:  'btn btn-neutral ml-2 whitespace-nowrap',    
        },
        showConfirmButton: true,
        showCancelButton: true,
        buttonsStyling: false,
        position: 'center',
        
    })
