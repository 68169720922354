import { postPromise } from '@/apis/base';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import config from '@/apis/config';

/**
 *
 * @param {*} params
 * @param {[string]} params.orgids
 * @param {boolean} justFirst
 * @returns
 */
export const getOrgsById = (params, justFirst) => {
return new Promise((resolve, reject) => {
    return postPromise(
        config.API_BASE_URL,
        composeFormData(params, ROUTE_IDS.GET_ORGS_BY_ID))
            .then(res => {
                resolve(
                    justFirst ? res?.[0]
                    : res)
            
            }, err => {
                reject(err)
            })
    })
  
};
