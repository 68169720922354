
import React, { useEffect, useRef, useState } from 'react';
import { useTranslations } from '../../hooks/translations';

import { useRegionCitySelect } from '@/components/CountryRegionSelect';
import { POSITION } from '@/components/Dropdown';
import useAllOrgs from '@/hooks/state/organization/useAllOrgs';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useGetOrgTypes from '@/hooks/state/organization/useGetOrgTypes';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import { closeDialogWithRef, flattenOrgs, openDialogWithRef } from '@/utils';
import { PERMISSION } from '../../constants';
import { useAppContext } from '../../context/AppContext';
import { useUserContext } from '../../context/UserContext';
import { BasicInput } from '../Input/BasicInput';
import { BasicOrgDropdown } from '../Input/BasicOrgDropdown';
import { BasicOrgTypeDropdown } from '../Input/BasicOrgTypeDropdown';
import { InputBase } from '../Input/InputBase';
import { BasicDialog } from './BasicDialog';

const CreateOrganizationDialog = ({ open, onClose, onCreate }) => {
  const dialogRef = useRef();
  const text = useTranslations('account');
  const contextQuery = useCurrentOrgContext();
  const currentOrgOption = {id:contextQuery.data?.orgid, name: contextQuery.data?.orgName}
  const [parent, setParent] = useState(contextQuery.data?.isUICContext ? '' : currentOrgOption);
  const [orgType, setOrgType] = useState('');
  const [orgName, setOrgName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');

  const [validations, setValidations] = useState({
    parent: '',
    orgtype: '',
    orgname: '',
    address: '',
    regionid: '',
    cityid: '',
    postalcode: '',
  });

  const allOrgsQuery = useAllOrgs({flat: true});
  const someOrgsQuery = useOrgWithId({org_id: contextQuery.data?.orgid, recursive: 1});
  const isUICAdmin = contextQuery?.data?.['admin level'] === PERMISSION.UIC_ADMIN;
  const orgsQuery = isUICAdmin ? allOrgsQuery : someOrgsQuery;
  const orgs = contextQuery.data?.isUICContext ? allOrgsQuery?.data?.flatOrgs : flattenOrgs(someOrgsQuery?.data)

  const { RegionSelect, CitySelect } = useRegionCitySelect({
    fixed: true,
    onRegionChange: (val) =>{
      setRegion(val)
      setValidations({...validations, 'regionid' : ''});
    }, 
    onCityChange:(val) => {
      setCity(val)
      setValidations({...validations,'cityid':''});
    },
    cityClassName: 'h-10 text-sm',
    regionClassName: 'h-10 text-sm',
    regionPosition: POSITION.BOTTOM,
    cityPosition: POSITION.TOP,
    regionError: validations.regionid !== '',
    cityError: validations.cityid != '',
    cityValue: city,
    regionValue: region,
    controlled: true
    
  })
  const typeQuery = useGetOrgTypes();

  useEffect(() => {
    if (open) 
      openDialogWithRef(dialogRef)
    else
      closeDialogWithRef(dialogRef)
  },[open])

  useEffect(() => {
    if (!contextQuery.data?.isUICContext) {
      setParent(currentOrgOption)
    }
  },[contextQuery.data])

  const onDialogClosed = () => {
    console.log();
    setParent(contextQuery.data?.isUICContext ? '' : currentOrgOption);
    setOrgType('');
    setOrgName('');
    setAddress('');
    setRegion('');
    setCity('');
    setPostalCode('');

    setValidations({
      parent: '',
      orgtype: '',
      orgname: '',
      address: '',
      regionid: '',
      cityid: '',
      postalcode: '',
    });
    onClose();
  };

  const onCreateBtnClicked = () => {
    let passedValidations = true
    const newValidations = {}
    if (parent === '' && !contextQuery.data?.isUICContext) {
      newValidations.parent = 'has-empty'
      passedValidations = false
    }
    if (orgType === '') {
      newValidations.orgtype = 'has-empty'
      passedValidations = false
    }
    if (orgName === '') {
      newValidations.orgname = 'has-empty'
      passedValidations = false
    }
    if (address === '') {
      newValidations.address = 'has-empty'
      passedValidations = false
    }
    if (region === '') {
      newValidations.regionid = 'has-empty'
      passedValidations = false
    }
    if (city === '') {
      newValidations.cityid = 'has-empty'
      passedValidations = false
    }
    if (postalCode === '') {
      newValidations.postalcode = 'has-empty'
      passedValidations = false
    }
    setValidations({...validations, ...newValidations})
    if (!passedValidations) return
    const condParams = parent ? {parent: parent.id} : {}
      onCreate({
        ...condParams,
        orgtype: orgType?.value,
        orgname: orgName,
        address: address,
        cityid: city?.id,
        regionid: region?.id,
        countryid: '27003', // Canada
        postalcode: postalCode,
      });

    setParent(contextQuery.data?.isUICContext ? '' : currentOrgOption);
    setOrgType('');
    setOrgName('');
    setAddress('');
    setRegion('');
    setCity('');
    setPostalCode('');
  };
  return (
    <BasicDialog 
      onClose={onDialogClosed}
      ref={dialogRef} 
      formID={'createOrgForm'} 
      title={'Create Organization'} 
      handleSubmit={onCreateBtnClicked}
      confirmText={'Create'}
      className='h-fit'
      >
      <BasicOrgDropdown
        isFlat
        label={'Parent Organization'} 
        controlled
        value={parent}
        className='h-10 text-sm'
        placeHolder={'No Parent Organization'}
        orgs={orgs}
        errorText={text.fieldRequired}
        isError={validations.parent !== ''}
        required={!contextQuery.data?.isUICContext}
        onChange={(org) => {setParent(org); setValidations({...validations, parent: ''})}}
        />
      <BasicOrgTypeDropdown
        label={'Organization Type'}
        orgTypes={typeQuery.data} 
        value={orgType}
        placeHolder={text.chooseOrgType}
        onChange={(orgType) => {console.log(orgType)
          
          setOrgType(orgType);
          setValidations({...validations, orgtype: ''})
        }}
        isError={validations.orgtype !== ''}
        errorText={text.fieldRequired}
      />
      <BasicInput 
        label={'Organization Name'} 
        value={orgName}
        isError={validations.orgname !== ''}
        onChange={(e) => {
          setOrgName(e.target.value);
          setValidations({...validations, orgname: ''});
        }}
        errorText={text.fieldRequired}
        required={true}
        />
      <BasicInput
        label={'Address'}
        value={address}
        isError={validations.address !== ''}
        onChange={(e) => {
          setAddress(e.target.value);
          setValidations({...validations,address: ''});
        }}
        errorText={text.fieldRequired}
        required={true}
      />

      <InputBase label={'Region'} 
        isError={validations.regionid !== ''}
        errorText={text.fieldRequired}
        required={true}>
          {RegionSelect}
      </InputBase>
      <InputBase label={'City'}
        isError={validations.cityid !== ''}
        errorText={text.fieldRequired}
        required={true}>
          {CitySelect}
      </InputBase>
      <BasicInput
        label={'Postal Code'}
        value={postalCode}
        isError={validations.postalcode !== ''}
        onChange={(e) => {
          setPostalCode(e.target.value);
          setValidations({...validations,postalcode: ''});
        }}
        errorText={text.fieldRequired}
        required={true}
      />
        
    </BasicDialog>
  )
};

export default CreateOrganizationDialog;
