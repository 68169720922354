export const UserStatusCell = ({status}) => (
    <div className="flex">
        <div className="w-3 h-3 rounded-full mr-2 mb-2">
            {   status || status === 'Active' ? (
                    <div className="badge bg-green-back text-green-text">Active</div>
                ): (
                    <div className="badge bg-red-back text-red-text">Inactive</div>
                )

            }
        </div>
    </div>
)