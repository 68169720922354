import { BasicDialog } from '@/components/Dialog/BasicDialog';
import { BasicInput } from '@/components/Input/BasicInput';
import useSendOrgInvite from '@/hooks/state/organization/useSendOrgInvite';
import { useYupValidationResolver } from '@/hooks/utils/useYupValidationResolver';
import { closeDialogWithRef } from '@/utils';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const validationSchema =  yup.object({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
})

export const OrgCreationInvitationDialog = ({dialogRef, ...props}) => {
    const createOrgMutation = useSendOrgInvite(queryResultFns(
        'Organization creation invite sent successfully',
        'Failed to send organization creation invite'
    ))
    const resolver = useYupValidationResolver(validationSchema)
    const {register, handleSubmit, formState, clearErrors } = useForm({resolver})
    
    function createOrg(org_details) {
        createOrgMutation.mutate(org_details)    
        closeDialogWithRef(dialogRef)
    }

    return (
        <BasicDialog
            native
            ref={dialogRef}
            title='Invite User to Create Community'
            id='orgCreationInvitationForm'
            handleSubmit={handleSubmit(createOrg)}
            onClose={() => clearErrors()}
            {...props}
        >
            <BasicInput
                required
                label='First Name'
                name='firstname'
                errors={formState.errors}
                {...register('firstname')}
            />
            <BasicInput
                required
                label='Last Name'
                name='lastname'
                errors={formState.errors}
                {...register('lastname')}
            />
            <BasicInput
                required
                label='Email'
                name='email'
                errors={formState.errors}
                {...register('email')}
            />
            
        </BasicDialog>
    );
};

