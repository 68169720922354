import { MILLISEC } from '@/constants';
import { useMutation } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { changePassword } from '@/apis/base/auth/changePassword';

export default function useChangePassword({ onError, onSuccess, ...props }) {
  const ChangeQuery = useMutation({
    mutationKey: [ROUTE_IDS.CHANGE_PASSWORD],
    mutationFn: changePassword,
    retry: false,
    staleTime: MILLISEC.MIN_15,
    onSuccess: onSuccess,
    onError: onError,
    ...props,
  });
  return ChangeQuery;
}
