
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getDefaultCuratorFeed } from '@/apis/base/curator/getDefaultCuratorFeed';

export default function useDefaultCuratorFeed(params) {
    const query = useQuery({
        queryKey: ['curator', 'default'],
        queryFn: getDefaultCuratorFeed,
        staleTime: MILLISEC.MIN_15,
        select
    });
    return query;
}

function select(data) {
    const clone = structuredClone(data)
    // clone?.posts?.forEach?.(p => console.log(p?.post_status))
    // clone?.posts?.forEach?.(p => console.log(new Date(p?.source_created_at).getTime()))
    const filteredPosts = clone?.posts?.filter?.(p => p?.post_status === 'approved')
    clone.posts = filteredPosts
    return clone
}