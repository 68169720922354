import React from 'react';


export const BasicDetailRow = ({label, value}) => {
    return (
        
            <div className="flex flex-row">
                <div className="w-1/3 text-gray-subText">
                    {label}
                </div>
                <div className="w-2/3">
                    {value}
                </div>
            </div>
        
    );
};

