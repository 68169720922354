import { BasicDetailRow } from '@/components/Label/BasicDetailRow';
import { getDateTimeString } from '@/utils';
import React, { useMemo } from 'react';

const dayKeys = {
    'monday': 'Mon',
    'tuesday': 'Tue',
    'wednesday': 'Wed',
    'thursday': 'Thu',
    'friday': 'Fri',
    'saturday': 'Sat',
    'sunday': 'Sun',
}

export const AmbassadorDetail = ({ app}) => {
    const eContact = app.emergencycontact;
    const availability = useMemo(() => {
        const selectedDays = Object.keys(dayKeys).filter(key => app.availability[key] === '1');
        return selectedDays.map(key => dayKeys[key]).join(', ');
    }, [app.availability]);
    return (
        <div className="flex flex-col">
            <BasicDetailRow label="Student" value={app.affiliation === 'Student' ? 'Yes' : 'No'}  />
            <BasicDetailRow label="Over 18 Years Old" value={app?.over18 === '1' ? 'Yes' : 'No'}/>
            <BasicDetailRow label='Comment' value={app.description}/>
            <BasicDetailRow label="First Name" value={eContact['first name']}/>
            <BasicDetailRow label="Last Name" value={eContact['last name']}/>
            <BasicDetailRow label="Email" value={eContact.email}/>
            <BasicDetailRow label="Phone" value={eContact['phone number']}/>
            <BasicDetailRow label="Working Days" value={availability}/>
            <BasicDetailRow label="Applied At" value={getDateTimeString(app.modified)}/>
        </div>
    );
}
