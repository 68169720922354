import { removeMember } from "@/apis/base/organizations/removeMember";
import { ROUTE_IDS } from "@/apis/base/routeId";
import { MILLISEC } from "@/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useRemoveMember(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.REMOVE_MEMBER],
        mutationFn: removeMember,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['org' , req?.orgid]})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}