import { numberWithCommas } from "@/utils";

export function allCaps(str) {
    return str?.toUpperCase?.();
}

export function randomString(length) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const randomArray = new Uint8Array(length);
  crypto.getRandomValues(randomArray);
  randomArray.forEach((number) => {
    result += chars[number % chars.length];
  });
  return result;
}

export function abbreviateNumber(value) {
  let safeValue = Number(value)
  if (isNaN(safeValue)) return value
  const suffixes = ["", "K", "M", "B", "T"];
  if (value > 1000000)
    return (value / 1000000).toPrecision(3) + "M";
  if (value > 1000)
    return (value / 1000).toPrecision(3) + "K";
 return value 
  
}

export function commaifyNumberStr(number) {
  const safeNumStr = String(number)
  const ret = numberWithCommas(safeNumStr?.replace(/[^0-9.]/g, ''))
  return ret
}

export function formatToPriceStr(number) {
  const endsWithDec = number?.endsWith?.('.')
  const asNum = Number(number.replace(/[^0-9.]/g, ""))
  const formatted = 
    asNum?.toLocaleString?.(undefined, 
        {minimumFractionDigits: 0, maximumFractionDigits: 2}) 
  const ret = formatted + (endsWithDec ? '.' : '')
  
  return ret
}
