import Swal from 'sweetalert2'

export const FailToast = Swal.mixin({
	toast: true,
	position: 'top-end',
	icon: 'error',
	target: '#popuptarget',
	customClass: {
		// container: 'absolute  top-0 right-0 h-fit ',
		popup: "!bg-red-100 ",
		timerProgressBar: '!bg-red-400',
        title: '!text-red-900',

	},
	showConfirmButton: false,
	timerProgressBar: true,
	timer: 2500,
	showClass: {
		popup: 'animate-in-toast'
	},
})

