import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS,composeFormData } from "@/apis/base/routeId"


export const getWishlistTree = (params) => {
    const data = new FormData();
    data.append('type', ROUTE_IDS.GET_WISHLIST_TREE);
    return postPromise(config.API_BASE_URL, data)
};
