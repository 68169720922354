import { FieldError, FieldPath, FieldValues, RegisterOptions, UseFormProps, UseFormRegisterReturn, UseFormReturn, useForm } from 'react-hook-form';

type CustomUseFormRegisterReturn<TFieldName extends string = string> =                          
    UseFormRegisterReturn<TFieldName> & {
        error: FieldError | undefined;
};

type CustomUseFormRegister<TFieldValues extends FieldValues> = <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(name: TFieldName, options?: RegisterOptions<TFieldValues, TFieldName>) => CustomUseFormRegisterReturn<TFieldName>;

type CustomUseFormReturn<TFieldValues extends FieldValues = FieldValues, TContext = any, TTransformedValues extends FieldValues | undefined = undefined> = Pick<UseFormReturn<TFieldValues,TContext,TTransformedValues>, Exclude<keyof UseFormReturn<TFieldValues,TContext,TTransformedValues>,'register'>> & {
    register: CustomUseFormRegister<TFieldValues>;
};

// type CustomUseFormProps<TFieldValues extends FieldValues = FieldValues, TContext = any> = UseFormProps & {validationSchema: Yup.AnyObject, ValidationProps}


export function useRHF({...props}: UseFormProps): CustomUseFormReturn {
    const methods = useForm({mode:'onBlur', criteriaMode:'all', ...props})
    // function register<TFieldValues extends FieldValues TFieldName extends FieldPath<TFieldValues>>(name: TFieldName , options?: RegisterOptions<TFieldValues,TFieldName>) : CustomUseFormRegisterReturn {
    //     return {
    //         ...methods.register(name,options),
    //         error: methods.getFieldState(name).error
    //     }
    // }
    const register: CustomUseFormRegister<FieldValues> = function(name ,options)  {
        return {
            ...methods.register(name, options),
            error: methods.formState.errors?.[name] as FieldError | undefined
        }
    }
    return {
        ...methods,
        register
    }
}