import { UserManagementPage } from "@/components/pages/UserManagementPage";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { useUserContext } from "../../../../context/UserContext";
import { useTranslations } from "../../../../hooks/translations";
import { PageTitle } from "@/components/Label/PageTitle";

const ProcessorUser = () => {
    const orgContextQuery = useCurrentOrgContext();
    return (
        <div className="flex flex-col gap-10">
            <PageTitle>
                Members
            </PageTitle>
            <UserManagementPage standardCommunity={false} orgid={orgContextQuery.data?.orgid}/>

        </div>
    );
}

export default ProcessorUser;