
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getWishlistTree } from '@/apis/base/wishlist/getWishlistTree';
import { func } from 'prop-types';
import { recursiveSubnodeHelper } from '@/utils';


export default function useWishlistTree() {
    const query = useQuery({
        queryKey: ['wishlist',ROUTE_IDS.GET_WISHLIST_TREE],
        queryFn: getWishlistTree,
        staleTime: MILLISEC.MIN_15,
        select
    });
    return query;
}

function select(data) {
    const clone = structuredClone(data)
    recursiveSubnodeHelper(
        {subnodes: clone},
        (currArr) => {
            currArr?.forEach ((subnode, idx) => {
                if (Array.isArray(subnode))
                    currArr[idx] = subnode[0];
            })
        },
        true,
        true
    )
    return clone
}
