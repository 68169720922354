
export const prefetchQueries = (client, configs) => queryHelper(client, configs, false)

export const fetchQueries = (client, configs) => queryHelper(client, configs, true)

export const removeContextQueries = (client) => {
    client.removeQueries({queryKey: ['context']})
}

const queryHelper = (client, configs, shouldRetData) => {
    const safeConfigs = Array.isArray(configs) ? configs : [configs]
    if (client == null || configs == null || safeConfigs?.length < 1 ) return
    const promises = []
    safeConfigs.forEach((c) => {
        promises.push(
            shouldRetData ? 
                client.fetchQuery(c) :
                client.prefetchQuery(c)
            )
    })
    return Promise.all(promises)
}



export function orgArrayContainsQueryFilter(query) {
    console.log('query', query?.queryKey)
    return false

}