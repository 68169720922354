import React from 'react';


export const BasicToggleButton = ({active, onClick, className, disabled, ...props}) => {
    return (
        <div 
            className={`
             rounded-full aspect-square ring-1 flex items-center justify-center  w-full h-full
            ${active ? 
                'text-blue-cornflower stroke-blue-cornflower border-blue-cornflower ring-blue-cornflower' 
                : 'text-gray-subText stroke-gray-subText border-gray-border ring-gray-border'}
            ${disabled ? 'opacity-50' : 'hover:cursor-pointer'}
            ${className}`} {...props}
            onClick={disabled ? null : onClick}>
            {props.children }
        </div>
    );
};

