import { getOrgRoles } from '@/apis/base/enum/getOrgRoles';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';


export default function useOrgRoles() {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_ORG_ROLES],
        queryFn: getOrgRoles,
        staleTime: MILLISEC.HOUR,
        gcTime: MILLISEC.HOUR,
        select: (data) => {
            const roleEnum = {};
            data.forEach(role => {
                    roleEnum[role.name] = {
                        ...role
                    };
            })
            return roleEnum
        }
    });
    return query;
}