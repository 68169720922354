import React from "react";

import { MainTabs } from "@/components/Tabs";
import { userTypes } from "@/constants/index";

import Current from "./Current";
import Requests from "./Requests";
import { BasicTabs } from "@/components/Tabs/BasicTabs";
import { VIPMemberTable } from "@/components/Tables/VIPMemberTable/VIPMemberTable";
import { VIPMembers } from "@/views/pages/Main/Members/VIPMembers";

const adminTabNames = ["Current", "Requests"];
// const memberTabNames = ["Current"];
function SubPages({ context, text, getAccountType, types, states }) {
  const tabs = context?.isAdmin ? [text.current, text.requests] : 
    context?.isGuest ? [text.current] : []
    
  
  return context?.isAdmin ? (
    <BasicTabs tabNames={tabs} autonomous>
      <Current text={text} context={context} getAccountType={getAccountType} />
      <Requests text={text} />
    </BasicTabs>
  ) : (
    <VIPMembers 
      ctx={context}
    />
  );
}

export default SubPages;
