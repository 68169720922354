import React, { forwardRef } from 'react';


export const BasicDetailInput = forwardRef(function({label, value, onChange, placeholder, containerClassName, className, ...props},ref)  {
    return (
        <div className={`flex flex-row ${containerClassName}`}>
                <div className="w-1/3 text-gray-subText">
                    {label}
                </div>
                <input ref={ref} {...{value, onChange}} className={`w-2/3 input input-little input-bordered focus:outline-none ${className}`} {...props}/>
                
            </div>
    );
});

