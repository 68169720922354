import { TrackedImage } from '@/components/Image/TrackedImage';
import { getWishlistUrls } from '@/utils';
import React from 'react';

// TODO: Add a rotating Carousel or dropdown multiple images
export const WishlistDropdown = ({images, ...props}) => {
    const [imageIndex, setImageIndex] = React.useState(0)
    const realSrc = getWishlistUrls(images)
    function handleLoadError(e) {
        if (imageIndex < realSrc.length - 1) {
            setImageIndex(imageIndex + 1)
        } else {
            e.target.onerror = null
            e.target.src = '/assets/images/placeholder.png'
        }
    }
    return (
        <div className="dropdown static dropdown-hover ">
            <TrackedImage 
                src={realSrc?.[imageIndex]}
                onError={handleLoadError}
                alt=""
                className='w-16 object-cover aspect-product rounded-md border-3'/>
            <div className='dropdown-content  pointer-events-none w-48  mb-1 mx-auto z-[99] shadow-all-light'> 
                <TrackedImage 
                    src={realSrc?.[imageIndex]} 
                    alt={"inventory" + 0}
                    className='w-full object-cover rounded-md' />
            </div>
        </div>
    );
};
