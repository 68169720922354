import { OrgCell } from "@/components/Tables/Cells/OrgCell";
import { UserCell } from "@/components/Tables/Cells/UserCell";
import { getDateString, pick, subObject } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";


const columnHelper = createColumnHelper();
const stringFilter = filterFns.includesString;
const inverseOneOrMoreFilter = (props) => !filterFns.arrIncludesSome(...props)

export const AllOrgsWishlistCols = [
columnHelper.accessor((row) => row.id, {
    id: "ID",
    size: 100,
    enableGlobalFilter: false,
    enableColumnFilter: false,
    meta: {
      hiddenCol:true
    },
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>ID</span>,
  }),
  columnHelper.accessor(row => row.processor === 'true', {
    id: "Processor",
    meta: {hiddenCol:true, hiddenFilter:true},  
    filterFn:filterFns.equals,
  }),
  columnHelper.accessor(row => row.brand === 'true', {
    id: "Brand",
    meta: {hiddenCol:true, hiddenFilter:true},  
    filterFn:filterFns.equals,
  }),
  columnHelper.accessor(row => row.corporation === 'true', {
    id: "Corporation",
    meta: {hiddenCol:true, hiddenFilter:true},  
    filterFn:filterFns.equals,
  }),
columnHelper.accessor((row) => row.name, {
    id: "Organization",
    size: 100,
    filterFn: stringFilter,
    cell: ({row}) => <OrgCell org={row.original} style={{paddingLeft: `${row.depth * 2}rem`}}/>,
    header: (info) => <span>ORGANIZATION</span>,
  }),
columnHelper.accessor((row) => row.ownerData, {
    id: "Master Admin",
    size: 100,
    filterFn: stringFilter,
    cell: ({getValue}) => <UserCell hasUserDetail={false} showDropdown={false} row={getValue()}/>,
    header: (info) => <span>MASTER ADMIN</span>,
  }),
columnHelper.accessor((row) => row.FINs || 0, {
    id: "FINs",
    size: 100,
    cell: ({getValue}) => <span>{getValue()}</span>,
    header: (info) => <span>FINS</span>,
  }),
columnHelper.accessor((row) => row.members?.length + row.admins?.length || 0, {
    id: "Members",
    size: 100,
    cell: ({getValue}) => <span>{getValue()}</span>,
    header: (info) => <span>Members</span>,
  }),
columnHelper.accessor((row) => row.name, {
    id: "Community Reach",
    size: 100,
    enableColumnFilter: false,
    enableGlobalFilter: false,
    cell: ({getValue}) => <span>{'–'}</span>,
    header: (info) => <span>COMMUNITY REACH</span>,
  }),
columnHelper.accessor((row) => row.submissiontimestamp, {
    id: "Last Updated",
    sortUndefined:true,
    size: 100,
    meta: {
      exportFn: (row) => getDateString(row)
    },
    enableColumnFilter: false,
    cell: ({getValue}) => (
    <span>
      {getValue() != null ?  getDateString(getValue()) : '–'}
    </span>
    ),
    header: (info) => <span>LAST UPDATED</span>,
  }),
columnHelper.accessor((row) => row.submissiontimestamp != null, {
    id: "Master Admin Completed",
    size: 100,
    sortUndefined:true,
    filterFn: stringFilter,
    cell: ({getValue}) => <span>{getValue() ? 'Yes' : 'No'} </span>,
    header: (info) => <span>MASTER ADMIN COMPLETED</span>,
  }),
columnHelper.accessor((row) => row?.['region id'], {
    id: "Region ID",
    size: 100,
    filterFn: stringFilter,
    meta: {
      hiddenCol:true,
      hiddenFilter:true,
    },
    cell: ({getValue}) => <span>{getValue()}</span>,
    header: (info) => <span>REGION ID</span>,
  }),
columnHelper.accessor((row) => row?.['city id'], {
    id: "City ID",
    size: 100,
    meta: {
      hiddenCol:true,
      hiddenFilter:true,
    },
    filterFn: stringFilter,
    cell: ({getValue}) => <span>{getValue()}</span>,
    header: (info) => <span>CITY ID</span>,
  }),
]

