import React, { useEffect, useMemo } from "react";

import { PageTitle } from "@/components/Label/PageTitle";
import { BasicTabs } from "@/components/Tabs/BasicTabs";
import useBDEventByID from "@/hooks/state/donation/useBDEventByID";
import useBDStatusEnum from "@/hooks/state/enum/useBDStatusEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { useTranslations } from "@/hooks/translations";
import {
    ArrowBack,
    LocationOn
} from "@material-ui/icons";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { BrandDonationEligibleOrganizations } from "./BrandDonationEligibleOrganizations";
import BrandDonationRegion from "./BrandDonationRegion";
import { BasicBackButton } from "@/components/Buttons/BasicBackButton";

function BrandDonationLocations({history, match}) {
    const donationID = match.params.id;
    const {eventid, orgid} = useParams();
    const state = history?.location?.state
    const {eventName} = state || {}
    const text = useTranslations("brandDonationCities");
    const [cookies, setCookie] = useCookies(['defaultEventEligibilityTab']);
    const [activeTab, setActiveTab] = React.useState(0);
    const tabs = [text.region, text.communityOrganization];
    const ctx = useCurrentOrgContext().data
    const eventQuery = useBDEventByID({eventid, orgid});
    const eventStatusQuery = useBDStatusEnum()

    useEffect(() => {
        if (cookies.defaultEventEligibilityTab) 
            setActiveTab(cookies.defaultEventEligibilityTab);
        else  setActiveTab(0)
    }, [])

    const changeTab = (tab) => {
        setCookie('defaultEventEligibilityTab', tab);
        setActiveTab(tab);
    }
    
    const handleBack = () => {
        history.goBack();
    }

    const canModify  = useMemo(() => {
        if (!eventQuery.data || !eventStatusQuery.data || !ctx) return false;
        const isTheEventProcessor = ctx.processor === eventQuery.data.processor;
        const eventNotLive = !eventStatusQuery.data.liveStatuses?.includes?.(eventQuery.data.status); 
        return  ctx?.isUICContext || 
                ((isTheEventProcessor || !ctx.processor) && (eventNotLive))
    },[eventQuery.data, eventStatusQuery.data])

    return (
        <div className="flex flex-col gap-5">
            <div className="w-full h-full flex flex-col gap-5">

                <div className="flex w-1/2 justify-between">
                    <span className="text-4xl font-semibold text-black">
                            <BasicBackButton onClick={handleBack} className={'absolute'} label={'Back to Donations' }/>
                    </span>
                    <div className="justify-self-end align-bottom"></div>
                </div>

                <span className="flex justify-start">
                    <PageTitle className={'w-fit'}>
                        <span>
                            Event Availability – <span className="font-medium">{eventName}</span>
                            <LocationOn className="w-8 h-8 ml-2 mt-1"/>
                        </span>
                    </PageTitle>
                </span>
                
                <div>
                    <BasicTabs activeTab={activeTab} onTabClick={(tab) => {changeTab(tab)}} tabNames={tabs} className={'mb-5'}>  
                        <BrandDonationRegion text={text}  canModify={canModify}/>
                        <BrandDonationEligibleOrganizations canModify={canModify}/>
                        {/* <BrandDonationCommunityTypes text={text} donationId={donationID}/>
                        <BrandDonationOrganization text={text} donationId={donationID}/> */}
                    </BasicTabs>
                </div>
            </div>
        </div>
    );
}

export default BrandDonationLocations;
