export function getRedirectPath(context) {

    if (context == null) return '/welcome'
    let ret 
    if (context?.isUICContext) ret =  'admin'
    else if (context?.brand) ret =  'brand'
    else if (context?.processor) ret =  'processor'
    else if (context?.corporation) ret =  'corporation'
    else ret =  '/'
    console.log("redirect path is ", ret)
    return ret

}