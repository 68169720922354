import { createColumnHelper, filterFns } from "@tanstack/react-table";
const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const brandPrefRegionalReportCols = (regions, useTimespan) => {
    const regionCols =  regions?.sort(regionCompare)?.map?.((r) => {
        return columnHelper.accessor((row) => row.regionObj?.[r.id]?.votes || 0, {
            id: r?.['region code'],
            size: 20,
            enableColumnFilter: false,
            cell: (info) => <span>{info.getValue()} </span>,
            header: (info) => <span>{r?.['region code']}</span>,
        })
    }) || []
    return  [
        columnHelper.accessor((row) => row.name, {
            id: "Brand",
            size: 100,
            filterFn: stringFilter,
            cell: (info) => <span>{info.getValue()}</span>,
            header: (info) => <span>BRAND</span>,
        }),
        ...regionCols,
        columnHelper.accessor((row) => row.votes || 0, {
            id: "Total",
            size: 50,
            cell: (info) => <span>{info.getValue()}</span>,
            header: (info) => <span>TOTAL</span>,
        }),
        ...( useTimespan ? [
            columnHelper.accessor((row) => row.votesTimespan || 0, {
                id: "Total Timespan",
                size: 50,
                cell: (info) => <span>{info.getValue()}</span>,
                header: (info) => <span>TOTAL IN TIMESPAN</span>,
            })
        ] : []

        )
    ]
}

function regionCompare(a,b) {
    if (a?.['region code'] < b?.['region code']) {
        return -1;
    }
    if (a?.['region code'] > b?.['region code']) {
        return 1;
    }
    return 0;
}