
import { BasicDialog } from "@/components/Dialog/BasicDialog";
import { BasicInput } from "@/components/Input/BasicInput";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";
import useInviteMember from "@/hooks/state/invite/useInviteMember";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { useYupValidationResolver } from "@/hooks/utils/useYupValidationResolver";
import { closeDialogWithRef } from "@/utils";
import { checkFormForAutofill } from "@/utils/misc";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { FormBasicOrgDropdown } from "../Input/BasicOrgDropdown";
import { BasicToggle } from "../Input/BasicToggle";

const validationSchema = yup.object({
    orgid: yup.string().transform(o=>o.id).required("Please select an organization"),
    firstname: yup.string().required("Please input the recipient's first name"),
    lastname: yup.string().required("Please input the recipient's last name"),
    email: yup.string().email().required("Please input the recipient's email"),
    admin: yup.boolean(),
})

const defaultValues = {
  orgid: {},
  admin: false,
  firstname: '',
  lastname: '',
  email: '',
}

const roleids = [
  {id: 1, name: 'ambassador', label: 'Ambassador'},
  {id: 2, name: 'smi', label: 'SMI'},
  {id: 3, name: 'fin', label: 'FIN'},
  {id: 4, name: 'guardian', label: 'Guardian'},
]

export const UserInvitationDialog = ({orgsData, dialogRef}) => {
  const contextQuery = useCurrentOrgContext()
  const roleEnumQuery = useRoleEnum()
  const roleEnum = roleEnumQuery.data
  const inviteMutation = useInviteMember({
    onSuccess: (data) => {SuccessToast.fire({text: "Invitation successful",})},
    onError: () => {FailPopup.fire({text:'Failed to invite user'})}
  })
  const [errors, setErrors] = useState({
    org: ''
  })

  const resolver = useYupValidationResolver(validationSchema)
  const {register, handleSubmit, formState, setValue, reset, control} 
    = useForm({resolver, defaultValues, mode: 'onBlur'})
  const showRoleToggles =  contextQuery.data?.standardCommunity
  
  function onSubmit(fields) {
    const {
      admin,
      firstname,
      lastname,
      email,
    } = fields;
    const {orgid} = fields || contextQuery.data?.orgid;
    const roles = [];
    for (const role of roleids) {
      if (fields[role.name]) roles.push(roleEnum[role.name]);
    }
    const params = {
      roleids: roles,
      orgid: orgid,
      recipients: [{
          firstname,
          lastname,
          email,
      }],
      admin,
    }
    reset()
    inviteMutation.mutate(params);
    closeDialogWithRef(dialogRef);
    
  }
    return (
        <BasicDialog 
          ref={dialogRef}
          id="inviteModal"
          title="Invite User"
          handleSubmit={(e) => {checkFormForAutofill(formState,handleSubmit(onSubmit))}}
          onClose={() => reset()}
        >
          <FormBasicOrgDropdown 
            control={control}
            required
            isSearchable 
            placeHolder={"Pick an Organization"} 
            name="orgid"
            orgs={orgsData} 
            {...register('orgid')}
          />

          
          <div className="flex flex-row mt-5 mb-1 justify-between items-center">
              <p >{`Make user an admin?`}</p> 
              <label className="label cursor-pointer">
                <BasicToggle  name={'admin'} {...register(`admin`)} />
              </label>
          </div>
    
          { showRoleToggles && roleids.map((role, index) =>

            <div key={role.id} className="flex flex-row my-1 justify-between items-center">
                  <p  >{`Give user ${role.label} role?`}</p>
                  <label className="label cursor-pointer">
                    <BasicToggle  name={`roleids[${role.name}]`} {...register(role.name)} />
                  </label>
              </div>
       
          )}
            <BasicInput
                required
                label='First Name'
                error={formState.errors.firstname}
                {...register('firstname')}
            />
            <BasicInput
                required
                label='Last Name'
                error={formState.errors.lastname}
                {...register('lastname')}
            />
            <BasicInput
                required
                label='Email'
                type='email'
                onChange={(e) =>{console.log('changing email',);setValue('email', e.target.value)}}
                error={formState.errors.email}
                {...register('email')}
            />
        </BasicDialog>
    )
}