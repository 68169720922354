import { EditButton } from "@/components/Buttons";
import { SendIcon } from "@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons";
import { CheckIcon, RequestIcon, TreeIcon } from "@/components/Icons";
import { HoverHint } from "@/components/Misc/HoverHint";
import { getDateString } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { OrgCell } from "../Cells/OrgCell";


const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove

const myFilter = (stuff) => {
  return false
}
export const shipmentsColumnHelper = (inventories) =>{ 
  const inventoryCols = (inventories.map((inv) => (
    columnHelper.display({
      id: inv.productname,
      size: 100,
      filterFn: stringFilter,
      cell: ({row, table}) => 
        <Quantities
          meta={table.options.meta}
          lineitem={{
            id: inv.id, 
            orgid: row.original.orgid,
            ...row.original.lineitems[inv.id]
          }}   
        />,
      header: (info) => <span className="whitespace-pre-wrap">
      <div className="indicator indicator-top indicator-end">
        {/* <p className="indicator-item badge  text-[0.6rem]">{'(product)'}</p> */}
      </div>
      {inv.productname?.toUpperCase?.()}
        </span>,
    })
  ))) || []
  let cols = [
    // columnHelper.accessor((row) => row.id, {
    //   id: "ID",
    //   size: 100,
    //   filterFn: stringFilter,
    //   cell: (info) => <span>{info.getValue()}</span>,
    //   header: ({info}) => <span>ID</span>,
    // }),
    columnHelper.accessor(row => row.orgname, {
      id: "Organization",
      size: 100,
      cell: ({row}) => <OrgCell className={'mb-7'} org={{id: row.original.orgid, name: row.original.orgname}} />,
      header: (info) => <span>ORGANIZATION</span>,
    }),
    columnHelper.accessor(row => row.modified, {
      id: "Modified At",
      size: 100,
      enableColumnFilter: false,
      cell: ({row}) => <ModifiedAt original={row.original}/>,
      header: (info) => <span>MODIFIED AT</span>,
    }),
    columnHelper.accessor(row => row['modified by'], {
      id: "Modified By",
      size: 100,
      enableColumnFilter: false,
      cell: ({row,table}) => <ModifiedBy userNames={table.options.meta.userNames}  original={row.original}/>,
      header: (info) => <span>MODIFIED BY</span>,
    }),
    ...inventoryCols,
    columnHelper.display({
      id: 'Actions',
      size: 50,
      cell: ({table, row}) => <Actions 
        table={table}
        row={row}
      />,
      header: (info) => <span>ACTIONS</span>,
    })
  ]
  return cols
}

const Actions = ({ row, table}) => {
  const {onTreeClicked, onEditClicked, canModify} = table.options.meta
  return (
    <div className="flex justify-between -mx-3 w-[5.5rem]">
         { !row.original.confirmedby && canModify &&
          <HoverHint>
            <EditButton buttonProps={{
              onClick: () => onEditClicked(row.original),
              className:'',
               }} />
          </HoverHint>
         }

          <div className="w-1"></div>
          <HoverHint>
            <button className="btn btn-ghost btn-little btn-circle" 
              onClick={() => onTreeClicked(row.original)}
            >
                <TreeIcon  className=''/>
            </button>
          </HoverHint>
      </div>
    )
  }

const ModifiedAt = ({original}) => (
  <div className="h-10 form-control gap-1 justify-center">
    <span className='mb-auto flex flex-nowrap'>
      <RequestIcon className='w-4 h-4 mr-1'/>
      {
        original?.requestmodified ? 
        getDateString(Number(original?.requestmodified))
        : '-'
      }
      </span>
    <span className='text-red-600 flex flex-nowrap'>
      <SendIcon className='w-4 h-4 mr-1'/>
      {
        original?.shipmentmodified ?
        getDateString(Number(original?.shipmentmodified))
        : '-'
      }
    </span>
    <span className="text-success-content flex flex-nowrap">
      <CheckIcon className='w-4 h-4 mr-1'/>
      {
        original?.confirmedtime ?
        getDateString(Number(original?.confirmedtime))
        : '-'
      }
    </span>

  </div>
)

const ModifiedBy = ({original, userNames}) => (
  <div className="h-10 w-fit form-control whitespace-nowrap gap-1 justify-center">
    <p className='mb-auto'>{userNames[original?.requestmodifier]?.name || '-'}</p>
    <p className='text-red-600 mt-auto'>{userNames[original?.shipmentmodifier]?.name || '-'}</p>
    <p className="text-success-content">{userNames[original?.confirmedby]?.name || '-'}</p>
  </div>
)

const Quantities = ({lineitem, meta}) => {
  const {inlineEditing, handleInlineBlur, canEditRequest, canEditShipment} = meta || {}
  const editingRequest = inlineEditing && canEditRequest
  const editingShipment = inlineEditing && canEditShipment && 
    (lineitem?.qtyconfirmed == null) &&
    (lineitem?.requestquantity != null)
  const editingConfirmed = editingRequest && (parseInt(lineitem?.shipmentquantity) > 0)
  // console.log('object', lineitem);
  const partiallyReceived = 
    Number(lineitem?.qtyconfirmed) < Number(lineitem?.shipmentquantity)
  return (
    <div className="h-10 form-control w-fit gap-1 justify-center">
        { editingRequest ?  (
          <input 
            onChange={() => {}}
            key={`${lineitem?.id}${lineitem?.requestquantity}`}
            type="number" 
            className='input input-xs border border-gray-border text-sm max-w-[5rem] focus:outline-none'
            defaultValue={parseInt(lineitem?.requestquantity) || 0}
            name='req'
            onBlur={(e) => handleInlineBlur(e, 'requestquantity',lineitem)}/>
        ) : (
            <p className={`${inlineEditing ? 'ml-2' :''} mb-auto`}>{lineitem?.requestquantity || '-'}</p>
        )}
        <div 
            className={`indicator form-control 
              ${partiallyReceived ? 'outline outline-warning rounded-md outline-offset-[3px] w-full' : ''} `}
            >
          { editingShipment ? (
            <input 
              onChange={() => {}}
              key={`${lineitem?.id}${lineitem?.shipmentquantity}`}
              type="number" 
              className='input input-xs border border-gray-border text-sm max-w-[5rem] focus:outline-none'
              defaultValue={parseInt(lineitem?.shipmentquantity) || 0}
              name='ship'
              onBlur={(e) => handleInlineBlur(e, 'shipmentquantity', lineitem)}/>
          ) : (
              <p className={`${inlineEditing ? 'ml-2' :''} text-red-600 mt-auto`}>{lineitem?.shipmentquantity || '-'}</p>
          )}
          { editingConfirmed ? (
            <input 
              onChange={() => {}}
              key={`${lineitem?.id}${lineitem?.qtyconfirmed}`}
              type="number" 
              className='input input-xs border border-gray-border text-sm max-w-[5rem] focus:outline-none'
              defaultValue={parseInt(lineitem?.qtyconfirmed) || 0}
              name='ship'
              onBlur={(e) => handleInlineBlur(e, 'qtyconfirmed', lineitem)}/>
          ) : (
            <p className={`${inlineEditing ? 'ml-2' :''} text-success-content mt-auto`}>{lineitem?.qtyconfirmed || '-'}</p>
          )}
          { partiallyReceived && (
            <div className="indicator-item indicator-bottom text-sm -bottom-2 badge badge-sm badge-warning">
              Not all received
            </div>
          )}
        </div>
    </div>
)}