import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addOrgAdmin } from '@/apis/base/admin/addOrgAdmin';
import { orgArrayContainsQueryFilter } from '@/utils/queryUtils';

export default function useAddOrgAdmin(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.ADD_ORG_ADMIN],
        mutationFn: addOrgAdmin,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            if (data?.status === 'organization admin not added.') 
                throw new Error(data.status)
            // if (getCookie('orgIdContext') === req.orgid)
            //     client.invalidateQueries({queryKey: ['context']})
            client.invalidateQueries({queryKey: ['org',req.orgid]})
            client.invalidateQueries({queryKey: ['org',[req.orgid]]})
            // client.invalidateQueries({predicate: orgArrayContainsQueryFilter})
            console.log('hi');
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}