import { submitRoleApp } from '@/apis/base/applications/submitRoleApp';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';


export default function useSubmitRoleApp({onSuccess, onError,...props}) {
    const client  = useQueryClient()
    const query = useMutation({
        mutationKey: [ROUTE_IDS.SUBMIT_ROLE_APPLICATION],
        mutationFn: submitRoleApp,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['context','role']})
            client.invalidateQueries({queryKey: ['context', 'org', req.orgid]})
            onSuccess(data)
        },
        onError,
        ...props
    });
    return query;
}