import React from 'react';

export const CuratorShareHoverCard = ({post, ...props}) => {
    return (
        <div className='relative group'>
            <svg className='-scale-x-100' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10 9V5l-7 7l7 7v-4.1c5 0 8.5 1.6 11 5.1c-1-5-4-10-11-11"/></svg>
            <div className="invisible absolute group-hover:visible -translate-x-full -translate-y-full top-0 left-0 bg-white shadow-even">

            </div>
        </div>
    );
};
