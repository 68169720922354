import { arrayIncludedExcluded, arrayIntersect, blurActive } from "@/utils/misc";
import { Close } from "@material-ui/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";

const Icon = (props) => {
  return (
    <svg  {...props} height="15" width="15" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="15" width="15" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

export const POSITION = {
  TOP: "top",
  END: "end",
  BOTTOM: "end",
  START: "start"
}

export const MULTI_SELECT_DROPDOWN_TYPE = {
  DEFAULT: 'default',
  WISHLIST: 'wishlist',
}

const defaultButtonClasses = (className, isError=false, enabled=true) => 
  `${className} w-fit focus:border-primary rounded-btn border place-items-center inline-flex 
  md:px-3 md:py-2 md:text:sm gap-0.5 text-xs md:font-medium px-1.5 py-0.5  no-animation font-base font-normal bg-transparent select-none justify-between md:gap-2 whitespace-nowrap flex-nowrap
  ${isError ? 'border-[rgba(223,66,66,0.78)] hover:border-[rgb(223,66,66)]' : 'border-gray-border'}
  ${enabled ? '' : 'bg-gray-300'}
  `


const wishlistButtonClasses = (className, enabled=true) => `
    
`

const defaultDropdownClasses = () => `
  border border-gray-border w-fit rounded-box form-control gap-1 flex-nowrap  max-h-[30rem] py-5  bg-white font-normal
`

const wishlistDropdownClasses = () => `
  
`

const MultiSelectDropdown = (props) =>{ 
  return <UnwrappedDropdown {...props}/>
}


const UnwrappedDropdown = (props) => {
  const {  
    type = MULTI_SELECT_DROPDOWN_TYPE.DEFAULT,
    className = "w-fit",
    required = true,
    selectedIDs = [],
    IDFn = (option) => option?.id,
    renderFn= (option) => option?.name,
    subnodeFn = (option) => option?.subnodes,
    placeHolder=<div className="w-1"> </div>,
    options = [],
    isSearchable,
    onToggle=()=>{},
    isError = false,
    position = POSITION.BOTTOM,
    errorText=null,
    enabled = true,
    sorted = true,
    fixed = false,
    needsReset = false,
    setNeedsReset = () => {}} = props;

  const themeMenu = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef();
  const dropdownRef = useRef();
  const containerRef = useRef()
  const displayRef = useRef()

  const buttonClasses = useMemo(() => {
    switch (type) {
      case MULTI_SELECT_DROPDOWN_TYPE.WISHLIST:
        return wishlistButtonClasses(className, enabled)
      default:
        return defaultButtonClasses(className, isError, enabled)
    }
  },[type, className, isError, enabled])

  const dropdownClasses = useMemo(() => {
    switch (type) {
      case MULTI_SELECT_DROPDOWN_TYPE.WISHLIST:
        return wishlistDropdownClasses()
      default:
        return defaultDropdownClasses()
    }
  },[type, className, isError, enabled])

  useEffect(() => {
    if (containerRef.current)
      new ResizeObserver(handleResize).observe(containerRef.current);
  }, [containerRef.current]);

  useEffect(() => {
    if (needsReset) {
      setSearchValue("");
      setNeedsReset(false);
    }
  }, [needsReset]);
  

  function onBeforeFocus(e) {
    if (dropdownRef.current?.contains(document.activeElement)) {
      blurActive()
      e.preventDefault()
    }
  }
  const handleResize = () => {
    if (displayRef.current && containerRef.current) {
      displayRef.current.style.maxWidth = `${containerRef.current.offsetWidth}px`
    }
  }



  const onItemClick = (e, option) => {
    const subs = subnodeFn(option)
    if (subs?.length > 0) {
      { 
        const [inc, exc] = arrayIncludedExcluded(subs.map(IDFn), selectedIDs)
        const parentIsChecked = selectedIDs.includes(IDFn(option))
        if (parentIsChecked) 
          inc.forEach((id) => onToggle(subs.find((sub) => IDFn(sub) === id)))
        else
          exc.forEach((id) => onToggle(subs.find((sub) => IDFn(sub) === id)))
        inc.forEach((id) => console.log(subs.find((sub) => IDFn(sub) === id)))
        exc.forEach((id) => console.log(subs.find((sub) => IDFn(sub) === id)))
        onToggle(option);
      }
    } 
    else   
      onToggle(option);
  };
  
  const isSelected = (option) => {
    return selectedIDs?.includes?.(IDFn(option));
  };


  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return sorted && Array.isArray(options) 
        ? options.sort(compare)
        : options;
    }

    return options.filter(
      (option) =>
        renderFn(option)?.length > 0 && renderFn(option).toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };
  
  const compare = (a,b) => {
    if (renderFn(a) < renderFn(b)){
      return -1;
    }
    if (renderFn(a) > renderFn(b)){
      return 1;
    }
    return 0;
  }
  let positionClasses
   switch (position) {
    case POSITION.TOP:
      positionClasses = 'bottom-16'
      break;
    case POSITION.BOTTOM:
      positionClasses = ''
      break;
    case POSITION.START:
      positionClasses = 'right-0'
      break;
    case POSITION.END:
      positionClasses = 'left-0'
      break;
    default:
      positionClasses = ''
      break;
  }

  
  return (
    <div 
      ref={dropdownRef}
      className={`dropdown group `}
      onMouseLeave={blurActive}
    >
      { isError &&
        <div className="label">
          <label className="label-text text-error">{errorText}</label>
        </div>
      }
      <div 
        key={'optionsContainer'}
        role="button"
        tabIndex={0} 
        className={buttonClasses} 
        onMouseDown={onBeforeFocus}
        ref={containerRef} 
      >
            {placeHolder}
            <Icon className={`transition-transform group-focus-within:rotate-180 duration-[250]`}/>
      </div>
            <ul 
              ref={themeMenu} 
              className={`menu menu-sm z-[20] overflow-y-scroll dropdown-content ${dropdownClasses}`}
            >
              { isSearchable && (
                <li className="hover:cursor-text pl-2">
                  <input
                    className="hover:cursor-text"
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={onSearch}
                    ref={searchRef}
                  />
                </li>
              )}
              
            { getOptions().map((option,idx) => (
                  <li key={IDFn(option) || idx}>
                    { subnodeFn(option)?.length > 0 ? (
                      <details  className="bg-white no-animation">
                        <summary  className="bg-white  no-animation hover:bg-primary-content hover:active:bg-primary w-full">
                          {renderFn(option)}
                          <input
                            readOnly
                            type="checkbox"
                            className="place-self-end checkbox checkbox-sm rounded-full"
                            checked={isSelected(option) || false} 
                            onClick={(e) => onItemClick(e,option)}

                            />
                        </summary>
                        <ul>
                            {
                              subnodeFn(option).map((sub, idx2) => (
                                <li
                                  key={IDFn(sub) || idx2} 
                                  value={IDFn(sub) || idx2} >
                                  <button
                                    tabIndex={0}
                                    className="flex gap-2 justify-between items-center  hover:bg-primary-content hover:active:bg-primary " onClick={(e) => onItemClick(e,sub)}
                                  >
                                    <p className="">{renderFn(sub)}</p>
                                    <input
                                      readOnly
                                      type="checkbox"
                                      className="checkbox checkbox-sm rounded-full "
                                      checked={isSelected(sub) || false} />
                                  </button>
                                </li>
                              ))
                            }
                        </ul>
                      </details>
                    ) : (
                      <button
                        tabIndex={0}
                        type="button"
                        onClick={(e) =>  onItemClick(e, option)}
                        className={`flex gap-5 w-full justify-between items-center text-sm px-2 py-2  cursor-pointer p-[5px]  font-light hover:bg-primary-content hover:active:bg-primary no-animation `} 
                      >
                        <p className="font-normal ">{renderFn(option)}</p>
                        <input
                          readOnly
                          type="checkbox"
                          className="checkbox  checkbox-sm rounded-full"
                          checked={isSelected(option) || false} />
                        
                      </button>)
                    }
                  </li>
            ))}
            </ul>
    {/* {position === POSITION.TOP  ? searchBarOrder.reverse() : searchBarOrder} */}

      {/* {showMenu &&  enabled && (
              <div className={`translate-y-1 w-[97%]  border overflow-auto max-h-[250px]   bg-white z-[10] rounded-2xl border-solid border-[#ccc] mt-3  overflow-y-scroll   
              ${fixed ? 'fixed' : 'absolute'} 
              ${positionClasses}`} key={'3'} ref={displayRef}>
              {position === POSITION.TOP  ? searchBarOrder.reverse() : searchBarOrder}
          </div>
        )} */}
    </div>
  );
}

export default MultiSelectDropdown;
