

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { getBDRequests } from '@/apis/base/donation/request/getBDRequests';


export default function useBDRequest({orgid, eventid}) {
    const query = useQuery(BDRequestQueryConfig({orgid, eventid}));
    return query;
}

export const BDRequestQueryConfig = ({orgid, eventid}) => queryOptions({
    queryKey: ['bd','event', eventid, 'org', orgid, ROUTE_IDS.GET_ORG_BD_REQUESTS],
    queryFn: (params) => getBDRequests({orgid, eventid}),
    enabled: !!orgid && !!eventid,
    staleTime: MILLISEC.MIN_15,
})