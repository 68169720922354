import React, { useEffect, useMemo } from 'react';

import General from './General';
import Support from './Support';

import { BasicTabs } from '@/components/Tabs/BasicTabs';
import { CommunityPreferences } from '../../CommunityPreferences';
import BrandPreference from "./BrandPreference";
import useMyOrgsData from '@/hooks/state/organization/useMyOrgsData';
import useMyUserData from '@/hooks/state/useMyUserData';

function SubPages({ text, history, org_id, user_type }) {
  const queryParams = new URLSearchParams(window.location.search);
  const active_tab = parseInt(queryParams.get("active"));
  const [activeTab, setActiveTab] = React.useState(active_tab < 5 ? active_tab : 0);
  const myOrgsQuery = useMyOrgsData({recursive: true})
  const myUserDataQuery = useMyUserData()
  const orgsIOwn = useMemo(() => {
    if (myOrgsQuery.isPending || myUserDataQuery.isPending) {
      return []
    }
    return myOrgsQuery.data?.filter?.(org => org.owner === myUserDataQuery.data?.id)
  },[])

  const hasAccessToCommunityManager = orgsIOwn?.length > 0 || myUserDataQuery.data?.isUICAdmin
  let tabNames = 
    hasAccessToCommunityManager
      ? [text.general, text.membership, text.brandPreference, text.support] 
      : [text.general, text.brandPreference, text.support] 

  useEffect(() => {
    if (myOrgsQuery.isPending || myUserDataQuery.isPending)
      return
    if (!hasAccessToCommunityManager && active_tab > 2) 
      onChangedTab(2)
  },[active_tab])

  const onChangedTab = (activeTab) => {
    history.push(`/account?active=${activeTab}`);
    setActiveTab(activeTab);
  }

  return (
    <BasicTabs tabNames={tabNames} activeTab={active_tab < 5 ? active_tab : 0} onTabClick={onChangedTab}>
      <General text={text} history={history}/>
      {hasAccessToCommunityManager && (
        <CommunityPreferences />
      )}
      <BrandPreference text={text}/>
      <Support {...{myUserDataQuery, myOrgsQuery,text}} />
    </BasicTabs>
  ) 
}

export default SubPages;