import { MILLISEC } from '@/constants';
import { getMyOrgs } from '@/apis/base/organization';
import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCookie } from '@/utils';
import { ROUTE_IDS } from '@/apis/base/routeId';

export default function useMyOrgs() {
    const myOrgs = useQuery(myOrgsQueryConfig());
    return myOrgs;
  }
  
  
  export const myOrgsQueryConfig = () => queryOptions({
    queryKey: ['context',ROUTE_IDS.GET_MY_ORGS],
    queryFn: getMyOrgs,
    staleTime: MILLISEC.MIN_15,
    enabled: getCookie("token") != null,
})