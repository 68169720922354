import { withStyles, makeStyles } from '@material-ui/core/styles';
import { InputBase, TextField, TextareaAutosize } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';

const AutoComplete = withStyles({
  root: {
    border: 'solid',
    borderColor: '#eee',
    borderWidth: 2,
    borderRadius: 2,
    paddingLeft: 10,
  },
  listbox: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  groupLabel: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },  
  inputRoot: {
    textDecoration: 'none',
  },
})(Autocomplete);

const TextInput = withStyles({
  root: {
    width: '100%',
    height: '39px',
    padding: '6px 16px',
    borderRadius: '6px',
    border: 'solid 2px #f0f0f0',
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.67px',
    color: '#2e2e2e',
  },
})(InputBase);

const SelectInput = withStyles({
  root: {
    width: '100%',
    height: '39px',
    borderRadius: '6px',
    border: 'solid 2px #f0f0f0',
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.67px',
    color: '#2e2e2e',
    '& .MuiSelect-selectMenu': {
      // paddingTop: '10px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  input: {
    padding: '0 0 0 16px',
    height: '39px',
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
})(InputBase);

// Search Input
const searchInputStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    
    maxWidth: '350px',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.67px',
    color: '#2e2e2e',
    boxShadow: 'none',
  },
}));

const SearchInput = ({
  value,
  placeholder,
  onChange,
  onRequestSearch,
  onCancelSearch,
  props
}) => {
  const classes = searchInputStyles();
  const [searchInput, setSearchInput] = useState('');

  const handleKeydown = (e) => {
    switch (e.key) {
      case 'Enter':
        onRequestSearch(searchInput);
      case 'Escape':
      onCancelSearch();
    }   
  };

  const handleChange = (e) => {
    setSearchInput(e.target.value);
    onChange(e.target.value);
  }

  return (
    <TextField
      size='small'
      className={classes.root}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      label={placeholder}
      onKeyDown={handleKeydown}
    />
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onRequestSearch: PropTypes.func,
  onCancelSearch: PropTypes.func,
};
SearchInput.defaultProps = {
  value: '',
  placeholder: 'Search',
  onChange: () => true,
  onRequestSearch: () => true,
  onCancelSearch: () => true,
};

const TextArea = (props) => {
  const { onChange } = props;

  return (
    <TextareaAutosize
      minRows={3}
      style={{ width: '100%', border: 'solid 1px darkgrey', padding: '2px' }}
      onChange={onChange}
    />
  );
};

export { AutoComplete, TextInput, SelectInput, SearchInput, TextArea };
