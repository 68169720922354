import React, { useState, useEffect } from 'react';

import { PasswordInput } from './components/Inputs';
import { BlackButton } from './components/Buttons';

import { useTranslations } from '../../../hooks/translations';
import { useAppContext } from '../../../context/AppContext';
import useCheckResetPasswordCode from '@/hooks/state/auth/useCheckResetPasswordCode';
import Swal from 'sweetalert2';
import useChangePassword from '@/hooks/state/auth/useChangePassword';

function Reset(props) {
  const { history, location } = props;
  const params = new URLSearchParams(location.search);
  const resetCode = params.get('reset-code');
  const email = params.get('email');

  const text = useTranslations('reset');
  const { setLoading, setMessage } = useAppContext();

  const [checked, setChecked] = useState(false);
  const [values, setValues] = useState({
    newPassword: '',
    confirmPassword: ''
  });

      // onSuccess: (response) => {
    //   setLoading(false);
    //   setMessage({ open: true, title: text.success, description: text.successChangePassword });
    //   props.history.push('/auth/login');
    // },

  const [validations, setValidations] = useState({
    newPassword: '',
    confirm_password: '',
  });
  const checkResetCodeQuery = useCheckResetPasswordCode({
    onError: (error) => {
      Swal.fire({
        title: text.error,
        text: text.badResetLink,
        icon: 'error',
        position: 'center',
      })  
    },
    onSuccess: (response) => {
      setChecked(true);
    }
  });
  const resetPasswordQuery = useChangePassword({
    onSuccess: (response) => {
      setLoading(false);
      Swal.fire({
        title: text.success,
        text: text.successChangePassword,
        icon: 'success',
        position: 'top-end',
        timer: 1500,
      }).then(() => {props.history.push('/auth/login')});
      props.history.push('/auth/login');
    },
    onError: (error) => {
      setLoading(false);
      Swal.fire({
        title: text.error,
        text: text.errorChangePassword,
        icon: 'error',
        position: 'center',
      }).then(() => {props.history.push('/auth/login')});
    },
  })

  const handleChange = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleContinue = () => {
    if (values.newPassword === '') {
      setValidations(prevState => ({ ...prevState, newPassword: 'has-empty' }));
      return;
    }
    else if (values.confirm_password === '') {
      setValidations(prevState => ({ ...prevState, confirm_password: 'has-empty' }));
      return;
    }
    else if (values.newPassword !== values.confirm_password) {
      setValidations(prevState => ({ ...prevState, confirm_password: 'has-error' }));
      return;
    }
    else if (values.newPassword.length < 6) {
      setValidations(prevState => ({ ...prevState, newPassword: 'has-error' }));
      return;
    }
    setLoading(true);
    resetPasswordQuery.mutate({ newpassword: values.newPassword, email: email, code: resetCode });
  };

  useEffect(() => {
    let data = {
      code: resetCode,
      email: email,
    }
    checkResetCodeQuery.mutate(data);
  }, [resetCode]);

  return (
    checked ? <>
      <form className="lg:my-25 sm:w-3/5 w-4/5">
        <div className="w-full">
          <h1 className="text-3xl font-bold text-gray-800">{text.resetPassword}</h1>
        </div>
        <div className="mt-4 w-full">
          <label className="text-gray-800">{text.enterPassword}:</label>
        </div>
        <div className="mt-9 w-full">
          <PasswordInput
            label={text.newPassword}
            value={values.newPassword}
            onChange={val => handleChange('newPassword', val)}
            error={validations.newPassword !== ''}
            helperText={validations.newPassword === 'has-empty' ? text.fieldRequired : validations.newPassword === 'has-error' ? text.passwordInvalid : ''}
          />
        </div>
        <div className="mt-6 w-full">
          <PasswordInput
            label={text.confirmPassword}
            value={values.confirm_password}
            onChange={val => handleChange('confirm_password', val)}
            error={validations.confirm_password !== ''}
            helperText={validations.confirm_password === 'has-empty' ? text.fieldRequired : validations.confirm_password === 'has-error' ? text.confirmPasswordInvalid : ''}
          />
        </div>
        <div className="mt-9 w-full">
          <BlackButton variant="contained" size="large" className="w-full" onClick={() => handleContinue()}>{text.continue}</BlackButton>
        </div>
      </form>
    </> : <></>
  );
}

export default Reset;
