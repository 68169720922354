import * as XLSX from 'xlsx'

type workSheet = {
    rows: any[],
    name: string
}

export function downloadExcel(workSheets: workSheet[], filename: string, columnWidths?: number[]) {
    const safeFileName = 
        filename.endsWith('.xlsx') ? filename : `${filename}.xlsx`
    const workbook = XLSX.utils.book_new()
    for (const sheet of workSheets) {
        const excelSheet = XLSX.utils.json_to_sheet(sheet.rows, )
        if (columnWidths){
            excelSheet['!cols'] = columnWidths?.map(width => ({width}))
        }        
        XLSX.utils.book_append_sheet(workbook, excelSheet, sheet.name)
    }
    XLSX.writeFile(workbook,safeFileName)
}