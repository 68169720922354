import React from 'react';


export const BasicPulseLoader = ({ ...props}) => {
    return (
        <div className="mx-auto flex justify-center">
            <div className="loading loading-lg loading-ring "/>
        </div>
    );
};

