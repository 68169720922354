import { DonationManagementPage } from "@/components/pages/DonationMangementPage";
import { BD_ROLES } from "@/constants";
import useBDEvents from "@/hooks/state/donation/useBDEvents";
import useBDStatusEnum from "@/hooks/state/enum/useBDStatusEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";


const BrandDonation = () => {
  const contextQuery = useCurrentOrgContext();
  const eventStatusesQuery = useBDStatusEnum()
  const donationQuery = useBDEvents({recursive: true, orgid: contextQuery?.data?.orgid, statusids: eventStatusesQuery.data?.allStatuses});
  const donations = donationQuery.data;
  return (
  <DonationManagementPage {...{role: BD_ROLES.BRAND,donations}}/>
)}

export default BrandDonation;