import React from 'react';
import { PlusIcon } from '../Icons';


export const BasicPlusButton = ({className, iconClassName, disabled,  ...props}) => {
    return (
        <button {...props} className={`
            btn  btn-sm btn-circle btn-outline  
            ${disabled ? 'btn-disabled' : ''}
        ${className}`}>
            <PlusIcon className={`stroke-accent hover:stroke-base-100 ${iconClassName}`}/>
            {props.children}
        </button>
    );
};

