import { arrayToObject } from '@/utils';
import React, { useEffect, useMemo } from 'react';
import useOrgWithId from '../../organization/useOrg';
import useUserData from '../../user/useUserData';
import useBDEventByID from '../useBDEventByID';


export const useReqShipPairs = ({brandid, eventid, ...props}) => {
    const [pairs, setPairs] = React.useState({})
    const [distinctUserIDs, setDistinctUserIDs] = React.useState([])
    const [distinctUserNames, setDistinctUserNames] = React.useState({})
    const distinctOrgIDs = useMemo(() => Object.keys(pairs ?? {}),[pairs])
    const orgQueryForNames = useOrgWithId({org_id: distinctOrgIDs})
    const userQueryForNames = useUserData({userids: distinctUserIDs})
    const eventQuery = useBDEventByID({orgid: brandid, eventid})

    // Get Organization Names
    useEffect(() => {
        if (!orgQueryForNames.data?.length) return
        const ps = pairs
        orgQueryForNames.data.forEach(org => {
            let targetOrg = ps[org.id]
            if (!!targetOrg) targetOrg.orgname = org.name
            else console.log('not found');
        })
        setPairs({...pairs,...ps})
    },[orgQueryForNames.data]) 

    // Get User Names
    useEffect(() => {
        if (!userQueryForNames.data?.length) return
        const ps = pairs
        const nameObj = arrayToObject(userQueryForNames.data, 'id')
        setDistinctUserNames(nameObj)
        setPairs({...pairs, ...ps}) 
    },[userQueryForNames.data])

    // Combine request, shipment, and confirmation data
    useEffect(() => {
        if (!eventQuery.data?.donations) return
        const initPairs = pairs
        const userIDs = new Set(distinctUserIDs) 
        eventQuery.data.donations.forEach((d) => {
            const safeRequests = d.requests || []
            const safeShipments = d.shipments || []
            safeRequests.forEach((r) => 
                {
                    let pair = initPairs[r.orgid]
                    if (!pair) {
                        initPairs[r.orgid] = {}
                        pair = initPairs[r.orgid]
                        pair.orgid = r.orgid
                        pair.requestid = r.requestid
                        pair.lineitems = {}
                        pair.requestmodifier = 
                            r['modified by'] || r['created by']
                        pair.requestmodified = r.modified
                        if (!isNaN(pair.confirmedby)) userIDs.add(pair.confirmedby)
                        if (!isNaN(pair.requestmodifier)) userIDs.add(pair.requestmodifier)
                    }
                    pair.lineitems[d.id] = {
                        requestquantity: r.quantity,   
                    }
                }
            )
            safeShipments.forEach((s) => 
                {
                    let pair = initPairs[s.orgid]
                    if (pair) {
                        pair.shipmentid = s.shipmentid
                        pair.shipmentmodifier =  
                            s['modified by'] || s['created by']
                        pair.shipmentmodified =  s.qtymodifiedtime || s.modified
                        pair.confirmedby = 
                            s['confirmed by'] 
                        pair.confirmedtime = s.qtyconfirmedtime
                        pair.lineitems[d.id] = {
                            ...pair.lineitems[d.id],
                            qtyconfirmed: s.qtyconfirmed,
                            shipmentquantity: s.quantity,   
                        }
                        if (!isNaN(pair.shipmentmodifier)) userIDs.add(pair.shipmentmodifier)
                        if (!isNaN(pair.confirmedby)) userIDs.add(pair.confirmedby)
                    } else console.log('WARNING: shipment without request', s)   
                }
            )
        })

        setPairs({...pairs,...initPairs})
        setDistinctUserIDs(Array.from(userIDs))
    }, [eventQuery.data])

    return {
        pairs: orgQueryForNames.data ? pairs : {},
        userNames: distinctUserNames,
    }
};

