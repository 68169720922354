
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { modWishlistProduct } from '@/apis/base/wishlist/modWishlistProduct';

export default function useModWishlistProduct(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: ['wishlist', ROUTE_IDS.MOD_WISHLIST_PRODUCT],
        mutationFn: modWishlistProduct,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            // client.invalidateQueries({queryKey: ['wishlist']})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}