import { UserAvatar } from "@/components/User/UserAvatar";
import { AvatarDropdown } from "@/components/dropdowns/AvatarDropdown";

export const UserCell = ({row, onClick, hasUserDetail=true, showJustImage=false, showDropdown=true, className}) => {
  const userName = row?.['first name'] && row?.['last name'] 
    ? `${row['first name']} ${row['last name']}` 
    : row?.name;
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      { showDropdown && (
          <AvatarDropdown 
            userid={row?.id || row?.userid}
            numImages={1}
            classname="static"
            dropdownClasssname=""
          />
      )}
      {!showDropdown && showJustImage && (
        <UserAvatar 
          userID={row?.userid || row?.id}
        />
      )}
      <div className="flex flex-col whitespace-nowrap items-start">
        <div onClick={onClick} className={`
        ${hasUserDetail ? 'underline hover:cursor-pointer' : ''}
        `}>{userName}</div>
        <span className="text-[12px] text-gray-400">{row?.email}</span>
      </div>
    </div>
  )
  }