import { MILLISEC } from '@/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { checkResetPasswordCode } from '@/apis/base/auth/checkResetPassword';

export default function useCheckResetPasswordCode({ onError, onSuccess, ...props }) {
  const loginData = useMutation({
    mutationKey: [ROUTE_IDS.CHECK_RESET_PASSWORD_CODE],
    mutationFn: checkResetPasswordCode,
    retry: false,
    staleTime: MILLISEC.MIN_15,
    onSuccess: onSuccess,
    onError: onError,
    ...props,
  });
  return loginData;
}
