import { getBDEvents } from '@/apis/base/donation/getBDEvents';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { recursiveNodeToArray, roundTo } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import useBDStatusEnum from '../enum/useBDStatusEnum';
import React from 'react';


export default function useBDEvents({orgid, recursive, statusids}) {
    const BDStatusQuery = useBDStatusEnum();
    const query = useQuery({
        queryKey: ['bd', 'org', orgid, 'event', statusids, ROUTE_IDS.GET_BD_EVENTS],
        queryFn: () => getBDEvents({orgid, recursive , 'statusids[]': statusids }),
        staleTime: MILLISEC.MIN_15,
        select: React.useCallback((data) => {
            const eventArray = 
                recursiveNodeToArray({
                    topNode: structuredClone(data),
                    inheritKeys: [
                        {parentKey: 'id', childKey: 'orgid'},
                        {parentKey: 'name', childKey: 'orgname'},
                    ],
                    transformFn: (e) => {
                        console.log('is processor',e.status === BDStatusQuery.data?.processorReview, e.name, e.processor );
                        e.canAudit = 
                            BDStatusQuery.data?.brandAuditStatuses?.includes?.(e.status) 
                            || (e.status === BDStatusQuery.data?.processorReview && e.processor === orgid)
                        if (e?.peritemreport === '1') {
                            e.price = 0
                            e.weight = 0
                            e?.donations?.forEach?.(d => {
                                    console.log(e.name, Number(d?.price) * Number(d?.quantity));
                                if (d.quantity) {
                                    if (d.price)
                                        e.price += roundTo(Number(d?.price) * Number(d?.quantity))
                                    if (d.weight)
                                        e.weight += roundTo(Number(d?.weight) * Number(d?.quantity))
                                }
                            })
                            e.price = roundTo(e?.price)
                        }
                    },
                    targetField: 'donationevents'
                })
            return eventArray
        }, [BDStatusQuery.data]),
    });
    return query;
}