import React from 'react';
import { RemoveUserIcon } from '../Icons';


export const BasicBlackButton = ({className, customSize=false, ...props}) => {
    return (
        <button 
            {...props} 
            className={`
                content-center items-center rounded-[0.3rem] bg-black font-normal text-white shadow-lg disabled:opacity-30
                ${customSize ? '' : 'px-5 py-4 lg:min-w-[10rem] w-full text-base font-normal'}
                ${className}`
                }>
            {props.children}
        </button>
    );
};

