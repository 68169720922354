import { ManageWishlistProductDialog } from '@/components/Dialog/ManageWishlistProductDialog';
import { PlusIcon } from '@/components/Icons';
import MultiSelectDropdown from '@/components/Input/MultiSelectDropdown';
import { PageTitle } from '@/components/Label/PageTitle';
import { WISHLIST_VIEW_TYPE } from '@/constants';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { useWishlistFilters } from '@/hooks/state/wishlist/helpers/useWishlistFilters';
import { useWishlistObject } from '@/hooks/state/wishlist/helpers/useWishlistObject';
import useRemoveWishlistProduct from '@/hooks/state/wishlist/useRemoveWishlistProduct';
import useWishProductCategories from '@/hooks/state/wishlist/useWishProductCats';
import useWishProducts from '@/hooks/state/wishlist/useWishProducts';
import useWishTargetCust from '@/hooks/state/wishlist/useWishTargetCust';
import { openDialogWithRef } from '@/utils';
import { WishlistGrid } from '@/views/pages/Admin/ProductManagement/components/WishlistGrid';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import React, { useCallback, useRef } from 'react';

export const ProductManagement = ({ps, ...props}) => {
    const dialogRef = useRef()
    const [isEditingProduct, setIsEditingProduct] = React.useState(false)
    const [selectedProductID, setSelectedProductID] = React.useState(null)
    const deleteProductMutation = useRemoveWishlistProduct(queryResultFns(
        'Product removed',
        'Failed to remove product'
    ))
    const productsQuery = useWishProducts({})
    const categoryQuery = useWishProductCategories({})
    const demographicsQuery = useWishTargetCust({})
    const ctx = useCurrentOrgContext().data
    const {
        demoFilter, 
        catFilter,
        handleCatFilterChange,
        handleDemoFilterChange
    } = useWishlistFilters(demographicsQuery.data, categoryQuery.data?.flat)
    const wishlistObj = useWishlistObject({
        view: WISHLIST_VIEW_TYPE.UIC,
        wishProductsData: productsQuery.data,
    })
    
    const handleEditProduct = useCallback((product) => {
        const start = performance.now()
        setSelectedProductID(product?.id)
        setIsEditingProduct(true)
        openDialogWithRef(dialogRef)
        console.log('Time:', (performance.now() - start) / 1000, 'sec')
    })
    const handleEditFinish = useCallback(() => {
        setIsEditingProduct(false)
        setSelectedProductID(null)
    })
    const handleRemoveProduct = useCallback((product) => {
        deleteProductMutation.mutate({
            productid: product.id
        })
    })
    return (
        <div>
            <PageTitle>
                Product Management
                <div className="btn btn-secondary" onClick={() => openDialogWithRef(dialogRef)}>
                    <PlusIcon />
                    Add Product
                </div>
            </PageTitle>

            {/* <ProductCategoryDropdown {...props} /> */}
            <div className="flex flex-col md:flex-row w-fit  gap-5 bg-base-100 p-4 mb-5 rounded-xl">
                <MultiSelectDropdown 
                    placeHolder='Filter categories'
                    options={categoryQuery.data?.tree}
                    renderFn={(val) => val?.name}
                    onToggle={handleCatFilterChange}
                    selectedIDs={catFilter}
                    className='w-72'
                />
                <MultiSelectDropdown 
                    placeHolder='Filter demographics'
                    options={demographicsQuery.data}
                    renderFn={(val) => val?.name}
                    onToggle={handleDemoFilterChange}
                    selectedIDs={demoFilter}
                    className='w-72'
                />
            </div>
            <WishlistGrid 
                view={WISHLIST_VIEW_TYPE.UIC}
                demoFilter={demoFilter}
                catFilter={catFilter}
                wishlistObj={wishlistObj}
                handleRemoveProduct={handleRemoveProduct}
                handleEditProduct={handleEditProduct}
                handleEditFinish={handleEditFinish}
                {...props} 
            />

            <ManageWishlistProductDialog
                productsData={productsQuery.data}
                categoryData={categoryQuery.data}
                demographicData={demographicsQuery.data}
                userid={ctx?.userid} 
                dialogRef={dialogRef}
                isExisting={isEditingProduct}
                existingProductID={selectedProductID}
                handleModFinish={handleEditFinish}
            />
        </div>
    );
};
