export const BadgeFromEventStatus = ({status, statusEnum, className, forceClosed}) => {
    if (forceClosed) return (
        <div className={`badge rounded-badge whitespace-nowrap bg-gray-300 text-gray-700 ${className}`}>Closed</div>
    )
    switch (status) {
        case statusEnum?.brandReview:
            return <div className={`badge rounded-badge h-fit-content whitespace-nowrap font-medium bg-blue-back text-blue-text ${className}`}>Brand Review</div>
        case statusEnum?.brandDeclined:
            return <div className={`badge rounded-badge whitespace-nowrap bg-blue-back text-red-strong ${className}`}>Brand Declined</div>
        case statusEnum?.unity11Review:
            return <div className={`badge rounded-badge whitespace-nowrap bg-purple-back text-purple-text ${className}`}>U11 Review</div>
        case statusEnum?.unity11Declined:
            return <div className={`badge rounded-badge whitespace-nowrap bg-purple-back text-red-strong ${className}`}>U11 Declined</div>
        case statusEnum?.processorReview:
            return <div className={`badge rounded-badge whitespace-nowrap bg-orange-back text-blue-text ${className}`}>Processor Review</div>
        case statusEnum?.processorDeclined:
            return <div className={`badge rounded-badge whitespace-nowrap bg-orange-back text-red-strong ${className}`}>Processor Declined</div>
        case statusEnum?.live:
            return <div className={`badge rounded-badge whitespace-nowrap bg-green-back text-green-text ${className}`}>Live</div>
        case statusEnum?.closed:
            return <div className={`badge rounded-badge whitespace-nowrap bg-gray-300 text-gray-700 ${className}`}>Closed</div>
        default:
            return <div className={`badge rounded-badge whitespace-nowrap ${className}`}></div>
    }
}