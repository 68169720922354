//./components/UserColumnDefs.tsx
import { ClosedEyeIcon, DeleteIcon, EyeIcon } from "@/components/Icons";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { UserCell } from "../Cells/UserCell";
import { UserStatusCell } from "../Cells/UserStatusCell";
import { BadgeCell } from "../Cells/BadgeCell";
import { oneOrMoreFilterFn } from "../filterFunctions/oneOrMoreFilterFn";
import { BasicTableButton } from "@/components/Buttons/BasicTableButton";

// createColumnHelper helps us create columns with maximum type safety.
// we assign the type person so that it knows the structure for our data
const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
export const UICAllUserColumnHelper = [
  columnHelper.accessor((row) => row?.id, {
    id: "User ID",
    size: 10,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>ID</span>,
  }),
  columnHelper.accessor((row) => `${row?.['first name']} ${row?.['last name']}`, {
    id: "Name",
    size: 120,
    filterFn: stringFilter,
    cell: ({row, table}) => 
      <UserCell
        onClick={() =>  table.options.meta.handleProfileClicked(row.original)}
        row={row.original}/>,
    header: () => <span>MEMBER</span>,
  }),
  columnHelper.accessor((row) => row?.email, {
    id: "Email",
    size: 120,
    filterFn: stringFilter,
    meta: {
      hiddenCol:true
    },
    cell: ({row, table}) => 
      <UserCell
        onClick={() =>  table.options.meta.handleProfileClicked(row.original)}
        row={row.original}/>,
    header: () => <span>MEMBER</span>,
  }),
  columnHelper.display({
    id: "NumOrgs",
    size: 240,
    cell: ({row}) => <UserOrgs row={row}/>,
    header: () => <span>ORGANIZATIONS</span>,
  }),
  columnHelper.accessor(row => row?.orgNames, {
    id: "Organizations",
    size: 240,
    filterFn: oneOrMoreFilterFn('orgNames'),
    meta: {
      hiddenCol: true,
    },
    cell: ({row}) => <span>{row.original?.orgs?.length}</span>,
  }),
  columnHelper.accessor((row) => row?.['phone number'], {
    id: "Phone",
    size: 240,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>  ,
    header: () => <span>PHONE</span>,
  }),
  columnHelper.accessor((row) => row?.emlverified === '1', {
    id: "Verification",
    size: 120,
    enableColumnFilter: false,
    cell: (info) => <BadgeCell label={info.getValue() ?  'Verified' : 'Unverified'} isSuccess={info.getValue()}/>,
    header: () => <span>VERIFICATION</span>,
  }),
  columnHelper.accessor((row) => row?.status === '1', {
    id: "Status",
    size: 120,
    enableColumnFilter: false,
    cell: (info) => <UserStatusCell status={info.getValue()} />,
    header: () => <span>STATUS</span>,
  }),
  columnHelper.display({
    id: "Action",
    size: 120,
    enableColumnFilter: false,
    cell: (info) => <DeleteButton orgid={info.row.original.id}/>,
    header: () => <span>ACTION</span>,
  }),
];

const UserOrgs = ({row}) => (
  <div 
    className={`
      swap  place-items-center  
      ${row.getIsSelected() ? 'swap-active' : ' hover:swap-active'}`}
  >
    <div className="swap-on" onClick={() => row.toggleSelected()}>
        { row.getIsSelected()
          ? <EyeIcon className='btn btn-neutral w-18'/>
          : <ClosedEyeIcon className='btn btn-neutral w-18'/>
        }
    </div>
    <div className="swap-off pointer-events-none">
      {row.original?.orgs?.length}
    </div>
  </div>
)

const DeleteButton = ({onDelete,orgid}) => (
  <button className="btn btn-little btn-ghost  btn-circle" onClick={() => onDelete(orgid)}>
      <DeleteIcon />
  </button>
)


