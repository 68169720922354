import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import uicLogo from '/assets/images/u11square.png';

import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { getLogoUrl } from '@/utils';
import { useTranslations } from '../../../../hooks/translations';
import routes from '../../../../routes';

const useStyles = makeStyles((theme) => ({
	list: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	subitem: {
		paddingLeft: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
		},
	},
}));

var getBreakpoint = function () {
	return window.getComputedStyle(document.body, ':before').content;
};


const ProcessorSideBar = ({ history }) => {
	const classes = useStyles();
	const text = useTranslations('brand');
	const brandRoutes = routes.filter(prop => prop.layout === '/brand');
	const contextQuery = useCurrentOrgContext();
	const orgContext = contextQuery.data;

	const activeRoute = (path) => {
		return history.location.pathname.indexOf(path) > -1 ? true : false;
	};

	return (
		<aside className='drawer-side z-[99] mt-0 xl:-mt-24 min-h-screen h-[calc(100vh)]'>
			<label htmlFor="my-drawer" className="drawer-overlay"></label>
			<nav className="flex min-h-screen w-72 flex-col gap-2 overflow-y-auto bg-base-100 px-6 py-10">
				<>
				{orgContext ? (
						<Link to="/" >
							<div className='w-full flex place-content-center'>
								<img className="h-14 " src={getLogoUrl(orgContext?.orgid)}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src=uicLogo;
								}}
								alt="Logo for UIC" />
								
							</div>
						</Link>
					) : (
						<Link to="/" >
							<img className="h-14" src={uicLogo} alt="Logo for UIC" />
						</Link>
					)}
				</>
				<ul className="menu gap-3  mt-5 text-xl">
					<li >
						<Link to={'/brand/dashboard'} className='hover:bg-base-100-content hover:active:bg-base-100'>
							<img src="https://unpkg.com/heroicons/20/solid/home.svg" className="h-6 w-6" alt='dashboard'></img>
							Dashboard
						</Link>
					</li>
					<li>
						<Link to={'/processor/users'} className='hover:bg-base-100-content hover:active:bg-base-100'>
							<img  src="https://unpkg.com/heroicons/20/solid/user.svg" className="h-6 w-6" alt='users'></img>
							Members
						</Link>
					</li>
					<li>
						<Link to={'/processor/organizations'} className='hover:bg-base-100-content hover:active:bg-base-100'>
							<svg 
								className="w-6 h-6 text-gray-800 dark:text-white " aria-hidden="true" 
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 18 18"
							>
								<path d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z"/>
							</svg>
							Organizations
						</Link>
					</li>
					<li>
						<details open >
							<summary className='hover:bg-base-100-content hover:active:bg-base-100'>
								<img
									src="https://unpkg.com/heroicons/20/solid/squares-2x2.svg"
									className="h-6 w-6" alt='products'/>
								Services
							</summary>
							<ul className='flex mt-3 flex-col gap-3'>
								<li><Link to={'/processor/services/donation'} className='hover:bg-base-100-content hover:active:bg-base-100'>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
										<path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
									</svg>
									Donations
								</Link></li>
							</ul>
						</details>
					</li>
					
				</ul>
			</nav>
			{/* <!-- /sidebar menu --> */}
		</aside>
	);
};

export default ProcessorSideBar;
