//./components/UserColumnDefs.tsx
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { getDateString, getEventInventoryUrl } from "@/utils";
import { Link } from "react-router-dom";
import { ApproveButton, EditButton, InventoryButton, LocationButton, ShippingButton, XButton } from "@/components/Buttons";
import { BadgeFromEventStatus } from "@/components/Util/BadgeFromEventStatus";
import { get } from "react-hook-form";
import { TrackedImage } from "@/components/Image/TrackedImage";

// createColumnHelper helps us create columns with maximum type safety.
// we assign the type person so that it knows the structure for our data
const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const strictStringFilter = filterFns.includesString.autoRemove

export const donationInventoryCols = [
  columnHelper.display({
    id:'images',
    size: 110,
    cell: ({row, table}) => <Images 
      imageEventHandler={table.options.meta.handleImageEvent} 
      row={row.original}
      orgID={table.options.meta.orgID}
      eventID={table.options.meta.eventID}
      />,
    header: () => <span>IMAGES</span>,
  }),
  columnHelper.accessor((row) => row.id, {
    id: "ID",
    size: 30,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>ID</span>,
  }),
  columnHelper.accessor((row) => row?.name, {
    id: "Brand name",
    filterFn: stringFilter,
    cell: (info) => <span className="font-bold">{info.getValue()}</span>,
    header: () => <span>BRAND NAME</span>,
  }),
  columnHelper.accessor((row) => row?.productname, {
    id: "productname",
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>PRODUCT NAME</span>,
  }),
  columnHelper.accessor((row) => row?.description, {
    id: "description",
    size: 300,
    enableColumnFilter: false,
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>DESCRIPTION</span>,
  }),
  columnHelper.accessor((row) => row?.quantity, {
    id: "quantity",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>  ,
    header: () => <span>QUANTITY</span>,
  }),
  columnHelper.accessor((row) => row?.price, {
    id: "price",
    size: 100,
    filterFn: numRangeFilter,
    cell: (info) => <span className="whitespace-nowrap">{info.getValue()}</span>,
    header: () => <span>PRICE</span>,
  }),
  columnHelper.accessor((row) => row?.weight, {
    id: "Weight",
    size: 100,
    filterFn: numRangeFilter,
    cell: (info) => <span className="whitespace-nowrap">{info.getValue()}</span>,
    header: () => <span>WEIGHT</span>,
  }),
  columnHelper.accessor((row) => row?.active === '1' ? 'Active' : 'Inactive', {
    id: "status",
    size: 100,
    filterFn: numRangeFilter,
    cell: (info) => <StatusBadge status={info.getValue()} />,
    header: () => <span>STATUS</span>,
  }),
  columnHelper.display( {
    id: "action",
    size: 100,
    filterFn: numRangeFilter,
    cell: (info) => <Actions row={info.row.original}  handleEdit={info.table.options.meta.handleEdit}/>,
    header: () => <span>ACTION</span>,
  }),
];


const StatusBadge = ({status}) => {
  switch(status) {
    case 'Active':
      return <div className="badge bg-green-back text-green-text rounded-badge">Active</div>
    case 'Inactive':
      return <div className="badge bg-yellow-back text-yellow-text rounded-badge">Inactive</div>
    default:
      return <div className="badge bg-gray-back text-gray-text rounded-badge">Unknown</div>
  }
}

const Images = ({row, imageEventHandler, orgID, eventID}) => {
  const numImages = row?.images?.length || 0
  const getImageLinks = () => {
    if (numImages > 0) {
      const links = []
      for(let i = 0; i < row.images; i++)
        links.push(getEventInventoryUrl(eventID,row.id,i))
      return links
    }
    return []
  } 
  return (
    <div className="">
      <TrackedImage
        src={getImageLinks()[0]} 
        alt={`inventory`}
        onMouseEnter={() => imageEventHandler(numImages > 0,getImageLinks())}
        onMouseLeave={() => imageEventHandler(false,[])}
        className=" w-18 h-18 object-contain"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src="/assets/images/placeholder.png";
        }}
         />
    </div>
  )
}

const Actions = ({row, handleEdit}) => {
  // TODO  delete event
  // TODO  approve event
  
    return (
        <div className="join group-hover:opacity-100 opacity-30">
            <EditButton buttonProps={{onClick: () => handleEdit(row)}}/>
        </div>
    )
}