import React from 'react';


export const BasicBlueRectButton = ({className, customSize=false, disabled,  ...props}) => {
    return (
        <button 
            {...props} 
            className={`
                btn rounded-md hover:cursor-pointer  text-white text-base bg-blue-button
                ${disabled ? 'opacity-40 pointer-events-none select-none' : ''}
                ${customSize ? '' : 'px-5 py-3 lg:min-w-[10rem] text-sm lg:text-lg '}
                ${className}`
                }>
            {props.children}
        </button>
    );
};

