import { blurActive } from '@/utils/misc';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from "react";

const Icon = () => {
  return (
    <svg height="15" width="15" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="15" width="15" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

export const POSITION = {
  TOP: "top",
  END: "end",
  BOTTOM: "end",
  START: "start"
}


const Dropdown = ({...props}) =>{ 
  const {  
    className = "",
    nullChoice=null,
    disabled,
    placeHolder,
    options = [],
    labelFn=()=>{},
    keyFn=()=>{},
    isMulti,
    isSearchable,
    onChange,
    isError = true,
    position = POSITION.BOTTOM,
    errorText,
    enabled = true,
    sorted = true,
    subOptions = null,
    value} = props;
  const listContainerRef = useRef(null)  
  const [showMenu, setShowMenu] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  const [searchValue, setSearchValue] = useState("");
  const [keyboardSelIdx, setKeyboardSelIdx] = useState(0);
  const searchRef = useRef();
  const inputRef = useRef();
  const containerRef = useRef()
  const displayRef = useRef()
  const memoOpts = useMemo(() => {
    return getOptions()
  },[options, searchValue, compare])

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
    // handleResize()
    new ResizeObserver(handleResize).observe(containerRef.current);
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    
    window.addEventListener("click", handler);
    return () => {
        window.removeEventListener("click", handler);
    };
});

useEffect(() => {
  keyboardSelIdx != null && scrollToIndex(keyboardSelIdx)
},[keyboardSelIdx])

  const handleInputClick = (e) => {
    // if (!disabled)
    //   setShowMenu(!showMenu);
  };

  const handleResize = () => {
    if (displayRef.current && containerRef.current) {
      displayRef.current.style.maxWidth = `${containerRef.current.offsetWidth}px`
    }
  }
  const getDisplay = () => {
    if (!value || Object.keys?.(value)?.length === 0 || value.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className="flex flex-wrap gap-[5px]">
          {value.map((option) => (
            <div key={option?.id || labelFn(option)} className="bg-[#ddd] flex items-center px-1 py-0.5 rounded-sm;">
              {labelFn(option)}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="flex items-center;"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return labelFn(value);
  };
  
  const removeOption = (option) => {
    return value.filter((o) => o.value !== keyFn(option));
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    onChange(newValue);
  };
  
  const onItemClick = (option) => {
    if (subOptions) return
    let newValue;
    if (isMulti) {
      if (value.findIndex((o) => o.value === keyFn(option)) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...value, option];
      }
    } else {
      newValue = option;
    }
    onChange(newValue);
    setShowMenu(false);
  };
  
  const isSelected = (option) => {
    if (isMulti) 
      return value.filter((o) => o.value === keyFn(option)).length > 0;
    if (!value) 
      return false;
    return value.value === option;
  };

  const onSearch = (e) => {
    setKeyboardSelIdx(0);
    setSearchValue(e.target.value);
  };

  function getOptions() {
    if (!searchValue) {
      return sorted && Array.isArray(options) ? options.sort(compare) : options;
    }

    return options.filter(
      (option) =>
        labelFn(option)?.length > 0 && labelFn(option).toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  }

  function compare(a,b) {
    if (a.label < b.label){
      return -1;
    }
    if (a.label > b.label){
      return 1;
    }
    return 0;
  }

  const getContainerWidth = () => {
    return containerRef.current.offsetWidth
  }
  function positionClasses() {
    switch (position) {
      case POSITION.TOP:
        return 'bottom-[100%]'
        case POSITION.BOTTOM:
          return 'top-[100%]'
      case POSITION.START:
        return 'right-[100%]'
      case POSITION.END:
        return 'left-[100%]'
      default:
        return ''
    }
  }

  function handleMouseLeave() {
    setShowMenu(false)
  }

  function scrollToIndex(idx) {
    const el = listContainerRef.current?.children?.[idx]
    if (el) {
      el.scrollIntoView({block: 'nearest'})
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') 
      e.preventDefault()
    if (e.key === 'ArrowDown') 
      setKeyboardSelIdx(prev => Math.min(prev + 1, memoOpts.length - 1))
    if (e.key === 'ArrowUp')
      setKeyboardSelIdx(prev => Math.max(prev - 1, 0))
    if (e.key === 'Enter') {
      e.preventDefault()
      if (memoOpts.length > 0) {
        onItemClick(memoOpts[keyboardSelIdx])
      }
    }
    if (e.key === 'Tab') {
      e.preventDefault()
      if (e.shiftKey)  
        setKeyboardSelIdx(prev => prev === 0 ? memoOpts.length - 1 : prev - 1)
      else 
        setKeyboardSelIdx(prev => prev === memoOpts.length - 1 ? 0 : prev + 1)
    }
    if (e.key === 'Escape') {
      setKeyboardSelIdx(0)
      setShowMenu(false)
    }
  }

  function handleBlur(e) {
    if (e.currentTarget.contains(e.relatedTarget)) return
    setKeyboardSelIdx(0)
  }

  function handleFocus(e) {
      setShowMenu(true)
  }
  
  const searchBarOrder = [
      <div key={'searchValueContainer'}>
          {isSearchable && (
              <div className={`${position === POSITION.TOP ? 'border-t' : 'border-b'} px-3 flex flex-row gap-3  border-gray-unselected p-[5px] place-items-center `} key={'searchValue'}>
                <input
                  onKeyDown={handleKeyDown} 
                  placeholder="search" 
                  className='w-full placeholder:text-sm outline-none bg-none p-2 border-[#e2d7d7]' onChange={onSearch} value={searchValue} ref={searchRef} key={'searchInput'}
                />
                  <svg 
                    key={`downchevron`} 
                    className="w-4 h-4 text-gray-800 dark:text-white" 
                    aria-hidden="true" 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" viewBox="0 0 20 20"
                  >
                    <path
                      key={`downchevronpath`}
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"  
                    /> 
                  </svg>
              </div>
          )}
      </div>,
      <div ref={listContainerRef} key={'optionsList'}>
          {memoOpts.map((option,i) => {
            if (keyFn(option) == null) return
            return(
              <div
              tabIndex={0}
              onClick={() => onItemClick(option)}
              onFocus={() => setKeyboardSelIdx(i)}
              onPointerOver={() => setKeyboardSelIdx(i)}
              key={`${labelFn(option)}`}
              className={`cursor-pointer focus:border-primary focus:outline-none p-[5px]  selection:bg-[#dadada] selection:text-white font-light ${keyboardSelIdx === i ? 'bg-[#e3e3e370]' : ''}`}
              >
                  <div className={`ml-2 py-2 text-l`}>
                    {labelFn(option )}
                  </div>
              </div>
          )})}
      </div>
  ]
  return (
    <div
      tabIndex={0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={containerRef}
      key={`dropdowncontainer-${options?.[0]}`} 
      className={`
        text-left  w-full border rounded-lg relative overflow-y-visible bg-base-100  border-solid focus:border-primary focus:outline-none form-control justify-center
        ${!!isError ? `border-red-border-error` : `border-gray-border`} 
        ${enabled ? '' : 'bg-gray-200'}
        ${className}`
      } 
      >

      <div ref={inputRef} onClick={handleInputClick} className="flex items-center justify-between select-none px-3 py-3 ">
        <div 
          className={`
            ${((!value || value.length === 0 || disabled)) ?   'text-gray-400' : 'text-black'}`} key={'dropDownDisplay'}
        >
            {getDisplay()}
        </div>
        <div className="dropdown-tools" key={`dropdowntools${options?.[0]}`}>
          <div className="dropdown-tool" key={`dropdowntool${options?.[0]}`}>
            <Icon key={`dropdowntoolsicon${options?.[0]}`}/>
          </div>
        </div>
    </div>

      {showMenu &&  enabled && !disabled && (
              <div onMouseLeave={handleMouseLeave} className={` w-full  border overflow-auto max-h-[250px]   bg-white z-[10] rounded-2xl border-solid border-[#ccc]   shadow-all-md overflow-y-scroll   
              ${positionClasses()}
              absolute
              `} key={'3'} ref={displayRef}>
              {position === POSITION.TOP  ? searchBarOrder.reverse() : searchBarOrder}
          </div>
        )}
    </div>
  );
}

const propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeHolder: PropTypes.string,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  position: PropTypes.string,
  errorText: PropTypes.string,
  enabled: PropTypes.bool,
  sorted: PropTypes.bool,
  subOptions: PropTypes.object,
  fixed: PropTypes.bool,
  needsReset: PropTypes.bool,
  setNeedsReset: PropTypes.func,
};
Dropdown.propTypes = propTypes;
Dropdown.defaultProps = {
  placeHolder: "",
  className: "",
  options: [],
  isMulti: false,
  isSearchable: false,
  onChange: () => {},
  isError: false,
  position: POSITION.BOTTOM,
  errorText: "",
  enabled: true,
  sorted: true,
  subOptions: null,
  fixed: false,
  needsReset: false,
  setNeedsReset: () => {},
};

export default Dropdown;

// export const FormDropdown = ({name, control, ...props}) => {
//   const {field, fieldState, formState, formState: { errors }} = 
//     useController({name, control})
   
//   return (
//     <UnwrappedDropdown 
//       onChange={field.onChange}
//       value={field.value}
//       isError={errors[name] != null}
//       errorText={errors[name]?.message}
//       {...props} 

//     />
//     );
// };
