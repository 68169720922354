import { WishlistProductCell } from "@/components/Tables/Cells/WishlistProductCell";
import { createColumnHelper, filterFns } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange

export const wishlistReportColumnHelper = [
    columnHelper.accessor((row) => row?.categoryid, {
        id: "Category ID",
        size: 10,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        filterFn: filterFns.arrIncludesSome,
        meta: {
            hiddenFilter: true,
            hiddenCol: true,
        },
        cell: (info) => <span> {info.getValue()} {console.log(info.getValue())}</span>,
        header: (info) => <span className="text-start">CATEGORY ID</span>,
    }),
    columnHelper.accessor((row) => row.isProduct ? row.targetid : null, {
        id: "Demographic ID",
        size: 10,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        filterFn: filterFns.arrIncludesSome,
        meta: {
            hiddenFilter: true,
            hiddenCol: true,
        },
        cell: (info) => <span> {info.getValue()}</span>,
        header: (info) => <span className="text-start">DEMOGRAPHIC ID</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? null : row?.name, {
        id: "Category Name",
        size: 10,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span className="text-start">CATEGORY NAME</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? row.name : null, {
        id: "Product Name",
        size: 100,
        filterFn: stringFilter,
        cell: ({row, getValue}) => row.original.isProduct && (
            <WishlistProductCell
                images={row.original.images}
                imgID={row.original.images?.[0]?.name}
                name={getValue()}
            />
        ),
        header: (info) => <span>PRODUCT NAME</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? row.targetname : null, {
        id: "Audience Name",
        size: 100,
        filterFn: stringFilter,
        cell: ({row, getValue}) => row.original.isProduct && (
            <span>{getValue()}</span>
        ),
        header: (info) => <span>PRODUCT NAME</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? row.quantities.description  : null, {
        id: "Description",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>DESCRIPTION</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? (row.quantities.totalQty || 0)  : null, {
        id: "Total Quantity",
        size: 100,
        filterFn: numRangeFilter,
        cell: ({getValue, row}) => <span>
            { row.original.isProduct 
                ? getValue() 
                : recursiveRowSum(row)
            }
        </span>,
        header: (info) => <span>TOTAL QUANTITY</span>,
    }),
]

function recursiveRowSum(row) {
    if (row.original.isProduct) return row.original.quantities.totalQty || 0
    return row.subRows.reduce((acc, subRow) => acc + recursiveRowSum(subRow), 0)
}