import { BasicDetailRow } from '@/components/Label/BasicDetailRow';
import React from 'react';

export const DependentSummary = (props) => {
    const { name, age, relationship, email, gender, idx} = props;
    console.log(props,name);
    return (
        <div className='flex flex-col gap-2'>
            <BasicDetailRow label="No" value={idx}/>
            <BasicDetailRow label="Name" value={name}/>
            <BasicDetailRow label="Email" value={email}/>
            <BasicDetailRow label="Age" value={age}/>
            <BasicDetailRow label="Gender" value={gender}/>
            <BasicDetailRow label="Relationship" value={relationship}/>
        </div>
    );
};

