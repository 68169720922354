import { useArbitraryQuery } from '@/hooks/state/misc/useArbitraryQuery';
import React from 'react';


export const TrackedImage = ({src, alt, disableTracking=false, loadingType,...props}) => {
    const allowTrack = !(src?.length > 200)
    const mockQuery = useArbitraryQuery(allowTrack ? src : '', disableTracking)
    const safeSrc = allowTrack ? `${src}${disableTracking ? '' : `?version=${mockQuery.data}`}` : src
    return (
        <img  
            loading={loadingType}
            src={safeSrc}  
            alt={alt}
            {...props}/>
    );
};