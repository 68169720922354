import { trimZerosAndDec } from '@/utils';
import React, { useRef } from 'react';

export const NumberInput = ({
    name, 
    onBlur=()=>{}, 
    defaultValue=0,
    min=0,
    allowDecimals=false,
    onFocus=()=>{},
    ...props}) => {
    const ref = useRef()
    function handleBlur(e) {
        ref.current.value = trimZerosAndDec(ref.current.value)
        onBlur(e)
    }
    function handleFocus(e) {
        e.target.select()
    }
    return (
        <input
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={ref}
            name={name}
            className='input input-xs border border-gray-border text-sm max-w-[5rem] focus:outline-none'
            min={min}
            defaultValue={Number(defaultValue)}
            type="number"
            onKeyDown={
                allowDecimals 
                    ? null
                    : (e) => ['.', '-'].includes(e.key) && e.preventDefault()
            }
            {...props}
        />
    );
};
