
import { getOrgsById } from '@/apis/base/organizations/getOrgsById';
import { MILLISEC } from '@/constants';
import { getUserLabelInOrg, recursiveSubnodeHelper, validateEmailSuffix } from '@/utils';
import { curry } from '@/utils/advanced';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';


export default function useFlatMembers({ orgID, recursive= 1}) {

    const query = useQuery({
        queryKey: ['org', orgID, 'members'],
        queryFn: () => getOrgsById({"orgids[]": orgID, recursive, showmembers: true}, false),
        staleTime: MILLISEC.MIN_15,
        structuralSharing: false,
        select: useCallback((data) => {
            const clone = structuredClone(data)
            const topLevelOrg = clone?.[0]
            const users = {}
            if (recursive) {
                recursiveSubnodeHelper({subnodes: clone},curry(formatOrgs)(users, topLevelOrg),true, false)
            } 
            else {
                formatOrgs(users, clone)
            }
            const ret = Object.values(users)
            console.log('done');
            return ret
        },[recursive, orgID]),
    });
    return query;
}

function formatOrgs(userObj, topLevelOrg, currArray) {
    for (const org of currArray) {
        for (const member of org.members || []) {
            let memberEntry = userObj[member.id]
            if (memberEntry == null) {
                userObj[member.id] = member
                member.orgData = [org]
                member.type = getUserLabelInOrg(member, topLevelOrg)
                member.admin = topLevelOrg?.admins?.some(a => String(a.id) === String(member.id))
            } else {
                memberEntry?.orgData?.push?.(org)
            }
            // if (member.owner) member.type = 'Master Admin'
            // else if (member?.admin) member.type = 'Admin'
            // else if (validateEmailSuffix(member.email,org.suffix)) member.type = 'Member'
            // else member.type = 'Guest'

        }
    }
}