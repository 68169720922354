import Swal from 'sweetalert2'

export const SuccessToast = Swal.mixin({
	toast: true,
	position: 'top-end',
	icon: 'success',
	target: '#popuptarget',
	customClass: {
		// container: 'absolute  top-0 right-0',
		popup: "!bg-green-50",
		timerProgressBar: '!bg-green-400',
		title: '!text-green-900',
		
	},
	showConfirmButton: false,
	timerProgressBar: true,
	timer: 2500,
	showClass: {
		popup: 'animate-in-toast'
	},
})

