import { MILLISEC } from '@/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useCookies } from 'react-cookie';
import { login } from '@/apis/base/auth/login';
import { recoverPassword } from '@/apis/base/auth/recoverPassword';

export default function useRecoverPassword({ onError, onSuccess, ...props }) {
  const loginData = useMutation({
    mutationKey: [ROUTE_IDS.RECOVER_PASSWORD],
    mutationFn: recoverPassword,
    retry: false,
    staleTime: MILLISEC.MIN_15,
    onSuccess: onSuccess,
    onError: onError,
    ...props,
  });
  return loginData;
}
