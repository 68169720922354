import React from 'react';
import { TrackedImage } from '../Image/TrackedImage';
import { getAvatarUrl, getEventInventoryUrl } from '@/utils';
import { UserAvatar } from '../User/UserAvatar';


export const AvatarDropdown
 = ({
    userid, 
    numImages=1, 
    classname='dropdown-top dropdown-end', 
    dropdownClasssname='left-[50%] mb-1 -ml-[6rem] dropdown-start',
    ...props}) => {
    return (
        <div className={`flex dropdown dropdown-hover w-10 ${classname}`}>
            <UserAvatar userID={userid} />
            <div className={`dropdown-content pointer-events-none w-48 mt-10 mb-1 mx-auto z-[99] shadow-all-dark ${dropdownClasssname}`}> 
                <TrackedImage 
                    src={getAvatarUrl(userid)} 
                    onError={(e) => e.currentTarget.classList.add('hidden')}
                    onLoad={(e) => e.currentTarget.classList.remove('hidden')}
                    alt={"inventory" + 0}
                    className='w-full  rounded-md' />
            </div>
        </div>
    );
};

