import { rangeArray } from '@/utils';
import React from 'react';


export const TableSkeleton = ({rowHeightClass='h-20', numRows=4,hideIcons=false, ...props}) => {
    return (
        <div className='form-control gap-10 w-full'>    
        { rangeArray(1,numRows).map((i,idx) => (
            <div key={'skeleton' + idx} className={`flex gap-3 ${rowHeightClass}`}>
            { !hideIcons &&
                <div className="skeleton h-full  aspect-square rounded-full animat"/>
            }
                <div className="flex-7 extra-slow form-control gap-1 justify-between">
                    <div className=" skeleton h-1/4 w-2/3"/>
                    <div className="w-5/6 skeleton h-1/4"/>
                    <div className="w-full skeleton h-1/4"/>
                </div>
            </div>    
        ))
        }
        </div>
    );
};

