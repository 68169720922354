import { XButton } from "@/components/Buttons"
import Swal from "sweetalert2"

export const SuccessPopup = Swal.mixin({
        showClass: {
            popup: 'animate-in-popup'
        },
        customClass: {
            confirmButton: 'btn btn-secondary',
            cancelButton:  'btn btn-neutral',
            container: 'h-[40rem]',
            icon: 'mt-[1rem]'
        },
        buttonsStyling: false,
        backdrop: false,
        title: "Success",
        position: 'top-end',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
    })
