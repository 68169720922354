import { useAppContext } from '@/context/AppContext';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useMyUserData from '@/hooks/state/useMyUserData';
import { useTranslations } from '@/hooks/translations';
import { getLogoUrl, getPageAvailability } from '@/utils';
import { Link, useHistory } from 'react-router-dom';

import { TrackedImage } from '@/components/Image/TrackedImage';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useCookies, withCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import HeaderMenu from './HeaderMenu';


const AuthenticatedHeader = () => {
  const history = useHistory()
  const location = useLocation()
  const orgContextQuery = useCurrentOrgContext();
  const orgContext = orgContextQuery?.data;

  // const isBrand = orgContext?.brand
  const isAdmin = orgContext?.isAdmin
  const whiteBg = orgContext?.standardCommunity
  const wide = !orgContext?.standardCommunity
  const accountForAbsSidebar = (orgContext?.brand || orgContext?.processor) && !(location.pathname.endsWith('account') || location.pathname.endsWith('organization-preferences'))
  const adminHeader = 
    (orgContext?.isUICContext &&
      !location.pathname.endsWith('account') && 
      !location.pathname.endsWith('organization-preferences'))
    || location.pathname.endsWith('wishlist')
    
  const availability = useMemo(() => 
    getPageAvailability(orgContext, !accountForAbsSidebar)
    ,[orgContext, accountForAbsSidebar])
   
  return !location.pathname.startsWith('auth') && (
    <header 
      className={`flex items-center w-screen  
                  h-header z-20 
                  ${accountForAbsSidebar ? 'justify-end ' : 'justify-center'}
                  ${wide ? 'pl-5' : ''}
                  ${whiteBg ? 'bg-primary' : 'bg-base-200'}`}>
      <div 
        className={`
                    flex px-4 sm:px-6 xl:px-8  justify-between  items-center
                    ${adminHeader ? 'w-admin-header ' : 'container'}  
                    ${wide ? '-ml-9 mr-3' : ''}
                  `}>
        <>
              { availability['header-logo'] ? (
                <Link to={'/'} className='h-full flex items-center'>
                    <TrackedImage src={getLogoUrl(orgContext?.orgid)} 
                      className=" max-h-14 max-w-[10rem] object-contain rounded-md" alt='to home'
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="/assets/images/u11square.png";
                    }}
                    />
                </Link>
                ) : ( <div className='w-1'/> )
            }
        </>
            <HeaderMenu 
              {...{availability, history, isAdmin, context: orgContext}}
            />
      </div>
    </header>
  );
};

export default withCookies( AuthenticatedHeader);
