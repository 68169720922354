import { rangeArray } from '@/utils';
import React from 'react';

export const Stepper = ({numSteps=4, onStepClicked, activeStep=1, ...props}) => {
    function handleStepClicked(step) {
        if (step < activeStep) {
            onStepClicked(step)
        }
    }
    return (
        <div className='flex gap-3'>
        {(rangeArray(1,numSteps)?.map((step => (
            <svg 
                key={step} width={15} height={15} 
                className={`${activeStep > step ? 'hover:cursor-pointer' : ''}`} 
                onClick={() => (handleStepClicked(step))}>
                <circle r={7.6} cx={7.6} cy={7.6} fill={activeStep >= step ? 'black' : '#E6E7E8'}/>
            </svg>
        ))))
            
        }
        </div>
    );
};