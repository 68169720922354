
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submitBDInventory } from '@/apis/base/donation/submitInventory';

export default function useSubmitBDInventory(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: ['bd',ROUTE_IDS.SUBMIT_BD_INVENTORY],
        mutationFn: submitBDInventory,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data) => {
            client.invalidateQueries({queryKey: ['bd']})
            onSuccess && onSuccess(data)
        },
        ...otherProps
    });
    return mutation;
}