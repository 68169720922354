import React, { useEffect } from 'react';
import { Doughnut, } from 'react-chartjs-2';
import {Chart as Chartjs, Legend} from 'chart.js'

Chartjs.register(Legend)

const legendMargin = {
    id: "legendMargin",
    beforeInit: function (chart) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.height += 0);
      };
    }
  };

const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '60%',
    borderWidth: 0,
    spacing: 0,
    offset: 5,
    borderRadius: 2,
    borderAlign: 'inner',
    layout: {
        autoPadding: false,
    },
    plugins: {
        legend: {
            display: true,
            fullSize:false,
            position: "right",
            align: "center",
            labels: {
                font: {
                    size: 15
                },
                boxWidth: 8,
                boxHeight:8,
                // borderRadius:100,
                // useBorderRadius: true,
                usePointStyle: true,
                pointStyle: 'circle',
            }
        }
    },
}

function getPreset(total, timely, untimely, unsubmitted, showTimely) {
    const condLabels = showTimely ? ['Within Range', 'Outside Range'] : ['Submitted']
    const condData = showTimely ? [timely, untimely] : [total - unsubmitted]
    const condBGColor = showTimely ? ['#78C96C', '#F57D79'] : ['#4588ff']
    const condBorderColor = showTimely ? ['#78C96C', '#F57D79'] : ['#4588ff']
    return {
        labels: [...condLabels, 'Unsubmitted'],
        datasets: [{
            data: [...condData, unsubmitted],
            backgroundColor: [...condBGColor, '#a2a2a2'],
            borderColor: [...condBorderColor, '#A2A2A2'],
        }],
    }
}

export const BrandPrefTurnoutChart = ({total=0, timely=0, untimely=0, unsubmitted=0, showTimely, ...props}) => {        
    const chartRef = React.useRef(null);
    useEffect(() => {
        chartRef.current.legend.left -= 10
    },[])
    return (
        <Doughnut ref={chartRef} plugins={[legendMargin]} options={doughnutOptions} data={getPreset(total,timely, untimely, unsubmitted, showTimely)} {...props}/>
    );
};

