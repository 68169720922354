

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation,useQueryClient } from '@tanstack/react-query';
import { confirmPopupPickup } from '@/apis/base/donation/popupStore/confirmPopupPickup';


export default function useConfirmPopupPickup(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.CONFIRM_POPUP_PICKUP],
        mutationFn: confirmPopupPickup,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            if (data?.status === "popup store request not processed.") throw new Error('popup store request not processed.')
            client.invalidateQueries({queryKey: ['popup', req.popupid]})
            onSuccess && onSuccess(data)
        },
        ...otherProps
    });
    return mutation;
}

