import React from 'react';

export const CuratorGridContainer = ({visibleRows, numColumns=5, ...props}) => {
    return (
        <div 
            className={`grid w-full auto-rows-[0px] max-w-full gap-x-2 md:gap-x-3  md:gap-y-1 lg:gap-x-4  lg:gap-y-2 overflow-hidden`} 
            style={{
                gridTemplateRows: `repeat(${visibleRows}, minmax(0px, 1fr))`,
                gridTemplateColumns: `repeat(${numColumns}, minmax(0px, 1fr))`
            }}
            {...props}>
        </div>
    );
};
