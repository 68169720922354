import { BasicDetailRow } from '@/components/Label/BasicDetailRow';
import { getDateString, getDateTimeString } from '@/utils';
import React, { useMemo } from 'react';
import { DependentSummary } from '../UserInfoDetail/DependentSummary';


export const FINDetail = ({app}) => {
    const dependents = useMemo(() => {
        if (!app.subnodes) return [];
        const uniqueNames = [...new Set(app.subnodes.map(subnode => subnode?.name))];
        const subnodesWithLargestTimestamp = uniqueNames.map(name => {
            const subnodesWithName = app.subnodes.filter(subnode => subnode.name === name);
            const subnodeWithLargestTimestamp = subnodesWithName.reduce((prev, current) => {
                return prev.timestamp > current.timestamp ? prev : current;
            });
            return subnodeWithLargestTimestamp;
        });
        return subnodesWithLargestTimestamp;
    }, [app.subnodes]);
    const providerText = app.provider != null && app?.provider != '' ? app.provider : 'No'
    return (
        <div className="flex flex-col">
                <BasicDetailRow label="Social Assistance" value={providerText}  />
                <BasicDetailRow label="Student ID#" value={app?.studentid}/>
                <BasicDetailRow label='Date of Birth' value={app?.dob}/>
                <BasicDetailRow label="Gender" value={app?.gender}/>
                <BasicDetailRow label="Applied at" value={getDateTimeString(dependents?.[0]?.timestamp || app.modified)}/>
                <BasicDetailRow label="Dependents" value={dependents.map((d, idx) => (
                    <div key={d.id} className='my-10'>
                        <DependentSummary idx={idx} {...{...d}}/>
                    </div>
                ))}/>
        </div>
    );
}



