import React, { useEffect, useMemo, useState } from 'react';
import { BasicTable } from '../BasicTable';
import { orgBrandPrefReportCols } from './orgBrandPrefReportCols';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import useAllBrands from '@/hooks/state/brands/useAllBrands';
import { arrayToObject, between, collapseObjIntoArray } from '@/utils';


export const BrandPrefReportTable = ({data, useTimespan, numBrands, ...props}) => {
    const safeData = data || []
    return (
        <BasicTable
            showSortButtons
            meta={{
                numBrands
            }}
            state={{
                columnVisibility: {
                    Timespan: useTimespan,
                    'Total Votes in Timespan': useTimespan,
                    '% Votes in Timespan': useTimespan
                }
            }}
            data={safeData}
            columnHelper={orgBrandPrefReportCols}>
        </BasicTable>
    );
};

