import { getMinimalOrgs } from '@/apis/base/organizations/getMinimalOrgs';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC, UIC_ORG } from '@/constants';
import { flattenOrgs } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

export default function useMinimalOrgs({includeNonStandard=false,...props}) {
    const query = useQuery({
        queryKey: ['org',ROUTE_IDS.GET_MINIMAL_ORGS],
        queryFn: (params) => getMinimalOrgs(params),
        staleTime: MILLISEC.MIN_15,
        notifyOnChangeProps: ['data'],
        select: useCallback((data) => {
            const dataClone = structuredClone(data);
            let flatOrgs = flattenOrgs(dataClone);
            const filteredOrgs = flatOrgs.filter(org => org.name != 'Christine Morrison' && org.id != UIC_ORG)
            return filteredOrgs;
        },[]),
        ...props
    });
    return query;
}