import { CopyIcon, DeleteIcon } from "@/components/Icons";
import { BasicToggle } from "@/components/Input/BasicToggle";
import { handleCopyInviteLinkToClipBoard } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { UserCell } from "../Cells/UserCell";
import { OrgTogglesDropdownCell } from "@/components/Tables/Cells/OrgTogglesDropdownCell";
import { copyOrgInviteToClipboard } from "@/utils/orgUtils";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const multiSelectFilter = (row, columnID, filterValue) => {
    const safeFilterValue = Array.isArray(filterValue) ? filterValue : []
    return safeFilterValue.length === 0 || safeFilterValue.includes(row.getValue(columnID))
}
export const UICAllOrgColummnHelper = [
    columnHelper.accessor(row => row.id ,{
        id: "ID",
        size: 100,
        cell: ({row,getValue, table}) => 
            <span className={addRowOpacityClasses(row,table)} style={{paddingLeft: `${row.depth * 2}rem`}}>{getValue()} </span>,
        header: (info) => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row['org type id'], {
        id: "Type",
        size: 100,
        meta: {
            hiddenFilter: true,
            hiddenCol:true
        },
        filterFn: multiSelectFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>TYPE</span>,
    }),
    columnHelper.accessor((row) => row.name, {
        id: "Organization",
        size: 100,
        filterFn: stringFilter,
                
        cell: ({row, getValue, table}) => {
            const opacityClasses = addRowOpacityClasses(row,table)
            return (
                <div className="indicator">
                    <span className={`whitespace-nowrap ${opacityClasses}`} style={{paddingLeft: `${row.depth * 2}rem`}}>
                        {getValue()}
                    </span>
                    {opacityClasses?.length > 0 &&
                        <div className="indicator-item badge badge-warning badge-sm text-2xs">
                                Visible due to child org
                        </div>
                    }
                </div>
            )
        },
        header: (info) => <span>ORGANIZATION</span>,
    }),
    columnHelper.accessor((row) => (row?.members?.length), {
        id: "Members",
        size: 100,
        enableColumnFilter: false,
        cell: ({row,table,getValue}) => 
            <span className={addRowOpacityClasses(row,table)}>{getValue()}</span>,
        header: (info) => <span>MEMBERS</span>,
    }),
    columnHelper.accessor((row) => row?.FINs, {
        id: "FIN Members",
        size: 100,
        enableColumnFilter: false,
        cell: ({row, getValue, table}) => 
            <span className={addRowOpacityClasses(row,table)}>{getValue()}</span>,
        header: (info) => <span>FIN MEMBERS</span>,
    }),
    columnHelper.accessor((row) => row.admin, {
        id: "Master Admin",
        size: 100,
        enableColumnFilter: false,
        cell: ({row, getValue, table}) => 
            <UserCell className={addRowOpacityClasses(row,table)} row={row.original?.admins?.find?.(a => Number(a.id) === Number(row.original.owner))} hasUserDetail={false}/>,
        header: (info) => <span>MASTER ADMIN</span>,
    }),
    columnHelper.accessor((row) => address(row), {
        id: "Address",
        size: 400,
        filterFn: stringFilter,
        cell: ({row, getValue, table}) => 
            <span className={addRowOpacityClasses(row,table)}>{getValue()}</span>,
        header: (info) => <span>ADDRESS</span>,
    }),
    columnHelper.accessor((row) => getOrgAdmins(row), {
        id: "PERMISSIONS",
        size: 100,
        enableColumnFilter: false,
        cell: ({table, row}) => {
            const {handleBrandToggle, handleCharityToggle, handleProcessorToggle, handleEcommerceToggle, handleCorporationToggle} = table.options.meta
            return (
                <OrgTogglesDropdownCell 
                    row={row.original}
                    onBrandToggle={handleBrandToggle}
                    onCharityToggle={handleCharityToggle}
                    onEcommerceToggle={handleEcommerceToggle}
                    onProcessorToggle={handleProcessorToggle}
                    // onCorporationToggle={handleCorporationToggle}
                />
            )
        },
        header: (info) => <span>Permissions</span>,
    }),
    columnHelper.display({
        id: "Action",
        size: 20,
        cell: (info) => <DeleteButton onClick={() => {}}/>,
        header: (info) => <span >ACTION</span>,
    })
]

function isFIN(user) {
    return user?.roles?.FIN === 'Approved'
}

const getMembers = ( row, fin=false) => 
    fin 
        ? row.admins?.filter?.(a => isFIN(a)).length + row.members?.filter?.(m => isFIN(m)).length
        : row.admins.length + row.members.length 

const ToggleButton = ({org, onClick, defaultChecked}) => {
    return (
    <BasicToggle className='toggle-sm xl:toggle-little 2xl:toggle-md ' onChange={(e) => {
        onClick(org, e.target.checked)}} defaultChecked={defaultChecked}/>
)}

const DeleteButton = () => (
    <button className="btn btn-ghost btn-little btn-circle">
        <DeleteIcon />
    </button>
)

const getOrgAdmins = (org) => {
    return org?.members
        ?.find?.(member => member?.id === org?.owner)
        ?.name ?? ''
}

const InviteLink = ({orgid}) => {
    return  (
        <button className="btn btn-primary btn-circle" onClick={() => copyOrgInviteToClipboard(orgid)}>
            <CopyIcon />
        </button>

    )
}

const address = (org) => {
    return `${org?.address}, ${org?.city} , ${org?.region}`
}


function addRowOpacityClasses(row,table) {
    return multiSelectFilter(row, 'Type',table.getColumn('Type').getFilterValue() || []) 
        ? '' 
        : 'opacity-40'
}