import { FacebookIcon, InstagramIcon, LinkedInIcon, TikTokIcon, TwitterXIcon } from "@/views/pages/Main/Home/components/Curator/PlatformIcons"

export const PlatformIcon = ({platform, ...props}) => {
    if (platform === 'X')  
        return <TwitterXIcon {...props}/>
    if (platform === 'Instagram')  
        return <InstagramIcon {...props}/>
    if (platform === 'LinkedIn')
        return <LinkedInIcon {...props}/>
    if (platform === 'TikTok')
        return <TikTokIcon {...props}/>
    if (platform === 'Facebook')
        return <FacebookIcon {...props}/>
    return <></>
}