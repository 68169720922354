import { addCommas } from '@/utils';
import React from 'react';

export const SEEPillarCardEntry = ({statistic, description, prefix='', suffix='',  ...props}) => {
    return (
        <div className='text-center w-full'>
            <p className='font-semibold text-xl leading-snug whitespace-nowrap'>{prefix}{addCommas(statistic,'')}{suffix}</p>
            <p className='text-xs 2xl:text-sm leading-none'>{description}</p>
        </div>
    );
};
