import { getEventInventoryUrl } from '@/utils';
import React from 'react';
import { TrackedImage } from '../Image/TrackedImage';


export const InventoryDropdown = ({eventid, imgSrc, inventoryid, numImages=1, ...props}) => {
    return (
        <div className="dropdown static dropdown-hover ">
            <TrackedImage src={imgSrc || getEventInventoryUrl( eventid, inventoryid, 0)} alt="" className='w-16 rounded-md'/>
            <div className='dropdown-content  pointer-events-none w-48  mb-1 mx-auto z-[99] shadow-all-light'> 
                <TrackedImage 
                    src={imgSrc || getEventInventoryUrl( eventid, inventoryid, 0)} 
                    alt={"inventory" + 0}
                    className='w-full rounded-md' />
            </div>
        </div>
    );
};