
import { AllOrgsWishlistCols } from '@/components/Tables/AllOrgsWishlistTable/AllOrgsWishlistCols';
import { BasicTable } from '@/components/Tables/BasicTable';
import { GEO_NODE_TYPE } from '@/constants';
import { orgWishlistReportConfig } from '@/hooks/state/wishlist/useOrgWishlistReport';
import { arrayToObject, getDateString, recursiveSubnodeHelper } from '@/utils';
import { useQueries } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';

export const AllOrgsWishlistTable = ({orgsData, geonode, ...props}) => {
    const reportQueriesConfigs = useMemo(() => {
        if (!orgsData?.flatOrgs) return []
        return orgsData.flatOrgs.map((org) => orgWishlistReportConfig({orgid: org.id}))
    },[orgsData?.flatOrgs])

    const reportsQueries = useQueries({
        queries: reportQueriesConfigs,
        combine: useCallback((queries) => combine(orgsData?.flatOrgs, queries), [orgsData?.flatOrgs])
    })

    const tableData = useMemo(() => {   
        if (!orgsData?.flatOrgs || ! reportsQueries?.data) return []
        const treeClone = structuredClone(orgsData.tree)
        treeClone.map((parentOrg) => 
            recursiveSubnodeHelper(
                parentOrg, 
                (subOrg) => populateOrgWithReport(subOrg, reportsQueries.data[subOrg.id] || {}),
                false,
                true
            )
        )
        return treeClone
    },[orgsData, reportsQueries?.data])  

    const columnFilters = useMemo(() => {
        const fs = []
        if (geonode && geonode.geotype === GEO_NODE_TYPE.REGION) 
            fs.push({id: 'Region ID', value: geonode.id})
        if (geonode && geonode.geotype === GEO_NODE_TYPE.CITY)
            fs.push({id: 'City ID', value: geonode.id})
        fs.push({id: 'Processor', value: false})
        fs.push({id: 'Corporation', value: false})
        fs.push({id: 'Brand', value: false})
        return fs
    } ,[geonode])
    return (
        <BasicTable 
            canExpand
            showExportButton
            exportFileName={`Sitewide Org Wishlist Report – ${getDateString(Date.now() / 1000)}`}
            data={tableData}
            columnHelper={AllOrgsWishlistCols}
            extraFilters={columnFilters}
            // tableProps={{
            //     filterFromLeafRows:false
            // }}
        >

        </BasicTable>
    );
};

function combine(flatOrgs, queries) {
    if (!queries.every((query) => query?.data != null)) return null
    const data = queries.map((query) => query.data)
    data?.forEach((report, i) => {
        report.id = flatOrgs[i]?.id
    })
    return {data: arrayToObject(data, 'id'  )}
}

function populateOrgWithReport(org, report) {
    Object.assign(org, report)
    org.ownerData = org.admins?.find(a => Number(a.id) === Number(org.owner)) 
}