
import { MILLISEC, UIC_ORG } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getOrgsByCity } from '@/apis/base/organizations/getOrgsByCity';
import useMyOrgs from './useMyOrgs';

export default function useOrgsByCity({city}) {
    const myOrgsQuery = useMyOrgs()
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_ORGS_BY_CITY, city ],
        queryFn: () => getOrgsByCity({cityid: city}),
        staleTime: MILLISEC.MIN_15,
        select: (data) => {
            return data?.length > 0 ? data.reduce((acc,org) => {
                if(org?.id!==UIC_ORG) {
                    if (myOrgsQuery?.data.includes(org?.id)) 
                        org.isMember = true
                    else 
                        org.isMember = false
                    acc.push(org)
                }
                return acc
            },[]) : data
        },
    });
    return query;
}