import { getEventInventoryUrl } from "@/utils"
import { TrackedImage } from "../Image/TrackedImage"
import { MinusIcon, PlusIcon } from "../Icons"
import { ProductCarousel } from "../Carousel/ProductCarousel"

export const BasicPickupRequestCard = ({eventid, item, value, onChange, maxQuantity, showRemaining=false, active })  => {
    const { productname, id: shipmentid, productsize, description, inventoryid, confirmed, remaining, qtyconfirmed} =  item || {}
    function handleChange(v) {
      console.log('e',typeof v, v);
      let newVal = Number(v)
      if (newVal < 0) newVal = 0
      if (newVal > maxQuantity) newVal = maxQuantity
      onChange(shipmentid, newVal)
    }
    return (
    <div className="rounded-none card card-compact w-full" >
        <ProductCarousel 
          eventid={eventid}
          inventoryid={inventoryid}
          numImages={4}
        />
      <div className="card-body p-0 pt-2" >
        <h2 className="card-title font-semibold text-xl" >
          {productname}
        </h2>
        <p className="-mb-2 font-[0.9rem] text-base">Sizes: {productsize}</p>
      <div className="divider  m-0 w-full" ></div>
        <div className="card-actions justify-between item
        s-center" >
          <div className="form-control gap-4 items-center">
          <span className="text-base">Request</span>
          </div>

          <div className="form-control gap-4 items-center">
            { active ? (
            <div className="flex flex-row gap-2  place-items-end items-center h-5" > 
              <button 
                  className=" animate-none " 
                  onClick={() => handleChange(value?.quantity - 1)}>
                  <MinusIcon/>
              </button>
              <input 
                  className="h-8 text-base w-20  focus:border-black text-center border-black border-solid border-[0.75px] rounded-6"
                  value={value?.quantity || 0}
                  type="tel"  
                  onChange={(e) => handleChange(e.target.value)}
              />
              <button className=" align-middle btn-[black] animate-none " 
                  onClick={() => handleChange(value?.quantity + 1)}>
                  <PlusIcon />
              </button>
          </div>
          ) : (
              <p className="text-lg h-5">{value?.quantity || 0}</p>
          )}
        </div>
      </div>
      </div>
  </div>
    )
  }