//components/ClientSideTable.tsx
import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { TableSkeleton } from "@/components/Skeletons/TableSkeleton";
import { getCookie, isIterable, setCookie } from "@/utils";
import { downloadCSV } from "@/utils/fileUtils/downloadCSV";
import { throttle } from "@/utils/misc";
import { compareItems, rankItem } from "@tanstack/match-sorter-utils";
import { createRow, flexRender, getCoreRowModel, getExpandedRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, memo, sortingFns, useReactTable } from "@tanstack/react-table";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BasicToggleButton } from "../Buttons/BasicToggleButton";
import { AscendingIcon, DescendingIcon, FilterIconOutline, SortIcon } from "../Icons";
import { BasicSearchBar } from "../Input/BasicSearchBar";
import { expandColumnHelper } from "./Cells/ExpandIndicatorCell";
import { Filters } from "./Filters";
import { Pagination } from "./Pagination/Pagination";
import { SortControls } from "./SortControls";

const DEFAULT_PAGE_SIZE = 10

export const BasicTable  = ({
  showLoading = false,
  debug = false,
  maxHeight='600px',
  extraFilters = [], 
  className = '', 
  headerClassName = '',
  rowClassName = '',
  meta, 
  state, 
  tableProps, 
  data, 
  showExportButton=false,
  exportFileName='export',
  detachChildrenOnParentFilter=false,
  horizontalScrolling=true,
  initialState,
  initialPageSize=null,
  columnHelper, 
  scrollShadows = true,
  hideSearchBar=false,
  hasPinnedRows=false,
  resetFiltersOnFiltersHide=true,
  renderSubComponent=()=><></>,
  initialSelectionDataIDs, 
  showSortButtons=false,
  hoverShadow=false, 
  handleSelectionChange = () => {}, 
  rowAccordion=false,
  controlsClassName = 'shadow-sm',
  oneRowControls=false, 
  extraRows=[], 
  initialExpand=false,
  canExpand=false,
  disabled=false, 
  subrowFn=(row) => row?.subnodes || [],
  children, 
  tableRef }) => {
  const scrollContainerRef = React.useRef(null)
  const rightShadowRef = React.useRef(null)
  const tableElRef = React.useRef(null)
  const history = useHistory()
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columnFilters, setColumnFilters] = useState(initialState?.columnFilters || [])
  const [globalFilter, setGlobalFilter] = useState('')
  const [rowSelection, setRowSelection] = useState({})
  const [expanded, setExpanded] = useState([])
  const [allowFilter, setAllowFilter] = useState(false)
  const [allowSort, setAllowSort] = useState(false)
  const [pageState, setPageState] = useState({  
    pageIndex: Number(getCookie(`${history.location.pathname}_tablePage`)) || 0,
    pageSize: Number(initialPageSize || getCookie(`${history.location.pathname}_tablePageSize`)) || DEFAULT_PAGE_SIZE
  })

  function onExpandedChange (update) {
    setExpanded(update)
  }
  function onRowSelectionChange (update) {
    setRowSelection(update)
  }
  function onPaginationChange (update) {
    const newState = update(pageState)
    setPageState(update)
    setCookie(`${history.location.pathname}_tablePageSize`, newState.pageSize)
  }

  const disabledChildren = useMemo(() => {
    return disabled && !!children ?  
      isIterable(children) ? 
        children.map((child) => React.cloneElement(child, {disabled, key: child.key})) 
        : React.cloneElement(children, {disabled})
      : children
  },[disabled, children])

  const table = useReactTable({
    columns: [...(canExpand ? expandColumnHelper : []),...columnHelper],
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      pagination: pageState,
      expanded,
      ...state,
    },
    initialState,
    meta,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    debugAll: debug,
    data,
    autoResetPageIndex: false,
    paginateExpandedRows:false,
    onPaginationChange,
    enableSubRowSelection: true,
    getSubRows: canExpand && subrowFn,
    getRowCanExpand: (row) => row.subRows?.length > 0,
    onExpandedChange: onExpandedChange,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: detachChildrenOnParentFilter 
      ? getDetachedChildrenFilteredRowModel() 
      : getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange,
    ...tableProps
  });
  const headers = table.getFlatHeaders()
  const rows = table.getRowModel().rows
  // Set scroll shadow listeners
  useEffect(() => {
    if (tableRef) tableRef.current = table
    const scrollContainer = scrollContainerRef?.current
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer || {}
    if (scrollLeft < scrollWidth - clientWidth - 5) 
      tableElRef?.current?.classList.add('fade-left-active')    
    if (scrollContainer ) {
      scrollContainer.addEventListener("scroll", 
      setShadows, 
      {passive: true})
    }
    return scrollShadows ? () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", 
        setShadows
      )}
    } : () => {}
  },[tableRef,data, scrollContainerRef])
  // Set extra filters
  useEffect(() => {
    // setColumnFilters([...columnFilters, ...extraFilters])
    for (const filter of extraFilters) 
      table.getColumn(filter.id).setFilterValue(filter.value)
  }, [extraFilters])  
  
  // Set initial row selection
  useEffect(() => {
    if (initialSelectionDataIDs?.length > 0 ) {
      rows.forEach(row => {
        if (initialSelectionDataIDs.includes(row.original.id)) {
          row.toggleSelected(true)
          row.pin('top')
        }
      })
    }
    if (initialExpand && !table.getIsAllRowsExpanded())
      table.toggleAllRowsExpanded()
  },[])

  useEffect(() => {
    handleSelectionChange?.(
      table.getSelectedRowModel().flatRows.map(r => {
        r.original.rowID = r.id
        return r.original
    }))  
  },[rowSelection])
const c = table.getAllColumns()[0].accessorFn
  useEffect(() => {
    const maxPageIndex = Math.max(table?.getPageCount() - 1,0)
    if (maxPageIndex < pageState.pageIndex) {
      setPageState({...pageState, pageIndex: maxPageIndex})
    }
  },[columnFilters])

  const toggleFilters = () => {
    setAllowFilter(prev => !prev)
    if (!resetFiltersOnFiltersHide) return
    if (allowFilter) setColumnFilters(extraFilters)
  }

  function toggleSort() {setAllowSort(prev => !prev)}

  function exportData() {
    const flatRows = table.getRowModel().flatRows
    const headers = table.getAllColumns()
      .filter(col => 
        col.getIsVisible() && 
        !col.columnDef?.meta?.hideExport === true &&
        !col.columnDef?.meta?.hiddenCol === true)
      .map(col =>col.columnDef.id)
    const rows = 
      flatRows.map(row => 
        headers.map(header => {
          let exportFn = 
            table.getColumn(header).columnDef.meta?.exportFn || 
            function(row) {return row}
          const rowValue = row.getValue(header)
          return rowValue == null ? '–' : exportFn(rowValue)
        })
    )
    downloadCSV(exportFileName, headers, rows)
  }

  const SubComponent = ({row}) => {
    return <>
      {renderSubComponent({row})}
    </>
}

  const setShadows = throttle(function(event) {
			const { scrollLeft, scrollTop, scrollWidth, clientWidth } = event.target || {}
      const t = tableElRef.current
      scrollTop > 10
        ? t.classList.add('fade-column-active') 
        : t.classList.remove('fade-column-active')
      scrollLeft > 10 
        ? t.classList.add('fade-right-active') 
        : t.classList.remove('fade-right-active')
      scrollLeft > scrollWidth - clientWidth - 5 && t
        ? t.classList.remove('fade-left-active')
        : t.classList.add('fade-left-active')
	}, 40)
  
  return data && !showLoading ? (
    <div  className={`w-full ${className}`}>
      <div className={`flex mb-3 rounded-2xl w-full 
        ${oneRowControls 
          ? 'flex-row flex-wrap gap-4 justify-between' 
          : 'items-start flex-col gap-2 '}
        `}> 
        <div className="gap-y-3 w-full gap-x-50 lg:gap-x-12 items-start flex justify-between flex-wrap-reverse ">
          <div className="flex gap-2 table-element mt-3">
              <BasicSearchBar 
                disabled={disabled} className={`w-40 sm:w-56 md:w-72 table-search border-gray-border ${controlsClassName}`} placeholder="Search all columns" 
                onChange={setGlobalFilter} 
                value={globalFilter} 
                iconClassName='text-accent w-6 h-6 place-self-center'/>

            <BasicToggleButton
              disabled={disabled}
              className={`table-toggle table-element ${controlsClassName} bg-base-100`}
              active={allowFilter}
              onClick={toggleFilters}
              >
              <FilterIconOutline className='w-6 h-6 '/>
            </BasicToggleButton>

            { showSortButtons &&
            <BasicToggleButton
              disabled={disabled}
              onClick={toggleSort}
              className={`table-element table-toggle bg-base-100 ${controlsClassName}`}  
              active={allowSort}>
                <SortIcon className='w-6 h-6'/>
            </BasicToggleButton>
            }
          </div>
          {showExportButton && (
            <BasicRectButton className={'bg-green-button text-white'} onClick={exportData}>
                Export to Excel
            </BasicRectButton>
          )}
          { children  && (
            <div className="flex items-start gap-2 table-element max-h-full">
              {disabled ? disabledChildren : children}
            </div>
          )}  
        </div>
        
        { allowFilter &&
          <div className="flex items-start ">
            <Filters disabled={disabled} headers={headers} table={table}/>
          </div>
        }

        { allowSort &&
          <div className="flex items-start ">
            <SortControls disabled={disabled} headers={headers} table={table}/>
          </div>
        }

      </div>
      <div className="w-full relative bg-base-100 rounded-2xl shadow-all-small shadowed-table">      
          <div 
            className="overflow-auto rounded-xl " 
            style={{maxHeight}} 
            ref={scrollContainerRef}>
            <table
              ref={tableElRef}
              className={`table  w-full  static`}
            >
              <thead className=" z-[11] min-h-[3rem] ">
                  <tr className=" border-gray-200 bg-base-200 border-b">
                      {headers.map((header) => (
                          <PinCheckingCell
                            hidden={header.column.columnDef?.meta?.hiddenCol}
                            key={header.id}
                            rightScrollRef={rightShadowRef}
                            colSpan={header.colSpan}
                            doublePinned={header.column.getIsPinned() != null}
                            pinned={header.column.getIsPinned() || 'top'}
                            style={{width: header.getSize()}}
                            className="whitespace-pre-wrap top-0 items-end first:rounded-tl-xl last:rounded-tr-xl bg-base-100">
                            {header.isPlaceholder ? null : (
                              <>
                                <div key={header.id + 'flexrowcontainer'}
                                  {...{
                                    className: ` w-fit max-w-full
            
                                      select-none flex items-end
                                      ${header.column.getCanSort()
                                        ? 'hover:cursor-pointer'
                                        : ''}
                                      ${headerClassName}`,
                                    onClick: disabled ? null : header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: (
                                      <span className="relative text-[#ff6000]">
                                        <AscendingIcon className='absolute bottom-0'/>
                                      </span>
                                      ),
                                    desc: (
                                      <span className="relative flex-nowrap text-[#4700ff]">
                                        <DescendingIcon className='absolute bottom-0'/>
                                      </span>
                                      ),
                                  }[String(header.column.getIsSorted()) && header.column.getIsVisible()] ?? null}
                                </div>
                              </>
                            )}
                          </PinCheckingCell>
                      ))}
                  </tr>
              </thead>
              <tbody className="min-h-[10rem]">
                {...extraRows}
                { hasPinnedRows && (
                  <>
                    <RowSection
                    {...{disabled, rowAccordion, rows: table.getTopRows(), hoverShadow, rowClassName, SubComponent}}
                    />
                    <RowSection
                    {...{disabled, rowAccordion, rows: table.getCenterRows(), hoverShadow, rowClassName, SubComponent}}
                    />
                    <RowSection
                    {...{disabled, rowAccordion, rows: table.getBottomRows(), hoverShadow, rowClassName, SubComponent}}
                    />
                </>
                )}
                { !hasPinnedRows && (
                  <>
                    <RowSection
                    {...{disabled, rowAccordion, rows, hoverShadow, rowClassName, SubComponent}}
                    />
                  </>
                )}
              </tbody>
            </table>
          </div>
      </div>
      <Pagination history={history} table={table} />
    </div>
  ) : (
    <TableSkeleton />
  )
};

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank,
      rowB.columnFiltersMeta[columnId]?.itemRank
    )
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}


const RowSection = ({disabled, rowAccordion, rows, hoverShadow, rowClassName, SubComponent}) => 
  rows?.map?.( row => (
      <Fragment key={row.id}>
          <PinCheckingRow
            row={row}
            key={row.id}
            className={`
              group
              ${disabled ? 'opacity-40' : ''}
              ${row.getIsSelected() && !rowAccordion ?
                'bg-green-100 outline-green-150 odd:bg-green-100   outline outline-2 -outline-offset-1'
                : ''}
                ${rowAccordion &&row.getIsSelected() ? 'border-none' :''}
              ${hoverShadow ?
                'hover:cursor-pointer hover:shadow-card odd:hover:shadow-card'
                : ''}
              ${rowClassName}
            `}
          >
            {row.getVisibleCells().map((cell) => (
              <PinCheckingCell
                hidden={cell.column.columnDef?.meta?.hiddenCol}
                key={cell.id}
                pinned={cell.column.getIsPinned()}
                style={{width: cell.column.getSize()}}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </PinCheckingCell>
            ))}
          </PinCheckingRow>

          { rowAccordion && row.getIsSelected() && (
            <tr key={'subcomponent' + row.id}>
              <td className="col-span-full colu px-4 table-fixed" colSpan={'100%'}>
                <SubComponent row={row}/>
              </td>
            </tr>
          )}
      </Fragment>
    ))

const PinCheckingCell = ({pinned=false, doublePinned, rightScrollRef, className, children, hidden, ...props}) => {
  const zIndexClass = doublePinned ? 'z-[13]' : 'z-[11]'
  let shadowClass = ''
  if (pinned === 'top') shadowClass += 'before:fade-column ' 
  if (doublePinned && pinned === 'left') shadowClass += 'before:fade-column before:flex-none before:h-fit  '
  if (doublePinned && pinned === 'right') shadowClass += 'after:fade-column '

  if (pinned === 'right') shadowClass += 'before:fade-left '
  if (pinned === 'left') shadowClass += 'after:fade-right '
  if (hidden) return null
  return pinned === false ? (
  <td className={` ${className} `} {...props}>{children}</td>
) : pinned === 'right' ? (
  <th className={`sticky top-0 right-0 bg-base-100 opacity-95 ${zIndexClass} ${shadowClass}
    ${className}`} {...props}>
    {children}
  </th>
) : pinned === 'left' ? (
  <th className={`sticky top-0 z-[11] left-0 bg-base-100 opacity-95 ${shadowClass} ${className} ${zIndexClass}`} {...props}>{children}</th>
) : pinned === 'top' ? (
  <th className={` sticky top-0 bg-base-100 opacity-95 z-[12] ${shadowClass} ${className} `} {...props}>{children}</th>
) : (
  <td className={` ${className} `} {...props}>{children}</td>
)
}

const PinCheckingRow = ({
  row,
  className,
  children,
  ...props
}) => 
  row.getIsPinned() === 'top' ? (
      <tr className={`sticky ${className} `}>
        {children}
      </tr>
    ) : (
      <tr {...{className, ...props}}>
        {children}
      </tr>
    )

function getDetachedChildrenFilteredRowModel() {
   return (table) => memo(() => [table.getPreFilteredRowModel(), table.getState().columnFilters, table.getState().globalFilter], (rowModel, columnFilters, globalFilter) => {
    if (!rowModel.rows.length || !(columnFilters != null && columnFilters.length) && !globalFilter) {
      for (let i = 0; i < rowModel.flatRows.length; i++) {
        rowModel.flatRows[i].columnFilters = {};
        rowModel.flatRows[i].columnFiltersMeta = {};
      }
      return rowModel;
    }
    const resolvedColumnFilters = [];
    const resolvedGlobalFilters = [];
    (columnFilters != null ? columnFilters : []).forEach((d) => {
      var _filterFn$resolveFilt;
      const column = table.getColumn(d.id);
      if (!column) {
        return;
      }
      const filterFn = column.getFilterFn();
      if (!filterFn) {
          console.warn(`Could not find a valid 'column.filterFn' for column with the ID: ${column.id}.`);
        return;
      }
      resolvedColumnFilters.push({
        id: d.id,
        filterFn,
        resolvedValue: (_filterFn$resolveFilt = filterFn.resolveFilterValue == null ? void 0 : filterFn.resolveFilterValue(d.value)) != null ? _filterFn$resolveFilt : d.value
      });
    });
    const filterableIds = columnFilters.map((d) => d.id);
    const globalFilterFn = table.getGlobalFilterFn();
    const globallyFilterableColumns = table.getAllLeafColumns().filter((column) => column.getCanGlobalFilter());
    if (globalFilter && globalFilterFn && globallyFilterableColumns.length) {
      filterableIds.push("__global__");
      globallyFilterableColumns.forEach((column) => {
        var _globalFilterFn$resol;
        resolvedGlobalFilters.push({
          id: column.id,
          filterFn: globalFilterFn,
          resolvedValue: (_globalFilterFn$resol = globalFilterFn.resolveFilterValue == null ? void 0 : globalFilterFn.resolveFilterValue(globalFilter)) != null ? _globalFilterFn$resol : globalFilter
        });
      });
    }
    let currentColumnFilter;
    let currentGlobalFilter;
    for (let j = 0; j < rowModel.flatRows.length; j++) {
      const row = rowModel.flatRows[j];
      row.columnFilters = {};
      if (resolvedColumnFilters.length) {
        for (let i = 0; i < resolvedColumnFilters.length; i++) {
          currentColumnFilter = resolvedColumnFilters[i];
          const id = currentColumnFilter.id;
          row.columnFilters[id] = currentColumnFilter.filterFn(row, id, currentColumnFilter.resolvedValue, (filterMeta) => {
            row.columnFiltersMeta[id] = filterMeta;
          });
        }
      }
      if (resolvedGlobalFilters.length) {
        for (let i = 0; i < resolvedGlobalFilters.length; i++) {
          currentGlobalFilter = resolvedGlobalFilters[i];
          const id = currentGlobalFilter.id;
          if (currentGlobalFilter.filterFn(row, id, currentGlobalFilter.resolvedValue, (filterMeta) => {
            row.columnFiltersMeta[id] = filterMeta;
          })) {
            row.columnFilters.__global__ = true;
            break;
          }
        }
        if (row.columnFilters.__global__ !== true) {
          row.columnFilters.__global__ = false;
        }
      }
    }
    const filterRowsImpl = (row) => {
      for (let i = 0; i < filterableIds.length; i++) {
        if (row.columnFilters[filterableIds[i]] === false) {
          return false;
        }
      }
      return true;
    };
    return filterRows(rowModel.rows, filterRowsImpl, table);
  }, {
    key: "getFilteredRowModel",
    debug: () => {
      var _table$options$debugA;
      return (_table$options$debugA = table.options.debugAll) != null ? _table$options$debugA : table.options.debugTable;
    },
    onChange: () => {
      table._autoResetPageIndex();
    }
  });

  function filterRows(rows, filterRowImpl, table) {
  if (table.options.filterFromLeafRows) {
    return filterRowModelFromLeafs(rows, filterRowImpl, table);
  }
  return filterRowModelFromRoot(rows, filterRowImpl, table);
}
function filterRowModelFromLeafs(rowsToFilter, filterRow, table) {
  var _table$options$maxLea;
  const newFilteredFlatRows = [];
  const newFilteredRowsById = {};
  const maxDepth = (_table$options$maxLea = table.options.maxLeafRowFilterDepth) != null ? _table$options$maxLea : 100;
  const recurseFilterRows = function(rowsToFilter2, depth) {
    if (depth === void 0) {
      depth = 0;
    }
    const rows = [];
    for (let i = 0; i < rowsToFilter2.length; i++) {
      var _row$subRows;
      let row = rowsToFilter2[i];
      const newRow = createRow(table, row.id, row.original, row.index, row.depth, void 0, row.parentId);
      newRow.columnFilters = row.columnFilters;
      if ((_row$subRows = row.subRows) != null && _row$subRows.length && depth < maxDepth) {
        newRow.subRows = recurseFilterRows(row.subRows, depth + 1);
        row = newRow;
        if (filterRow(row) && !newRow.subRows.length) {
          rows.push(row);
          newFilteredRowsById[row.id] = row;
          newFilteredFlatRows.push(row);
          continue;
        }
        if (filterRow(row) || newRow.subRows.length) {
          rows.push(row);
          newFilteredRowsById[row.id] = row;
          newFilteredFlatRows.push(row);
          continue;
        }
        // Add child rows without parent if the parent does not match
        if (!filterRow(row) && newRow.subRows.length) {
          newRow.subRows.forEach((subRow) => {
            rows.push(subRow);
            newFilteredRowsById[subRow.id] = subRow;
            newFilteredFlatRows.push(subRow);
          });
        }
      } else {
        row = newRow;
        if (filterRow(row)) {
          rows.push(row);
          newFilteredRowsById[row.id] = row;
          newFilteredFlatRows.push(row);
        }
      }
    }
    return rows;
  };
  return {
    rows: recurseFilterRows(rowsToFilter),
    flatRows: newFilteredFlatRows,
    rowsById: newFilteredRowsById
  };
}
}

function filterRowModelFromRoot(rowsToFilter, filterRow, table) {
  console.log('here');
  var _table$options$maxLea2;
  const newFilteredFlatRows = [];
  const newFilteredRowsById = {};
  const maxDepth = (_table$options$maxLea2 = table.options.maxLeafRowFilterDepth) != null ? _table$options$maxLea2 : 100;
  const recurseFilterRows = function(rowsToFilter2, depth) {
    if (depth === void 0) {
      depth = 0;
    }
    const rows = [];
    for (let i = 0; i < rowsToFilter2.length; i++) {
      let row = rowsToFilter2[i];
      const pass = filterRow(row);
      if (pass) {
        var _row$subRows2;
        if ((_row$subRows2 = row.subRows) != null && _row$subRows2.length && depth < maxDepth) {
          const newRow = createRow(table, row.id, row.original, row.index, row.depth, void 0, row.parentId);
          newRow.subRows = recurseFilterRows(row.subRows, depth + 1);
          row = newRow;
        }
        rows.push(row);
        newFilteredFlatRows.push(row);
        newFilteredRowsById[row.id] = row;
      } else if (row.subrows) {
        console.log('has subrows', row.subrows);
        return recurseFilterRows(row.subRows, depth + 1);
      }
    }
    return rows;
  };
  return {
    rows: recurseFilterRows(rowsToFilter),
    flatRows: newFilteredFlatRows,
    rowsById: newFilteredRowsById
  };
}