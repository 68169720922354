import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import React, { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { getDateString, getLogoUrl, getMonthDayString } from "@/utils";
import { LogoIcon } from "@/components/LogoIcon";

import useModOrgData from "@/hooks/state/organization/useModOrgData";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import useLeaveOrg from "@/hooks/state/organization/useLeaveOrg";
import { Popup } from "@/wrappers/swal/Popup";
import { XButton } from "@/components/Buttons";
import { OrgLogo } from "@/components/Image/OrgLogo";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import { APP_STATUS_LABEL, UIC_ORG } from "@/constants";
import { ExpandIndicatorCell } from "@/components/Tables/Cells/ExpandIndicatorCell";
import { BasicTable } from "@/components/Tables/BasicTable";
import { OrgCell } from "@/components/Tables/Cells/OrgCell";

export const CommunityAffiliationTable = ({ data, isBusiness = false, ...props }) => {
  const appStatusQuery = useAppStatusEnum()
  const appStatuses = appStatusQuery.data || []
  return (
    <BasicTable 
      className="table-xs md:table-md text-xs sm:text-sm md:text-md  xl:text-xl "
      initialExpand
      initialState={{
        sorting: [{
            id: "name",
            desc: false
        }]
      }}
      headerClassName="text-[10px] md:text-xs "
      data={data} 
      columnHelper={MyCommunitiesCols}
      canExpand
      meta={{
        appStatuses
      }} 
      {...props}
    />
  )
  
}

const OrgSummary = ({org, ...props}) => {
  const numMembers = !isNaN(Number(org?.members)) ? Number(org?.members) : org?.members?.length
  const numFIN = org?.FINs
  const showFinCount = org.brand === "false" && org.corporation === 'false' && (org.id != UIC_ORG)
  return (
    <div className="flex flex-col gap-2" {...props}>
        <p className="text-sm  xl:text-lg font-medium text-gray-normal whitespace-nowrap">
          {`${numMembers} members`}
        </p>
        { showFinCount && (
          <p className="text-sm  xl:text-lg font-medium text-gray-normal whitespace-nowrap">
            {`${numFIN} Families in need`}
          </p>
        )}
    </div>
)}

const DeleteButton = ({row}) => {
    const leaveOrgMutation = useLeaveOrg({
        onSuccess: (data) => {SuccessToast.fire({text: 'Successfully left organization'})},
        onError: (error) => {FailPopup.fire({text: 'Failed to leave organization'})},
    })

  const handleDeleteClicked = () => {
      Popup.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover your personal settings for this organization!', 
        confirmButtonText: `Yes, leave ${row?.name} `,
        showConfirmButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          leaveOrgMutation.mutate({orgid: row.id})
        }
      })
  }

    return (
      <XButton 
        className={'w-10 h-10 lg:w-20 lg:h-20'} 
        buttonProps={{onClick: handleDeleteClicked}}
        svgProps={{className:'w-6 h-6 md:w-8 md:h-8 xl:w-10 xl:h-10'}} />
    )
}

const UserTypes = ({row, appStatuses, disabled}) => {
    if (!row.members) return !disabled && <>Member</>
    const me = row.members?.find?.((member) => member.id === row.userid)
    const a = APP_STATUS_LABEL.APPROVED
    return !disabled && (
      <>
          { me?.FIN  === a && 
              <span>Family In Need</span>
          }
          { me?.Ambassador === a && 
              <span>Ambassador</span>
          }
          { me?.["Social Media Influencer"] === a && 
              <span>Social Media Influencer</span>
          }
          { me?.Guardian === a ? (
              <span>Guardian</span>
              ): me?.Guest ? (
                  <span >Guest</span>
              ) : (
                  <span>Member</span>
              )
          }
      </>
    )
}


// createColumnHelper helps us create columns with maximum type safety.
// we assign the type person so that it knows the structure for our data
const columnHelper = createColumnHelper();

export const MyCommunitiesCols = [
    columnHelper.display({
    header: () => <span>ORGANIZATION</span>,
    size: 100,
    maxSize: 100,
    id: "logo",
    cell: ({row}) =>  
      <OrgCell 
        className={row.original?.disabled ? 'opacity-30' : ''}
        org={row.original}
        style={{paddingLeft: `${row.depth * 1}rem`}}   
        />,
    }),
    columnHelper.accessor((row) => row.name, {
    header: () => <span></span>,
    id: "name",
    size: 50,
    enableSorting: true,
    cell: ({row}) => 
    <OrgSummary 
      className={row.original?.disabled ? 'opacity-30' : ''}
      style={{paddingLeft: `${row.depth * 1}rem`}}  
      org={row.original} />,
    }),
  columnHelper.accessor((row) => row.id, { // TODO
    id: "roles",
    size: 50,
    cell: ({row, table}) => <UserTypes
      disabled={row.original?.disabled}
      appStatuses={table.options.meta.appStatuses}
      row={row.original}/>,
    header: () => <span>USER TYPE</span>,
  }),
  columnHelper.accessor((row => row.roleexpiry), {
    id: 'Expiry',
    size: 60,
    cell: ({getValue, row}) => (
      <span
        className={`${row.original?.disabled ? 'opacity-30' : ''} whitespace-nowrap`}>
          { getValue() 
            ? getMonthDayString(getValue())
            : 'Not Set'
            
          
        }
      </span>
      ),
    header: () => <span>ROLE EXPIRY DATE</span>,
  }),
  columnHelper.accessor((row) => row.timestamp, {
    id: "membersince",
    size: 50,
    cell: ({row, getValue}) => <span className={`${row.original?.disabled ? 'opacity-30' : ''} whitespace-pre-wrap md:whitespace-nowrap`}>{getDateString(getValue())}</span>,
    header: () => <span>MEMBER SINCE</span>,
  }),
  columnHelper.display({
    id: "actions",
    size: 10,
    maxSize:10,
    cell: ({row}) => !row.original?.disabled && (
    <DeleteButton row={row.original}/>
  ),
    header: () => <span>REMOVE</span>,
  }),
];