import { commaifyNumberStr, formatToPriceStr } from '@/utils/textUtils';
import { useController } from 'react-hook-form';
import { InputBase } from './InputBase';
import React from 'react';

export const FormBasicNumberInput = ({
    label=null,
    labelView=false,
    name,
    className, 
    error: controlledError,
    isError: isControlledError=false,
    errorText: controlledErrorText,
    required=false,
    containerClassName, 
    formState=null,
    defaultValue,
    disabled=false,
    placeholder,
    customSize=false,
    initialValue,
    compact=false,
    control,
    children,
    ...props}) => {
    const {field, fieldState: {error}, formState: { disabled: formDisabled}} = 
            useController({name, control,  defaultValue: initialValue, disabled })
    const isError = isControlledError || error != null;
    const errorText = controlledErrorText || error?.message;
    function formatValue(e) {
        const ret = formatToPriceStr(e.target.value)
        if (ret !== 'NaN' && ret !== 'NaN.' ) 
            field.onChange(ret)
    }
    function handleBlur(e){
        if (e.target.value === '') {
            e.target.value = 0
            formatValue(e)
        }
    }
    return (
        <InputBase 
            {...{
                label: children || label,
                isError,
                errorText,
                required,
                containerClassName,
                disabled: field.disabled
            }}
        >
            <input 
                type='text'
                step={"any"}
                className={`
                    input focus:outline-none  focus:border-primary
                    ${customSize ? '' : 'input-little'}
                    ${isError ? 'input-error' : 'input-bordered'}
                    ${disabled || formDisabled ? 'input-disabled' : ''}
                    ${labelView ? 'placeholder:text-current' : ''}
                    ${className}
                `} 
                {...{
                    placeholder: labelView ? label : placeholder,
                    name, 
                    disabled: field.disabled,
                    ...field,
                    onChange: formatValue,
                    // onBlur: handleBlur,
                    value: field.value || '',
                }}
                {...props}
                min={-1}
                onBlur={handleBlur}
            />
        </InputBase>

    );
};


export const BasicNumberInput = ({
    label=null,
    labelView=false,
    name,
    className, 
    error,
    isError,
    errorText,
    required=false,
    containerClassName, 
    formState=null,
    defaultValue,
    disabled=false,
    onChange=()=>{},
    controlled =false,
    placeholder,
    customSize=false,
    compact=false,
    value: controlledValue,
    children,
    ...props}) => {
    const [value, setValue] = React.useState(controlledValue)
    function formatValue(e) {
        const ret = formatToPriceStr(e.target.value)
        if (ret !== 'NaN' && ret !== 'NaN.' ) 
            controlled ? onChange(ret) : setValue(ret)
    }
    function handleBlur(e){
        if (e.target.value === '') {
            e.target.value = 0
            formatValue(e)
        }
    }
    return (
        <InputBase 
            {...{
                label: children || label,
                isError,
                errorText,
                required,
                containerClassName,
                disabled: disabled
            }}
        >
            <input 
                type='text'
                step={"any"}
                className={`
                    input focus:outline-none  focus:border-primary
                    ${customSize ? '' : 'input-little'}
                    ${isError ? 'input-error' : 'input-bordered'}
                    ${disabled  ? 'input-disabled' : ''}
                    ${labelView ? 'placeholder:text-current' : ''}
                    ${className}
                `} 
                {...{
                    placeholder: labelView ? label : placeholder,
                    name, 
                    disabled: disabled,
                    onChange: formatValue,
                    // onBlur: handleBlur,
                    value: (controlled ? controlledValue : value) || '',
                }}
                {...props}
                min={-1}
                onBlur={handleBlur}
            />
        </InputBase>

    );
};



