import { getEventInventoryUrl, getProductUrl, getWishlistUrls, rangeArray } from '@/utils';
import { useEffect, useMemo } from 'react';
import { CarouselBase } from './CarouselBase';

export const WishlistProductCarousel = ({
    productid,
    images, 
    modifiedTimestamp,
    className = 'max-h-[20rem]  lg:max-h-[30rem] lg:max-w-[50%]', 
    ...props }) => {
    const urls = useMemo(() => getWishlistUrls(images),[images])
    return (images) && (
      <CarouselBase
        modifiedTimestamp
        className='box-border object-cover border border-gray-border' 
        buttonType='ecommerceButtons'
        // aspectRatioClassName='aspect-product'
        disableTracking={images != null}
        images={urls}
      />
    );
};
