import { useTranslations } from "@/hooks/translations";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";

import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";

import CreateDonationEventDialog from "@/components/Dialog/CreateDonationEventDialog";
import { DonationEventsTable } from "@/components/Tables/DonationEventsTable";
import { BD_MODE, BD_ROLES, MILLISEC } from "@/constants";
import useModBDEvent from "@/hooks/state/donation/useModBDEvent";
import useBDStatusEnum from "@/hooks/state/enum/useBDStatusEnum";
import { closeDialogWithRef, getCookie, openDialogWithRef, resetFormWithId, setCookie } from "@/utils";
import { getApproveDestStatus, getDeclineDestStatus } from "@/utils/tableUtils";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PageTitle } from "../Label/PageTitle";
import { BasicTabs } from "../Tabs/BasicTabs";

const initialDialogState = (context) => ({
  orgid: context?.orgid,
  event: null,
  description: "",
  starttime: Date.now() / 1000,
  endtime: (Date.now() + MILLISEC.DAY) / 1000 ,
  processor: context?.orgid,
  charity: null,
  name: "",
  peritemreport: false,
  weight: "",
  price: "",
  images: [],
  idx: -1,
})

const tabNames = ['Pipeline', 'In-Process', 'Live', 'Historical']

export const DonationManagementPage = ({role, donations}) => {
  const dialogRef = useRef();
  const contextQuery = useCurrentOrgContext();
  const text = useTranslations("brandDonation");
  const history = useHistory()
  const [isExisting, setIsExisting] = useState(false);
  const [dialogData, setDialogData] = useState({...initialDialogState(contextQuery.data), orgid: contextQuery.data?.orgid});
  const [tab, setTab] = useState(BD_MODE.PIPELINE);
  const eventStatusesQuery = useBDStatusEnum()
  const eventStatuses = eventStatusesQuery.data;

  const donationMutation = useModBDEvent({
    orgid: contextQuery?.data?.orgid,
    onSuccess: (data) => {
      if (data?.status === 'donation event not modified')
        throw new Error('Failed to update event');
      SuccessToast.fire({text: 'Successfully updated event'})
    },
    onError: (error) => {FailPopup.fire({text: 'Failed to update event'})}
  });

  const constraints = useMemo(() => {
    return { 
      editEnabled:
        BD_ROLES.UIC || 
        !(tab >= BD_MODE.LIVE) && 
        ((tab === BD_MODE.PIPELINE && role === BD_ROLES.BRAND) || 
        (tab === BD_MODE.IN_PROCESS && role === BD_ROLES.PROCESSOR)),
      auditEnabled:
        BD_ROLES.UIC || 
        !(tab >= BD_MODE.LIVE) && (
          (tab === BD_MODE.PIPELINE && role === BD_ROLES.BRAND) ||
          (tab === BD_MODE.IN_PROCESS && (role === BD_ROLES.PROCESSOR || role === BD_ROLES.BRAND))
        ),
      inventoryEnabled: true,
      eligibilityEnabled: 
        role === BD_ROLES.UIC ||
        (tab === BD_MODE.PIPELINE && role === BD_ROLES.BRAND) ||
        (tab === BD_MODE.IN_PROCESS && role === BD_ROLES.PROCESSOR),
      shippingEnabled:
        BD_ROLES.UIC ||
        tab >= BD_MODE.LIVE,
      internalProcessing: tab === BD_MODE.IN_PROCESS && role === BD_ROLES.BRAND
    }
  },[tab,role])

  const orgPathName = history.location.pathname.split('/')[1];
  
  useLayoutEffect(() => {
    const defaultTab = Number(getCookie('defaultDonationTab')) ?? 0
    if (defaultTab == null) 
      setCookie('defaultDonationTab', 0, `/${orgPathName}/services/donation`)
    else setTab(Number(defaultTab))
  }, [])

  useLayoutEffect(() => {
    resetFormWithId('eventform');
  },[dialogData])

  const handleApprove = (eventid) => {
    const e = donations?.find?.(d => d?.id === eventid);
    if (!e) return;
    const destStatus = getApproveDestStatus( eventStatuses, tab, contextQuery?.data, e);
    const params = {
      eventid,
      status: destStatus,
      orgid: e?.orgid
    }
    donationMutation.mutate(params);
  }
  
  const handleDecline = (eventid) => {
    const e = donations?.find?.(d => d?.id === eventid);
    if (!e) return;
    const destStatus = getDeclineDestStatus(eventStatuses, tab, contextQuery?.data);
    const params = {
      eventid,
      status: destStatus,
      orgid: e?.orgid
    }
    donationMutation.mutate(params);
  }

  const changeTab = (tab) => {
    setTab(tab);
    setCookie('defaultDonationTab', tab);
  }

  const handleCreateDialog = () => {
    setIsExisting(false);
    setDialogData({...initialDialogState(contextQuery.data), orgid: contextQuery.data?.orgid});
    openDialogWithRef(dialogRef);
  }

  const handleEdit = (item) => {
    setIsExisting(true);
    setDialogData({
      ...item,
    });
    openDialogWithRef(dialogRef);
  }

  const handleSubmit = () => {
    setDialogData({...initialDialogState(contextQuery.data)});
    closeDialogWithRef(dialogRef);
  }

  return (
    <div className="flex flex-col">
        <PageTitle>Donations</PageTitle>
        <BasicTabs activeTab={tab} onTabClick={changeTab} tabNames={tabNames} />
          <DonationEventsTable
            auditEnabled={constraints.auditEnabled}
            internalProcessing={constraints.internalProcessing}
            role={role}
            mode={tab} 
            handleCreate={handleCreateDialog} 
            handleEdit={handleEdit} 
            handleApprove={handleApprove}
            handleDecline={handleDecline}
            data={donations}                
          />
        
      <CreateDonationEventDialog
        canEdit={constraints.editEnabled}
        partialEdit={role === BD_ROLES.PROCESSOR}
        dialogRef={dialogRef}
        isExisting={isExisting}
        onFinish={handleSubmit}
        data={dialogData}
      />
  </div>
  );
}
