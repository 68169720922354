import { postPromise } from '@/apis/base';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import config from '@/apis/config';
import { convertObjFieldsToString } from '@/utils';

const signUpOrg = (params) => {
  params.type = ROUTE_IDS.SIGN_UP_ORG;
  return postPromise(
    config.API_BASE_URL,
    convertObjFieldsToString(params), {json: true})
};

export { signUpOrg };
