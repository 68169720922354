
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { BlackButton } from '../../components/Buttons';
import { PasswordInput } from '../../components/Inputs';

import { useRegionCitySelect } from '@/components/CountryRegionSelect';
import { ErrorAlert } from '@/components/alerts/ErrorAlert';
import useCheckEmailUnique from '@/hooks/state/auth/useCheckEmaiUnique';
import useMinimalOrgs from '@/hooks/state/organization/useMinimalOrgs';
import { AuthPasswordInput, AuthTextInput } from '@/views/pages/Auth/components/BasicInputs';

export const UserRegister = ({  text,
    onSubmit,
    code,
    history,
    includePhone=true,
    suffix = null,
     ...props}) => {
    const [values, setValues] = useState({
        firstname: '',
        countryid: 27003,
        regionid: '',
        cityid: '',
        lastname: '',
        occupation: '',
        password: '',
      });
    const [validations, setValidations] = useState({
        firstname: '',
        lastname: '',
        countryid: 27003,
        regionid: '',
        cityid: '',
        occupation: '',
        password: '',
        confirmPassword: '',
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
  // Get all organizations
  const orgsQuery = useMinimalOrgs({})
  const uniqueEmailMutation = useCheckEmailUnique({})
  const {RegionSelect, CitySelect} = useRegionCitySelect({
    regionClassName:'border-2 border-gray-100  rounded-md border-opacity-40',
    cityClassName:'border-2 border-gray-100 rounded-md border-opacity-40',
    onRegionChange: (key) => setValues((prevState) => ({...prevState, regionid: key})),
    onCityChange: (key) => {
      setValues((prevState) => ({...prevState, cityid: key}))},
    regionError: validations.regionid !== '',
  })  

  const handleChange = (prop, value) => {
    setValidations((prevState) => ({ ...prevState, [prop]: '' }));
    setValues((prevState) => ({ ...prevState, [prop]: value }));
  };

  const handleContinue = () => {
        if (values.firstname === '')
          setValidations((prevState) => ({...prevState, firstname: 'has-empty',}));
        else if (values.lastname === '')
          setValidations((prevState) => ({...prevState,lastname: 'has-empty',}));
        else if (values.occupation === '')
          setValidations((prevState) => ({...prevState,occupation: 'has-empty',}));
        else if (values.password === '')
          setValidations((prevState) => ({...prevState,password: 'has-empty',}));
        else if (confirmPassword === '')
          setValidations((prevState) => ({...prevState,confirmPassword: 'setConfirmPassworda',}));
        else if (values.password !== confirmPassword)
          setValidations((prevState) => ({...prevState,confirmPassword: 'has-error',}));
        else if (values.password.length < 6)
          setValidations((prevState) => ({...prevState,password: 'has-error',}));
        else
          onSubmit(values)
  };

    return (
        <form>
            <div className="w-full">
              <p className="text-3xl font-semibold">{text.personalInfo}</p>
            </div>
            <div className="mt-3 w-full">
              {error && (
                <ErrorAlert onClose={() => setError(null)}>
                  {error}
                </ErrorAlert>
              )}
            </div>
            <div className="mt-3 w-full">
              <AuthTextInput
                name='firstname'
                label={text.firstname}
                value={values.firstname}
                autoComplete='given-name'
                onChange={(e) => handleChange('firstname', e.target.value)}
                errorMessage={
                  validations.firstname === 'has-empty'
                    ? text.fieldRequired
                    : ''
                }

              />
            </div>
            <div className="mt-3 w-full">
              <AuthTextInput
                name='lastname'
                label={text.lastname}
                value={values.lastname}
                autoComplete='family-name'
                onChange={(e) => handleChange('lastname', e.target.value)}
                errorMessage={
                  validations.lastname === 'has-empty' ? text.fieldRequired : ''
                }
              />
            </div> 
            <div className="mt-3 w-full">
              <AuthTextInput
                name='occupation'
                label={text.occupation}
                value={values.occupation}
                autoComplete='organization-title'
                onChange={(e) => handleChange('occupation', e.target.value)}
                errorMessage={
                  validations.occupation === 'has-empty'
                    ? text.fieldRequired
                    : ''
                }
              />
            </div>

            <div className="mt-3 w-full">
              {RegionSelect}
            </div>

            <div className="mt-3 w-full">
              {CitySelect}
            </div>

            <div className="mt-3 w-full">
              <AuthPasswordInput
                name='password'
                label={text.password}
                value={values.password}
                onChange={(e) => handleChange('password', e.target.value)}
                autoComplete='new-password'
                errorMessage={
                  validations.password === 'has-empty'
                    ? text.fieldRequired
                    : validations.password === 'has-error'
                    ? text.passwordInvalid
                    : ''
                }
              />
            </div>

            <div className="mt-3 w-full">
              <AuthPasswordInput
                name='confirmPassword'
                label={text.confirmPassword}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete='current-password'
                errorMessage={
                  validations.confirmPassword === 'has-empty'
                    ? text.fieldRequired
                    : validations.confirmPassword === 'has-error'
                    ? text.confirmPasswordInvalid
                    : ''
                }
              />
            </div>
            <div className="mt-3 w-full">
              <span className="text-gray-400">
                {text.agreeTo}{' '}
                <Link className="underline" to="/terms">
                  {text.terms}
                </Link>{' '}
                {text.and}{' '}
                <Link className="underline" to="/privacy">
                  {text.privacy}
                </Link>
                .
              </span>
            </div>
            <div className="mt-9 w-full">
              <BlackButton
                variant="contained"
                size="large"
                className="w-full"
                onClick={handleContinue}
              >
                {text.continue}
              </BlackButton>
            </div>
            <div className="mt-3 w-full flex justify-center">
              <span className="text-gray-400">
                {text.haveAccount}{' '}
                <Link className="underline" to="/auth/login">
                  {text.signin}
                </Link>
              </span>
            </div>
        </form>
    );
};

