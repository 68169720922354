import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { CheckboxCell } from "../Cells/CheckboxCell";
import { CheckboxHeader } from "../Cells/CheckboxHeader";
import { OrgLogo } from "@/components/Image/OrgLogo";
import { BasicPlusButton } from "@/components/Buttons/BasicPlusButton";
import { BasicMinusButton } from "@/components/Buttons/BasicMinusButton";
import { OrgCell } from "../Cells/OrgCell";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove

export const EligibleOrgsColumns = [
  // columnHelper.accessor((row) => row.name, {
  //   id: "Organization",
  //   size: 200,
  //   filterFn: stringFilter,
  //   cell: (info) => <span>{info.getValue()}</span>,
  //   header: (info) => <span>ORGANIZATION</span>,
  // }),
  // columnHelper.display({
  //   id: "Logo",
  //   size: 90,
  //   cell: ({row}) => <OrgLogo id={row.original.id}/>,
  // }),
columnHelper.accessor((row) => row.name, {
    id: "Organization",
    size: 200,
    filterFn: stringFilter,
    cell: (info) => <OrgCell org={info.row.original}/>,
    header: (info) => <span>ORGANIZATION</span>,
}),
columnHelper.accessor((row) => row['org type name'], {
    id: "Type",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>TYPE</span>,
  }),
columnHelper.display({
    id: "Actions",
    size: 10,
    filterFn: stringFilter,
    cell: ({row,table}) => <OrgSelection disabled={table.options.meta.disabled} row={row}/>,
  }),
]

const OrgSelection = ({row, disabled}) => {
  return row.getIsSelected() ? (
    <BasicMinusButton  disabled={disabled} className={'w-8 h-8'} iconClassName='w-5 h-5' 
      onClick={() => {
        row.toggleSelected()
        row.pin('false')
      }
    }/>
  ) : (
    <BasicPlusButton disabled={disabled} className={'w-8 h-8'} iconClassName={'w-5 h-5'} 
      onClick={() => {
        row.pin('top')
        row.toggleSelected()
      }}
      />
  )
}

