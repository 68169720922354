import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getLiveBDEvents } from '@/apis/base/donation/getLiveBDEvents';

export default function useLiveBDEvents(params) {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_LIVE_BD_EVENTS],
        queryFn: (params) => getLiveBDEvents(params),
        staleTime: MILLISEC.MIN_15,
        ...params
    });
    return query;
}