import React, { useEffect, useRef, useState } from 'react';


import { openDialogWithRef, subObject, validateEmail } from '../../../../../utils';

import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { OrgCreationInvitationDialog } from '@/components/Dialog/OrgCreationInvitationDialog';
import DropdownTwo from '@/components/DropDownTwo';
import { UICAllOrgTable } from '@/components/Tables/UICAllOrgTable/UICAllOrgTable';
import useAllOrgs from '@/hooks/state/organization/useAllOrgs';
import useModOrgData from '@/hooks/state/organization/useModOrgData';
import useSendOrgInvite from '@/hooks/state/organization/useSendOrgInvite';
import useUICOCreateOrg from '@/hooks/state/organization/useUICOCreateOrg';
import useUserData from '@/hooks/state/user/useUserData';
import { queryResultFns } from '@/wrappers/swal/queryResultFns';
import CreateOrganizationDialog from "../../../../../components/Dialog/CreateOrganizationDialog";
import { useAppContext } from '../../../../../context/AppContext';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';

function TabContent({ text, type }) {
  const { subnodes, name, id } = type || {};
  const inviteDialogRef = useRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });
  const [openDeleteOrgDlg, setOpenDeleteOrgDlg] = useState(false);
  const [orgIdToDelete, setOrgIdToDelete] = useState(null);
  const [openCreateOrgDialog, setOpenCreateOrgDialog] = useState(false);
  const [subtypeFilters, setSubtypeFilters] = useState(subnodes ? [...subnodes.map((st) => st.id),id] : [id]);
  const [typeFilter, setTypeFilter] = useState(subtypeFilters);
  const [adminids, setAdminIds] = useState([]);
  const orgsQuery = useAllOrgs({})
  const createOrgMutation = 
    useUICOCreateOrg(queryResultFns(text.createOrgSuccess, text.createOrgFail,handleOrgCreationSuccess))
  const modOrgRolesMutation = useModOrgData(queryResultFns(
      'Successfully inherited parent community roles ', 
      'Failed to inherit parent community roles'))
  const sendOrgInviteMutation = 
    useSendOrgInvite(queryResultFns(text.inviteSuccess, text.inviteFail));

  useEffect(() => {
    setAdminIds(recursiveGetAdmins(orgsQuery.data || []))
  },[orgsQuery.data])

  useEffect(() => {
    setSubtypeFilters(subnodes ? [...subnodes.map((st) => st.id),id] : [id])
    setTypeFilter(subnodes ? [...subnodes.map((st) => st.id),id] : [id])
  },[type])

    function handleOrgCreationSuccess(data, req) {
    SuccessToast.fire({text: 'Successfully created organization'})
    const parentOrg = orgsQuery.data.find(org => org.id === req.parent)
    if (!parentOrg) return
    const roles = subObject(parentOrg, ['brand', 'charity', 'processor','corporation','ecommerce'])
    if (Object.values(roles).some(v => v)) {
      modOrgRolesMutation.mutate({orgid: data.orgid, ...roles})
    }
  }

  function recursiveGetAdmins(orgs, admins=new Set()) {
    orgs.forEach((org) => {
      if (org.owner) admins.add(org.owner)
      if (org.subnodes) recursiveGetAdmins(org.subnodes, admins);
    })
    return Array.from(admins || []);
  }

  // function recursiveAddAdmins(orgs, admins=[]) {
  //   orgs.forEach((org, i) => {
  //       if (org.owner) {
  //         org.admin = admins.find((a) => a.id === org.owner)
  //         org.ownerInfo = admins.find((a) => a.id === org.owner)
  //         }
        
  //       if (org.subnodes) recursiveAddAdmins(org.subnodes, admins);
  //     })
  //   return orgs;
  // }

  const handleInviteClicked = () => {
    openDialogWithRef(inviteDialogRef);
    setOpenDialog(true);
    setDialogData({
      firstname: '',
      lastname: '',
      email: '',
    });
  };

  const handleChangeDialog = (name, val) => {
    setDialogData({ ...dialogData, [name]: val });
  };

  const handleInvite = () => {
    sendOrgInviteMutation.mutate(dialogData);
    setOpenDialog(false);
  };

  const onDeleteOrgClicked = (org_id) => {
    setOpenDeleteOrgDlg(true);
    setOrgIdToDelete(org_id);
  }

  const cancelDeleteOrg = () => {
    setOpenDeleteOrgDlg(false);
  }

  const confirmDeleteOrg = () => {
    modOrgMutation.mutate({orgid: orgIdToDelete, delete: true})
    setOpenDeleteOrgDlg(false);
  }

  const modOrgMutation = 
    useModOrgData( 
        queryResultFns(
          "Successfully modified organization",
          "Failed to modify organization")
    ); 

  const createOrg = (org_details) => {
    console.log("org_details", org_details)
    createOrgMutation.mutate(org_details)    
    setOpenCreateOrgDialog(false);
  }

  const ecommercePermissionUpdated = (org, newVal) => 
    modOrgMutation.mutate({orgid:org.id, ecommerce: newVal});
  const handleProcessorToggle = (org,newValue) => 
    modOrgMutation.mutate({orgid:org.id, processor: newValue});
  const handleBrandToggle = (org,newValue) => {
    // console.log(newValue);
    modOrgMutation.mutate({orgid:org.id, brand: newValue});
  }
  const handleCorporationToggle = (org,newValue) =>
    modOrgMutation.mutate({orgid:org.id, corporation: newValue});
  const handleCharityToggle = (org,newValue) => 
    modOrgMutation.mutate({orgid:org.id, charity: newValue});

  return (
    <>
      { orgsQuery.isLoading ?
        (<div className="w-full h-full flex justify-center items-center">
          <span className="loading loading-ring loading-lg"></span>
        </div> 
      ) : (
        <div>
          <UICAllOrgTable 
            orgs={orgsQuery.data}
            visibleTypes={typeFilter}
            meta={{
              handleBrandToggle,
              handleCharityToggle,
              handleProcessorToggle,
              handleCorporationToggle,
              handleEcommerceToggle: ecommercePermissionUpdated,
              handleOrgDelete: onDeleteOrgClicked,
            }}
          >
            <div className="flex mb-6 flex-row items-center justify-end gap-2 h-h-[45px]-element">
              { 
                subnodes && 
                  <div className='w-44 min-w-fit h-fit '>
                    <DropdownTwo 
                      className={' '}
                      options={
                        subnodes
                          .map((st) => ({key: st.id, label: st.name}))
                            .concat({key: [...subnodes.map(st=>st.id),id],label: 'All Subtypes'})} 
                      placeHolder={'All Subtypes'}
                      onChange={({key,label}) => {
                        const subTypeArr = Array.isArray(key) ? key : [key];
                        setSubtypeFilters(subTypeArr)
                        setTypeFilter(subTypeArr)
                      }}
                    />    
                  </div>
              }
                <BasicRectButton className='btn-secondary ml-1' onClick={() => handleInviteClicked()}>
                  {text.invite}
                </BasicRectButton>
                <BasicRectButton 
                  onClick={() => setOpenCreateOrgDialog(true)} 
                  className={'bg-green-button text-base-100'}>
                    {text.create}
                </BasicRectButton>
            </div>
          </UICAllOrgTable>
        </div>
      ) 
    }
      <OrgCreationInvitationDialog
        dialogRef={inviteDialogRef}
      />
      { openCreateOrgDialog && 
      ( 
      <CreateOrganizationDialog
        open={openCreateOrgDialog}
        onClose={() => setOpenCreateOrgDialog(false)}
        onCreate={createOrg}
        orgTypeName={name}
      />)}
    </>
  );
}

export default TabContent;
