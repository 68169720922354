import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getAllOrgs } from '@/apis/base/organization';
import { getProductCategories } from '@/apis/base/wishlist/getProductCategories';
import { getWishlistProducts } from '@/apis/base/wishlist/getWishlistProducts';

const compare = (product1, product2) => {
  if (product1.productid < product2.productid) {
    return -1;
  }
  if (product1.productid > product2.productid) {
    return 1;
  }
  return 0;
};

export default function useWishProducts(props={}) {
  const productQuery = useQuery({
    queryKey: ['wishlist', ROUTE_IDS.GET_WISHLIST_PRODUCTS],
    queryFn: getWishlistProducts,
    retry: 2,
    // notifyOnChangeProps: 'data',
    staleTime: MILLISEC.MIN_15,
    select,
    ...props
  });
  return productQuery;
}

function select(data) {
  return structuredClone(data).sort(compare)
}