import DropdownTwo from "@/components/DropDownTwo";
import { InputBase } from "@/components/Input/InputBase";
import { UserCell } from "@/components/Tables/Cells/UserCell";
import { OrgDropdown } from "@/components/dropdowns/OrgDropdown";
import { forwardRef, useEffect, useState } from "react";
import { useController } from "react-hook-form";

// TODO add extravalues to FormBasicOrgDropdown as seen above
export const FormBasicUserDropdown = forwardRef(function({
    users = [],
    extraValues,
    initialValue = {},
    label=null,
    name,
    className, 
    required=false,
    onChange=()=>{},
    placeHolder='Choose User',
    containerClassName, 
    control,
    disabled: controlledDisabled=false,
    ...props}, ref)  {
        const {field, fieldState: {error}, formState: { disabled }} = 
            useController({name, control, defaultValue: initialValue})
        const [selectedUser, setSelectedUser] = useState(initialValue)

        useEffect(() => {
            setSelectedUser(initialValue)
        },[initialValue])

        function handleChange(val) {
            control ? field.onChange(val) : onChange(val)
        }

        const options = users?.length > 0 ? users : []

        extraValues && extraValues.concat(extraValues)

        const isDisabled = controlledDisabled || disabled
        return (
            <InputBase
                {...{
                    label,
                    isError: error!=null,
                    errorText: error?.message,
                    required,
                    containerClassName,
                    disabled: isDisabled
                }}
            >
                <DropdownTwo 
                    value={control ?field.value : selectedUser}
                    onChange={handleChange}
                    disabled = {isDisabled}
                    isError={error!=null}
                    initialValue={initialValue}
                    nameFn={(user) => user?.name}
                    valueFn={(user) => user?.id}
                    renderFn={RenderUser}
                    className={`
                        border-[1px]
                        border-gray-border
                        focus:border-primary
                        disabled:opacity-30
                        ${isDisabled ? 'input-disabled bg-gray-100 border-none' : ''}
                        ${className}
                    `}

                />
            </InputBase>
        )
});
// TODO add extravalues to FormBasicOrgDropdown as seen above
export const BasicUserDropdown = forwardRef(function({
    users = [],
    extraValues,
    initialValue = {},
    label=null,
    name,
    className, 
    required=false,
    onChange=()=>{},
    placeHolder='Choose User',
    controlled=false,
    value,
    isError=false,
    errorMessage='',
    containerClassName='', 
    disabled,
    ...props}, ref)  {


        function handleChange(val) {
            onChange(val)
        }

        const options = users?.length > 0 ? users : []

        extraValues && extraValues.concat(extraValues)
        return (
            <InputBase
                {...{
                    label,
                    isError: isError,
                    errorText: errorMessage,
                    required,
                    containerClassName,
                    disabled: disabled
                }}
            >
                <DropdownTwo 
                    options={options}
                    value={value}
                    onChange={handleChange}
                    disabled = {disabled}
                    isError={isError}
                    initialValue={initialValue}
                    placeHolder={placeHolder}
                    labelClassName='!min-h-[3rem]'
                    // nameFn={(user) => user?.name}
                    valueFn={(user) => user?.id}
                    renderFn={RenderUser}
                    className={`
                        disabled:opacity-30
                        ${disabled ? 'input-disabled bg-gray-100 border-none' : ''}
                        ${className}
                    `}

                />
            </InputBase>
        )
});


const RenderUser = (user) => {
    return (
        <div className="flex">  
            <UserCell 
                showDropdown={false}
                showJustImage
                row={user}
            />
        </div>
    )
}