import React from 'react';

export const InputLabel = ({label=null, required=false, disabled=false}) => {
    return label && (
        <div className={`label-text flex text-xs font-[500]  ${disabled ? 'opacity-40' : 'text-gray-subText' }`}>
            {label}
            {required  && (
                <>
                    {/* <span className='ml-2'>&#65113;required&#65114;</span> */}
                    <span className="text-red-strong ml-2">*</span>
                </>
                )}
        </div>
    );
};

