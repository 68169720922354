
import usePopup from "@/apis/base/donation/popupStore/usePopup";
import usePopupRequests from "@/apis/base/donation/popupStore/usePopupRequests";
import { BasicBackButton } from "@/components/Buttons/BasicBackButton";
import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { EventCarousel } from "@/components/Carousel/EventCarousel";
import { MainContainer } from "@/components/Containers/MainContainer";
import { ManagePopupDialog } from "@/components/Dialog/ManagePopupDialog";
import { LocationIcon } from "@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons";
import { BasicPulseLoader } from "@/components/Loaders/BasicPulseLoader";
import { BasicTabs } from "@/components/Tabs/BasicTabs";
import { usePopupAdminData } from "@/hooks/state/donation/aggregateHelpers/usePopupAdminData";
import useModPopup from "@/hooks/state/donation/popupStore/useModPopup";
import useModPopupRequest from "@/hooks/state/donation/popupStore/useModPopupRequest";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { getNiceDateString, openDialogWithRef, subObject } from "@/utils";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { Popup } from "@/wrappers/swal/Popup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { useQueryClient } from "@tanstack/react-query";
import React, { useRef } from "react";
import { useTranslations } from "../../../../../../../hooks/translations";
import PickupOverview from "./PickupOverview";
import PickupProductView from "./ProductView";
import PickupRequestView from "./RequestView";

const PickupOrchestration = (props) => {
	const popupDialogRef = useRef()
	const text = useTranslations("inventoryPickup");
	const {history, match} = props;
	const { eventid, popupid } = match.params || {}
	const contextQuery = useCurrentOrgContext()
	const ctx = contextQuery.data
	const client = useQueryClient()
	const popupQuery = usePopup({eventid, popupid});
	const requestsQuery = usePopupRequests({popupid});
	const popupMutation = useModPopup({
		onSuccess: (data) => {
			SuccessToast.fire({text: 'Popup store modified'})
			history.push(`/donation?tab=pickup`)
		},
		onError: (error) => {FailPopup.fire({text: 'Failed to modify popoup store'})}
	})
	const requestMutation = useModPopupRequest({})
	const {lineItems, requests, loading} = usePopupAdminData({popupid}) || {}
	const popup = popupQuery.data || {};
	const popupActive = popup?.active === '1';

	function handleBack() {
		history.goBack();
	}

	function reloadDataFn() {
		client.refetchQueries({queryKey: ['popup', popupid]})
	}

	function openPopupDialog() {
		openDialogWithRef(popupDialogRef)
	}
	
	function openCloseEventModal() {
		Popup.fire({
			title: 'Are you sure?', 
			text: 'Once you close the event, family in need users will no longer have access to the products, and you don\'t have option to re-open the event.',
			confirmButtonText: 'Yes, close the event',
		}).then((result) => {
			if (result.isConfirmed) 
				popupMutation.mutate({...(subObject(popup,['orgid','eventid', 'description', 'starttime','endtime','name',])), popupid: popup.id, active:0},{
			onSuccess: () => console.log('deleted')})
		})
	}	
	const tabs = [text.pickupOverview, text.pickupRequestView, text.pickupProductView];
	return (
		<MainContainer>
		<BasicBackButton onClick={handleBack} className={'absolute'} label={'Back To Events'}/>
		{( popup && lineItems && requests ? (
			<>
				<div className='w-full form-control items-center'>
					<div className="w-full flex lg:flex-row flex-col-reverse mt-10 lg:mt-0 h-fit justify-between mb-10">
						<div className="pt-10 mr-10 flex-5  form-control self-stretch justify-between">
							<div>
								<div className="w-full whitespace-nowrap text-3xl font-semibold">
									{popup.name} 
								</div>
								<EventDates className={'-mt-1'} popup={popup}/>
								<EventLocation location={popup.location}/>
								<EventDescription description={popup.description}/>
							</div>

							{ popupActive && (ctx?.isAdmin || ctx?.isUICContext) && (
								<PopupButtons {...{ openCloseEventModal, openPopupDialog}} />
							)}
						</div>
						<EventCarousel eventid={eventid} numImages={4} />
					</div>
					<BasicTabs autonomous tabNames={tabs}>
						<PickupOverview {...{lineItems, eventid, text, reloadDataFn, loading}}/>
						<PickupRequestView {...{popupName: popup.name, popupActive, lineItems, requests, eventid, popupid, text, reloadDataFn, loading}}/>
						<PickupProductView {...{lineItems, eventid, text, reloadDataFn, loading}}/>
					</BasicTabs>
				</div>
				{ popupActive &&(
					<ManagePopupDialog dialogRef={popupDialogRef} initialValues={popup} {...{eventid, orgid: ctx.orgid}}/>
				)}
			</>
		) : (
			<BasicPulseLoader/>
		)
		)}
			
		</MainContainer>
	)
	}

const EventDates = ({className, popup}) => (
	<div className={`flex gap-4 text-xs text-gray-darkSubText w-full max-w-[15rem] my-2 ${className}`}>
		<p>{getNiceDateString(popup.starttime)}</p>
		<p>–</p>
		<p>{getNiceDateString(popup.endtime)}</p>
	</div>
)

const EventDescription = ({description}) => (
    <div className='flex relative mt-5'>
        <div className="absolute h-full divider divider-horizontal"></div>
        <p className='text-sm ml-10 text-gray-darkSubText font-medium w-full'>{description}</p>
    </div>
)

const EventLocation = ({location}) => (
	<div className="flex items-center gap-2 text-xs text-gray-darkSubText w-full max-w-[15rem] my-4 ">
		<LocationIcon className='w-6 h-6'/>
		<p>{location}</p>
	</div>
)


const PopupButtons = ({openPopupDialog, openCloseEventModal, refreshData}) => (
	<div className="flex gap-2 w-full items-end justify-end">
		<BasicRectButton customSize className='text-sm btn-little whitespace-nowrap  w-[8rem] btn-secondary' onClick={openPopupDialog}>Modify Store</BasicRectButton>
		<BasicRectButton customSize className='text-sm btn-little whitespace-nowrap  w-[8rem] btn-primary' onClick={openCloseEventModal}>Close Store</BasicRectButton>
	</div>
)

export default PickupOrchestration;