import { CardActions, CardContent, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { WhiteCard } from "../../../../../../components/Cards";
import { TextArea, TextInput } from "../../../../../../components/Inputs";
import { useAppContext } from "../../../../../../context/AppContext";
import { getDateString, getMonthDayString } from "../../../../../../utils";

import useSubmitBaseRole from "@/hooks/state/application/useSubmitBaseRole";
import useSubmitRoleApp from "@/hooks/state/application/useSubmitRoleApp";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";

import useCancelRoleApp from "@/hooks/state/application/useCancelRoleApp";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { queryResultFns } from "@/wrappers/swal/queryResultFns";
import { GrayButton } from "@/views/pages/Auth/components/Buttons";
import { BlueOutlineButton } from "@/components/Buttons/BlueOutlineButton";
import { GrayOutlineButton } from "@/components/Buttons/GrayOutlineButton";
import { BasicBlueRectButton } from "@/components/Buttons/BasicBlueRectButton";
import { BasicGrayButton } from "@/components/Buttons/BasicGrayButton";
import { FailPopup } from "@/wrappers/swal/FailPopup";

function AmbassadorApplication({baseAmb, prevAmbApp,  text, org_type, expiry_date}) {
  const { setLoading, setMessage } = useAppContext();
  const [isEditing, setIsEditing] = useState(false);
  const [ambassadorForm, setAmbassadorForm] = useState(null);
  const contextQuery = useCurrentOrgContext({})
  const cancelAppMutation = useCancelRoleApp(queryResultFns('Cancelled Application', 'Failed to cancel application'))
  const roleEnumQuery = useRoleEnum()
  const appStatusQuery = useAppStatusEnum()
  const ambAppMutation = useSubmitRoleApp({
      ...queryResultFns(text.succeedToApplyAmbassador, text.failToApplyAmbassador),
      onSettled: () => setIsEditing(false)
  })
  const baseAmbMutation = useSubmitBaseRole({
    onSuccess: () => ambAppMutation.mutate({roleid: roleEnum.ambassador, orgid: contextQuery.data?.orgid}),
    onError: () => {
      FailPopup.fire({text: text.failedToApplyAmbassador})
      setIsEditing(false)
    }
  })
  const appStatuses = appStatusQuery.data || []
  const appsOpen = contextQuery?.data?.ambactive === "1"
  const appDeclined = prevAmbApp?.status === appStatuses?.declined
  const roleEnum = roleEnumQuery.data

  useEffect(() => {
        setAmbassadorForm({
          is_student: false,
          age_over_18: false,
          comment: '',
          emergency_contact_first_name: '',
          emergency_contact_last_name: '',
          emergency_contact_email: '',
          emergency_contact_phone: '',
          working_days: []
        })}
      ,[])
      
  useEffect(() => {
      // if (baseAmb) {
      //     setAmbassadorForm(prev => ({...prev, ...baseAmb})
      // }
  },[])


  const cancelApplication = () => {
    if (prevAmbApp?.status === appStatuses.pending) 
      cancelAppMutation.mutate({roleid: roleEnum.ambassador, orgid: contextQuery.data?.orgid})
  
  }

  const onSubmit = () => {
    const workingDays = {
      monday: String(ambassadorForm.working_days.includes('Mon')),
      tuesday: String(ambassadorForm.working_days.includes('Tue')),
      wednesday: String(ambassadorForm.working_days.includes('Wed')),
      thursday: String(ambassadorForm.working_days.includes('Thu')),
      friday: String(ambassadorForm.working_days.includes('Fri')),
      saturday: String(ambassadorForm.working_days.includes('Sat')),
      sunday: String(ambassadorForm.working_days.includes('Sun'))
    }
    const params = {
        roleid: roleEnum.ambassador,
        over18: ambassadorForm.age_over_18,
        affiliation: ambassadorForm.is_student ? "Student" : "guardian",
        description: ambassadorForm.comment,
        emergencycontact: {
            firstname: ambassadorForm.emergency_contact_first_name,
                lastname: ambassadorForm.emergency_contact_last_name,
                email: ambassadorForm.emergency_contact_email,
                phonenumber: ambassadorForm.emergency_contact_phone
        },
        availability: workingDays
    }
    baseAmbMutation.mutate(params)
    ambAppMutation.mutate({roleid: roleEnum.ambassador, orgid: contextQuery.data?.orgid})
    setIsEditing(false)
  }

  const handleChange = (name, value) => {
    if (['Mon', 'Tue', 'Wed', 'Thu', 'Fri', "Sat", "Sun"].includes(name)) {
      if (value) {
        setAmbassadorForm({...ambassadorForm, 'working_days': ambassadorForm.working_days.findIndex(e => e === name) === -1 ? [...ambassadorForm.working_days, name] :
              [...ambassadorForm.working_days]});
      } else {
        setAmbassadorForm({...ambassadorForm, 'working_days': ambassadorForm.working_days.findIndex(e => e === name) === -1 ? [...ambassadorForm.working_days] :
              [...ambassadorForm.working_days.slice(0, ambassadorForm.working_days.findIndex(e => e === name)),
                ...ambassadorForm.working_days.slice(ambassadorForm.working_days.findIndex(e => e === name) + 1)]});
      }
    } else {
      setAmbassadorForm({...ambassadorForm, [name]: value});
    }
  }

  return (
      <>
        {ambassadorForm && appStatuses && (
            <div id="become-ambassador" className="mt-5">
              {prevAmbApp?.status === appStatuses.pending ? (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.becomeAmbassador}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.pendingBecomeAmbassador}</p>
                          <div className="xs:flex items-center mt-12.5 my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.status}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="w-fit-content bg-yellow-cornsilk text-yellow-broom rounded-6 text-center py-px px-2.5">{text.pending}</p>
                            </div>
                          </div>
                          <div className="xs:flex items-center my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.submitted}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="font-medium">{getDateString(prevAmbApp?.timestamp)}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                      <BasicGrayButton disabled={prevAmbApp?.status !== appStatuses.pending} onClick={() => cancelApplication()}>{text.cancel}</BasicGrayButton>
                    </CardActions>
                  </WhiteCard>
              ) : prevAmbApp?.status === appStatuses.approved ? (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.becomeAmbassador}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.successedBecomeAmbassador}</p>
                          <div className="xs:flex items-center mt-12.5 my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.status}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="w-fit-content bg-green-honeydew text-green-lime rounded-6 text-center py-px px-2.5">{text.approved}</p>
                            </div>
                          </div>
                          <div className="xs:flex items-center my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.expiryDate}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="font-medium">{getMonthDayString(contextQuery?.data?.roleexpiry)}</p>
                            </div>
                          </div>
                          {/*<div className="xs:flex items-center my-4">*/}
                          {/*  <div className="flex-2">*/}
                          {/*    <p className="font-light">{text.expires}:</p>*/}
                          {/*  </div>*/}
                          {/*  <div className="flex-5">*/}
                          {/*    <p className="font-medium">March 26, 2021</p>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          <div className="flex-3" />
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                      <div className="indicator">
                      <div className="indicator-item"></div>
                        <GrayButton disabled onClick={() => cancelApplication()}>{text.cancel}</GrayButton>

                      </div>
                    </CardActions>
                  </WhiteCard>
              ) : prevAmbApp?.status === appStatuses.declined ? (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.becomeAmbassador}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.declinedAmbassador}</p>
                          <div className="xs:flex items-center mt-12.5 my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.status}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="w-fit-content bg-red-misty-rose text-red-strong rounded-6 text-center py-px px-2.5">{text.declined}</p>
                            </div>
                          </div>
                          <div className="xs:flex items-center my-4">
                            <div className="flex-2">
                              <p className="font-light">{text.declined}:</p>
                            </div>
                            <div className="flex-5">
                              <p className="font-medium">{getDateString(prevAmbApp.modified)}</p>
                            </div>
                          </div>
                          <div className="flex-3" />
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                    <div className="indicator">
                      { !appsOpen || appDeclined && 
                        <div 
                          className={`mb-2 ml-2 indicator-item badge  ${appDeclined ? 'text-red-700 bg-red-200' : 'badge-warning'}`}
                        >
                          { appDeclined
                            ? 'Application Declined'
                            : 'Applications Closed'
                          }
                        </div>}
                        <GrayButton disabled={true} onClick={() => {
                          setAmbassadorForm(prevState => ({ ...prevState, status: null }))
                          setIsEditing(true)
                        }}>{text.openApplication}</GrayButton>
                    </div>
                    </CardActions>
                  </WhiteCard>
              ) : (
                  <WhiteCard>
                    <CardContent>
                      <p className="mb-4 text-2xl font-semibold">{text.becomeAmbassador}</p>
                      <Divider />
                      <div className="xl:flex mt-7.5">
                        <div className="flex-7">
                          <p className="leading-1.75">{text.noBecomeAmbassador}</p>
                          {isEditing && (
                              <>
                                <div className="mt-12.5 my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.isAgeOver18}</p>
                                    </div>
                                    <div className="flex-4">
                                      {ambassadorForm.age_over_18 ? (
                                          <div className="flex">
                                            <div>
                                              <BlueOutlineButton className='!btn-sm' onClick={() => handleChange('age_over_18', true)}>{text.yes}</BlueOutlineButton>
                                            </div>
                                            <div className="ml-2">
                                              <GrayOutlineButton className='!btn-sm' onClick={() => handleChange('age_over_18', false)}>{text.no}</GrayOutlineButton>
                                            </div>
                                            <span className="text-red-strong ml-2">*</span>
                                          </div>
                                      ) : (
                                          <div className="flex">
                                            <div>
                                              <GrayOutlineButton className='!btn-sm' onClick={() => handleChange('age_over_18', true)}>{text.yes}</GrayOutlineButton>
                                            </div>
                                            <div className="ml-2">
                                              <BlueOutlineButton className='!btn-sm' onClick={() => handleChange('age_over_18', false)}>{text.no}</BlueOutlineButton>
                                            </div>
                                            <span className="text-red-strong ml-2">*</span>
                                          </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                
                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.isStudent}</p>
                                    </div>
                                    <div className="flex-4">
                                      {ambassadorForm.is_student ? (
                                        <div className="flex">
                                          <div>
                                            <BlueOutlineButton className='!btn-sm' onClick={() => handleChange('is_student', true)}>{text.yes}</BlueOutlineButton>
                                          </div>
                                          <div className="ml-2">
                                            <GrayOutlineButton className='!btn-sm' onClick={() => handleChange('is_student', false)}>{text.no}</GrayOutlineButton>
                                          </div>
                                          <span className="text-red-strong ml-2">*</span>
                                        </div>
                                      ) : (
                                        <div className="flex">
                                          <div>
                                            <GrayOutlineButton className='!btn-sm' onClick={() => handleChange('is_student', true)}>{text.yes}</GrayOutlineButton>
                                          </div>
                                          <div className="ml-2">
                                            <BlueOutlineButton className='!btn-sm' onClick={() => handleChange('is_student', false)}>{text.no}</BlueOutlineButton>
                                          </div>
                                          <span className="text-red-strong ml-2">*</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <p className="font-light text-gray-500">{text.ambassadorComment}</p>
                                  </div>
                                </div>

                                <div className="my-4">
                                  <div className="xs:flex flex items-center">
                                    <TextArea className="flex-1" onChange={(e) => handleChange('comment', e.target.value)}/>
                                    <span className="text-red-strong ml-2">*</span>
                                  </div>
                                </div>

                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <p className="font-light">{text.workingDays}:</p>
                                  </div>
                                </div>
                                <div className="my-4">
                                  <div className="md:flex items-center">
                                    <div className="flex-3">
                                    </div>
                                    <div className="flex-4">
                                      <div className="overflow-x-auto flex justify-between">
                                        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(e => (
                                          <div className="flex-none" key={`ambassador-working-days-${e}`}>
                                            {ambassadorForm?.working_days?.includes(e) ? (
                                              <BlueOutlineButton className='!btn-sm' onClick={() => handleChange(e, false)}>{e}</BlueOutlineButton>
                                            ) : (
                                              <GrayOutlineButton className='!btn-sm' onClick={() => handleChange(e, true)}>{e}</GrayOutlineButton>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/*<Divider/>*/}
                                <div className="mt-10 my-4">
                                  <p className="text-lg">Emergency Contact</p>
                                </div>
                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      {/*<p className="font-light">{text.emergencyName}:</p>*/}
                                      <p className="font-light">{text.emergencyFirstName}:</p>
                                    </div>
                                    <div className="flex-4 flex">
                                      <TextInput className="flex-1" value={ambassadorForm.emergency_contact_first_name} onChange={(e) => handleChange('emergency_contact_first_name', e.target.value)}/>
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      {/*<p className="font-light">{text.emergencyName}:</p>*/}
                                      <p className="font-light">{text.emergencyLastName}:</p>
                                    </div>
                                    <div className="flex-4 flex">
                                      <TextInput className="flex-1" value={ambassadorForm.emergency_contact_last_name} onChange={(e) => handleChange('emergency_contact_last_name', e.target.value)}/>
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.emergencyEmail}:</p>
                                    </div>
                                    <div className="flex-4 flex">
                                      <TextInput className="flex-1" value={ambassadorForm.emergency_contact_email} onChange={(e) => handleChange('emergency_contact_email', e.target.value)}/>
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="my-4">
                                  <div className="xs:flex items-center">
                                    <div className="flex-3">
                                      <p className="font-light">{text.emergencyPhoneNo}:</p>
                                    </div>
                                    <div className="flex-4 flex">
                                      <TextInput className="flex-1" value={ambassadorForm.emergency_contact_phone} onChange={(e) => handleChange('emergency_contact_phone', e.target.value)}/>
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                  </div>
                                </div>


                                {/*<div className="xs:flex items-center my-4">*/}
                                {/*  <CustomCheckbox*/}
                                {/*    checked={Boolean(ambassadorForm.agree)}*/}
                                {/*    onChange={handleAmbassadorAgreeChecked}*/}
                                {/*  />*/}
                                {/*  <span className="ml-2 text-gray-normal leading-loose">{text.agreeDescription1}</span>*/}
                                {/*</div>*/}
                              </>
                          )}
                        </div>
                        <div className="flex-3" />
                      </div>
                    </CardContent>
                    <CardActions>
                      {isEditing ? (
                          <div className="w-full xl:flex">
                            <div className="flex-7 flex justify-between">
                              <BasicGrayButton onClick={() => {
                                setAmbassadorForm(prevState => ({ ...prevState }))
                                setIsEditing(false)
                                }}>{text.cancel}</BasicGrayButton>
                              <BasicBlueRectButton disabled={!ambassadorForm?.comment?.length || !ambassadorForm?.emergency_contact_first_name?.length || !ambassadorForm?.emergency_contact_last_name?.length
                                  || !ambassadorForm?.emergency_contact_email?.length || !ambassadorForm?.emergency_contact_phone?.length}
                                          onClick={() => onSubmit()}>{text.submit}</BasicBlueRectButton>
                            </div>
                            <div className="flex-3" />
                          </div>
                      ) : (
                          <div className="indicator">
                            { !appsOpen && <div className="mb-2 ml-2 indicator-item badge badge-warning">Applications Closed</div>}
                            <BasicGrayButton disabled={!appsOpen} onClick={() => {setAmbassadorForm(prevState => ({ ...prevState })); setIsEditing(true)}}>{text.openApplication}</BasicGrayButton>
                          </div>
                      )}
                    </CardActions>
                  </WhiteCard>
              )}
            </div>
        )}
      </>
  )
}

export default AmbassadorApplication;
