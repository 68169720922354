import { FailPopup } from "./FailPopup"
import { SuccessToast } from "./SuccessToast"

export function queryResultFns(successText, FailText, successFn, failFn) {
    return ({
        onSuccess: (data, req) => {
            SuccessToast.fire({text: successText})
            successFn && successFn(data, req)
        },
        onError: (error) => {
            FailPopup.fire({text: FailText})
            failFn && failFn(error)
        }
    })
}