import { NumberInput } from "@/components/Input/NumberInput";
import { IndentedCell } from "@/components/Tables/Cells/IndentedCell";
import { WishlistProductCell } from "@/components/Tables/Cells/WishlistProductCell";
import { trimZerosAndDec } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { useRef } from "react";

const columnHelper = createColumnHelper();
const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange


export const orgWishlistColumnHelper = [
    columnHelper.accessor((row) => row?.categoryid, {
        id: "Category ID",
        size: 10,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        filterFn: filterFns.arrIncludesSome,
        meta: {
            hiddenFilter: true,
            hiddenCol: true,
        },
        cell: (info) => <span> {info.getValue()} {console.log(info.getValue())}</span>,
        header: (info) => <span className="text-start">CATEGORY ID</span>,
    }),
    columnHelper.accessor((row) => row.isProduct ? row.targetid : null, {
        id: "Demographic ID",
        size: 10,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        filterFn: filterFns.arrIncludesSome,
        meta: {
            hiddenFilter: true,
            hiddenCol: true,
        },
        cell: (info) => <span> {info.getValue()}</span>,
        header: (info) => <span className="text-start">DEMOGRAPHIC ID</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? null : row?.name, {
        id: "Category Name",
        size: 10,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span className="text-start">CATEGORY NAME</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? row.name : null, {
        id: "Product Name",
        size: 100,
        filterFn: stringFilter,
        cell: ({row, getValue}) => row.original.isProduct && (
            <WishlistProductCell
                images={row.original.images}
                imgID={row.original.images?.[0]?.name}
                name={getValue()}
            />
        ),
        header: (info) => <span>PRODUCT NAME</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? row.targetname : null, {
        id: "Audience Name",
        size: 100,
        filterFn: stringFilter,
        cell: ({row, getValue}) => row.original.isProduct && (
            <span>{getValue()}</span>
        ),
        header: (info) => <span>AUDIENCE NAME</span>,
    }),
    columnHelper.accessor((row) => row?.isProduct ? row.quantities.description  : null, {
        id: "Description",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>DESCRIPTION</span>,
    }),
    columnHelper.accessor((row) => Number(row.quantities.reportQty) || null, {
        id: "Final Request",
        size: 100,
        filterFn: numRangeFilter,
        cell: ({row, table, getValue}) => row?.original.isProduct ? (
            <InlineInput 
                row={row.original}
                meta={table.options.meta}
            />
        ) : <span>{getValue()}</span>,
        header: (info) => <span>FINAL REQUEST</span>,
    }),
    columnHelper.accessor((row) => Number(row.quantities.communityQty), 
        {
            id: "Community Request",
            size: 100,
            filterFn: numRangeFilter,
            cell: (info) => <span>{info.getValue()}</span>,
            header: (info) => <span>COMMUNITY REQUEST</span>,
        }
    ),
]

const InlineInput = ({row, meta}) => {
    const {maxQuantity} = meta || {}
    return (
        <NumberInput 
            name={row.id}
            max={maxQuantity}
            defaultValue={row.quantities?.reportQty}

        />
    )
}

