import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import uicLogo from '/assets/images/u11square.png';

import { TrackedImage } from '@/components/Image/TrackedImage';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { getLogoUrl } from '@/utils';
import { useTranslations } from '../../../../hooks/translations';
import routes from '../../../../routes';

const useStyles = makeStyles((theme) => ({
	list: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	subitem: {
		paddingLeft: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
		},
	},
}));

var getBreakpoint = function () {
	return window.getComputedStyle(document.body, ':before').content;
};

const BrandSideBar = ({ history }) => {
	const classes = useStyles();
	const text = useTranslations('brand');
	const brandRoutes = routes.filter(prop => prop.layout === '/brand');
	const contextQuery = useCurrentOrgContext();
	const orgContext = contextQuery.data;

	const activeRoute = (path) => {
		return history.location.pathname.indexOf(path) > -1 ? true : false;
	};

	const [openCollapses, setOpenCollapses] = useState([brandRoutes.find(prop => prop.collapse && activeRoute(prop.path))?.name]);

	const toggleCollapse = (name) => {
		const index = openCollapses.indexOf(name);
		if (index > -1)
			setOpenCollapses(prevState => ([...prevState.slice(0, index), ...prevState.slice(index + 1)]));
		else
			setOpenCollapses(prevState => ([...prevState, name]));
	};

	return (
		<aside className='drawer-side z-[99] -mt-24 h-[calc(100vh+6rem)]'>
			<label htmlFor="my-drawer" className="drawer-overlay">
			</label>
			<nav className="flex min-h-screen h-full w-72 flex-col gap-2 overflow-y-auto bg-base-100 px-6 py-10">
				<>
				{orgContext ? (
						<Link to="/" >
							<div className='w-full flex place-content-center'>
								<TrackedImage className="h-14" src={getLogoUrl(orgContext?.orgid)}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src=uicLogo;
								}}
								alt="Logo" />
								
							</div>
						</Link>
					) : (
						<Link to="/" >
							<img className="h-14" src={uicLogo} alt="Logo for UIC" />
						</Link>
					)}
				</>
				<ul className="menu gap-3  mt-5 text-xl">
					<li >
						<Link to={'/brand/dashboard'} className='hover:bg-base-100-content hover:active:bg-base-100'>
							<img src="https://unpkg.com/heroicons/20/solid/home.svg" className="h-6 w-6" alt='dashboard'></img>
							Dashboard
						</Link>
					</li>
					<li>
						<Link to={'/brand/users'} className='hover:bg-base-100-content hover:active:bg-base-100'>
							<img  src="https://unpkg.com/heroicons/20/solid/user.svg" className="h-6 w-6" alt='users'></img>
							Members
						</Link>
					</li>
					<li>
						<Link to={'/brand/organizations'} className='hover:bg-base-100-content hover:active:bg-base-100'>
							<svg className="w-6 h-6 text-gray-800 dark:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
								<path d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z"/>
							</svg>
							Organizations
						</Link>
					</li>
					<li >
						<details open >
							<summary className='hover:bg-base-100-content hover:active:bg-base-100'>
								<img
									src="https://unpkg.com/heroicons/20/solid/squares-2x2.svg"
									className="h-6 w-6" alt='products'></img>
								Services
							</summary>
							<ul className='flex mt-3 flex-col gap-3'>
								<li><Link to={'/brand/services/donation'} className='hover:bg-base-100-content hover:active:bg-base-100'>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
										<path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
									</svg>
									Donations
								</Link></li>
								<li><Link to={'/brand/services/product-sale'} className='hover:bg-base-100-content hover:active:bg-base-100'>
									<svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
										<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
									</svg>
								Product Sale
								</Link></li>
								<li><Link to={'/brand/services/recycling'} className='hover:bg-base-100-content hover:active:bg-base-100'>
									<svg className='w-6 h-6' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

													<path fill="none" d="M0 0H24V24H0z"/>
													<path d="M19.562 12.097l1.531 2.653c.967 1.674.393 3.815-1.28 4.781-.533.307-1.136.469-1.75.469H16v2.5L11 19l5-3.5V18h2.062c.263 0 .522-.07.75-.201.718-.414.963-1.332.55-2.049l-1.532-2.653 1.732-1zM7.304 9.134l.53 6.08-2.164-1.25-1.031 1.786c-.132.228-.201.487-.201.75 0 .828.671 1.5 1.5 1.5H9v2H5.938c-1.933 0-3.5-1.567-3.5-3.5 0-.614.162-1.218.469-1.75l1.03-1.787-2.164-1.249 5.53-2.58zm6.446-6.165c.532.307.974.749 1.281 1.281l1.03 1.785 2.166-1.25-.53 6.081-5.532-2.58 2.165-1.25-1.031-1.786c-.132-.228-.321-.417-.549-.549-.717-.414-1.635-.168-2.049.549L9.169 7.903l-1.732-1L8.97 4.25c.966-1.674 3.107-2.248 4.781-1.281z"/>

									</svg>
									Recycling
								</Link></li>
								<li><Link  to={'/brand/services/converter'} className='hover:bg-base-100-content hover:active:bg-base-100'>
										<svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>

								Converter
								</Link></li>
							</ul>
						</details>
					</li>
					
				</ul>
			</nav>
			{/* <!-- /sidebar menu --> */}
		</aside>
		// <div className="w-14 lg:w-50 h-full overflow-hidden shadow-left z-10 absolute">
		//   <List className={classes.list}>
		//     {brandRoutes.map((prop, key) => {
		//       if (prop.collapse) {
		//         return (
		//           <React.Fragment key={key}>
		//             <ListItem button selected={activeRoute(prop.path)} onClick={() => toggleCollapse(prop.name)}>
		//               <ListItemIcon><prop.icon /></ListItemIcon>
		//               <ListItemText primary={text[prop.name]} />
		//               {openCollapses.indexOf(prop.name) > -1 ? <ExpandLess /> : <ExpandMore />}
		//             </ListItem>
		//             <Collapse in={openCollapses.indexOf(prop.name) > -1} timeout="auto" unmountOnExit>
		//               <List>
		//                 {prop.children.map((child, index) => {
		//                   if (Boolean(child.icon)) {
		//                     return (
		//                       <Link to={prop.layout + prop.path + child.path} key={index}>
		//                         <ListItem className={classes.subitem} button selected={activeRoute(prop.path + child.path)}>
		//                           <ListItemIcon><child.icon /></ListItemIcon>
		//                           <ListItemText primary={text[child.name]} />
		//                         </ListItem>
		//                       </Link>
		//                     );
		//                   } else {
		//                     return null;
		//                   }
		//                 })}
		//               </List>
		//             </Collapse>
		//           </React.Fragment>
		//         );
		//       } else {
		//         return (
		//           <Link to={prop.layout + prop.path} key={key}>
		//             <ListItem button selected={activeRoute(prop.path)}>
		//               <ListItemIcon><prop.icon /></ListItemIcon>
		//               <ListItemText primary={text[prop.name]} />
		//             </ListItem>
		//           </Link>
		//         );
		//       }
		//     })}
		//   </List>
		// </div>
	);
};

export default BrandSideBar;
