import React from 'react';
import { BasicTable } from '../BasicTable';
import { PopupInventoryCols } from './PopupInventoryCols';
import { BasicRefreshButton } from '@/components/Buttons/BasicRefreshButton';


export const PopupInventoryTable = ({lineItems, eventid, reloadDataFn, loading, ...props}) => {
    return (    
        <div className=''>
            <BasicTable
                controlsClassName='shadow-showcase'
                horizontalScrolling={false}
                meta={{eventid}}
                data={Object.values(lineItems)}
                columnHelper={PopupInventoryCols} 
            >
                <BasicRefreshButton 
                    onClick={reloadDataFn}
                    loading={loading}
                />        
            </BasicTable>
        </div>
    );
};

function compareRequests(a,b)  {
    return (a?.modified < b?.modified) ? -1 : 1
}