

import { getRequestList } from '@/apis/base/admin/getRequestList';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { requestStatusToLabel } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import useAppStatusEnum from '../enum/useAppStatusEnum';
import useRoleEnum from '../enum/useRoleEnum';
import React from 'react';


export default function useRoleRequests({orgid, roleids, flat=false,...props}) {
    const {select, ...rest} = props || {}
    const roleEnumQuery = useRoleEnum()
    const statusEnumQuery = useAppStatusEnum()
    const roleEnum = roleEnumQuery?.data
    const query = useQuery({
        queryKey: ['role','admin',ROUTE_IDS.GET_ROLE_REQUESTS,roleids,orgid],
        queryFn: () => getRequestList({orgid: orgid, "roleids[]": roleids}),
        staleTime: MILLISEC.MIN_15,
        enabled: orgid != null && roleids != null,
        select: React.useCallback(select ? select : (data) => {
            const flatRoles = []
            if (data?.FIN?.length > 0) {
                data.FIN?.forEach((item) => {
                    item.roleid = roleEnum.fin
                    item.roleLabel = 'Family In Need'
                    item.statusLabel = requestStatusToLabel(item.status, statusEnumQuery.data)

                    if (flat)
                        flatRoles.push(item)
                });
            }
            if (data?.Ambassador?.length > 0) {
                data.Ambassador?.forEach((item) => {
                    item.roleid = roleEnum.ambassador
                    item.roleLabel = 'Ambassador'
                    item.statusLabel = requestStatusToLabel(item.status, statusEnumQuery.data)
                    if (flat)
                        flatRoles.push(item)
                });
            }
            if (data?.['Social Media Influencer']?.length > 0) {
                data['Social Media Influencer']?.forEach((item) => {
                    item.roleid = roleEnum.smi
                    item.roleLabel = 'Social Media Influencer'
                    item.statusLabel = requestStatusToLabel(item.status, statusEnumQuery.data)
                    if (flat)
                        flatRoles.push(item)
                });
            }
                    if (flat)
                        return flatRoles
                    return data;
        },[flat, roleEnum, statusEnumQuery.data]),
        ...props
    });
    return query;
}