//components/ClientSideTable.tsx
import { useReactTable, getCoreRowModel, flexRender, sortingFns, getFilteredRowModel, getSortedRowModel, getPaginationRowModel, getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { compareItems, rankItem } from "@tanstack/match-sorter-utils";
import { donationInventoryCols } from "./donationInventoryCols";
import GalleryOverlay from "@/components/GalleryOverlay";
import { BasicTable } from "@/components/Tables/BasicTable";




const DonationInventoryTable = ({canModify, handleEdit, globalFilter, setGlobalFilter, data, orgID, eventID, ...props}) => {
  const [galleryState, setGalleryState] = useState({
    show: false,
    imageLinks: [],
  })
  const safeData = data?.length > 0 ? data : [];

  const handleImageEvent = (showGallery, productId) => {
    setGalleryState({
      show: showGallery,
      imageLinks: productId,
    })
}

    return (
      <>
        <BasicTable 
          data={safeData} 
          state={{columnVisibility: {action: canModify}}}
          meta={{
            handleImageEvent, 
            handleEdit,
            eventID,
            orgID }} 
          columnHelper={donationInventoryCols} 
          {...props}/>
        { galleryState.show &&
          <GalleryOverlay imageLinks={galleryState?.imageLinks}/>
        }
      </>
    )
}

export { DonationInventoryTable};