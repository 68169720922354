import React from 'react';

export const Delimit = ({children, delimiter, ...props}) => {
    if (!children) return null;
    return (
        <>
            {React.Children.map(children, (child, i) => {
                return (
                <React.Fragment>
                    { i !== 0 && (
                        <>
                            {delimiter}
                        </>
                    )}
                    <React.Fragment>{child}</React.Fragment>
                </React.Fragment>
                );
            })}
        </>
    );
};
