import React, { useEffect, useLayoutEffect, useState } from "react";

import { useTranslations } from "@/hooks/translations";

import { PageTitle } from "@/components/Label/PageTitle";
import { BasicTabs } from "@/components/Tabs/BasicTabs";
import useOrgTypes from "@/hooks/state/enum/useOrgTypes";
import { getCookie, setCookie } from "@/utils";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import TabContent from "./components/TabContent";

function Organization() {
  const text = useTranslations("orgManagement");
  const orgTypesQuery = useOrgTypes()
  const [activeTab, setActiveTab] = useState(0);  
  useEffect(() => {
    if (orgTypesQuery.isError) {    
      FailPopup.fire({text: text.failedLoadOrgTypes})
    }
  }, [orgTypesQuery.status]);
  // Initialize with stored default tab if it exists
  useLayoutEffect(() => {
    const defaultTab = Number(getCookie('defaultOrgTypeTab'));
    if (defaultTab == null) 
      setCookie('defaultOrgTypeTab', 0, '/admin/org')
    else setActiveTab(defaultTab)
  },[])
  
  const handleTabClicked = (index) => {
      setCookie('defaultOrgTypeTab', index, '/admin/org')
      setActiveTab(index)
  };
  return orgTypesQuery.data && (
    <div>
      <PageTitle>{text.title}</PageTitle>
      <BasicTabs 
        activeTab={activeTab} 
        onTabClick={handleTabClicked}  
        // tabNames={['All',orgTypesQuery.data?.hierarchalTypes?.map?.(t=>t.name)]} >
        tabNames={orgTypesQuery.data?.hierarchalTypes?.map?.(t=>t.name)} >
            <TabContent
              text={text}
              type={orgTypesQuery.data?.hierarchalTypes?.[activeTab]} 
            />
      </BasicTabs>
    </div>
  );
}

export default Organization;
