
import { OrgProfileLogo } from '@/components/Image/OrgProfileLogo';
import { TrackedImage } from '@/components/Image/TrackedImage';
import useRoleEnum from '@/hooks/state/enum/useRoleEnum';
import { useDeleteAllCookies } from '@/hooks/state/misc/useDeleteAllCookies';
import { contextQueryComponentConfigs } from '@/hooks/state/organization/useCurrentOrgContext';
import useMyOrgs from '@/hooks/state/organization/useMyOrgs';
import useOrgWithId from '@/hooks/state/organization/useOrg';
import { preloadOrgImages } from '@/utils/imageUtils';
import { fetchQueries } from '@/utils/queryUtils';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useHistory } from 'react-router-dom';
import { deleteAllCookies, getAvatarUrl, getCookie, getUserLabelInOrg, routeFromOrg } from '../../../../utils';

const closeDropdown = () => {
	document.activeElement.blur()
}	

const UserDropDown = ({availability, context}) => {
	const scrollContainerRef = useRef(null)
	const history = useHistory()
	const [cookies, setCookie, deleteCookie] = useCookies(['orgIdContext']);
	const myOrgsQuery = useMyOrgs()
	const orgInfoQuery = useOrgWithId({org_id: myOrgsQuery?.data})
	const client = useQueryClient()
	const roleEnumQuery = useRoleEnum()
	const userid = context?.userid
	const nukeCookies = useDeleteAllCookies()
	
	// setup scroll listener
	useEffect(() => {
		if (myOrgsQuery.data?.length < 4) return
		const scrollContainer = scrollContainerRef.current
		scrollContainer?.classList?.add?.('fade-bottom')
		if (scrollContainer) 
			scrollContainer.addEventListener("scroll", setShadows, {passive: true})
		return () => {
			if (scrollContainer) 
				scrollContainer.removeEventListener("scroll", setShadows)
		}
	},[myOrgsQuery.data, scrollContainerRef.current])
	
	function setShadows(event) {
		window.requestAnimationFrame(() => {
			const { scrollTop, scrollHeight, clientHeight } = event.target || {}
			if (scrollTop > 0) {
				scrollContainerRef.current.classList.add('fade-top')
			} else {
				scrollContainerRef.current.classList.remove('fade-top')
			}
			if (scrollTop < scrollHeight - clientHeight - 10 && orgInfoQuery.data?.length > 4) {
				scrollContainerRef.current.classList.add('fade-bottom')
			} else {
				scrollContainerRef.current.classList.remove('fade-bottom')
			}
		})
	}
	
	function prefetchContext(orgid) {
		return fetchQueries(client, contextQueryComponentConfigs(roleEnumQuery.data, orgid))
	}

	function handleCommunityHover(orgid) {
		// const start = performance.now()
		prefetchContext(orgid).then(()=>{
			// console.log('Time:', (performance.now() - start)/ 1000)
		}) 
	}


	function handleCommunityClick(orgid, orgType) {
		const promises = []
		promises.push(prefetchContext(orgid))
		preloadOrgImages(client, orgid)
		console.log("promises", promises)
		const t = performance.now()
		prefetchContext(orgid).then(()=>{
			setCookie('orgIdContext', orgid, { path: '/' });
			closeDropdown()
			history.replace(routeFromOrg({ 'org type id': orgType, orgid}))
			console.log('in promise', (performance.now() - t) / 1000, 'sec');
			console.log('route from org is ', routeFromOrg({ 'org type id': orgType, orgid}));

			// console.log('prefetch time', (performance.now() - t) / 1000, 'sec');
		})
		// Promise.all(promises).then((e) => {
		// })
	}

	function handleDropdownClick(e) {
		if (document.activeElement === e.target) {
			closeDropdown()
			e.preventDefault()
		} 
	}

	function handleDebug() {
		if (import.meta.env.DEV) {
			client.clear()
			client.getQueryCache().clear()
			client.refetchQueries({predicate: () => true})
		}
	}
	
	return (<>
		<div  className="dropdown-end dropdown z-30">
			<div
				tabIndex="0"
				role='button'
				id='dropdownbutton'
				className="btn btn-circle  btn-ghost"
				onMouseDown={handleDropdownClick}
				>
				<div className="w-10 pointer-events-none aspect-square avatar hidden sm:inline-flex rounded-full ring-accent ring-offset-base-100 ring-[1.5px] ">
					<TrackedImage
						className="w-10 h-10 rounded-half object-cover"
						src={getAvatarUrl(userid)}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src="/assets/images/profile.png";
						}}
						alt="avatar"
					/>
				</div>
				<div className="sm:hidden flex w-10 h-10 place-content-center place-self-center ">
					<svg className='place-self-center' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line>
					</svg>
				</div>
			</div>

			<div id='userdropdown' className='dropdown-content min-w-fit'>
				<ul
					tabIndex="0"
					className="menu mt-3 rounded-box bg-base-100  min-w-[20rem] shadow-2xl  text-[15px] gap-3 py-3 z-[99] ">
					<li className='menu-title py-0 -mb-3 font-bold text-[1.3rem] text-primary'>{context?.orgName}</li>
					<li className='menu-title flex flex-col gap-1 -mb-5' onClick={handleDebug}>
						<span className=' font-bold text-[16px] tracking-wide text-gray-500'>{context?.username}</span>
						<span className='text-gray-500 font-normal tracking-wide'>
							{getUserLabelInOrg({id:context?.userid, email: context?.userid}, context)}
						</span>
					</li>

					<div className='divider after:bg-gray-400 before:bg-gray-400 px-[0.9rem] my-0'></div>

					<li className='sm:hidden'>
						<ul >
								{ availability['news'] && 
									<li>
										<Link to={'/news'} className=' mt-1 hover:bg-accent-content text-blue-button' onClick={closeDropdown}>News</Link>
									</li>
								}
								{ availability['members'] && 
									<li>
										<Link to={'/members'} className=' mt-1 hover:bg-accent-content    text-blue-button' onClick={closeDropdown}>Members</Link>
									</li>
								}
								{ availability['donation'] && 
									<li>
										<Link to={'/donation'} className=' mt-1 hover:bg-accent-content    text-blue-button' onClick={closeDropdown}>Donation</Link>
									</li>
								}
								{ availability['local-communities'] && 
									<li>
										<Link to={'/local-communities'} className=' mt-1 hover:bg-accent-content    text-blue-button'>Local onClick={closeDropdown} Communities</Link>
									</li>
								}
								{ availability['wishlist'] && 
									<li>
										<Link to={'/wishlist'} className=' mt-1 hover:bg-accent-content    text-blue-button' onClick={closeDropdown}>Wishlist</Link>
									</li>
								}
						</ul>
					</li>
					<li>
						<h2 className='menu-title text-primary font-bold text-lg cursor-default -mt-3' >Settings</h2>
						<ul >
							<li>
								<Link to={'/account'} className='  hover:bg-accent-content    text-blue-button' onClick={closeDropdown} >My Account</Link>
							</li>
							<li>
								{
									availability['organization-preferences'] && (
									<Link to={'/organization-preferences'} onClick={closeDropdown} className=' hover:bg-accent-content text-blue-button whitespace-nowrap'>
										Organization Preferences
									</Link>)
								}					
							</li>
						</ul>
					</li>

					<div className='divider after:bg-gray-400 before:bg-gray-400 my-0 divider-neutral px-[0.9rem]'></div>
					<li>
						<h2 className='menu-title text-primary font-bold text-lg cursor-default -mt-3'>
							My Communities
						</h2>
						<ul
							ref={scrollContainerRef}
							id='scrollContainer'
							className='fade  max-h-64  overflow-y-scroll special-scroll pt-4  overflow-clip rounded-md '
						> 
								{ context != null && !getCookie('agnostic') && 
									<li className='indicator w-full flex flex-row flex-nowrap mt-4'>
									<a className=' hover:bg-accent-content w-full h-16 btn flex justify-start gap-4 [--btn-focus-scale:0.99] flex-nowrap ' onClick={closeDropdown} >
										<OrgProfileLogo orgid={context?.orgid}/>
											<div 
												className='w-fit whitespace-pre-wrap font-normal text-base max-w-[15rem] break-words text-start'
											>
												{context?.orgName}
											</div>
											<div 
												className='indicator-item badge bg-blue-cornflower px-1 py-[0.8rem] badge-sm mr-4 text-white'
											>
												Active
											</div>
									</a>
								</li>}
								{
									myOrgsQuery.isSuccess && orgInfoQuery.isSuccess  && orgInfoQuery?.data?.length > 0 && 
									orgInfoQuery.data?.sort(orgCompare).map((org) => 
										org.id === context?.orgid ? (
											<div key={`currentorgplaceholder`}></div>
										) : (
											<CommunityListItem
												onHover={handleCommunityHover} 
												key={`${org?.id}listitem`}
												orgid={org?.id} 
												orgname={org?.name}
												history={history}
												onClick={() => handleCommunityClick(org?.id, org?.['org type id'])}
												setCookie={
													(cName,value) => 
														setCookie(cName,value,{path: '/'})
												}
											/>
										)
									) 
								}
					</ul>
				</li>
				<li className='w-fit-content place-self-end h-fit-content  text-blue-button '>
					<button className='btn btn-sm btn-ghost line-clamp-1 '
						onClick={() => {
							// console.log('logging out')
							deleteAllCookies()
							nukeCookies()
							client.getQueryCache().clear()
							client.clear()
							client.resetQueries()
							closeDropdown()
							history.replace('/')
						}}
					>Sign out</button>
				</li>
			</ul>
		</div>
	</div>
	</>
	);
};

const CommunityListItem = ({orgid, orgType,  orgname, onHover, onClick }) => {	
	
	return (
		<li onMouseEnter={() => onHover(orgid)} key={orgid + 'list item'} className=' w-full flex flex-row flex-nowrap mt-3'>
			<a key={orgid + 'link'} onClick={() => onClick(orgid, orgType)} className='hover:bg-accent-content  w-full  h-16 btn btn-ghost flex [--btn-focus-scale:0.99]  justify-start gap-4 flex-nowrap'>
				<OrgProfileLogo orgid={orgid}/>
				<div key={orgid + 'namelabel'} className='w-fit whitespace-pre-wrap font-normal text-base max-w-[15rem] break-words textstart text-start'>
					{orgname}
				</div>
			</a>
		</li>
	)
}

function orgCompare(a, b) {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
}

export { UserDropDown };
