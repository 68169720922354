
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getOrgVIPs } from '@/apis/base/organizations/getOrgVIPs';

export default function useOrgVIPs({orgid}) {
    const query = useQuery({
        queryKey: ['org',orgid,ROUTE_IDS.GET_ORG_VIPS],
        enabled: !!orgid,
        queryFn: (params) => getOrgVIPs({orgid}),
        notifyOnChangeProps: ['data'],
        staleTime: MILLISEC.MIN_15,
    });
    return query;
}