
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { submitBaseRole } from '@/apis/base/applications/submitBaseRole';
import { useMutation } from '@tanstack/react-query';


export default function useSubmitBaseRole({onSuccess, onError}) {
    const query = useMutation({
        mutationKey: [ROUTE_IDS.SUBMIT_BASE_ROLE],
        mutationFn: submitBaseRole,
        staleTime: MILLISEC.MIN_15,
        onSuccess,
        onError,
    });
    return query;
}