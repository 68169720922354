import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { StyledDialog } from "../Dialogs";
import { TextArea } from "../Inputs";
import { BasicBlueRectButton } from "@/components/Buttons/BasicBlueRectButton";
import { GrayButton } from "@/views/pages/Auth/components/Buttons";

const ApplicationManagementDialog = ({text, open, approve, comment, onClosedBtnClicked, onConfirmBtnClicked, onCommentChanged}) => {
  return (
    <StyledDialog open={open}
                  onClose={onClosedBtnClicked}
    >
      <DialogTitle>{text.manageApplication}</DialogTitle>
      <DialogContent>
        <div className="items-center my-3">
          <div>
            <p className="font-light">{text.comment}</p>
          </div>
          <div className="mt-2">
            <TextArea className="px-2 py-2" onChange={(e) => onCommentChanged(e.target.value)} value={comment}/>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <BasicBlueRectButton onClick={onConfirmBtnClicked}>{approve ? text.approve : text.decline}</BasicBlueRectButton>
        <GrayButton onClick={onClosedBtnClicked}>{text.close}</GrayButton>
      </DialogActions>
    </StyledDialog>
  )
};

export default ApplicationManagementDialog;