import { BrandPrefTurnoutChart } from '@/components/Charts/PieCharts/BrandPrefTurnoutChart';
import { BasicBoxTabs } from '@/components/Input/BasicBoxTabs';
import { BasicStartEndDate } from '@/components/Input/BasicStartEndDate';
import { BrandPrefRegionalReportTable } from '@/components/Tables/BrandPrefRegionalReportTable/BrandPrefRegionalReportTable';
import { BrandPrefReportTable } from '@/components/Tables/BrandPrefReportTable/BrandPrefReportTable';
import useBrandPrefRegionalReport from '@/hooks/state/admin/uic/useBrandPrefRegionalReport';
import useAllUsers from '@/hooks/state/admin/useAllUsers';
import useAllBrands from '@/hooks/state/brands/useAllBrands';
import { arrayToObject, between, roundTo } from '@/utils';
import React, { useEffect, useState } from 'react';
import { TopPlacements } from '../../../Reports/components/TopPlacements';

const tabNames=['Regional', 'Overview']
const TABS = {
    REGIONAL: 0,
    OVERVIEW: 1,
}


export const BrandPrefRegionalReport = ({...props}) => {
    const [timeRange, setTimeRange] = useState({start: '', end: ''})
    const [activeTab, setActiveTab] = useState(TABS.OVERVIEW)
    const [voteData, setVoteData] = useState({
        possibleVotes: 0,
        timelyPrefs: 0,
        outdatedPrefs: 0,
        unsubmittedPrefs: 0,
        topFive: [],
        timelyTopFive:[],
        prefs: {},
    })
    const prefReportQuery = useBrandPrefRegionalReport()
    const userDataQuery = useAllUsers()
    const brandPrefsQuery = useAllBrands()
    const useTimespan = !!timeRange.start && !!timeRange.end
    // const geoQuery = useGeoData({
    //     select: (data) => {
    //         return arrayToObject(data?.[0]?.subnodes, 'id')
    //     }
    // })

    useEffect(() => {
        calculateBrandPrefs()
    },[userDataQuery.data, brandPrefsQuery.data, timeRange])

    function calculateBrandPrefs() {
        if (!userDataQuery.data || !brandPrefsQuery.data) return
        for (const brand of brandPrefsQuery.data) {
            brand.votes = 0
            brand.votesTimespan = 0
            brand.regionObj = {}
        }
        const brandPrefObj = arrayToObject(brandPrefsQuery.data, 'id')
        const members = userDataQuery.data
        let timelyPrefs = 0
        let unsubmittedPrefs = 0
        let outdatedPrefs = 0
        let top5 = []
        let timelyTop5 = []

        // Get vote data from users
        for (const member of members) {
            if (member?.brandsmodified === null) console.log(member.name)
            const mRegion = member?.['region id']
            if (member.brands?.length === 0) {
                unsubmittedPrefs++   
                continue
            }
            if (useTimespan) {
                if (between(Number(member?.brandsmodified), timeRange.start, timeRange.end)) {
                    timelyPrefs++
                    for (const b of member.brands) {
                        if (!brandPrefObj[b]?.votes) 
                        brandPrefObj[b].votes = 0
                        brandPrefObj[b].votesTimespan++

                        if (!mRegion) continue
                        if (!brandPrefObj[b].regionObj[mRegion]) 
                            brandPrefObj[b].regionObj[mRegion] = {timelyPrefs: 0, votes: 0}
                        brandPrefObj[b].regionObj[mRegion].timelyPrefs++
                    }
                } else {
                    outdatedPrefs++
                }
            } 
            for (const b of member.brands) {
                if (!brandPrefObj[b]?.votes) 
                    brandPrefObj[b].votes = 0
                brandPrefObj[b].votes++

                if (!mRegion) continue
                if (!brandPrefObj[b].regionObj[mRegion]) 
                    brandPrefObj[b].regionObj[mRegion] = {timelyPrefs: 0, votes: 0}
                brandPrefObj[b].regionObj[mRegion].votes++
            }
        }
        const sorted =  
            Object
                .values(brandPrefObj)
                .sort(comparePrefs)
        top5 = sorted.slice(0,5)
        sorted
            .forEach((pref, idx) => {
                pref.rank = idx + 1 
                pref.percentTotal = 
                    roundTo(pref.votes / (members.length - unsubmittedPrefs)  * 100)
        })
        if (useTimespan) {
            const timelySorted =  
                Object
                    .values(brandPrefObj)
                    .sort(compareTimespanPrefs)
            timelyTop5 = timelySorted.slice(0,5)
            timelySorted.forEach((pref, idx) => {
                pref.timespanRank = idx + 1
                pref.percentTimespan = 
                    timelyPrefs > 0 
                        ? roundTo(pref.votesTimespan / timelyPrefs * 100)
                        : 0
            })
        }

        setVoteData({
            possibleVotes: members?.length,
            timelyPrefs,
            outdatedPrefs,
            unsubmittedPrefs,
            topFive: top5,
            timelyTopFive: timelyTop5,
            prefs: brandPrefObj
        })

    }

    function onChangeTab(tab) {
        setActiveTab(tab)
    }

    function onSetTimeRange(val) {
        if (!val) return
        const start = new Date(val.start)?.getTime() / 1000
        const end = new Date(val.end)?.getTime() / 1000
        setTimeRange({start, end})
    }
    
    function comparePrefs(a, b) {
        if (a.votes < b.votes) return 1
        if (a.votes > b.votes) return -1
        return 0
    }

    function compareTimespanPrefs(a, b) {
        if (a.votesTimespan < b.votesTimespan) return 1
        if (a.votesTimespan > b.votesTimespan) return -1
        return 0
    }

    return (
        <div>
            <div className="flex gap-10 h-50 mb-10">
                <div className="form-control flex-1 justify-evenly bg-base-100 p-4 rounded-xl gap-4">
                    <BasicBoxTabs
                        className=' tabs-lg '
                        tabNames={tabNames} 
                        activeTab={activeTab}
                        onChange={onChangeTab}
                    />

                    <BasicStartEndDate
                        className={' w-full'}
                        onChange={onSetTimeRange} 
                        inputClassName={'w-full text-sm h-[45px] bg-base-150'}
                    />
                    
                </div>
                <div className="flex-2 bg-base-100 p-4 rounded-xl h-full flex justify-around gap-20">
                    <div className=' w-72 h-full'>
                        <BrandPrefTurnoutChart
                            showTimely={useTimespan}
                            timely={voteData.timelyPrefs}
                            untimely={voteData.outdatedPrefs}
                            total={voteData.possibleVotes}
                            unsubmitted={voteData.unsubmittedPrefs}
                        />
                    </div>
                    <div className="group ">
                        <div className={`swap hover:cursor-default ${useTimespan ? ' group-hover:swap-active' : ''}`}>
                            <TopPlacements className='swap-off' title={'Top Voted Brands'} data={voteData.topFive}/>
                            <TopPlacements timely className='swap-on' title={'Top Voted Brands (in time range)'} data={voteData.timelyTopFive}/>
                        </div>
                    </div>
                    
                </div>

            </div>

            {   activeTab === TABS.REGIONAL && (
                <BrandPrefRegionalReportTable 
                    data={Object.values(voteData.prefs)} 
                    useTimespan={useTimespan}    
                />
            )}
            {  activeTab === TABS.OVERVIEW && (
                <BrandPrefReportTable 
                    data={Object.values(voteData.prefs)}
                    numBrands={brandPrefsQuery.data?.length || 0}
                    useTimespan={useTimespan}
                />
            )}
        </div>
    )
};

