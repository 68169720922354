import React from 'react';
import { XIcon } from '../Icons';


export const RemovableTag = ({label, onDelete, disabled, ...props}) => {
    return (
        <div className={`rounded-full w-fit-content h-8 flex flex-row gap-3 px-3 py-2 border-gray-border border place-items-center font-normal text-xs 
        ${disabled ? 'opacity-40' : 'hover:cursor-pointer'}`}
        >
            <span className='whitespace-nowrap'>{label}</span>
            <button disabled={disabled} onClick={disabled ? null : onDelete} >
                <XIcon className='text-md'/>
            </button>
        </div>
    );
};

