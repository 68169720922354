import { EditButton } from "@/components/Buttons";
import { createColumnHelper, filterFns } from "@tanstack/react-table";


const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const EligibleLocationsColumnHelper = [
columnHelper.accessor((row) => row?.name, {
    id: "Region",
    size: 100,
    enableColumnFilter: false,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>Region</span>,
}),
columnHelper.display({
    size: 10,
    id: "Edit",
    cell: (info) => (
    <EditButton className="cursor-pointer" 
        buttonProps={{
            onClick:() => (info.table.options.meta.handleEdit(info.row.original))
        }} 
    />
  ),
}),
columnHelper.accessor((row) => row?.cities, {
    id: "Cities",
    size: 600,
    enableColumnFilter: false,
    cell: (info) => <Cities cities={info.getValue()} row={info.table.options.meta?.allCities} />,
    header: (info) => <span>Eligible Cities</span>,
  }),
]
const Cities = ({cities, row}) => {
    const cityNames = cities?.map?.(c => c.name)|| []
    return (
    <div className="flex flex-row">
        {/* {console.log(row)} */}
        {/* cities.map((item, index) => (
        <div key={index} className="mr-2">{cityN}</div>)
        ) */}
        {   cityNames?.length > 0 &&
            cityNames.join(', ')
        }
    </div>
)}


