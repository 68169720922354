import { InventoryDropdown } from "@/components/dropdowns/InventoryDropdown";
import { createColumnHelper, filterFns } from "@tanstack/react-table";


const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const PopupInventoryCols = [
columnHelper.accessor((row, idx) => idx, {
    id: "No",
    size: 10,
    enableColumnFilter: false,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>ID</span>,
  }),
  columnHelper.display({
      id: "Image",
      enableColumnFilter: false,
      size: 100,
      cell: ({row, table}) => 
        <InventoryDropdown 
            eventid={table.options.meta.eventid} 
            inventoryid={row.original.inventoryid} />,
    }),
columnHelper.accessor((row) => row.productname, {
    id: "Product Name",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>PRODUCT NAME</span>,
  }),
columnHelper.display({
    id: "Adjustments",
    size: 100,
    enableColumnFilter: false,
    cell: ({row}) => <ItemAdjustments row={row} itemAdjustments={row.original.itemAdjustments}/>,
    header: (info) => <span>ADJUSTMENT</span>,
  }),
columnHelper.display({
    id: "Adjusters",
    size: 100,
    enableColumnFilter: false,
    cell: ({row}) => <ItemAdjusters itemAdjustments={row.original.itemAdjustments}/>,
    header: (info) => <span>DESCRIPTION</span>,
  }),
]



const ItemAdjustments = ({row, itemAdjustments}) => (
    <div className="form-control gap-2 w-fit items-end">
        {   itemAdjustments.map(({adjustment,label}, idx) => (
            <p className={`${adjustment?.[0] === '+' ? 'text-success-content' : 'text-red-600'} `} key={label}>{adjustment}</p>
        ))}
        <p className="text-blue-cornflower font-semibold under">{row?.original?.remaining || '–'}</p>
    </div>
)

const ItemAdjusters = ({itemAdjustments}) => (
    <div className="form-control gap-2">
        {   itemAdjustments.map(({adjustment, label}, idx) => (
            <p className={`${adjustment?.[0] === '+' ? 'text-success-content' : 'text-red-600'}`} key={label}>{label}</p>
        ))}
        <p className="text-blue-cornflower font-semibold under">Remaining</p>
    </div>
)