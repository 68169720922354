import React, { useEffect } from 'react';


export const useMinTimeTrue = (setBoolean=() => {}, boolean, delay=500) => {
    useEffect(() => {
        if (boolean) setBoolean(boolean)
        else {
            const timeOutID = setTimeout(() => setBoolean(boolean), delay);
            return () => clearTimeout(timeOutID);
        } 
    },[boolean])
};

