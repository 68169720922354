export const appName = 'United Hearts for Canada';
export const contactEmail = 'seamus@unitedinchange.com';
export const contactAddress =
  '555 Richmond st west, Toronto , ON, M4X 1L6, Canada';
export const referCookieFrom = 'http://www.allaboutcookies.org';
export const referShopifyPrivacyFrom = 'https://www.shopify.com/legal/privacy';
export const referGooglePrivacyFrom =
  'https://www.google.com/intl/en/policies/privacy';
export const referGoogleAnalyticsOptOutFrom =
  'https://tools.google.com/dlpage/gaoptout';
export const referHowTargetedAdvertisingWorksFrom =
  'http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work';
export const referDigitalAdvertisingOptOutFrom = 'http://optout.aboutads.info';
export const scpIDListDocFilename = 'SCP_ID_List.docx(xlsx)';
export const userTypes = {
  uicAdmin: 'uic_admin',
  masterAdmin: 'master_admin',
  admin: 'admin',
  member: 'member',
  guest: 'guest',
  alumni: 'alumni',
};
export const roles = {
  ambassador: 'ambassador',
  familyInNeed: 'family_in_need',
  socialMediaInfluencer: 'social_media_influencer',
  guardian: 'guardian',
};
export const shipmentStatus = {
  'in-request': 'in-request',
  'in-delivery': 'in-delivery',
  delivered: 'delivered',
};
export const registerStatus = {
  emailVerified: 2010400,
  registerSuccess: 20105000,
}
export const ApplicationType = {
  FAMILY_IN_NEED: 'FAMILY IN NEED',
  AMBASSADOR: 'AMBASSADOR',
  SOCIAL_MEDIA_INFLUENCER: 'SOCIAL MEDIA INFLUENCER',
  GUARDIAN: 'GUARDIAN',
};

export const InventoryRequestStatus = {
  IN_REQUEST: 'in-request',
  IN_DELIVERY: 'in-delivery',
  DELIVERED: 'delivered',
};

export const subCategories = [
  'Shirts Dress & Casual',
  'Sweatshirts & hoodies',
  'Polos & Tees',
  'Denim',
  'Pants',
  'Suits & Jackets',
  'Outwear',
  'Activewear',
  'Shorts & Swimwear',
  'Socks, Underwear and Pyjamas',
  'Skirts & Dresses',
];

export const ROLE_LABEL = {
  FIN: 'Family in Need',
  AMBASSADOR: 'Ambassador',
  SMI: 'Social Media Influencer',
  GUARDIAN: 'Guardian',
}

export const APP_STATUS_LABEL = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
}

export const brandDashboard = [
  'Communities',
  'Members',
  'Events Live',
  'Events All Time',
  'Landfill Avoidance',
  'Donation $ Value',
  'Donation Customers',
  'E-Commerce Sales',
  'E-Commerce Customers',
];

export const PERMISSION = {
  UIC_ADMIN: '10',
  MASTER_ADMIN: '9',
  ADMIN: '8',
  MEMBER: '1',
  GUEST: '0',
  ALUMNI: '0',
};

export const TIME_UNIT = {
  SEC: 'sec',
  MIN: 'min',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
}

export const MILLISEC = {
  SEC: 1000,
  SEC_30: 30000,
  MIN: 60000,
  MIN_5: 300000,
  MIN_10: 600000,
  MIN_14: 840000,
  MIN_15: 900000,
  MIN_30: 1800000,
  HOUR: 3600000,
  DAY: 86400000,
  WEEK: 604800000,
  MONTH: 2592000000,
  YEAR: 31536000000,
};

export const ORG_TYPES = {
  UNIVERSITY: 'University',
  SCHOOL_BOARD: 'School Board',
  CHARITY: 'Charity',
  FIRST_NATIONS: 'First Nations',
  BRANDS: 'Brand',
  BUSINESS: 'Business',
  CORPORATION: 'Corporation',
  OTHER: 'Other',
};

export const INVITE_TYPE = {
  MEMBER: 0,
  ORGANIZATION:1,
  LINK: 2
}

export const BD_MODE = {
  PIPELINE: 0,
  IN_PROCESS: 1,
  LIVE: 2,
  HISTORICAL: 3,
}

export const UIC_ORG = "6002"

export const UPLOAD_TYPE = {
  ORG_BANNER: 'banner',
  ORG_LOGO: 'logo',
  ORG_SPLASH: 'splash',
  AVATAR: 'avatar',
  PRODUCT: 'product',
  EVENT: 'event',
  EVENT_INVENTORY: 'eventInventory',
}

export const BD_ROLES = {
  BRAND: 0,
  PROCESSOR: 1,
  UIC: 2,
}

export const BREAKPOINTS = {
  xs: '(min-width: 480px)',
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)',
  '3xl': '(min-width: 1920px)',
}

export const MIN_TIMESTAMP = 1672645438 // Janrurary 18 2023
export const MAX_TIMESTAMP = 1893570238 // January 19 2038

export const MIN_DATE = '2023-12-31'
export const MAX_DATE = '2038-12-31'

export const DIRECTION = {
  LEFT: 0,
  RIGHT: 1,
  UP: 2,
  DOWN: 3,
}

export const PRODUCT_FILTER = {
  gender: 0,
  category: 1,
}

export const WISHLIST_VIEW_TYPE = {
  UIC: 1,
  ORG_ADMIN: 2,
  MEMBER: 3,
  REPORT: 4,
  REPORT_AND_DEMAND: 5,
}

export const GEO_NODE_TYPE = {
  COUNTRY: "1",
  REGION: "2",
  CITY: "3",
}

export const SEE_CATEGORY = {
  SOCIAL: "social",
  ENVIRONMENTAL: "environmental",
  ECONOMIC: "economic",
}

export const OBJ_TYPES = {
    user: "user",
    site: "site",
    admin: "admin",
    email: "email",
    role: "role",
    organization: "organization",
    community: "community",
    member: "member",
    brand: "brand",
    processor: "processor",
    charity: "charity",
    corporation: "corporation",
    product: "product",
    wishlist: "wishlist",
    event: "event",
    donation: "donation",
    request: "request",
    shipment: "shipment",
    popup: "popup",
    codes: "codes",
    fin: "fin",
    ambassador: "ambassador",
    smi: "smi",
    guardian: "guardian",
    image: "image",
    tag: "tag",
    geo: "geo",
    sponsor: "sponso8"
}


export const IMAGE_TYPE = {
  ORG_BANNER: 'banner',
  ORG_LOGO: 'logo',
  ORG_SPLASH: 'splash',
  AVATAR: 'avatar',
  PRODUCT: 'product',
  EVENT: 'event',
  EVENT_INVENTORY: 'eventInventory'  
}