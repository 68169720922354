import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { modBDInventory } from '@/apis/base/donation/modBDInventory';

export default function useModBDInventory({eventid, ...props}) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_BD_INVENTORY],
        mutationFn: modBDInventory,
        staleTime: MILLISEC.MIN_15,
        onMutate: async (data) => {
            if (!eventid) throw new Error('eventid is required to modify inventory')
        },
        onSuccess: (data, req) => {
            if (data?.status === 'inventory not modified') 
                throw new Error('Failed to modify inventory');
            client.invalidateQueries({queryKey: ['bd']})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}