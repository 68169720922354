import { useEffect, useLayoutEffect, useRef, useState } from "react";

export const SlideModal = ({show, setShow, topClass='top-[50%]', className, children}) => {
    const modalRef = useRef(null)
    const [hidden, setHidden] = useState(true)
    const handleAnimationEnd = () => {
        if (!show)
            setHidden(true)
    }

    useEffect(() => {
        if (show) {
            setHidden(false)
            document.documentElement.style.overflow = 'hidden'
        }  else 
            document.documentElement.style.overflow = 'unset'
    },[show])

    useEffect(() => {
        if (hidden)
            window.removeEventListener('resize', handleResize)
        else 
            window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [hidden])

    useLayoutEffect(() => {
        if (!hidden)
            resizeToParent()
    }, [hidden])
    
    
    const handleResize = () => {
        resizeToParent()
    }

    const resizeToParent = () => {
        if (modalRef.current === null) return 
        modalRef.current.style.width = modalRef.current?.parentElement?.parentElement.offsetWidth + 'px'
    }


    return !hidden && (
        <div  className="relative indicator w-full">  
            { show && (
                <button className="fixed left-0 top-0 modal-backdrop w-screen h-screen z-[1000] bg-gray-modal-backdrop" onClick={(e) =>  setShow(false)}/>
            )}
            { !hidden && (
                <div  
                    ref={modalRef}
                    className={`
                        ${show ? 'slide-top-show' : 'slide-top-hide'}
                        fixed z-[1005] ${topClass} 
                        h-full rounded-xl bg-base-100 shadow-card 
                        ${className}    
                    `} 
                    onAnimationEnd={handleAnimationEnd}> 
                    <div className="relative w-full h-full pt-5">
                        {children}
                    </div>
                    <div className="indicator-item indicator-end ">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => setShow(false)}>
                            ✕
                        </button>
                    </div>
                </div>

            )}    
        </div>
    )
}