import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';


export const useArbitraryQuery = (key, disable=false) => {
    // console.log(key, disable);
    if (!disable && key?.length > 500) 
        throw new Error(`tracked image received a way too long key ${key}`);
    const mockQuery = useQuery({
        queryKey: ['mock', key],
        enabled: 
            !disable &&
            key &&
            (typeof key === 'string') 
                ? key?.length < 500 
                : false,
        queryFn,
        gcTime: 0,
        initialData: Date.now(),
        retry: false,
        staleTime: MILLISEC.DAY,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
    })
    return mockQuery;
};


function queryFn() {
    return new Promise((resolve,reject) => {
        resolve(Date.now());
    });
}