
import { CardActions, CardContent, Divider, Select } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { withCookies } from 'react-cookie';
import { WhiteCard } from '@/components/Cards';
import { SelectInput, TextInput } from '@/components/Inputs';
import { SectionTabs } from '@/components/Tabs';

import {
    getAgnosticUserType,
    getAvatarUrl,
    getDateString
} from '@/utils';
import {
    FacebookIcon,
    InstagramIcon,
    LockIcon,
    TwitterIcon,
} from '../../components/Icons';

import { uploadAvatar } from '@/apis/base/lambda/uploadImages';
import useChangePassword from '@/hooks/state/auth/useChangePassword';
import { SyncAlt } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { ConfirmDialog } from '../../../../../../components/Dialogs';

import { TrackedImage } from '@/components/Image/TrackedImage';
import useAppStatusEnum from '@/hooks/state/enum/useAppStatusEnum';
import useRoleEnum from '@/hooks/state/enum/useRoleEnum';
import useModOrgData from '@/hooks/state/organization/useModOrgData';
import useMyUserData from '@/hooks/state/useMyUserData';
import useModMyUserData from '@/hooks/state/user/useModMyUserData';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useQueryClient } from '@tanstack/react-query';
import { BasicGrayButton } from '@/components/Buttons/BasicGrayButton';
import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';
import { BlueOutlineButton } from '@/components/Buttons/BlueOutlineButton';
import { GrayOutlineButton } from '@/components/Buttons/GrayOutlineButton';
import { BasicRedRectButton } from '@/components/Buttons/BasicRedRectButton';
import useMySecondaryEmails from '@/hooks/state/user/useMySecondaryEmails';
import useAddSecondaryEmail from '@/hooks/state/user/useAddSecondaryEmail';
import { EmailManagement } from '@/views/pages/Main/Account/components/pages/components/EmailManagement';

const EDIT = {
  PERSONAL: 0,
  CONTACT: 1,
  EMERGCONTACT: 1,
  DEPENDENTS: 1,
}


function General({ text, history, cookies }) {
  const [avatarRefreshToggle,setAvatarRefreshToggle] = useState(false)
  const [
    allowedSCPService,
    // setAllowedSCPService,
  ] = useState(false);
  const [isOpenPhoneEdit, setIsOpenPhoneEdit] = useState(false);
  const [changePwdError, setChangePwdError] = useState('');
  const [newEmailError, setNewEmailError] = useState('');
  const userQuery = useMyUserData()
  const secondaryEmailsQuery = useMySecondaryEmails()
  const addSecondaryEmailMutation = useAddSecondaryEmail({myUserID: userQuery.data?.id})
  const roleEnumQuery = useRoleEnum()
  const appStatusQuery = useAppStatusEnum()
  const {data: userData} = userQuery || null
  const client = useQueryClient()
  console.log(secondaryEmailsQuery.data);
  const personalInfoMutation = useModMyUserData({
    onSuccess: () => {
      SuccessToast.fire({
        icon: 'success',
        title: text.success,
        text: text.succeedToUpdatePersonalInfo,
        position: 'top-end',
        timer: 1500,
      })
  },
    onError: (err) => {
      FailPopup.fire({
        text: text.failedUpdatePersonalInfo,
      });
      // setChangePwdError(err.response.data?.detail);
    },
  })
  const orgMutation = useModOrgData({
    onSuccess: () => {
      SuccessToast.fire({
        text: 'Successfully updated organization info',
      })
  
    }, 
    onError: () => {
      FailPopup.fire({
        text: 'Failed to update organization info',
      });
      
      // setChangePwdError(err.response.data?.detail);
    
    }, 
  })
  const resetPasswordQuery = useChangePassword({
    onSuccess: () => {
      SuccessToast.fire({text: text.successChangePassword})
    },
    onError: (err) => {
      FailPopup.fire({text: text.failedChangePassword})
      // setChangePwdError(err.response.data?.detail);
    },
  });

  // const geoQuery = useGeoNode({
  //   nodeIds: [userData?.['country id'], userData?.['region id'], userData?.['city id']]
  // })
  // const city = geoQuery.data?.length > 0 ? 
  //   geoQuery?.data.find(node => node.id === userData?.['city id'])
  //   : {}
  // const region = geoQuery.data?
  //   geoQuery?.data.find(node => node.id === userData?.['region id'])
  //   : {}
  // const {CitySelect, RegionSelect, CountrySelect} = useRegionCitySelect({
  //   onCityChange: (key) => {console.log('key,label', key); handleChange('organization','cityid', key)},
  //   onRegionChange: (key) => {
  //     handleChange('organization','regionid', key)
  //     handleChange('organization','cityid', null)
  //   },
  //   initialRegionID: region?.id,
  //   initialRegionLabel: region?.name,
  //   initialCityID: city?.id,
  //   initialCityLabel: city?.name,
  //   fixed:false,
  // })

  const [campusList] = useState([
    {
      id: 1,
      name: 'St. George',
    },
    {
      id: 2,
      name: 'Scarborough',
    },
    {
      id: 3,
      name: 'Mississauga',
    },
  ]);
  const [buildingList] = useState([
    {
      id: 1,
      name: 'Chestnut residence',
    },
    {
      id: 2,
      name: 'Innis college',
    },
    {
      id: 3,
      name: 'Loretto college',
    },
  ]);

  const [editGeneralInfo, setEditGeneralInfo] = useState({
    personal: {
      avatar: getAvatarUrl(userData?.id),
      firstname: userData.firstname,
      lastname: userData.lastname,
      email: userData?.email,
      phonenumber: userData?.['phone number'],
      occupation: userData?.occupation,
      is_active: userData?.status === "1",
      created_at: userData?.userTimestamp,
      userType: getAgnosticUserType(userData),
      roleexpiry: userData?.roleexpiry,
    },
    password: {
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    services: {
      scp: false,
      // bd: false,
    },
    needs: {
      social: 0,
      family: 0,
      dependents: '',
    },
    location: {
      residence: 0,
      campus: 1,
      building: 1,
      room: '203',
    },
    social: {
      facebook: false,
      instagram: false,
      twitter: false,
    },
  });
  useEffect(() => {
    setEditGeneralInfo(
      {
      personal: {
      avatar: getAvatarUrl(userData?.id),
      firstname: userData.firstname,
      lastname: userData.lastname,
      email: userData?.email,
      phonenumber: userData?.['phone number'],
      occupation: userData?.occupation,
      is_active: userData?.status === "1",
      created_at: userData?.userTimestamp,
      userType: getAgnosticUserType(userData),
      roleexpiry: userData?.roleexpiry,
    },
    password: {
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    services: {
      scp: false,
      // bd: false,
    },
    needs: {
      social: 0,
      family: 0,
      dependents: '',
    },
    location: {
      residence: 0,
      campus: 1,
      building: 1,
      room: '203',
    },
    social: {
      facebook: false,
      instagram: false,
      twitter: false,
    }
  },)
  },[userQuery.data])

  const [generalEditable, setGeneralEditable] = useState({
    personal: false,
    password: false,
    email: false,
    contact: false,
    emergContact: false,
    dependents: false,
    organization: false,
    services: false,
    needs: false,
    location: false,
    social: false,
  });
  const [generalTabs, setGeneralTabs] = useState([
    { title: 'Personal information', href: 'personal-information' },
  ]);
  const [openDeleteEmailDlg, setOpenDeleteEmailDlg] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState('');
  const onEmailDeleteBtnClicked = (userEmail) => {
    setOpenDeleteEmailDlg(true);
    setEmailToDelete(userEmail);
  };
  const addGeneralTab = (tabData) => {
    const isExist = generalTabs.find((el) => el.title === tabData.title);
    if (!isExist) {
      setGeneralTabs((prevState) => [...prevState, tabData]);
    }
  };

  const [openTransferOwnershipDlg, setOpenTransferOwnershipDlg] =
    useState(false)

  const avatarRef = useRef();
  const logoRef = useRef();
  const backgroundRef = useRef();
  const bannerRef = useRef();


  const toggleAvatarRefresh = () => setAvatarRefreshToggle(!avatarRefreshToggle)

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const handleEdit = (type) => {
    setGeneralEditable({ ...generalEditable, [type]: true });
  };

  const handleSave = (type) => {
    switch (type) {
      case 'personal':
        const personalParams = {
          firstname: editGeneralInfo.personal.firstname,
          lastname: editGeneralInfo.personal.lastname,
          occupation: editGeneralInfo.personal.occupation,
          email: editGeneralInfo.personal.email,
          phonenumber: editGeneralInfo.personal.phonenumber,
        }
        
        const avatar = avatarRef.current.files[0];
        console.log(avatar);
        if (avatar) 
          uploadAvatar(client, avatar,userData?.id).then((res) => {
            toggleAvatarRefresh()
            client.invalidateQueries({queryKey:['user', 'me']});
          })
        personalInfoMutation.mutate(personalParams);
        setGeneralEditable({ ...generalEditable, [type]: false });


        // updateUserInfo(personalFormData, userData?.userid)
        //   .then((res) => {
        //     const userData = { ...userData, ...res };
        //     cookies.set('userInfo', JSON.stringify(userData), { path: '/' });
        //     setGeneralInfo({ ...generalInfo, [type]: editGeneralInfo[type] });
        //     setGeneralEditable({ ...generalEditable, [type]: false });
        //     setLoading(false);
        //   })
        //   .catch((err) => {
        //     setMessage({
        //       open: true,
        //       title: text.error,
        //       description: text.failedUpdatePersonalInfo,
        //     });
        //     setLoading(false);
        //   });
        break;
      case 'password':
        if (!editGeneralInfo.password.password.length) {
          setChangePwdError(text.passwordRequired);
        } else if (
          editGeneralInfo.password.newPassword !==
          editGeneralInfo.password.confirmNewPassword
        ) {
          setChangePwdError(text.passwordsMismatched);
        } else if (editGeneralInfo.password.newPassword.length < 6) {
          setChangePwdError(text.minimumPassword);
        } else {
          let data = {
            oldpassword: editGeneralInfo.password.password,
            newpassword: editGeneralInfo.password.newPassword,
            email : userData?.email
          };
          resetPasswordQuery.mutate(data);
        }
        break;
      
      case 'services':
        if (editGeneralInfo.services.scp) {
        //   enableSCPService()
        //     .then((res) => {
        //       setGeneralInfo({ ...generalInfo, [type]: editGeneralInfo[type] });
        //       setGeneralEditable({ ...generalEditable, [type]: false });
        //       setLoading(false);
        //     })
        //     .catch((err) => {
        //       setMessage({
        //         open: true,
        //         title: text.error,
        //         description: text.failedEnableScpService,
        //       });
        //       setLoading(false);
        //     });
        // } else {
        //   disableSCPService()
        //     .then((res) => {
        //       setGeneralInfo({ ...generalInfo, [type]: editGeneralInfo[type] });
        //       setGeneralEditable({ ...generalEditable, [type]: false });
        //       setLoading(false);
        //     })
        //     .catch((err) => {
        //       setMessage({
        //         open: true,
        //         title: text.error,
        //         description: text.failedDisableScpService,
        //       });
        //       setLoading(false);
        //     });
        }
        break;
      default:
        setGeneralEditable({ ...generalEditable, [type]: false });
        break;
    }
  };

  const handleCancel = (type) => {
    if (type === 'password') setChangePwdError('');
    if (type === 'email') setNewEmailError('');
    setGeneralEditable({ ...generalEditable, [type]: false });
  };

  const handleChange = (type, field, value) => {
    setEditGeneralInfo({
      ...editGeneralInfo,
      [type]: { ...editGeneralInfo[type], [field]: value },
    });
  };

  const handleDelete = () => {};


  const handleDeleteEmail = () => {

  };

  const changeLoginOrg = (loginOrg) => {
  };

  const transferOwnership = (user_id) => {

  };

  const roleNameClicked = () => {
  };

  return (
    userQuery.isSuccess && (
      <div className="lg:flex mb-12 w-full">
        <div className="flex-4 w-full">
          <div id="personal-information" className="mt-0.5 form-control gap-4">
            <WhiteCard>
              <CardContent>
                <p className="mb-4 text-2xl font-semibold">
                  {text.personalInformation}
                </p>
                <Divider />
                { generalEditable.password ? (
                  <div className="xl:flex mt-7.5">
                    <div className="flex-7">
                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.password}:</p>
                        </div>
                        <div className="flex-5">
                          <TextInput
                            type="password"
                            value={editGeneralInfo.password.password}
                            onChange={(e) =>
                              handleChange(
                                'password',
                                'password',
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.newPassword}:</p>
                        </div>
                        <div className="flex-5">
                          <TextInput
                            type="password"
                            value={editGeneralInfo.password.newPassword}
                            onChange={(e) =>
                              handleChange(
                                'password',
                                'newPassword',
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">
                            {text.confirmNewPassword}:
                          </p>
                        </div>
                        <div className="flex-5">
                          <TextInput
                            type="password"
                            value={editGeneralInfo.password.confirmNewPassword}
                            onChange={(e) =>
                              handleChange(
                                'password',
                                'confirmNewPassword',
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="xs:flex items-center my-4">
                        <div className="flex-2"></div>
                        <div className="flex-5">
                          <p className="font-light text-red-bright">
                            {changePwdError}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex-3" />
                  </div>
                ) : generalEditable.email ? (
                  <EmailManagement 
                    myUserQuery={userQuery}
                    secondaryEmailQuery={secondaryEmailsQuery}
                    handleBack={() => setGeneralEditable({ ...generalEditable, email: false })}
                  />
                ) : (
                  <div className="xl:flex mt-7.5">
                    <div className="flex-7">
                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.avatar}:</p>
                        </div>
                        <div className="flex-5">
                          {generalEditable.personal ? (
                            <div className="flex items-center">

                                <img
                                  src={editGeneralInfo.personal.avatar}
                                  className="w-9 h-9 rounded-half"
                                  key={avatarRefreshToggle + 'editedimg'}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/assets/images/profile.png';
                                  }}
                                  alt="avatar"
                                />

                              <input
                                ref={avatarRef}
                                accept="image/*"
                                className="hidden"
                                id="contained-button-file"
                                type="file"
                                onChange={(e) => {
                                  if (e.target.files[0]) {
                                    fileToDataUri(e.target.files[0]).then(
                                      (dataUri) =>
                                        handleChange(
                                          'personal',
                                          'avatar',
                                          dataUri
                                        )
                                    );
                                  }
                                }}
                              />
                              <div
                                className={
                                  editGeneralInfo.personal.avatar ? 'ml-4' : ''
                                }
                              >
                                <BasicGrayButton
                                  onClick={() => avatarRef.current.click()}
                                >
                                  {text.uploadImage}
                                </BasicGrayButton>
                              </div>
                            </div>
                          ) :
                            <TrackedImage
                              className="w-9 h-9 rounded-half"
                              src={getAvatarUrl(userData?.id)}
                              onError={ (e) => {
                                e.target.src = '/assets/images/profile.png';
                                e.target.onerror = null;
                              }}
                              alt="avatar"
                            />
                          }
                        </div>
                      </div>
                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.firstname}:</p>
                        </div>
                        <div className="flex-5">
                          {generalEditable.personal ? (
                            <TextInput
                              value={editGeneralInfo.personal.firstname}
                              onChange={(e) =>
                                handleChange(
                                  'personal',
                                  'firstname',
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <p className="font-medium">
                              {userData.firstname}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.lastname}:</p>
                        </div>
                        <div className="flex-5">
                          {generalEditable.personal ? (
                            <TextInput
                              value={editGeneralInfo.personal.lastname}
                              onChange={(e) =>
                                handleChange(
                                  'personal',
                                  'lastname',
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <p className="font-medium">
                              {userData.lastname}
                            </p>
                          )}
                        </div>
                      </div>

                      {!generalEditable.personal && (
                        <div className="my-4">
                          <div className="xs:flex items-center">
                            <div className="flex-2">
                              <p className="font-light">{text.email}:</p>
                            </div>
                            <div className="flex-5">
                                  <p className="font-medium">
                                    {userData?.email}
                                  </p>
                            </div>
                          </div>
                          <div className="xs:flex items-center">
                            <div className="flex-2" />
                            <div className="flex-5">
                              {generalEditable.personal && (
                                <p className="pt-1 text-xs leading-loose opacity-50">
                                  {text.requireReverifyEmail}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.phone}:</p>
                        </div>
                        <div className="flex flex-5">
                          {generalEditable.personal ? (
                            <TextInput
                              value={editGeneralInfo.personal.phonenumber}
                              onChange={(e) =>
                                handleChange(
                                  'personal',
                                  'phonenumber',
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <p className="flex flex-3 font-medium items-center">
                              {userData?.['phone number']}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.occupation}:</p>
                        </div>
                        <div className="flex-5">
                          {generalEditable.personal ? (
                            <TextInput
                              value={editGeneralInfo.personal.occupation}
                              onChange={(e) =>
                                handleChange(
                                  'personal',
                                  'occupation',
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <p className="font-medium">
                              {userData?.occupation}
                            </p>
                          )}
                        </div>
                      </div>

                      {!generalEditable.personal && (
                        <div className="my-4">
                          <div className="flex items-center">
                            <div className="flex-2">
                              <p className="font-light">{text.status}:</p>
                            </div>
                            <div className="flex-5">
                              {userData?.status === '1' ? (
                                <div className="w-fit-content bg-green-honeydew text-green-lime rounded-6 text-center py-px px-2.5">
                                  <span className="text-sm font-medium -tracking-0.58">
                                    {text.active}
                                  </span>
                                </div>
                              ) : (
                                <div className="w-fit-content bg-yellow-cornsilk text-yellow-broom rounded-6 text-center py-px px-2.5">
                                  <span className="text-sm font-medium -tracking-0.58">
                                    {text.hold}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {!generalEditable.personal && (
                        <div className="xs:flex items-center my-4">
                          <div className="flex-2">
                            <p className="font-light">{text.memberSince}:</p>
                          </div>
                          <div className="flex-5">
                            {generalEditable.personal ? (
                              <TextInput
                                value={getDateString(userData.timestamp)}
                                disabled
                                endAdornment={<LockIcon />}
                              />
                            ) : (
                              <p className="font-medium">
                                {getDateString(userData.timestamp)}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      {!generalEditable.personal && (
                        <div className="my-4">
                          <div className="xs:flex items-center">
                            <div className="flex-2">
                              <p className="font-light">{text.memberType}:</p>
                            </div>
                            <div className="flex-5">
                              {generalEditable.personal ? (
                                <TextInput
                                  value={''}
                                  disabled
                                  endAdornment={<LockIcon />}
                                />
                              ) : (
                                <p className="font-medium">
                                {userData?.['admin level'] == '10' ? 'Unity11 Admin' : 'User'}
                                  {userData?.owner === 'true' && (
                                    <span
                                      className="ml-2"
                                      onClick={() =>
                                        setOpenTransferOwnershipDlg(true)
                                      }
                                    >
                                      <SyncAlt />
                                    </span>
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="xs:flex items-center">
                            <div className="flex-2" />
                            <div className="flex-5">
                              {generalEditable.personal && (
                                <p className="pt-1 text-xs leading-loose opacity-50">
                                  {text.visit}{' '}
                                  <span className="text-blue-cornflower cursor-pointer underline">
                                    {text.apply}
                                  </span>{' '}
                                  {text.applyOtherRole}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex-3" />
                  </div>
                )}
              </CardContent>
              <CardActions>
                {generalEditable.password && (
                  <>
                    <BasicBlueRectButton onClick={() => handleSave('password')}>
                      {text.save}
                    </BasicBlueRectButton>
                    <BasicGrayButton onClick={() => handleCancel('password')}>
                      {text.cancel}
                    </BasicGrayButton>
                  </>
                )}
                {generalEditable.email && (
                  <>
                    {/* <BasicGrayButton onClick={() => handleCancel('email')}>
                      {text.back}
                    </BasicGrayButton> */}
                  </>
                )}
                {!generalEditable.password &&
                  !generalEditable.email &&
                  generalEditable.personal && (
                    <>
                      <BasicBlueRectButton onClick={() => handleSave('personal')}>
                        {text.save}
                      </BasicBlueRectButton>
                      <BasicGrayButton onClick={() => handleCancel('personal')}>
                        {text.cancel}
                      </BasicGrayButton>
                    </>
                  )}
                {!generalEditable.password &&
                  !generalEditable.email &&
                  !generalEditable.personal && (
                    <div className='flex gap-2 flex-wrap'>
                      <BasicGrayButton onClick={() => handleEdit('personal')}>
                        {text.edit}
                      </BasicGrayButton>
                      <BasicGrayButton onClick={() => handleEdit('email')}>
                        {text.updateEmail}
                      </BasicGrayButton>
                      <BasicGrayButton onClick={() => handleEdit('password')}>
                        {text.changePassword}
                      </BasicGrayButton>
                    </div>
                  )}
              </CardActions>
            </WhiteCard>
                  
            {/* <div id='contact-information'>
              <WhiteCard>

                <SubsectionTitle>Contact Information</SubsectionTitle>
                <div className="divider divider-vertical"></div>
                <div className="form-control gap-4">
                  <div className="xl:flex mt-7.5">
                      <div className="flex-7 ">

                        <div className="xs:flex items-center my-4">
                          <div className="flex-2">
                            <p className="font-light">{text.phone}:</p>
                          </div>
                          <div className="flex-5">
                            {generalEditable.contact ? (
                              <TextInput
                                value={editGeneralInfo.personal.phonenumber}
                                onChange={(e) =>
                                  handleChange(
                                    'personal',
                                    'firstname',
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              <p className="font-medium">
                                {userData?.['phone number']}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="xl:flex mt-7.5">
                    <div className="flex-7">
                      {userEmails.map((userEmail, idx) => (
                        <div
                          className="xs:flex items-center my-4"
                          key={'user-email-div-' + idx}
                        >
                          <div className="flex-2">
                            {idx === 0 && (
                              <p className="font-light">{text.email}:</p>
                            )}
                          </div>
                          <div className="flex-5">
                            <div className="flex justify-between">
                              <div className="flex">
                                <p className="font-medium">{userEmail.email}</p>
                                {(userEmail.is_primary ||
                                  userEmail.email_verified) && (
                                  <div className="flex">
                                    <div className="text-black">
                                      <span className="ml-2 mr-1">-</span>
                                    </div>
                                    <div className="text-green-lime">
                                      <span>
                                        {userEmail.is_primary
                                          ? 'Primary'
                                          : 'Verified'}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {!userEmail.is_primary && (
                                <DeleteSweep
                                  onClick={() =>
                                    onEmailDeleteBtnClicked(userEmail.email)
                                  }
                                />
                              )}
                            </div>
                            {!userEmail.email_verified && (
                              <div className="flex items-center">
                                <div className="text-xs">
                                  <Circle
                                    fontSize={'inherit'}
                                    color={'disabled'}
                                    className={'mr-2'}
                                  />
                                </div>
                                <div className="text-orange-gamboge">
                                  <span className="text-sm mr-1">
                                    {text.unverified}
                                  </span>
                                </div>
                                <div className="text-black">
                                  <span className="mr-1">-</span>
                                </div>
                                <div className="text-blue-600">
                                  <span
                                    className={'underline'}
                                    onClick={() => resendVerificationEmail(idx)}
                                  >
                                    {text.resendVerificationEmail}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="xs:flex mt-12">
                        <p>{text.addEmailAddress}</p>
                      </div>
                      <div className="xs:flex justify-between">
                        <TextInput
                          className={'mr-4'}
                          value={newEmail}
                          onChange={(e) => setNewEmail(e.target.value)}
                        />
                        <BasicGrayButton small onClick={addEmail}>
                          {text.add}
                        </BasicGrayButton>
                      </div>

                      <div className="xs:flex mt-12">
                        <p>{text.primaryEmailAddress}</p>
                      </div>
                      <div className="xs:flex">
                        <Select
                          native
                          value={primaryEmail}
                          onChange={(e) => setPrimaryEmail(e.target.value)}
                          input={<SelectInput />}
                          className="mr-4"
                        >
                          {userEmails
                            .filter((e) => e.email_verified === true)
                            .map((userEmail, idx) => (
                              <option key={`primary-email-${idx}`}>
                                {userEmail.email}
                              </option>
                            ))}
                        </Select>

                        <BasicGrayButton small onClick={handleUpdatePrimaryEmail}>
                          {text.save}
                        </BasicGrayButton>
                      </div>

                      {newEmailError && (
                        <div className="xs:flex items-center">
                          <p className="font-light text-red-bright">
                            * {newEmailError}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="flex-3" />
                  </div>

                      </div>
                      <div className="flex-3"></div>
                  </div>
                </div>
                <CardActions>
                  {!generalEditable.contact && (
                    <>
                      <BasicGrayButton onClick={() => handleEdit('contact')}>
                        {text.edit}
                      </BasicGrayButton>
                    </>
                  )}
                  {generalEditable.contact && (
                    <>
                      <BasicBlueRectButton onClick={() => handleSave('contact')}>
                        {text.save}
                      </BasicBlueRectButton>
                      <BasicGrayButton onClick={() => handleCancel('contact')}>
                        {text.cancel}
                      </BasicGrayButton>
                    </>

                  )}
                </CardActions>
              </WhiteCard>
            </div> */}

          </div>
             {/* [userTypes.admin, userTypes.masterAdmin].includes( */}

          {allowedSCPService && false && (
            <div id="organization-services" className="mt-5">
              <WhiteCard>
                <CardContent>
                  <p className="mb-4 text-2xl font-semibold">
                    {text.orgServices}
                  </p>
                  <Divider />
                  <div className="xl:flex mt-7.5">
                    <div className="flex-7">
                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.scp}:</p>
                        </div>
                        <div className="flex-5">
                          {generalEditable.services ? (
                            <Select
                              native
                              value={editGeneralInfo.services.scp}
                              onChange={(e) =>
                                handleChange(
                                  'services',
                                  'scp',
                                  e.target.value === 'true' ? true : false
                                )
                              }
                              input={<SelectInput />}
                            >
                              <option value={true}>{text.enable}</option>
                              <option value={false}>{text.disable}</option>
                            </Select>
                          ) : userData?.scp ? (
                            <div className="w-fit-content bg-green-honeydew text-green-lime rounded-6 text-center py-px px-2.5">
                              <span className="text-sm font-medium -tracking-0.58">
                                {text.enable}
                              </span>
                            </div>
                          ) : (
                            <div className="w-fit-content bg-red-light-grayish text-red-bright rounded-6 text-center py-px px-2.5">
                              <span className="text-sm font-medium -tracking-0.58">
                                {text.disable}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex-3" />
                  </div>
                </CardContent>
                <CardActions>
                  {generalEditable.services ? (
                    <>
                      <BasicBlueRectButton onClick={() => handleSave('services')}>
                        {text.save}
                      </BasicBlueRectButton>
                      <BasicGrayButton onClick={() => handleCancel('services')}>
                        {text.cancel}
                      </BasicGrayButton>
                    </>
                  ) : (
                    <BasicGrayButton onClick={() => handleEdit('services')}>
                      {text.edit}
                    </BasicGrayButton>
                  )}
                </CardActions>
              </WhiteCard>
            </div>
          )}
          <div className="hidden">
            <div id="needs-details" className="mt-5">
              <WhiteCard>
                <CardContent>
                  <p className="mb-4 text-2xl font-semibold">
                    {text.needsDetails}
                  </p>
                  <Divider />
                  <p className="mt-7.5 leading-1.75 opacity-50">
                    {text.needsDetailsDescription}
                  </p>
                  <div className="xl:flex mt-12.5">
                    <div className="flex-7">
                      <div className="my-4">
                        <div className="xs:flex items-center">
                          <div className="flex-2">
                            <p className="font-light">
                              {text.socialAssistance}:
                            </p>
                          </div>
                          <div className="flex-5">
                            {generalEditable.needs ? (
                              editGeneralInfo.needs.social === 1 ? (
                                <div className="flex">
                                  <div>
                                    <BlueOutlineButton
                                      onClick={() =>
                                        handleChange('needs', 'social', 1)
                                      }
                                    >
                                      {text.yes}
                                    </BlueOutlineButton>
                                  </div>
                                  <div className="ml-4">
                                    <GrayOutlineButton
                                      onClick={() =>
                                        handleChange('needs', 'social', 0)
                                      }
                                    >
                                      {text.no}
                                    </GrayOutlineButton>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex">
                                  <div>
                                    <GrayOutlineButton
                                      onClick={() =>
                                        handleChange('needs', 'social', 1)
                                      }
                                    >
                                      {text.yes}
                                    </GrayOutlineButton>
                                  </div>
                                  <div className="ml-4">
                                    <BlueOutlineButton
                                      onClick={() =>
                                        handleChange('needs', 'social', 0)
                                      }
                                    >
                                      {text.no}
                                    </BlueOutlineButton>
                                  </div>
                                </div>
                              )
                            ) : (
                              <p className="font-medium">
                                {userData?.social === 1 // TODO what does this mean
                                  ? text.yes
                                  : text.no}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="xs:flex items-center">
                          <div className="flex-2" />
                          <div className="flex-5">
                            {generalEditable.needs && (
                              <p className="pt-1 text-xs leading-loose opacity-50">
                                {text.indicateSocialAssistance}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">
                            {text.familyComposition}:
                          </p>
                        </div>
                        <div className="flex-5">
                          {generalEditable.needs ? (
                            <Select
                              native
                              value={editGeneralInfo.needs.family}
                              onChange={(e) =>
                                handleChange(
                                  'needs',
                                  'family',
                                  parseInt(e.target.value)
                                )
                              }
                              input={<SelectInput />}
                            >
                              <option value={0}>{text.singlePerson}</option>
                              <option value={1}>{text.family}</option>
                            </Select>
                          ) : (
                            <p className="font-medium">
                              {userData?.family === 0
                                ? text.singlePerson
                                : text.family}
                            </p>
                          )}
                        </div>
                      </div>
                      {editGeneralInfo.needs.family === 1 ? (
                        <div className="my-4">
                          <div className="xs:flex items-center">
                            <div className="flex-2">
                              <p className="font-light">{text.dependents}:</p>
                            </div>
                            <div className="flex-5">
                              {generalEditable.needs ? (
                                <Select
                                  native
                                  value={editGeneralInfo.needs.dependents}
                                  onChange={(e) =>
                                    handleChange(
                                      'needs',
                                      'dependents',
                                      parseInt(e.target.value)
                                    )
                                  }
                                  input={<SelectInput />}
                                >
                                  <option aria-label="None" value="" hidden />
                                  <option value={1}>{text.one}</option>
                                  <option value={2}>{text.two}</option>
                                </Select>
                              ) : (
                                <p className="font-medium">
                                  {userData?.dependents}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="xs:flex items-center">
                            <div className="flex-2" />
                            <div className="flex-5">
                              {generalEditable.needs && (
                                <p className="pt-1 text-xs leading-loose opacity-50">
                                  {text.indicateHowMany}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="flex-3" />
                  </div>
                </CardContent>
                <CardActions>
                  {generalEditable.needs ? (
                    <>
                      <BasicBlueRectButton onClick={() => handleSave('needs')}>
                        {text.save}
                      </BasicBlueRectButton>
                      <BasicGrayButton onClick={() => handleCancel('needs')}>
                        {text.cancel}
                      </BasicGrayButton>
                    </>
                  ) : (
                    <BasicGrayButton onClick={() => handleEdit('needs')}>
                      {text.edit}
                    </BasicGrayButton>
                  )}
                </CardActions>
              </WhiteCard>
            </div>
            <div id="location-details" className="mt-5">
              <WhiteCard>
                <CardContent>
                  <p className="mb-4 text-2xl font-semibold">
                    {text.locationDetails}
                  </p>
                  <Divider />
                  <p className="mt-7.5 leading-1.75 opacity-50">
                    {text.locationDetailsDescription}
                  </p>
                  <div className="xl:flex mt-12.5">
                    <div className="flex-7">
                      <div className="my-4">
                        <div className="sm:flex items-center">
                          <div className="flex-2">
                            <p className="font-light">{text.residence}:</p>
                          </div>
                          <div className="flex-5">
                            {generalEditable.location ? (
                              editGeneralInfo.location.residence === 1 ? (
                                <div className="xs:flex">
                                  <div className="xs:block hidden">
                                    <BlueOutlineButton
                                      onClick={() =>
                                        handleChange('location', 'residence', 1)
                                      }
                                    >
                                      {text.onCampus}
                                    </BlueOutlineButton>
                                  </div>
                                  <div className="xs:hidden block">
                                    <BlueOutlineButton
                                      fullWidth
                                      onClick={() =>
                                        handleChange('location', 'residence', 1)
                                      }
                                    >
                                      {text.onCampus}
                                    </BlueOutlineButton>
                                  </div>
                                  <div className="xs:block hidden ml-4">
                                    <GrayOutlineButton
                                      onClick={() =>
                                        handleChange('location', 'residence', 0)
                                      }
                                    >
                                      {text.offCampus}
                                    </GrayOutlineButton>
                                  </div>
                                  <div className="xs:hidden block xs:ml-4 xs:mt-0 mt-2">
                                    <GrayOutlineButton
                                      fullWidth
                                      onClick={() =>
                                        handleChange('location', 'residence', 0)
                                      }
                                    >
                                      {text.offCampus}
                                    </GrayOutlineButton>
                                  </div>
                                </div>
                              ) : (
                                <div className="xs:flex">
                                  <div className="xs:block hidden">
                                    <GrayOutlineButton
                                      onClick={() =>
                                        handleChange('location', 'residence', 1)
                                      }
                                    >
                                      {text.onCampus}
                                    </GrayOutlineButton>
                                  </div>
                                  <div className="xs:hidden block">
                                    <GrayOutlineButton
                                      fullWidth
                                      onClick={() =>
                                        handleChange('location', 'residence', 1)
                                      }
                                    >
                                      {text.onCampus}
                                    </GrayOutlineButton>
                                  </div>
                                  <div className="xs:block hidden ml-4">
                                    <BlueOutlineButton
                                      onClick={() =>
                                        handleChange('location', 'residence', 0)
                                      }
                                    >
                                      {text.offCampus}
                                    </BlueOutlineButton>
                                  </div>
                                  <div className="xs:hidden block xs:ml-4 xs:mt-0 mt-2">
                                    <BlueOutlineButton
                                      fullWidth
                                      onClick={() =>
                                        handleChange('location', 'residence', 0)
                                      }
                                    >
                                      {text.offCampus}
                                    </BlueOutlineButton>
                                  </div>
                                </div>
                              )
                            ) : (
                              <p className="font-medium">
                                {userData?.residence === 1
                                  ? 'On-campus'
                                  : 'Off-campus'}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="sm:flex items-center">
                          <div className="flex-2" />
                          <div className="flex-5">
                            {generalEditable.location && (
                              <p className="pt-1 text-xs leading-loose opacity-50">
                                {text.limitLocations}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="my-4">
                        <div className="xs:flex items-center">
                          <div className="flex-2">
                            <p className="font-light">{text.campus}:</p>
                          </div>
                          <div className="flex-5">
                            {generalEditable.location ? (
                              <Select
                                native
                                value={editGeneralInfo.location.campus}
                                onChange={(e) =>
                                  handleChange(
                                    'location',
                                    'campus',
                                    parseInt(e.target.value)
                                  )
                                }
                                input={<SelectInput />}
                              >

                                {campusList.map((item, index) => (
                                  <option
                                    key={`campus-option-${index}`}
                                    className="w-108 h-9 py-1.5 px-4 bg-white rounded-6 border-2 border-solid border-gray-100 font-medium -tracking-0.67"
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </Select>
                            ) : (
                              <p className="font-medium">
                                {campusList.find(
                                  (item) =>
                                    item.id === userData?.campus
                                ) &&
                                  campusList.find(
                                    (item) =>
                                      item.id === userData?.campus
                                  ).name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="xs:flex items-center">
                          <div className="flex-2" />
                          <div className="flex-5">
                            {generalEditable.location && (
                              <p className="pt-1 text-xs leading-loose opacity-50">
                                {text.selectCampus}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="xs:flex items-center my-4">
                        <div className="flex-2">
                          <p className="font-light">{text.building}:</p>
                        </div>
                        <div className="flex-5">
                          {generalEditable.location ? (
                            <Select
                              native
                              value={editGeneralInfo.location.building}
                              onChange={(e) =>
                                handleChange(
                                  'location',
                                  'building',
                                  parseInt(e.target.value)
                                )
                              }
                              input={<SelectInput />}
                            >
                              {buildingList.map((item, index) => (
                                <option
                                  key={`building-option-${index}`}
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              ))}

                            </Select>
                          ) : (
                            <p className="font-medium">
                              {buildingList.find(
                                (item) =>
                                  item.id === userData?.building
                              ) &&
                                buildingList.find(
                                  (item) =>
                                    item.id === userData?.building
                                ).name}
                            </p>
                          )}
                        </div>
                      </div>
                      {editGeneralInfo.location.residence === 1 ? (
                        <div className="my-4">
                          <div className="xs:flex items-center">
                            <div className="flex-2">
                              <p className="font-light">{text.room}:</p>
                            </div>
                            <div className="flex-5">
                              {generalEditable.location ? (
                                <TextInput
                                  value={editGeneralInfo.location.room}
                                  onChange={(e) =>
                                    handleChange(
                                      'location',
                                      'room',
                                      e.target.value
                                    )
                                  }
                                />
                              ) : (
                                <p className="font-medium">
                                  {userData?.room}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="xs:flex items-center">
                            <div className="flex-2" />
                            <div className="flex-5">
                              {generalEditable.location && (
                                <p className="pt-1 text-xs leading-loose opacity-50">
                                  {text.provideResidence}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="flex-3" />
                  </div>
                </CardContent>
                <CardActions>
                  {generalEditable.location ? (
                    <>
                      <BasicBlueRectButton onClick={() => handleSave('location')}>
                        {text.save}
                      </BasicBlueRectButton>
                      <BasicGrayButton onClick={() => handleCancel('location')}>
                        {text.cancel}
                      </BasicGrayButton>
                    </>
                  ) : (
                    <BasicGrayButton onClick={() => handleEdit('location')}>
                      {text.edit}
                    </BasicGrayButton>
                  )}
                </CardActions>
              </WhiteCard>
            </div>
            <div id="social-media" className="mt-5">
              <WhiteCard>
                <CardContent>
                  <p className="mb-4 text-2xl font-semibold">
                    {text.socialMedia}
                  </p>
                  <Divider />
                  <div className="xl:flex mt-7.5">
                    <div className="flex-7">
                      <p className="text-gray-normal leading-1.75">
                        {text.socialMediaDescription}
                      </p>
                      <div className="mt-12.5 my-4">
                        <div className="xs:flex items-center">
                          <div className="sm:flex-2 flex-3">
                            <div className="flex items-center">
                              <FacebookIcon />
                              <p className="ml-3 font-light">
                                {text.facebook}:
                              </p>
                            </div>
                          </div>
                          <div className="sm:flex-5 flex-4 xs:mt-0 mt-2">
                            {generalEditable.social ? (
                              <>
                                <div className="xs:block hidden">
                                  <BasicGrayButton>{text.connectAccount}</BasicGrayButton>
                                </div>
                                <div className="xs:hidden block">
                                  <BasicGrayButton fullWidth>
                                    {text.connectAccount}
                                  </BasicGrayButton>
                                </div>
                              </>
                            ) : (
                              <p className="font-medium opacity-50">
                                {text.notSet}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="xs:flex items-center">
                          <div className="sm:flex-2 flex-3" />
                          <div className="sm:flex-5 flex-4">
                            {generalEditable.social && (
                              <p className="pt-1 text-xs leading-loose opacity-50">
                                {text.allowFacebook}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="my-4">
                        <div className="xs:flex items-center">
                          <div className="sm:flex-2 flex-3">
                            <div className="flex items-center">
                              <InstagramIcon />
                              <p className="ml-3 font-light">
                                {text.instagram}:
                              </p>
                            </div>
                          </div>
                          <div className="sm:flex-5 flex-4 xs:mt-0 mt-2">
                            {generalEditable.social ? (
                              <>
                                <div className="xs:block hidden">
                                  <BasicGrayButton>{text.connectAccount}</BasicGrayButton>
                                </div>
                                <div className="xs:hidden block">
                                  <BasicGrayButton fullWidth>
                                    {text.connectAccount}
                                  </BasicGrayButton>
                                </div>
                              </>
                            ) : (
                              <p className="font-medium opacity-50">
                                {text.notSet}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="xs:flex items-center">
                          <div className="sm:flex-2 flex-3" />
                          <div className="sm:flex-5 flex-4">
                            {generalEditable.social && (
                              <p className="pt-1 text-xs leading-loose opacity-50">
                                {text.allowInstagram}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="my-4">
                        <div className="xs:flex items-center">
                          <div className="sm:flex-2 flex-3">
                            <div className="flex items-center">
                              <TwitterIcon />
                              <p className="ml-3 font-light">{text.twitter}:</p>
                            </div>
                          </div>
                          <div className="sm:flex-5 flex-4 xs:mt-0 mt-2">
                            {generalEditable.social ? (
                              <>
                                <div className="xs:block hidden">
                                  <BasicGrayButton>{text.connectAccount}</BasicGrayButton>
                                </div>
                                <div className="xs:hidden block">
                                  <BasicGrayButton fullWidth>
                                    {text.connectAccount}
                                  </BasicGrayButton>
                                </div>
                              </>
                            ) : (
                              <p className="font-medium opacity-50">
                                {text.notSet}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="xs:flex items-center">
                          <div className="sm:flex-2 flex-3" />
                          <div className="sm:flex-5 flex-4">
                            {generalEditable.social && (
                              <p className="pt-1 text-xs leading-loose opacity-50">
                                {text.allowTwitter}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-3" />
                  </div>
                </CardContent>
                <CardActions>
                  {generalEditable.social ? (
                    <>
                      <BasicBlueRectButton onClick={() => handleSave('social')}>
                        {text.save}
                      </BasicBlueRectButton>
                      <BasicGrayButton onClick={() => handleCancel('social')}>
                        {text.cancel}
                      </BasicGrayButton>
                    </>
                  ) : (
                    <BasicGrayButton onClick={() => handleEdit('social')}>
                      {text.edit}
                    </BasicGrayButton>
                  )}
                </CardActions>
              </WhiteCard>
            </div>
            <div id="delete-account" className="mt-5"></div>
            <WhiteCard>
              <CardContent>
                <p className="mb-4 text-2xl font-semibold">
                  {text.deleteAccount}
                </p>
                <Divider />
                <p className="my-7.5 leading-1.75 opacity-50">
                  {text.deleteAccountDescription}
                </p>
              </CardContent>
              <CardActions>
                <BasicRedRectButton onClick={() => handleDelete()}>
                  {text.deleteAccount}
                </BasicRedRectButton>
              </CardActions>
            </WhiteCard>
          </div>
        </div>
        <div className="flex-1 lg:block hidden mt-7 ml-12">
          <SectionTabs tabs={generalTabs} />
        </div>

        {/* {[userTypes.admin, userTypes.masterAdmin].includes( */}
        {[].includes(
          userData?.userType
        ) && (
          {/* <SelectLoginOrganizationDialog
            text={text}
            open={openSelectLoginOrgDlg}
            onClose={() => setOpenSelectLoginOrgDlg(false)}
            changeLoginOrg={changeLoginOrg}
          /> */}
        )}
        <ConfirmDialog
          open={openDeleteEmailDlg}
          title={text.deleteUserEmailDlgTitle}
          description={text.deleteUserEmailDlgDescription}
          onConfirm={handleDeleteEmail}
          onCancel={() => setOpenDeleteEmailDlg(false)}
        />
      </div>
    )
  );
}

export default withCookies(General);
