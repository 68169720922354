
import { BasicBlueRectButton } from "@/components/Buttons/BasicBlueRectButton";
import { BasicGrayButton } from "@/components/Buttons/BasicGrayButton";
import { BlueOutlineButton } from "@/components/Buttons/BlueOutlineButton";
import { GrayOutlineButton } from "@/components/Buttons/GrayOutlineButton";
import useCancelRoleApp from "@/hooks/state/application/useCancelRoleApp";
import useSubmitBaseRole from "@/hooks/state/application/useSubmitBaseRole";
import useSubmitRoleApp from "@/hooks/state/application/useSubmitRoleApp";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import useOrgTypes from "@/hooks/state/enum/useOrgTypes";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { queryResultFns } from "@/wrappers/swal/queryResultFns";
import { CardActions, CardContent, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { WhiteCard } from "../../../../../../components/Cards";
import { TextArea, TextInput } from "../../../../../../components/Inputs";
import { useAppContext } from "../../../../../../context/AppContext";
import { getDateString, getMonthDayString } from "../../../../../../utils";

function SocialMediaInfluencerApplication({prevSmiApp, baseSmi, text, org_type, expiry_date}) {
  const { setLoading, setMessage } = useAppContext();
  const [isEditing, setIsEditing] = useState(false);
  const [socialMediaForm, setSocialMediaForm] = useState({
    is_student: false,
    student_id: '',
    age_over_18: false,
    motivation: '',
    existing_network_desc: ''
  });
  const contextQuery = useCurrentOrgContext()
  const roleEnumQuery = useRoleEnum()
  const appStatusQuery = useAppStatusEnum()
  const orgTypesQuery = useOrgTypes();

  const cancelAppMutation = useCancelRoleApp(
    queryResultFns('Cancelled application', 'Failed to cancel application'))
  const smiBaseMutation = useSubmitBaseRole({
    onSuccess: () => smiAppMutation.mutate({roleid: roleEnum.smi, orgid: contextQuery.data}),
    onError: () => {
      FailPopup.fire({text: text.failedToApplySMI})
      setIsEditing(false)
    }
  })
  const smiAppMutation = useSubmitRoleApp({
      ...queryResultFns(text.succeedToApplySMI, text.failedToApplySMI),
      onSettled: () => setIsEditing(false)
  })
const types = orgTypesQuery?.data;

const appsOpen = contextQuery.data?.smiactive === "1"
const roleEnum = roleEnumQuery.data
const appStatuses = appStatusQuery.data
const appDeclined = prevSmiApp?.status === appStatuses?.declined

  const cancelApplication = () => {
    console.log('hi');
    if (prevSmiApp?.status === appStatuses.pending)
      cancelAppMutation.mutate({roleid:roleEnum.smi, orgid: contextQuery.data?.orgid})
  }

  const handleSMIAgreeChecked = (val) => {
    handleChange('agree', val);
  }

  const handleChange = (name, value) => {
    if (name === 'is_student' && value) {
      if (org_type === types.university) {
        setSocialMediaForm({...socialMediaForm, 'grade': 1, [name]: value});
      } else if (org_type === types.schoolBoard) {
        setSocialMediaForm({...socialMediaForm, 'grade': 6, [name]: value});
      } else {
        setSocialMediaForm({...socialMediaForm, [name]: value});
      }
    } else {
      setSocialMediaForm({...socialMediaForm, [name]: value});
    }
  }

  const onSubmit = () => {
    const requestDetails = {
      roleid: roleEnum.smi,
      over18: socialMediaForm.age_over_18,
      affiliation: socialMediaForm.is_student ? 'Student': 'Employee',
      description: socialMediaForm.motivation,
      networkdescription: socialMediaForm.existing_network_desc,
    }
    smiBaseMutation.mutate(requestDetails)
    smiAppMutation.mutate({roleid:roleEnum.smi, orgid: contextQuery.data?.orgid})
    setIsEditing(false);
    // setLoading(true);
    // applySMIForm(socialMediaForm)
    //   .then(res => {
    //     setLoading(false);
    //     setSocialMediaForm(res);
    //     setMessage({open: true, title: text.success, description: text.succeedToApplySMI});
    //   })
    //   .catch(res => {
    //     setLoading(false);
    //     setMessage({open: true, title: text.error, description: text.failedToApplySMI});
    //   });
  }

  return (
    <>
      {socialMediaForm && appStatuses && (
        <div id="become-social-media-influencer" className="mt-5">
          {prevSmiApp?.status === appStatuses.pending ? (
            <WhiteCard>
              <CardContent>
                <p className="mb-4 text-2xl font-semibold">{text.becomeSocialMediaInfluencer}</p>
                <Divider />
                <div className="xl:flex mt-7.5">
                  <div className="flex-7">
                    <p className="leading-1.75">{text.pendingBecomeSMI}</p>
                    <div className="xs:flex items-center mt-12.5 my-4">
                      <div className="flex-2">
                        <p className="font-light">{text.status}:</p>
                      </div>
                      <div className="flex-5">
                        <p className="w-fit-content bg-yellow-cornsilk text-yellow-broom rounded-6 text-center py-px px-2.5">{text.pending}</p>
                      </div>
                    </div>
                    <div className="xs:flex items-center my-4">
                      <div className="flex-2">
                        <p className="font-light">{text.submitted}:</p>
                      </div>
                      <div className="flex-5">
                        <p className="font-medium">{getDateString(prevSmiApp?.timestamp)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-3" />
                </div>
              </CardContent>
              <CardActions>
                <BasicGrayButton disabled={prevSmiApp?.status !== appStatuses.pending} onClick={() => cancelApplication()}>{text.cancel}</BasicGrayButton>
              </CardActions>
            </WhiteCard>
          ) : prevSmiApp?.status === appStatuses.approved ? (
            <WhiteCard>
              <CardContent>
                <p className="mb-4 text-2xl font-semibold">{text.becomeSocialMediaInfluencer}</p>
                <Divider />
                <div className="xl:flex mt-7.5">
                  <div className="flex-7">
                    <p className="text-gray-normal leading-1.75">{text.approvedSMI}</p>
                    <div className="xs:flex items-center mt-12.5 my-4">
                      <div className="flex-2">
                        <p className="font-light">{text.status}:</p>

                      </div>
                      <div className="flex-5">
                        <p className="w-fit-content bg-green-honeydew text-green-lime rounded-6 text-center py-px px-2.5">{text.approved}</p>
                      </div>
                    </div>
                    <div className="xs:flex items-center my-4">
                      <div className="flex-2">
                        <p className="font-light">{text.expiryDate}:</p>
                      </div>
                      <div className="flex-5">
                        <p className="font-medium">{getMonthDayString(contextQuery?.data?.roleexpiry)}</p>
                      </div>
                    </div>
                    <div className="flex-3" />
                  </div>
                  <div className="flex-3" />
                </div>
              </CardContent>
              <CardActions>
                <BasicGrayButton disabled onClick={() => cancelApplication()}>{text.cancel}</BasicGrayButton>
              </CardActions>
            </WhiteCard>
          ) : prevSmiApp?.status === appStatuses.declined ? (
            <WhiteCard>
              <CardContent>
                <p className="mb-4 text-2xl font-semibold">{text.becomeSocialMediaInfluencer}</p>
                <Divider />
                <div className="xl:flex mt-7.5">
                  <div className="flex-7">
                    <p className="leading-1.75">{text.declinedSMI}</p>
                    <div className="xs:flex items-center mt-12.5 my-4">
                      <div className="flex-2">
                        <p className="font-light">{text.status}:</p>
                      </div>
                      <div className="flex-5">
                        <p className="w-fit-content bg-red-misty-rose text-red-strong rounded-6 text-center py-px px-2.5">{text.declined}</p>
                      </div>
                    </div>
                    <div className="xs:flex items-center my-4">
                      <div className="flex-2">
                        <p className="font-light">{text.declined}:</p>
                      </div>
                      <div className="flex-5">
                        <p className="font-medium">{getDateString(prevSmiApp.modified)}</p>
                      </div>
                    </div>
                    <div className="flex-3" />
                  </div>
                  <div className="flex-3" />
                </div>
              </CardContent>
              <CardActions>
                <div className="indicator">
                   { !appsOpen || appDeclined && 
                      <div 
                        className={`mb-2 ml-2 indicator-item badge  ${appDeclined ? 'text-red-700 bg-red-200' : 'badge-warning'}`}
                      >
                        { appDeclined
                          ? 'Application Declined'
                          : 'Applications Closed'
                        }
                      </div>
                    }
                  <BasicGrayButton disabled={!appsOpen} onClick={() => {
                  setSocialMediaForm(prevState => ({ ...prevState, status: null }))
                  setIsEditing(true)
                  }}>{text.openApplication}</BasicGrayButton>
                </div>
              </CardActions>
            </WhiteCard>
          ) : (
            <WhiteCard>
              <CardContent>
                <p className="mb-4 text-2xl font-semibold">{text.becomeSocialMediaInfluencer}</p>
                <Divider />
                <div className="xl:flex mt-7.5">
                  <div className="flex-7">
                    <p className="leading-1.75">{text.smiDescription}</p>
                    {isEditing && (
                      <>
                        <div className="mt-12.5 my-4">
                          <div className="xs:flex items-center">
                            <div className="flex-3">
                              <p className="font-light">{text.isStudent}</p>
                            </div>
                            <div className="flex-4">
                              {socialMediaForm.is_student ? (
                                <div className="flex">
                                  <div>
                                    <BlueOutlineButton className={'!btn-little'} onClick={() => handleChange('is_student', true)}>{text.yes}</BlueOutlineButton>
                                  </div>
                                  <div className="ml-2">
                                    <GrayOutlineButton className={'!btn-little'} onClick={() => handleChange('is_student', false)}>{text.no}</GrayOutlineButton>
                                  </div>
                                  <span className="text-red-strong ml-2">*</span>
                                </div>
                              ) : (
                                <div className="flex">
                                  <div>
                                    <GrayOutlineButton className={'!btn-little'} onClick={() => handleChange('is_student', true)}>{text.yes}</GrayOutlineButton>
                                  </div>
                                  <div className="ml-2">
                                    <BlueOutlineButton className={'!btn-little'} onClick={() => handleChange('is_student', false)}>{text.no}</BlueOutlineButton>
                                  </div>
                                  <span className="text-red-strong ml-2">*</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="my-4">
                          <div className="xs:flex items-center">
                            <div className="flex-3">
                              <p className="font-light">{text.isAgeOver18}</p>
                            </div>
                            <div className="flex-4">
                              <div className="flex-5">
                                {socialMediaForm.age_over_18 ? (
                                  <div className="flex">
                                    <div>
                                      <BlueOutlineButton className={'!btn-little'} onClick={() => handleChange('age_over_18', true)}>{text.yes}</BlueOutlineButton>
                                    </div>
                                    <div className="ml-2">
                                      <GrayOutlineButton className={'!btn-little'} onClick={() => handleChange('age_over_18', false)}>{text.no}</GrayOutlineButton>
                                    </div>
                                    <span className="text-red-strong ml-2">*</span>
                                  </div>
                                ) : (
                                  <div className="flex">
                                    <div>
                                      <GrayOutlineButton className={'!btn-little'} onClick={() => handleChange('age_over_18', true)}>{text.yes}</GrayOutlineButton>
                                    </div>
                                    <div className="ml-2">
                                      <BlueOutlineButton className={'!btn-little'} onClick={() => handleChange('age_over_18', false)}>{text.no}</BlueOutlineButton>
                                    </div>
                                    <span className="text-red-strong ml-2">*</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="my-4">
                          <div className="xs:flex items-center">
                          <p className="font-light">{text.smiMotivation}</p>
                          </div>
                        </div>
                        <div className="my-4 flex">
                          <div className="xs:flex flex-1 items-center">
                            <TextArea onChange={(e) => handleChange('motivation', e.target.value)}/>
                            <span className="text-red-strong ml-2">*</span>
                          </div>
                        </div>
                        <div className="my-4">
                          <div className="xs:flex items-center">
                            <div className="flex-3">
                              <p className="font-light">{text.existingSocialMediaPresenceOrNetWork}</p>
                            </div>
                            <div className="flex-4">
                              <div className="flex-5">
                                {socialMediaForm.existing_smi ? (
                                    <div className="flex">
                                      <div>
                                        <BlueOutlineButton className={'!btn-little'} onClick={() => handleChange('existing_smi', true)}>{text.yes}</BlueOutlineButton>
                                      </div>
                                      <div className="ml-2">
                                        <GrayOutlineButton className={'!btn-little'} onClick={() => handleChange('existing_smi', false)}>{text.no}</GrayOutlineButton>
                                      </div>
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                ) : (
                                    <div className="flex">
                                      <div>
                                        <GrayOutlineButton className={'!btn-little'} onClick={() => handleChange('existing_smi', true)}>{text.yes}</GrayOutlineButton>
                                      </div>
                                      <div className="ml-2">
                                        <BlueOutlineButton className={'!btn-little'} onClick={() => handleChange('existing_smi', false)}>{text.no}</BlueOutlineButton>
                                      </div>
                                      <span className="text-red-strong ml-2">*</span>
                                    </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {socialMediaForm.existing_smi && (
                            <div className="my-4">
                              <div className="xs:flex items-center">
                                <div className="flex-3">
                                  <p className="font-light">{text.shareSocialMediaOrNetWork}</p>
                                </div>
                                <div className="flex-4 flex">
                                  <TextInput value={socialMediaForm.existing_network_desc} onChange={(e) => handleChange('existing_network_desc', e.target.value)}/>
                                  <span className="text-white ml-2">*</span>
                                </div>
                              </div>
                            </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex-3" />
                </div>
              </CardContent>
              <CardActions>
                {isEditing ? (
                  <div className="w-full xl:flex">
                    <div className="flex-7 flex justify-between">
                      <BasicGrayButton  onClick={() => setIsEditing(false)}>{text.cancel}</BasicGrayButton>
                      <BasicBlueRectButton disabled={!socialMediaForm?.motivation?.length} onClick={() => onSubmit()}>{text.submit}</BasicBlueRectButton>
                    </div>
                    <div className="flex-3" />
                  </div>
                ) : (
                  <div className="indicator">
                    { !appsOpen && <div className="mb-2 ml-2 indicator-item badge badge-warning">Applications Closed</div>}
                    <BasicGrayButton disabled={!appsOpen} onClick={() =>  setIsEditing(true)}>{text.openApplication}</BasicGrayButton>
                  </div>
                )}
              </CardActions>
            </WhiteCard>
          )}
        </div>
      )}
    </>
  )
}

export default SocialMediaInfluencerApplication;
