
import React, { useState } from "react";

import { useAppContext } from "@/context/AppContext";
import { useUserContext } from '@/context/UserContext';
import useLiveBDEvents from "@/hooks/state/donation/useLiveBDEvent";
import useBDStatusEnum from "@/hooks/state/enum/useBDStatusEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { between } from "@/utils";
import { BasicBDEventCard } from "./BasicBDEventCard";
import useGeoData from "@/hooks/state/misc/useGeoData";
import { useGeoIDs } from "@/hooks/state/misc/useGeoIDs";

const ActiveDonations = (props) => {
  const { history} = props;
  const {data: ctx} = useCurrentOrgContext();
  const eventQuery = useLiveBDEvents({select: filterByEligibility})

  function filterByEligibility(liveEvents) {
    console.log('in select');
    return liveEvents.filter((ev) => {
      // console.log('\ndetermining eligibility for event', ev.name, ev);
      const activeTimeWindow = 
        between(Date.now() / 1000, Number(ev?.starttime) , Number(ev?.endtime) )
      // console.log("eventIsWithinWindow", activeTimeWindow)
      if (!activeTimeWindow) return false;
      const geographicallyEligible = 
        ev?.locales?.every?.(region => region.cities?.length === 0) // No restrictions
        || ev?.locales?.some((region) => 
          region?.regionid === ctx?.['region id'] 
            && region?.cities.some(cityID => cityID === ctx?.['city id'])) // City matches
      // console.log("geographicallyEligible",ev.name,  geographicallyEligible)
      const d = []
      if (!geographicallyEligible) return false;

      const communityTypeEligible = 
        ev?.orgtypes?.length === 0 // No restrictions
        || ev?.orgtypes?.some((type) => 
          type === ctx?.['org type id']) // Type matches
      // console.log("communityTypeEligible", communityTypeEligible)
      if (!communityTypeEligible) return false;
      const orgSpecificallyEligible = 
        ev?.orgs?.length === 0 // No restrictions
        || ev?.orgs?.some((org) => org === ctx?.orgid) // Org matches
      // console.log("orgSpecificallyEligible", orgSpecificallyEligible)
      if (!orgSpecificallyEligible) return false;

      return true;
    })
  }

  const viewEvent = (ev) => {
    history.push(`/donation/${ev.orgid}/${ev.id}/${ctx?.orgid}/admin`);
  }
  return (
    <div className="mb-12">
      <div className="flex flex-col items-center">
        <div className="w-full">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8">
            {eventQuery.data?.map((ev, index) => (
              <div key={`donation-active-${index}`}>
                <BasicBDEventCard
                  onClick={() => viewEvent(ev)}
                  event={ev}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveDonations;
