import { getOrgTypes } from '@/apis/base/organizations/getOrgTypes';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { camelCase, flattenOrgs, flattenSubnodes, recursiveFlattenSubnodes } from '@/utils';
import { useQuery } from '@tanstack/react-query';


export default function useOrgTypes(params) {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_ORG_TYPES],
        queryFn: (params) => getOrgTypes(params),
        staleTime: MILLISEC.HOUR,
        gcTime: MILLISEC.HOUR,
        select: (data) => {
            
            const flatTypes = flattenOrgs(data)
            const hierarchalTypes = structuredClone(data)
            const allTypes = {}
            flatTypes.forEach(t => allTypes[camelCase(t.name)] = t.id)

            return {
                flatTypes,
                ...allTypes,
                hierarchalTypes,
            }

        },
    });
    return query;
}