
import { forwardRef } from "react"
import PropTypes from 'prop-types';

export const BasicMultiDialog = forwardRef(function MultiDialogFunc({ 
	children, 
	className, 
	onClose = ()=>{} }, 
	ref)  {
    return (
        <dialog ref={ref}  className={`modal h-1/2  ${className}`} onClose={onClose}>
            <form method="dialog" className="absolute hover:cursor-pointer left-0 top-0 right-0 bottom-0 w-screen h-full modal-backdrop">
                <button>close</button>
            </form>
                {children}
        </dialog> 	
    )
}) 