import React from 'react';
import Swal from 'sweetalert2';


export const ConfirmationPopup = Swal.mixin({
    customClass: {
        confirmButton: 'btn text-white bg-blue-button text-lg w-32 border-none focus:outline-none focus:border-none mr-3 focus:shadow-none ',
        cancelButton:  'btn btn-neutral w-32 text-lg focus:shadow-none',
        icon: 'mt-[1rem]'
    },
    buttonsStyling: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    icon: 'warning',
})

