import { BasicBackButton } from '@/components/Buttons/BasicBackButton';
import { PageTitle } from '@/components/Label/PageTitle';
import { BasicTabs } from '@/components/Tabs/BasicTabs';
import useBrandPrefRegionalReport from '@/hooks/state/admin/uic/useBrandPrefRegionalReport';
import React from 'react';
import { OrgBrandPrefReport } from '../../../Reports/OrgBrandPrefReport';
import { BrandPrefRegionalReport } from './BrandPrefRegionalReport';

const tabNames = ['Overview', 'By Community']
export const BrandPrefReport = ({history, ...props}) => {
    const [activeTab, setActiveTab] = React.useState(0)
    
    const prefReportQuery = useBrandPrefRegionalReport()
    // console.log('data',prefReportQuery.data);
    function goBack() {history.goBack()}
    return (
        <div>
            <BasicBackButton className={'-mt-20'} onClick={goBack}>Return to reports</BasicBackButton>
 
            <PageTitle>Brand Preference Report</PageTitle>
            <BasicTabs {...{activeTab, onTabClick: setActiveTab, tabNames}}>
                <BrandPrefRegionalReport />
                <OrgBrandPrefReport />
            </BasicTabs>
        </div>
    );
};

