

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { getBDShipments } from '@/apis/base/donation/shipment/getBDShipments';


export default function useBDShipment({orgid, eventid}) {
    const query = useQuery(BDShipmentsQueryConfig({orgid, eventid}));
    return query;
}

export const BDShipmentsQueryConfig = ({orgid, eventid}) => queryOptions({
    queryKey: ['bd', 'event', eventid, 'org',  orgid,  ROUTE_IDS.GET_BD_SHIPMENTS],
    queryFn: (params) => getBDShipments({orgid, eventid}),
    staleTime: MILLISEC.MIN_15,
})