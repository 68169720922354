
import { PopupInventoryTable } from "@/components/Tables/PopupInventoryTable/PopupInventoryTable";
import React from "react";
import { useAppContext } from "../../../../../../../context/AppContext";

const PickupProductView = ({text, lineItems, eventid, history, reloadDataFn, loading}) => {
  const { setLoading, setMessage } = useAppContext();
  
  return (
    <div className="mb-12">
      <PopupInventoryTable {...{eventid, lineItems, reloadDataFn, loading}}/>
    </div>
  )
};

export default PickupProductView;
