
import useAppStatusEnum from '@/hooks/state/enum/useAppStatusEnum';
import React from 'react';


export const RequestStatusCell = ({status}) => {
  const appStatusQuery = useAppStatusEnum();
  const appStatuses = appStatusQuery.data;
    return (
        <>
            {
                status === appStatuses?.pending ?
                <div className="badge bg-yellow-back text-yellow-text gap-2">
                  pending
                </div> : status === appStatuses?.approved ?
                <div className="badge bg-green-back text-green-text  gap-2">
                   approved
                </div>  
                : <div className="badge bg-red-back text-red-text gap-2">
                  declined
                </div>
              } 

        </>
    );
};

