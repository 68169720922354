
import React, { useState } from 'react';
import { withCookies } from 'react-cookie';



import { PageTitle } from '@/components/Label/PageTitle';
import { BasicTabs } from '@/components/Tabs/BasicTabs';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useModOrgData from '@/hooks/state/organization/useModOrgData';
import { useTranslations } from '@/hooks/translations';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import Apply from '../Account/components/pages/Apply';
import Organization from '../Account/components/pages/Organization';
import GeneralOrgPrefs from './GeneralOrgPrefs';

const adminTabNames = ['General', 'Organization', 'Apply']
const memberTabNames = ['Apply']
const specialOrgTabNames = ['General', 'Organization']


function OrgPrefs(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const active_tab = parseInt(queryParams.get("active"));
  const { history } = props;
  const text = useTranslations('account');
  const [avatarRefreshToggle,setAvatarRefreshToggle] = useState(false)
  const [allowedCustomize, setAllowedCustomize] = useState(false);
  const [
    allowedSCPService,
    // setAllowedSCPService,
  ] = useState(false);
  const contextQuery = useCurrentOrgContext();
  const context = contextQuery?.data;
  const {isOwner, standardCommunity} = context || {}
  const hasAdminAccess = isOwner || context?.isUICAdmin
  const defaultTab = 0
  const [activeTab, setActiveTab] = useState(active_tab < 3 ? active_tab : defaultTab);
  const orgMutation = useModOrgData({
    onSuccess: () => {
      SuccessToast.fire({
        text: 'Successfully updated organization info',
      })
    }, 
    onError: () => {
      FailPopup.fire({
        text: 'Failed to update organization info',
      });
      
      // setChangePwdError(err.response.data?.detail);
    }, 
  })

  const currentTabNames = !standardCommunity ? specialOrgTabNames  : 
                          hasAdminAccess ? adminTabNames : 
                          memberTabNames
  return (
    <div className="w-full min-h-content bg-base-200 flex justify-center py-6">
      <div className="container">
        <div className="w-full h-full px-4 sm:px-6 xl:px-8">
        <PageTitle className={'pb-2'}> Organization Preferences </PageTitle>
          <BasicTabs onTabClick={setActiveTab} {...{tabNames: currentTabNames, activeTab}}>
            { hasAdminAccess && <GeneralOrgPrefs text={text}/>}
            { hasAdminAccess && <Organization text={text}/>}
            {standardCommunity && <Apply text={text}/>}
          </BasicTabs>
      </div>
    </div>
  </div>
  );
  
}

export default withCookies(OrgPrefs);
