import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from "react";

import CustomCheckbox from "./CustomCheckbox";
import { TextArea } from "./Inputs";
import RatingBar from './RatingBar';
import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';
import { GrayButton } from '@/views/pages/Auth/components/Buttons';
const StyledDialog = withStyles({
  paperWidthSm: {
    width: '100%'
  },
  root: {
      
    '& .MuiDialogTitle-root': {
      padding: '30px 25px 20px 25px',
      '& h2': {
        fontSize: '1.25rem',
        fontFamily: 'Poppins',
      },
    },
    '& .MuiDialogContent-root': {
      'max-height': 'fit-content',
      padding: '0 25px',
      '& p': {
        fontFamily: 'Poppins',
      },
      '& mark': {
        color: 'red',
        background: 'none',
      },
    },
    '& .MuiDialogActions-root': {
      padding: '30px 25px 30px 25px',
    },
  },
})(Dialog);

const StyledInput = withStyles({

  root: {
    width: '100%',
    height: '36px',
    padding: '6px 16px',
    borderRadius: '6px',
    border: 'solid 2px #f0f0f0',
    backgroundColor: '#ffffff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: '#2e2e2e',
    '&.Mui-error': {
      border: 'solid 1px #f44336',
    },
  },
})(InputBase);

const SelectInput = withStyles({
  root: {
    width: '100%',
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fff',
  },
  input: {
    padding: '8px 15px',
    '&:focus': {
      backgroundColor: 'unset',
    },
  }
})(InputBase);

const SemiSelectInput = withStyles({
  root: {
    width: '100%',
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fff',
  },
  input: {
    padding: '15px 15px',
    '&:focus': {
      backgroundColor: 'unset',
    },
  }
})(InputBase);

const MessageDialog = ({ open, title, description, onClose }) => (
  <StyledDialog

    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <BasicBlueRectButton onClick={onClose}>Ok</BasicBlueRectButton>
    </DialogActions>
  </StyledDialog>
);

const ConfirmDialog = ({ open, title, description, onConfirm, onCancel }) => (
  <StyledDialog
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <BasicBlueRectButton onClick={onConfirm}>Confirm</BasicBlueRectButton>
      <GrayButton onClick={onCancel}>Cancel</GrayButton>
    </DialogActions>
  </StyledDialog>
)

const AlertDialog = ({ open, title, description, onConfirm }) => (
  <StyledDialog
    open={open}
    onClose={onConfirm}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <BasicBlueRectButton onClick={onConfirm}>Ok</BasicBlueRectButton>
    </DialogActions>
  </StyledDialog>
)

const FeedBackDialog = ({open, data, onChange, onConfirm}) => {
  return (
    <StyledDialog open={open}>
      <DialogTitle>Thank you & Feedback</DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <div className="flex items-center my-3">
          <p className="w-40 font-light">Pick Up ID:</p>
          <p># {data.pickup_id}</p>
        </div>
        <div className="flex items-center my-1">
          <p className="w-40 font-light">Rate the event?</p>
          <RatingBar value={data.event_rate} onChange={val => {onChange('event_rate', val)}}/>
        </div>
        <div className="flex items-center">
          <p className="w-40 font-light">Rate your product?</p>
          <RatingBar value={data.product_rate} onChange={val => onChange('product_rate', val)}/>
        </div>
        <div className="my-2">
          <p className="font-light">Help us get more products by sending a thank you note to the brands that support our community</p>
        </div>
        <div>
          <TextArea value={data.description} onChange={e => onChange('description', e.target.value)}></TextArea>
        </div>
        <div className={"flex items-center justify-between my-4"}>
          <div className={"flex items-center"}>
            <CustomCheckbox
              checked={Boolean(data.anonymous)}
              onChange={val => onChange('anonymous', val)}/>
            <p className={"ml-2"}>Anonymous</p>
          </div>
          <div>
            <BasicBlueRectButton onClick={onConfirm}>Confirm</BasicBlueRectButton>
          </div>
        </div>
      </DialogContent>

    </StyledDialog>
  );
};

export { AlertDialog, ConfirmDialog, FeedBackDialog, MessageDialog, SelectInput, SemiSelectInput, StyledDialog, StyledInput };

