import api from '../api';
import config from '../config';
import { postPromise } from '../base';
import { roles, userTypes } from '../../constants';
import { ROUTE_IDS, composeFormData } from './routeId';

const getGeoData = () => {
  const body = new FormData();
  body.append('type', 'getgeodata');
  return postPromise(config.API_BASE_URL, body).then((response) => {
    const canada = {};
    canada.name = response[0].Name;
    canada.id = response[0].id;
    canada.code = response[0].Internet;
    const cadRegions = new Map();
    response[0].subnodes.map((r) =>
      cadRegions.set(r.id, {
        name: r.Name,
        code: r['Region Code'],
        cities: new Map(
          r.subnodes.map((c) => [ c.id,
            { name: c.Name, code: c['city code'] },
          ])
        ),
      })
    );
    canada.regions = cadRegions;
    return canada;
  });
};

const honestGeoData = () => {
  const body = composeFormData({},ROUTE_IDS.GET_GEO_DATA)
  return postPromise(config.API_BASE_URL, body);
}


export { getGeoData, honestGeoData };
