import { useTranslation } from 'react-i18next';

/**
 * 
 * @param {string} page 
 * @returns {Object}
 */
export const useTranslations = (page) => {
  const { t } = useTranslation();
  return t(page, { returnObjects: true, fallbackLng: 'en', });
};