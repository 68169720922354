import useConfirmBDShipment from '@/hooks/state/donation/shipment/useConfirmBDShipment';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { arrayToObject, closeDialogWithRef, collapseObjIntoArray } from '@/utils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import React, { useEffect, useMemo } from 'react';
import { ConfirmDeliveryIcon } from '../Icons';
import { TableSkeleton } from '../Skeletons/TableSkeleton';
import { ConfirmShipmentTable } from '../Tables/SimpleTable/ConfirmShipmentTable';
import { BasicDialog } from './BasicDialog';


export const ConfirmShipmentDialog = ({dialogRef, brandorgid, communityState, ...props}) => {
    const {data: ctx} = useCurrentOrgContext() || {}

    const [values, setValues] = React.useState({})
    const valuesArray = useMemo(() => collapseObjIntoArray(values), [values])
    const confirmMutation = useConfirmBDShipment({
        brandorgid, 
        onSuccess: (data) => {
            SuccessToast.fire({text: 'Request Updated'})
            closeDialogWithRef(dialogRef)
        },
        onError: (error) => {FailPopup.fire({text: 'Failed to Update Request'})}
    })

    useEffect(() => {
        if (!communityState?.lineItems) return
        const confirmedArr = communityState.lineItems.map(v => ({inventoryid: v.inventoryid, confirmedQuantity: v.confirmedQuantity}))
        const confirmedObj = arrayToObject(confirmedArr, 'inventoryid')
        setValues(confirmedObj)
    }, [communityState])
        
    function onChange(changedValue) {
        setValues({
            ...values,
            ...changedValue
        })
    }

    function onSubmit() {
        const lineitems = Object.values(values)?.map(({inventoryid, confirmedQuantity}) => ({inventoryid, qtyconfirmed: confirmedQuantity}))
        
        const params = {eventid: communityState.eventid, recipientorgid: communityState.orgid, lineitems}
        confirmMutation.mutate(params)
    }

    
    return  (
        <BasicDialog 
            ref={dialogRef}
            id='shipmentForm'
            title="Confirm Shipment Arrival "
            className='w-[60rem] h-[41rem] min-h-fit overflow-hidden pb-20'
            hideActions
            {...props}
        >   
        {values && communityState?.lineItems?.length > 0 ? (
            <>
            { values &&
                <ConfirmShipmentTable 
                    values={values}
                    communityState={communityState}
                    onChange={onChange}
                    {...props}
            />
            }
                <div className="my-4 flex w-full justify-end">
                    
                    <button className="btn btn-secondary " onClick={onSubmit}>
                        Confirm Shipment Arrival
                        <ConfirmDeliveryIcon className='w-5 h-5'/>
                    </button>
                

                    <button 
                        className="btn btn-neutral ml-2" 
                        onClick={() => closeDialogWithRef(dialogRef)}
                    >
                        Cancel
                    </button>
                </div>
            </>
        ) : (
            <div className='form-control items-center w-full gap-14'>
                <div className="skeleton rounded-xl w-1/3 aspect-video"/>
                <TableSkeleton numRows={3} rowHeightClass='h-18'/>
            </div>
        )}
        </BasicDialog>
    ) 
};



