import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS } from "@/apis/base/routeId"
import { convertObjFieldsToString } from "@/utils"
import { useMutation, useQueryClient } from '@tanstack/react-query';


export const confirmPopupPickup = (params) => {
    params.type = ROUTE_IDS.CONFIRM_POPUP_PICKUP
    return new Promise((resolve, reject) => {
        postPromise(config.API_BASE_URL, convertObjFieldsToString(params), {json: true}).then((res) => {
            if (res?.status === "popup store pickup not confirmed.") reject(res)
            else resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })

}
