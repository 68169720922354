import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS,composeFormData } from "@/apis/base/routeId"
import { convertObjFieldsToString } from "@/utils";


export const modOrgData = (params) => 
    postPromise(config.API_BASE_URL,convertObjFieldsToString({type: ROUTE_IDS.MOD_ORG_DATA,...params}));


