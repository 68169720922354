import React from 'react';
import { withCookies } from 'react-cookie';
import { Redirect, Route, Switch } from 'react-router-dom';

import Footer from '../../../components/Footer';
import Sidebar from './components/Sidebar';

import routes from '../../../routes';

function Admin(props) {
  const { history } = props;

  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        if (prop.collapse) {
          return prop.children.map((child, key) => (
            <Route
              path={prop.layout + prop.path + child.path}
              component={child.component}
              key={key}
            />
          ))
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };

  return (
    <div className=" bg-base-200">
      <div className="flex min-h-screen ">
        <Sidebar history={history} />
        <div className="ml-10 lg:ml-50 w-mobile-admin-content lg:w-admin-content min-h-content  px-12 mb-5">
          <Switch>
            {getRoutes()}
            <Redirect from="/admin" to="/admin/uic" />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withCookies(Admin);
