import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const DonationContext = createContext();

const DonationContextProvider = ({ children }) => {
  // Donations Contexts
  const [donations, setDonations] = useState([]);
  const [closedDonations, setClosedDonations] = useState([]);
  const [pickupDonations, setPickupDonations] = useState([]);
  const [processor, setProcessor] = useState("");
  const [brandDonationSearchKey, setBrandDonationSearchKey] = useState('');
  const [brandDonationSearchFlag, setBrandDonationSearchFlag] = useState(true);
  const [isOverallWeightCost, setIsOverallWeightCost] = useState(false);

  return (
    <DonationContext.Provider
      value={{
        donations, setDonations,
        pickupDonations, setPickupDonations,
        closedDonations, setClosedDonations,
        processor, setProcessor,
        brandDonationSearchKey, setBrandDonationSearchKey,
        brandDonationSearchFlag, setBrandDonationSearchFlag,
        isOverallWeightCost, setIsOverallWeightCost,
      }}
    >
      {children}
    </DonationContext.Provider>
  );
};

DonationContextProvider.propTypes = {
  children: PropTypes.object,
};

export default DonationContextProvider;
export const useDonationContext = () => useContext(DonationContext);
