import { BasicTable } from "../BasicTable"
import { UICAllOrgColummnHelper } from "./UICAllOrgColummnHelper"


export const UICAllOrgTable = ({ orgs, visibleTypes,...props }) => {
    const safeData = orgs?.length > 0 ? orgs : []
    return (
        <BasicTable 
            detachChildrenOnParentFilter
            horizontalScrolling={false}
            extraFilters={[
                {id: 'Type', value: visibleTypes}
            ]}
            state={{
                columnVisibility: {'Type': false}
            }}
            tableProps={{
                filterFromLeafRows: true,
            }}
            canExpand
            columnHelper={UICAllOrgColummnHelper}
            data={safeData}
            {...props}
        />
    )
}