

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { modMyUserData } from '@/apis/base/user/modMyUserdata';
import { getCookie } from '@/utils';


export default function useModMyUserData({...props}) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient()
    const query = useMutation({
        mutationKey: [ROUTE_IDS.MOD_MY_USER_DATA],
        mutationFn: modMyUserData,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({ queryKey: ['user', req?.userid]})
            client.invalidateQueries({ queryKey: ['user', 'me']})
            client.invalidateQueries({ queryKey: ['org', getCookie('orgIdContext')]})
            if (!!onSuccess) props.onSuccess(data,req);
          },
        ...otherProps
    });
    return query;
}