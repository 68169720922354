import config from '@/apis/config';
import moment from 'moment';
import {
  MILLISEC,
  PERMISSION,
  TIME_UNIT,
  UIC_ORG
} from '../constants';

const getCookie = (cookiename) => {
  let cookiestring = RegExp(cookiename + '=[^;]+').exec(document.cookie);
    const decoded = decodeURIComponent(
      cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : null
    );
    if (decoded === 'null' ) return null
    if (decoded === 'true' ) return true
    if (decoded === 'false' ) return false
  return decoded
};

const setCookie = (name, value, path='/') => {
  const expiryDate = new moment().add(1, 'years').toDate();
  document.cookie = name + '=' + value + ';' + expiryDate + `;path=${path}`;
  // console.log('new cookie is ', getCookie(name));
};

const setRedirectPathCookie = () => {
  setCookie('redirect',window.location.pathname,{path:'/'})
}

function camelCase(str) {
  if (!(typeof str === 'string') || str instanceof String) return
  const ret =  str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
  return ret
}


const printObject = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    console.log(`${key}: ${value}`);
  }
}

const printFormData = (fd) => {
  for (const [key, value] of fd.entries()) 
    console.log(`${key}: ${value}`);
}

function rangeArray( start, end )  {
  if (start > end) return []
  if (Number.isNaN(start) || Number.isNaN(end)) return []
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}


const deleteCookie = (name) => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
}

const deleteAllCookies = () => {
  let cookies = document.cookie.split(';');
  // console.log("nuking cookies", cookies);
  for (const cookie of cookies) console.log(cookie);
  
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let name = cookie.split('=')[0];
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
  }
};

const maxFINItems = (dependentData) => {
  // console.log('dependentData', dependentData);
  return (dependentData?.[0]?.uniqueDependents?.length || 0) + 1
}

const validateEmail = (value) => {
  let emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

const validateEmailSuffix = (email, suffix) => {
  // console.log(email, suffix);
  if (!email) {
    console.log('cannot validate a null email');
    return false;
  }
  if (!suffix) {
    return true
  }
  if (suffix == null || email.endsWith(suffix)) {
    return true;
  }
  return false;
};

function getUserLabelInOrg(user, org) {
  // console.log("user", user)
  if (String(org?.owner) === String(user?.id)) return 'Master Admin';
  if (org?.admins?.some?.(a => String(a.id) === String(user?.id))) return 'Admin';
  if (!validateEmailSuffix(user?.email, org?.suffix)) return 'Guest';
  return 'Member';
}

function timeStampIsExpired(timestamp) {
  return Date.now() - Number(timestamp) * 1000 > (MILLISEC.MONTH * 3);

}


const getDateString = (timestamp) => {

  var date = moment.unix(timestamp);
  var local = date.local().format('ll');
  return local
  // return timestamp?.length ? local : '';
};

function getMonthDayString(timestamp) {
  var date = moment.unix(timestamp);
  var local = date.local().format('MMM DD');
  return local
}

const countOrgMembers = (org) => 
  org?.members?.length ?? 0

const countOrgFinMembers = (org) =>
  org?.members?.filter?.(member => member?.FIN === 'Approved')?.length ?? 0

const getAvatarUrl = (id) => {
return `${config.IMAGES_URL}/users/${id}/avatar` 
}

const getBannerUrl = (id) => {
  return `${config.IMAGES_URL}/organizations/${id}/banner`
}

const getSplashUrl = (id) => {
  return `${config.IMAGES_URL}/organizations/${id}/splash`
}

const getLogoUrl = (id) => {
  return `${config.IMAGES_URL}/organizations/${id}/logo`
}

const getEventUrl = (id,idx) => {
  return `${config.IMAGES_URL}/events/${id}/${idx}`
}

const getEventInventoryUrl = ( eventID, id,idx) => { 
  return `${config.IMAGES_URL}/events/${eventID}/inventory/${id}/${idx}`
}

const getProductUrl = (id,idx) => {
  return `${config.IMAGES_URL}/products/${id}/${idx}`
}
const getWishlistUrls = (images) => {
  if (!(images && images?.length > 0)) return []
  const ret =  images?.map(img => 
    img?.name?.startsWith?.('bd') || img?.name.startsWith('https://d2usum1uink89')
      ? `${config.WISHLIST_URL}/${img?.name}`
      : `${config.IMAGES_URL}/${img?.name}`
    )
  return ret
 }
const getV2WishlistUrls = (id,numImg) => (
  [...Array(numImg).keys()]
    .map(idx => `${config.IMAGES_URL}/products/${id}/${idx}`)
)


const getTextSize = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return Array.isArray(text) ? 
    text.map(t => context.measureText(t).width) 
    : context.measureText(text).width; 
}

const getElementTextSize = (text, element) => {
  if (!(element instanceof Element)) {
    // console.log('not an element ', element);
    return 0
  }
  const {font} = getComputedStyle(element)
  return getTextSize(text, font)
}

  const inputDateFromTimestamp  = (timestamp) => {
    var date = moment.unix(Number(timestamp));
    var local = date.local().format('ll');
    
    return timestamp?.length ? local : '';
  
  }

const getNiceDateString = (timestamp, short=false) => {
  var date = moment.unix(timestamp);
  if (short) return date.local().format('ll')
  var local = date.local().format('LL');
  return local
}

const getDashedDateString = (timestamp) => {
  var date = moment.unix(timestamp);
  var local = date.local().format('yyyy-MM-DD');
  return local
  // return timestamp?.length ? local : '';
}

const getDateTimeString = (timestamp) => {
  var date = moment.unix(timestamp);
  var local = date.local().format('lll');
  return timestamp?.length ? local : '';
};

const getTimeSince = (timestamp) => {
  if (!timestamp) return ''
  const date = Date.now() / 1000
  const elapsed = date - timestamp
  if (elapsed < MILLISEC.MIN)
    return dateDifference(timestamp, date, {unit: TIME_UNIT.SEC, roundDown:true}) + ' seconds'
  if (elapsed < MILLISEC.HOUR)  
    return dateDifference(timestamp, date, {unit: TIME_UNIT.MIN, roundDown:true}) + ' minutes'
  if (elapsed < MILLISEC.DAY) 
    return dateDifference(timestamp, date, {unit: TIME_UNIT.HOUR, roundDown:true}) + ' hours'
  if (elapsed < MILLISEC.WEEK)  
    return dateDifference(timestamp, date, {unit: TIME_UNIT.DAY, roundDown:true}) + ' days'
  if (elapsed < MILLISEC.MONTH) 
    return dateDifference(timestamp, date, {unit: TIME_UNIT.WEEK, roundDown:true}) + ' weeks'
  if (elapsed < MILLISEC.YEAR)  
    return dateDifference(timestamp, date, {unit: TIME_UNIT.MONTH, roundDown:true}) + ' months'
  return dateDifference(timestamp, date, {unit: TIME_UNIT.YEAR, roundDown:true}) + 'yYears'
}

const dateDifference = (start, end, config={unit: TIME_UNIT.MIN, roundDown:true} ) => {
  const {unit, roundDown} = config
  let since = 0
  switch (unit) {
    case TIME_UNIT.SEC:
      since = (end - start) / MILLISEC.SEC
      return roundDown ? Math.floor(since) : since
    case TIME_UNIT.MIN:
      since = (end - start) / MILLISEC.MIN
      return roundDown ? Math.floor(since) : since
    case TIME_UNIT.HOUR:
      since = (end - start) / MILLISEC.HOUR
      return roundDown ? Math.floor(since) : since
    case TIME_UNIT.DAY:
      since = (end - start) / MILLISEC.DAY
      return roundDown ? Math.floor(since) : since
    case TIME_UNIT.WEEK:
      since = (end - start) / MILLISEC.WEEK
      return roundDown ? Math.floor(since) : since
    case TIME_UNIT.MONTH:
      since = (end - start) / MILLISEC.MONTH
      return roundDown ? Math.floor(since) : since
    case TIME_UNIT.YEAR:
      since = (end - start) / MILLISEC.YEAR
      return roundDown ? Math.floor(since) : since
  }
}

const getS3BucketUrl = (url) => {
  return 'https://uic-beta.s3.amazonaws.com/' + url;
};

const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

const nameCompare = (a, b) => {
  return (a.firstname + ' ' + a.lastname).localeCompare(
    b.firstname + ' ' + b.lastname
  );
};

const userTypeCompare = (a, b) => {
  let types = [
    'master_admin',
    'uic_admin',
    'admin',
    'ambassador',
    'member',
    'student',
  ];
  return types.findIndex((el) => el === a.user_type) -
    types.findIndex((el) => el === b.user_type) >
    0
    ? 1
    : -1;
};

const boolCompare = (a, b) => {
  return a === b ? 0 : a && !b ? 1 : -1;
};


const routeFromOrg = (org) => {  
  const id = org?.orgid || org?.id
  const typeId = org?.['org type id']

  if (id === UIC_ORG) return '/admin'
  if (org.isBrand) return '/brand'
  if (org.isProcessor) return '/processor'
  return '/'
  
}

const getPageAvailability = (ctx, showLogo=false) => {
  const isUICAdmin = ctx?.['admin level'] === PERMISSION.UIC_ADMIN
  const isAdmin = ctx?.isAdmin || false;
  const isBrand = ctx?.brand || false;
  const standardCommunity = ctx?.standardCommunity || false;
  const finView = standardCommunity && ctx?.finStatus === 'Approved'
  const ambView = standardCommunity && ctx?.ambStatus === 'Approved'
  const availability = {}
  availability['home'] = true
  availability['news'] = standardCommunity
  availability['members'] = standardCommunity
  availability['donation'] = (standardCommunity && isAdmin) || finView || ambView
  availability['wishlist'] = (standardCommunity && isAdmin) || finView
  availability['organization-preferences'] = true
  availability['header-logo'] = (!isBrand && !ctx?.processor) || (showLogo)
  availability['admin'] = isUICAdmin
  availability['brand'] = isBrand
  availability['processor'] = ctx?.processor
  availability['fakeheaderitems'] = !standardCommunity
  return availability
};


const handleCopyInviteLinkToClipBoard = (orgId) => {
  navigator.clipboard.writeText(
    config.SITE_URL + `/auth/signup?organization=${orgId}`
  );
  // setInviteLinkCopied(orgId);

  // setTimeout(() => {
  //   setInviteLinkCopied(-1);
  // }, 5000);
};

const getUserTypeName = (user, context) => {
  if (user?.['admin level'] === PERMISSION.UIC_ADMIN) return 'UIC Admin';
  else if (context?.owner === user?.id) return 'Master Admin';
  else if (context?.admins?.map(o=>o.id)?.includes?.(user.id)) return 'Admin';
  else if (user?.email.endsWith(context?.suffix)) return 'Member'
  else return 'Guest';
};

const getAgnosticUserType = (user) => {
  if (user?.['admin level'] === PERMISSION.UIC_ADMIN) return 'UIC Admin'
  else return 'User'
}

const getRoleName = (roleEnumQueryData, roleID) => {
  switch (roleID) {
    case roleEnumQueryData.fin:
      return 'Family In Need';
    case roleEnumQueryData.ambassador:
      return 'Ambassador';
    case roleEnumQueryData.guardian:
      return 'Guardian';
    case roleEnumQueryData.smi:
      return 'Social Media Influencer';
  }
};

const requestTypeToLabel = (roleEnumQueryData, type) => {
  switch (type) {
    case roleEnumQueryData.fin:
      return 'Family In Need';
    case roleEnumQueryData.ambassador:
      return 'Ambassador';
    case roleEnumQueryData.guardian:
      return 'Guardian';
    case roleEnumQueryData.smi:
      return 'Social Media Influencer';
    default: 
    return ''
  }
}

const requestStatusToLabel = (status, statuses) => {
  switch (status) {
    case statuses.approved:
      return 'Approved';
    case statuses.declined:
      return 'Declined';
    case statuses.pending:
      return 'Pending';
    default: 
    return ''
  }
}

const ensureToken = () => getCookie('token') != null

const isIterable = (obj) => {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}


const jsonToFormData = (json) => {
  const formData = new FormData();
  Object.keys(json).forEach((key) => {
    if (json[key] instanceof Array) 
      addArrayToFormData(key, json[key], formData) 
    else
      formData.append(key, String(json[key]))
  });
  return formData;
};

const objectFromForm = (form, onlyDirty=false) => {
  return Object.fromEntries(
    Object.values(form).reduce((acc, el) => {
      const value = el?.type === 'date' ? el?.valueAsNumber : el?.value
      if (onlyDirty && el?.defaultValue === value) return acc
      acc.push([el?.name, value])
      return acc
    }, [])
  )}

const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

const filesToDataUri = (files) => {
  return Promise.all(files.map(fileToDataUri));
}

const formDataToJson = (data) => {
  const json = {};
  for (const [key, value] of data.entries()) {
    json[key] = value;
  }
  return json;
};

const addArrayToFormData = (fieldName, array, formData) => {
    for (let i = 0; i < array.length; i++) {
        formData.append(fieldName, String(array[i]))
    }
    return formData
}

const convertObjFieldsToString = (obj) => {
    Object.keys(obj).forEach(k => {
      if (typeof obj[k] === 'object') {
        return obj[k]?.toString?.();
      }
      obj[k] = '' + obj[k];
    });
    
    return obj;
}

const flattenOrgs = (queryData) => {
  if (!queryData || !(queryData.length > 0)) return []
  let orgs = []
  const orgidSet = new Set()
  flattenOrgsHelper(queryData, orgs, orgidSet)
  return orgs
}

const flattenOrgsHelper = (queryData, orgsArr, orgidSet) =>   {
  if (!queryData || !(queryData.length > 0)) return 
  queryData.forEach(org => {
    if (orgidSet.has(org.id)) return
    orgidSet.add(org.id)
    orgsArr.push(org)
    if (org?.subnodes?.length > 0) {
      flattenOrgsHelper(org.subnodes, orgsArr, orgidSet)
    }
  })
}

const flattenSubnodes = ({topItem, includeTopItem=false, filterFn = null, inheritKeys = []}) => {
  if (!(topItem?.subnodes?.length > 0)) throw new Error('topItem must have subnodes')

  const ret =  filterFn == null ? 
    topItem.subnodes.reduce((acc, curr) => {
      inheritKeys.forEach(keyObj => curr[keyObj.childKey] = topItem[keyObj.parentKey])
      acc.push(curr)
      return acc
  },[]) : 
    topItem.subnodes.filter(filterFn)
      .reduce((acc, curr) => {
        inheritKeys.forEach(keyObj => curr[keyObj.childKey] = topItem[keyObj.parentKey])
        acc.push(curr)
        return acc
  },[]) 
    return includeTopItem ? [...ret,topItem] : ret
}

const recursiveFlattenArraySubnodes = ({
  topArr, 
  filterFn = null,
  startingArr=[]}) => {
    if (!(topArr?.length > 0)) throw new Error('mainArr must not be empty')
    return topArr.reduce((acc, curr) => {
      if (curr?.subnodes?.length > 0) {
        recursiveFlattenArraySubnodes({
          topArr: curr.subnodes, filterFn, startingArr: acc
        })
      }
      acc.push(curr)
      return acc
    }, startingArr)
}

const recursiveFlattenSubnodes = ({topItem,  filterFn = null, inheritKeys, mapFields = null, includeParent=true}) => {
  if (!(topItem?.subnodes?.length > 0)) throw new Error('topItem must have subnodes')
  let mainArr = includeParent ? 
    mapFields?.length > 0 ?
        pick(topItem, ...mapFields) 
        : topItem
    : []
  if (Array.isArray(mainArr))
    mainArr.forEach(el => inheritKeys.forEach(keyObj => el[keyObj.childKey] = topItem[keyObj.parentKey]))
  else
    mainArr = [...mainArr]
  recursiveFlattenSubnodesHelper(topItem, filterFn, inheritKeys, mapFields, mainArr)
  return mainArr
}

const recursiveFlattenSubnodesHelper = (currItem, filterFn, inheritKeys, mapFields,mainArr) => {
  if (!(currItem?.subnodes?.length > 0)) return 
  // const filteredNodes = filterFn == null ? currItem.subnodes : currItem.subnodes.filter(filterFn)
  return currItem?.subnodes.reduce((acc,node) => {
    const trimmedNode = mapFields?.length > 0 ?
        pick(node, ...mapFields) : node
    inheritKeys?.length > 0 && inheritKeys.forEach(keyObj => {
        Array.isArray(trimmedNode) ?
          trimmedNode.forEach(el => el[keyObj.childKey] = currItem[keyObj.parentKey]) :
          node[keyObj.childKey] = currItem[keyObj.parentKey]
      })
    acc.push(trimmedNode)
    recursiveFlattenSubnodesHelper(node, filterFn, inheritKeys, mapFields, mainArr)
  },mainArr) 
}

const recursiveNodeToArray = ({topNode, filterFn, transformFn, inheritKeys,  targetField, includeParent}) => {
  const extraData = {}
  inheritKeys.forEach(keyObj => extraData[keyObj.childKey] = topNode[keyObj.parentKey])
  let mainArr = topNode?.[targetField]
  mainArr.forEach(el => {
    Object.assign(el,extraData)
    if (transformFn) transformFn(el)
  })
if (!(topNode?.subnodes?.length > 0)) return mainArr
  recursiveNodeToArrayHelper({currItem: topNode, filterFn, transformFn, inheritKeys, targetField, mainArr})
  return mainArr
}

const recursiveNodeToArrayHelper = ({currItem, filterFn, transformFn, inheritKeys, targetField,mainArr}) => {
  if (!(currItem?.subnodes?.length > 0)) return 
  return currItem?.subnodes.reduce((acc,node) => {
    const extraData = {}
    inheritKeys.forEach(keyObj => extraData[keyObj.childKey] = node[keyObj.parentKey])
    const arr = node[targetField]
    arr.forEach(el => {
      Object.assign(el,extraData)
      if (transformFn) transformFn(el)
    })
    acc.push(...arr)
    recursiveNodeToArrayHelper({currItem: node, filterFn, transformFn, inheritKeys, targetField, mainArr})
    return acc
  },mainArr) 
}

function recursiveSubnodeHelper(topNode, fn, passArray=false, includeTopNode=true) {
  if (passArray) 
    includeTopNode && topNode?.subnodes && fn(topNode.subnodes)
  else fn(topNode)
  if (!topNode?.subnodes) return
  if (passArray) {
    if (!includeTopNode) fn(topNode.subnodes)
    topNode?.subnodes?.forEach?.(node => recursiveSubnodeHelper(node, fn, true, false))
    return topNode?.subnodes
  }
  const ret = 
   topNode?.subnodes?.reduce?.((acc,node) => {
    recursiveSubnodeHelper(node, fn, false, true)
    acc.push(node)
    return acc
  },[]) 
  return ret
}

function tailRecursiveSubnodeHelper(topNode, fn, passArray, includeTopNode=true) {
  console.log('helper running, topNode', topNode);
  let safeTopArray = Array.isArray(topNode) ? topNode : [topNode]
  if (!safeTopArray?.length) return
  if (passArray) {
    for (const node of safeTopArray) {
      node.subnodes?.forEach?.(node => tailRecursiveSubnodeHelper(node?.subnodes, fn, true, true))
      if (includeTopNode) fn(safeTopArray)
    }
  } else {
    for (const node of safeTopArray) {
      node.subnodes?.forEach?.(node => tailRecursiveSubnodeHelper(node, fn, false, true))
      if (includeTopNode) fn(node)
    }
  }
}


function clamp(num, min=0, max=1) {
  return num <= min ? min : num >= max ? max : num
}

function fuzzySort (s) {
  let hay = this.toLowerCase(), i = 0, n = -1, l;
  s = s.toLowerCase();
  // eslint-disable-next-line no-cond-assign
  for (; l = s[i++] ;) if (!~(n = hay.indexOf(l, n + 1))) return false;
  return true;
}

// Get only keys that exist in obj
const pick = (obj, ...keys) => {
  const fObj = Object.fromEntries(
    keys
      .filter(key => key in obj)
        .map(key => [key, obj[key]])
  )
  return keys?.length === 1 ? fObj[keys[0]] : fObj
};

function subObject(obj, keys) {
  // console.log(keys.reduce((a, c) => obj[c] ? (a[c] = obj[c], a) : a, {}));
  return keys.reduce((a, c) => obj[c] ? (a[c] = obj[c], a) : a, {})
}

function dateIsBetween(date, start, end) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const tergetDate = new Date(date)
  return tergetDate >= startDate && tergetDate <= endDate
}

function dateIsBefore(date1, date2) {
  return new Date(date1) < new Date(date2);
}

function dateIsAfter(date1, date2) {
  return new Date(date1) > new Date(date2);
}

function dashedDateIsValid(date) {
  if (!date) {
    // console.log('Cannot validate undefined date')
    return false
  }
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!date.match(regEx)) return false;  // Invalid format
  var d = new Date(date);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === date;
}

function trimZerosAndDec(value)  {
  let newVal = value.replace(/^0+/,'')
  if (newVal === '')  newVal = '0'
  return newVal
}

function roundTo(value, places = 2) {
  var power = Math.pow(10, places || 0);
  return Math.round(value * power) / power;
}

const collapseObjIntoArray = (obj, keyFieldName) => (
  Object.entries(obj || {}).reduce((acc,[key, value]) => {
    if (!Array.isArray(value))  {
      if (keyFieldName) acc.push({...value, [keyFieldName]: key})
      else acc.push(value)
    }
    return acc
  },[]
))

const arrayToObject = (arr, keyFieldName) => {
  const obj = {}
  if (!Array.isArray(arr)) return obj
  arr.forEach(el => {
    obj[el?.[keyFieldName]] = el
  })
  return obj
}

const openDialogWithRef = (ref) => {
  if (!ref || !ref.current) return
  ref.current.showModal()
}

const closeDialogWithRef = (ref) => {
  if (!ref || !ref.current) return
  ref.current.close()
}

const resetFormWithRef = (ref) => {
  if (!ref || !ref.current) return
  ref.current.reset()
}

const resetFormWithId = (id) => {
  const form = document.getElementById(id)
  if (!form) return
  form?.reset?.()
}

function capitalizeFirst(str) {
  if (!(typeof str === 'string' || str instanceof String)) return str
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function numberWithCommas(x) {
  // console.log(x);
  const safeNum = String(x || 0)
  var parts = safeNum.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  parts[1] = parts[1]?.substring?.(0,2) 
  
  return (parts[1] != null || parts[0].slice(-1) === '.') ? parts[0] + "." + parts[1] : parts[0];
}

function addCommas(num, delimiter=' ') {
  const safeNum = String(num || 0)
  return safeNum.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1,${delimiter}`)
}

function ordinalIndicator(num) {
  const rem = num % 10
  switch (rem) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

function arrayRemove(arr, index) {
  var idx = index
  arr?.splice?.(idx, 1);
}

function arrayFindRemove(arr,value,  compareFn = (v) => v === value) {
  var idx = arr?.findIndex(compareFn)
  if (idx !== -1) {
    arr?.splice?.(idx, 1);
  } 
  // else {console.log('couldn\'t find it');}

}


function prettyPrint(obj) {
  console.log(JSON.stringify(obj, null, 2))
}

const between = (val, min, max) => 
   val >= Number(min) && val <= Number(max)

const strToStatus = (str) => str === '1' ? 'Active' : 'Inactive';

// function $id(id) { return document.getElementById(id); }

// function htmlFormToJson(frmname) {
//   function $id(id) { return document.getElementById(id); }
// 	var obj = {}; var elements = $id(frmname).querySelectorAll( "input, select, textarea, checkbox, radio" );
// 	for (var i = 0; i < elements.length; ++i) {
// 		var element = elements[i]; var key = element.name; var value = element.value;
// 		if (key) {	if (element.type != 'checkbox') { obj[key] = value; }
// 					else {	if (key.indexOf('[]') < 0) {	chk = (element.checked) ? 1 : 0; obj[key] = chk; }
// 							else {	if (element.checked) {	if (!obj.hasOwnProperty.call(key)) { obj[key] = value; }
// 															else { obj[key] += "~"+value; } } } } } }
// 	return obj; }

export {
  addArrayToFormData, addCommas, arrayRemove, arrayToObject, between, boolCompare, camelCase, capitalizeFirst, clamp, closeDialogWithRef, collapseObjIntoArray, convertObjFieldsToString, countOrgFinMembers, countOrgMembers, dashedDateIsValid, dateIsAfter, dateIsBefore, dateIsBetween, deleteAllCookies, deleteCookie, ensureToken, fileToDataUri, filesToDataUri, flattenOrgs, flattenSubnodes, formatPhoneNumber, fuzzySort, getAgnosticUserType, getAvatarUrl,
  getBannerUrl, getCookie, getDashedDateString, getDateString, getDateTimeString, getElementTextSize, getEventInventoryUrl, getEventUrl, getLogoUrl, getNiceDateString, getPageAvailability, getProductUrl, getRoleName, getS3BucketUrl, getSplashUrl, getTextSize, getTimeSince, getUserLabelInOrg, getUserTypeName, getV2WishlistUrls, getWishlistUrls, handleCopyInviteLinkToClipBoard, inputDateFromTimestamp, isIterable, jsonToFormData, maxFINItems, nameCompare, numberWithCommas, objectFromForm, openDialogWithRef, ordinalIndicator, pick, prettyPrint, printFormData, rangeArray, recursiveFlattenArraySubnodes, recursiveFlattenSubnodes,
  recursiveNodeToArray, recursiveSubnodeHelper, requestStatusToLabel, requestTypeToLabel, resetFormWithId, resetFormWithRef, roundTo, routeFromOrg, setCookie, strToStatus, subObject, tailRecursiveSubnodeHelper, timeStampIsExpired, trimZerosAndDec, userTypeCompare, validateEmail, validateEmailSuffix, getMonthDayString, setRedirectPathCookie
};

