import { getRoleApp } from '@/apis/base/applications/getRoleApp';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { ensureToken } from '@/utils';
import { queryOptions, useQuery } from '@tanstack/react-query';
import useRoleEnum from '../enum/useRoleEnum';
import { notNull } from '@/utils/misc';

export default function useGetRoleApps({orgid,...params}) {
    const rEnumQuery = useRoleEnum()

    const query = useQuery(orgRoleAppQueryConfig({roleids: rEnumQuery.data.allRoles, orgid, ...params}));
    return query;
}

export const orgRoleAppQueryConfig = ({roleids, orgid, ...params}) => queryOptions({
    queryKey: ['context','role','org', orgid, ROUTE_IDS.GET_ROLE_APPLICATIONS, {roleids}],
    queryFn: () => getRoleApp({'roleids[]': roleids, ...params,orgid}),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    staleTime: MILLISEC.SEC * 3,
    gcTime: MILLISEC.SEC * 5,
    refetchOnReconnect:true,
    structuralSharing: false,
    refetchInterval: MILLISEC.MIN_5,
    notifyOnChangeProps: ['data'],
    enabled: ensureToken() && 
            notNull(orgid) && 
            roleids != null,
    select
})

function select(data) {
    data.finApp = data.fin?.FIN;
    data.ambApp = data.ambassador?.Ambassador;
    data.smiApp = data.smi['Social Media Influencer']
    return data
}