
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { transferOwnership } from '@/apis/base/admin/transferOwnership';

export default function useTransferOrgOwnership({contextOrgID,...props}) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.TRANSFER_ORG_OWNERSHIP],
        mutationFn: transferOwnership,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => { 
            console.log('req orgid is ', req.orgid);
            if (data?.status !== 'organization ownership transferred') throw new Error(data?.status)
            if (String(req.orgid) === String(contextOrgID))
                client.invalidateQueries({queryKey: ['context','org', req.orgid]})
            client.invalidateQueries({queryKey: ['org', req.orgid]})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}