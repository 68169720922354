import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { TrackedImage } from '@/components/Image/TrackedImage';
import { getLogoUrl, getSplashUrl } from '@/utils';
import { abbreviateNumber } from '@/utils/textUtils';
import { LockIcon, UnlockIcon } from '@/views/pages/Main/Account/components/Icons';
import React from 'react';


export const CollapseOrg = ({org, joinOrg, isJoined=false, ...props}) => {
    if (!org) return null
    const isPrivate = org.private === '1'
    return (

        <div className='collapse collapse-plus group bg-base-200  w-full'>
            <input type="radio" name='org-accordion'/>
            <div className="collapse-title has w-full flex justify-start items-start">
                <TrackedImage 
                    className='rounded-md  w-20 max-h-20  object-cover'
                    src={getLogoUrl(org.id)}
                    onError={(e) => e.currentTarget.classList.add('invisible')}
                />
                <div className="flex ml-3 xl:ml-6">
                    <div className="form-control">
                        <div className='flex '>
                            <p className=' font-semibold text-xl xl:text-2xl leading-tight -mt-1'>
                                {org.name}                    
                            </p>
                    {isPrivate ? ( 
                            <LockIcon 
                                fill='fill-yellow-600'
                                stroke='stroke-gray-500'
                                className='w-4 h-4 ml-2  text-red-300 '
                            />
                        //<div className='badge  ml-2 w-fit badge-neutral'>
                        //    Private
                        //</div> *
                    ) : (
                            <UnlockIcon className='w-4 h-4  ml-2 fill-yellow-600 stroke-gray-500'/>
                        //<div className='badge  ml-2 w-fit badge-success'>
                        //    <LockIcon 
                        //        fill='fill-yellow-600'
                        //        stroke='stroke-gray-500'
                        //        className='w-4 h-4 mr-1  text-red-300 '
                        //    />
                        //    public
                        //</div>
                    )}
                        </div>
                        <p className='font-medium'>
                            {org?.['city name']}, {org?.['region name']} – {org?.['org type name']}
                        </p>
                    { 
                        //org.description && org.description != '' && (
                        org.description && (
                        <>
                            {/* <span className='text-lg font-semibold'>Description</span> */}
                            <span className='text-gray-subText  max-w-100 group-has-[:checked]:line-clamp-none  line-clamp-2 overflow-ellipsis will-change-auto'>
                                {org?.description || 'Deserunt enim ullamco ea fugiat exercitation eiusmod officia fugiat velit pariatur. Elit irure id ad consectetur incididunt labore Lorem aliqua Lorem.'}
                            </span>
                        </>
                    )}
                    <span>{abbreviateNumber(org.members + org.admins ?? 0)} Members</span>
                    </div>
                </div>
            </div>
            <div className="collapse-content pb-0 flex">
                <div className="flex flex-col w-fit-content min-w-[15rem] mr-10">
                    {   org.website && (
                        <div className='mb-3'>
                            <p className='text-lg font-semibold'>Website</p>
                            <a className='link text-blue-500' target='_blank ' href={`https://${org.website}#link`}>{org.website}</a>
                        </div>
                    )}
                    { isJoined ? (
                    <BasicRectButton 
                        onClick={() => joinOrg(org.id)}
                        customSize
                        
                        className='w-fit-content disabled pointer-events-none btn-primary btn-outline text-md mt-auto'>
                            Joined
                        </BasicRectButton>
                    ) : (
                    <BasicRectButton 
                        onClick={() => joinOrg(org.id)}
                        customSize
                        className='w-fit-content btn-primary text-md mt-auto'>
                        Join
                    </BasicRectButton>

                    )}

                </div>
                <TrackedImage 
                    src={getSplashUrl(org.id)}
                    className='rounded-md   w-40 h-40 md:w-64 md:h-64 object-cover max-w-full'
                    onError={(e) => e.currentTarget.classList.add('hidden')}
                />
            </div>

        </div>

    );
};

