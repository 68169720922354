import React, { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import { OrgDropdown } from '../dropdowns/OrgDropdown';
import { InputBase } from './InputBase';


export const BasicOrgDropdown = forwardRef(function({
    orgs = [],
    initialValue,
    extraValues,
    label=null,
    name,
    className, 
    isError=false,
    errorText,
    required=false,
    onChange=()=>{},
    containerClassName, 
    disabled=false,
    ...props}, ref)  {
        return (
            <InputBase 
                {...{
                    label,
                    isError,
                    errorText,
                    required,
                    containerClassName,
                }}
            >
                <OrgDropdown 
                    isError={isError}
                    extraValues={extraValues}
                    onChange={onChange}
                    orgs={orgs}
                    disabled={disabled}
                    className={`
                        border-[1px]
                        focus:border-primary    
                        disabled:opacity-30
                        ${isError ? 'input-error' : 'input-bordered'}
                        ${disabled ? 'input-disabled bg-gray-100 border-none' : ''}
                        ${className}
                    `}
                    initialValue={initialValue}
                    {...props}
                />
            </InputBase>
        )
});

// TODO add extravalues to FormBasicOrgDropdown as seen above
export const FormBasicOrgDropdown = forwardRef(function({
    orgs = [],
    initialValue = {},
    label=null,
    name,
    className, 
    required=false,
    onChange=()=>{},
    containerClassName, 
    control,
    disabled: controlledDisabled=false,
    ...props}, ref)  {
        const {field, fieldState: {error}, formState: { disabled }} = 
            useController({name, control, defaultValue: initialValue})
        const isDisabled = controlledDisabled || disabled
        return (
            <InputBase 
                {...{
                    label,
                    isError: error!=null,
                    errorText: error?.message,
                    required,
                    containerClassName,
                    disabled: isDisabled
                }}
            >
                <OrgDropdown
                    controlled
                    onChange={field.onChange}
                    orgs={orgs}
                    value={field.value}
                    disabled={isDisabled}
                    isError={error!=null}
                    errorText={error?.message}
                    className={`
                        border-[1px]
                        border-gray-border
                        focus:border-primary
                        disabled:opacity-30
                        ${isDisabled ? 'input-disabled bg-gray-100 border-none' : ''}
                        ${className}
                    `}
                    initialValue={initialValue}
                    {...props}
                />
            </InputBase>
        )
});