import React, { useEffect } from "react";

import { useUserContext } from "@/context/UserContext";
import { useTranslations } from "@/hooks/translations";

import { PageTitle } from "@/components/Label/PageTitle";
import { PERMISSION } from "@/constants";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { withCookies } from "react-cookie";
import { useAppContext } from "../../../../context/AppContext";
import SubPages from "./components/pages";

function Members(props) {
  const { cookies } = props;
  const text = useTranslations("members");
  const { userInfo } = useUserContext();
  const { setRoles } = useAppContext();
  const contextQuery = useCurrentOrgContext({})
  const types = [
    { id: 1, name: "Student in Need" },
    { id: 2, name: "Ambassador" },
    { id: 3, name: "Student" },
  ];
  const states = [
    { id: 1, name: "Active" },
    { id: 2, name: "Paused" },
    { id: 3, name: "Inactive" },
    { id: 4, name: "Closed" },
  ];

  useEffect(() => {
    if (cookies.get('roles')) {
      setRoles(cookies.get('roles'));
    } else {
      setRoles(false);
    }
  }, [cookies, setRoles]);

  return (
    contextQuery.isSuccess && (
    <div className="w-full min-h-content bg-base-200 flex justify-center py-12">
      <div className="container">
        <div className="w-full h-full px-4 sm:px-6 xl:px-8">
            <PageTitle>{text.title}</PageTitle>
            <SubPages
              text={text}
              context={contextQuery.data}
              types={types}
              states={states}
            />
        </div>
      </div>
    </div>

    )
  );
}

export default withCookies(Members);
