import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BasicBackButton } from '@/components/Buttons/BasicBackButton';
import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { useAppContext } from '@/context/AppContext';
import useBDEventByID from '@/hooks/state/donation/useBDEventByID';
import useBDStatusEnum from '@/hooks/state/enum/useBDStatusEnum';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { useTranslations } from '@/hooks/translations';
import { closeDialogWithRef, openDialogWithRef, resetFormWithId } from '@/utils';
import { DonationInventoryTable } from './DonationInventoryTable';
import InventoryDialog from './components/InventoryDialog';
import { PageTitle } from '@/components/Label/PageTitle';
import useWishProductByCat from '@/hooks/state/wishlist/useWishProductbyCat';
import useWishProductCategories from '@/hooks/state/wishlist/useWishProductCats';
import useWishTargetCust from '@/hooks/state/wishlist/useWishTargetCust';
import useOrgWithId from '@/hooks/state/organization/useOrg';

function BrandDonationInventory(props) {
	const { history, match } = props;
	const location = useLocation();
	const {eventid, orgid} = match.params || {};
	const text = useTranslations('brandDonationInventoryMangement');
	const [selectedItem, setSelectedItem] = useState();
	const [isEdit, setIsEdit] = useState(false);
	const [canModify, setCanModify] = useState(false);
	const dialogRef = useRef()
	const ctx = useCurrentOrgContext().data
	const hostOrgQuery = useOrgWithId({org_id: orgid})
	const eventQuery = useBDEventByID({ eventid, orgid });
	const eventStatusQuery = useBDStatusEnum()
	const productCategoryQuery = useWishProductCategories()
	const demoQuery = useWishTargetCust()
	const inventories = eventQuery.data?.donations || [];
	
	useEffect(() => {
		if (!ctx || !eventQuery.data || !eventStatusQuery.data) return
		const d = (eventQuery.data.status === eventStatusQuery.data.brandReview 
			&& ctx?.brand) ||
			(eventQuery.data.status === eventStatusQuery.data.processorReview)
			console.log("d", d)
		setCanModify(
			(eventQuery.data.status === eventStatusQuery.data.brandReview 
			&& (ctx?.brand || ctx?.isUICContext)) ||
			(eventQuery.data.status === eventStatusQuery.data.processorReview)
		)
	},[eventQuery.data, ctx, eventStatusQuery.data])

	useEffect(() => {
		setSelectedItem({name: eventQuery.data?.orgname});
	},[eventQuery.data])

	const handleCreate = () => {
		setIsEdit(false)
		resetFormWithId('inventorydialog')
		openDialogWithRef(dialogRef)
	};

	const handleEdit = (item) => {
		setSelectedItem(item);
		setIsEdit(true)
		openDialogWithRef(dialogRef)
	};

	const handleBack = () => {
		history.goBack()
	};

	const handleClose = () => {
		setSelectedItem({name: eventQuery.data?.orgname});
	}

	return (
		<div>
			<div className="flex justify-start items-center"></div>
				<BasicBackButton onClick={handleBack} label={'Back to Donations'}/>
				<div className="flex flex-row items-center">
					<PageTitle>	
						{text.title}
					</PageTitle>
				</div>

			<div className="flex md:flex-row xs:flex-col items-center justify-between mb-5">
          <span className="text-4xl  font-semibold text-black mr-3">
            {text.donations}
          </span>
        </div>

			<div className=" w-full rounded-2xl ">
				<DonationInventoryTable 
					handleEdit={handleEdit} 
					data={inventories} 
					canModify={canModify}
					eventID={eventid}
					orgID={orgid}>
					<div className="mb-6 flex justify-between items-center">
						{ canModify &&
							<BasicRectButton className={'btn-secondary'} onClick={handleCreate}>{text.addNew}</BasicRectButton>
						}
					</div>
				</DonationInventoryTable>
			</div>
			<InventoryDialog
				demoData={demoQuery.data}
				categoryData={productCategoryQuery.data}
				hostOrgData={hostOrgQuery.data?.[0]}
				perItemReport={eventQuery.data?.peritemreport === '1'}
				ref={dialogRef}
				onClose={handleClose}
				inventory={selectedItem}
				eventid={eventid}
				isEdit={isEdit}
			/>
		</div>
	);
}

export default BrandDonationInventory;