import { MILLISEC } from '@/constants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useCookies } from 'react-cookie';
import { login } from '@/apis/base/auth/login';
import { checkEmailUnique } from '@/apis/base/misc/checkEmailUnique';

export default function useCheckEmailUnique(props) {
  const {onSuccess, ...otherprops} = props || {}
  const { setCookie } = useCookies([]);
  const uniqueData = useMutation({
      mutationKey: [ ROUTE_IDS.CHECK_EMAIL],
      mutationFn: checkEmailUnique,
      retry: false,
      staleTime: MILLISEC.MIN_15,
      onSuccess: (data,req) => {
          onSuccess && onSuccess(data,req)
      },
      ...otherprops
  })
  return uniqueData;
}
