import { createColumnHelper, filterFns } from "@tanstack/react-table";


const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const UICAdminColumnHelper = [
columnHelper.accessor((row) => row.id, {
    id: "ID",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span className="w-10">{info.getValue()}</span>,
    header: (info) => <span className="w-10">ID</span>,
  }),
columnHelper.accessor((row) => row.name, {
    id: "Name",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>Name</span>,
  }),
columnHelper.accessor((row) => row.email, {
    id: "Email",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>Email</span>,
  }),
columnHelper.accessor((row) => row['phone number'], {
    id: "Phone",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>Phone</span>,
  }),
columnHelper.accessor((row) => row.occupation, {
    id: "Occupation",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>Occupation</span>,
  }),
]

