import React from 'react';

export const TwoColumnGrid = ({className, children, ...props}) => {
    return (
        <div 
            className={`grid w-full grid-cols-[[first-start],auto,[first-end],1fr,[second-start],auto,[second-end]] ${className}`}>
            {children}
        </div>
    );
};
