import Dropdown from "@/components/Dropdown";
import useGetOrgTypes from "@/hooks/state/organization/useGetOrgTypes";
import DropdownTwo from "@/components/DropDownTwo";
import { useMemo } from "react";

export const OrgTypeDropDown = ({ onChange,  isError, errorText, className, ...props }) => {
    const typeQuery = useGetOrgTypes();

    const options = useMemo(() => {
        if (!typeQuery.isSuccess) return []
        return typeQuery.data?.map((type) => {
            const suboptions = type.subnodes?.length > 0 ? [
                ...type?.subnodes?.map(
                    (sub) => ({
                        label: sub.name,
                        value: sub.id,
                        ...sub
                    })
                ),
                {
                    label: `General ${type.name}`,
                    value: type.id,
                    ...type
                }
            ] : []
            return {
                label: type.name,
                value: type.id,
                suboptions,
            }
        })
    },[typeQuery.dataUpdatedAt])

    return  (
        <DropdownTwo {...{
            options,
            isError,
            errorText,
            placeHolder: 'Choose Organization Type',
            hasSubOptions: true,
            onChange,
            className,
            ...props
        }}/>
    )
}