import { MonthlyDonationsChartCard } from '@/components/SEE/subcomponents/MonthlyDonationsChartCard';
import { SEEPillarCard } from '@/components/SEE/subcomponents/SEEPillarCard';
import { SEEPillarCardEntry } from '@/components/SEE/subcomponents/SEEPillarCardEntry';
import { TopDonorsListCard } from '@/components/SEE/subcomponents/TopDonorsListCard';
import { SEE_CATEGORY } from '@/constants';
import useGenericSEEData from '@/hooks/state/SEE/useGenericSeeData';
import { prettyPrint } from '@/utils';
import React from 'react';

const data = {
    topdonors: [
        {"orgname":"Aritzia","contribution":'8,711,215.00'},
        {"orgname":"L'Oréal","contribution":'8,250,776.00'},
        {"orgname":"Moores","contribution":'7,765,372.00'},
        {"orgname":"Zara","contribution":'7,504,436.00'},
        {"orgname":"Aldo","contribution":'7,049,156.00'},
        {"orgname":"H&M","contribution":'6,609,290.00'},
        {"orgname":"Roots","contribution":'3,575,708.00'},
        {"orgname":"Levi's","contribution":'3,538,536.00'},
        {"orgname":"Deciem","contribution":'2,663,548.00'},
        {"orgname":"Church & Dwight","contribution":'2,016,972.00'},
        {"orgname":"P&G","contribution":'1,454,676.00'},
    ],
    "communitiessupported": 1368,
    "communityreach": 3.2,
    "peoplehelped": 100000,
    "landfillavoidancelbs": 15,
    "watergallonssaved": 19,
    "carbonoffsettonnes": 255,
    "volunteerhours": 74000,
    "ytddonations": 51,
    "totalsponsorships": 684,
}
const donors = [
    {"orgname":"Lululemon","contribution":19857},
    {"orgname":"Aritzia","contribution":18392},
    {"orgname":"Aldo","contribution":16543},
    {"orgname":"Calin Klein","contribution":14478},
    {"orgname":"H&M","contribution":12234},
    {"orgname":"Nike","contribution":10067},
    {"orgname":"Roots","contribution":8156},
    {"orgname":"Levi's","contribution":6021},
    {"orgname":"Winners","contribution":4023},
    {"orgname":"Gildan","contribution":2019},
    {"orgname":"Old Navy","contribution":1803},
    {"orgname":"Zara","contribution":1625},
    {"orgname":"Blue Notes","contribution":1412},
    {"orgname":"Puma","contribution":1320},
    {"orgname":"Under Armour","contribution":1280},
    {"orgname":"Canada Goose","contribution":1192},
    {"orgname":"Sport Chek","contribution":1209},
]




export const GenericReport = ({ps, ...props}) => {
    const SEEQuery = useGenericSEEData()
    return SEEQuery.data && (
        <div className='flex gap-4 w-full justify-stretch //overflow-auto'>
            <div className='form-control gap-4 flex-3 lg:flex-4 shrink-1 w-full  min-w-0 '>
                <div className='flex flex-col md:flex-row gap-4 md:max-h-[20rem]  h-full w-full min-w-0 '>
                    <MonthlyDonationsChartCard/>        
                    <div className="block md:hidden flex-2 max-h-full w-full min-w-0">
                        <TopDonorsListCard donors={data?.topdonors} />
                    </div>    
                </div>
                <div className="flex flex-col md:flex-row gap-4 w-full min-w-0">
                    <SEEPillarCard SEECategory={SEE_CATEGORY.SOCIAL} title='social'>
                        <SEEPillarCardEntry
                            statistic={data?.communitiessupported}
                            description={'Communities Supported'}
                        />
                        <SEEPillarCardEntry
                            statistic={data?.communityreach}
                            description={'Community Reach'}
                            suffix='M'
                        />
                        <SEEPillarCardEntry
                            statistic={data?.peoplehelped}
                            description={'People Helped Annually'}
                            suffix='+'
                        />
                    </SEEPillarCard>
                    <SEEPillarCard SEECategory={SEE_CATEGORY.ENVIRONMENTAL} title='environmental'>
                        <SEEPillarCardEntry
                            statistic={data?.landfillavoidancelbs}
                            description={'Saved from Landfill'}
                            suffix='M lbs.'
                        />
                        <SEEPillarCardEntry
                            statistic={data?.watergallonssaved}
                            description={'Water Saved'}
                            suffix='B Gallons'
                        />
                        <SEEPillarCardEntry
                            statistic={data?.carbonoffsettonnes}
                            description={'Carbon Offset Saved'}
                            suffix='k T.'
                        />
                    </SEEPillarCard>
                    <SEEPillarCard SEECategory={SEE_CATEGORY.ECONOMIC} title='economical'>
                        <SEEPillarCardEntry
                            statistic={data?.volunteerhours}
                            description={'Volunteer Hours'}
                        />
                        <SEEPillarCardEntry
                            statistic={data?.ytddonations}
                            description={'Annual Donations'}
                            prefix='$'
                            suffix='M'
                        />
                        <SEEPillarCardEntry
                            statistic={data.totalsponsorships}
                            description={'Total Donations'}
                            prefix='$'
                            suffix='M'
                        />
                    </SEEPillarCard>
                </div>
            </div>        
            <div className="hidden md:block flex-2 lg:flex-2 shrink-0 max-h-full w-full">
                <TopDonorsListCard donors={data?.topdonors} />
            </div>    
        </div>
    );
};
