import React, { useEffect, useRef, useState } from "react";


import { BasicBlueRectButton } from "@/components/Buttons/BasicBlueRectButton";
import { BasicRedRectButton } from "@/components/Buttons/BasicRedRectButton";
import { RequestManagementTable } from "@/components/Tables/RequestManagementTable/RequestManagementTable";
import useModAppRequests from "@/hooks/state/admin/useModAppRequests";
import useRoleRequests from "@/hooks/state/admin/useRoleRequests";
import useAppStatusEnum from "@/hooks/state/enum/useAppStatusEnum";
import useRoleEnum from "@/hooks/state/enum/useRoleEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { openDialogWithRef } from "@/utils";
import RequestDetailsDialog from "@/views/pages/Admin/User/components/RequestDetailsDialog";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { Popup } from "@/wrappers/swal/Popup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import ApplicationManagementDialog from "../../../../../../components/User/ApplicationManagementDialog";


function Requests({text}) {
  const tableRef = useRef();
  const dialogRef = useRef()
  const [hasSelection, setHasSelection] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [detailRequest, setDetailRequest] = useState(null);

  const [openApplicationMgmtDlg, setOpenApplicationMgmtDlg] = useState(false);
  const [approve, setApprove] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [comment, setComment] = useState('');
  const contextQuery = useCurrentOrgContext()

  const roleEnumQuery = useRoleEnum()
  const roleEnum = roleEnumQuery?.data

  const appStatusQuery = useAppStatusEnum()
  const appStatuses = appStatusQuery?.data
  const requestsQuery = useRoleRequests({orgid: contextQuery.data?.orgid, roleids: roleEnum.allRoles, flat: true})
  const requestMutation = useModAppRequests({
    onSuccess: (data) => {
      SuccessToast.fire({
        text: `Request Successfully ${approve ? 'Approved' : 'Declined'}`,
      })
    },
    onError: (data) => {
      Popup.fire({
        title: "Error",
        text: `Failed ${approve ? 'approve' : 'decline'} to request`,
        icon: "error",
        showConfirmButton: true,
      })
    } 
  })

  useEffect(() => {
  }, [requestsQuery.data])

  useEffect(() => {},[tableRef?.current])
  
  const handleMutate = (isApprove) => {
    const items = selectedItems?.length > 0 ?
    selectedItems
    : tableRef?.current?.getSelectedRowModel?.()
      ?.rows.map(r => r.original)
    if (tableRef?.current != null && items?.length > 0) {
      const params = {
        orgid: contextQuery.data?.orgid,
        statusid : isApprove ? appStatuses.approved : appStatuses.declined,
        "finids[]": items.filter(item => item?.roleid === roleEnum.fin)
          .map(req   => req.id),
        "ambassadorids[]": items.filter(item => item?.roleid === roleEnum.ambassador)
          .map(req => req.id),
        "smiids[]": items.filter(item => item?.roleid === roleEnum.smi)
          .map(req => req.id),
          comment: comment 
      }
      requestMutation.mutate(params)
      setSelectedItems([]);
    } else {
      FailPopup.fire({text: `Failed to ${isApprove ? 'approve' : 'reject'} request`})
    }
    setOpenApplicationMgmtDlg(false); 
}


  const viewDetails = (item) => {
      setDetailRequest(item)
      openDialogWithRef(dialogRef)
  };

  const onSingleModify = (isApprove,request) => {
    setSelectedItems([request]);
    setApprove(isApprove);
    setOpenApplicationMgmtDlg(true);
  }
  const onConfirmBtnClicked = () => {
    if (approve) {
      handleMutate(true)
    } else {
      handleMutate(false)
    }
  };

  const handleMultiModify = (isApprove) => {
    setApprove(isApprove);
    setOpenApplicationMgmtDlg(true);
  }

  const handleSelectionChange = (e) => 
    {setHasSelection(Object.keys(e).length > 0);
    }

  return (
    <div className="mb-12">
      {!requestsQuery.data  ? <>
          <div className="flex flex-col justify-center  gap-4">
            <div className="skeleton h-100 bg-accent-content w-full flex flex-col justify-end px-5 py-5  gap-1">
            </div>
          </div>
      </> : 
        <RequestManagementTable 
          tableRef={tableRef}
          className='table-lg'
          data={requestsQuery.data}
          handleSelectionChange={handleSelectionChange}
          meta={{
            onSingleModify,
            viewDetails,
          }}>
            <BasicBlueRectButton disabled={!hasSelection} onClick={() => handleMultiModify(true)}>
              Approve
            </BasicBlueRectButton>
            <BasicRedRectButton disabled={!hasSelection} onClick={() => handleMultiModify(false)}>
              Decline
            </BasicRedRectButton>      
        </RequestManagementTable>

      }
      <RequestDetailsDialog
        request={detailRequest}
        dialogRef={dialogRef}
      />
      <ApplicationManagementDialog
        text={text}
        open={openApplicationMgmtDlg}
        approve={approve}
        comment={comment}
        onClosedBtnClicked={() => setOpenApplicationMgmtDlg(false)}
        onConfirmBtnClicked={onConfirmBtnClicked}
        onCommentChanged={(comment) => setComment(comment)}
      />
    </div>
  );
}

export default Requests;
