import { TrackedImage } from '@/components/Image/TrackedImage';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { getEventInventoryUrl } from '@/utils';
import React from 'react';


export const ConfirmShipmentTable = ({values, communityState, ...props}) => {
    const {brandorgid, eventid} = communityState || {}
    const {data: ctx} = useCurrentOrgContext() || {}
    return (
        <div className="rounded-xl border-gray-border border min-h-[20rem] overflow-y-scroll">
            <table className="table table-lg table-zebra place-items-center place-content-center">
                <TableHead/>
                <tbody>
                { communityState.lineItems.map((item, idx) => (
                    <TableRow 
                        key={item.inventoryid}
                        {...{
                            confirmed: values[item.inventoryid]?.confirmedQuantity,
                            lineItem: item,
                            eventid, 
                            brandorgid,
                            idx,
                            ...props
                        }}
                    />
                ))}
                </tbody>
            </table>
     </div>
    );
};

const  TableHead = () => (
    <thead>
        <tr>
            <th>NO</th>
            <th></th>
            <th>PRODUCT</th>
            <th>QUANTITY SHIPPED</th>
            <th>QUANTITY RECEIVED</th>
            {/* <th></th> */}
        </tr>
    </thead>
)

const TableRow = ({idx, lineItem, onChange, confirmed, eventid, brandorgid}) => {
    const {requestedQuantity, inventoryid} = lineItem || {}
    const handleChange = (e) => {
        const {name, value} = e.target
        onChange({[inventoryid]: {inventoryid, confirmedQuantity: Number(value)}})
    }
    return (
    <tr>
        <td>
            <div className="flex items-center gap-3">
                {idx}
            </div>
        </td>

        <td >
            <div className="flex dropdown dropdown-hover dropdown-top w-fit">
                <TrackedImage src={getEventInventoryUrl( eventid, inventoryid, 0)} alt="" className='w-16 rounded-md'/>
                    <div className='dropdown-content pointer-events-none w-48 left-[50%] mb-1 -ml-[6rem] mx-auto'> 
                        <TrackedImage 
                            src={getEventInventoryUrl( eventid, inventoryid, 0)} 
                            alt={"inventory" + idx}
                            className='w-full rounded-md' />
                    </div>
            </div>
        </td>

        <td>
            {lineItem.productname}
        </td>

        <td> 
            <span>{lineItem.shipmentQuantity}</span>
        </td>

        <td>
            <input type="tel" className='input input-sm border border-gray-border text-md max-w-[5rem] focus:outline-none' value={confirmed || 0} name='ship' onChange={handleChange}/>
        </td>
        {/* 
            <th>
                <button className="btn btn-ghost btn-xs">clear</button>
            </th> 
        */}
    </tr>
)}