import { postPromise } from '@/apis/base';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import config from '@/apis/config';

export const getGeoNode = (params) => {
  return postPromise(
    config.API_BASE_URL,
    composeFormData(params, ROUTE_IDS.GET_GEO_NODE)
  );
};

