
import React, { createRef, useEffect, useRef, useState } from "react";

import { useTranslations } from "@/hooks/translations";
import {
  arrayToObject,
  getDashedDateString,
  openDialogWithRef
} from "@/utils";

import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { UserInvitationDialog } from "@/components/Dialog/UserInvitationDialog";
import { BasicTable } from "@/components/Tables/BasicTable";
import { ExpandedUserRow } from "@/components/Tables/SpecialRows/ExpandedUserRow";
import { UICAllUserColumnHelper } from "@/components/Tables/UICAllUserTable/UICAllUserColumnHelper";
import useAllUsers from "@/hooks/state/admin/useAllUsers";
import useMinimalOrgs from "@/hooks/state/organization/useMinimalOrgs";
import UserProfile from "../../../../../components/User/UserProfile";
import { downloadExcel } from "@/utils/fileUtils/downloadExcel";

const CurrentUsers = (props) => {
  const tableRef = useRef();
  const { context } = props;
  const inviteDialogRef = createRef();
  const text = useTranslations("userManagement");
  const [userList, setUserList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const usersQuery = useAllUsers()
  const orgsQuery = useMinimalOrgs({});
  
  const BODY_CONTENT = {
    ALL_MEMBER: "all_member",
    MEMBER_DETAIL: "member_detail",
  };

  const [bodyContent, setBodyContent] = useState(BODY_CONTENT.ALL_MEMBER);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() =>{
    if (usersQuery.isSuccess && usersQuery.data?.length > 0) {
      setUserList(usersQuery.data);
    }}
  , [usersQuery.data]);
  
  useEffect(() => {
    if (!orgsQuery.isSuccess || !usersQuery.isSuccess) return
    const orgObj = arrayToObject(orgsQuery.data,'id')
    const users = usersQuery.data
    users.forEach(user => {
      const orgNameList = []
      user.orgs.forEach(org => {
        orgNameList.push(orgObj?.[org]?.name)
      })
      user.orgNames = orgNameList
    })
    setUserList([...users])
  },[orgsQuery.data, usersQuery.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearchKey = (val) => {
    setSearchKey(val);
  };

  // const onDeleteUserClicked = (org_id) => {
  //   setOpenDeleteUserDlg(true);
  //   setUserIdToDelete(org_id);
  // };

  // const cancelDeleteUser = () => {
  //   setOpenDeleteUserDlg(false);
  // };

  function confirmDeleteUser() {}

  function exitDetailView() {
    setBodyContent(BODY_CONTENT.ALL_MEMBER);
  }

  const handleProfileClicked = (user) => {
    console.log(user);
    setSelectedUser(user);
    setBodyContent(BODY_CONTENT.MEMBER_DETAIL);
  }
  function exportVisibleUsers() {
    const tableRows = tableRef.current?.getFilteredRowModel?.()?.rows?.map?.(row => row.original)
    if (!tableRows) return
    const today = new Date()
    const todayInSecs = today.getTime() / 1000
    const filename = "U11 Users " + getDashedDateString(todayInSecs)
    const sheet = {
      name: "U11 Users",
      rows: tableRows.map(row => ({
        "First Name": row['first name'],
        "Last Name": row['last name'],
        "Email": row.email
      }))
    }
    downloadExcel([sheet], filename, [20,20,20])
  }

  return (
    <div className="form-control gap-4 justify-start">
          { bodyContent === BODY_CONTENT.ALL_MEMBER ? (
            <BasicTable 
                tableRef={tableRef}
                rowAccordion
                data={userList} 
                horizontalScrolling={false}
                renderSubComponent={ExpandedUserRow}
                columnHelper={UICAllUserColumnHelper}
                meta={{handleProfileClicked}}
              >
            <BasicRectButton className={'bg-green-button text-white'} onClick={exportVisibleUsers}>
                Export
            </BasicRectButton>
            <BasicRectButton className="btn-secondary" 
              onClick={() => 
              openDialogWithRef(inviteDialogRef)}>
                Invite
            </BasicRectButton>
          </BasicTable>
        ) : (
          <UserProfile backLabel='Back to Users Table' user={selectedUser} showUserType={false} showRoles={false} exitDetailView={exitDetailView} />
        )}
          <div className=" w-full ">
         <UserInvitationDialog dialogRef={inviteDialogRef} orgsData={orgsQuery.data} />
          </div>
    </div>
  );
};

export default CurrentUsers;
