import { withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const WhiteCard = withStyles({
  root: {
    padding: '16px 25px 16px 25px',
    borderRadius: '9px',
    border: 'solid 1px #eaebec',
    overflow: 'visible'
  },
})(Card);


const TableCard = withStyles({
  root: {
    padding: '16px 0',
    borderRadius: '9px',
    border: 'solid 1px #eaebec',
  },
})(Card);

const TableCardContent = withStyles({
  root: {
    padding: '0',
  },
})(CardContent);

const CardContainer = withStyles({
  root: {
    borderRadius: 9,
    boxShadow: 'none',
  },
})(Card);

const CardBody = withStyles({
  root: {
    padding: 0,
  },
})(CardContent);

const CardFooter = withStyles({
  root: {
    padding: '0 16px 16px 16px',
  },
})(CardActions);

const NewsCard = ({ text, image, title, description, link }) => {
  return (
    <CardContainer>
      <CardBody>
        <div className="w-full pt-pc-62.75 border border-solid border-gray-200 relative overflow-hidden">
          <img
            src={image}
            className="w-full h-full absolute top-0 left-0 object-cover object-top"
            alt="News logo"
          />
        </div>
        <div className="m-4">
          <p className="text-1.5xl font-semibold leading-1.33 whitespace-nowrap overflow-ellipsis overflow-hidden">
            {title}
          </p>
          <p className="text-sm font-light leading-1.75 min-h-20 max-h-24 overflow-hidden">
            {description}
          </p>
        </div>
      </CardBody>
      <CardFooter>
        <a
          className="font-semibold text-gray-normal leading-1.33 cursor-pointer hover:opacity-80"
          href={`https://unitedheartsforcanada.com/news/${link}`}
          target="_blank"
          rel="noreferrer"
        >
          {text.readMore}
        </a>
      </CardFooter>
    </CardContainer>
  );
};

const ImageCarousel = withStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    '& div': {
      height: '100%',
    },
    '& button': {
      outline: 'none',
    },
  },
})(Carousel);

const DonationCard = ({ text, images, name, org_name, date, onClick }) => {
  return (
    <CardContainer>
      <CardBody>
        {onClick ? (
          <div
            className="w-full pt-full border border-solid border-gray-800 relative cursor-pointer"
            onClick={onClick}
          >
            <ImageCarousel autoPlay={true} indicators={false}>
              {images.map((item, i) => (
                <img
                  key={`donation-card-logo-${i}`}
                  className="w-full h-full object-cover object-top"
                  src={item}
                  alt="Donation logo"
                />
              ))}
            </ImageCarousel>
          </div>
        ) : (
          <div className="w-full pt-full border border-solid border-gray-200 relative ">
            <ImageCarousel autoPlay={false} indicators={false}>
              {images.map((item, i) => (
                <img
                  key={`donation-card-logo-${i}`}
                  className="w-full h-full object-cover object-top"
                  src={item}
                  alt="Donation logo"
                />
              ))}
            </ImageCarousel>
          </div>
        )}
        <div className="p-1">
          <p className="mt-2 text-1.5xl font-semibold  leading-1.33 whitespace-nowrap overflow-ellipsis overflow-hidden">
            {name}
          </p>
          <p className="mt-2 text-md font-semibold  leading-1.33 whitespace-nowrap overflow-ellipsis overflow-hidden">
            {org_name}
          </p>
          <p className="text-sm font-light leading-1.75">
            {text.date}: {date}
          </p>
        </div>
      </CardBody>
    </CardContainer>
  );
};

const DashboardCard = ({ title, data, st_color, end_color }) => {
  console.log(data);
  let value = 0;
  let unit = '';
  if (Number(data) >= 1000000) {
    unit = 'M';
    value = Math.round(Number(data) / 1000000);
  } else if (Number(data) >= 1000) {
    unit = 'K';
    value = Math.round(Number(data) / 1000);
  } else {
    unit = '';
    value = data;
  }

  const DashboardCardContainer = withStyles({
    root: {
      borderRadius: 9,
      boxShadow: 'none',
      background: `linear-gradient(45deg, ${st_color} 30%, ${end_color} 90%)`,
      color: 'white',
      margin: 15,
    },
  })(Card);
  return (
    <DashboardCardContainer>
      <CardContent>
        <div className="flex flex-col">
          <p className="text-1.5xl font-semibold leading-1.33 whitespace-nowrap overflow-ellipsis overflow-hidden">
            {title}
          </p>
          <div className="flex flex-row justify-center items-center mt-3 font-bold align-bottom">
            {(title === 'Donation $ Value' || title === 'E-Commerce Sales') && (
              <p className="text-3xl mr-1">$</p>
            )}
            <p className="text-5xl font-semibold leading-1.33 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {value}
            </p>
            {title === 'Members In Your Community' ||
            title === 'Donation $ Value' ||
            title === 'E-Commerce Sales' ||
            title === 'E-Commerce Customers' ? (
              <p className="text-4xl ml-1">{unit}</p>
            ) : title === 'Landfill Avoidance' ? (
              <p className="text-4xl ml-1">lbs</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </CardContent>
    </DashboardCardContainer>
  );
};

export {
  WhiteCard,
  TableCard,
  TableCardContent,
  NewsCard,
  DonationCard,
  DashboardCard,
};
