import { MILLISEC } from '@/constants';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getGeoData, honestGeoData } from '@/apis/base/misc';

export default function useGeoData(props) {
  const geoQuery = useQuery(getGeoDataConfig(props));
  return geoQuery;
}

export const getGeoDataConfig = (props) => queryOptions({
    queryKey: [ROUTE_IDS.GET_GEO_DATA],
    queryFn: honestGeoData,
    staleTime: MILLISEC.MIN_15,
    gcTime: MILLISEC.HOUR,
    ...props
})
