
import React from "react";import { UserManagementPage } from "@/components/pages/UserManagementPage";
function Current({ context }) {
  return (
    <div className="mb-12">
      <UserManagementPage  standardCommunity={true} orgid={context?.orgid}/>
    </div>
  );
}

export default Current;
