import React from 'react';
import { Link } from 'react-router-dom';


export const DropdownBlueLink = ({to, children,...props}) => {
    return (
        <Link {...props} to={to}  className='hover:bg-accent-content text-blue-button' >
            {children}
        </Link>
    );
};

