import { ChevronRight } from '@/components/Icons';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

const columnHelper = createColumnHelper();

export const ExpandIndicatorCell = ({expanded=false, onClick, className, ...props}) => {
    return (
        <div onClick={onClick} className={`w-5 hover:cursor-pointer ${className}`} {...props}>
            <ChevronRight className={`w-fit transition-transform transform ${expanded ? 'rotate-90' : ''}`} />
        </div>
    );
};



export const expandColumnHelper = [
    columnHelper.display({
        id: 'Expand',
        size: 10,
        meta: {
            hideExport: true,
        } ,
        header: ({table}) => table.getCanSomeRowsExpand() && <div className='w-10'></div>,
        cell: ({row}) => row.getCanExpand() ? (
            <ExpandIndicatorCell 
                style={{paddingLeft: `${row.depth * 1}rem`}}  
                expanded={row.getIsExpanded()} 
                onClick={row.getToggleExpandedHandler()} />
        ) : <div></div>,
        
    })
]