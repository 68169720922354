import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { OrgCell } from "../Cells/OrgCell";
import { getDateString } from "@/utils";
import { ExpandIndicatorCell, expandColumnHelper } from "../Cells/ExpandIndicatorCell";
import { BasicGhostButton } from "@/components/Buttons/BasicGhostButton";
import { CheckIcon, EyeIcon, RequestIcon } from "@/components/Icons";
import { EditButton } from "@/components/Buttons";
import { SendIcon } from "@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons";

const columnHelper = createColumnHelper();
const stringFilter = filterFns.includesString;
export const OrgTreeBDShipmentReportCols = (lineitems) => {
  const lineItemCols = lineitems.map((li) => {
      return columnHelper.display({
          id: li.id,
          size: 10,
          enableColumnFilter: false,
          cell: ({row, table}) => row && table && (
            <Quantities 
              meta={table.options.meta}
              row={row}
              item={li}
              />
            ),
          header: (info) => <span>{li.productname?.toUpperCase?.()}</span>,
      })
  })
  return [
      columnHelper.accessor((row) => row.id, {
          id: "Organization",
          size: 400,
          filterFn: stringFilter,
          cell: ({row}) => <OrgCell style={{paddingLeft: `${row.depth * 2}rem`}} org={row.original}/>,
          header: (info) => <span>ORGANIZATION</span>,
      }),
      columnHelper.accessor(row => row.modified, {
          id: "Modified At",
          size: 200,
          enableColumnFilter: false,
          cell: ({row}) => <ModifiedAt original={row.original}/>,
          header: (info) => <span>MODIFIED AT</span>,
        }),
        columnHelper.accessor(row => row['modified by'], {
          id: "Modified By",
          size: 200,
          enableColumnFilter: false,
          cell: ({row,table}) => <ModifiedBy userNames={table.options.meta.userNames}  original={row.original}/>,
          header: (info) => <span>MODIFIED BY</span>,
        }),
      ...lineItemCols,
      columnHelper.display({
          id: "Actions",
          size: 10,
          cell: ({row, table}) => <Actions {...{row, table}} />,
          header: (info) => <span>ACTIONS</span>,
      }),
  ]
}

const Actions = ({row, table}) => {
    const {canEditRequest, canEditShipment, handleEdit} = table?.options?.meta || {}
    return (
        <EditButton  buttonProps={{
            onClick: () => handleEdit(row.original),
            disabled: !(canEditRequest && !row.original.shipmentmodified) && !(canEditShipment && !row.original.confirmedby)
            }} />
    )
}

const ModifiedAt = ({original}) => (
    <div className="h-10 form-control gap-1 justify-center">
      <span className='mb-auto flex flex-nowrap whitespace-nowrap'>
        
        <RequestIcon className='w-4 h-4 mr-1'/>
        {
          original?.requestmodified ? 
          getDateString(Number(original?.requestmodified))
          : '-'
        }
        </span>
      <span className='text-red-600 flex flex-nowrap'>
        <SendIcon className='w-4 h-4 mr-1'/>
        {
          original?.shipmentmodified ?
          getDateString(Number(original?.shipmentmodified))
          : '-'
        }
      </span>
      <span className="text-success-content flex flex-nowrap">
        <CheckIcon className='w-4 h-4 mr-1'/>
        {
          original?.qtyconfirmedtime ?
          getDateString(Number(original?.qtyconfirmedtime))
          : '-'
        }
      </span>
    </div>
  )
  
  const ModifiedBy = ({original, userNames}) => (
    <div className="h-10 w-fit form-control whitespace-nowrap gap-1 justify-center">
      <p className='mb-auto'>{userNames?.[original?.requestmodifier]?.name || '-'}</p>
        <p className='text-red-600 mt-auto'>{userNames?.[original?.shipmentmodifier]?.name || '-'}</p>
        <p className="text-success-content">{userNames[original?.confirmedby]?.name || '-'}</p>
    </div>
  )
  
  const Quantities = ({row, item, meta}) => {
    const {inlineEditing, handleInlineBlur, canEditRequest, canEditShipment} = meta || {}
    const lineitem = {...item, ...row.original?.lineitems?.[item.id], orgid: row.original?.id}
    const partiallyReceived = 
      Number(lineitem?.confirmedquantity) < Number(lineitem?.shipmentquantity)
    const editingRequest = inlineEditing && canEditRequest && Number(lineitem?.requestquantity < 1)
    const editingShipment = inlineEditing && canEditShipment && 
      (lineitem?.confirmedquantity == null) &&
      (lineitem?.requestquantity != null)
    const editingConfirmed = inlineEditing && canEditRequest && 
      (Number(lineitem?.shipmentquantity) > 0)
    return (
      <div className="h-10 form-control w-fit gap-1 justify-center">
        { editingRequest ?  (
          <input 
            onChange={() => {}}
            key={`${item?.id}${lineitem?.requestquantity}`}
            type="number" 
            className='input input-xs border border-gray-border text-sm max-w-[5rem] focus:outline-none'
            defaultValue={parseInt(lineitem?.requestquantity) || 0}
            name='req'
            onBlur={(e) => handleInlineBlur(e, 'requestquantity',lineitem)}/>
        ) : (
            <p className={`${inlineEditing ? 'ml-2' :''} mb-auto`}>
              {lineitem?.requestquantity || '-'}
            </p>
        )}
        <div 
            className={`indicator form-control 
              ${partiallyReceived ? 'outline outline-warning rounded-md outline-offset-[3px] w-full' : ''} `}
            >
          { editingShipment ? (
            <input 
              onChange={() => {}}
              key={`${item?.id}${lineitem?.shipmentquantity}`}
              type="number" 
              className='input input-xs border border-gray-border text-sm max-w-[5rem] focus:outline-none'
              defaultValue={parseInt(lineitem?.shipmentquantity) || 0}
              name='ship'
              onBlur={(e) => handleInlineBlur(e, 'shipmentquantity', lineitem)}/>
          ) : (
              <p className={`${inlineEditing ? 'ml-2' :''} text-red-600 mt-auto`}>{lineitem?.shipmentquantity || '-'}</p>
          )}
          { editingConfirmed ? (
            <input 
              onChange={() => {}}
              key={`${item?.id}${lineitem?.confirmedquantity}`}
              type="number" 
              className='input input-xs border border-gray-border text-sm max-w-[5rem] focus:outline-none'
              defaultValue={parseInt(lineitem?.confirmedquantity) || 0}
              name='ship'
              onBlur={(e) => handleInlineBlur(e, 'confirmedquantity', lineitem)}/>
          ) : (
            <p className={`${inlineEditing ? 'ml-2' :''} text-success-content mt-auto`}>{lineitem?.confirmedquantity || '-'}</p>
          )}
          { partiallyReceived && (
            <div className="indicator-item indicator-bottom text-sm -bottom-2 badge badge-sm badge-warning">
              Not all received
            </div>
          )}
        </div>
      </div>
  )}