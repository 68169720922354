import React from 'react';

export const DashboardCard = ({className, ...props}) => {
    return (
        <div 
            className={`form-control flex-1 justify-evenly bg-base-100 p-4 rounded-xl gap-4 min-w-fit ${className}`} 
            {...props}>
        </div>
    );
};
