

import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getProcessorDonations } from '@/apis/base/donation/getProcessorDonations';
import useBDStatusEnum from '../enum/useBDStatusEnum';
import { roundTo } from '@/utils';
import React from 'react';

export default function useProcessorDonations({processorid}) {
    const BDStatusQuery = useBDStatusEnum();
    const query = useQuery({
        queryKey: ['bd', 'event', processorid , ROUTE_IDS.GET_PROCESSOR_DONATIONS],
        queryFn: () => getProcessorDonations({orgid: processorid}),
        staleTime: MILLISEC.MIN_15,
        enabled: !!processorid,
        select: React.useCallback(data => {
            const clone = structuredClone(data)
            for (const e of clone) {
                e.canAudit = e.status === BDStatusQuery.data?.processorReview
                e.canEdit = e.status === BDStatusQuery.data?.processorReview
                if (e?.peritemreport === '1') {
                    e.price = 0
                    e.weight = 0
                    e?.donations?.forEach?.(d => {
                        if (d.quantity) {
                            if (d.price)
                                e.price += roundTo(Number(d.price) * Number(d.quantity))
                            if (d.weight)
                                e.weight += roundTo(Number(d.weight) * Number(d.quantity))
                        }
                    })
                }
            }

            return clone
        }, [BDStatusQuery.data])
    });
    return query;
}