import { getLogoUrl } from "@/utils";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useState } from "react";

import { Lock } from "@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons";
import useJoinOrg from "@/hooks/state/organization/useJoinOrg";
import useLeaveOrg from "@/hooks/state/organization/useLeaveOrg";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";

export const LocalCommunitiesTable = ({ data = [], isBusiness = false }) => {
          const columns = colHelpercols;
          const table = useReactTable({
            columns: columns,
            data,
            getCoreRowModel: getCoreRowModel(),
          });
        
          const headers = table.getFlatHeaders()
          const rows = table.getRowModel().rows
        
          return data?.length > 0 && (
            <div className="p-5 py-0 rounded-9 ">
              <table className="table  my-4 w-full ">
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header?.id}>
                            {header?.isPlaceholder ? null : (
                                <span>
                                {flexRender(
                                    header?.column.columnDef.header,
                                    header?.getContext()
                                )}
                                </span>
                            )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {rows.map((cell) => (
                    <tr key={cell.id } className="relative hover:shadow-card odd:hover:shadow-card  odd:z-[5]  even:bg-base-200  hover:cursor-pointer">
                      {cell.getVisibleCells().map((cell) => (
                        <td key={cell.id} style={{width: cell.column.getSize()}}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
}

const OrgSummary = ({org}) => (

    <div className=" indicator flex flex-col gap-2 ">
        <span className="font-bold text-xl whitespace-nowrap w-fit-content flex flex-row gap-2">
            {   org?.suffix !== '' && 
                <div className="indicator-item flex flex-row badge gap-2 font-normal">
                private
                <svg className="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                    <path d="M14 7h-1.5V4.5a4.5 4.5 0 1 0-9 0V7H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-5 8a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0v3Zm1.5-8h-5V4.5a2.5 2.5 0 1 1 5 0V7Z"/>
                </svg>

                </div>
                }
            <div>
            {`${org?.name}`}

            </div>
            
        </span>
        <span className="text-[15px] font-semibold text-gray-normal">{org?.members?.length || 'unknown members'}</span>
    </div>
)

const OrgLogo = ({id}) => (
    <img src={getLogoUrl(id)} alt="org logo" 
        onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.hidden = true;
        }}
        className="w-20 h-16 object-contain "/>
)

const JoinButton = ({row}) => {
    const [joined,setJoined] = useState(row.isMember)
    const leaveOrgMutation = useLeaveOrg({
        onSuccess: (data) => {SuccessToast.fire({text: 'Successfully left organization'})},
        onError: (error) => {FailPopup.fire({text: 'Failed to leave organization'})},
    })
    const joinMutation = useJoinOrg({
        onSuccess: (data) => {SuccessToast.fire({text: 'Successfully joined organization'})},
        onError: (error) => {FailPopup.fire({text: 'Failed to join organization'})},
    })

    const handleJoinClicked = () => {
        if (joined){
            leaveOrgMutation.mutate({orgid: row.id}, {
                onSuccess: (data) => {SuccessToast.fire({text: 'Successfully left organization'})},
                onError: (error) => {FailPopup.fire({text: 'Failed to leave organization'})},
                onSettled: (data, error) => {setJoined(false)}
            })
        }
        else {
            joinMutation.mutate({orgid: row.id})
            setJoined(!joined)
        }
    }

    return (
        <button 
            className={`btn btn-circle no-animation ring-2 focus:border-none ring-gray-500 ring-inset btn-sm place-self-center
            ${joined ? 'bg-green-verdant' : ''}`} 
            onClick={handleJoinClicked}>
            {   joined &&
                <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check absolute ">
                    <polyline  points="20 6 9 17 4 12"></polyline>
                </svg>

            }
        </button>
    )
}

const OrgDescription = ({description=''}) => (
    <div className="flex flex-col gap-2 ">
        <span className="text-[15px]  line-clamp-2">
            {`${description}`}
        </span>
        <span className="underline">
            see more
        </span>
    </div>
)

const OrgRestriction = ({row}) => row?.private ? (
    <div className="flex flex-col gap-2 ">
        <div className="whitespace-nowrap flex flex-row gap-2 badge bg-yellow-back text-yellow-text rounded-badge">Members Only <Lock/> </div>
        <>Accepted emails: {row?.suffix}</>
    </div>
) : (
    <div className="badge bg-green-back text-green-text rounded-badge">
        public
    </div>
)   



// createColumnHelper helps us create columns with maximum type safety.
// we assign the type person so that it knows the structure for our data
const colHelper = createColumnHelper();

const colHelpercols = [
    colHelper.display({
    header: () => <span className="text-[15.5px] text-gray-400 font-medium">Organization</span>,
    id: "logo",
    size: 30
    ,
    cell: (info) =>  <OrgLogo id={info.row?.original?.id}  />,
    }),
    colHelper.accessor((row) => row.name, {
    header: () => <span></span>,
    id: "name",
    size: 200,
    cell: (info) => <OrgSummary org={info.row.original} />,
    }),
  colHelper.accessor((row) => row.description, {
    id: "description",
    size: 300,
    cell: (info) => <OrgDescription description={info.getValue()}/>,
    header: () => <span className="text-[15.5px] text-gray-400 font-medium">Description</span>,
  }),
  colHelper.accessor((row) => row.private, {
    id: "private",
    size: 130,
    cell: (info) => <OrgRestriction row={info.row.original}/>,
    header: () => <span className="text-[15.5px] text-gray-400 font-medium">Restriction</span>,
  }),
  colHelper.display({
    id: "id",
    size: 1,
    cell: (info) => <JoinButton row={info.row.original}/>,
    header: () => <span className="text-[15.5px] text-gray-400 font-medium">Join</span>,
  }),
];
