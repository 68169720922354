
import { BasicPickupCard } from "@/components/Cards/BasicPickupCard";
import React from "react";
import { useAppContext } from "../../../../../../../context/AppContext";

const PickupOverview = ({text, lineItems, eventid}) => {
  const { setLoading, setMessage } = useAppContext();

  return (
    <div className="w-full min-h-content bg-white flex justify-center">
      <div className="container">
        <div className="w-full h-full ">
          <div className="flex flex-col items-center">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8 my-8 lg:mb-12.5">
              {Object.values(lineItems)?.map?.((item, index) => (
                <div key={`request-${index}`}>
                  <BasicPickupCard eventid={eventid} showRemaining key={item.id}  item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default PickupOverview;
