import { BD_MODE } from "@/constants";

export const getColumnVisibilityForMode = (mode, orgContext) => {
    switch (mode) {
        case BD_MODE.PIPELINE: 
            return {
                
            }
        case BD_MODE.IN_PROCESS:
            return {
                Actions: orgContext?.brand ? false : true
            }
        case BD_MODE.LIVE:
            return {
                Actions: false
            }
        case BD_MODE.HISTORICAL:
            return {
                Actions: false
            }
    }
}

const BD_APPROVER = {
    BRAND: 'brand',
    U11: 'unity11',
    PROCESSOR: 'processor',
    BRAND_AND_PROCESSOR: 'brandAndProcessor',
}
export const getApproveDestStatus = (statuses, mode, orgContext, event) => {
    const A = BD_APPROVER
    const currStatus = event?.status
    let approverRole = A.BRAND
    if (orgContext?.isUICContext)
        approverRole = A.U11
    else if (orgContext?.brand && event?.processor === orgContext?.orgid)
        approverRole = A.BRAND_AND_PROCESSOR
    else if (orgContext?.brand) 
        approverRole = A.BRAND
    else if (orgContext?.processor)
        approverRole = A.PROCESSOR

    switch (currStatus) {
        case statuses?.brandReview:
        case statuses?.brandDeclined:
            if ([A.BRAND, A.BRAND_AND_PROCESSOR, A.U11].includes(approverRole))
                return statuses?.unity11Review
        case statuses?.unity11Review:
            if (approverRole === A.U11)
                return statuses?.processorReview
        case statuses?.unity11Declined:
            if (approverRole === A.U11)
                return statuses?.processorReview
            if ([A.BRAND,A.BRAND_AND_PROCESSOR]?.includes(approverRole))
            return statuses?.unity11Review
        case statuses?.processorReview:
            if ([A.PROCESSOR, A.BRAND_AND_PROCESSOR,A.U11].includes(approverRole))
                return statuses?.live
        case statuses?.processorDeclined:
            return statuses?.live
        case statuses?.live:
            return statuses?.closed
        default: 
            return null
    }
}

export const getDeclineDestStatus = (statuses, mode, orgContext) => {
    if (orgContext?.brand) 
        return statuses?.brandDeclined
    if (orgContext?.isUICAdmin) 
        return statuses?.unity11Declined
    if (orgContext?.processor)
        return statuses?.processorDeclined
}

export const getFilteredRows = ({table, filterfn}) => {
    return table?.getFilteredRowModel?.()
        ?.reduce?.((acc, curr) => {
            if (filterfn)
                if (filterfn(curr))
                    acc.push(curr)
            else
                acc.push(curr)
        },[])
            .rows.map(row => row.original)
}

export const deselectRow = (table, rowID) => {
    table?.getRow(rowID)?.toggleSelected?.(false)
}

export const unpinRow = (table, rowID) => {
    table?.getRow(rowID)?.togglePinned?.(false)
}

// export const metaSorter = (a,b) =?{

// }