import { MILLISEC } from '@/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getCookie } from '@/utils';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getOrgTypes } from '@/apis/base/organizations/getOrgTypes';

export default function useGetOrgTypes(justLeafs=false) {
    const orgTypesData = useQuery({
        queryKey: [ROUTE_IDS.GET_ORG_TYPES],
        queryFn: getOrgTypes,
        staleTime: MILLISEC.MIN_15,
        select: (data) => {
          if (justLeafs) {
            return recursiveHelper(data,[])
          } else return data
        }

    });
  return orgTypesData;
}

const recursiveHelper = (arr,acc) => {
  arr.forEach(item => {
    if (item?.subnodes?.length > 0) {
      recursiveHelper(item.subnodes,acc)
    } else
      acc.push(item)
  })
  return acc
}