import { InputLabel } from "../Label/InputLabel";
import { InputContainer } from "./InputContainer";

export const InputBase = ({
    containerClassName,
    label=null,
    isError=false,
    errorText,
    required=false,
    disabled=false,
    children
}) => (
    <div className={`input-base ${containerClassName} ${label? 'form-control' : '' } `}>
        <InputContainer {...{label, required, isError, errorText}}>
            {children}
        </InputContainer>
    </div>	
)