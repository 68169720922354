import { DIRECTION } from '@/constants';
import React from 'react';
import { ChevronLeft, ChevronRight } from '../Icons';


export const SquareDirectionalButton = ({direction=DIRECTION.LEFT,onClick=()=>{}, className, ...props}) => {
    return (
        <div tabIndex={0} onClick={onClick} role={'button'} className={`rounded-md btn-square btn-little bg-base-100 flex justify-center items-center shadow-card hover:cursor-pointer ${className} pointer-events-auto `}>
            { direction === DIRECTION.LEFT  && (
                <ChevronLeft />
            )}
            { direction === DIRECTION.RIGHT && (
                <ChevronRight />
            )}
        </div>
    );
};

