
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation,useQueryClient } from '@tanstack/react-query';
import { createPopupRequest } from '@/apis/base/donation/popupStore/createPopupRequest';


export default function useCreatePopupRequest(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.CREATE_POPUP_REQUEST],
        mutationFn: createPopupRequest,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['popup']})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}