import React from 'react';


export const SubsectionTitle  = ({className, ...props}) => {
    return (
        <div className={`
            text-2xl font-semibold text-gray-700
            ${className}
        `}>
        {props.children}
        </div>
    );
};

