import React, { useEffect } from "react";
import { InputBase } from "./InputBase";

export const BasicTextArea = React.forwardRef(({
    label = null,
    name,
    className,
    id=`textarea_${label}_${name}`,
    isError: isControlledError=false,
    errorText: controlledErrorText,
    error,
    required = false,
    customSize = false,
    onChange = () => {},
    value,
    containerClassName,
    ...props
}, ref) => {
    const isError = isControlledError || error != null;
    const errorText = controlledErrorText || error?.message;
    useEffect(() => {
        const textarea = document.getElementById(id)
        if (textarea) {
            textarea.style.height = "";
            textarea.style.height = textarea.scrollHeight + "px";
        } 
    },[value])
    
    return (
    <InputBase
        {...{
            label,
            isError,
            errorText,
            required,
            containerClassName,
        }}
    >
        <textarea
            value={value}
            id={id}
            ref={ref}
            onInput={onInput}
            name={name}
            required={required}
            onChange={onChange}
            style={{
                willChange:'height',
            }}
            className={
                `will-change-[height] textarea w-full focus:outline-none placeholder: focus:border-primary
                disabled:text-gray-subText
                min-h-[40px]
                ${isError ? 'textarea-error' : 'textarea-bordered'}
                ${customSize ? '' : 'input-little'}
                ${className}
                `}
            {...props}
        />
    </InputBase>
)
})
    
const onInput = (e) => {
    e.currentTarget.style.height = "";
    e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
}