import { SEE_CATEGORY } from '@/constants';
import clsx from 'clsx';
import * as _ from 'lodash';
import { twMerge } from 'tailwind-merge';

export function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && 
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) 
    );
}

export function observeVisibility(el, onVisibleStart, onVisibleEnd, containerEl=window) {
    const observer = new containerEl.IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        console.log('ENTER')
        onVisibleStart()
        return
      }
      console.log('LEAVE')
      onVisibleEnd()
    }, {
      root: null,
      threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
    })
    
    observer.observe(el);
    return () => observer.disconnect()
}
// If this is not called on a form, react hook form does not see autofilled fields
export function checkFormForAutofill(formState, handleSubmit = ()=>{},e) {
  const fieldNames = Object.keys(formState?.defaultValues)
  // console.log('names',fieldNames);
  for (const name of fieldNames) {
    const d = document.getElementsByName(name)?.[0]
    d?.focus?.()
    d?.blur?.()
  }
  e?.preventDefault?.()
  handleSubmit(e)
}
export function observeResize(el, onResize) {
    const observer = new ResizeObserver(onResize)
    observer.observe(el)
    return () => observer.disconnect()
}

export const getObjectDiff = (obj1, obj2, compareRef = false) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
}

export const getStringDiff = (diffMe, diffBy) => diffMe.split(diffBy).join('')


export function throttle(func, limit) {
  let inThrottle = false;
  
  return function() {
    if (!inThrottle) {
      func.apply(this, arguments);
      inThrottle = true;
      
      setTimeout(function() {
        inThrottle = false;
      }, limit);
    }
  };
}

export function blurActive() {document.activeElement.blur()}

export function arrayIntersect(arr1, arr2) {return arr1.filter(value => arr2.includes(value))}

export function arrayIncludedExcluded(arr, filter) {
  const inc = []
  const exc = []
  for (const value of arr) {
    if (filter.includes(value)) inc.push(value)
    else exc.push(value)
  }
  return [inc,exc]
}


export function SEECategoryToBGClass(category) {
  switch (category) {
    case SEE_CATEGORY.ECONOMIC:
      return 'bg-red-see'
    case SEE_CATEGORY.ENVIRONMENTAL:
      return 'bg-green-see'
    case SEE_CATEGORY.SOCIAL:
      return 'bg-blue-see'
    default:
      return 'bg-blue-see'
  }
}

export function isTrue(value) {
  return value === 'true' || value === true || value === 1 || value === '1'
}

export function notNull(value) {
  return value !== null && value !== undefined && value !== '' && value !== 'null' && value !== 'undefined'
}

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function filterDebugOrgs(data) {
  return data?.filter?.(o => 
    !o?.name?.toLowerCase()?.startsWith('dfu') 
  )
}

export function getFlatWishlistProductsWithSuperCategory(wishlistTree) {
  return wishlistTree
    .map((superCategory) => {
        if (superCategory.subnodes) {
            return superCategory.subnodes
                .map((category) => {
                    if (category.name === "Shirts Dress & Casual")
                        console.log("cat products", category.products);
                    return category.products.map((product) => ({
                        ...product,
                        subCategory: category.name,
                        superCategory: superCategory.name,
                    }));
                })
                .flat();
        } else {
            return superCategory.products.map((product) => ({
                ...product,
                superCategory: superCategory.name,
            }));
        }
    })
    .flat();
}

// A and B must have superCategory and subCategory
export function compareWishlistProducts(a, b) {
  if (a.superCategory === b.superCategory) {
        if (a.subCategory && !b.subCategory) {
            return -1;
        } else if (!a.subCategory && b.subCategory) {
            return 1;
        } else if (a.subCategory === b.subCategory) {
            if (a.name > b.name) {
                return 1;
            }
            else if (a.name < b.name) {
                return -1;
            }
            else {
                console.log('last bucket 1', a, b);
                return 0;
            }
        } else if (a.subCategory > b.subCategory) {
            return 1;
        } else if (a.subCategory < b.subCategory) {
            return -1;
        } else {
            console.log('last bucket 2', a, b, a.subCategory < b.subCategory, a.subCategory > b.subCategory);    
            return 0;
        }
    } else if (a.superCategory < b.superCategory) {   
        return -1;
    }
    else if (a.superCategory > b.superCategory) {
        return 1;
    } else {
        return 0;
    }
}