import useAddOrgAdmin from "@/hooks/state/admin/useAddOrgAdmin"
import useMembers from "@/hooks/state/admin/useMembers"
import useRemoveMember from "@/hooks/state/admin/useRemoveMember"
import useRemoveOrgAdmin from "@/hooks/state/admin/useRemoveOrgAdmin"
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext"
import useOrgWithId from "@/hooks/state/organization/useOrg"
import { openDialogWithRef } from "@/utils"
import { ctxToPermission } from "@/utils/userUtils"
import { queryResultFns } from "@/wrappers/swal/queryResultFns"
import { useRef, useState } from "react"
import { BasicRectButton } from "../Buttons/BasicRectButton"
import { UserInvitationDialog } from "../Dialog/UserInvitationDialog"
import { MemberAddIcon } from "../Icons"
import { UserTable } from "../Tables/UserTable/UserTable"
import UserProfile from "../User/UserProfile"
import useFlatMembers from "@/hooks/state/admin/useFlatMembers"

const BODY_CONTENT = {
    ALL_MEMBER: "all_member",
    MEMBER_DETAIL: "member_detail",
};

export const UserManagementPage = ({ orgid, standardCommunity=true, ...props }) => {
    const dialogRef = useRef();  
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [bodyContent, setBodyContent] = useState(BODY_CONTENT.ALL_MEMBER);
    const membersQuery = useFlatMembers({orgID: orgid})
    const ctx = useCurrentOrgContext().data
    const orgQuery = useOrgWithId({org_id:  ctx?.orgid, recursive: 1, showMembers: true})
    const removeUserMutation = useRemoveMember()
    const makeAdminMutation = useAddOrgAdmin(queryResultFns(
        'Admin added',
        'Failed to add admin'
    ))
    const removeAdminMutation = useRemoveOrgAdmin(queryResultFns(
        'Admin removed',
        'Failed to remove admin'
    ))
    const handleProfileClicked = (user) => {
        setSelectedUser(user);
        setBodyContent(BODY_CONTENT.MEMBER_DETAIL);
    }

    const handleBackClicked = () => {
        setSelectedUser(null);
        setBodyContent(BODY_CONTENT.ALL_MEMBER);
    }

    const deleteUser = (orgid, userid) => removeUserMutation.mutate({orgid, userid})

    const onInviteBtnClicked = () => {
        openDialogWithRef(dialogRef)
    }

    function handleAdminToggle(row) {
        if (row.admin === true) 
            removeAdminMutation.mutate({
                orgid: ctx.orgid,
                userid: row.id
            })
        else 
            makeAdminMutation.mutate({
                orgid: ctx.orgid,
                userid: row.id
            })
    }

    return bodyContent === BODY_CONTENT.ALL_MEMBER ? (
        <>
            <UserTable 
                {...{
                    users: membersQuery.data,
                    permission: ctxToPermission(ctx),   
                    standardCommunity,
                    deleteUser,
                    handleAdminToggle,
                    handleBackClicked,
                    handleProfileClicked
                }}
                {...props}
                >
                {/* <BasicRectButton 
                    className={'bg-blue-cornflower text-white'}
                    onClick={onInviteBtnClicked} 
                    > 
                    Invite
                    <MemberAddIcon/>
                </BasicRectButton> */}
            </UserTable>
            <UserInvitationDialog orgsData={orgQuery.data} dialogRef={dialogRef}/>
        </>
    ) : (
        <UserProfile backLabel="Back to Members Table" showRoles={standardCommunity} user={selectedUser} exitDetailView={handleBackClicked}/>
    )
}