import { ROUTE_IDS } from '@/apis/base/routeId';
import { getMyUserData } from '@/apis/user/userData';
import { MILLISEC } from '@/constants';
import { ensureToken } from '@/utils';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';

export default function useMyUserData() {
  // const [cookies, setCookie, removeCookie] = useCookies(['token']) 
  // const config = useMemo(() => myUserDataQueryConfig(), [cookies.token])
  const userQuery = useQuery({
    queryKey: ['user', 'me', ROUTE_IDS.GET_MY_USER_DATA],
    queryFn: getMyUserData,
  staleTime: MILLISEC.SEC * 3,
    retry:2,
    enabled: ensureToken(),
    gcTime: MILLISEC.SEC_30,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    select,
  });
  // useEffect(() => {
  //   // if (cookies.token)
  //   // console.log('token changing', cookies.token);
  //   userQuery.refetch()
  // },[cookies.token])
  return userQuery
}

export const myUserDataQueryConfig = () => queryOptions({
    queryKey: ['user', 'me', ROUTE_IDS.GET_MY_USER_DATA],
    queryFn: getMyUserData,
    staleTime: MILLISEC.SEC * 3,
    retry:2,
    enabled: ensureToken(),
    gcTime: MILLISEC.SEC_30,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    notifyOnChangeProps: ['data'],
    select,
})

function select(data) {
  // console.log('in user select');
  data.isUICAdmin = ['10','13'].includes(data?.['admin level'])
  data.userid = data.id
  data.fullname = data.name
  data.firstname = data?.['first name']
  data.lastname = data?.['last name']
  return data
}