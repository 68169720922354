import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ open: false });

  // SCP Contexts
  const [scpStatus, setScpStatus] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [roles, setRoles] = useState([]);
  const [defaultTab, setDefaultTab] = useState(0);
  
  
  const [currentOrg, setCurrentOrg] = useState(null);

  const getCategoryName = (category_id) => {
    return categories.find(category => category.id === category_id)?.name || ''
  };

  const getCategoryIdWithProductId = (product_id) => {
    return products.find(product => product.id === product_id)?.category_id || '';
  };

  const getProductName = (product_id) => {
    return products.find(product => product.id === product_id)?.name || ''
  };

  const getProductWithId = (product_id) => {
    return products.find(product => product.id === product_id) || null;
  };

  const getTemplateName = (id) => {
    return templates.find(t => t.id === id)?.name || 'Custom';
  };

  const getTemplateWithId = (id) => {
    return templates.find(t => t.id === id) || null;
  }

  const getProductsData = (items) => {
    return items.map((item, index) => (
      index === 0
        ? getProductName(item.product_id) + ': ' + item.quantity
        : ', ' + getProductName(item.product_id) + ': ' + item.quantity
    ));
  };
  const resetApp = () => {
    setCategories([]);
    setBrands([]);
    setProducts([]);
    setTemplates([]);
    setScpStatus(false);
    setCurrentOrg(null);
    setRoles([]);
    setDefaultTab(0);
  }

  return (
    <AppContext.Provider 
      value={{ 
        loading, 
        message, 
        scpStatus,
        setLoading, 
        setMessage,
        // SCP Contexts
        categories,
        brands,
        products,
        templates,
        setScpStatus,
        setCategories,
        resetApp,
        setBrands,
        setProducts,
        setTemplates,
        getCategoryName,
        getProductName,
        getTemplateName,
        getProductsData,
        getProductWithId,
        getTemplateWithId,
        getCategoryIdWithProductId,
        currentOrg,
        setCurrentOrg,
        roles,
        setRoles,
        defaultTab,
        setDefaultTab
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object,
};

export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
