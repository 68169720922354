import { WhiteCard } from '@/components/Cards';
import { WishlistIcon } from '@/components/Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons';
import { CheckBoxIcon, LinkIcon } from '@/components/Icons';
import useBrandPrefRegionalReport from '@/hooks/state/admin/uic/useBrandPrefRegionalReport';
import React from 'react';

export const BrandPrefReportCard = ({history, ...props}) => {
    const prefQuery = useBrandPrefRegionalReport();
    function navToPrefReport() {
        history.push('reporting/brand-preferences')
    }
    return (
        <div className="card will-change-[shadow] card-compact bg-white shadow-sm hover:shadow-all-light transition-shadow duration-700 hover:cursor-pointer w-full" onClick={navToPrefReport}>
            <div className="card-body">
                <div className="flex w-full justify-between">
                    <CheckBoxIcon className='w-7 h-7 opacity-50 mt-3' />
                    <LinkIcon className='w-4 h-4'/>
                </div>
                <h2 className="card-title">
                    Brand Preferences
                </h2>
                <p className='text-gray-subText'>   
                    Tracking preferences across <span className='font-semibold text-gray-darkSubText'>{prefQuery.data?.length || '...'}</span> brands
                </p>
            </div>
        </div>
    );
};

