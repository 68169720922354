import React, { Fragment } from 'react';

export const TwoColumnRow = ({children, ...props}) => {
    return Array.isArray(children) &&(
        <Fragment>
            <div className='col-start-[first-start] place-self-start'>
                {children?.[0]}
            </div>
            <div className='col-start-[second-start]'>
                {children?.[1]}
            </div>

        </Fragment>
    );
};
