
import React, { useState } from "react";

import { SlideModal } from "@/components/Cards/SlideModal";
import { EligibleLocationsTable } from "@/components/Tables/EligibleLocationsTable/EligibleLocationsTable";
import { useAppContext } from "@/context/AppContext";
import useDonationLocations from "@/hooks/state/donation/useDonationLocations";
import useModBDEvent from "@/hooks/state/donation/useModBDEvent";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { LocalMultiSelect } from "./components/LocaleMultiSelect";

const BrandDonationRegion = ({ text, canModify}) => {
    const { setLoading, setMessage } = useAppContext();
    const [available, setAvailable] = useState([]);
    const [selectedRegion,setSelectedRegion] = useState('');
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const {eventid, orgid} = useParams();
    const locations = useDonationLocations({eventid, orgid});
    const eventMutation = useModBDEvent({
        orgid,
        onSuccess: (data) => {SuccessToast.fire({text: 'Event Availability Updated'})},
        onError: (error) => {FailPopup.fire({text: 'Failed to update event availability'})}
    });

    const handleEdit = (region) => {
        setSelectedRegion(region);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const handleSubmit = ({regionid, cities}) => {
        // if (cities?.length === 0)  {
        //     setShowModal(false)
        //     return
        // }

        const otherRegions = locations.data?.flatRegions?.filter?.(r => r.id !== regionid)
            .map(r => ({regionid: r.id, cities: r?.cities.map(c => c.id)}));
        const params = {
            orgid,
            eventid,
            locales: [...(otherRegions ?? []), {regionid, cities}]
        }
        eventMutation.mutate(params);
        setShowModal(false);
    }

    return (
        <>
            <EligibleLocationsTable
                canModify={canModify}
                data={locations.data} handleEdit={handleEdit}

            />
            <SlideModal setShow={setShowModal} show={showModal} topClass='top-[40%]'>
                <LocalMultiSelect close={closeModal} handleSubmit={handleSubmit} region={selectedRegion}/>
            </SlideModal>
                {/* <div className="  w-full">
                </div> */}
        </>
    )
};

export default BrandDonationRegion;
