import { modOrgData } from '@/apis/base/organizations/modOrgData';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useMyOrgs from './useMyOrgs';


export default function useModOrgData({onSuccess, onError}) {
    const myOrgsQuery = useMyOrgs()
    const client = useQueryClient()
    const query = useMutation({
        mutationKey: ['context', 'user',ROUTE_IDS.MOD_ORG_DATA],
        mutationFn: modOrgData,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data,req) => {
            console.log(data,req);
            client.invalidateQueries({queryKey: ['org']})
            client.invalidateQueries({queryKey: ['user']})
            if (myOrgsQuery.data && myOrgsQuery.data.some(o => o === String(req.orgid))) 
                client.invalidateQueries({queryKey: ['context', 'org', req.orgid]})
            onSuccess(data)
        }

    });
    return query;
}