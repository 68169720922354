import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation,useQueryClient } from '@tanstack/react-query';
import { confirmBDShipment } from '@/apis/base/donation/shipment/confirmBDShipment';
import useAddOrgSponsorship from '../../organization/sponsorships/useAddOrgSponsorship';
import useSponsorshipEnum from '../../organization/sponsorships/useSponsorshipEnum';

export default function useConfirmBDShipment({brandorgid,...props}) {
    const orgSponsorMutation = useAddOrgSponsorship({onSuccess: () => {console.log('added org sponsor');}})
    const sponsorshipEnumQuery = useSponsorshipEnum()
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.CONFIRM_SHIPMENT_ARRIVAL],
        mutationFn: confirmBDShipment,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['bd', 'event', req.eventid ]})
            onSuccess && onSuccess(data)
            if (!req.recipientorgid ||!req.eventid || !brandorgid) return
            orgSponsorMutation.mutate({
                    orgid: req.recipientorgid, 
                    sponsororg: brandorgid, 
                    sponsorType: sponsorshipEnumQuery.data?.['Bulk Donation']?.id, 
            })
        },
        ...otherProps
    });
    return mutation;
}