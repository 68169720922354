import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getOrgTree } from '@/apis/base/organizations/getOrgTree';


export default function useOrgTree({orgid}) {
    const query = useQuery({
        queryKey: ['org', orgid,ROUTE_IDS.GET_ORG_TREE],
        queryFn: (params) => getOrgTree({orgid}),
        enabled: !!orgid,
    });
    return query;
}