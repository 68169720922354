import React from 'react';

import { useTranslations } from '@/hooks/translations';

import SubPages from './components/pages';
import { PageTitle } from '@/components/Label/PageTitle';

function MainDonation(props) {
  const text = useTranslations('donation');

  return (
    <div className="w-full min-h-screen bg-primary flex justify-center py-12">
      <div className="container">
        <div className="w-full h-full px-4 sm:px-6 xl:px-8">
          <PageTitle>{text.title}</PageTitle>
          <SubPages text={text} history={props.history} />
        </div>
      </div>
    </div>
  );
}

export default MainDonation;
