export function downloadCSV(fileName, headers, rows) {
    console.log(rows);
    const preamble = 'data:text/csv;charset=utf-8,'
    const rowContent = 
        rows.map(row => 
            row.map(cell => 
                String(cell).replaceAll(',','')
            ).join(",")
        ).join("\n")
    const csvContent =  headers + '\n' + rowContent;
    const encodedUri = encodeURI(preamble + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

