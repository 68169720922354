import { uploadProductImages } from '@/apis/base/lambda/uploadImages';
import { BasicDialog } from '@/components/Dialog/BasicDialog';
import { BasicDropdownInput } from '@/components/Input/BasicDropdownInput';
import { BasicInput } from '@/components/Input/BasicInput';
import { BasicTextArea } from '@/components/Input/BasicTextArea';
import { ImageUploadAndAuditor } from '@/components/Input/ImageUploadAndAuditor';
import { IMAGE_TYPE } from '@/constants';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useAddWishlistProduct from '@/hooks/state/wishlist/useAddWishlistProduct';
import useModWishlistProduct from '@/hooks/state/wishlist/useModWishlistProduct';
import useWishProductCategories from '@/hooks/state/wishlist/useWishProductCats';
import useWishProducts from '@/hooks/state/wishlist/useWishProducts';
import useWishTargetCust from '@/hooks/state/wishlist/useWishTargetCust';
import { useYupValidationResolver } from '@/hooks/utils/useYupValidationResolver';
import { closeDialogWithRef } from '@/utils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string().required('Please enter the product name'),
    categoryid: Yup.string().transform((cat) => cat.id).required('Please select the category'),
    targetid: Yup.string().transform((target) => target.id).required('Please select the demographic'),
    description: Yup.string().required('Please enter the product description'),
    // image: Yup.string().required('Please upload an image')
})

const defaultValues = {
    name: '',
    categoryid: '',
    targetid: '',
    description: '',
}
export const ManageWishlistProductDialog = ({
    dialogRef,
    isExisting=false, 
    existingProductID=null,
    categoryData,
    demographicData,
    userid,
    productsData,
    handleModFinish=()=>{},
    ...props}) => {
    const imageRef = useRef()
    const auditorRef = useRef()
    const [existingProduct, setExistingProduct] = useState(null)
    const [submissionLoading, setSubmissionLoading] = useState(false)
    // const ctx = useCurrentOrgContext();
    // const categoryQuery = useWishProductCategories();
    // const demographicQuery = useWishTargetCust();
    const client = useQueryClient()
    const modProductMutation = useModWishlistProduct({
        onSettled: handleMutationSettled,
        onSuccess: (data, req) => handleMutationSuccess('product modified', req?.productid),
        onError: () => FailPopup.fire('Failed to modify product')
    })
    const productMutation = useAddWishlistProduct({   
        onSettled: handleMutationSettled,
        onSuccess: (data, req) => handleMutationSuccess('Product added', data?.nodeid),
        onError: () => FailPopup.fire('Failed to add product')
    })
    const resolver = useYupValidationResolver(validationSchema);
    const {register, handleSubmit, formState, setValue, reset, control}
        = useForm({resolver, defaultValues, mode: 'onBlur'});

    useEffect(() => {
        // console.log('here1, ', isExisting, existingProductID, productsData);
        if (isExisting && existingProductID && productsData)
            handleLoadExistingProduct()
    },[isExisting, existingProductID, productsData])
    
    function handleLoadExistingProduct() {
        const targetProduct = productsData.find(p => p.id === existingProductID) || {}
        setExistingProduct(targetProduct)
        const categoryid = categoryData.flat.find(cat => cat.id === targetProduct.categoryid)
        const targetid = demographicData.find(target => target.id === targetProduct.targetid)
        reset({
                ...targetProduct,
                categoryid,
                targetid,
        })
    }
    function handleMutationSuccess(successMessage, nodeid) {
        SuccessToast.fire(successMessage)
        uploadNewImages(nodeid)
    }
    function handleDialogClose() {
        reset(defaultValues)
        setSubmissionLoading(false)
        imageRef.current.value = ''
        auditorRef.current?.resetImages?.()
        handleModFinish()
    }
    function handleMutationSettled() {
        client.invalidateQueries('wishlist')
        closeDialogWithRef(dialogRef)
        setSubmissionLoading(false)
    }
    function handleFormFinish(fields) {
        if (isExisting) handleProductMod(fields)
        else handleProductCreation(fields)

    }
    function uploadNewImages(nodeid) {
        const imageArray = Array.from(imageRef.current.files)
        if (imageArray?.length < 1) return
        uploadProductImages({
            client,
            uploads: Array.from(imageRef.current.files),
            productID: nodeid,
            userID: userid
        })
    }
    async function handleProductMod(fields) {
        const imageArray = Array.from(imageRef.current.files)
        if (imageArray?.length > 0)  {
            const imageName = existingProductID
            setSubmissionLoading(true)
            modProductMutation.mutate({
                productid: existingProduct.id,
                image: 'wishlist-' + imageName,
                ...fields
            })
        }
        else {
            modProductMutation.mutate({
                productid: existingProduct.id,
                ...fields,
                image: existingProduct.images
            })
        }
        setExistingProduct(null)
    }
    function handleProductCreation(fields) {
        const imageName = 'v2' + fields.name.split(' ').join('_')
        setSubmissionLoading(true)
        productMutation.mutate(fields)
        uploadProductImages({
            client,
            uploads: Array.from(imageRef.current.files),
            productID: imageName,
            userID: userid
        })    }
    return (
        <BasicDialog
            ref={dialogRef}
            handleSubmit={handleSubmit(handleFormFinish)}
            confirmLoading={submissionLoading}
            onClose={handleDialogClose}
            title={isExisting ? 'Modify Wishlist Product' : 'Add Wishlist Product'}
            formID={'addWishlistProductForm'}
            {...props}
        >
            <BasicInput
                required
                label='Name'
                placeholder='Enter Product Name'
                error={formState.errors.name}
                {...register('name')}
            />
            <BasicTextArea
                required
                label='Description'
                placeholder='Enter Product Description'
                error={formState.errors.description}
                {...register('description')}
            />
            <BasicDropdownInput
                hasSubOptions
                control={control}
                required
                isSearchable
                renderFn={(option) => option?.name}
                label='Product Category'
                placeHolder={"Pick a Product Category"}
                data={categoryData?.tree}
                {...register('categoryid')}
            />
            <BasicDropdownInput
                control={control}   
                required
                isSearchable
                label='Demographic Target'
                renderFn={(option) => option?.name}
                placeHolder={"Pick a Demographic Target"}
                data={demographicData}
                {...register('targetid')}
            />
            <ImageUploadAndAuditor
                ref={auditorRef}
                imageRef={imageRef}
                preexistingImages={isExisting ? existingProduct?.images : null} 
                subjectNodeID={existingProduct?.id}
                subjectImageType={IMAGE_TYPE.PRODUCT}
                required={!isExisting}
                label={'Images'}
            />
        </BasicDialog>
    );
};
