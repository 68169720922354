import { getCookie, setCookie } from '@/utils';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { act } from 'react-dom/test-utils';
import { set } from 'react-hook-form';


export const BasicBoxTabs = ({
    autonomous=false, 
    onChange=() =>{},
    activeTab: initialActiveTab = 0,
    tabNames, 
    className = '', 
    containerClassName = '', 
    tabClassName,
    children,
    ...props}) => {
    const [activeTab, setActiveTab] = useState(initialActiveTab)

    useEffect(() => {
        initTabs()
    },[])

    useLayoutEffect(() => {
        setActiveTab(initialActiveTab)
    },[initialActiveTab])
    

    function initTabs() {
        const rememberedTab = Number(getCookie(tabNames.join(''))) ?? 0
        if (autonomous) setActiveTab(rememberedTab)
        else onChange(rememberedTab)
    }

    function onTabClick(index) {
        setCookie(`${tabNames.join('')}`, index)
        if (autonomous) setActiveTab(index)
        else onChange(index)
    }

    return (
        <>
            <div role='tablist' className={`tabs bg-neutral tabs-boxed  ${className}`}>
                { tabNames?.map?.((name, idx) => (
                    <a
                        key={name}
                        onClick={()=>onTabClick(idx)}
                        role='tab'
                        className={`
                        tab whitespace-nowrap 
                        ${!tabClassName && activeTab === idx ? 'tab-active' : tabClassName} `}>{name}</a>
                ))}
            </div>
            <div className={`${containerClassName}`}>
            { !autonomous && Array.isArray(children) 
                ? children?.filter(Boolean)?.[activeTab]
                : children
            }
            </div>
        </>
    );
};

