import usePopupRequests from "@/apis/base/donation/popupStore/usePopupRequests"
import { subObject } from "@/utils"
import { useEffect, useState } from "react"

export const usePopupAdminData = ({popupid}) => {
    const pickupQuery = usePopupRequests({
        popupid,
        refetchInterval: 5000
    })
    const [lineItems, setLineItems] = useState({})
    const [requests, setRequests] = useState({})
    // const flatInventories = useMemo(() => Object.values(lineItems), [lineItems])

    useEffect(() => {
        if (!pickupQuery.data?.length) return
        const initItems = lineItems || {}
        const initRequests = requests || {}
        for (const item of pickupQuery.data) {
            const {productname, id: inventoryid, description, size } = item.item
            const {id, qtyconfirmed} = item
            if (qtyconfirmed === '0') continue
            if (initItems[item?.id] == null) 
                initItems[item?.id] = {
                    shipmentid: id,
                    inventoryid,
                    qtyconfirmed,
                    productname,
                    description,
                    size
                }
            initItems[item?.id].remaining = Number(qtyconfirmed)
            const deliveryAdjustment =  {
                label: 'Delivered',
                adjustment: '+ ' + item.qtyconfirmed
            }
            const requestAdjustments = []
            const sortedRequests = item?.requests?.sort(compareRequests) || []
            for (const r of sortedRequests) {
                if (!initRequests[r.userid]) initRequests[r.userid] = 
                    {   
                        ...subObject(r, ['userid','username', 'email']),
                        picked: false,
                        items: []
                    }
                if (Number(r.qtyconfirmed) > 0) {
                    requestAdjustments.push({
                        label: 'Picked up by ' + r.username,
                        adjustment: '- ' + r.qtyconfirmed
                    })
                    initRequests[r.userid].picked = true
                    initItems[item.id].remaining -= Number(r.qtyconfirmed)
                }
                initRequests[r.userid].items[id] = {
                    ...initRequests[r.userid].items[id],
                    shipmentid: id,
                    quantity: r.quantity,
                    inventoryid,
                    productname,
                    qtyconfirmed: r.qtyconfirmed,
                }
            }
            initItems[item?.id].itemAdjustments = [deliveryAdjustment, ...requestAdjustments]
        }
        setLineItems({...lineItems, ...initItems})
        setRequests({...requests, ...initRequests})
    },[pickupQuery.data])
    // console.log(pickupQuery.isFetching, pickupQuery.isRefetching);
    return {lineItems, requests, loading: pickupQuery.isFetching}
}

function compareRequests(a,b)  {
    return (a?.modified < b?.modified) ? -1 : 1
}