//components/ClientSideTable.tsx
import { BasicBlueRectButton } from "@/components/Buttons/BasicBlueRectButton";
import { BD_MODE } from "@/constants";
import { prefetchBDEventByID } from "@/hooks/state/donation/useBDEventByID";
import { prefetchDonationLocations } from "@/hooks/state/donation/useDonationLocations";
import useBDStatusEnum from "@/hooks/state/enum/useBDStatusEnum";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { Add } from "@material-ui/icons";
import { useQueryClient } from "@tanstack/react-query";
import React, { useLayoutEffect, useState } from "react";
import { BasicTable } from "./BasicTable";
import { donationColumnHelper } from "./donationColumnHelper";


const DonationEventsTable = ({role, mode, auditEnabled, internalProcessing, handleCreate, handleApprove, handleDecline, handleEdit, data }) => {
  const client = useQueryClient()
  const orgContextQuery = useCurrentOrgContext();
  const eventStatusQuery = useBDStatusEnum();
  const eventStatuses = eventStatusQuery?.data;
  const ctx = orgContextQuery?.data;
  const safeData = data?.length > 0 ? data : [];
  const [statusFilters, setStatusFilters] = useState([])

  useLayoutEffect(() => {
    setStatusFilters(getStatusFilterByMode(mode))
  },[mode,eventStatusQuery.data])

  const handleLocationPrefetch = (eventid, orgid) => {
    prefetchDonationLocations(client, eventid, orgid)
  }

  const handleInventoryPrefetch = (eventid, orgid) => {
    prefetchBDEventByID(client, eventid, orgid)
  }
  
  const getStatusFilterByMode = (mode) => {
    let statusFilters = null
    let endTimeFilters = null
    switch(mode) {
      case BD_MODE.PIPELINE:
          statusFilters = eventStatuses?.pipelineStatuses
        break;
      case BD_MODE.IN_PROCESS:
          statusFilters = eventStatuses?.inProcessStatuses
        break;
      case BD_MODE.LIVE:
        statusFilters = [eventStatuses?.live, eventStatuses?.closed]
        endTimeFilters = {min: Date.now() / 1000, max: Number.MAX_VALUE  }
        break;
      case BD_MODE.HISTORICAL:
        statusFilters = eventStatuses?.liveStatuses
        endTimeFilters = {min: 0, max: Date.now() / 1000  }
        break;
      default: 
        break;
    }
    return statusFilters ? [
      {
        id: 'Status',
        value: statusFilters
      },
      {
        id: 'End',
        value: endTimeFilters
      }
  ] : []
  }
    return (
      <BasicTable 
        columnHelper={donationColumnHelper}
        data={safeData}
        extraFilters={statusFilters}
        initialState={{
          sorting: [
            {
              id: 'Modified',
              desc: true
            }
          ]
        }}
        state={{
          columnVisibility: {'Actions': auditEnabled}
        }}
        meta={{
          mode, 
          eventStatuses, 
          handleEdit, 
          handleApprove, 
          handleDecline, 
          handleLocationPrefetch, 
          handleInventoryPrefetch,
          internalProcessing,
          showingHistorical: mode === BD_MODE.HISTORICAL
        }}
        >
        {  mode === BD_MODE.PIPELINE && (ctx?.brand || ctx?.isUICContext) && (
            <BasicBlueRectButton onClick={handleCreate} className='w-50 rounded-2xl'>
            Add
            <Add/>
            </BasicBlueRectButton>
          )}
        
      </BasicTable>
);
};


export { DonationEventsTable };
