import { TrackedImage } from '@/components/Image/TrackedImage';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { collapseObjIntoArray, getEventInventoryUrl } from '@/utils';
import React, { useMemo } from 'react';


export const ConfirmPopupPickupTable = ({values, eventid, ...props}) => {
    // const {brandorgid, eventid} = communityState || {}
    const {data: ctx} = useCurrentOrgContext() || {}
    return (
        <div className="rounded-xl border-gray-border border min-h-[20rem] overflow-y-scroll w-fu">
            <table className="table table-lg table-zebra place-items-center place-content-center w-full">
                <TableHead/>
                <tbody>
                { values.map((item, idx) => (
                    <TableRow 
                        key={item.inventoryid}
                        {...{
                            item,
                            eventid, 
                            idx,
                            ...props
                        }}
                    />
                ))}
                </tbody>
            </table>
     </div>
    );
};

const  TableHead = () => (
    <thead>
        <tr>
            <th>NO</th>
            <th></th>
            <th>PRODUCT</th>
            <th>QUANTITY</th>
        </tr>
    </thead>
)

const TableRow = ({idx, item, onChange, eventid, brandorgid}) => {
    const {qtyconfirmed, inventoryid, shipmentid, productname} = item || {}

    const handleChange = (e) => {
        const {name, value} = e.target
        onChange({[shipmentid]: {...item, qtyconfirmed: Number(value)}})
    }
    return (
    <tr>
        <td>
            <div className="flex items-center gap-3">
                {idx}
            </div>
        </td>
        <td>    
            <div className="flex dropdown dropdown-hover dropdown-top w-fit">
                <TrackedImage src={getEventInventoryUrl( eventid, inventoryid, 0)} alt="" className='w-16 rounded-md'/>
                    <div className='dropdown-content pointer-events-none w-48 left-[50%] mb-1 -ml-[6rem] mx-auto'> 
                        <TrackedImage 
                            src={getEventInventoryUrl( eventid, inventoryid, 0)} 
                            alt={"inventory" + idx}
                            className='w-full rounded-md' />
                    </div>
            </div>
        </td>
        <td>
        {productname}
        </td>
        <td>
            <input type="number" className='input input-sm border border-gray-border text-md max-w-[5rem] focus:outline-none' value={qtyconfirmed} name='qtyconfirmed' onChange={handleChange}/>
        </td>

    </tr>
)}