import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS,composeFormData } from "@/apis/base/routeId"


export const getMyPopupRequests = (params) => {
    return new Promise((resolve, reject) => {
        postPromise(config.API_BASE_URL,composeFormData(params, ROUTE_IDS.GET_MY_POPUP_REQUESTS))
        .then((res) => {
            console.log('result',res);
            if (res?.status != null) reject(res)
            else resolve(res)
        })
    })

}
