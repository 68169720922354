
import useOrgPopups from "@/hooks/state/donation/popupStore/useOrgPopups";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import useMyWishlist from "@/hooks/state/wishlist/useMyWishlist";
import React from "react";
import { BasicBDPopupCard } from "../BasicBDPopupCard";
import { Popup } from "@/wrappers/swal/Popup";
import { timeStampIsExpired } from "@/utils";

const PickupEvent = (props) => {
  const { history, text } = props;
  const contextQuery = useCurrentOrgContext()
  const { data: ctx } = contextQuery || {}
  const myWishlistQuery = useMyWishlist({enabled: !ctx?.isAdmin, select: data=>data})

  const popupsQuery = useOrgPopups({orgid: ctx?.orgid})

  const onDonationClicked = (popup) => {
    if (ctx.isAdmin || ctx.ambStatus === 'Approved') history.push(`/donation/pickup/${popup.eventid}/${popup.id}/admin`)
    else {
      const hasSubmittedWishlist = myWishlistQuery.data || myWishlistQuery.data?.length > 1 
      const hasNonExpiredWishlist = !timeStampIsExpired(myWishlistQuery.data?.[0]?.submissiontimestamp)
      console.log("hasSubmittedWishlist", hasSubmittedWishlist)
      console.log("hasNonExpiredWishlist", hasNonExpiredWishlist)
      if (!hasNonExpiredWishlist || !hasSubmittedWishlist)
        Popup.fire({
          text: 
            !hasSubmittedWishlist ? 
              'Users must submit a wishlist before they can participate in a donation event.' : 
              'Users must have a wishlist submitted in the within the last 3 months to participate in a donation event.',
          confirmButtonText:'Take me to my wishlist',
          cancelButtonText: 'I\'ll do it later',
        }).then((result) => {
          if (result.isConfirmed) history.replace('/wishlist')
        })

      if (hasSubmittedWishlist && hasNonExpiredWishlist) {
        history.push(`/donation/pickup/${popup.eventid}/${popup.id}`)
      }
  }
  }

  

  return (
    <div className="mb-12">
      <div className="flex flex-col items-center">
        <div className="w-full">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8">

            {popupsQuery.data && 
              popupsQuery.data?.filter?.(p => p.active === '1')?.map?.((item, index) => (
                <div key={`donation-pickup-${index}`}>
                  <BasicBDPopupCard
                    popup={item}
                    onClick={() => {onDonationClicked(item)}}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PickupEvent;
