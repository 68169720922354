import { SlideModal } from '@/components/Cards/SlideModal';
import { OrgAccordion } from '@/components/Dialog/SlideModal/BrowseOrgSlideModal/OrgAccordion';
import { OrgSearchBar } from '@/components/Input/OrgSearchBar';
import { PageTitle } from '@/components/Label/PageTitle';
import useMinimalOrgs from '@/hooks/state/organization/useMinimalOrgs';
import { orgWithIdConfig } from '@/hooks/state/organization/useOrg';
import { useQueries } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';


export const  BrowseOrgSlideModal = ({show, setShow, hiddenOrgIDs=[], orgsData=[], ...props}) => {    
    const [searchResults, setSearchResults] = useState([])
    const filteredOrgs = useMemo(() => {
        return removeHiddenOrgs(orgsData || [])
    },[orgsData, hiddenOrgIDs])

    useEffect(() => {
        if (orgsData && (filteredOrgs?.length > 0 || searchResults?.length > 0)) 
            setSearchResults(filteredOrgs)
    },[orgsData, hiddenOrgIDs])
    
    const handleSearchResults = useCallback((results) => {
        setSearchResults(removeHiddenOrgs(results))
    },[hiddenOrgIDs, orgsData])

    function removeHiddenOrgs(orgsList) {
        return orgsList.filter(org => !hiddenOrgIDs.includes(org.id))
    }
    
    return (
        <SlideModal 
            {...{
                show,
                setShow,
                topClass: 'top-[20%]',
                className: 'overflow-y-auto px-6',

            }}
        >
            <div className="flex w-fit">
                <div className="w-full gap-5 form-control">
                    <PageTitle>
                        Find Communities
                    </PageTitle>
                    <div className=" rounded-xl bg-base-200 p-4 w-fit z-[2]">
                        <OrgSearchBar 
                            onFilter={handleSearchResults}
                            orgs={ filteredOrgs }
                        />
                    </div>
                    <div className="w-full">
                        { searchResults.length >= 0 && (
                            <OrgAccordion 
                                orgsOptions={searchResults}
                            />
                        )}

                    </div>
                </div>
            </div>
            {/* {  orgNamesQuery.data?.length > 0 && 
                orgNamesQuery.data.map(org => (
                    <CommunityCollapse 
                        basicInfo={org}
                        fullInfo={orgQueries.data?.[org.id]}
                        active={selectedOrg?.id === org.id}
                    />
                ))
            } */}
        </SlideModal>
    );
};

