import { useEffect, useState } from "react";
const defaultBreakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
}

const useBreakpoints = (breakpoints=defaultBreakpoints) => {
    const [breakpoint, setBreakPoint] = useState('');

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
     }, []);

  function handleResize() {
        for (const [key, bp] of Object.entries(breakpoints)) {
            if (window.innerWidth < bp) {
                setBreakPoint(key)
                break
            }
        }
    }

  return breakpoint
}

export default useBreakpoints