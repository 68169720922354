import { getOrgsById } from '@/apis/base/organizations/getOrgsById';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { arrayRemove } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import useMyOrgs from './useMyOrgs';

export default function useMyOrgsData({asTree=false, select, ...props}) {
    const myOrgsQuery = useMyOrgs()
    const myOrgs = useQuery({
        queryKey: ['context', ROUTE_IDS.GET_MY_ORGS, 'data', myOrgsQuery.data],
        queryFn: () => getOrgsById({ 'orgids[]': myOrgsQuery.data, recursive: 1, showmembers: true, asTree }, false),
        staleTime: MILLISEC.MIN_15,
        enabled: myOrgsQuery.data?.length > 0,
        notifyOnChangeProps: ['data'],
        select: React.useCallback((data) => {
          let ret = asTree ? structuredClone(data) : data
          if (asTree) {
            const depthMap = {}
            recursiveDepthMap(ret, depthMap)
          }
          return select ? select?.(ret) : ret
        },[asTree, select, myOrgsQuery.data]),
        ...props
    });
  return myOrgs;
}

function recursiveDepthMap(orgs, depthMap, depth=0) {
  if (!orgs?.length) return
  for (let i = orgs?.length - 1; i >= 0 ; i--) {
    const org = orgs?.[i]
    const currID = org?.id
    // Doesn't exist in depth map yet
    if (!depthMap[currID]) {
      depthMap[currID] = {depth, orgid: currID, origin: orgs, idx: i}
    // Exists in depth map at shallower depth
    } else if (depthMap[currID] != null && depthMap[currID]?.depth < depth) {
      arrayRemove(depthMap[currID].origin, depthMap[currID].idx)
      depthMap[currID] = {depth, orgid: currID, origin: orgs}
    // Exists in depth map at deeper depth
    } else {
      arrayRemove(orgs, i)
      depthMap[currID] = {depth, orgid: org.id, origin: orgs}
    } 
    recursiveDepthMap(org.subnodes, depthMap, depth + 1)
  }
}

