import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { signupMember } from '@/apis/base/auth/signupMember';

export default function useSignupMember({ onError, onSuccess, props }) {
  const client = useQueryClient();
  const signupData = useMutation({
    mutationKey: ['user', ROUTE_IDS.SIGN_UP_MEMBER],
    mutationFn: signupMember,
    staleTime: MILLISEC.YEAR,
    retry: false,
    retryDelay: 1000,
    onError: onError,
    onSuccess: (data) => {
      client.invalidateQueries({queryKey:['org']});
      client.invalidateQueries({queryKey:['user']});
      onSuccess && onSuccess(data);
    },
    ...props,
  });
  return signupData;
}

