import React from 'react';

import {useUserContext} from "../../../../../../context/UserContext";
import SocialMediaInfluencerApplication from "./SocialMediaInfluencerApplication";
import AmbassadorApplication from "./AmbassadorApplication";
import FamilyInNeedApplication from "./FamilyInNeedApplication";
import {SectionTabs} from "../../../../../../components/Tabs";
import useGetMyBaseRoleData from '@/hooks/state/application/useGetMyBaseRoleData';
import useGetRoleApps from '@/hooks/state/application/useGetRoleApps';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';

function Apply({text}) {
  const {userInfo} = useUserContext();
  const {org_type, expiry_date, roles} = userInfo;
  const apply_sections = [
    { title: text.requestProducts, href: 'request-products' },
    { title: text.becomeAmbassador, href: 'become-ambassador' },
    { title: text.becomeSocialMediaInfluencer, href: 'become-social-media-influencer'}
  ];
  const contextQuery = useCurrentOrgContext({})
  const baseRoleQuery = useGetMyBaseRoleData({})
  const roleAppQuery = useGetRoleApps({orgid: contextQuery.data?.orgid})

  // const prevFinApp = baseRoleQuery.data ? baseRoleQuery.data.first(base => !!base?.FIN) : null;
  // const prevAmbApp = baseRoleQuery.data ? baseRoleQuery.data.first(base => !!base?.Ambassador) : null;
  // const prevSmiApp = baseRoleQuery.data ? baseRoleQuery.data.first(base => !!base?.SMI) : null;
  
  const {finApp: prevFinApp, ambApp: prevAmbApp, smiApp: prevSmiApp} = roleAppQuery.data || {};
  // const baseAmb = baseRoleQuery.data ? baseRoleQuery.data.first(base => !!base?.Ambassador) : null;
  // const baseFin = baseRoleQuery.data ? baseRoleQuery.data.first(base => !!base?.FIN) : null;
  // const baseSmi = baseRoleQuery.data ? baseRoleQuery.data.first(base => !!base?.SMI) : null;
  const {FIN: baseFin, Ambassador: baseAmb, SMI: baseSmi} = baseRoleQuery.data || {};

  return (
    <div className="lg:flex mb-12">
      <div className="flex-4">
        { !!baseRoleQuery.data ? 
          (
            <>
              <FamilyInNeedApplication baseFin={baseFin}  prevFinApp={prevFinApp} text={text} org_type={org_type} expiry_date={expiry_date}
                                      isGuardian={true}/>
              <AmbassadorApplication baseAmb={baseAmb} prevAmbApp={prevAmbApp} text={text} org_type={org_type} expiry_date={expiry_date}/>
              <SocialMediaInfluencerApplication baseSmi={baseSmi} prevSmiApp={prevSmiApp} text={text} org_type={org_type} expiry_date={expiry_date}/>
            </>
          )
        : (
            <div className='flex gap-20 flex-col'>
              <ApplicationSkeleton />
              <ApplicationSkeleton />
              <ApplicationSkeleton />
            </div>

        )
        }
      </div>
      <div className="flex-1 lg:block hidden mt-7 ml-12">
        <SectionTabs tabs={apply_sections}/>
      </div>
    </div>
  );
}

const ApplicationSkeleton = () => (
  <div className="flex flex-col gap-4 w-full">
    <div className="skeleton h-4 w-28"></div>
    <div className="skeleton h-4 w-42"></div>
    <div className="skeleton h-4 w-full"></div>
  </div>
)

export default Apply;
