import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import { getBrandPrefRegionalReport } from '@/apis/base/admin/uic/getBrandPrefRegionalReport';
import { arrayToObject } from '@/utils';

export default function useBrandPrefRegionalReport(params) {
    const query = useQuery({
        queryKey: [ROUTE_IDS.GET_BRAND_PREF_REGIONAL_REPORT],
        queryFn: (params) => getBrandPrefRegionalReport(params),
        staleTime: MILLISEC.MIN_15,
        select,
    });
    return query;
}

function select(data) {
    data.forEach((brand) => {
        brand.regionObj =  arrayToObject(brand?.regions, 'id')
        brand.total = brand?.regions?.reduce?.((acc, curr) => acc + Number(curr.quantity), 0)
    })
    return data
}