
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useQuery } from '@tanstack/react-query';
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import { getAllBDEvents } from '@/apis/base/admin/uic/getAllBDEvents';
import { roundTo } from '@/utils';
import React from 'react';


export default function useAllBDEvents({statusids = null}) {    
    const query = useQuery({
        queryKey: ['bd', 'event', ROUTE_IDS.GET_ALL_BD_EVENTS],
        queryFn: (params) => getAllBDEvents(params),
        staleTime: MILLISEC.MIN_15,
        // enabled: contextQuery.data?.isUICContext,
        select: React.useCallback((data) => {
            data.reduce((acc, curr) => {
                curr.canAudit = true
                if (curr?.peritemreport === '1') {
                    curr.price = 0
                    curr.weight = 0
                    curr?.donations?.forEach?.(d => {
                        curr.price += Number(d.price) * Number(d.quantity)
                        curr.weight += Number(d.weight) * Number(d.quantity)
                    })
                    curr.price = roundTo(curr?.price)
                }
                acc.push(curr)
                return acc
            },[])
            if (statusids != null)
                return data.filter((item) => statusids.includes(item.status));
            else 
                return data
        }, [statusids])
    });
    return query;
}

