import { ROUTE_IDS } from '@/apis/base/routeId';
import { MILLISEC } from '@/constants';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { getGeoNode } from '../../../apis/base/misc/getGeoNode.js';

export default function useGeoNode({nodeIds, ...props}) {
  const geoNodeQuery = useQuery(getGeoNodeConfig({nodeIds, props}));
  return geoNodeQuery;
}

export const getGeoNodeConfig = ({nodeIds, ...props}) => queryOptions({
  queryKey: [ROUTE_IDS.GET_GEO_NODE, nodeIds],
  queryFn: () => getGeoNode({"geoids[]": nodeIds}),
  staleTime: MILLISEC.MIN_15,
  gcTime: MILLISEC.HOUR,
  enabled: (props?.enabled ?? true) && nodeIds != null && nodeIds.length > 0,
  ...props
})
