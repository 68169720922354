import React from 'react';
import { Redirect, Route, Switch, Link, useHistory } from 'react-router-dom';

import routes from '../../../routes';
import headerLogo from '/assets/images/unity11.png'

import { appName } from '../../../constants';
import { useTranslations } from '../../../hooks/translations';
import { useCookies, withCookies } from 'react-cookie';
import { BasicBackButton } from '@/components/Buttons/BasicBackButton';

const backgroundImages = [
  '/assets/images/background/auth/signup1.png',
  '/assets/images/background/auth/signup2.png',
  '/assets/images/background/auth/signup3.png',
]

function Auth() {
  const text = useTranslations('auth');
  const [cookies, setCookie] = useCookies(['authBGIndex'])
  const history = useHistory()
  if (cookies.authBGIndex == null) {
    setCookie('authBGIndex', 0, { path: '/' })
  }

  function handleBack() {
    history.push('/')
  }
  
  const getRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <div className="lg:flex  relative">
      <div className="flex-3 lg:h-screen xs:h-full  xs:block overflow-hidden lg:min-h-screen ">
        <img
          className="w-full lg:min-h-full object-cover flex-shrink"
          src={backgroundImages[Number(cookies.authBGIndex)]}
          alt="auth screen background"
        />
        {/* <div className="w-full h-full max-h-screen flex justify-center z-50 absolute inset-0">
          <div className="flex flex-col justify-between w-3/4">
            
          </div>
        </div> */}
      </div>
      <div className="lg:h-screen xs:h-full max-h-screen flex flex-2 flex-col pt-10 lg:pt-20 relative">
          <div className="absolute left-[5vw] lg:left-5 top-5 lg:top-10">
            <BasicBackButton 
              label={'Back to Home'}
              onClick={handleBack}
            />
          </div>
        <div className="flex items-start justify-center overflow-y-scroll h-full relative pt-5 lg:pt-0"> 
          <Switch>
            {getRoutes()}
            <Redirect exact from="/auth" to="/auth/login" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withCookies(Auth);
