import { XIcon } from "@/components/Icons";
import React, { useEffect, useRef, useState } from "react";

const Icon = () => {
  return (
    <svg height="15" width="15" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="15" width="15" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

export const POSITION = {
  TOP: "top",
  END: "end",
  BOTTOM: "end",
  START: "start"
}

const DropdownTwo = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  showClearButton=false,
  isError = false,
  position = POSITION.BOTTOM,
  renderFn = (option) => option?.label,
  nameFn = renderFn,
  valueFn = (option) => option?.value,
  subOptFn = (option) => option?.suboptions,
  displayFn = renderFn,
  allowSuperOptionSelect = false,
  errorText,
  enabled = true,
  sorted = true,
  className,
  labelClassName='',
  value=null,
}) => {
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  const [keyboardSelIdx, setKeyboardSelIdx] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const searchRef = useRef();
  const inputRef = useRef();
  const containerRef = useRef()
  const displayRef = useRef()
  const themeMenu = useRef(null);
  useEffect(() => {
    if (!showMenu) {
      document.activeElement.blur();
    } else if (
      showMenu &&
      !containerRef.current.contains(document.activeElement)
    ) {
      setShowMenu(false);
    }
  }, [showMenu]);

  useEffect(() => {
    if (value === '')
      setSelectedValue(null)
  },[value])
   
  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
    // handleResize()
    new ResizeObserver(handleResize).observe(containerRef.current);
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    window.addEventListener("click", handler);
    return () => {
        window.removeEventListener("click", handler);
    };
});
  
  const handleResize = () => {
    if (displayRef.current && containerRef.current) {
      displayRef.current.style.maxWidth = `${containerRef.current.offsetWidth}px`
    }
  }

  const getDisplay = () => {
    if (value) return displayFn(value)
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className="flex flex-wrap gap-[5px];">
          {selectedValue.map((option, idx) => (
            <div key={valueFn(option) || idx} className="bg-[#ddd] flex items-center px-1 py-0.5 rounded-sm">
              {nameFn(option)}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="flex items-center;"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return displayFn(selectedValue);
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== valueFn(option));
  };

  function clear() {
    setSelectedValue(null)
    onChange(null)
  }

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (e, option) => {
    e.currentTarget.focus()
    if (!option) return 
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o) => o.value === valueFn(option)) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
    setShowMenu(false);
  };
  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === valueFn(option)).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === valueFn(option);
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (options == null || options.length === 0) return []
    if (!searchValue) {
      return sorted && Array.isArray(options) ? options.sort(compare) : options;
    }

    return options.filter(
      (option) =>
        nameFn(option).toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  const compare = (a,b) => {
    if (nameFn(a) < nameFn(b)){
      return -1;
    }
    if (nameFn(a) > nameFn(b)){
      return 1;
    }
    return 0;
  }
  let positionClasses
   switch (position) {
    case POSITION.TOP:
      positionClasses = 'bottom-16'
      break;
    case POSITION.BOTTOM:
      positionClasses = ''
      break;
    case POSITION.START:
      positionClasses = 'right-0'
      break;
    case POSITION.END:
      positionClasses = 'left-0'
      break;
    default:
      positionClasses = ''
      break;
  }
  

  function handleBlur(e) {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    setShowMenu(false);
  }

  function handleFocus(e) {
    e.stopImmediatePropagation()
    setShowMenu(true);
  }
  console.log(className);
  return (
    <div className={`w-full ${className} min-w-fit `}>

      <div 
        tabIndex={0} 
        className={`dropdown indicator focus:border-primary focus:outline-none w-full min-w-fit bg-transparent ${enabled ? '' : 'bg-gray-300'}`} 
        onMouseLeave={() => setShowMenu(false)}
        onBlur={handleBlur}
        id="dropdowncontainer" ref={containerRef} >
        { showClearButton && selectedValue &&(
          <div className="indicator-item" onClick={clear}>
            <XIcon className='btn btn-ghost btn-circle btn-xs text-white bg-gray-darkSubText'/>
          </div>
        )}
        <label 
          onClick={() => setShowMenu(m => !m)}
          className={`btn min-h-[40px] h-[40px] hover:bg-white no-animation font-base font-normal bg-white animate-none text-sm w-full min-w-fit justify-between whitespace-nowrap flex-nowrap
          ${!selectedValue || selectedValue.length === 0 ?   'text-gray-400' : 'text-black'}
          ${isError ? 'border-[rgba(223,66,66,0.78)] hover:border-[rgb(223,66,66)]' : 'border-gray-border'}
          ${labelClassName}
          `
          }  
         >
          {getDisplay()}
          <Icon/>
        </label>
          { showMenu &&
            <ul 
              ref={themeMenu} 
              onFocus={(e) => {
                setShowMenu(true);
              }}
              className="menu overscroll-none border border-gray-border w-full rounded-lg form-control gap-1 flex-nowrap  max-h-[30rem] overflow-y-scroll py-5 absolute bg-white font-normal   
                dropdown-content z-[20] dropdown-open" 
            >
              { isSearchable && (
                <li className="hover:cursor-text pl-2">
                  <input
                    className="hover:cursor-text"
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={onSearch}
                    ref={searchRef}
                  />
                </li>
              )}
              
            { getOptions().map((option,idx) => (
                  <li
                    key={option.id || idx}
                  >
                    { subOptFn(option)?.length > 0 ? (
                      <details className="-ml-1 bg-white no-animation pl-[0.6rem]">
                        <summary  
                          className="bg-white no-animation  pl-2">
                           { allowSuperOptionSelect ? (
                            <button
                              className="w-full btn btn-sm btn-ghost font-normal  text-inherit" 
                              onClick={((e) => onItemClick(e, option))}
                            >
                              {nameFn(option)}
                            </button>
                           ) : (
                            <span>
                              {nameFn(option)}
                            </span>
                           )}
                        </summary>
                        <ul>
                            {
                              subOptFn(option).map((sub, idx2) => (
                                <li
                                  key={sub.id || idx2} 
                                  value={nameFn(sub) || idx2} >
                                  <button
                                    tabIndex={0}
                                    className="ml-2 py-2 text-l cursor-pointer p-[5px] font-light btn-ghost no-animation" onClick={(e) => onItemClick(e,sub)}
                                  >
                                    <div className="">{nameFn(sub)}</div>
                                    <a>
                                  </a>
                                  </button>
                                </li>
                              ))
                            }
                        </ul>
                      </details>
                    ) : (
                      <button
                        tabIndex={0}
                        type="button"
                        onClick={(e) =>  onItemClick(e, option)}
                        className={`ml-2 py-2 text-l cursor-pointer p-[5px] font-light        btn-ghost no-animation `} 
                      >
                        <div className="font-normal">{nameFn(option)}</div>
                      </button>)
                    }
                  </li>
            ))}
            </ul>
          }
      </div>
    </div>
  );
};

export default DropdownTwo;
