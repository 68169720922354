import { CopyIcon } from "@/components/Icons";
import { OrgTogglesDropdownCell } from "@/components/Tables/Cells/OrgTogglesDropdownCell";
import { getDateString, handleCopyInviteLinkToClipBoard } from "@/utils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { OrgCell } from "../Cells/OrgCell";


const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const orgTableColumnHelper = [
  columnHelper.accessor((row) => row.name, {
    id: "Organization",
    size: 100,
    filterFn: stringFilter,
    cell: ({row}) => <OrgCell org={row.original} style={{paddingLeft: `${row.depth * 2}rem`}}/>,
    header: (info) => <span>ORGANIZATION</span>,
  }),
columnHelper.accessor((row) => getOrgAdmins(row), {
    id: "Master Admin",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>MASTER ADMIN</span>,
  }),
columnHelper.accessor((row) => row?.address, {
    id: "Address",
    size: 200,
    cell: ({row}) => <span>{address(row?.original)}</span>,
    header: (info) => <span>ADDRESS</span>,
  }),
columnHelper.accessor((row) => getDateString(row.timestamp), {
    id: "Date Created",
    size: 100,
    filterFn: stringFilter,
    cell: (info) => <span>{info.getValue()}</span>,
    header: (info) => <span>DATE CREATED</span>,
  }),
  columnHelper.accessor((row) => row.members.length, {
    id: "Permissions & Roles",
    size: 100,
    cell: ({table, row}) => <OrgTogglesDropdownCell row={row.original} {...table.options.meta} />,
    header: (info) => <span>PERMISSIONS</span>,
  }),
columnHelper.display({
    id: "Invite Link",
    size: 10,
    cell: ({row, table}) => <InviteLink orgid={row.original.id} table={table}/>,
    header: (info) => <span>INVITE LINK</span>,
  }),
]

const getOrgAdmins = (org) => {
    return org?.members
        ?.find?.(member => member?.id === org?.owner)
        ?.name ?? ''
}

const InviteLink = ({orgid, table}) => {
  const { copyInviteLink } = table.options.meta || {}
    return  (
        <button className="btn btn-ghost btn-sm btn-circle" onClick={() => copyInviteLink(orgid)}>
            <CopyIcon />
        </button>
    )
}

const address = (org) => {
    const cityLabel = org?.city ? org?.city + ', ' : null
    const regionLabel = org?.region ? org?.region : null
    const geoLabel = cityLabel && regionLabel ?  ' ' + cityLabel + regionLabel : ''
    return `${org?.address}${geoLabel}`
}