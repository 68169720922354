import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation,useQueryClient } from '@tanstack/react-query';
import { modOrgWishlistReport } from '@/apis/base/wishlist/modOrgWishlistReport';


export default function useModOrgWishlistReport(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_ORG_WISHLIST_REPORT],
        mutationFn: modOrgWishlistReport,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['wishlist', 'org', req?.orgid]})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}