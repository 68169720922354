import React, { useMemo } from 'react';
import useGeoData from '@/hooks/state/misc/useGeoData';
import { BrandPrefRegionalReport } from '@/views/pages/Admin/Brand/components/pages/BrandPrefRegionalReport';
import { brandPrefRegionalReportCols } from './brandPrefRegionalReportCols';
import { BasicTable } from '@/components/Tables/BasicTable';


export const BrandPrefRegionalReportTable = ({data, useTimespan, ...props}) => {
    const geoDataQuery = useGeoData()
    const safeData = data || []
    const cols = useMemo(() => 
        brandPrefRegionalReportCols(
            geoDataQuery.data?.[0]?.subnodes || [], useTimespan
        ) || [],
    [geoDataQuery])
    return (
        <BasicTable
            initialPageSize={50}
            className='table-sm'
            data={safeData}
            columnHelper={cols} {...props}
        />
    );
};

