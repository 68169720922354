import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS } from "@/apis/base/routeId"
import { convertObjFieldsToString } from "@/utils"


export const createPopup = (params) => {
    params.type = ROUTE_IDS.CREATE_POPUP
    return postPromise(config.API_BASE_URL, convertObjFieldsToString(params), {json: true})
}
