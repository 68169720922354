import { OrgProfileLogo } from '@/components/Image/OrgProfileLogo';
import { BasicSearchBar } from '@/components/Input/BasicSearchBar';
import { useGeoIDs } from '@/hooks/state/misc/useGeoIDs';
import { arrayToObject, recursiveFlattenSubnodes, recursiveSubnodeHelper } from '@/utils';
import { CheckCircle } from '@material-ui/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const OrgSearchBar = ({orgs, onChange, initialOrgID, onFilter, ...props}) => {
    const geoQuery  = useGeoIDs() || {}
    const [initialValue, setInitialValue] = useState(null)
    
    // const {regions, cities} = geoQuery.data || {}

    // function getOrgOptions() { 
    //     if (!orgs?.length) return []
    //     const filteredOrgs = orgs?.filter?.(org => org?.name!= 'Christine Morrison')
    //     const options =  
    //         Object.values(filteredOrgs)
    //             ?.map?.(org => ({label: org?.name, value: org}))
    //     return options
    // }   
    
    useEffect(() => {
        if (!initialOrgID) console.log('no initial org id');
        if (!initialOrgID || !orgs) return
        const initialOrg = orgs?.find?.(org => org?.id === initialOrgID)
        console.log('initialOrg', initialOrg);
        initialOrg && setInitialValue(initialOrg)
    },[initialOrgID, orgs?.length])

    useEffect(() => {
        if (!geoQuery.data || !orgs) return
        orgs.forEach((org) => {
            org.localeLabel = 
                `${org?.['city name'] || 'hi'}, ${org?.['region name']}`
            org.searchValue = 
                `${org?.name} ${org?.['org type name']} ${org?.localeLabel}`
        })
    },[geoQuery.data, orgs])

    function displayOrgOpt(org) {
        if (!org) return console.log('empty')
        const isPrivate = org.private === '1'
        return (
                <div tabIndex={0} className="flex m-0 p-0 w-full justify-between items-center  whitespace-nowrap text-xs md:text-base">
                    <OrgProfileLogo orgid={org.id}/>
                    <div className='@container flex h-fit items-start w-full'>
                        <div className="form-control ml-2 ">
                            <p className='text-sm @[500px]:text-lg flex font-medium whitespace-pre-wrap break-after-auto line-clamp-2 '>
                                <span>
                                    {org.name}
                                </span>
                                <span className='ml-1 align-self-start text-gray-subText flex text-2xs @[500px]:text-sm font-medium whitespace-nowrap'>
                                    – {org?.['org type name'] || 'Education'}
                                </span>
                            </p>
                            <span className='text-gray-subText leading-none flex text-2xs @[500px]:text-xs'>
                                {org?.['city name']}, {org?.['region name']}
                            </span>

                        </div>
                    </div>
                    {/* { isPrivate && (
                        <div className={`badge badge-xs text-2xs @[500px]:text-xs ml-2 @[500px]:badge-md w-fit badge-neutral`}>
                            Private
                        </div>
                    )} */}
                </div>
        )
    }

    const orgSearchFilterFn = useCallback((org, search) => {
        return org?.searchValue?.toLowerCase()?.includes?.(search?.toLowerCase())    
    },[])

    return (
        <BasicSearchBar
            value={initialValue}
            dropdownClassName={'!bg-opacity-100'}
            labelFn={useCallback((op) => op?.name,[])}
            searchFilterFn={useCallback(orgSearchFilterFn,[])}
            displayFn={useCallback(displayOrgOpt,[])}
            liveChanges={false}
            idFn={useCallback((org) => org?.id,[])}
            onFilter={onFilter}
            options={orgs}
            className={'w-[25rem] md:w-[35rem] lg:w-[45rem]'}
            placeholder='Search for a community'
            onChange={onChange}
            {...props}
        />
    );
};

