import React from 'react';


export const BasicGhostButton = ({className, little=false, circle=false, square=false, ...props}) => {
    const shapeClass = circle ? 'btn-circle' : square ? 'btn-square' : ''
    return (
        <button {...props} 
        className={`btn btn-ghost 
            ${shapeClass}
            ${little ? 'btn-little' : ''}
            ${className}
        `}>
            {props.children}
        </button>
    );
};

