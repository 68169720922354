import { MILLISEC } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getAllOrgs } from '@/apis/base/organization';
import { getProductCategories } from '@/apis/base/wishlist/getProductCategories';
import { getTargetCustomers } from '@/apis/base/wishlist/getTargetCustomers';

export default function useWishTargetCust(props) {
  const allOrgs = useQuery({
    queryKey: [ROUTE_IDS.GET_TARGET_CUSTOMERS],
    queryFn: getTargetCustomers,
    retry: 2,
    // notifyOnChangeProps: 'data',
    staleTime: MILLISEC.MIN_15,
    ...props
  });
  return allOrgs;
}
