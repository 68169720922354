import React, { useMemo, useState } from "react";

import { INVITE_TYPE, userTypes } from "@/constants/index";
import { useAppContext } from "@/context/AppContext";
import { getCookie, openDialogWithRef, subObject } from "@/utils";

import { BasicDropdownButton } from "@/components/Buttons/BasicDropdownButton";
import { BasicRectButton } from "@/components/Buttons/BasicRectButton";
import { MenuListItem } from "@/components/Containers/MenuListItem";
import { UserInvitationDialog } from "@/components/Dialog/UserInvitationDialog";
import { OrganizationManagementTable } from "@/components/Tables/OrganizationManagementTable/OrganizationManagementTable";
import { OrgTagDropdown } from "@/components/dropdowns/OrgTagDropdown";
import useCurrentOrgContext from "@/hooks/state/organization/useCurrentOrgContext";
import useOrgDataWithGeo from "@/hooks/state/organization/useOrgDataWithGeo";
import useSendOrgInvite from "@/hooks/state/organization/useSendOrgInvite";
import useUICOCreateOrg from "@/hooks/state/organization/useUICOCreateOrg";
import useUsers from "@/hooks/state/user/useUsers";
import { FailPopup } from "@/wrappers/swal/FailPopup";
import { SuccessToast } from "@/wrappers/swal/SuccessToast";
import { queryResultFns } from "@/wrappers/swal/queryResultFns";
import config from "../../../../../../apis/config";
import CreateOrganizationDialog from "../../../../../../components/Dialog/CreateOrganizationDialog";
import { useGeoIDs } from "@/hooks/state/misc/useGeoIDs";
import useOrgWithId from "@/hooks/state/organization/useOrg";
import { useCookies } from "react-cookie";
import useModOrgData from "@/hooks/state/organization/useModOrgData";
import { copyOrgInviteToClipboard } from "@/utils/orgUtils";

function Organization({ text }) {
  const inviteDialogRef = React.useRef(null);
  const [inviteLinkCopied, setInviteLinkCopied] = useState(-1);
  const [checkedTags, setCheckedTags] = React.useState([])
  const [openCreateOrgDialog, setOpenCreateOrgDialog] = useState(false);
  const [cookies] = useCookies()
  const contextQuery = useCurrentOrgContext()
  const context = contextQuery.data
  const orgsQuery = useOrgWithId({org_id: cookies.orgIdContext, recursive:1, showMembers: true})
  const modOrgRolesMutation = useModOrgData(queryResultFns(
      'Successfully inherited parent community roles ', 
      'Failed to inherit parent community roles'))
  const createOrgMutation = useUICOCreateOrg({
    onSuccess: handleOrgCreationSuccess,
    onError: () => {FailPopup.fire({text: 'Failed to create organization'})}
  })
  const sendOrgInviteMutation = 
    useSendOrgInvite(queryResultFns(text.inviteSuccess, text.inviteFail));
  // const handleCheck = (e) => {
  //   if (e.target.checked) {
  //       setCheckedTags([...checkedTags, e.target.value])
  //   } else {
  //       setCheckedTags(checkedTags.filter(v => v !== e.target.value))
  //   }
  // }

  function handleOrgCreationSuccess(data, req) {
    console.log("req", req)
    SuccessToast.fire({text: 'Successfully created organization'})
    const parentOrg = orgsQuery.data.find(org => org.id === req.parent)
    const roles = subObject(parentOrg, ['brand', 'charity', 'processor','corporation','ecommerce'])
    if (Object.values(roles).some(v => v)) {
      modOrgRolesMutation.mutate({orgid: data.orgid, ...roles})
    }
  }

  const handleSelectInviteType = (type) => {
    switch (type) {
      case INVITE_TYPE.ORGANIZATION:
        setOpenCreateOrgDialog(true);
        break;
      case INVITE_TYPE.MEMBER:
        openDialogWithRef(inviteDialogRef);
        break;
      case INVITE_TYPE.LINK:
        copyInviteLink(context?.orgid);
        break;
    }
  };

  const createOrg = (org_details) => {
    console.log("org_details", org_details)
    createOrgMutation.mutate(org_details)    
    setOpenCreateOrgDialog(false);
  };
  const copyInviteLink = (orgId) => {
    const l  = copyOrgInviteToClipboard(orgId)
    setInviteLinkCopied(orgId);

    setTimeout(() => {
      setInviteLinkCopied(-1);
    }, 5000);
  };
  return (
    <div className="mb-12">
      <OrganizationManagementTable
        orgs={orgsQuery.data}
        canChangeOrgRoles={context?.owner === context?.userid}
        copyInviteLink={copyInviteLink}
      >
        <BasicDropdownButton tabIndex={0} label={'Invite'} 
            className="h-12 no-animation text-white bg-blue-cornflower ">
          <MenuListItem onClick={() => handleSelectInviteType(INVITE_TYPE.ORGANIZATION)}>
            {text.inviteOrg}
          </MenuListItem>
          <MenuListItem onClick={() => handleSelectInviteType(INVITE_TYPE.MEMBER)}>
            {text.inviteMember}
          </MenuListItem>
          <MenuListItem onClick={() => handleSelectInviteType(INVITE_TYPE.LINK)}>
            {text.inviteLink}
          </MenuListItem>
        </BasicDropdownButton>
        <BasicRectButton 
          className={'bg-green-button text-white'} 
            onClick={() => setOpenCreateOrgDialog(true)}>
            Create
        </BasicRectButton>
      </OrganizationManagementTable>
      <CreateOrganizationDialog
        open={openCreateOrgDialog}
        onClose={() => setOpenCreateOrgDialog(false)}
        onCreate={createOrg}
      />
      <UserInvitationDialog dialogRef={inviteDialogRef} orgsData={orgsQuery.data || []}/>
       <div className="mt-6 mb-4 flex justify-end">
      </div>
    </div>
  );
}

export default Organization;