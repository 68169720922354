import { capitalizeFirst, rangeArray } from '@/utils';
import { observeResize } from '@/utils/misc';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import React, { useEffect, useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
Chart.register(  
    BarElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {   
        x:{
            grid: {display: false},

            ticks: {
                color: 'white',
                font: {
                    size: 15,
                    family:'Poppins',
                    weight: 300
                },
            // display: false

            }
        },
        y: {
            display: false,
            grid: {display: false},
            ticks: {display: false}

        }
    },
    elements:{
        bar:{
            borderRadius: 35,
            // maxBarThickness: 0.01,
        },
    },
    datasets: {
        bar: {
            // barPercentage: 0.5,
            // barThickness: 75,
            // maxBarThickness: 85,
            borderSkipped: false
        },

    },
    plugins: {
        legend: {
            display: false
        }

    }
}


const monthlydonations = [
    ['Jan', 2.2],
    ['Feb', 2.4],
    ['Mar', 1.2],
    ['Apr', 1.6],
    ['May', 2.0],
    ['Jun', 1.4],
    ['Jul', 1.9],
    ['Aug', 2.3],
    ['Sep', 1.2],
    ['Oct', 1.6],
    ['Nov', 2.1],
    ['Dec', 1.3],
]

// const heightRatio = 0.2
export const MonthlyDonationsChartCard = ({...props}) => {
    const canvasRef = useRef()
    const containerRef = useRef()
    const safeDonations = Object.entries(monthlydonations)?.splice?.(0,6) || []
    const chartData = useMemo(() => getDataSet(safeDonations), [safeDonations])
    useEffect(observeContainerResize,[])
    function observeContainerResize() {
        observeResize(containerRef.current, (e) => {
            const {bottom, width} = e?.contentRect || {}
            if (!canvasRef.current || !containerRef.current) return
            canvasRef.current.height = bottom
            canvasRef.current.width = width
        })
    }
    // useEffect(() => {
    //     if (!canvasRef.current) return
    //     canvasRef.current.height = canvasRef.current.width * heightRatio
    // },[])
    return monthlydonations && (
        <div className='bg-gray-see flex-2 rounded-2xl p-8 pb-3   text-white w-full h-full min-w-0 overflow-hidden shrink'>
            <div className="flex justify-between font-medium text-2xl">
                <span className='font-medium text-2xl'>2023 Donations</span>
                <span>$51,372,016</span>
            </div>
            <div ref={containerRef} className='py-4  relative min-w-0 w-full h-full box-border pb-10'>
                <Bar 
                    ref={canvasRef}
                    className='min-w-0 '
                    data={chartData}
                    options={barOptions}
                />
            </div>
        </div>
    );
};


function getDataSet() {
    const labels = monthlydonations.map(([month, amount]) => month)
    // const data = monthlydonations.map(([month, amount]) => amount)
    const data = monthlydonations.map(([month, amount]) => amount)
    return {
     labels,
    datasets: [
      {
        data: data,
        backgroundColor: '#61C29C',
        tension:0.4,
      }
    ],
    }
}