import { postPromise } from '@/apis/base';
import { ROUTE_IDS, addRouteIDToFormData, composeFormData } from '../routeId';
import config from '@/apis/config';

const changePassword = (params) => {
  return postPromise(
    config.API_BASE_URL,
    composeFormData(params, ROUTE_IDS.CHANGE_PASSWORD)
  );
};

export { changePassword };
