import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { DashboardCard } from '@/components/Cards/DashboardCard';
import DropdownTwo from '@/components/DropDownTwo';
import { ExcelIcon } from '@/components/Icons';
import { BasicBoxTabs } from '@/components/Input/BasicBoxTabs';
import MultiSelectDropdown from '@/components/Input/MultiSelectDropdown';
import { WishlistReportTable } from '@/components/Tables/WishlistReportTable/WishlistReportTable';
import { WISHLIST_VIEW_TYPE } from '@/constants';
import useAllUserWishlists from '@/hooks/state/admin/uic/useAllUserWishlists';
import useGeoData from '@/hooks/state/misc/useGeoData';
import { useOrgsWishlistReportAndDemand } from '@/hooks/state/wishlist/helpers/useOrgsWishlistReportAndDemand';
import { useWishlistFilters } from '@/hooks/state/wishlist/helpers/useWishlistFilters';
import { useWishlistObject } from '@/hooks/state/wishlist/helpers/useWishlistObject';
import { orgWishlistReportConfig } from '@/hooks/state/wishlist/useOrgWishlistReport';
import useWishProductCategories from '@/hooks/state/wishlist/useWishProductCats';
import useWishProducts from '@/hooks/state/wishlist/useWishProducts';
import useWishTargetCust from '@/hooks/state/wishlist/useWishTargetCust';
import useWishlistTree from '@/hooks/state/wishlist/useWishlistTree';
import { arrayToObject, flattenOrgs, getDashedDateString } from '@/utils';
import { downloadExcel } from '@/utils/fileUtils/downloadExcel';
import { getFlatWishlistProductsWithSuperCategory } from '@/utils/misc';
import { WishlistGrid } from '@/views/pages/Admin/ProductManagement/components/WishlistGrid';
import { useQueries } from '@tanstack/react-query';
import React, { useMemo } from 'react';

const tabNames=['Grid', 'Table']
const TABS = {
    GRID: 0,
    TABLE: 1
}
export const WishlistOverviewReport = ({geoQuery, wishlistTreeQuery, categoryQuery, demographicsQuery, orgsQuery, productsQuery, ...props}) => {
    const wishlistQuery = useAllUserWishlists({})
    const [activeTab, setActiveTab] = React.useState(0)
    const [selectedGeo, setSelectedGeo] = React.useState(null)
    const [selectedOrg, setSelectedOrg] = React.useState(null)
    const {
        demoFilter, 
        catFilter,
        handleCatFilterChange,
        handleDemoFilterChange
    } = useWishlistFilters(demographicsQuery.data, categoryQuery.data?.flat)

    const wishlistObj = useWishlistObject({
        view: WISHLIST_VIEW_TYPE.REPORT, 
        sitewideWishlists: wishlistQuery.data, 
        wishProductsData: productsQuery.data,
    })
    function exportUserWishlists() {
        const flatProductsWithSupercategory = getFlatWishlistProductsWithSuperCategory(wishlistTreeQuery.data)
        // const filteredProducts = 
        //     flatProductsWithSupercategory
        //         .filter((product) => 
        //             catFilter.includes(product.categoryid) && 
        //             demoFilter.includes(product.targetid))
        const flatProductsWithSupercategoryObj = arrayToObject(flatProductsWithSupercategory,"id");
        const wishlistWithSupercategoryData = Object.values(wishlistObj).map((product) => {
            const targetProduct = flatProductsWithSupercategoryObj[product.id]
            if (!targetProduct) {
                console.log('missing target product', product);
            }
            return {
                ...product,
                ...targetProduct,
            }
        })
        const sortedData = wishlistWithSupercategoryData.sort(compareProducts)
        console.table('after sorting', sortedData);
        const rows = sortedData.map((row) => ({
            Category: row.superCategory,
            Subcategory: row.subCategory,
            "Product Name": row.name,
            "Demographic Name": row.targetname,
            Description: row.description,
            "Total Demand": row.totalQty ? String(row.totalQty) : "0"
        }))
        const today = new Date();
        const todayInSecs = today.getTime() / 1000;
        const filename = `national_wishlist_${getDashedDateString(todayInSecs)}`
        const sheet = {
            name: "National Wishlist",
            rows
        }
        const columnWidths = [18,26,28,18,25,17,18] // By trial and error
        downloadExcel([sheet], filename, columnWidths)
    }

    const exportNotReady = !wishlistTreeQuery.isSuccess || !wishlistQuery.isSuccess || !productsQuery.isSuccess
    

    return (
        <div>
            <div className="place-self-start flex gap-4 items-end">
                <DashboardCard className={'place-self-start max-w-[20rem]'}>
                    <BasicBoxTabs 
                        className='tabs-lg w-full'
                        tabNames={tabNames} 
                        activeTab={activeTab}
                        onChange={setActiveTab}
                    />   
                    <DropdownTwo 
                        showClearButton
                        isSearchable={true}
                        options={geoQuery.data?.[0]?.subnodes}
                        value={selectedGeo}
                        onChange={setSelectedGeo}
                        placeHolder='No geographic filter set'
                        valueFn={(val) => val}
                        renderFn={(val) => val?.name}
                        displayFn={(val) => <p> Showing results for <strong>{val?.name}</strong></p>}
                    />
                    <div className="flex flex-col md:flex-row w-fit gap-5">
                        <MultiSelectDropdown 
                            placeHolder='Filter categories'
                            options={categoryQuery.data?.tree}
                            renderFn={(val) => val?.name}
                            onToggle={handleCatFilterChange} 
                            selectedIDs={catFilter}
                            className='w-[50rem]'
                        />
                        <MultiSelectDropdown 
                            placeHolder='Filter demographics'
                            options={demographicsQuery.data}
                            renderFn={(val) => val?.name}
                            onToggle={handleDemoFilterChange}
                            selectedIDs={demoFilter}
                            className='w-72'
                        />
                    </div>
                </DashboardCard>
                <BasicRectButton
                        customSize
                        className={
                            "btn-neutral justify-between  btn-little "
                        }
                        onClick={exportUserWishlists}
                    >
                        {exportNotReady ? (
                            <div className="loading"></div>
                        ) : (
                            <>Export national wishlist</>
                        )}
                    <ExcelIcon className="fill-green-600 w-6 h-6" />
                </BasicRectButton>
            </div>
                {activeTab === TABS.GRID && (
                    <div className='mt-5'>
                        <WishlistGrid
                            {...{
                                view: WISHLIST_VIEW_TYPE.REPORT,
                                catFilter,
                                demoFilter,
                                wishlistObj,
                                reportProps: {
                                    geonodeFocus: selectedGeo,
                                }
                            }}
                        />
                    </div>
            )}
            {activeTab === TABS.TABLE && (
                <WishlistReportTable 
                    data={wishlistTreeQuery.data}
                    catFilter={catFilter}
                    demoFilter={demoFilter}
                    wishlistObj={wishlistObj}
                />
            )}
        </div>
    );
};


const compareProducts = (a, b) => {
    if (a.superCategory === b.superCategory) {
        if (a.subCategory && !b.subCategory) {
            return -1;
        } else if (!a.subCategory && b.subCategory) {
            return 1;
        } else if (a.subCategory === b.subCategory) {
            if (a.name > b.name) {
                return 1;
            }
            else if (a.name < b.name) {
                return -1;
            }
            else {
                console.log('last bucket 1', a, b);
                return 0;
            }
        } else if (a.subCategory > b.subCategory) {
            return 1;
        } else if (a.subCategory < b.subCategory) {
            return -1;
        } else {
            console.log('last bucket 2', a, b, a.subCategory < b.subCategory, a.subCategory > b.subCategory);    
            return 0;
        }
    } else if (a.superCategory < b.superCategory) {   
        return -1;
    }
    else if (a.superCategory > b.superCategory) {
        return 1;
    } else {
        return 0;
    }
}