import React, { useState } from "react";


import { BrandPrefTable } from "@/components/Tables/BrandPrefTable/BrandPrefTable";
import useAllBrands from "@/hooks/state/brands/useAllBrands";

function Brand({ text }) {
  const {data: brandData} = useAllBrands();

  return (
    <div className="mt-10">
      <BrandPrefTable prefData={brandData}/>

    </div>
  );
}

export default Brand;
