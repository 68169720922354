import { BasicBackButton } from '@/components/Buttons/BasicBackButton';
import { PageTitle } from '@/components/Label/PageTitle';
import { BasicTabs } from '@/components/Tabs/BasicTabs';
import useAllUserWishlists from '@/hooks/state/admin/uic/useAllUserWishlists';
import useGeoData from '@/hooks/state/misc/useGeoData';
import useAllOrgs from '@/hooks/state/organization/useAllOrgs';
import useWishlistTree from '@/hooks/state/wishlist/useWishlistTree';
import useWishProductCategories from '@/hooks/state/wishlist/useWishProductCats';
import useWishProducts from '@/hooks/state/wishlist/useWishProducts';
import useWishTargetCust from '@/hooks/state/wishlist/useWishTargetCust';
import { WishlistCommunityEngagement } from '@/views/pages/AdminWishlistReport/Subpages/WishlistCommunityEngagement';
import { WishlistCommunityReport } from '@/views/pages/AdminWishlistReport/Subpages/WishlistCommunityReport';
import { WishlistOverviewReport } from '@/views/pages/AdminWishlistReport/Subpages/WishlistOverviewReport';

import React from 'react';

const tabNames = ['User', 'Community','Community engagement']
export const AdminWishlistReport = ({history, ...props}) => {
    const categoryQuery = useWishProductCategories({})
    const demographicsQuery = useWishTargetCust({})
    const productsQuery = useWishProducts({})
    const geoQuery = useGeoData({})
    const wishlistTreeQuery = useWishlistTree({})
    const [activeTab, setActiveTab] = React.useState(1)
    const orgsQuery = useAllOrgs({flat: true})
    function goBack() {history.goBack()}

    return (
        <div>
            <BasicBackButton className={'-mt-20'} onClick={goBack}>
                Return to reports
            </BasicBackButton>
            <PageTitle>Wishlist Report</PageTitle>
            <BasicTabs {...{activeTab, onTabClick: setActiveTab, tabNames}}>
                <WishlistOverviewReport {...{orgsQuery, wishlistTreeQuery, geoQuery, demographicsQuery, categoryQuery, productsQuery}}/>
                <WishlistCommunityReport {...{orgsQuery, wishlistTreeQuery, geoQuery, demographicsQuery, categoryQuery, productsQuery}}/>
                <WishlistCommunityEngagement orgsQuery={orgsQuery} />
            </BasicTabs>
        </div>
    );
};

