import { MILLISEC } from '@/constants';
import { useMutation } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { UICOCreateOrg } from '@/apis/base/organizations/UICOCreateOrg';
import { signUpOrg } from '@/apis/base/organizations/signUpOrg';

export default function useSignUpOrg({ onError, onSuccess, props }) {
  const signUpOrgQuery = useMutation({
    mutationKey: ['org',ROUTE_IDS.SIGN_UP_ORG],
    mutationFn: signUpOrg,
    retry: false,
    staleTime: MILLISEC.MIN_15,
    onSuccess: onSuccess,
    onError: onError,
    ...props,
  });
  return signUpOrgQuery;
}