import axios from 'axios';

import Swal from 'sweetalert2';
import { deleteAllCookies, getCookie } from '../utils';
import { refreshToken } from './base/auth/refreshToken';
import { ROUTE_IDS } from './base/routeId';


const api = axios.create();

const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise = undefined;
  return () => {
    if (isCalled) {
      return runningPromise;
    } else {
      isCalled = true;
      runningPromise = refreshToken();
      return runningPromise;
    }
  };
};

const refreshExpiredToken = refreshExpiredTokenClosure();

api.interceptors.request.use(
  (request) => {
    let baseURL = '';
    request.url = baseURL + request.url;
    let accessToken = getCookie('token');
    if (accessToken != null) {
      request.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: '*/*',
      };
    } else {
      request.headers = {
        Accept: '*/*',
      };
    }

    return request;
},
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    // console.log('RESPONSE', response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const status = error?.response?.status;
    const endpoint = originalRequest?.data?.['type'];
    // console.log('code ', status);
    if (endpoint !== ROUTE_IDS.REFRESH && status === 401) {
      const refreshPayload = new FormData();
      refreshPayload.append('type', ROUTE_IDS.REFRESH);
      let refreshToken = getCookie('refreshToken');
      if (refreshToken != null) {
        try {
          const data = await refreshExpiredToken();
          console.log('refreshdata', data);
          if (data.status == 200 ) {
            console.log(data);
            console.log("====================================", data?.token);
            document.cookie = `token=${data?.data.token}; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/`;
            const new_token = data?.token;
            originalRequest.headers['Authorization'] = 'Bearer ' + new_token;
            return api(originalRequest)
            // const new_response = await axios(originalRequest);
            // return Promise.resolve(new_response);
          }  else {
            deleteAllCookies();
            document.location.href = '/auth/login';
            return Promise.reject(error);
          }
        } catch (err) {
          const res = await Swal.fire({
            timer: 3000,
            position: 'top-end',
            icon: 'error',
            title: 'Session expired',
            text: 'Please login again',
            showConfirmButton: false
          }).then((result) => {
            deleteAllCookies(); // TODO this may not be working
            console.log("FAILED TO REFRESGH COOKIES");
            document.location.href = '/auth/login';
            return Promise.reject()
          })
          return res
        }
      } else {
        console.log("FAILED TO REFRESGH COOKIES– NO REFRESH TOKEN");
        deleteAllCookies();
        
        // document.location.href = '/auth/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;
