import { styled } from '@material-ui/core';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';


import { BasicTabs } from '@/components/Tabs/BasicTabs';
import { useAppContext } from '@/context/AppContext';
import { useTranslations } from '@/hooks/translations';
import { CuratorWidget } from '../Home/components/CuratorWidget';
import { GiftIcon, HeartIcon } from '../Home/components/Icons';
import { DefaultCuratorFeed } from '@/views/pages/Main/Home/components/Curator/DefaultCuratorFeed';



export const Welcome = (props) => {
const { cookies, history } = props;
const text = useTranslations('home');
const { setLoading, setMessage } = useAppContext();
const [donationList, setDonationList] = useState([]);
const [brandPreferenceSetupStatus, setBrandPreferenceSetupStatus] =
useState(true);
const tabs = [[text.news, text.donations], [text.news]];
const [shopifyProducts, setShopifyProducts] = useState([]);
const queryParams = new URLSearchParams(window.location.search);
const [splashImageError, setSplashImageError] = useState(false)

const backgroundItems = [
    {
      url: '/assets/images/welcome.jpg',
      text1: 'Welcome to a new',
      text2: 'sustainable marketplace.',
    },
    {
      url: '/assets/images/connecting-brands.jpg',
      text1: 'Connecting brands',
      text2: 'with communities.',
    },
    {
      url: '/assets/images/every-transaction.jpg',
      text1: 'Every transaction',
      text2: 'gives back.',
    },
  ];

    return (
        <>
        <div className="w-full min-h-content bg-primary flex justify-center">
            <div className="container">
            <div className="w-full h-full px-4 sm:px-6 xl:px-8 my-4">
                <div className="my-6 w-full xl:flex">
                <div className="xl:w-3/5">
                    <div
                    className="w-full shadow-card rounded-9 relative overflow-hidden select-none"
                    style={{ paddingTop: '65%' }}
                    >
                        <div className="w-full h-full absolute top-0 left-0">
                            <StyledCarousel
                                indicatorIconButtonProps={{
                                    style: { color: 'white', opacity: 0.5, padding: 3 },
                                }}
                                activeIndicatorIconButtonProps={{
                                    style: { opacity: 1 },
                                }}
                                >
                                {backgroundItems.map((item, i) => (
                                    <div key={i} className="w-full h-full relative">
                                    <img
                                        className="w-full h-full object-cover object-bottom"
                                        src={item.url}
                                        alt={item.text1}
                                    />
                                    <div className="w-full h-full bg-dark absolute top-0 left-0 z-[4] opacity-60" />
                                    <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 z-[5]">
                                        <div className="flex justify-center items-center text-center">
                                        <div className="flex flex-col justify-center items-center">
                                            <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                                            {item.text1}
                                            </p>
                                            <p className="2xl:text-6xl sm:text-5xl xs:text-4xl text-3xl font-semibold text-white">
                                            {item.text2}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            ))}
                            </StyledCarousel>
                        </div>
                    </div>
                </div>
                <div className="xl:w-2/5 xl:pl-5 xl:pt-0 pt-6 flex xl:flex-col sm:flex-row flex-col justify-between">
                    {/* TODO Figure out what this is about */}
                    <>
                        <div className="flex-1 flex flex-col justify-center items-center text-center rounded-9 bg-base-100 shadow-card p-4 overflow-hidden">
                        <HeartIcon />
                        <p className="2xl:text-3xl sm:text-2xl text-xl mt-4 font-semibold tracking-0.28">
                            {text.annualDonated}
                        </p>
                        <p className="2xl:mt-5 mt-3 sm:text-lg text-base font-light">
                            {text.annualDonation}
                        </p>
                        </div>
                        <div className="2xl:mt-5 xl:mt-3 sm:mt-0 mt-3 xl:ml-0 sm:ml-3 ml-0 flex-1 flex flex-col justify-center items-center text-center rounded-9 bg-base-100 shadow-card p-4 overflow-hidden">
                        <GiftIcon />
                        <p className="2xl:text-3xl sm:text-2xl text-xl mt-4 font-semibold tracking-0.28">
                            {text.communityPartner}
                        </p>
                        <div className="2xl:mt-5 mt-3 sm:text-lg text-base font-light flex justify-space items-center w-full">
                            <div className="flex-1">
                            <p>{text.communitySchools}</p>
                            <p>{text.schoolText}</p>
                            <p>{text.CommunityText}</p>
                            </div>
                            <p className="mx-4 sm:text-3xl text-2xl opacity-50">
                            {' > '}
                            </p>
                            <div className="flex-1">
                            <p>{text.communityCharities}</p>
                            <p>{text.charities}</p>
                            </div>
                            <p className="mx-4 sm:text-3xl text-2xl opacity-50">
                            {' > '}
                            </p>
                            <div className="flex-1">
                            <p>{text.communityBrands}</p>
                            <p>{text.brands}</p>
                            </div>
                        </div>
                        </div>
                    </>
                    {/*<div className="flex flex-col">*/}
                    {/*  <p className="2xl:mt-5 mt-3 sm:text-base text-sm font-light">{text.communityStore}</p>*/}
                    {/*  <div className="flex justify-start mr-auto">*/}
                    {/*    {shopifyProducts.map(product => (*/}
                    {/*      <a href={product.onlineStoreUrl}>*/}
                    {/*        <img className="h-24" src={product.images[0].src} alt={"product name"}/>*/}
                    {/*      </a>*/}
                    {/*    ))}*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                </div>
                </div>
                    <div className="mt-4">
                        <BasicTabs tabNames={['News']} activeTab={0} fake>
                            <DefaultCuratorFeed initialRows={4} />
                        </BasicTabs>
                    </div>
                    </div>
                </div>
            </div>
        </>
        )
}

const StyledCarousel = styled(Carousel)({
    height: '100%',
    '& .CarouselItem': {
      height: '100%',
    },
    '& .CarouselItem div': {
      height: '100%',
    },
    '& > div:last-child': {
      position: 'absolute',
      bottom: 20,
      zIndex: 40,
    },
    '& button svg': {
      fontSize: '17px !important',
    },
  });