import { checkForNeedsRedirect } from '@/utils/contextUtils';
import React, { useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const RedirectHelper = ({contextQuery}) => {
    const history = useHistory()
    useLayoutEffect(() =>{
        if (!contextQuery.data || contextQuery?.isLoading) return
        checkForNeedsRedirect(contextQuery, history)
    },[contextQuery.data, contextQuery.isLoading])

    return (
        <div className='invisible'>
        </div>
    );
};
