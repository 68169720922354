import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submitTicket } from '@/apis/base/misc/submitTicket';


export default function useSubmitTicket(props) {
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.SUBMIT_TICKET],
        mutationFn: submitTicket,
        staleTime: MILLISEC.MIN_15,
        ...props
    });
    return mutation;
}