import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';
import { BasicGhostButton } from '@/components/Buttons/BasicGhostButton';
import { BasicGrayButton } from '@/components/Buttons/BasicGrayButton';
import { BasicDetailInput } from '@/components/Input/BasicDetailInput';
import { BasicToggle } from '@/components/Input/BasicToggle';
import { BasicDetailRow } from '@/components/Label/BasicDetailRow';
import { BadgeCell } from '@/components/Tables/Cells/BadgeCell';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import { getUserLabelInOrg, getUserTypeName } from '@/utils';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import React from 'react';
import { useForm } from 'react-hook-form';
import useModMyUserData from '@/hooks/state/user/useModMyUserData';
import useModUserData from '@/hooks/state/admin/useModUserData';


export const EditUserInfoDetail = ({user, onCancel= ()=>{}}) => {
    const userMutation = useModUserData({
        onSuccess: (data) => {
            SuccessToast.fire({text: 'User Updated'})
            onCancel()
        },
        onError: (error) => {FailPopup.fire({text: 'Failed to update user'})}
    })
    const ctxQuery = useCurrentOrgContext();
    const {register, handleSubmit, formState: {errors}} = useForm()
    const onSubmit = (data) => {
        userMutation.mutate({...data, userid: user.id})
    }
    return (
        <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
                <BasicDetailInput {...register('firstname')}  className='max-w-[15rem]' name='firstname' label="First Name" defaultValue={user?.['first name']}/>
                <BasicDetailInput {...register('lastname')} className='max-w-[15rem]' name='lastname' label="Last Name" defaultValue={user?.['last name']}/>
                <BasicDetailInput {...register('occupation')} className='max-w-[15rem]' name='occupation' label='Occupation' defaultValue={user?.occupation}/>
                <BasicDetailInput {...register('email')} className='max-w-[15rem]' name='email' label="Email" defaultValue={user?.email}/>
                <BasicDetailInput {...register('phonenumber')} className='max-w-[15rem]' name='phonenumber' label="Phone" defaultValue={user?.['phone number']}/>
                <BasicDetailRow className='max-w-[15rem]'  label="Type" value={getUserLabelInOrg(user, ctxQuery.data)}/>
                <div className="relative mb-10 ml-[33%]">
                    <div className="absolute flex  mt-4 gap-4 ">
                        <BasicGrayButton className={'btn-sm'} type='button' onClick={onCancel}>Cancel</BasicGrayButton>
                        <BasicBlueRectButton className={'btn-sm'} customSize type='submit'>Submit</BasicBlueRectButton>
                    </div>

                </div>
        </form>
    );
};

