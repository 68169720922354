import useDefaultCuratorFeed from '@/hooks/utils/useDefaultCuratorFeed';
import { DefaultCuratorFeed } from '@/views/pages/Main/Home/components/Curator/DefaultCuratorFeed';
import React from 'react';

export const Debug = ({ps, ...props}) => {
    return (
        <div>
            <DefaultCuratorFeed/>
        </div>
    );
};
