import { CardActions, CardContent } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { withCookies } from 'react-cookie';

import {
    getBannerUrl,
    getDashedDateString,
    getDateString,
    getLogoUrl,
    getMonthDayString,
    getSplashUrl,
} from '@/utils';

import { uploadOrgFlair } from '@/apis/base/lambda/uploadImages';
import { WhiteCard } from '@/components/Cards/WhiteCard';
import { useRegionCitySelect } from '@/components/CountryRegionSelect';
import { TrackedImage } from '@/components/Image/TrackedImage';
import { BasicDateInput } from '@/components/Input/BasicDateInput';
import { BasicInput } from '@/components/Input/BasicInput';
import { BasicToggle } from '@/components/Input/BasicToggle';
import { SubsectionTitle } from '@/components/Label/SubsectionTitle';
import { SectionTabs } from '@/components/Tabs';
import useGeoNode from '@/hooks/state/misc/useGeoNode';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import useModOrgData from '@/hooks/state/organization/useModOrgData';
import { useTranslations } from '@/hooks/translations';
import { FailPopup } from '@/wrappers/swal/FailPopup';
import { SuccessToast } from '@/wrappers/swal/SuccessToast';
import { useQueryClient } from '@tanstack/react-query';
import { BasicBlueRectButton } from '@/components/Buttons/BasicBlueRectButton';
import { BasicGrayButton } from '@/components/Buttons/BasicGrayButton';
import { UserCell } from '@/components/Tables/Cells/UserCell';
import { TransferOwnership } from '@/views/pages/Main/Organization/components/TransferOwnership';
import { BasicOrgTypeDropdown } from '@/components/Input/BasicOrgTypeDropdown';

const standardCommunitySections = [
    { title: 'Organization Details', href: 'organization-details' },
    { title: 'Application Forms', href: 'application-forms' },
    { title: 'Custom Organization', href: 'custom-organization' }]
const nonstandardCommunitySections = [
    { title: 'Organization Details', href: 'organization-details' },
    { title: 'Custom Organization', href: 'custom-organization' }]

const EDIT = {
    DETAILS: 0,
    FORMS: 1,
    CUSTOM: 2,
    MASTER_ADMIN: 3,
}    

const MIN_DATE = '1673893771'

function GeneralOrgPrefs(props) {
  const text = useTranslations('account');
  const [avatarRefreshToggle,setAvatarRefreshToggle] = useState(false)
  const contextQuery = useCurrentOrgContext();
  const client = useQueryClient()
  const orgMutation = useModOrgData({
    onSuccess: () => {
      SuccessToast.fire({
        text: 'Successfully updated organization info',
      })
  
    }, 
    onError: () => {
      FailPopup.fire({
        text: 'Failed to update organization info',
      });
      
      // setChangePwdError(err.response.data?.detail);
    
    }, 
  })
  const context = contextQuery?.data;
  console.log(context?.admins);
  const geoQuery = useGeoNode({
    nodeIds: [context?.['country id'], context?.['region id'], context?.['city id']]
  })
  const city = geoQuery.data?.length > 0 ? 
    geoQuery?.data.find(node => node.id === context?.['city id'])
    : {}
  const region = geoQuery.data?
    geoQuery?.data.find(node => node.id === context?.['region id'])
    : {}
  const {CitySelect, RegionSelect, CountrySelect} = useRegionCitySelect({
    onCityChange: (c) => {handleChange('cityid', c)},
    onRegionChange: (r) => {
      handleChange('regionid', r)
    },
    initialRegionID: region?.id,
    initialRegionLabel: region?.name,
    initialCityID: city?.id,
    initialCityLabel: city?.name,
  })

  const [campusList] = useState([
    {
      id: 1,
      name: 'St. George',
    },
    {
      id: 2,
      name: 'Scarborough',
    },
    {
      id: 3,
      name: 'Mississauga',
    },
  ]);
  const [buildingList] = useState([
    {
      id: 1,
      name: 'Chestnut residence',
    },
    {
      id: 2,
      name: 'Innis college',
    },
    {
      id: 3,
      name: 'Loretto college',
    },
  ]);
  const [editedOrgInfo, setEditedOrgInfo] = useState({
      orgname: context?.orgName || '',
      address: context?.address || '',
      cityid: context?.cityid || '',
      regionid: context?.regionid || '',
      countryid: context?.countryid || '',
      postalcode: context?.['postal code'] || '',
      title: context?.title || '',
      welcome: context?.welcome || '',
      description: context?.description || '',
      suffix: context?.suffix || '',
      roleexpiry: Number(context?.roleexpiry) > Number(MIN_DATE) ? context?.roleexpiry : MIN_DATE,
      org_logo_url: getLogoUrl(context?.orgid)  ,
      org_background_url: getSplashUrl(context?.orgid) ,
      org_banner_url: getBannerUrl(context?.orgid)  ,
      finactive: context?.finactive === '1' || '',
      ambactive: context?.ambactive === '1' || '',
      smiactive: context?.smiactive === '1' || '',
      private: context?.private === '1' ? true : false,
      orgtype:  {label: context?.['org type name'], value: context?.['org type id']},
  });
  console.log(contextQuery.data);
  useEffect(() => {
    setEditedOrgInfo({
        orgname: context?.orgName || '',
        address: context?.address || '',
        cityid: context?.['city id'] || '',
        regionid: context?.['region id'] || '',
        countryid: context?.['country id'] || '',
        postalcode: context?.['postal code'] || '',
        title: context?.title || '',
        welcome: context?.welcome || '',
        description: context?.description || '',
        suffix: context?.suffix || '',
        roleexpiry: Number(context?.roleexpiry) > Number(MIN_DATE) ? context?.roleexpiry : MIN_DATE,
        org_logo_url: getLogoUrl(context?.orgid)|| '',
        org_background_url: getSplashUrl(context?.orgid)|| '',
        org_banner_url: getBannerUrl(context?.orgid)|| '',
        finactive: context?.finactive,
        ambactive: context?.ambactive,
        smiactive: context?.smiactive,
        private: context?.private === '1' ? true : false,
        orgtype:  {label: context?.['org type name'], value: context?.['org type id']},
    })
  },[contextQuery.data])
    // useEffect(() => {
        
    // },[getSplashUrl(context?.orgid)])
    console.log(editedOrgInfo.org_background_url);
  const [editing, setEditing] = useState(null);
  const [generalTabs, setGeneralTabs] = useState( 
    context?.standardCommunity ? 
        standardCommunitySections 
        : nonstandardCommunitySections);
  
  const [openSelectLoginOrgDlg, setOpenSelectLoginOrgDlg] = useState(false);
  const [openTransferOwnershipDlg, setOpenTransferOwnershipDlg] =
    useState(false)

  const logoRef = useRef();
  const backgroundRef = useRef();
  const bannerRef = useRef();


  const toggleAvatarRefresh = () => setAvatarRefreshToggle(!avatarRefreshToggle)

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const handleEdit = (type) => {
    setEditedOrgInfo({ ...editedOrgInfo, [type]: true });
  };

  const handleSave = () => {
        const {org_logo_url,  org_background_url,roleexpiry, ...rest} = editedOrgInfo
        let isPrivate = editedOrgInfo.private
        // if (isPrivate && editedOrgInfo.suffix === '') isPrivate = false
        const orgParams = {
          ...rest,
          private: isPrivate,
          roleexpiry: roleexpiry === "" ? MIN_DATE : roleexpiry,
          orgtype: editedOrgInfo.orgtype.value,
          orgid: context?.orgid,
        }
        const logoFile = logoRef.current?.files?.[0];
        const splashFile = backgroundRef?.current?.files[0];
        const bannerFile = bannerRef.current?.files?.[0];
        if (logoFile || splashFile || bannerFile) 
          uploadOrgFlair({client, bannerFile, splashFile, logoFile,orgID: context?.orgid, userID:context.userid}).then((res) => {
            setTimeout(() => {
                client.invalidateQueries({queryKey:['mock', getLogoUrl(context?.orgid)]});
            }, 900)
        })
       

        orgMutation.mutate(orgParams);
        setEditing(false);

  };



  const handleChange = ( field, value) => {
    setEditedOrgInfo({
     ...editedOrgInfo, [field]: value 
    });
  };

  return  contextQuery.isSuccess && (
        <div className="flex justify-between gap-16">
            <div className=" w-full">
            <div className="mt-5 form-control gap-4">
                <div id="organization-details">
                        <WhiteCard>
                            <SubsectionTitle>Organization details</SubsectionTitle>
                            <div className="divider divider-vertical m-0 mt-1 mb-5"></div>
                            <div className="xl:flex ">
                            <div className="flex-7">
                                <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                    <p className="font-light">{text.orgName}:</p>
                                </div>
                                <div className="flex-5">
                                    {editing === EDIT.DETAILS ? (
                                    <BasicInput
                                        value={editedOrgInfo.orgname}
                                        onChange={(e) =>
                                        handleChange(
                                            'orgname',
                                            e.target.value
                                        )
                                        }
                                    />
                                    ) : (
                                    <p className="font-medium">
                                        {context?.orgName}
                                    </p>
                                    )}
                                </div>
                                </div>
                                <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                    <p className="font-light">{text.address}:</p>
                                </div>
                                <div className="flex-5">
                                    {editing === EDIT.DETAILS ? (
                                    <BasicInput
                                        value={editedOrgInfo.address}
                                        onChange={(e) =>
                                        handleChange(
                                            'address',
                                            e.target.value
                                        )
                                        }
                                    />
                                    ) : (
                                    <p className="font-medium">
                                        {context?.address}
                                    </p>
                                    )}
                                </div>
                                </div>
                                <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                    <p className="font-light">{text.country}:</p>
                                </div>
                                <div className="flex-5">
                                    {editing === EDIT.DETAILS ? (
                                    // <BasicInput value={editedOrgInfo.city} onChange={e => handleChange( 'city', e.target.value)} />
                                    <div className='flex-5'>
                                        {CountrySelect}
                                                
                                    </div>
                                    ) : (
                                    <p className="font-medium">
                                        {'Canada'}
                                    </p>
                                    )}
                                </div>
                                </div>
                                <div className="xs:flex items-center my-4">
                                    <div className="flex-3">
                                        <p className="font-light">{text.region}:</p>
                                    </div>
                                    <div className="flex-5">
                                    {
                                        editing === EDIT.DETAILS ? (
                                        <div className='z-10 flex-5'>
                                            {RegionSelect}
                                        </div>
                                        ) : (
                                        <p className="font-medium">
                                            {region?.name}
                                        </p>
                                        )
                                    }
                                    </div>
                                </div>
                                <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                    <p className="font-light">{text.city}:</p>
                                </div>
                                <div className="flex-5">
                                    {editing === EDIT.DETAILS ? (
                                    // <BasicInput value={editedOrgInfo.city} onChange={e => handleChange( 'city', e.target.value)} />
                                    <div className='flex-5'>
                                        {CitySelect}
                                    </div>
                                    ) : (
                                    <p className="font-medium">
                                        {city?.name}
                                    </p>
                                    )}
                                </div>
                                </div>
                                <div className="xs:flex items-center my-4">
                                    <div className="flex-3">
                                        <p className="font-light">{text.postalCode}:</p>
                                    </div>
                                    <div className="flex-5">
                                        {editing === EDIT.DETAILS ? (
                                        <BasicInput
                                            value={editedOrgInfo.postalcode}
                                            onChange={(e) =>
                                            handleChange(
                                                'postalcode',
                                                e.target.value
                                            )
                                            }
                                        />
                                        ) : (
                                        <p className="font-medium">
                                            {context?.['postal code']}
                                        </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xs:flex items-start my-4">
                                    <div className="flex-3">
                                        <p className="font-light">Master Admin:</p>
                                    </div>
                                    <div className="flex-5">
                                        {editing === EDIT.MASTER_ADMIN ? (
                                            <TransferOwnership
                                                context={context}
                                                org={context}
                                                admins={context?.admins?.filter?.(a => String(a.id) != String(context?.owner))}
                                                exit={() => setEditing(null)}œ
                                            />
                                            
                                        ) : (
                                            <UserCell 
                                                hasUserDetail={false}
                                                row={context?.admins.find(a => String(a.id) === String(context?.owner))}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-3" />
                        </div>
                        <div className='flex-3 '>
                            {editing === EDIT.DETAILS ? (
                            <div className=' flex gap-2 flex-wrap '>
                                <BasicBlueRectButton className={'lg:min-w-[4rem] !text-sm'}  onClick={() => handleSave()}>
                                {text.save}
                                </BasicBlueRectButton>
                                <BasicGrayButton onClick={() => setEditing(null)}>
                                {text.cancel}
                                </BasicGrayButton>
                            </div>
                            ) : (
                                <div className='flex gap-2 flex-wrap '>
                                    <BasicGrayButton onClick={() => setEditing(EDIT.DETAILS)}>
                                        {text.edit}
                                    </BasicGrayButton>
                                    {   context?.isOwner && (
                                        <BasicGrayButton 
                                            onClick={() => setEditing(
                                                editing === EDIT.MASTER_ADMIN 
                                                    ? null 
                                                    :EDIT.MASTER_ADMIN)}
                                        >
                                            { editing === EDIT.MASTER_ADMIN 
                                                ? 'Cancel master admin transfer' 
                                                : 'Transfer master adminship'}
                                        </BasicGrayButton>
                                    )}

                                </div>
                            )}
                        </div>
                    </WhiteCard>
                </div>

            { context?.standardCommunity && (
                <div id="application-forms"> 
                    <WhiteCard>
                        <SubsectionTitle>Application Forms</SubsectionTitle>
                        <div className="divider divider-vertical m-0 mb-5"></div>
                        <CardActions>
                            <div className='flex-7'>
                                <div className="flex flex-row items-center mb-4 justify-between">
                                    <p  className="flex-3 mr-10">Allow Famiy In Need applications?</p>
                                    <label className="label cursor-pointer flex-5">
                                        <BasicToggle onChange={(e) => handleChange('finactive',e.target.checked)}  disabled={!(editing === EDIT.FORMS)} 
                                        defaultChecked={context?.finactive === '1'} name="allowFin"  />
                                    </label>
                                </div>

                                <div className="flex flex-row items-center my-4 justify-between">
                                        <p className="flex-3 mr-10">
                                            Allow brand ambassador applications?
                                        </p>
                                            <label className="label cursor-pointer flex-5">
                                                <BasicToggle onChange={(e) => handleChange('ambactive',e.target.checked)} disabled={!(editing === EDIT.FORMS)} defaultChecked={context?.ambactive === '1'} name="alowAmb"  />
                                            </label>
                                </div>

                                <div className="flex flex-row items-center my-4 justify-between">
                                    <p  className="flex-3 mr-10">
                                        Allow social media influencer    applications?
                                    </p>
                                    <div className="form-control flex-5">
                                        <BasicToggle name="allowSmi" onChange={(e) => handleChange('smiactive',e.target.checked)} disabled={!(editing === EDIT.FORMS)} defaultChecked={context?.smiactive === '1'}  />
                                    </div>
                            </div>
                            </div>
                            <div className="flex-3"></div>
                        </CardActions>
                    <div className='flex-3'>
                        {editing === EDIT.FORMS ? (
                            <div className=' flex gap-2'>
                                <BasicBlueRectButton className={'lg:min-w-[4rem] !text-sm'} onClick={() => handleSave()}>
                                {text.save}
                                </BasicBlueRectButton>
                                <BasicGrayButton onClick={() => setEditing(null)}>
                                {text.cancel}
                                </BasicGrayButton>
                            </div>
                        ) : (
                        <BasicGrayButton onClick={() => setEditing(EDIT.FORMS)}>
                            {text.edit}
                        </BasicGrayButton>
                        )}
                    </div>
                    </WhiteCard>
                </div>
            )}

            <div id="custom-organization">
                <WhiteCard>
                    <SubsectionTitle>Custom Organization</SubsectionTitle>
                    <div className="divider divider-vertical m-0 mt-1 mb-5"></div>
                    <CardContent>
                        <div className="xl:flex mt-1 ">
                        <div className="flex-7">
                            <div className="xs:flex items-center my-4">
                                <p className="flex-3 font-light">{text.welcomeTitle}:</p>
                                <div className="flex-5">
                                    {editing === EDIT.CUSTOM ? (
                                    <BasicInput
                                        value={editedOrgInfo.title}
                                        onChange={(e) =>
                                        handleChange(
                                            'title',
                                            e.target.value
                                        )
                                        }
                                    />
                                    ) : (
                                    <p className="font-medium">
                                        {context?.title ||
                                        text.notSet}
                                    </p>
                                    )}
                                </div>
                            </div>
                            <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                    <p className="font-light">{text.welcomeMessage}:</p>
                                </div>
                                <div className="flex-5">
                                    {editing === EDIT.CUSTOM ? (
                                    <BasicInput
                                        value={editedOrgInfo.welcome}
                                        onChange={(e) =>
                                        handleChange(
                                            'welcome',
                                            e.target.value
                                        )
                                        }
                                    />
                                    ) : (
                                    <p className="font-medium">
                                        {context?.welcome ||
                                        text.notSet}
                                    </p>
                                    )}
                                </div>
                            </div>

                            <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                    <p className="font-light">Organization Description:</p>
                                </div>
                                <div className="flex-5">
                                    {editing === EDIT.CUSTOM ? (
                                    <textarea className='textarea textarea-bordered outline-none w-full '
                                        value={editedOrgInfo.description}
                                        onChange={(e) =>
                                            handleChange(
                                                'description',
                                                e.target.value
                                            )
                                        }
                                    />
                                    ) : (
                                    <p className="font-medium">
                                        {context?.description ||
                                        text.notSet}
                                    </p>
                                    )}
                                </div>
                            </div>
                                <div className="xs:flex items-center">
                                    <p className="flex-3 font-light ">Organization is private?</p>
                                        <label className="label cursor-pointer flex-5 -my-3">
                                        <BasicToggle  
                                            disabled={!(editing === EDIT.CUSTOM)} defaultChecked={editedOrgInfo.private}
                                            onChange={e => {
                                                handleChange('private',e.target.checked)
                                            }}/>
                                        </label>
                                </div>


                            <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                <p className="font-light">{text.emailSuffix}:</p>
                                </div>
                                <div className="flex-5">
                                {editing === EDIT.CUSTOM ? (
                                    <BasicInput
                                    value={editedOrgInfo.suffix}
                                    onChange={(e) =>
                                        handleChange(
                                        'suffix',
                                        e.target.value
                                        )
                                    }
                                    />
                                ) : (
                                    <p className="font-medium">
                                    {context?.suffix ||
                                        text.notSet}
                                    </p>
                                )}
                                </div>
                            </div>
                                

                            <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                    <p className="font-light">Community type:</p>
                                </div>
                                <div className="flex-5">
                                    {editing === EDIT.CUSTOM ? (
                                    <BasicOrgTypeDropdown
                                        value={editedOrgInfo.orgtype}
                                        onChange={(e) =>
                                        handleChange('orgtype',e)}
                                    />
                                    ) : (
                                    <p className="font-medium">
                                        {editedOrgInfo.orgtype?.label }
                                    </p>
                                    )}
                                </div>
                            </div>

                            <div className="xs:flex items-center my-4">
                                <div className="flex-3">
                                <p className="font-light">Year end date:</p>
                                </div>
                                <div className="flex-5">
                                {editing === EDIT.CUSTOM ? (
                                    <BasicDateInput
                                        value={getDashedDateString(editedOrgInfo.roleexpiry)}
                                        onChange={(e) => {
                                            handleChange('roleexpiry', e.target.valueAsNumber / 1000 )}
                                        } 
                                    />
                                ) : (
                                    <p className="font-medium">
                                        { getMonthDayString(context?.roleexpiry) ||
                                            text.notSet
                                        }
                                    </p>
                                )}
                                </div>
                            </div>

                            <div className="xs:flex items-start my-8 ">
                            <div className="flex-3">
                                <p className="font-light">{text.logo}:</p>
                            </div>
                            <div className="flex-5">
                                <div className='flex flex-col my-4 '>
                                    {/* {editing === EDIT.CUSTOM ? (
                                        <img
                                            className="w-full rounded-6  border-gray-border  border-2 border-solid max-h-[20rem] max-w-[20rem]"
                                            width={320}
                                            height={215}
                                            src={editedOrgInfo.org_logo_url}
                                            onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/assets/images/placeholder.png';
                                            }}
                                            alt="logo"
                                        />
                                    ) : (
                                    )}  */}
                                        <TrackedImage
                                            width={320}
                                            height={215}
                                            className="w-full rounded-6  border-gray-border  border-2 border-solid max-h-[20rem] max-w-[20rem]"
                                            src={editedOrgInfo.org_logo_url}
                                            onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/assets/images/placeholder.png';
                                            }}
                                            alt="logo"
                                        />
                                        
                                {editing === EDIT.CUSTOM && (
                                    <>
                                        <input
                                        ref={logoRef}
                                        accept="image/*"
                                        className="hidden"
                                        id="contained-button-file"
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files[0]) {
                                            fileToDataUri(e.target.files[0]).then(
                                                (dataUri) => {
                                                handleChange(
                                                    'org_logo_url',
                                                    dataUri
                                                );
                                                }
                                            );
                                            }
                                        }}
                                        />
                                        <div
                                        className={
                                            editedOrgInfo.org_logo_url
                                            ? 'flex mt-2'
                                            : 'flex'
                                        }
                                        >
                                        <div className="mr-3">
                                            <BasicGrayButton
                                                onClick={() => logoRef.current.click()}
                                            >
                                                {text.uploadImage}
                                            </BasicGrayButton>
                                        </div>
                                        <div>
                                            <BasicGrayButton
                                                onClick={() =>handleChange('org_logo_url','')}
                                            >
                                                {text.setDefault}
                                            </BasicGrayButton>
                                        </div>
                                    </div> </>
                                )} 
                                </div>
                            </div>
                            </div>
                            <div className="xs:flex items-start my-8 ">
                            <div className="flex-3">
                                <p className="font-light">{text.background}:</p>
                            </div>
                            <div className="flex-5">
                                <>
                                    {/* {editing === EDIT.CUSTOM ? (
                                        <img
                                            className="w-full rounded-6  border-gray-border  border-2 border-solid max-h-[20rem] max-w-[20rem]"
                                            width={320}
                                            height={215}
                                            src={editedOrgInfo.org_background_url}
                                            onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/assets/images/placeholder.png';
                                            }}
                                            alt="background"
                                        />
                                    ) : (
                                    )}  */}
                                        <TrackedImage
                                            width={320}
                                            height={215}
                                            className="w-full rounded-6  border-gray-border  border-2 border-solid max-h-[20rem] max-w-[20rem]"
                                            src={editedOrgInfo.org_background_url}
                                            onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/assets/images/placeholder.png';
                                            }}
                                            alt="background"
                                        />
                                        
                                {editing === EDIT.CUSTOM && (
                                    <>
                                        <input
                                        ref={backgroundRef}
                                        accept="image/*"
                                        className="hidden"
                                        id="contained-button-file"
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files[0]) {
                                            fileToDataUri(e.target.files[0]).then(
                                                (dataUri) => {
                                                handleChange(
                                                    'org_background_url',
                                                    dataUri
                                                );
                                                }
                                            );
                                            }
                                        }}
                                        />
                                        <div
                                            className={
                                                editedOrgInfo.org_background_url
                                                ? 'flex mt-2'
                                                : 'flex'}>
                                            <div className="mr-3">
                                                <BasicGrayButton
                                                onClick={() =>
                                                    backgroundRef.current.click()
                                                }
                                                >
                                                {text.uploadImage}
                                                </BasicGrayButton>
                                            </div>
                                            <div>
                                                <BasicGrayButton
                                                    onClick={() => handleChange('org_background_url','')
                                                }
                                                >
                                                {text.setDefault}
                                                </BasicGrayButton>
                                            </div>
                                    </div>  </>
                                )}
                                </>
                            </div>
                            </div>
                            <div className="xs:flex items-start my-4">
                            <div className="flex-3">
                                <p className="font-light">{text.banner}:</p>
                            </div>
                            <div className="flex-5">
                                <> 
                                    {/* {editing === EDIT.CUSTOM ? (
                                        <img
                                            className="w-full rounded-6  border-gray-border  border-2 border-solid max-h-[20rem] max-w-[20rem]"
                                            width={320}
                                            height={215}
                                            src={editedOrgInfo.org_banner_url}
                                            onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/assets/images/placeholder.png';
                                            }}
                                            alt="banner"
                                        />
                                    ) : (
                                    )}  */}
                                        <TrackedImage
                                            width={320}
                                            height={215}
                                            className="w-full rounded-6  border-gray-border  border-2 border-solid max-h-[20rem] max-w-[20rem]"
                                            src={editedOrgInfo.org_banner_url}
                                            onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/assets/images/placeholder.png';
                                            }}
                                            alt="background"
                                        />
                                        
                                {editing === EDIT.CUSTOM && (
                                    <>
                                        <input
                                            ref={bannerRef}
                                            accept="image/*"
                                            className="hidden"
                                            id="contained-button-file"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files[0]) {
                                                    fileToDataUri(e.target.files[0]).then(
                                                        (dataUri) =>
                                                            handleChange('org_banner_url',dataUri)
                                                    );
                                                }
                                        }}
                                        />
                                        <div
                                        className={
                                            editedOrgInfo.org_banner_url
                                            ? 'flex mt-2'
                                            : 'flex'
                                        }
                                        >
                                        <div className="mr-3">
                                            <BasicGrayButton
                                            onClick={() =>
                                                bannerRef.current.click()
                                            }
                                            >
                                            {text.uploadImage}
                                            </BasicGrayButton>
                                        </div>
                                        <div>
                                            <BasicGrayButton
                                            onClick={() =>
                                                handleChange(
                                                'org_banner_url',
                                                ''
                                                )
                                            }
                                            >
                                            {text.setDefault}
                                            </BasicGrayButton>
                                        </div>
                                        </div>
                                    </>
                                    )}
                                </>
                            </div>
                            </div>
                        </div>
                        <div className="flex-3" />
                        </div>
                    </CardContent>
                    <div className='flex-3'>
                        {editing === EDIT.CUSTOM ? (
                        <div className=' flex gap-2'>
                            <BasicBlueRectButton className={'lg:min-w-[4rem] !text-sm'} onClick={() => handleSave()}>
                            {text.save}
                            </BasicBlueRectButton>
                            <BasicGrayButton onClick={() => setEditing(null)}>
                            {text.cancel}
                            </BasicGrayButton>
                        </div>
                        ) : (
                        <BasicGrayButton onClick={() => setEditing(EDIT.CUSTOM)}>
                            {text.edit}
                        </BasicGrayButton>
                        )}
                    </div>
                </WhiteCard>
            </div>
        </div>
        



            {/* {[userTypes.admin, userTypes.masterAdmin].includes( */}
            {/* {[].includes(
                context?.userType
                ) && (
                    <SelectLoginOrganizationDialog
                        text={text}
                        open={openSelectLoginOrgDlg}
                        onClose={() => setOpenSelectLoginOrgDlg(false)}
                        changeLoginOrg={changeLoginOrg}
                    />
            )} */}
            
         
            
        </div>      
        <div className="relative">
            <div className='lg:block hidden sticky mt-4 top-5 right-5'>
                <SectionTabs tabs={generalTabs} />
            </div>

        </div>
    </div>
  )
}

export default withCookies(GeneralOrgPrefs);
