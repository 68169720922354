
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeWishlistProduct } from '@/apis/base/wishlist/removeWishlistProduct';

export default function useRemoveWishlistProduct(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.REMOVE_WISHLIST_PRODUCT],
        mutationFn: removeWishlistProduct,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data) => {
            client.invalidateQueries({queryKey: ['wishlist']})
            onSuccess && onSuccess(data)
        },
        ...otherProps
    });
    return mutation;
}