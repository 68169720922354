import { getEventUrl, rangeArray } from '@/utils';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { TrackedImage } from '../Image/TrackedImage';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { CarouselBase } from '@/components/Carousel/CarouselBase';

export const EventCarousel = ({
    eventid, 
    images, 
    numImages=4, 
    className = 'max-h-[20rem]  lg:max-h-[30rem] lg:max-w-[35rem] w-fit aspect-video', 
    ...props }) => {
   
    const src = useMemo(() => {
        return (
            eventid
                ? rangeArray(0, numImages)
                    .map(idx => getEventUrl(eventid, idx))
                : images || ['/assets/images/placeholder.png']
        )
    }, [eventid, images, numImages])

    return (eventid || images) && (
        <CarouselBase 
            className={`shadow-all-light relative rounded-box overflow-clip ${className}`}
            buttonType='shadowButtons'
            aspectRatioClassName='aspect-product'
            disableTracking={images != null}
            images={src}
            numImages={numImages}
        />
    );
};

