
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { getAppStatusEnum } from '@/apis/base/enum/getAppStatusEnum';
import { ensureToken } from '@/utils';


export default function useAppStatusEnum(params) {

    const query = useQuery(appStatusEnumQueryConfig());
    return query;
}

export const appStatusEnumQueryConfig = () => queryOptions({
    queryKey: [ROUTE_IDS.GET_APP_STATUS_ENUM],
    queryFn: getAppStatusEnum,
    staleTime: MILLISEC.HOUR,
    enabled: ensureToken(),
    gcTime: MILLISEC.HOUR,
    select,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
})
function select(data) {
    data.pending = data.find(s => s.name === 'Pending')?.id
    data.declined = data.find(s => s.name === 'Declined')?.id 
    data.approved = data.find(s => s.name === 'Approved')?.id
    data.allStatuses = [data.pending, data.declined, data.approved]
    return data
}