import { getEventUrl, rangeArray } from '@/utils';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { TrackedImage } from '../Image/TrackedImage';
import { ChevronLeft, ChevronRight } from '../Icons';

export const CardEventCarousel = ({
    eventid, 
    images, 
    numImages, 
    className = 'max-h-[20rem]  lg:max-h-[30rem] lg:max-w-[50%] w-fit h-full', 
    ...props }) => {
    const carouselRef = useRef()
    const [scrollPosition, setScrollPosition] = useState(0)
    const src = useMemo(() => {
        return (
            eventid
                ? rangeArray(0, numImages - 1)
                    .map(idx => getEventUrl(eventid, idx))
                : images || ['/assets/images/placeholder.png']
        )
    },[eventid, images, numImages])

    useEffect(() => {
        setScrollPosition(0)
    },[images])

    useLayoutEffect(() => {
        const clientWidth = carouselRef.current?.clientWidth
        carouselRef.current?.scrollTo({left: clientWidth * scrollPosition, behavior: 'smooth'})
    },[scrollPosition])

    function scrollLeft(e) {
        e.stopPropagation()
        let newPosition = canLeft()
            ? scrollPosition - 1
            : numImages - 1 
        setScrollPosition(newPosition)
    }
    function scrollRight(e) {
        e.stopPropagation()
        let newPosition = canRight()
            ? scrollPosition + 1 
            : 0
        setScrollPosition(newPosition)
    }
    function canRight() {
        return scrollPosition < numImages - 1
    }
    function canLeft() {
        return scrollPosition > 0
    }
    function hideImage(e) {
        if (numImages < 2) return
        e.currentTarget.parentElement.classList.add('hidden')
    }
    function showImage(e) {
        e.currentTarget.parentElement.classList.remove('hidden')
    }
    return (eventid || images) && (
        <div className="relative h-full w-full">
            <div ref={carouselRef} className="carousel w-full">
                { src?.map?.((img, idx) => (
                    <div key={`image ${idx}`} className="w-full h-full carousel-item">
                        <TrackedImage
                            disableTracking={img?.length > 200}
                            className=' w-full object-cover aspect-video'
                            src={img || '/assets/images/placeholder.png'}
                            onError={hideImage}
                            onLoad={showImage}
                            alt='event'
                        />
                    </div>
                ))}
                { numImages > 0 && (
                    <div className="absolute flex w-full h-full justify-between flex-nowrap pb-[0.4rem]">
                        <div tabIndex={0} role='button' className="w-1/3 items-center h-full opacity-0 hover:cursor-pointer hover:opacity-100 shadow-mask-left transition-[opacity, background-opacity] duration-300 bg-opacity-0  hover:bg-opacity-80 bg-black flex" onClick={scrollLeft}>
                            <ChevronLeft className='ml-[15%] text-white w-10 h-10 '/>
                        </div>
                        <div tabIndex={0} role='button' className="w-1/3 items-center h-full opacity-0 hover:cursor-pointer hover:opacity-100 shadow-mask-right transition-[opacity, background-opacity] duration-300 bg-opacity-0  hover:bg-opacity-80 bg-black flex justify-end" onClick={scrollRight}>
                            <ChevronRight className='mr-[15%] text-white w-10 h-10 '/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

