import { BrandPrefReportCard } from '@/components/Cards/Reports/BrandPrefReportCard';
import { WishlistReportCard } from '@/components/Cards/Reports/WishlistReportCard';
import { PageTitle } from '@/components/Label/PageTitle';
import useCurrentOrgContext from '@/hooks/state/organization/useCurrentOrgContext';
import React from 'react';

export const AdminReportDashboard = (props) => {
    const history = props.history;
    return (
        <div>
            <PageTitle>Reports</PageTitle>
            <div className='w-full justify-center grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 justify-items-center
            3xl:grid-cols-4 gap-7 mt-5'>
                <BrandPrefReportCard  {...{history}}/>
                <WishlistReportCard {...{history}} />
            </div>
        </div>
    );
};

