import { ROUTE_IDS, addRouteIDToFormData } from '../../routeId';
import config from '@/apis/config';
import { postPromise } from '@/apis/base';

const getAllBrands = () => {
  return postPromise(
    config.API_BASE_URL,
    addRouteIDToFormData(new FormData(), ROUTE_IDS.GET_BRANDS)
  );
};

export { getAllBrands };
