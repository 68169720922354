import config from "@/apis/config"
import { postPromise } from "@/apis/base"
import { ROUTE_IDS } from "@/apis/base/routeId"
import { convertObjFieldsToString } from "@/utils"
import { composeFormData } from "../routeId"


export const submitRoleApp = (params) => {
    return new Promise((resolve, reject) => {
        postPromise(config.API_BASE_URL, composeFormData(params,ROUTE_IDS.SUBMIT_ROLE_APPLICATION)).then((res)  => {
            if (res?.status === "FIN application already submitted") 
                reject(res)
            else
                resolve(res)    
        })
    })
}
