import { BasicBlackButton } from '@/components/Buttons/BasicBlackButton';
import { BackArrowShort, ForwardArrowShort } from '@/components/Icons';
import { rangeArray, setCookie } from '@/utils';
import React, { useMemo } from 'react';
import { PageSizeDropdown } from './PageSizeDropdown';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';


export const Pagination = ({table, history}) => {
    const {pageIndex, pageSize } = table?.getState?.()?.pagination || {}
    const pageCount = table?.getPageCount()
    const getAvailablePagebuttons = () => {
        let minOption = Math.max(0, pageIndex - 2)
        const maxOption = Math.min(pageCount - 1, minOption + 4)
        const range = rangeArray(minOption, maxOption)
        return range
    }
    const setPageIndex = (idx) => {
        table?.setPageIndex?.(idx)
        setCookie(`${history.location.pathname}_tablePage`, idx)   
    }
    const pageOptions = useMemo(() => getAvailablePagebuttons(), [pageIndex, pageCount])
    return (
        <div className="flex justify-between">
            {  pageCount > 1 ? (
                <div className='flex gap-4 items-center'>
                    <BasicBlackButton 
                        customSize 
                        className={'btn-sm btn-circle'} 
                        onClick={() => {setPageIndex(pageIndex - 1)}}
                        disabled={!table?.getCanPreviousPage()}>
                        {/* <BackArrowShort className='w-4 h-4'/> */}
                        <ChevronLeft className=''/>
                    </BasicBlackButton>
                    <div className='join flex join-horizontal'>
                        {   (pageIndex + 1) > 3 && 
                            ( 
                                (pageIndex + 1) === 4  ? 
                                <PageButton index={0} onClick={setPageIndex}>{1}</PageButton>
                            : <>
                                    <PageButton index={0} onClick={setPageIndex}>{1}</PageButton>
                                    <PageButton>...</PageButton>
                                </>
                            )
                        }
                        {pageOptions.map((index) => (
                            
                            <PageButton 
                                index={index} 
                                key={'page' + index}
                                isActive={pageIndex === index} 
                                onClick={setPageIndex}
                                >
                                    {index + 1}
                            </PageButton>
                            ))}
                        {   pageCount - (pageIndex + 1) > 2 && 
                            ( 
                                pageCount - (pageIndex + 1) === 3  ? 
                                <PageButton index={pageCount - 1} onClick={setPageIndex}>{pageCount}</PageButton>
                            : <>
                                    <PageButton>...</PageButton>
                                    <PageButton index={pageCount - 1} onClick={setPageIndex}>{pageCount}</PageButton>
                                </>
                            )
                        }
                    </div>
                    <BasicBlackButton 
                        customSize 
                        className={'btn-sm btn-circle'}
                        onClick={() => {setPageIndex(pageIndex + 1)}}
                        disabled={!table?.getCanNextPage()}>
                        <ChevronRight  className=''/>
                    </BasicBlackButton>
                </div>

            ) : (
                <div className="w-1"></div>
            )}

                <PageSizeDropdown table={table}/>
        </div>
    );
};


const PageButton = ({onClick= () => {}, index, isActive, children}) => {
    return (
        <div className={`join-item bg-primary btn btn-sm ${isActive ? 'outline outline-black z-20' : ''}`} onClick={() => onClick(index)}>
            {children}
        </div>
    )
}