
import { MILLISEC } from '@/constants';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getCookie } from '@/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addSecondaryEmail } from '@/apis/base/user/addSecondaryEmail';

export default function useAddSecondaryEmail({myUserID, ...props}) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.ADD_SECONDARY_EMAIL],
        mutationFn: addSecondaryEmail,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['context']})
            client.invalidateQueries({queryKey: ['user', myUserID]})
            client.invalidateQueries({queryKey: ['user', 'me']})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}   