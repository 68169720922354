import config from '@/apis/config';
import { ROUTE_IDS, addRouteIDToFormData } from '../routeId';
import api from '@/apis/api';
import { jsonToFormData } from '@/utils';

/**
 * 
 * @param {Object} params 
 * @param {string} params.code
 * @returns 
 */
const  getInviteData = (params) => {
  return new Promise((resolve, reject) => {
    let formData = jsonToFormData(params, new FormData());
    addRouteIDToFormData(formData, ROUTE_IDS.GET_INVITE_DATA);
    api
      .post(config.API_BASE_URL, formData)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);
      });
  });
};

export {  getInviteData  };
