import { MILLISEC, UIC_ORG } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { ROUTE_IDS } from '@/apis/base/routeId';
import { getAllOrgs } from '@/apis/base/organization';
import { arrayRemove } from '@/utils';
import React from 'react';

export default function useAllOrgs({flat=false, ...props}) {
  const {select, ...otherProps} = props || {}
  const allOrgs = useQuery({
    queryKey: ['org',ROUTE_IDS.GET_ORGANIZATIONS, flat],
    queryFn: getAllOrgs,
    notifyOnChangeProps: ['data'],
    staleTime: MILLISEC.MIN_15 ,
    select: React.useCallback((data) => {
      const u11Index = data.findIndex(org => org.id === UIC_ORG)
      if (u11Index > -1) arrayRemove(data, u11Index)
      if (!flat) return data
      const dataClone = structuredClone(data);
      const flatOrgs = []
      flatOrgHelper(dataClone, flatOrgs);
      dataClone.tree = data
      dataClone.flatOrgs = flatOrgs;
      return dataClone
    },[flat]) ,
    ...otherProps
  });
  return allOrgs;
}

const flatOrgHelper = (inputArray, outputArray) => {
  try {
    if (inputArray == null ) 
      return 
    inputArray.forEach((org) => 
      {
        if (org.id !== UIC_ORG) 
          outputArray.push(org)
        flatOrgHelper(org?.subnodes,outputArray)
      })
    return 

  } catch (error) {
    return ["uh oh"];
  }
}

