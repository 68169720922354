import React from "react";

import { BasicBlackOutlineButton } from "./Buttons/BasicBlackOutlineButton";
import { LocationIcon } from "./Headers/AuthenticatedHeader/HeaderMenu/HeaderIcons";
import { RemoveUserIcon } from "./Icons";


const XButton = ({buttonProps, svgProps}) => (
  <button {...buttonProps} className={`btn btn-ghost hover:text-red-text btn-circle btn-little flex-row ${buttonProps?.className}`}>
      <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
    </button>
)

const ApproveButton = ({buttonProps, svgProps}) => (
  <button {...buttonProps} className="btn btn-ghost hover:text-green-lime btn-circle btn-little flex-row">
    <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" ><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>

  </button>
)

const EditButton = ({ buttonProps, svgProps}) => (
  <button {...buttonProps}  className={`btn btn-ghost btn-circle btn-little  ${buttonProps?.className}`} >
    <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
  </button>
)

const ShippingButton = ({buttonProps, svgProps}) => (
  <button {...buttonProps} className="btn btn-ghost btn-circle btn-little flex-row" >
     <svg {...svgProps} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" className="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
  </button>
)

const InventoryButton = ({buttonProps, svgProps}) => (
  <button {...buttonProps} className="btn btn-ghost btn-circle btn-little flex-row" >
      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
       <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M3.308 9a2.257 2.257 0 0 0 2.25-2.264 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 1 0 4.5 0C19.058 5.471 16.956 1 16.956 1H3.045S1.058 5.654 1.058 6.736A2.373 2.373 0 0 0 3.308 9Zm0 0a2.243 2.243 0 0 0 1.866-1h.767a2.242 2.242 0 0 0 3.733 0h.767a2.242 2.242 0 0 0 3.733 0h.767a2.247 2.247 0 0 0 1.867 1A2.22 2.22 0 0 0 18 8.649V19H9v-7H5v7H2V8.524c.37.301.83.469 1.308.476ZM12 12h3v3h-3v-3Z"/>

  </svg>
  </button>
)

const DeleteButton = ({handleDelete, orgid, userid, }) => (
  <BasicBlackOutlineButton onClick={() => handleDelete(orgid, userid)} >
      <RemoveUserIcon />
  </BasicBlackOutlineButton>
)

const LocationButton = ({buttonProps, svgProps}) => (
  <button {...buttonProps} className="btn btn-ghost btn-circle btn-little flex-row" >
     <LocationIcon />
  </button>
)






// const ChevronButton = ()

export {
   ApproveButton, DeleteButton, EditButton, InventoryButton, LocationButton, ShippingButton,  XButton
};

