import { Delimit } from '@/components/Misc/Delimit';
import { SEE_CATEGORY } from '@/constants';
import { SEECategoryToBGClass } from '@/utils/misc';
import { allCaps } from '@/utils/textUtils';
import React from 'react';


export const SEEPillarCard = ({
    className, 
    SEECategory=SEE_CATEGORY.SOCIAL,
    title='Pillar Title',
    children,
    ...props}) => {
    return (
        <div 
            className={
                `form-control flex-1 last //max-w-[15rem] min-w-0  rounded-2xl p-2 md:p-5 items-center gap-2 
                ${SEECategoryToBGClass(SEECategory)} ${className}
                `}
            {...props}
        >
            <h3 className='font-semibold text-[0.70rem]  xl:text-[1.1rem] leading-snug pb-4'>   {allCaps(title)}</h3>
            <Delimit
                delimiter={<div className="w-full bg-white h-[2px]"></div>}
            > 
                {children}
            </Delimit>
        </div>
    );
};


