import React from 'react';


export const MultiTagSelect = ({data, checkedValues=[], disabled=false, onChange, className }) => {
    return (
        <form className='w-full'>
            <div className={`flex flex-row flex-wrap gap-2 gap-y-2  ${className}`}>
                {   data?.map?.((tData,i) => 
                    <Tag onChange={onChange} checked={checkedValues?.includes?.(tData?.value)} key={'tag ' + i} {...tData} disabled={disabled} />
                )}
            </div>
        </form>
    )
};

const Tag = ({label, value, disabled, checked, onChange}) => {
    return (
        <label  key={label + 'label'} htmlFor={label} className={`flex flex-row   box-border border-accent  border-[2px] text-[20px] tracking-wide font-[600] rounded-lg py-2 px-3 items-center whitespace-nowrap hover:xcursor-pointer
        ${ disabled ? 
            'bg-gray-100 hover:cursor-default text-gray-300 border-gray-400 opacity-40 ' :
            checked ? 
                'bg-gray-selected  text-white hover:cursor-pointer' : ' hover:cursor-pointer bg-gray-100 opacity-60 text-accent'}
        `}>
            <input  type='checkbox'  onChange={(e) => onChange(value, e.currentTarget.checked)} checked={checked} key={label + 'box'} id={label} name={value} disabled={disabled} className='appearance-none absolute '/>
            <span key={label + 'label'} >{label}</span>
        </label>

    )
}

