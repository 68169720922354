import { IMAGE_TYPE, OBJ_TYPES } from "@/constants";
import { getBannerUrl, getLogoUrl, getSplashUrl } from "@/utils";
import { randomString } from "@/utils/textUtils";

export const cacheImages = async (client, imgs=[], isTracked=true) => {
  console.log('caching images');
    if (!Array.isArray(imgs)) return Promise.resolve([])
    const promises = imgs.map((src) => 
      new Promise((resolve, reject) => {
        console.log('in image promise');
        const img = new Image();
        let mockData = client.getQueryData(['mock',src]);
        if (mockData != null) {
          // console.log('image already loaded');
          return resolve(true);
        } else {
          // console.log('image not loaded yet');
          mockData = Date.now();
        }
        img.src = `${src}${isTracked ? `?version=${mockData}` : ''}`
        img.onload = (e) => {
          console.log('image loaded',e);
          client.setQueryData(['mock',src], mockData)
          resolve( true);
      }
        img.onerror = (err) => {
          console.log("failed to load image", err);
          resolve(false)
        };
      }
    ))
    return Promise.all(promises)
}

export function loadFilesIntoInput(input, urls) {
  if (!urls || !(urls?.length > 1) || !input || !input.files) return
  const dt = new DataTransfer()
  urls.forEach((url, i) => {
    getImgURL(url, (blob) => {
      const fileName = url?.split('amazon.com/')?.[1]
      console.log('existing filename is ', fileName);
      const file = new File([blob], fileName, {type: blob.type})
      dt.items.add(file)
    })
  })
  input.files = dt.files
}

function getImgURL(url, callback){
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
      callback(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}


export async function preloadOrgImages(client, orgid) {
  const imgs = []
  imgs.push(getSplashUrl(orgid))
  imgs.push(getLogoUrl(orgid))
  imgs.push(getBannerUrl(orgid))
  return cacheImages(client, imgs)
}
export function getProductImageName() {

}

export function generateImageNames(nodeType, nodeid, numImages) {
  const names = []
  for (let i = 0; i < numImages; i++) {
    names.push(`${nodeType}-${nodeid}-${i}`)
  }
  return names
}

export function generateImageName(imageType, nodeid) {
  if (imageType === null || nodeid === null) throw new Error('failed to generate image name')
  const randomSuffix = randomString(5)
  switch (imageType) {
    case IMAGE_TYPE.PRODUCT:
      return `${nodeid}/${randomSuffix}`
    case OBJ_TYPES.avatar:
      return `${nodeid}/${randomSuffix}`
  }
}