import { BasicRectButton } from '@/components/Buttons/BasicRectButton';
import { ChevronRight } from '@/components/Icons';
import { getNiceDateString } from '@/utils';
import { CuratorShareHoverCard } from '@/views/pages/Main/Home/components/Curator/CuratorShareHoverCard';
import { PlatformIcon } from '@/views/pages/Main/Home/components/Curator/PlatformIcon';
import React from 'react';

export const SelectedPostDialog = ({selectedPost, selectNextPost, selectPrevPost, id='curatorDialog', onClose, ...props}) => {
    const postedDate = new Date(selectedPost?.source_created_at).getTime() / 1000
    return (
        <dialog id={id} className="modal items-start lg:items-center  top-10 lg:top-0 overflow-y-scroll font-light" onClose={onClose}>
            {selectedPost && (
                <div className="p-0 rounded-none bg-transparent relative w-auto">
                    <SelectSiblingButton toRight={false} onClick={selectPrevPost} className='absolute -left-6 top-1/2 transform -translate-y-1/2'/>
                    <SelectSiblingButton toRight={true} onClick={selectNextPost} className='absolute -right-6 top-1/2 transform -translate-y-1/2'/>
                    <div className="h-full  max-w-[950px] w-full  lg:w-auto ">
                        {/* Left */}
                        <figure className='w-full h-full min-h-[310px] relative lg:pr-[335px]'>
                            <img className='w-full h-auto max-w-[600px] lg:max-w-none' src={selectedPost.image} alt="post_image" />
                        </figure>
                        {/* Right */}
                        <div className="bg-white absolute lg:top-0 lg:right-0 lg:h-full h-auto w-full max-w-[600px] lg:w-[335px]">
                            <div className='relative h-full w-full flex flex-col px-5 pt-2.5 pb-[100px]'>
                                <form method="dialog">
                                    {/* if there is a button in form, it will close the modal */}
                                    <button className="absolute text-lg right-2 top-2">✕</button>
                                </form>
                                <header className='flex relative pb-1 mb-4 pr-4 pt-3'> 
                                    <PlatformIcon className={'w-6 h-6 place-self-center mr-4'} platform={selectedPost.network_name}/>
                                    <a href={selectedPost.user_url}>
                                        <figure className='w-[40px] aspect-square rounded-full overflow-hidden'>
                                            <img className='w-full h-full object-cover' src={selectedPost.user_image} alt="post_image" />
                                        </figure>
                                    </a>
                                    <div className="h-full text-xs pl-5">
                                        <p>{selectedPost.user_screen_name}</p>
                                        <p className='opacity-90'>@{selectedPost.user_full_name}</p>
                                    </div>
                                </header>
                                <div className='pt-2 text-sm font-light overflow-y-scroll px-2 text-clip whitespace-break-spaces break-words h-full relative'>
                                    <div className='absolute bg-gradient-to-b from-transparent from-70% to-90% to-white w-full h-full left-0 top-0'/>
                                    <p className='pb-4'>{getNiceDateString(postedDate, true)}</p>
                                    <p>{selectedPost.title}</p>
                                    <p>{selectedPost.text} </p>
                                </div>

                                <div className='absolute bottom-16 left-0 w-full bg-white flex flex-col py-2 items-center'>
                                    <a href={selectedPost.url} className='w-fit'>
                                        <button className='rounded-md bg-base-200 px-3 py-2 whitespace-nowrap font-normal'>
                                            Go to original post
                                        </button>
                                    </a>
                                </div>
                                <footer className='flex justify-between text-sm border-t absolute w-full bottom-0 left-0 py-3 px-2'>
                                    <div className="flex pl-2 gap-3">
                                        <p>{selectedPost.likes} LIKES</p>
                                        &bull;
                                        <p>{selectedPost.comments} COMMENTS</p>
                                    </div>
                                    <CuratorShareHoverCard post={selectedPost}/>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <form method='dialog' className="modal-backdrop absolute left-0 top-0 w-full h-full">
                <button>close</button>
            </form>
        </dialog>
    );
};


const SelectSiblingButton = ({toRight=true, onClick, className}) => (
    <button onClick={onClick} type='button' className={className}>
        <ChevronRight strokeWidth={3} strokeLinecap='square' className={`w-12 h-12 text-white ${toRight ? '' : 'rotate-180'} ${className}`}/>
    </button>
)