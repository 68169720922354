
import { BasicRectButton } from "@/components/Buttons/BasicRectButton"
import { BrowseOrgSlideModal } from "@/components/Dialog/SlideModal/BrowseOrgSlideModal/BrowseOrgSlideModal"
import { PlusIcon } from "@/components/Icons"
import { SectionTabs } from "@/components/Tabs"
import { UIC_ORG } from "@/constants"
import useMyOrgs from "@/hooks/state/organization/useMyOrgs"
import useMyOrgsData from "@/hooks/state/organization/useMyOrgsData"
import { recursiveSubnodeHelper } from "@/utils"
import { useCallback, useState } from "react"
import { CommunityAffiliationTable } from "./components/CommunityAffiliationTable"
import useMinimalOrgs from "@/hooks/state/organization/useMinimalOrgs"

export const CommunityPreferences = () => {
    const [showOrgBrowse, setShowOrgBrowse] = useState(false)
    const [generalTabs, setGeneralTabs] = useState([
        { title: 'Community Preferences', href: 'community-preferences' },
    ]);
    const myOrgsQuery = useMyOrgs()
    const allOrgsQuery = useMinimalOrgs({
        includeNonStandard:false, 
    })
    const communitiesQuery = useMyOrgsData({ 
        asTree:true,
        recursive: 1,
        select: useCallback((data) => {
            data?.forEach((org) => {
                recursiveSubnodeHelper(org,(o) => {
                    o.disabled = !myOrgsQuery.data?.includes?.(o.id)
                })}
            )
            return data.reduce((acc, org) => {
                if (org.id !== UIC_ORG) 
                    acc.push(org)
                return acc
            }
            ,[])
        },[myOrgsQuery.data]) 
    }) 
    console.log(communitiesQuery.data);
    return (
        <div className="relative flex flex-row">
            <div className=" lg:w-[80%] w-full" id="community-preferences">
                { !communitiesQuery.isPending &&
                    <CommunityAffiliationTable 
                        data={communitiesQuery.data} 
                    >
                        <BasicRectButton 
                            customSize className={'btn-primary btn-sm'} onClick={() => {setShowOrgBrowse (!showOrgBrowse)}}
                        >
                            Browse Communities  
                            <PlusIcon className="-mr-2"/>
                        </BasicRectButton>
                    </CommunityAffiliationTable>
                }
            </div>
            <div className="lg:block hidden mt-7 ml-12">
                <SectionTabs tabs={generalTabs} />
            </div>

            <div className="absolute right-0 container w-[50rem]">
                <BrowseOrgSlideModal
                    orgsData={allOrgsQuery.data}
                    hiddenOrgIDs={myOrgsQuery.data}
                    setShow={setShowOrgBrowse}
                    show={showOrgBrowse}
                />
            </div>
        </div>
    )
}
